import axios from "../../services/Api";
import { showToast } from "../../services/functions";
import { FETCH_ERROR, FETCH_SALES_DETAIL, FETCH_SALES_ORDER_ITEM_LIST, FETCH_SALES_ORDER_PROCESSING, FETCH_SALES_STATUS_COUNT, FETCH_START, FETCH_SUCCESS, GENERATE_SO_NUMBER,FETCH_ESOPFORM_TABLE_DATA, FETCH_PASSLINK_VALIDATION_TOKEN,FETCH_ASSOCIATED_JOB, FETCH_ASSOCIATED_TRIP } from "../types";


export const getAllSALESORDER = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/getSalesOrderList', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_SALES_ORDER_PROCESSING, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SALES_ORDER_PROCESSING, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data.data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const saveSalesOrderEntry = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/saveSalesOrder', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback(null)
        });
    }
};

export const saveSalesOrderWithExcel = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/bulkSalesOrderUpload', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                //(data.message);
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            callback(null)
        });
    }
};

export const generateSONumber = (payload,callback) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('base/generateSONumber', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success){
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: GENERATE_SO_NUMBER, payload: data.data})
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: GENERATE_SO_NUMBER, payload: ""})
        }
        if(callback){
            callback(data)
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: GENERATE_SO_NUMBER, payload: ""})
            if(callback){
                callback({success:false,message:error.message})
            }
            //("Error****:", error.message);
        });
    }
};

export const getSalesDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/getSalesOrderDetail', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_SALES_DETAIL, payload: {data: null, count: 0}})
                // if(payload.page || payload.limit || payload.entity_ID || payload.status || payload.submissionDate){
                //     // dispatch({ type: FETCH_ASN_DETAIL, payload: null})
                //     // Dont do anything
                // }
                // else{
                // }
                // showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SALES_DETAIL, payload: {data: data.data, count: data.count}})
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast("Something went wrong", true);
            if(cb){
                cb()
            }
        });
    }
};

export const getSalesStatusCount = (payload) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/getSalesStatusWiseCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_SALES_STATUS_COUNT, payload: null})
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type:FETCH_SALES_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getSalesOrderitemList = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/getSalesOrderitemList', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_SALES_ORDER_ITEM_LIST, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SALES_ORDER_ITEM_LIST, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const approveRejectSales = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('sales/changeSalesOrderStatus', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast(data.message, false);
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};

export const shareSalesOrderProcessingForm = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/saveFormConfig', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast(data.message, false);
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};

export const getEsopFormTableDataAPI = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/getFormConfigDetails', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ESOPFORM_TABLE_DATA, payload: {data: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ESOPFORM_TABLE_DATA, payload: {data: [], count: 0}})
            }
            if(cb){
                cb(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};
export const getPassLinkValidationToken = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/passcodeValidation', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PASSLINK_VALIDATION_TOKEN, payload: data?.data ||""})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_PASSLINK_VALIDATION_TOKEN, payload:  ""})
            }
            if(cb){
                cb(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};


export const getAssociatedJobDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/job/getAssociatedJobDetails', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ASSOCIATED_JOB, payload: {data: null, count: 0}})
                // if(payload.page || payload.limit || payload.entity_ID || payload.status || payload.submissionDate){
                //     // dispatch({ type: FETCH_ASN_DETAIL, payload: null})
                //     // Dont do anything
                // }
                // else{
                // }
                // showToast(data.message, true);
                if(cb){
                    cb(data)
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ASSOCIATED_JOB, payload: {data: data.data, count: data.count}})
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast("Something went wrong", true);
            if(cb){
                cb()
            }
        });
    }
};


export const getAssociatedTripDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/sales/getTripDetails', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ASSOCIATED_TRIP, payload: {data: null, count: 0}})
                // if(payload.page || payload.limit || payload.entity_ID || payload.status || payload.submissionDate){
                //     // dispatch({ type: FETCH_ASN_DETAIL, payload: null})
                //     // Dont do anything
                // }
                // else{
                // }
                // showToast(data.message, true);
                if(cb){
                    cb(data)
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ASSOCIATED_TRIP, payload: {data: data.data, count: data.count}})
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast("Something went wrong", true);
            if(cb){
                cb()
            }
        });
    }
};
