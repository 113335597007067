import React, { useEffect, useState } from 'react'
import {
    Grid, Card, Typography,Box, 
} from "@mui/material";
import colors, { inputClasses, cardClasses, textClasses, buttonClasses } from '../../static/styles/theme';
import { showToast } from "../../services/functions";
import axios from '../../services/Api';

const INVDetail = (props) => {
 
    const [state,setState]=useState({loader:true,data:[]})

    let fetchData=async()=>{
        let {search,origin,pathname}=window.location || {}
        setState({loader:true,data:[]})
        await axios.get(pathname + search)
            .then(({ data }) => {
                // //("data",data);
                setState({ loader: false, data: data?.data || [] })
            })
            .catch(function (error) {
                //("Error****:", error.message);
                setState({ loader: false, data: [] })
            });
    }
    useEffect(()=>{
        fetchData()
    },[])
    return(
        <>
            <Grid container sx={{height: "100vh",backgroundColor:"#F77200"}}
             justifyContent="center" alignItems="center" direction={"column"}
             >
                    <Card item sx={{ p: 3, ...cardClasses.basic, minWidth: "30vw", minHeight: "20vh" }} className="mui-custom-card">
                        <Box className="item-detail">
                            {
                                state?.data && state?.data?.map((detailElement, detaiIndex) => {
                                    return (
                                        <Typography key={detaiIndex}>
                                            <span style={{
                                                "fontSize": "14px",
                                                "fontWeight": "700",
                                                "lineHeight": "17px"
                                            }}>{detailElement.label} : </span>
                                            <span style={{
                                                "fontSize": "14px",
                                                "fontWeight": "00",
                                                "lineHeight": "17px"
                                            }}>{detailElement.value || "-"}</span>
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                    </Card>
               
            </Grid>
        </>
    )
   

};

export default INVDetail;