import { FETCH_ALL_DEKIT, FETCH_DEKIT_STATUS_COUNT, FETCH_DEKIT_DETAIL } from "../types"
const INIT_STATE = {
    allDeKit: [],
    totalRows: 0,
    statusCount: null,
    deKitDetail: null,
    deKitDetailItemCount: 0,

}
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ALL_DEKIT: return { ...state, allDeKit: action.payload.rows, totalRows: action?.payload?.count || 0 }
        case FETCH_DEKIT_STATUS_COUNT: return { ...state, statusCount: action.payload }
        case FETCH_DEKIT_DETAIL: return { ...state, deKitDetail: action?.payload?.data, deKitDetailItemCount: action?.payload?.count || 0 }

        default: return state
    }
}