import React, { useState, useEffect} from 'react';
import {  useDispatch } from 'react-redux';
import {Box,Grid,Button, Typography,Table,TableBody,TableCell, } from '@mui/material';
import theme, { buttonClasses, textClasses } from '../../../../static/styles/theme';
import { getUserProductivity } from '../../../../redux/actions/ManageTeam';
import AlertDialog from '../../../Components/DialogBox/dialogbox';
import GenericLoader from '../../../Components/Generic/GenericLoader';
import UserProductivity from "../../../../static/images/User/userProductivity.svg"

const ViewUserProductvity = (props) => {
	let dispatch = useDispatch();
	const { open, hide,selectedValue={}} = props
	const [loader, setLoader] = useState(false)
	const [detail, setDetail] = useState({})
	

	const fetchUserProductvityData = () => {
		setLoader(true)
		setDetail({ data: [], count: 0 })
		dispatch(getUserProductivity({ userId: selectedValue?.id}, ({ success = false, message, data = {} }) => {
			setLoader(false)
			setDetail(data)
		}));
	}

    // let ob = {
    //     "totalHoursWorked": 432,

    //     "totalOrdersAssigned": 291,
    //     "totalPickedOrdersAssigned": 118,
    //     "totalDropedOrdersAssigned": 171,

    //     "totalOrdersCompleted": 194,
    //     "totalPickedOrdersCompleted": 72,
    //     "totalDropedOrdersCompleted": 120,
    //     "totalUnitsPicked": 19810,
    //     "totalUnitsDroped": 33087,
    //     "totalUnitsScanned": 52952,

    //     "pickRate": 45.856,
    //     "dropRate": 76.59,
    //     "orderAccuracy": 66.667
    // }

    let formattedValue = (value, unit) => {
        if (value) {
            return value + " " + unit
        }
        return "-"
    }
    let maxCols=3
    let timeUnit="Hours"
    let orderUnit="Jobs"
    let qtyUnit="Units"
    let ratioUnit="%"
    let rateUnit="Units/Hours"

    const getRows = () => {
        return [
            {
                title: "Total Hours Worked",
                table: [
                    {
                        label: "Total Hours Worked",
                        value: formattedValue(detail.totalHoursWorked || 0, timeUnit),
                        isHideLabel: true
                    }
                ],
                
            },
            {
                title: "Jobs Assigned",
                table: [
                    { label: "Total", value: formattedValue(detail.totalOrdersAssigned || 0, orderUnit), isHideLabel: false },
                    { label: "Picked", value: formattedValue(detail.totalPickedOrdersAssigned || 0, orderUnit), isHideLabel: false },
                    { label: "Droped", value: formattedValue(detail.totalDropedOrdersAssigned || 0, orderUnit), isHideLabel: false }
                ],
                
            },
            {
                title: "Jobs Completed",
                table: [
                    { label: "Total", value: formattedValue(detail.totalOrdersCompleted || 0, orderUnit), isHideLabel: false },
                    { label: "Picked", value: formattedValue(detail.totalPickedOrdersCompleted || 0, orderUnit), isHideLabel: false },
                    { label: "Droped", value: formattedValue(detail.totalDropedOrdersCompleted || 0, orderUnit), isHideLabel: false }
                ],
                
            },
            {
                title: "Units Scanned",
                table: [
                    { label: "Total", value: formattedValue(detail.totalUnitsScanned || 0, qtyUnit), isHideLabel: false },
                    { label: "Picked", value: formattedValue(detail.totalUnitsPicked || 0, qtyUnit), isHideLabel: false },
                    { label: "Droped", value: formattedValue(detail.totalUnitsDroped || 0, qtyUnit), isHideLabel: false },
                ],
                
            },
            {
                title: "Rates and Accuracy",
                table: [
                    { label: "Picked Rate", value:formattedValue( detail.pickRate || 0, rateUnit), isHideLabel: false,subLabel:"Picked Units/Worked Hours" },
                    { label: "Droped Rate", value: formattedValue(detail.dropRate || 0, rateUnit), isHideLabel: false ,subLabel:"Droped Units/Worked Hours"},
                    { label: "Job Accuracy", value: formattedValue(detail.orderAccuracy || 0, ratioUnit), isHideLabel: false,subLabel:"Job Assigned/Job Completed" }
                ]
            }
        ];
    }

	useEffect(() => {
		if (selectedValue?.id) fetchUserProductvityData()
	}, [selectedValue?.id])


	return (
		<>
            <AlertDialog
                viewDialog={open}
                handleClose={() => hide()}
                showDivider={false}
                width="60%"
                title="User Productivity"
                viewSearchInput={true}
                searchInputDisplay={
                    <Typography>
                        {selectedValue?.name}, {selectedValue?.designation}
                    </Typography>
                }
                body={
                    <Box>
                        {loader
                            ? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>

                                <Grid item><GenericLoader /></Grid>
                            </Grid>
                            :
                            <Box sx={{ width: "inherit" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #0000002B" }}>
                                    {/* <thead>
                                        <tr style={{ border: "1px solid #0000002B" }}>
                                            <th
                                                style={{
                                                    border: "1px solid #0000002B",
                                                    color: "orange",
                                                    textTransform: "capitalize",
                                                    fontSize: '18px',
                                                    textAlign: "center"
                                                }}
                                                colSpan={maxCols}
                                            >
                                                {selectedValue?.name || ""}
                                            </th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        {detail?.totalHoursWorked ?
                                            getRows().map((section, i) => (
                                                <React.Fragment key={i}>
                                                    {section?.table?.map((row, j) => (
                                                        <tr key={i + "/" + j} style={{ border: "1px solid #0000002B" }}>
                                                            {j == 0 && <th
                                                                style={{
                                                                    textAlign: "left",
                                                                    padding: "10px",
                                                                    fontSize: '15px',
                                                                    fontWeight: 'bold',
                                                                    border: "1px solid #0000002B"
                                                                }}
                                                                rowSpan={section?.table?.length || 1}

                                                            >
                                                                {section.title}
                                                            </th>
                                                            }
                                                            {!row.isHideLabel && (
                                                                <th
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "1px solid #0000002B",
                                                                        padding: "10px",
                                                                        fontSize: '15px'
                                                                    }}

                                                                >
                                                                    {row.label}
                                                                    <br />
                                                                    {row?.subLabel && <span style={{ fontSize: '12px' }}>({row?.subLabel})</span>}

                                                                </th>
                                                            )}
                                                            <td
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "1px solid #0000002B",
                                                                    padding: "10px",
                                                                    fontSize: '15px'
                                                                }}
                                                                colSpan={row?.isHideLabel ? 2 : 1}

                                                            >
                                                                {row.value}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                            : <tr
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <td component="th" scope="row" colSpan={11} style={{ ...textClasses.normalText, textAlign: "center", overflowWrap: "break-word" }}>
                                                    <Grid container justifyContent={"center"} alignItems="center" sx={{ mb: 2 }}>
                                                        <Box>
                                                            <img src={UserProductivity} height={"250px"} width={"250px"} />
                                                            <Typography sx={{ pl: 2, pr: 2,fontWeight:600}}>
                                                                No Productivity Found!
                                                            </Typography>
                                                            <Typography sx={{ pl: 2, pr: 2 }}>
                                                                If you need assistance with productivity strategies, time management tips, or tools to help improve your productivity, Contact to company admin!
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    
                                                </td>

                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Box>
                        }
                    </Box>
                }
            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => hide()}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >
                    Cancel
                </Button>
            </AlertDialog>
			
		</>
	)
}

export default ViewUserProductvity