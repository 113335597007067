import { FETCH_ADJUSTMENT_REASONS, CREATE_ADJUSTMENT_REASON, FETCH_ALL_INVENTOR_ADJUSTMENTS, CREATE_INVENTORY_ADJUSTMENT, FETCH_ADJUSTMENT_DETAILS ,FETCH_ADJUSTMENT_STATUS_COUNT} from "../types";

const INIT_STATE = {
    allAdjustments: [],
    totalRows: 0,
    adjustmentReasons: [],
    statusCount: 0

}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      // case CLEAR_ENTRY: return {...state, selectedTemplate: null, packages: []}
      case FETCH_ALL_INVENTOR_ADJUSTMENTS: return {...state, allAdjustments: action.payload.rows, totalRows: action.payload.count}
      case FETCH_ADJUSTMENT_REASONS: return {...state, adjustmentReasons: action.payload }
      case FETCH_ADJUSTMENT_STATUS_COUNT: return {...state, statusCount: action.payload}

      default:
        return state;
    }
}