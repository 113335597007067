import React, { useEffect, useState } from "react"
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Box, Typography, Button, } from "@mui/material";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import theme, { textClasses, buttonClasses, } from "../../../../static/styles/theme";

const ViewDekitSkuComponents = ({ viewModal, setViewModal }) => {
    const tableData = viewModal.action;
    const [pagination, setPagination] = useState({ page: 0, limit: 10 })
    const tableHeaders = ["S.No", "SKU ID", "Entity ID", "Count"]
    const tableRows = tableData?.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit)?.map((row, index) => {
        let item = [];
        item.push(
            <Typography sx={{ ...textClasses.normalText }}>
                {(index + 1) + (pagination?.page * pagination?.limit)}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.SKU_ID}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.itemId}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.count}
            </Typography>
        )
        return item;
    })
    return (
        <>

            <AlertDialog
                viewDialog={viewModal.type === "viewComponents"}
                handleClose={() => { setViewModal({ ...viewModal, type: '' }) }}
                showDivider={true}
                width="70%"
                title="Component Details"
                actionsCSS={{ justifyContent: 'flex-start' }}
                body={
                    <>
                        {
                            <Box sx={{ width: "inherit" }}>
                                <GenericTable
                                    data-testid="components-table"
                                    header={tableHeaders}
                                    rows={tableRows}
                                    pageCount={tableData?.length || 0}
                                    pageNumber={pagination.page}
                                    handleChangePage={(event, pagevalue) => {
                                        setPagination({ ...pagination, page: pagevalue })
                                    }}
                                    handleChangeRowsPerPage={(event) => {
                                        setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                                    }}
                                    rowsPerPage={pagination.limit}
                                />
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ViewDekitSkuComponents;