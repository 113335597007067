export const automationTableDataInward= [
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Rahul Mukim, Admin ",
    UpdatedAt: "23-05-2023 13:56",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Govind, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Joseph, Admin ",
    UpdatedAt: "23-04-2023 03:56",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Madhur, Admin ",
    UpdatedAt: "23-04-2023 22:51",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Inward Task Scheduling",
    Details: "After QC verification create put away task",
    WorkFlow: "Inward FG",
    CreatedBy: "Rahul Mukim, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: false,
    Action: "Action",
  },
 
];
export const automationTableDataOutward = [
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Mayank, Admin ",
    UpdatedAt: "13-06-2023 11:36",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Ratan Mukim, Admin ",
    UpdatedAt: "23-04-2023 23:56",
    Status: true,
    Action: "Action",
  },
  {
    Name: "Outward Task Scheduling",
    Details: "After Picking create dispatch task",
    WorkFlow: "Outward FG",
    CreatedBy: "Ajay, Admin ",
    UpdatedAt: "12-08-2023 21:12",
    Status: false,
    Action: "Action",
  },
];
export const automationTableDataInventory = [
  {
    Name: "Inventory Task Scheduling",
    Details: "Create inventory audit task",
    WorkFlow: "Inventory FG",
    CreatedBy: "yatin, Admin ",
    UpdatedAt: "12-09-2023 11:33",
    Status: false,
    Action: "Action",
  },
  {
    Name: "Inventory Task Scheduling",
    Details: "Create inventory audit task",
    WorkFlow: "Inventory FG",
    CreatedBy: "Rinku, Admin ",
    UpdatedAt: "23-04-2023 12:56",
    Status: true,
    Action: "Action",
  },

];
export const automationHeader = [
  "name",
  "Detail",
  "Workflow",
  "Created By",
  "Updated At",
  "Status",
];

export const allWorkflows = [
  {
    status: "active",
    isGridExist: false,
    _id: "6373811b45d7f86888289496",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouse",
    companyName: "Ninja Coding",
    name: "Workflow I",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.638Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "634010de65af23502ffbc0c6",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouses",
    companyName: "Ninja Coding",
    name: "Workflow II",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "641bfde82b311227ac6d1164",
    companyName: "Ninja Coding",
    base: "Coding Ninjas",
    name: "Workflow III",
    type: "warehouse",
    location: "Lynkit",
    description: "Added on 23-03-2023",
    createdAt: "2022-11-23T10:29:02.688Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
    __v: 0,
  },
];
export const allWarehouses = [
  {
    status: "active",
    isGridExist: false,
    _id: "6373811b45d7f86888289496",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouse",
    companyName: "Ninja Coding",
    name: "Okhla Warehouse III",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.638Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "634010de65af23502ffbc0c6",
    base: "Coding Ninjas",
    description: "Coding Ninjas warehouses",
    companyName: "Ninja Coding",
    name: "Okhla Warehouse 1",
    type: "warehouse",
    location: "Okhla",
    createdAt: "2022-10-07T07:41:46.078Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
  },
  {
    status: "active",
    isGridExist: false,
    _id: "641bfde82b311227ac6d1164",
    companyName: "Ninja Coding",
    base: "Coding Ninjas",
    name: "Lynkit Warehouse 1",
    type: "warehouse",
    location: "Lynkit",
    description: "Added on 23-03-2023",
    createdAt: "2022-11-23T10:29:02.688Z",
    updatedAt: "2023-04-28T03:46:06.573Z",
    __v: 0,
  },
];

export const jobTypes = [
    {
        "status": "active",
        "isGridExist": false,
        "_id": "6373811b45d7f86888289496",
        "base": "Coding Ninjas",
        "description": "Coding Ninjas warehouse",
        "companyName": "Ninja Coding",
        "name": "Picking",
        "type": "warehouse",
        "location": "Okhla",
        "createdAt": "2022-10-07T07:41:46.078Z",
        "updatedAt": "2023-04-28T03:46:06.638Z"
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "634010de65af23502ffbc0c6",
        "base": "Coding Ninjas",
        "description": "Coding Ninjas warehouses",
        "companyName": "Ninja Coding",
        "name": "Change Location",
        "type": "warehouse",
        "location": "Okhla",
        "createdAt": "2022-10-07T07:41:46.078Z",
        "updatedAt": "2023-04-28T03:46:06.573Z"
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "641bfde82b311227ac6d1164",
        "companyName": "Ninja Coding",
        "base": "Coding Ninjas",
        "name": "Put Away",
        "type": "warehouse",
        "location": "Lynkit",
        "description": "Added on 23-03-2023",
        "createdAt": "2022-11-23T10:29:02.688Z",
        "updatedAt": "2023-04-28T03:46:06.573Z",
        "__v": 0
    },
    {
        "status": "active",
        "isGridExist": false,
        "_id": "641bfde82b311227ac6d1164",
        "companyName": "Ninja Coding",
        "base": "Coding Ninjas",
        "name": "Quality Check",
        "type": "warehouse",
        "location": "Lynkit",
        "description": "Added on 23-03-2023",
        "createdAt": "2022-11-23T10:29:02.688Z",
        "updatedAt": "2023-04-28T03:46:06.573Z",
        "__v": 0
    }
];

export const taskFieldList = [
    {
        "_id": "6373811b45d7f86888289496",
        "name": "Current Status",
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Total Count",
    },
];

export const conditionsList = [
    {
   
        "_id": "6373811b45d7f86888289496",
        "name": "greater than",
        "value": ">",

    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "less than",
        "value": "<",   
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "equal to",
        "value": "==",
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "greater than & equal to",
        "value": ">=",
    },
    {
        "_id": "641bfde82b311227ac6d1164",
        "name": "less than & equal to",
        "value": "<=",
    },
];

export const fieldValueData = [
    {
        "_id": "6373811b45d7f86888289496",
        "name": "Pass",
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Fail"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Yes"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "No"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Full"
    },
    {
        "_id": "634010de65af23502ffbc0c6",
        "name": "Partial"
    },
];



export const AUTOMATION_TRIGGER= "AUTOMATION_TRIGGER";
export const JOB_CREATION_STEP = "JOB_CREATION_STEP";

export const ADD_COMPONENT = "ADD_COMPONENT";
export const ADD_CONDITIONS = "ADD_CONDITIONS";
