const intialState = {
    AllPermissions: []
};


const PermissionReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_ALL_PERMISSIONS":
            return { ...state, AllPermissions: payload }
        default:
            return state;
    }
}
export default PermissionReducer;