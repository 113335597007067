import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';


import {
    Grid, Typography, Select, MenuItem, Box,
    Card, CardHeader, CardContent, CardActions, CardMedia,
    Avatar, Divider, Paper, Tabs, Tab, Breadcrumbs
} from '@mui/material'

import theme, { textClasses } from "../../../static/styles/theme"

import SelectTemplate from './SelectTemplate';
import ComponentCanvas from './ComponentCanvas';
import StandardComponents from './StandardComponents';
import PreviousUsedComponents from './PreviousUsedComponents';

import { getPreviousComonents, getStandardComonents, initCanvas, saveTemplate, selectTemplate, getTemplateTypes, getAllUom } from '../../../redux/actions';
import { templateTypes } from '../../../services/constants';
import { convertToCanvasObject } from '../../../redux/reducers/Canvas';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function CreateTemplate() {

    const dispatch = useDispatch();
    const allUom = useSelector(state => state.uom.allUom);
    let usedComponents = useSelector(state => state.templates.usedComponents)
    // usedComponents = null;
    const stdComponents = useSelector(state => state.templates.standardComponents)
    const predefinedElements = useSelector(state=> state.templates.predefinedElements)
    const components = useSelector(state => state.canvas.components );
    const selectedTemplate = useSelector(state => state.canvas.selectedTemplate)
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate)
    const allTemplateTypes = useSelector(state => state.templates.templateTypes);
    ////("Selected Template", selectedTemplate);
    ////("Selected Sub Template", selectedSubTemplate);

    const connectedWith = useSelector(state => state.canvas.connectedWith);
    const selectedItemMaster = useSelector(state => state.canvas.selectedItemMaster);

    const [tabIndex, setTabIndex] = useState(1);
    const [sectionStep, setSectionStep] = useState(1);
    const [selectModal, setSelectModal] = useState(false);
    
    const previousExists = usedComponents && usedComponents.length > 0;

    const handleOpenModal = () => setSelectModal(true);
    const handleCloseModal = () => setSelectModal(false);

    const handleTemplateSelect = (templateType, subTemplateType) => {
        dispatch(
            selectTemplate(templateType, subTemplateType, convertToCanvasObject(allTemplateTypes))
        )
        setSectionStep(sectionStep + 1);
    }

    const handleTabChange = (event, value) => {
        ////("Changing " + value);
        setTabIndex(value)
    }

    const handleTemplateChange = (event) => {
        ////("Changing template to ", event.target.value);
        let tType;
        let sTType;
        if(event.target.value == "-"){
            dispatch(
                selectTemplate("-", "-", convertToCanvasObject(allTemplateTypes))
            )
            setSectionStep(1);
            return;
        }
        for (let i = 0; i < allTemplateTypes.length; i++) {
            const t = allTemplateTypes[i];
            for (let j = 0; j < t.allTemplateTypes.length; j++) {
                const st = t.allTemplateTypes[j];
                if(st.templateTypekey == event.target.value){
                    tType = t.templateCategorykey;
                    sTType = st.templateTypekey;
                    dispatch(
                        selectTemplate(tType, sTType, convertToCanvasObject(allTemplateTypes))
                    )
                    setSectionStep(2);
                    return;
                }
            }
            
        }
    }

    

    useEffect(() => {
        
        dispatch(
            getStandardComonents({type: "wms"})
        )
        dispatch(
            getPreviousComonents({type: "wms"})
        )
        dispatch(
            getTemplateTypes((data) => {
                if(data && data.success){
                    dispatch(
                        initCanvas(convertToCanvasObject(data.data))
                    )
                    dispatch(
                        selectTemplate("-", "-", convertToCanvasObject(data.data))
                    )
                }
            })
        )
        // dispatch(
        //     selectTemplate("-", "-")
        // )
        dispatch(
            getAllUom()
        )
    }, [])

    const getSectionStep = () => {
        if(sectionStep == 1) {
            return (
                <SelectTemplate showModal={selectModal} handleTemplateSelect={handleTemplateSelect} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal}/>
            )
        }
        else if(sectionStep == 2) {
            return <ComponentCanvas  
            components={components} 
            setComponents={null} 
            selectedSubTemplate={selectedSubTemplate}
            handleSaveTemplate={handleSaveTemplate}
            handleTemplateChange={handleTemplateChange}
            />
        }
        return <div>Coming Soon..</div>
    }

    const getTabStyles = (tIndex) => {
        return {
            backgroundColor: tabIndex == tIndex ? theme.themeOrange: "white",
            color: tabIndex == tIndex ? "white" : theme.themeOrange,
            border: "1px solid",
            boxShadow: "0px 2px 6px gray",
            borderColor: theme.themeOrange,
            p: 0,
            pt: "10px",
            pb: "10px",
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "400",
            textTransform: "none",
        }
    }

    

    const handleSaveTemplate = (data, callback) => {
        const payload = {
            name: data.name,
            templateType: selectedSubTemplate,
            templateCategory: selectedTemplate,
            status: data.status,
            description: data.description,
            attributes: [...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.attributes.other],
            // packageTemplate: [...components.packageTemplate.mandatory, ...components.packageTemplate.other],
            // itemTemplate: [...components.itemTemplate.mandatory, ...components.itemTemplate.other],
        }

        if(["itemMaster"].includes(selectedSubTemplate)){//,"inwardDataEntry"
            payload.dataEntryType = data.dataEntryType;
            // payload.attributes = [
            //     ...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate).map((f, i) => {
            //         let fcopy = {...f};
            //         if(fcopy.isForBarcode){
            //             fcopy.orderInBarCode = i
            //         }
            //         return fcopy
            //     })
            // ]
            // payload.attributes = payload.attributes.concat(
            //     ...components.attributes.other.map((f, i) => {
            //         let fcopy = {...f};
            //         if(fcopy.isForBarcode){
            //             fcopy.orderInBarCode = payload.attributes.length + i
            //         }
            //         return fcopy
            //     })
            // )
        }
        
        if(selectedSubTemplate == "inwardDataEntry" && components.packageTemplate.status){
            payload['packageTemplate'] = [...components.packageTemplate.mandatory, ...components.packageTemplate.other]
        }
        if(["inwardDataEntry" ,"ASNTemplate","kittingTemplate","salesOrderTemplate"].includes(selectedSubTemplate) && components.itemTemplate.status){
            // Mark UOM required false in case of SPI
            if(payload.packageTemplate){
                let uomIndex = payload.packageTemplate.map(f => f._id).indexOf("UOM");
                if(uomIndex >= 0){
                    payload.packageTemplate[uomIndex].required = false;
                }
            }
            payload['itemTemplate'] = [...components.itemTemplate.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.itemTemplate.other]
        }
        if(["ASNTemplate","salesOrderTemplate"].includes(selectedSubTemplate)){
            payload["connectedMaster"] = {
                "itemMaster": selectedItemMaster != "-" ? selectedItemMaster : "",
            }
            payload["connectedWithAddressbook"] = components?.attributes?.addressBook;
        }
        ////(payload);
      
        dispatch(
            saveTemplate(payload, callback)
        )
    }

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Typography sx={{...textClasses.cardTitle}}>Create Templates</Typography>
                <Breadcrumbs sx={{mb: 1}}>
                    <Typography sx={{...textClasses.normalText, fontSize: "12px", color: theme.themeOrange}}>
                        <Link style={{color: theme.themeOrange, textDecoration: "none"}} to="/dashboard/templates">
                            Manage Templates
                        </Link>
                    </Typography>
                    
                    <Typography sx={{...textClasses.normalText, fontSize: "12px"}}>Create Template</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={10}>
                        <Card sx={{borderRadius: "8px"}}>
                            <CardHeader
                                sx={
                                    {
                                        pt: 1,
                                        pl: 2,
                                        pb: 1,
                                        borderRadius: "8px 8px 0px 0px",
                                        backgroundColor: theme.themeOrange,
                                        color: "white",
                                        boxShadow: "0px 2px 6px gray"
                                    }
                                }
                                action={
                                    // sectionStep > 1 && 
                                    <Select
                                        sx={{
                                            backgroundColor: "white",
                                            color: theme.themeOrange,
                                            minWidth: "200px",
                                        }}
                                        size='small'
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedSubTemplate}
                                        onChange={handleTemplateChange}
                                    >
                                        <MenuItem value={"-"}>Select Template Type</MenuItem>
                                        {
                                            allTemplateTypes.map((item, i) => {
                                                if(item.allTemplateTypes.length > 1){
                                                    let menus = item.allTemplateTypes.map((s, j) => {
                                                        return <MenuItem value={s.templateTypekey} key={`${i}-${j}`}>{item.templateCategoryLabel} - {s.templateTypeLabel}</MenuItem>
                                                    })
                                                    return menus;
                                                }
                                                else{
                                                    return <MenuItem value={item.allTemplateTypes[0].templateTypekey} key={i}>{item.allTemplateTypes[0].templateTypeLabel}</MenuItem>
                                                }
                                            })
                                        }
                                    </Select>
                                }
                                title={
                                    <Typography variant='h6'>Drop Section</Typography>
                                }
                            />
                            <CardContent sx={{zIndex: 100, mr: 2, overflowY: "scroll", height:'78vh', borderRadius: "8px"}}>
                                <Grid container sx={{mb: 1}}>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 1 ? theme.themeOrange : null}}>1</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}> choose template type</Typography>
                                    </Grid>
                                    <Grid item sm={3} justifyContent="center"  direction={"row"}>
                                        <Divider sx={{mt: 2 , borderBottomWidth : "3px" , backgroundColor:sectionStep==1?theme.themeOrange:null}} />
                                        
                                        
                                    </Grid>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 2 ? theme.themeOrange : null}}>2</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>drag and drop comp</Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Divider sx={{mt: 2, borderBottomWidth:"3px" , backgroundColor: sectionStep==2? theme.themeOrange : null}}/>
                                    </Grid>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 3 ? theme.themeOrange : null}}>3</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>preview & submit form</Typography>
                                    </Grid>
                                </Grid>

                                {
                                    getSectionStep()
                                }
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={4} xs={2}>
                        <Paper sx={{height:'78vh'}}>
                            <Tabs value={tabIndex} aria-label="disabled tabs example" variant='fullWidth' onChange={handleTabChange} sx={{'& .MuiTabs-indicator': { display: 'none' }, "& .MuiTab-root.Mui-selected": {color: '#FFFFFF'}}}>
                                {
                                    previousExists &&
                                    <Tab disabled={!previousExists} label="Previously Used Components"  {...a11yProps(0)} sx={{...getTabStyles(0), backgroundColor: previousExists ? (tabIndex == 0 ? theme.themeOrange: "white") : "#D7D7D7", marginRight: "1px", borderTopLeftRadius: "8px"}} />
                                }
                                <Tab label="Standard Form Components" {...a11yProps(1)} sx={{...getTabStyles(1),  marginLeft: previousExists ? "1px" : "0px", borderTopRightRadius: "8px"}} />
                            </Tabs>
                            {
                                previousExists &&
                                <TabPanel value={tabIndex} index={0} style={{backgroundColor: "#EEEEEE"}} >
                                    <PreviousUsedComponents usedComponents={usedComponents} />
                                </TabPanel>
                            }
                            
                            <TabPanel value={tabIndex} index={1} style={{backgroundColor: "#EEEEEE"}}>
                                <StandardComponents stdComponents={stdComponents} predefinedElements={predefinedElements}/>
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Grid>
            </DndProvider>
        </>
    )
}
// sx={{position: "fixed", zIndex: 100, mr: 2, overflowY: "scroll", maxHeight: "500px", borderRadius: "8px"}}
