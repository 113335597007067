import React, {useState, useEffect} from 'react'
import { Grid, Typography, Button, Dialog, DialogContent, IconButton, Autocomplete, TextField,Tooltip } from '@mui/material'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../static/styles/theme'
import { Cancel } from '@mui/icons-material';
import GenericCollapsableItemList from '../../Components/Generic/GenericCollapsableItemList';
import { capitalizeSentence,getShortText } from '../../../services/functions';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTemplates, getSelectedTemplate } from '../../../redux/actions';
import { useForm } from 'react-hook-form';
import { getFieldsLayoutForEntry } from '../../Components/Utils';

const CreatePackageDialog = (
    { open, onClose, dialogType, items, handleRemoveSelection, handleCreatePackaging, editIndex, packageObj, handleSavePackaging,
        getLabelKeyForJobConfig,jobProcessConfig={}
    }) => {

    const dispatch = useDispatch();
    
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const allTemplates = useSelector(state => state.templates.allTemplates);
    const template = useSelector(state => state.inward.selectedTemplate);
    const { register, control, handleSubmit, getValues, setValue, reset, formState: {errors}} = useForm();
    const allFields = template ? template.attributes: []
    const layout = getFieldsLayoutForEntry(allFields, register, control, () => {}, errors);

    const [editObject, setEditObject] = useState(null);

    const handleCreate = (data) => {
        handleCreatePackaging(data);
    }

    const handleSave = (data) => {
        let newObj = {
            ...editObject,
            ...data
        }
        handleSavePackaging(newObj, editIndex)
    }

    const handleRemoveItemFromLocal = (i) => {
        const old = JSON.parse(JSON.stringify(editObject));
        old.itemDetail.splice(i, 1);
        setEditObject(old);
    }

    useEffect(() => {
        if(open){
            
            dispatch(getAllTemplates({status: "published", templateType: "picking"}, (res, count) => {
                if(count > 0){
                    setSelectedTemplate(res[0]);
                }
            }))
            if(dialogType == "edit"){
                setEditObject(packageObj);
            }
        }
        else{
            let resetD = {...getValues()};
            let rKeys = Object.keys(resetD);
            for (let i = 0; i < rKeys.length; i++) {
                resetD[rKeys[i]] = ""
            }
            reset({
                ...resetD
            });
            setEditObject(null);
        }
        
    }, [open])

    useEffect(() => {
        if(selectedTemplate != null){
            dispatch(
                getSelectedTemplate({template_id: selectedTemplate._id})
            )
        }
    }, [selectedTemplate])

    useEffect(() => {
        if(template && editObject){
            let resetD = {...getValues()};
            let rKeys = Object.keys(resetD);
            for (let i = 0; i < rKeys.length; i++) {
                resetD[rKeys[i]] = ""
                setValue(rKeys[i], editObject[rKeys[i]])   
            }
        }
    }, [template, editObject])


    return (
        <Dialog
            open={open}
            // onClose={onClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"xl"}
        >
            <DialogContent>
                
                <Grid container>
                    <Grid container item sm={6} xs={6}>
                        <Typography sx={{...textClasses.cardTitle}}>Package Details</Typography>
                    </Grid>
                    <Grid container item={6} xs={6} justifyContent="right">
                        <Autocomplete
                                disabled
                                value={selectedTemplate}
                                onChange={(e, option) => {
                                    setSelectedTemplate(option);
                                }}
                                getOptionLabel={(option) => capitalizeSentence(option.name || "")}
                                size='small'
                                options={allTemplates}
                                sx={{...inputClasses.shadowField,
                                    minWidth: "220px",
                                    m: 0,
                                    ml: 2,
                                    ".MuiInputBase-input": {
                                    fontSize: "14px",
                                    color: "#455A64"
                                }}}
                                renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Template" />}
                            />
                    </Grid>
                </Grid>
                <Grid sx={{p: 2, border: "1px solid #D9D9D9", borderRadius: "8px", mt: 2}}>
                    <Typography sx={{...textClasses.boldText, mb: 1}}>
                        Selected Items in Package
                        ({
                            dialogType == "create"
                            ?
                                `${items.map(i => i.count).reduce((a, b) => a + b, 0)}`
                            :
                                `${editObject && editObject.itemDetail.map(i => i.count).reduce((a, b) => a + b, 0)}`
                        })
                    </Typography>
                    <GenericCollapsableItemList
						items={dialogType == "create" ? items : (editObject ? editObject.itemDetail : [])}
						limit={3}
                        itemSpacing={{sm: 3, xs: 4}}
						handleRemoveItem={dialogType == "create" ? handleRemoveSelection : handleRemoveItemFromLocal}
						getContent={(itemList, i) => {
							let itemObj = itemList[i];
                            // let itemKey = itemObj.isItemExist
                            //     ? getLabelKeyForJobConfig(jobProcessConfig, "item", "key") || "NA" :
                            //     (itemObj.isPackageExist ? getLabelKeyForJobConfig(jobProcessConfig, "package", "key") || "NA" :
                            //         getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key") || "NA");
                            // return `${itemObj[itemKey]} (${itemObj.count} qty)`
                           
                            let labelToView = itemObj.isItemExist
                              ? getLabelKeyForJobConfig(jobProcessConfig, "item", "key", itemObj,["gateInTime"]) || "NA"
                              : itemObj.isPackageExist
                                ? getLabelKeyForJobConfig(jobProcessConfig, "package", "key", itemObj,["gateInTime"]) || "NA"
                                : getLabelKeyForJobConfig(jobProcessConfig, "shipment", "key",itemObj,["gateInTime"]) || "NA";
                            return <Tooltip title={`${labelToView} (${itemObj.count} qty)`} placement="bottom">
                            <Typography sx={{...textClasses.t13n}}>
                              {getShortText(`${labelToView} (${itemObj.count} qty)`, 21)}
                            </Typography>
                          </Tooltip>
                            ;
						}}
						// sx={{ mb: 3}}
					/>
                </Grid>
                <Grid sx={{p: 2, mt: 2, border: "1px solid #D9D9D9", borderRadius: "8px"}}>
                    <Grid container spacing={4}>
                        {   
                            layout.single.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                        {
                                            field
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {/* <Grid container spacing={4}>
                        {   
                            layout.multi.map((field, i) => {
                                return (
                                    <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                        {
                                            field
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </Grid> */}
                </Grid>
                <Grid container item direction={"row"} sm={12} xs={12} sx={{mt: 2}}>
                    <Button variant='contained' size='small' sx={{...buttonClasses.lynkitBlackFill, minHeight: "40px",  minWidth: "150px"}} onClick={onClose}> 
                        Cancel
                    </Button>
                    {
                        dialogType == "create"
                        ?
                            <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}}
                                onClick={handleSubmit(handleCreate)}
                            >
                                Create Package
                            </Button>
                        :
                            <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}}
                                onClick={handleSubmit(handleSave)}
                            >
                                Update Package
                            </Button>

                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CreatePackageDialog;