import React, { useState } from "react"
import { Chip, IconButton, Collapse, Typography, Stack, Tooltip,Box } from '@mui/material'
import {
    ExpandLess, ExpandMore, Clear as ClearIcon,
} from '@mui/icons-material';
import { getShortText } from '../../../services/functions';
import theme ,{textClasses} from "../../../static/styles/theme";

const CustomChip = ({data = [], makeDisabled = false, handleDelete,readLabelKey="$self"}) => {

    const [expand, setExpand] = useState(false);

    let renderChip = (labelObj="", index = 0) => {
        let name=labelObj
        if(readLabelKey){
            if(readLabelKey=="$self"){
                name=labelObj
            }
            else{
                name=labelObj[readLabelKey]
            }
        }
        return (
            <Chip
                label={
                    (name + "")?.length > 15
                        ? <Tooltip title={name} placement="top">
                            <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                {getShortText(name, 15)}
                            </Typography>
                        </Tooltip>
                        : <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            {name || ""}
                        </Typography>
                }
                onDelete={() => handleDelete(index, name)}
                key={index}
                sx={{ m: 0.5, color: theme.themeOrange, backgroundColor: theme.themeLightOrange, border: "1px solid #FF7200" }}
                deleteIcon={
                    <IconButton
                        disabled={makeDisabled ? !data || data.length <= 1 : false}
                    >
                        <ClearIcon sx={{ color: theme.themeOrange }} />
                    </IconButton>
                }
            />
        )
    }

    return (
        <Box>
            <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="start"
                alignItems="start"
                data-testid="data-jobtype"
                
            >
                {
                    data?.length && data.slice(0, 5).map((dl, index) => renderChip(dl || "", index))
                }

                {
                    data?.length > 5 &&
                    <>
                        {!expand &&
                            <Typography
                                sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange, mt: 1 }}
                            >
                                View All <ExpandMore style={{ cursor: "pointer" }} onClick={() => setExpand(true)} />
                            </Typography>
                        }
                        <Collapse in={expand} timeout="auto" unmountOnExit>
                            {data.slice(5).map((dl, index) => renderChip(dl || "", 5 + index))}
                        </Collapse>
                        {expand &&
                            <Typography
                                sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange, mt: 1 }}
                            >
                                View Less <ExpandLess onClick={() => setExpand(false)} style={{ cursor: "pointer" }} />
                            </Typography>
                        }
                    </>
                }
            </Stack>
        </Box>
    )
}

export default CustomChip
