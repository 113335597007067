import {
  Autocomplete,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { selectStylesOverride } from "../../../../utils/util";
import GenericLoader from "../../Generic/GenericLoader";
import theme, {
  textClasses,
  buttonClasses,
} from "../../../../static/styles/theme";
import { Grid as VirtualGrid, List, AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMappedMasterId, getMappedArea } from "../../../../redux/actions";
import EmptyPage from "../../../Modules/EntityMaster/EmptyPage";

const ViewAreaMapping = ({
  open,
  hide,
  selectedData,
  distinctMasters,
  categoryClicked,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const mappedMasterIds = useSelector(
    (state) => state.areaMappingData.mappedMasterIds
  );
  const mappedAreaItems = useSelector(state=> state.areaMappingData.mappedAreaForView)?.data || []
  const [selectedCategory, setSelectedCategory] = useState({
    label: "",
    value: "",
  });
  const [selectedFamily, setSelectedFamily] = useState({
    label: "",
    value: "",
  });
  const [payload, setPayload] = useState({
    floorId: "",
    mappingArea: "",
    category: "",
  });

  // console.log(mappedAreaItems, "show mappedAreaItems")
  const categoryOptions = useMemo(() => {
    const options = [];
    for (let master in distinctMasters) {
      if (distinctMasters[master] === true) {
        let label, value;
        if (master === "item-master") {
          label = "Item Master";
          value = "itemMaster";
        } else if (master === "custom-master") {
          label = "Custom Master";
          value = "customMaster";
        }
        options.push({ label: label, value: value });
      }
    }
    return options;
  }, [distinctMasters]);
  // console.log

  useEffect(() => {
    const selectedOption = categoryOptions.find(
      (option) => option.value === categoryClicked
    );
    selectedOption && setSelectedCategory(selectedOption);
    setSelectedFamily({
      label: selectedData?._id?.subProductType,
      value: selectedData?._id?.subProductType,
    });
  }, [categoryOptions, setSelectedCategory, setSelectedFamily, selectedData]);

  useEffect(() => {
    if (selectedData) {
      setPayload((prev) => ({
        ...prev,
        floorId: selectedFamily?.floorId || selectedData?._id?.floorId,
        mappingArea: selectedFamily?.value,
        category: selectedCategory?.value,
      }));
    }
  }, [
    selectedData,
    categoryOptions,
    selectedCategory,
    setPayload,
    selectedFamily,
  ]);

  useEffect(()=>{
    if(open){
      dispatch(getMappedArea({allAreaNameOnly: true,get_type:"floorWise" }, ()=>{
      }, "details"))
    }
  },[open, dispatch])

  useEffect(() => {
    if(open){
      if (payload?.floorId && payload?.mappingArea && payload?.category) {
        dispatch(
          getMappedMasterId(payload, () => {
            setLoader(false);
          })
        );
      }
    }
  }, [open, payload, dispatch]);

  const handleChangeCategory = useCallback(
    (option) => {
      setSelectedCategory(option);
    },
    [setSelectedCategory]
  );

  const handleChangeFamily = useCallback(
    (option) => {
      setSelectedFamily(option);
    },
    [setSelectedFamily]
  );

  const numberOfRows = Math.ceil(mappedMasterIds?.length / 6);

  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 5 + columnIndex; // Assuming 3 columns
    const item = mappedMasterIds[index]?.entityID;

    return (
      <div style={{ ...style, padding: "8px" }}>
        {item && (
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.themeLightOrange,
              border: `1px solid ${theme.themeOrange}`,
              borderRadius: "12px",
              pl: 1,
              pr: 1,
              minHeight: "32px",
            }}
          >
            <Grid item sm={10} xs={8}>
              {item && item.length > 12 ? (
                <Tooltip arrow placement="top" title={item}>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item && item.length > 20
                      ? item.substring(0, 20) + "..."
                      : item}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {item}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={hide}
        data-testid="viewAreaMapping"
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"lg"}
      >
        <DialogContent>
          <Grid container xs={12}>
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Grid item xs={4}>
                <Typography sx={{ mt: "5px", fontWeight: "800" }}>
                  Mapped Area Details
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={8}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "1rem",
                }}
              >
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    data-testid="categoryDropdown"
                    options={categoryOptions}
                    onChange={(e, option) => {
                      handleChangeCategory(option);
                    }}
                    defaultValue={selectedCategory}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          fontSize: "12px",
                          ...selectStylesOverride,
                        }}
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "14px" }}>
                            Select Category
                          </Typography>
                        }
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              color: option.isCreateOption
                                ? "#ff7200"
                                : "#727376",
                              fontSize: "14px",
                            }}
                          >
                            {option.label}
                          </li>
                        )}
                        value={selectedCategory}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Autocomplete
                    size="small"
                    data-testid="familyDropdown"
                    disableClearable
                    options={
                      mappedAreaItems || []
                    }
                    onChange={(e, option) => {
                      handleChangeFamily(option);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          fontSize: "12px",
                          ...selectStylesOverride,
                        }}
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "14px" }}>
                            Family
                          </Typography>
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          color: option.isCreateOption ? "#ff7200" : "#727376",
                          fontSize: "14px",
                        }}
                      >
                        {option.label}
                      </li>
                    )}
                    value={mappedAreaItems?.find((item)=> item.value === selectedFamily?.value) || ""}
                  />
                </Grid>
              </Grid>
            </Grid>

            {loader ? (
              <Grid
                item={true}
                data-testid="loader"
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                sx={{ p: 1 }}
              >
                <Grid item>
                  <GenericLoader />
                </Grid>
              </Grid>
            ) : mappedMasterIds?.length > 0 ? (
              <Grid
                item
                xs={12}
                border={1}
                sx={{
                  borderRadius: "10px",
                  padding: 2,
                  borderColor: "grey.500",
                }}
              >
                <div style={{ width: "100%", height: "300px" }}>
                  {
                    <AutoSizer>
                      {({ width, height }) => {
                        return (
                          <FixedSizeGrid
                            columnCount={5} // Number of columns
                            columnWidth={220} // Width of each column
                            height={numberOfRows < 5 ? numberOfRows * 50 : 300} // Total height of the grid
                            rowCount={numberOfRows}
                            rowHeight={50} // Height of each row
                            width={width} // Total width of the grid
                          >
                            {cellRenderer}
                          </FixedSizeGrid>
                        );
                      }}
                    </AutoSizer>
                  }
                </div>
              </Grid>
            ) : (
              <EmptyPage />
            )}
            <Grid
              item
              direction={"row"}
              sm={12}
              xs={12}
              sx={{
                ml: 1,
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                pr: 0,
              }}
            >
              <Button
                variant="outlined"
                data-testid="cancelButton"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "130px",
                }}
                onClick={hide}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewAreaMapping;
