import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomModel from '../../Components/CustomModels/UserModel';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button } from "@mui/material";
import colors, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { Delete, Edit } from '@mui/icons-material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

function EditViewWorkFlow(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [tasksArray, setTasksArray] = useState([])
    const { open, hide, status, item } = props



    useEffect(() => {
        const arrayWithShowKey = item.tasks.map((task, index) => {
            if (index === item.tasks.length - 1) {
                return { ...task, show: false };
            } else {
                return { ...task, show: true };
            }
        });
        const arrayConverted = [
            {
                operationName: item.operationName,
                tasks: arrayWithShowKey
            }
        ];
        setTasksArray(arrayConverted)
    }, [item])

    ////('tasksArray', tasksArray)





    return (
        <>
            <CustomModel
                show={open}
                onClose={() => hide()}
            >
                <div className='ppmodal' style={{ height: 'auto' }} data-testid='editModal'>
                    <div className='modelHead'>
                        <span style={{ color: '#000', fontWeight: '600' }}>In-House Operations</span>
                        <div>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<Edit />}
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    paddingTop: "5.5px",
                                    paddingBottom: "5.5px",
                                    fontSize: "12px", borderRadius: "8px", margin: "0px", marginRight: '10px'
                                }}
                            >
                                Edit
                            </Button>
                            <Button
                                variant="contained"
                                className='UM_delete-btn'
                                startIcon={<Delete />}
                                // onClick={() => {
                                //     setActionStatus("Delete")
                                //     setEditBlock(true)
                                // }}
                                sx={{ fontSize: "10px", borderRadius: "8px", margin: "0px", color: "black", border: "1px solid black" }}
                            > Delete User(s)
                            </Button>
                        </div>

                    </div>
                    <div className='modalBody' style={{ flexDirection: 'row', maxHeight: '170px', overflowY: 'auto', overflowX: 'hidden', border: '1px dashed #ff7200', flexWrap: 'wrap', height: '170px', marginTop: '15px', padding: '10px' }}>
                        {tasksArray && tasksArray.map((item) => {
                            return (
                                item.tasks.map((data, ind) => {
                                    return (
                                        <div className='pBoxVertical'>
                                            <div className="customOrangeBtn" style={{ marginBottom: '12px', position: 'relative', zIndex: '10', width: 'auto' }}>
                                                {data && data.taskNames}
                                            </div>
                                            {data.show === true ?
                                                <div className="doubleArr" >
                                                    <DoubleArrowIcon />
                                                </div>
                                                : ''}
                                        </div>
                                    )
                                })

                            )
                        })}
                    </div>
                </div>


            </CustomModel>
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />
        </>
    )
}

export default EditViewWorkFlow;
