import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid, Box, InputLabel, Select, MenuItem, FormControl, Button, Typography, Autocomplete, TextField, OutlinedInput,
    InputAdornment, Menu, Tabs, Tooltip, Tab, Switch, FormControlLabel,
} from "@mui/material";
import "../.././Style/GridView.css";
import {
    getCellStorageCapacity,
    getCellCapacityDetail,
    updateGridAccessbilityPreferences,
    getGridAccessbilityDetail,
    getGridAccessbilityList,
} from "../../../../../redux/actions";
import { getMappedArea } from "../../../../../redux/actions";
import { showToast, textCapitalize, renderOptionHandler, deepCopy_Object } from "../../../../../services/functions";
import {
    BorderColor,
    Cancel,
    KeyboardArrowDown,
    Search,
} from "@mui/icons-material";
import theme from "../../../../../static/styles/theme";
import { selectStylesOverride } from "../../../../../utils/util";
import AddIcon from '@mui/icons-material/Add';
import GridBoxIcon from "../../../../../static/images/Icon/GridDesign-pana.png";
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import {
    buttonClasses,
    inputClasses,
    textClasses,
} from "../../../../../static/styles/theme";
import AreaUtilizationStep1 from "./AreaUtilizationStep1";
import AreaAccessbility from "./AreaAccessbility";
import GenericTable from "../../../CustomTable/GenericTable";
import { useDebounce } from "../../../../../utils/hooks";
import GenericLoader from "../../../Generic/GenericLoader";
import AlertDialog from "../../../DialogBox/dialogbox";
import GenericToggle from "../../../GenericToggle/index";


const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

let initail_filter = {
    page: 1,
    limit: 10,
    areaName: "",
    areaType: "",
    fetch: false,
};

const dataRead = {
    "utilization": [
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total area is ",
                    "postfix": " sq.",
                    "key": "totalArea",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total usable area is ",
                    "postfix": " sq.",
                    "key": "usableArea",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Warehouse Capacity Is ",
                    "postfix": " cubic",
                    "key": "totalwarehouseCapacity",
                },
                {
                    "label": "",
                    "multiple": false,
                    "prefix": " ",
                    "postfix": "",
                    "key": "unit"
                }
            ]
        },
    ],
    "accessbility": [
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total Pick Not Allowed Location(s) ",
                    "postfix": "",
                    "key": "pickUpNotAllowed",
                }
            ]
        },
        {
            type: "input", renderOption: [
                {
                    "label": "",
                    "multiple": false,
                    "prefix": "Total Drop Not Allowed Location(s) ",
                    "postfix": "",
                    "key": "dropNotAllowed",
                },
            ]
        },
    ],
}

let tabList = [
    {
        "tabHeading": "Utilization matrix",
        "cardHeading": "Cell Dimensions & Area Analytics",
        "cardSubHeading": "Define area utilization matrix",
        "carddescription": "If your warehouse is presently operating at its maximum storage capacity, you're probably exploring methods to expandits storage capabilities.",
        "icon": "GridBoxIcon",
        "permission": ["addAreaUtilization", "viewAreaUtilization", "editAreaUtilization"],
        "buttonText": "Add Dimension",
        "buttonPermission": "addAreaUtilization",
        "tabKey": "utilization"
    },
    {
        "tabHeading": "Grid Accessbility",
        "cardHeading": "Grid Accessbility && Preferences",
        "cardSubHeading": "Identify and Prepare the Location",
        "carddescription": "Choose the rack, shelf, or bin that will be used for pick and drop operations. Ensure it is strategically placed for efficient access.",
        "icon": "GridBoxIcon",
        "permission": ["updateGridAccessbilityPreferences", "defineGridAccessibility", "getGridAccessbilityList",],
        "buttonText": "Select Location",
        "buttonPermission": "defineGridAccessibility",
        "tabKey": "accessbility"
    }
]

function AccessbilityAndUtilization(props) {

    const { warehouseInfo = {}, floorInfo = {} } = props
    const dispatch = useDispatch();
    const { cellCapacityDetail } = useSelector((state) => state.warehouseData);

    const { userPermissions = {} } = useSelector((state) => state.userPermission);

    const [tableData, setTableData] = useState({});
    const [filter, setFilter] = useState({
        ...initail_filter,
    });
    const [loader, setLoader] = useState("");
    const [showModel, setShowModel] = useState("");
    const [utiliZationID, setUtilizationID] = useState("");
    const [tabIndex, setTabIndex] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const debouncedSearchedAreaName = useDebounce(filter.areaName, 500);

    const areaTypeOption = [
        { label: "Block", value: "block" },
        // { label: "rack" },
        { label: "Shelves", value: "shelves" },
    ];

    let getTabList = () => {
        return tabList?.filter(info => {
            return userPermissions?.permissions?.some((dl) => info?.permission?.includes(dl?.permissionId))
        })
    }

    let tabKey = getTabList()?.at(tabIndex)?.tabKey || ""

    const handleToggle = (event, cellDetail = {}, rowIndex = 0) => {
        let name = event.target.name
        let checked = event.target.checked
        let payload = {
            "cellId": cellDetail?.cellId,
            "floorId": cellDetail?.floorId,
            "markAllDamaged": cellDetail?.isLocationDamaged == true,
            "updatePreferences": { ...cellDetail?.accessbilityPreferences || {} }
        }

        let tempData = deepCopy_Object(tableData || {})
        if (name == "isLocationDamaged") {
            payload["markAllDamaged"] = checked
            tempData["data"][rowIndex][name] = checked
        }
        else {
            payload["updatePreferences"][name] = {
                isDisabled: !checked
            }
            tempData["data"][rowIndex]["accessbilityPreferences"][name] = {
                isDisabled: !checked
            }
        }


        // console.log(payload,"payload")
        setLoader("row")
        dispatch(updateGridAccessbilityPreferences(payload, ({ success, message }) => {
            setLoader("")
            showToast(message, !success)
            if (success) {
                if (name == "pickUp") {
                    tempData.floorAccessbility.pickUpNotAllowed = Number(tempData?.floorAccessbility?.pickUpNotAllowed || 0) + (checked ? -1 : 1)
                }
                else if (name == "drop") {
                    tempData.floorAccessbility.dropNotAllowed = Number(tempData?.floorAccessbility?.dropNotAllowed || 0) + (checked ? -1 : 1)
                }

                // console.log(tempData)
                setTableData(tempData)
                // setFilter({ ...filter, fetch: false,})
                // fetchAreautilization(floorInfo,filter, tabIndex)
            }
        }))
    }


    const gridTableHeader = tabKey == "accessbility"
        ? [
            "Area Type",
            "Area Name",
            "Pick Up ",
            "Drop",
            "Tempareture Sensors",
            "Damaged",
        ]
        : [
            "Utilization ID",
            "Area Type",
            "Area Name",
            "Dimensions ",
            "Accepted Inventory Type",
            "Primary Capacity Matrix",
            "Entity Type & Max. Capacity ",

            ...((userPermissions?.permissions || []).find((dl) => dl.permissionId == "editAreaUtilization") ? ["Action"] : [])
        ];


    const gridTableRowData = tableData?.data?.map((cellData, index) => {
        const item = [];
        if (tabKey == "utilization") {
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                    }}
                >
                    {cellData?.utiliZationID}
                </Typography>
            );
        }
        item.push(
            <Typography
                sx={{
                    ...textClasses.normalText,
                    textTransform: "capitalize"
                }}
            >
                {cellData?.areaType}
            </Typography>
        );
        item.push(
            <Typography
                sx={{
                    ...textClasses.normalText,
                }}
            >
                {cellData?.cellName || (cellData?.cellDetail?.map((dl) => textCapitalize(dl.name))?.join(", ")) || "-"}
            </Typography>
        );
        if (tabKey == "accessbility") {
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textAlign: "center"
                    }}
                >
                    <GenericToggle
                        disbledName="'Not Allowed'"
                        enabledName="'Allowed'"
                        isEnable={!(cellData?.accessbilityPreferences?.pickUp?.isDisabled == true)}
                        handleToggle={(event) => handleToggle(event, cellData, index)}
                        name="pickUp"
                        width={100}
                        isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}

                    />
                </Typography>

            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textAlign: "center"
                    }}
                >
                    <GenericToggle
                        disbledName="'Not Allowed'"
                        enabledName="'Allowed'"
                        isEnable={!(cellData?.accessbilityPreferences?.drop?.isDisabled == true)}
                        handleToggle={(event) => handleToggle(event, cellData, index)}
                        name="drop"
                        width={100}
                        isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}

                    />
                </Typography>

            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textAlign: "center"
                    }}
                >
                    <GenericToggle
                        disbledName="'No'"
                        enabledName="'Yes'"
                        isEnable={!(cellData?.accessbilityPreferences?.temperatureSensors?.isDisabled == true)}
                        handleToggle={(event) => handleToggle(event, cellData, index)}
                        name="temperatureSensors"
                        width={60}
                        isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}

                    />
                </Typography>

            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textAlign: "center"
                    }}
                >
                    <GenericToggle
                        disbledName="'No'"
                        enabledName="'Yes'"
                        isEnable={cellData?.isLocationDamaged == true}
                        handleToggle={(event) => handleToggle(event, cellData, index)}
                        name="isLocationDamaged"
                        width={100}
                        isActionEnabledSwitch={(userPermissions?.permissions || []).some((dl) => dl.permissionId == "updateGridAccessbilityPreferences") && loader != "row"}

                    />
                </Typography>

            );

        }
        else {

            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                    }}
                >
                    {`${cellData?.dimension?.height} ${cellData?.dimension?.unit}  * ${cellData?.dimension?.length} ${cellData?.dimension?.unit} * ${cellData?.dimension?.width} ${cellData?.dimension?.unit}`}
                </Typography>
            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,

                    }}
                >
                    {cellData?.acceptedInventoryType
                        ?.map((dl) => textCapitalize(dl))
                        ?.join(", ") || "-"}
                </Typography>
            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textTransform: "capitalize"
                    }}
                >
                    {cellData?.primaryCapacityMatrix || "-"}
                </Typography>
            );
            item.push(
                <Typography
                    data-testid="dropdown"
                    sx={{
                        ...textClasses.t13n,
                        color: theme.themeOrange,
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                    onClick={(e) => handleViewData(e, index, cellData?.utiliZationID)}
                >
                    View
                </Typography>
            );
            if (cellData?.quantityCapacity?.quantity && cellData?.quantityCapacity?.unit) {
                item.push(
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                        }}
                    >{`${cellData?.quantityCapacity?.quantity} ${cellData?.quantityCapacity?.unit}`}</Typography>
                );
            }
            if ((userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization")) {
                item.push(
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.t13n,
                            color: theme.themeOrange,
                            cursor: "pointer",
                            textDecoration: "underline",
                        }}
                        onClick={(e) => {
                            setUtilizationID(cellData?.utiliZationID)
                            handleEditDimensions(cellData?.utiliZationID)
                        }}
                    >
                        Edit
                    </Typography>
                );
                // item.push(
                //     <Typography
                //         data-testid="dropdown"
                //         sx={{
                //             ...textClasses.t13n,
                //             color: theme.themeOrange,
                //             cursor: "pointer",
                //         }}
                //         onClick={(e) => handleOpenAction(e, index, cellData?.utiliZationID)}
                //     >
                //         Action <KeyboardArrowDown />
                //     </Typography>
                // );
            }
        }



        return item;
    });

    const detail = {}
    if (tabKey == "accessbility") {
        detail["dropNotAllowed"] = tableData?.floorAccessbility?.dropNotAllowed || "0";
        detail["pickUpNotAllowed"] = tableData?.floorAccessbility?.pickUpNotAllowed || "0";
    }
    else {
        detail["totalArea"] = tableData?.floorCapacity?.totalDimension?.length * tableData?.floorCapacity?.totalDimension?.width || "0";
        detail["nonUsableArea"] = tableData?.floorCapacity?.nonStorageDimension?.length * tableData?.floorCapacity?.nonStorageDimension?.width || "0";
        detail["usableArea"] = (Number(detail["totalArea"] || 0) - Number(detail["nonUsableArea"] || 0)) || "0";
        detail["unit"] = tableData?.floorCapacity?.totalDimension?.unit || "";
        detail["totalwarehouseCapacity"] = (Number(detail["usableArea"]) * tableData?.floorCapacity?.maxStackHeight?.height) || "0";
    }


    const handlTabChange = (tab_key, tab_Index = 0) => {
        setFilter({ ...initail_filter, fetch: false })
        setTabIndex(tab_Index)
        fetchAreautilization(floorInfo, initail_filter, tab_Index)
    }

    const handleOpenAction = (event, i, utiliZationID = "") => {
        setUtilizationID(utiliZationID);
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };
    const handleViewData = (e, index, utiliZationID = "") => {
        setUtilizationID(utiliZationID);
        setShowModel("showUtilizationDetail");
    };

    const handleAddDimensions = (tab_key) => {
        if (tab_key == "accessbility") {
            setShowModel(showModel ? "" : "addAccessbility");
        }
        else {
            setShowModel(showModel ? "" : "submitCapacity");
        }
    };
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };

    const handleEditDimensions = (ID="") => {
        setActionIndex(null);
        setShowModel(showModel ? "" : "updateCapacity");
        const payload = {
            floorId: floorInfo?.floorId,
            utiliZationID: !!ID? ID : utiliZationID,
            // updateUtilization:true
        };
        dispatch(
            getCellCapacityDetail(payload, (data) => {
            })
        );
    }

    const TabPanel = (panprops) => {
        const { children, value, index, ...other } = panprops;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2,pt:0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const callBack = (response) => {
        setTableData(response)
        setLoader("");
    }

    const fetchAreautilization = (floor = {}, temp_filter = {}, tab_Index) => {
        setTableData({})
        const payload = {
            floorId: floor?.floorId,
            page: temp_filter.page,
            limit: temp_filter.limit,
            areaName: temp_filter.areaName || "",
            areaType: temp_filter.areaType || "",
        };
        setLoader("table");
        let tabKey = getTabList()?.at(tab_Index)?.tabKey || ""
        if (tabKey == "accessbility") {
            dispatch(getGridAccessbilityList(payload, callBack));
        }
        else {
            dispatch(getCellStorageCapacity(payload, callBack));
        }

    };
    useEffect(() => {
        setFilter({ ...initail_filter, fetch: false })
        let tempTab = tabIndex
        if (getTabList()?.length - 1 < tabIndex) {
            setTabIndex(0)
            tempTab = 0
        }
        fetchAreautilization(floorInfo, initail_filter, tempTab)
    }, [floorInfo?.floorId])

    useEffect(() => {
        if (filter?.fetch) {
            fetchAreautilization(floorInfo, filter, tabIndex)
        }
    }, [filter?.page, filter?.limit, filter?.areaType, debouncedSearchedAreaName])

    return (
        <Box>
            <Box
                sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
            >
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    onChange={(event, tab_Index) => {
                        handlTabChange(event?.target?.name, tab_Index)
                    }}
                    sx={{
                        "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                        "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
                        "ml": 1,
                    }}

                >
                    {
                        getTabList()?.map((ele, index) => {
                            return <Tab
                                name={ele?.tabKey}
                                key={index}
                                data-testid={ele.tabHeading}
                                label={ele.tabHeading}
                                // label={`${ele.tabHeading} (${tableData?.count || 0})`}
                                {...a11yProps(index)}
                                sx={{ ...textClasses.normalText, textTransform: "none" }}
                                disabled={!!loader}
                            />

                        })
                    }
                </Tabs>
            </Box>

            {
                getTabList()?.map((ele, index) => {
                    return <>
                        <TabPanel
                            value={tabIndex} index={index}
                        >
                            {
                                (ele?.tabKey == "utilization" ? floorInfo?.isCapacityDefined : floorInfo?.isAccessbilityDefined)
                                &&
                                <>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",

                                        }}
                                    >
                                        {
                                            (dataRead[ele?.tabKey] || [])?.map((option, dataIndex) => {
                                                return <Grid key={dataIndex} item sx={{ ml: 1 }}>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.t14n,
                                                            textAlign: "center",
                                                            border: "1px solid #FF7200",
                                                            borderRadius: "8px",
                                                            bgcolor: "#ff720014",
                                                            padding: "10px",
                                                            color: "#FF7200",
                                                            fontWeight: "700",
                                                            // marginTop: "-60px",
                                                            mt: { sm: 1, md: 0 }
                                                        }}
                                                    >
                                                        {renderOptionHandler(detail, option?.renderOption || [])}
                                                    </Typography>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={2}
                                        className="mt-0 mb-2"
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Grid
                                            container
                                            item
                                            direction={"row"}
                                            justifyContent={"start"}
                                            xs={9}
                                        >
                                            <Grid item xs={3} sx={{ mr: 2 }}>
                                                <OutlinedInput
                                                    onChange={(e) =>
                                                        setFilter({
                                                            ...filter,
                                                            areaName: e?.target?.value || "",
                                                            fetch: true,
                                                            page: 1
                                                        })
                                                    }
                                                    size="small"
                                                    sx={{
                                                        ...selectStylesOverride,
                                                        width: "100%",
                                                        fontSize: "12px",
                                                        "& input::placeholder": {
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                    value={filter.areaName || ""}
                                                    placeholder="Search by area name"
                                                    endAdornment={
                                                        <InputAdornment>
                                                            <Search fontSize="small" />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3} sx={{ mr: 2 }}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    size="small"
                                                    onChange={(e, option) =>
                                                        setFilter({
                                                            ...filter,
                                                            page: 1,
                                                            areaType: option?.value || "",
                                                            fetch: true
                                                        })
                                                    }
                                                    value={
                                                        (filter.areaType &&
                                                            areaTypeOption.find((dl) => dl.value == filter.areaType)
                                                                ?.label) ||
                                                        ""
                                                    }
                                                    options={areaTypeOption}
                                                    sx={{ ...selectStylesOverride, width: "100%" }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="area type" />
                                                    )}
                                                />
                                            </Grid>
                                            {(filter.areaName || filter.areaType) && (
                                                <Grid item xs={2}>
                                                    <Button type="button">
                                                        <Typography
                                                            sx={{
                                                                ...textClasses.boldText,
                                                                color: theme.themeOrange,
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                                textTransform: "none",
                                                            }}
                                                            onClick={() => {
                                                                setFilter({ ...initail_filter, fetch: true });
                                                            }}
                                                        >
                                                            Clear Filter(s)
                                                        </Typography>
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>

                                        {(userPermissions?.permissions || []).find((dl) => dl.permissionId == ele?.buttonPermission) &&
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    spacing={1}
                                                    onClick={() => handleAddDimensions(ele?.tabKey)}
                                                    className="RF_sampledownload-btn"
                                                    sx={{
                                                        ...buttonClasses.lynkitBlackFill,
                                                        textTransform: "none",
                                                        textAlign: "start",
                                                        ...textClasses.t12n,
                                                        padding: "10px 10px",
                                                    }}
                                                    startIcon={<AddIcon />}
                                                >
                                                    {ele?.buttonText}
                                                </Button>
                                            </Grid>}
                                    </Grid>
                                </>}

                            {loader == "table" ? (
                                <Grid
                                    data-testid="loader"
                                    container
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems="center"
                                    sx={{ p: 15 }}
                                >
                                    <Grid item>
                                        <GenericLoader />
                                    </Grid>
                                </Grid>
                            ) : (
                                (ele?.tabKey == "utilization" ? floorInfo?.isCapacityDefined : floorInfo?.isAccessbilityDefined)
                                    ?
                                    <>
                                        <GenericTable
                                            header={gridTableHeader}
                                            rows={gridTableRowData}
                                            pageCount={tableData?.count}
                                            pageNumber={filter.page - 1}
                                            handleChangePage={(event, pageValue) => {
                                                setFilter({ ...filter, page: pageValue + 1, fetch: true });
                                            }}
                                            handleChangeRowsPerPage={(event) => {
                                                setFilter({ ...filter, limit: +event.target.value, fetch: true });
                                            }}
                                            rowsPerPage={filter.limit}
                                        />
                                        {actionIndex != null && (userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization") && (
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={actionIndex != null}
                                                onClose={handleCloseAction}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                                            >
                                                {(userPermissions?.permissions || []).some((dl) => dl.permissionId == "editAreaUtilization") && (
                                                    <MenuItem key={1} onClick={handleEditDimensions}>
                                                        Edit
                                                    </MenuItem>
                                                )}

                                            </Menu>
                                        )}
                                    </>
                                    :
                                    <Grid sx={{ width: "100%" }}
                                    >
                                        <Typography sx={{ ...textClasses.t16n, fontFamily: "Nunito", fontWeight: "700", marginBottom: "10px", }}
                                        >
                                            {ele?.cardHeading}
                                        </Typography>
                                        <Box
                                            sx={{ backgroundColor: "#ff720014", width: "100%", height: "185px", borderRadius: "8px", border: "1px solid #ff7200c2", }}
                                        >
                                            <Grid container spacing={2} className="ms-2 mt-2">
                                                <Grid item xs={2}>
                                                    <img src={GridBoxIcon} />
                                                </Grid>
                                                <Grid item xs={7.5}>
                                                    <Typography
                                                        sx={{ color: "#FF7200", fontSize: "30px", fontWeight: "700", }}
                                                    >
                                                        {ele?.cardSubHeading}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ color: "#FF7200", fontSize: "16px", fontWeight: "600", fontFamily: "Nunito", }}
                                                    >
                                                        {ele?.carddescription}
                                                    </Typography>
                                                </Grid>
                                                {(userPermissions?.permissions || []).some((dl) => dl.permissionId == ele?.buttonPermission) &&
                                                    <Grid item xs={2}>
                                                        <Button
                                                            variant="contained"
                                                            spacing={1}
                                                            onClick={() => handleAddDimensions(ele?.tabKey)}
                                                            className="RF_sampledownload-btn"
                                                            sx={{ ...buttonClasses.lynkitBlackFill, textTransform: "none", textAlign: "start", ...textClasses.t12n, padding: "10px 10px", }}
                                                            startIcon={<AddIcon />}
                                                        >

                                                            {ele?.buttonText}
                                                        </Button>
                                                    </Grid>}
                                            </Grid>
                                        </Box>
                                    </Grid>
                            )
                            }
                        </TabPanel>
                    </>
                })
            }
            
            {["submitCapacity", "updateCapacity"].includes(showModel) ? (
                <AreaUtilizationStep1
                    fetchAreautilization={() => {
                        setFilter({ ...initail_filter, fetch: false })
                        let tempTab = tabIndex
                        if (getTabList()?.length - 1 < tabIndex) {
                            setTabIndex(0)
                            tempTab = 0
                        }
                        fetchAreautilization(floorInfo, initail_filter, tempTab)
                    }}
                    floorInfo={floorInfo}
                    floorId={floorInfo?.floorId}
                    showModel={showModel}
                    setShowModel={setShowModel}
                    warehouseInfo={warehouseInfo}
                    cellCapacityDetail={cellCapacityDetail}

                />
            ) : (
                ""
            )}
            {["addAccessbility"].includes(showModel) ? (
                <AreaAccessbility
                    fetchAreautilization={() => {
                        setFilter({ ...initail_filter, fetch: false })
                        let tempTab = tabIndex
                        if (getTabList()?.length - 1 < tabIndex) {
                            setTabIndex(0)
                            tempTab = 0
                        }
                        fetchAreautilization(floorInfo, initail_filter, tempTab)
                    }}
                    floorInfo={floorInfo}
                    floorId={floorInfo?.floorId}
                    showModel={showModel}
                    setShowModel={setShowModel}
                    warehouseInfo={warehouseInfo}
                />
            ) : (
                ""
            )}
            {showModel == "showUtilizationDetail" && <ViewAreaUtilizationDetail
                showModel={showModel}
                setShowModel={setShowModel}
                cellCapacityDetail={cellCapacityDetail}
                utiliZationID={utiliZationID}
                setUtilizationID={setUtilizationID}
                floorInfo={floorInfo}
            />}
        </Box>
    );
}

export default AccessbilityAndUtilization;


let detail_Initail_filter = {
    page: 1,
    limit: 10,
    entityID: "",
    entityType: "",
};
const ViewAreaUtilizationDetail = (props) => {
    const dispatch = useDispatch();

    const {
        showModel = false,
        setShowModel,
        cellCapacityDetail,
        utiliZationID,
        floorInfo,
    } = props;
    const [dialogFilter, setdialogFilter] = useState({
        ...detail_Initail_filter,
    });
    const [dialogLoader, setDialogLoader] = useState(true);
    const entityTypeOption = [
        { label: "Loose Items", value: "looseItems" },
        { label: "box Or Packages", value: "boxOrPackages" },
    ];
    const header = [
        "Entity ID ",
        "Entity Type",
        "Max. QTY ",
        "Max Accepted Weight",
        // "Created By ",
        // "Created At ",
    ];

    const areaUtilizationTableRowData = cellCapacityDetail?.data?.map(
        (cellData, index) => {
            const item = [];
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                        textTransform: "capitalize"
                    }}
                >
                    {cellData?.SKU_ID || "-"}
                </Typography>
            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                    }}
                >
                    {cellData?.type && textCapitalize(cellData?.type) || "-"}
                </Typography>
            );
            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                    }}
                >
                    {cellData?.maxCount || "-"}
                </Typography>
            );

            item.push(
                <Typography
                    sx={{
                        ...textClasses.normalText,
                    }}
                >
                    {cellCapacityDetail?.capacityDetail?.weightCapacity?.maxWeight || "-"}
                </Typography>
            );
            return item;
        }
    );


    const debouncedSearchedentityID = useDebounce(dialogFilter.entityID, 500);
    useEffect(() => {
        const payload = {
            floorId: floorInfo?.floorId,
            utiliZationID: utiliZationID,
            page: dialogFilter.page,
            limit: dialogFilter.limit,
            entityID: dialogFilter.entityID,
            entityType: dialogFilter.entityType,
        };
        setDialogLoader(true);
        dispatch(
            getCellCapacityDetail(payload, (data) => {
                setDialogLoader(false);
            })
        );
    }, [
        debouncedSearchedentityID,
        dialogFilter.entityType,
        dialogFilter.page,
        dialogFilter.limit,
        utiliZationID,
    ]);

    const numberOfRows = Math.ceil(
        (cellCapacityDetail?.capacityDetail?.cellDetail?.length || 0) / 4
    );
    const cellRenderer = ({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
        const itemdata = cellCapacityDetail?.capacityDetail?.cellDetail?.map(
            (dl) => dl.name
        );
        const item = itemdata[index];
        return (
            <div style={{ ...style, padding: '1px' }}>
                {/* <CustomChip
          data={item || []}
          makeDisabled={false}
          handleDelete={""}
          readLabelKey={"$self"}
        /> */}
                {item && (
                    <div style={{ margin: '6px' }}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            sx={{
                                backgroundColor: theme.themeLightOrange,
                                border: `1px solid ${theme.themeOrange}`,
                                borderRadius: "12px",
                                pl: 1,
                                pr: 1,
                                minHeight: "32px",
                                ml: 2,
                                mr: 2
                            }}
                        >
                            <Grid item sm={10} xs={8} >
                                {item && item.length > 12 ? (
                                    <Tooltip arrow placement="top" title={item}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: theme.themeOrange,
                                                textAlign: "start",
                                            }}
                                        >
                                            {item && item.length > 20
                                                ? item.substring(0, 20) + "..."
                                                : item}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography
                                        sx={{
                                            ...textClasses.normalText,
                                            color: theme.themeOrange,
                                            textAlign: "start",
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                )}
                            </Grid>
                            {/* <Grid item xs={2} sx={{ textAlign: "end" }}>
                <IconButton sx={{ alignContent: "right", padding: '1px' }}
                // onClick={() => {handleRemoveitem(item)}} 
                // disabled={cellCapacityDetail?.length<2}
                ><Cancel sx={{ color: theme.themeOrange }} />
                </IconButton>
              </Grid> */}
                        </Grid>
                    </div>
                )}
            </div>
        );
    };
    const getBody = () => {
        return (
            <>
                <Box
                    sx={{
                        border: "2px solid #FF7200",
                        borderRadius: "8px",
                        padding: "0px 20px 10px 0",
                        marginBottom: "20px",
                        width: '100%',
                        height: '200px'
                    }}
                >
                    {
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <FixedSizeGrid
                                        columnCount={4} // Number of columns
                                        columnWidth={190} // Width of each column
                                        height={numberOfRows < 3 ? numberOfRows * 50 : 200} // Total height of the grid
                                        rowCount={numberOfRows}
                                        rowHeight={50} // Height of each row
                                        width={width} // Total width of the grid
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: '63vw',
                                            position: 'absolute',
                                            left: '1vw !important'
                                        }}
                                    >
                                        {cellRenderer}
                                    </FixedSizeGrid>
                                );
                            }}
                        </AutoSizer>
                    }
                </Box>
                <Grid
                    container
                    item
                    direction={"row"}
                    justifyContent={"start"}
                    xs={12}
                    mb={3}
                >
                    <Grid item xs={4} sx={{ mr: 2 }}>
                        <OutlinedInput
                            onChange={(e) =>
                                setdialogFilter({
                                    ...dialogFilter,
                                    entityID: e?.target?.value || "",
                                    page: 1
                                })
                            }
                            size="small"
                            sx={{
                                ...selectStylesOverride,
                                width: "100%",
                                fontSize: "12px",
                                "& input::placeholder": {
                                    fontSize: "14px",
                                },
                            }}
                            value={dialogFilter.entityID || ""}
                            placeholder="Entity ID"
                            endAdornment={
                                <InputAdornment>
                                    <Search fontSize="small" />
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ mr: 2 }}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            onChange={(e, option) =>
                                setdialogFilter({
                                    ...dialogFilter,
                                    page: 1,
                                    entityType: option?.value || "",
                                })
                            }
                            value={
                                (dialogFilter.entityType &&
                                    entityTypeOption.find(
                                        (dl) => dl.value == dialogFilter.entityType
                                    )?.label) ||
                                ""
                            }
                            options={entityTypeOption}
                            sx={{ ...selectStylesOverride, width: "100%" }}
                            renderInput={(params) => (
                                <TextField {...params} label="Entity Type" />
                            )}
                        />
                    </Grid>
                    {(dialogFilter.entityID || dialogFilter.entityType) && (
                        <Grid item xs={2}>
                            <Button type="button">
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        setdialogFilter({ ...detail_Initail_filter });
                                    }}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {dialogLoader ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{ p: 10 }}
                    >
                        <Grid item>
                            <GenericLoader />
                        </Grid>
                    </Grid>
                ) : (
                    <GenericTable
                        header={header}
                        rows={areaUtilizationTableRowData}
                        pageCount={cellCapacityDetail?.count}
                        pageNumber={dialogFilter.page - 1}
                        handleChangePage={(event, pageValue) => {
                            setdialogFilter({ ...dialogFilter, page: pageValue });
                        }}
                        handleChangeRowsPerPage={(event) => {
                            setdialogFilter({ ...dialogFilter, limit: +event.target.value });
                            // setFilter(+event.target.value);
                        }}
                        rowsPerPage={dialogFilter.limit}
                    />
                )}
            </>
        );
    };

    return (
        <>
            <AlertDialog
                viewDialog={showModel}
                sx={{ borderRadius: "50px" }}
                width="70%"
                title={
                    <Typography sx={{ fontWeight: "1000", fontSize: "24px" }}>
                        <span>Inventory Holding Capacity </span>
                    </Typography>
                }
                body={getBody()}
            >
                <Button
                    //   fullWidth
                    variant="outlined"
                    onClick={() => setShowModel(false)}
                    sx={{
                        ...buttonClasses.lynkitBlackFill,
                        m: 1,
                        fontSize: ".7rem",
                        minWidth: "100px",
                    }}
                >
                    Cancel
                </Button>
            </AlertDialog>{" "}
        </>
    );
};

