import {INIT_URL} from "../types";

const INIT_STATE = {
  // token: "",
  // initURL: '',
  // authUser: "",
  token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : "",
  initURL: '',
  authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case "DUMMY": {
        return {...state, name: action.payload}
    }

    // case SIGNOUT_USER_SUCCESS: {
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("shipmentID")
    //   localStorage.removeItem('user')
    //   return {
    //     ...state,
    //     token: null,
    //     authUser: null,
    //     initURL: ''
    //   }
    // }

    // // case USER_DATA: {
      
    // //   return {
    // //     ...state,
    // //     authUser: action.payload,
    // //   };
    // // }

    // case USER_TOKEN_SET: {
    //   // //("set token",action.user)
    //   return {
    //     ...state,
    //     token: action.payload,
    //     // user:action.user
    //   };
    // }
    // case GET_USER_PERMISSION: {
     
    //   return {
    //     ...state,
    //     authUser: action.payload,
    //   };
    // }

    default:
      return state;
  }
}