import { FETCH_TABLE_HEADER } from "../types";

    const INIT_STATE = {
    inventoryHeader: {},
    asnHeader: {},
    templateHeader: {},
    adjustmentHeader: {},
    dialogHeader: {}
  };
  
  export default (state = INIT_STATE, action) => {
    const tableName = action.payload && action.payload.tableName ? action.payload.tableName : ""
    switch (action.type) {

        case FETCH_TABLE_HEADER: 
            let newState = {...state}
            newState[tableName] = action.payload.data
            return newState
        default:
            return state;
    }
  }
  //chnage