import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, FETCH_TABLE_HEADER } from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getTableHeader = (tableName, payload, cb) =>{
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('/base/getTableHeaderConfig', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_TABLE_HEADER, payload: {tableName: tableName, data: data.data}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_TABLE_HEADER, payload: {tableName: tableName, data: {}}})
            }
            if(cb){
                cb(data?.data || null)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const updateTableHeader = (payload, cb) =>{
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('/base/updateTableHeaderConfig', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS }); 
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            if(cb){
                cb(data || null)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};