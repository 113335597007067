import {
    GET_ITEM_MASTER,
    FETCH_ITEMS_DETAIL,
    GET_MASTER_COUNT
} from "../types";

const intialState = {
    ItemMaster: [],
    ItemDetail: null,
    masterTemplateWiseCount:{}
};


const ItemMasterReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_ITEM_MASTER:
            return { ...state, ItemMaster: payload.rows, count: payload.count }

        case FETCH_ITEMS_DETAIL: return { ...state, ItemDetail: payload.rows }
        case GET_MASTER_COUNT: 
        // //("payload",payload);
        return { ...state, masterTemplateWiseCount: payload}
        default:
            return state;
    }
}
export default ItemMasterReducer;