import React from "react";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme, { buttonClasses } from "../../../static/styles/theme";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};
const FileModal = ({ openModal, displayUrl, setOpenModal }) => {
  const handleClose = () => setOpenModal(false);
  const hideModal = () => {
    setOpenModal(false);
  };
  const cancelHandler = () => {
    setOpenModal(false);
  };
  const handleDownload = () => {
    window.open(displayUrl, '_blank');

  };

  return (
    <>
      <div>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              style={{
                height: 400,
                width: "100%",
                cursor: "pointer",
              }}
              src={displayUrl}
            />
            <button
              onClick={cancelHandler}
              style={{
                position: "absolute",
                bottom: "7%",
                left: "4%",
                fontWeight: "300",
                fontSize: "12px",
                padding: "3px 17px 3px 17px",
                ...buttonClasses.lynkitBlackFill,
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleDownload}
              style={{
                position: "absolute",
                bottom: "7%",
                left: "14%",
                fontWeight: "300",
                fontSize: "12px",
                padding: "3px 15px 3px 15px",
                ...buttonClasses.lynkitOrangeFill,
              }}
            >
              Download
            </button>
            <CloseIcon
              sx={{
                position: "absolute",
                right: "2%",
                top: "2%",
              }}
              onClick={hideModal}
              className="crossIconPri"
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default FileModal;
