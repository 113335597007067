import React, { useEffect, useState } from "react"
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import { getSuperAdminCompanyData } from "../../../../redux/actions";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import { dateTimeFormater } from "../../../../services/functions";


const ViewWarehouseFloorModal = ({ viewModal, setViewModal }) => {
    const targetId = viewModal?.action;
    const originFrom = viewModal?.origin;
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState({
        data: [],
        count: ""
    })
    const [loader, setLoader] = useState(true);
    const [pagination, setPagination] = useState({ page: 0, limit: 10 })

    const statusList = [
        {
            label: "Active",
            value: "active",
            color: "#3E621E"
        },
        {
            label: "In-Active",
            value: "inActive",
            color: "#FF0101"
        }
    ]

    const getWarehouseFloorViewTableData = (page, limit) => {
        let payload = {
            [`${originFrom}Id`]: targetId,
            type: viewModal?.type == "viewWarehouses" ? "warehouse" : "floor",
            page: page,
            limit: limit
        }
        setLoader(true)
        dispatch(getSuperAdminCompanyData(payload, (data) => {
            if (data.success) {
                setLoader(false)
                setTableData({ data: data?.data, count: data?.count })
            }
        }))
    }
    const tableHeaders = viewModal?.type == "viewWarehouses" ? ["S.No", "Warehouse Name", "State", "Location", "Status", "Company Name", "Created On"] :
        ["S.No", "Floor Name", "Warehouse Name", "Rows", "Columns", "Status", "Company Name", "Created On"]
    const tableRows = tableData?.data?.map((row, index) => {
        let item = [];

        item.push(
            <Typography sx={{ ...textClasses.normalText }}>
                {(index + 1) + (pagination?.page * pagination?.limit)}
            </Typography>
        )

        item.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                {row?.name}
            </Typography>
        )
        if (viewModal?.type == "viewWarehouses") {

            item.push(
                <Typography sx={{ ...textClasses.normalText, }}>
                    {row?.formData?.state}
                </Typography>
            )
            item.push(
                <Typography sx={{ ...textClasses.normalText, }}>
                    {row?.formData?.registered_addr}
                </Typography>
            )
        } else {
            item.push(
                <Typography sx={{ ...textClasses.normalText }}>
                    {row?.warehouseName}
                </Typography>,
                <Typography sx={{ ...textClasses.normalText, }}>
                    {row?.row}
                </Typography>
            )
            item.push(
                <Typography sx={{ ...textClasses.normalText, }}>
                    {row?.col}
                </Typography>
            )

        }
        const statusObject = statusList.find((status) => status.value === row?.status);

        item.push(
            <Typography key={`status-${index}`} sx={{ ...textClasses.normalText, color: statusObject?.color || '#000' }}>
                {statusObject?.label || '-'}
            </Typography>
        );
        item.push(
            <Typography sx={{ ...textClasses.normalText }}>
                {row?.companyName}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.createdAt && dateTimeFormater(row?.createdAt) || '-'}
            </Typography>
        )
        return item;
    })

    useEffect(() => {
        getWarehouseFloorViewTableData(pagination?.page + 1, pagination?.limit)
    }, [pagination.page, pagination.limit])
    return (
        <>

            <AlertDialog
                viewDialog={viewModal?.type}
                handleClose={() => { setViewModal({ ...viewModal, type: '' }) }}
                // viewSearchInput={true}
                showDivider={true}
                width="70%"
                title={`All ${viewModal?.type == "viewWarehouses" ? "Warehouses" : "Floors"} Details`}
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            <Box sx={{ width: "inherit" }}>
                                <GenericTable
                                    data-testid="connected-components-table"
                                    header={tableHeaders}
                                    rows={tableRows}
                                    pageCount={tableData?.count || 0}
                                    pageNumber={pagination.page}
                                    handleChangePage={(event, pagevalue) => {
                                        setPagination({ ...pagination, page: pagevalue })
                                    }}
                                    handleChangeRowsPerPage={(event) => {
                                        setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                                    }}
                                    rowsPerPage={pagination.limit}
                                />
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ViewWarehouseFloorModal;