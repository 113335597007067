import React, { useEffect, useState } from "react"
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, OutlinedInput, FormControl, IconButton, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Clear, Search, Delete } from "@mui/icons-material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import GenericTable from "../../Components/CustomTable/GenericTable";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import TypeAndSearch from "../../Components/TypeAndSearch";
import { RFIDMapping } from "./Utils";
import { RFIDMultiMapTagging } from "../../../redux/actions";
import config from "../../../services/config";
import { showToast } from "../../../services/functions";
const RFIDMappingModal = ({ viewModal, setViewModal, sharedObj, searchTitle,clearFilter }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState(sharedObj?.formData?.SKU_ID)
  const [tableData, setTableData] = useState([])
  const [pagination, setPagination] = useState({ page: 0, limit: 10 })
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value)
    setPagination({ ...pagination, page: 0 })
  }
  const handleRowDelete = (index) => {
    // Calculate the global index based on the current page and limit
    const globalIndex = pagination.page * pagination.limit + index;

    setTableData(prevTableData => {
      const newData = prevTableData.filter((_, i) => i !== globalIndex);

      // Reset pagination if table becomes empty
      if (newData.length === 0) {
        setPagination({ ...pagination, page: 0 });
      } else {
        // Check if the current page becomes empty after deletion
        const maxPage = Math.floor((newData.length - 1) / pagination.limit);
        if (pagination.page > maxPage) {
          setPagination(prev => ({ ...prev, page: maxPage }));
        }
      }

      return newData;
    });
  };
  const handleMapRFIDTagSubmit =()=>{
    const payload = {
      assetID: sharedObj?.formData?.SKU_ID,
      assetType:"pallet",
      tagToAttach: tableData?.map((dl) => ({
        tag_tid: dl?.tId,
        tag_epc: dl?.epc,
        tagType:dl?.tagType,
        displayName:dl?.displayName
      }))
    };
    dispatch(RFIDMultiMapTagging(payload,(data={})=>{
      showToast(data.message, !data.success);
      if (data.success) {
        setTableData([])
        setViewModal("") 
        clearFilter()
      }
    }))
  }
  const tableHeaders = ["S.No", "Display Name", "TID", "EPC", "Action"]
  const tableRows = tableData?.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit)?.map((row, index) => {
    let item = [];
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {(index + 1) + (pagination?.page * pagination?.limit)}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.displayName}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText,  }}>
        {row?.tId}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.epc}
      </Typography>
    )
    item.push(
      <IconButton
        aria-label="delete"
        key={index}
        onClick={() => handleRowDelete(index)}
      >
        <Delete sx={{ color: "red" }} />
      </IconButton>,
    )

    return item;
  })
  return (
    <>

      <AlertDialog
        viewDialog={viewModal === "attachTag"}
        handleClose={() => { setViewModal("") }}
        viewSearchInput={true}
        showDivider={true}
        width="70%"
        title="RFID Mapping"
        actionsCSS={{ justifyContent: 'flex-start' }}
        searchInputDisplay={
          <FormControl variant="outlined" size="small" fullWidth sx={{ width: 290, }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ display: 'inline-block',  ...textClasses.t15nBold,width:120 }} >
              {searchTitle}:
            </Typography>
            <OutlinedInput
              data-testid="search-input"
              disabled={true}
              value={searchValue}
              onChange={handleSearchInputChange}
              sx={{
                ...inputClasses.filterField,
                "&:focus": {
                  borderColor: theme.themeOrange,
                },
                "&:before": {
                  borderColor: theme.themeOrange,
                },
                "&:after": {
                  borderColor: theme.themeOrange,
                },
                "& input::placeholder": { fontSize: "14px" },
              }}
              placeholder={`Search By ${searchTitle}`}
              endAdornment={
                searchValue == ""
                  ? (
                    <InputAdornment position="end">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ) : (
                    <IconButton
                      sx={{
                        visibility: searchValue != "" ? "visible" : "hidden",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setSearchValue("")
                      }}
                    >
                      <Clear fontSize="inherit" />
                    </IconButton>
                  )
              }
            />
            </Box>
          </FormControl>
        }
        body={
          <>
            {loader ?
              <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                <Grid item><GenericLoader /></Grid>
              </Grid> :
              <Grid container>
                <Grid item xs={6} sx={{ mb: 2 }}>
                  <TypeAndSearch
                    data-testid="RFIDMappingFilter"
                    // disabled={!selectedData?.inventoryType["value"] }
                    // fullWidth
                    component={RFIDMapping(sharedObj?._id, config?.lynkID)}
                    value={""}
                    set_Value={true}
                    setEmptyOnChange={true}
                    handleChange={(event, option) => {
                      if (event.target.value != "") {
                        if(!tableData?.find(dl=>dl?.epc==option?.epc)){
                          setTableData((prevData) => [option,...prevData]);
                        }
                        else{
                          showToast("Tag already exists",true)
                        }
                
                      }
                    }}
                  />
                </Grid>
                <Box sx={{ width: "inherit" }}>
                  {tableData?.length>0 && 
                  <GenericTable
                    data-testid="RfidMapping-table"
                    header={tableHeaders}
                    rows={tableRows|| []}
                    pageCount={tableData?.length}
                    pageNumber={pagination.page}
                    handleChangePage={(event, pagevalue) => {
                      setPagination({ ...pagination, page: pagevalue })
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                    }}
                    rowsPerPage={pagination.limit}
                  />
                  }
                </Box>
              </Grid>
            }
          </>
        }

      >
        <Button
          variant="contained"
          data-testid="cancel-btn"
          size="small"
          onClick={() => { setViewModal("") }}
          sx={{ ...buttonClasses.lynkitBlackFill, m: 1, minWidth: "130px" }}
        >Cancel
        </Button>
        <Button
          data-testid="submit-btn"
          variant="contained"
          size="small"
          // disabled={tableData?.length <= 0 || tableData?.some(dl => !dl?.count) || dataReceieved}
          sx={{ ...buttonClasses.lynkitOrangeFill, width: '180px' }}
        onClick={handleMapRFIDTagSubmit}
        >
          Map RFIDs
        </Button>
      </AlertDialog>
    </>
  );
}

export default RFIDMappingModal;