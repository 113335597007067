//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const INIT_URL = "INIT_URL";
export const RESET_STORE  = "RESET_STORE ";

//template functions
export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const FETCH_STD_COMPS = "FETCH_STANDARD_COMPS";
export const FETCH_PRV_COMPS = "FETCH_PREVIOUS_COMPS";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const FETCH_TEMPLATES_ERR = "FETCH_TEMPLATES_ERR";
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const DISABLE_TEMPLATES = "DISABLE_TEMPLATES";
export const PUBLISH_TEMPLATES = "PUBLISH_TEMPLATES";
export const DELETE_TEMPLATES = "DELETE_TEMPLATES";

// Create Template Action types

export const FETCH_ALL_TEMPLATE_TYPES = "FETCH_ALL_TEMPLATE_TYPES";
export const INIT_CANVAS = "INIT_CANVAS";
export const REPLACE_EDIT_CANVAS = "REPLACE_EDIT_CANVAS";
export const ADD_SECTION = "ADD_SECTION";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const ADD_FIELD = "ADD_FIELD";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const SET_EDIT_FIELD = "SET_EDIT_FIELD";
export const REMOVE_EDIT_FIELD = "REMOVE_EDIT_FIELD";
export const EDIT_FIELD = "EDIT_FIELD";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const SELECT_SUB_TEMPLATE = "SELECT_SUB_TEMPLATE";
export const SET_ADDRESS_BOOK_FIELD = "SET_ADDRESS_BOOK_FIELD"

export const TEMPLATED_CREATEDBY = "TEMPLATED_CREATEDBY";
export const FETCH_TEMPLATES_TYPE = "FETCH_TEMPLATES_TYPE";
export const TEMPLATE_STATUS = "TEMPLATE_STATUS";
export const FETCH_STATUS_TYPE = "FETCH_STATUS_TYPE";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_STATUS_COUNT = "FETCH_STATUS_COUNT";
export const FETCH_REGEX_LIST = "FETCH_REGEX_LIST";

// For ASN
export const FETCH_SUPPLIER_MASTER_TEMPLATES = "FETCH_SUPPLIER_MASTER_TEMPLATES";
export const FETCH_CUSTOMER_MASTER_TEMPLATES = "FETCH_CUSTOMER_MASTER_TEMPLATES";
export const FETCH_ITEM_MASTER_TEMPLATES = "FETCH_ITEM_MASTER_TEMPLATES";
export const SELECT_ITEM_MASTER = "SELECT_ITEM_MASTER";

//template actions
export const TEMPLATE_EDIT = "TEMPLATE_EDIT";
export const TEMPLATE_REMOVE = "TEMPLATE_REMOVE";

//data entry
export const DATA_ENTRY_FILTER = "DATA_ENTRY_FILTER";
export const DATA_ENTRY_COMPANY = "DATA_ENTRY_COMPANY";
export const DATA_ENTRY_FLOOR = "DATA_ENTRY_FLOOR";
export const FETCH_WAREHOUSES = "FETCH_WAREHOUSES";
export const FETCH_FLOORS = "FETCH_FLOORS";
export const FETCH_MAPPING = "FETCH_MAPPING";

// UI related
export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";

// Inward Data Entry
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";
export const FETCH_ITEM_MASTER_FOR_INWARD = "FETCH_ITEM_MASTER_FOR_INWARD";

// job
export const FETCH_ALL_JOBS = "FETCH_ALL_JOBS";
export const RESET_ALL_JOBS = "RESET_ALL_JOBS";
export const FETCH_JOB_DETAIL = "FETCH_JOB_DETAIL";
export const FETCH_CELL_RACK_LOCATION = "FETCH_CELL_RACK_LOCATION";
export const FETCH_WAREHOUSE_FLOORS = "FETCH_WAREHOUSE_FLOORS";
export const FETCH_TEMPLATE_HEADERS = "FETCH_TEMPLATE_HEADERS";
export const FETCH_JOB_TYPES = "FETCH_JOB_TYPES";
export const FETCH_JOB_TYPES_COUNT = "FETCH_JOB_TYPES_COUNT";
export const FETCH_ALL_WORKERS = "FETCH_ALL_WORKERS";
export const FETCH_SHIPMENTS = "FETCH_SHIPMENTS";
export const CREATE_GATE_IN_JOB = "CREATE_GATE_IN_JOB";
export const FETCH_ALL_JOB_ACTION = "FETCH_ALL_JOB_ACTION";
export const FETCH_JOB_STATUS_COUNT = "FETCH_JOB_STATUS_COUNT";
export const FETCH_JOB_LOCATION_DETAIL = "FETCH_JOB_LOCATION_DETAIL";
export const FETCH_JOB_PENDENCY_DETAIL = "FETCH_JOB_PENDENCY_DETAIL";
export const FETCH_JOB_PROCESS_CONFIG = "FETCH_JOB_PROCESS_CONFIG";
export const GENERATE_DISPATCH_ORDER_NUMBER = "GENERATE_DISPATCH_ORDER_NUMBER";
export const FETCH_WORK_ORDER_LIST_CONFIG = "FETCH_WORK_ORDER_LIST_CONFIG"
export const FETCH_JOB_SHIPMENT_DETAIL = "FETCH_JOB_SHIPMENT_DETAIL"

// Inventory
export const FETCH_ALL_INVENTORY = "FETCH_ALL_INVENTORY";
export const FETCH_INVENTORY_FOR_MODAL = "FETCH_INVENTORY_FOR_MODAL";
export const FETCH_INVENTORY_TYPE_COUNT = "FETCH_INVENTORY_TYPE_COUNT";
export const FETCH_INVENTORY_STATUS_COUNT = "FETCH_INVENTORY_STATUS_COUNT";
export const FETCH_SHIPMENT_DETAIL = "FETCH_SHIPMENT_DETAIL";
export const FETCH_PACKAGE_DETAIL = "FETCH_PACKAGE_DETAIL";
export const FETCH_VALIDATE_INVENTORY ="FETCH_VALIDATE_INVENTORY"
export const FETCH_JOB_CREATION = "FETCH_JOB_CREATION"
export const FETCH_ITEM_DETAIL = "FETCH_ITEM_DETAIL";
export const FETCH_PACKAGE_ITEM_DETAIL = "FETCH_PACKAGE_ITEM_DETAIL";
export const FETCH_TEMPLATE_HEADER = "FETCH_TEMPLATE_HEADER";
export const FETCH_INVENTORY_FOR_LABEL = "FETCH_INVENTORY_FOR_LABEL";
export const FETCH_ALL_TAGS = "FETCH_ALL_TAGS";
export const FETCH_USED_TAGS = "FETCH_USED_TAGS"
export const ALLOCATE_TAG = "FETCH_ALL_TAG";
export const DEALLOCATE_TAG = "FETCH_ALL_TAG";
export const FETCH_PARCEL_DIEMENSION_DATA = "FETCH_PARCEL_DIEMENSION_DATA"


// fetch inventory data
export const FETCH_INVENTORY_TABLE_HEADER = "FETCH_INVENTORY_TABLE_HEADER"
export const UPDATE_INVENTORY_TABLE_HEADER = "UPDATE_INVENTORY_TABLE_HEADER"
export const FETCH_INVENTORIES = "FETCH_INVENTORIES";
export const FETCH_INVENTORY = "FETCH_INVENTORY";
export const FETCH_INVENTORY_ERR = "FETCH_INVENTORY_ERR";

//inventory data filters
export const FETCH_INV_WAREHOUSE = "FTECH_INV_WAREHOUSE";
export const FETCH_INV_FLOOR = "FETCH_INV_FLOOR";
export const FETCH_INV_TIME = "FETCH_INV_TIME";
export const FETCH_INV_SHIPID = "FETCH_INV_SHIPID";
export const FETCH_INV_MAPPINGAREA = "FETCH_INV_MAPPINGAREA";
export const FETCH_INV_STATUS = "FETCH_INV_STATUS";
export const FETCH_INV_TYPESHIP = "FETCH_INV_TYPESHIP";
export const FETCH_INV_TYPEPACK = "FETCH_INV_TYPEPACK";
export const FETCH_INV_TYPEITEM = "FETCH_INV_TYPEITEM";
export const FETCH_PARCEL_DIEMENSION = "FETCH_PARCEL_DIEMENSION";

// inv filter for typeWise count
export const FETCH_INV_WAREHOUSE_COUNT = "FETCH_INV_WAREHOUSE_COUNT";
export const FETCH_INV_FLOOR_COUNT = "FETCH_INV_FLOOR_COUNT";

// Inventory Configuration
export const FETCH_INVENTORY_SETTINGS = "FETCH_INVENTORY_SETTINGS"
export const CHANGE_INVENTORY_SETTINGS = "CHANGE_INVENTORY_SETTINGS"


// Table Header configuration
export const FETCH_TABLE_HEADER = "FETCH_TABLE_HEADER"
export const UPDATE_TABLE_HEADER = "UPDATE_TABLE_HEADER"


// main dashboard
export const GET_ALL_ANALYTICS = "GET_ALL_ANALYTICS";
export const GET_ZONE_USER = "GET_ZONE_USER";
export const TRACK_USER = "TRACK_USER";
export const GET_DASHBOARD_DATA="GET_CHART_DATA";
export const GET_WAREHOUSE_OPRATION="GET_WAREHOUSE_OPRATION"
export const  GET_WAREHOUSE_INVENTORY = "GET_WAREHOUSE_INVENTORY";
export const GET_REPORT = "GET_REPORT"
export const GET_HEADER = "GET_HEADER"
export const GET_ITEM_VISE_REPORT="GET_ITEM_VISE_REPORT"
// // inv for geeting template
// export const FETCH_TEMPLATE_HEADER = "FETCH_TEMPLATE_HEADER";



//template table
export const GET_TEMPLATE_FETCH = "GET_TEMPLATE_FETCH"
export const GET_TEMPLATE_SUCC = "GET_TEMPLATE_SUCC"
export const GET_TEMPLATE_ERR = "GET_TEMPLATE_ERR"

export const GET_ITEM_MASTER = "GET_ITEM_MASTER"
export const FETCH_ITEMS_DETAIL = "FETCH_ITEMS_DETAIL"

// Return Process
export const FETCH_STOCK_RETURN_LIST = "FETCH_STOCK_RETURN_LIST"
export const FETCH_STOCK_DATA = "FETCH_STOCK_DATA"
export const ADD_STOCK_RETURN = "ADD_STOCK_RETURN"



// Inventory Adjustments
export const FETCH_ADJUSTMENT_REASONS = "FETCH_ADJUSTMENT_REASONS";
export const CREATE_ADJUSTMENT_REASON = "CREATE_ADJUSTMENT_REASON";
export const FETCH_ALL_INVENTOR_ADJUSTMENTS = "FETCH_ALL_INVENTOR_ADJUSTMENTS";
export const FETCH_ADJUSTMENT_DETAILS = "FETCH_ADJUSTMENT_DETAILS";
export const CREATE_INVENTORY_ADJUSTMENT = "CREATE_INVENTORY_ADJUSTMENT";
export const FETCH_ADJUSTMENT_STATUS_COUNT = "FETCH_ADJUSTMENT_STATUS_COUNT"


// Advance shipment notice
export const FETCH_ADVANCE_NOTICE_SHIPMENT = "FETCH_ADVANCE_NOTICE_SHIPMENT"
export const EDIT_ADVANCE_NOTICE_SHIPMENT = "EDIT_ADVANCE_NOTICE_SHIPMNET"
export const VIEW_ADVANCE_NOTICE_SHIPMENT = "VIEW_ADVANCE_NOTICE_SHIPMENT"
export const REJECT_ADVANCE_NOTICE_SHIPMENT = "REJECT_ADVANCE_NOTICE_SHIPMENT"
export const FETCH_ASN_STATUS_COUNT = "FETCH_ASN_STATUS_COUNT"
export const GENERATE_PO_NUMBER = "GENERATE_PO_NUMBER"

// Sales Order Processing 
export const FETCH_SALES_ORDER_PROCESSING = "FETCH_SALES_ORDER_PROCESSING"
export const FETCH_SALES_DETAIL ="FETCH_SALES_DETAIL"
export const FETCH_SALES_STATUS_COUNT ="FETCH_SALES_STATUS_COUNT"
export const FETCH_SALES_ORDER_ITEM_LIST = "FETCH_SALES_ORDER_ITEM_LIST"
export const GENERATE_SO_NUMBER = "GENERATE_SO_NUMBER"
export const FETCH_ESOPFORM_TABLE_DATA = "FETCH_ESOPFORM_TABLE_DATA"
export const FETCH_PASSLINK_VALIDATION_TOKEN = "FETCH_PASSLINK_VALIDATION_TOKEN"
export const FETCH_ASSOCIATED_JOB = "FETCH_ASSOCIATED_JOB"
export const FETCH_ASSOCIATED_TRIP = "FETCH_ASSOCIATED_TRIP"


// Landing Form
export const FETCH_EXTERNAL_FORM_CONFIG = "FETCH_EXTERNAL_FORM_CONFIG"
export const FETCH_E_SALES_ORDER_PROCESSING = "FETCH_E_SALES_ORDER_PROCESSING"
export const FETCH_E_SALES_STATUS_COUNT = "FETCH_E_SALES_STATUS_COUNT"
export const FETCH_E_SALES_DETAIL = "FETCH_E_SALES_DETAIL"

// Entity Master
export const GET_MASTER_COUNT = "GET_MASTER_COUNT"

// KIt Master
export const FETCH_CONNECTED_SKU_DETAIL = "FETCH_CONNECTED_SKU_DETAIL"

//Route navigation
export const GET_NAVIGATION_ROUTE = "GET_NAVIGATION_ROUTE"

// Warehouse capacity
export const GET_WAREHOUSE_CAPACITY ="GET_WAREHOUSE_CAPACITY"

// Cell Capacity
export const GET_CELL_CAPACITy="GET_CELL_CAPACITY"
export const GET_CELL_STORAGE_CAPACITY ='GET_CELL_STORAGE_CAPACITY '
export const GET_CELL_CAPACITY_DETAIL ="GET_CELL_CAPACITY_DETAIL"
export const GET_UTILIZATION_ANALYTICS_BLOCK = "GET_UTILIZATION_ANALYTICS_BLOCK"
export const GET_UTILIZATION_ANALYTICS_RACK = "GET_UTILIZATION_ANALYTICS_RACK"

// Cell Accessbility
export const GET_CELL_ACCESSBILITY_LIST="GET_CELL_ACCESSBILITY_LIST"
export const GET_CELL_ACCESSBILITY_DETAIL ="GET_CELL_ACCESSBILITY_DETAIL"


// For ASN Entry
export const CLEAR_CONNECTED_MASTER_DETAIL = "CLEAR_CONNECTED_MASTER_DETAIL";
export const FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL = "FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL"
export const FETCH_ORDER_CONNECTED_MASTER_DETAIL = "FETCH_ORDER_CONNECTED_MASTER_DETAIL"
export const FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES = "FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES"
export const FETCH_ORDER_CONNECTED_MASTER_ENTRIES = "FETCH_ORDER_CONNECTED_MASTER_ENTRIES"

export const FETCH_SHIPMENT_TEMPLATE_HEADER = "FETCH_SHIPMENT_TEMPLATE_HEADER";
export const SAVE_ASN_ENTRY = "SAVE_ASN_ENTRY"
export const EDIT_ASN_ENTRY = "EDIT_ASN_ENTRY"
export const FETCH_ASN_DETAIL = "FETCH_ASN_DETAIL";


///dum
export const DUMMY_FETCH_TEMPLATE_LIST = "DUMMY_FETCH_TEMPLATE_LIST"
export const DUMMY_FETCH_TEMPLATE_DETAIL = "DUMMY_FETCH_TEMPLATE_DETAIL"

export const DUMMY_FETCH_INVENTORY_LIST = "DUMMY_FETCH_INVENTORY_LIST"
export const DUMMY_FETCH_INVENTORY_DETAIL = "DUMMY_FETCH_INVENTORY_DETAIL"

export const DUMMY_FETCH_SHIP_DETAIL = "DUMMY_FETCH_SHIP_DETAIL"

// UOM

export const FETCH_ALL_UOM = "FETCH_ALL_UOM";
export const ADD_UOM = "ADD_UOM";
export const CHANGE_USN_MODAL_STATUS = "CHANGE_USN_MODAL_STATUS";

//Super Admin
export const GENERATE_QR = "GENERATE_QR";
export const GENERATE_EXCEL ="GENERATE_EXCEl"
export const FETCH_ALL_COMPANY = "FETCH_ALL_COMPANY"
export const FETCH_COMPANY_DETAIL = "FETCH_COMPANY_DETAIL"
export const FETCH_COMPANY_STATUS_COUNT = "FETCH_COMPANY_STATUS_COUNT"
export const FETCH_COMPANY_TYPE_WISE_COUNT = "FETCH_COMPANY_TYPE_WISE_COUNT"
export const FETCH_SUPERADMIN_USERS_PERMISSIONS = "FETCH_SUPERADMIN_USERS_PERMISSIONS"
export const FETCH_SUPERADMIN_USERS_DETAIL = "FETCH_SUPERADMIN_USERS_DETAIL"

// Indentifier Configuration
export const GET_IDENTIFIER_SETTING = "GET_IDENTIFIER_SETTING"


// Label Configuration
export const FETCH_LABEL_CONFIGRATION = "FETCH_LABEL_CONFIGRATION"

export const CHECK_DISTINCT_MASTER_EXIST = "CHECK_DISTINCT_MASTER_EXIST"

export const GET_MAPPED_MASTER_IDS = "GET_MAPPED_MASTER_IDS"

export const FLUSH_MAPPED_IDS = "FLUSH_MAPPED_IDS"

// Area Mapping

export const GET_AREA_MAPPING_DATA = "GET_AREA_MAPPING_DATA"

export const GENERATE_JOB_DETAIL = "GENERATE_JOB_DETAIL"
export const GET_USERS_TO_ASSIGN_JOB = "GET_USERS_TO_ASSIGN_JOB"


//KIT

export const FETCH_KIT = "FETCH_KIT"
export const FETCH_KIT_STATUS_COUNT= "FETCH_KIT_STATUS_COUNT"
export const FETCH_KIT_DETAIL = "FETCH_KIT_DETAIL";

// DEKIT

export const FETCH_ALL_DEKIT = "FETCH_ALL_DEKIT"
export const FETCH_DEKIT_STATUS_COUNT= "FETCH_DEKIT_STATUS_COUNT"
export const FETCH_DEKIT_DETAIL= "FETCH_DEKIT_DETAIL"


// DYNAMIC PAGE CONFIG

export const FETCH_DYNAMIC_PAGE_CONFIG= "FETCH_DYNAMIC_PAGE_CONFIG"


//User
export const FETCH_USER_PRODUCTIVITY= "FETCH_USER_PRODUCTIVITY"
export const FETCH_USERS_TYPE_WISE_COUNT= "FETCH_USERS_TYPE_WISE_COUNT"
