

export const getItemPackageComponent=(attributes,targetComponent = {})=>{

    let onChangeSet = attributes?.map(a => a._id)?.reduce((acc, curr) => {
        if(!["count", "quantity","totalQuantity","KIT_Quantity","mappingArea","USN_Value"].includes(curr)){
            let endKey=curr
            if(["SKU_Number", "packageName"].includes(curr)){
                endKey="SKU_ID"
            }
            else if(["SKU_Name", "packageDescription"].includes(curr)){
                endKey="SKU_Detail"
            }
            acc[curr] = `$response.formData.${endKey}`
        }
        return acc
    }, {})

    const component = {
        _id: targetComponent._id,
        type: "select",
        icon: "simple-icon-note",
        label: targetComponent.label || "",
        description: targetComponent.description || "",
        placeholder: targetComponent.placeholder || "",
        className: "form-control",
        subtype: "text",
        regex: "",
        handle: true,
        forType: "all",
        elementType: "Selection Type",
        value: "",
        required:  targetComponent.required || false,
        regex:  targetComponent.regex || "",
        dynamic: true,
        auth: true,
        "elementType" : "Selection Type",
        "keyForValue" : "label",
        "keyForLabel" : "value",
        "apiDomain" : "/v1/",
        "apiEndPoint" : "inventory/getDistinctMasterList",
        "forType" : "all",
        "apiMethod" : "post",
        "name" : "Item Master List",
        "apiDataReturnKey" : "data",
        "multipleAPICall" : true,
        "apiParameters" : [
            {
              label: "isSkipTemplate",
              value: true,
            },
            {
              label: "master_Type",
              value: "item-master",
            },
            {
              label: "page",
              value: 1,
            },
            {
              label: "limit",
              value: 10,
            },
            {
              label: "base_ID",
              value: "onSearch",
            },
            {
              label: "dataEntryType",
              value: targetComponent._id=="SKU_Number"?"item":"package",
            },
        ],
        "setTargetValue" : true,
        "targetAPI" : "scan/scanEntityMaterData",
        "targetAPIMethod" : "post",
        "targetParameters" : [
            {
                "label" : "base_ID",
                "value" : "$self.value"
            },
            {
                "label" : "master_Type",
                "value" : "item-master"
            },
            {
                "label": "dataEntryType",
                "value":  targetComponent._id=="SKU_Number"?"item":"package",
            }
        ],
        "targetApiDataReturnKey" : "data",
        "values" : [

        ],
        "fetched" : false,
        "multiple" : false,
        "hideLabel" : false,
        "delete" : false,
        "onChangeSet" : onChangeSet
      };

    return component
}
export const getPalletComponent=()=>{

    const component = {
        _id: "pallet_ID",
        type: "select",
        icon: "simple-icon-note",
        label: "Select Pallet",
        description: "Select Pallet",
        placeholder: "Select Pallet",
        className: "form-control",
        subtype: "text",
        regex: "",
        handle: true,
        forType: "all",
        elementType: "Selection Type",
        value: "",
        required: false,
        dynamic: true,
        auth: true,
        "elementType" : "Selection Type",
        "keyForValue" : "label",
        "keyForLabel" : "value",
        "apiDomain" : "/v1/",
        "apiEndPoint" : "inventory/getDistinctMasterList",
        "forType" : "all",
        "apiMethod" : "post",
        "name" : "Item Master List",
        "apiDataReturnKey" : "data",
        "multipleAPICall" : true,
        "apiParameters" : [
            {
              label: "isSkipTemplate",
              value: true,
            },
            {
              label: "master_Type",
              value: "item-master",
            },
            {
              label: "page",
              value: 1,
            },
            {
              label: "limit",
              value: 10,
            },
            {
              label: "base_ID",
              value: "onSearch",
            },
            {
              label: "dataEntryType",
              value:"pallet",
            },
        ],
       
        "values" : [

        ],
        "fetched" : false,
        "multiple" : false,
        "hideLabel" : false,
        "delete" : false,
      };

    return component
}