import React, { useEffect, useState } from 'react'
import { Modal, Typography, Grid, Button, Card, TextField, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel, IconButton,
    Table, TableBody, TableCell,FormLabel, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent
 } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { removeEditField, saveField } from '../../../redux/actions';
import { useForm } from 'react-hook-form';
import theme, { inputClasses, textClasses } from '../../../static/styles/theme';
import { Add, Remove } from '@mui/icons-material';
import { showToast } from '../../../services/functions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default function EditFieldModal({editField, allRegex}) {
    
    const components = useSelector(state => state.canvas.components);
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate);
    ////("Edit Field", editField, selectedSubTemplate);
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue } = useForm();
    const fieldsSection = editField ? components[editField.sectionId][editField.type] : null;
    let field = null;
    if(fieldsSection){
        
        if(editField.type == "mandatory"){
            
            field = fieldsSection.filter(f => f.templateType == selectedSubTemplate)
            if(field.length > 0){
                field = field[editField.index];
            }
        }
        else{
            field = fieldsSection[editField.index];
        }
    }
    // const field = fieldsSection && editField.sectionId .filter(f => f.templateType == selectedSubTemplate)[editField.index] : null;
    const [required, setRequired] = useState(false);
    const [state, setState] = useState({
        isForBarcode:false,
        showInLabel:false
    });
    // const [isForBarcode, setIsForBarcode] = useState(false);
    const [regex, setRegex] = useState("-")
    const [options, setOptions] = useState([]);

    const handleOptionChange = (e, type, i) => {
        let oldOptions = [...options];
        oldOptions[i][type] = e.target.value;
        setOptions(oldOptions);
    }

    const filterRegex=(type,regexList=[])=>{
        if(type=="number") return regexList.filter(r=>r.regexType=="number")
        return regexList
    }

    const handleOptionAdd = () => {
        const newOption = {
            label: `Option ${options.length + 1}`,
            value: `option ${options.length + 1}`,
        }
        let oldOptions = [...options, newOption];
        setOptions(oldOptions);
    }

    const handleOptionDelete = (i) => {
        let oldOptions = [...options];
        oldOptions.splice(i, 1)
        setOptions(oldOptions);
    }
    
    const handleRequiredChange = (e) => {
        setRequired(!required);
    }

    const handleQrChange = (key,e) => {
        setState({...state,[key]:e.target.checked});
    }

    const handleCloseModal = () => {
        dispatch(removeEditField())
    }

    const validateOptions = (options) => {
        let status = true;
        let message = "";
        if(!options || options.length <= 1){
            status = false;
            message = "Alteast 2 options are needed"
        }
        for (let i = 0; i < options.length; i++) {
            const e = options[i];
            if(!e.label || e.label == "" || !e.value || e.value == ""){
                status = false;
                message = "Label & Value of option cannot be blank"
                break;
            }
            
        }
        return {status, message}
    }

    const handleSave = (data) => {
        let editData = {
            ...data,
            required: required,
        };
        if(["itemMaster","inwardDataEntry","ASNTemplate"].includes(selectedSubTemplate)){
            editData['isForBarcode'] = state.isForBarcode || false;
        }
        if(["itemMaster","inwardDataEntry","ASNTemplate"].includes(selectedSubTemplate)){
            editData['showInLabel'] = state.showInLabel || false;
        }
        let output={status:true}
        if(["text", "number", "textarea", "input"].indexOf(field.type) >= 0){
            editData['regex'] = regex && regex != "-" ? regex : ""
        }
        if(!field.dynamic && field._id != "UOM" && ["select", "checkbox", "radio"].indexOf(field.type) >= 0){
            editData['values'] = options
            //status, message
            output = validateOptions(options);
        }
        if(!output || !output.status){
            showToast(output.message || "", true);
        }
        else{
            let newField = {...field, ...editData};
            // Find index
            let customIndex =  editField.index;
            // if(editField.sectionId == "attributes" && editField.type == "mandatory"){
            if(editField.type == "mandatory"){
                let tempI = 0;
                const baseFields = components[editField.sectionId][editField.type];
                for (let i = 0; i < baseFields.length; i++) {
                    const f = baseFields[i];
                    if(f.templateType == selectedSubTemplate){
                        if(tempI == editField.index){
                            customIndex = i;
                            break;
                        }
                        else{
                            tempI++;
                        }
                    }
                    
                }
            }
            dispatch(saveField(editField.sectionId, editField.type, customIndex, newField))
            handleCloseModal();
        }
        
    }

    const handleRegexChange = (e) => {
        ////(e.target.value);
        setRegex(e.target.value);
    }

    useEffect(() => {
        if(editField && field){
            ////("changed Edit Field");
            ////(field);
            setValue("label", field.label);
            setValue("placeholder", field.placeholder);
            setRequired(field.required ? field.required : false);
            // setIsForBarcode(field.isForBarcode ? field.isForBarcode : false);
            setState({...state,
                isForBarcode:field.isForBarcode ? field.isForBarcode : false,
                showInLabel:field.showInLabel ? field.showInLabel : false,
            });
            setRegex(field.regex ? field.regex : "");
            if(field._id != "UOM" && ["select", "checkbox", "radio"].indexOf(field.type) >= 0){
                setOptions(field.values);
            }
        }
    }, [editField])
    
    return (
        <>
            {
                field && 
                <Dialog
                    open={editField != null}
                    onClose={handleCloseModal}
                    scroll={"paper"}
                    PaperProps={{ sx: { width: "60%" } }}
                    maxWidth="50%"
                >
                    <DialogContent dividers>
                        {/* <Card sx={style}> */}
                        <Typography variant='h6'>{field.label} Properties</Typography>
                        <Grid container justifyContent={"end"} alignItems="end">
                                <FormControlLabel checked={required}
                                    control={<Checkbox
                                        sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }}
                                        onChange={handleRequiredChange}
                                        disabled={editField.type == "mandatory"} size='small' />}
                                    label="Mandatory"
                                />
                            {
                                ["itemMaster","inwardDataEntry","ASNTemplate"].includes(selectedSubTemplate) &&
                                    <FormControlLabel checked={state.isForBarcode || false}
                                        control={<Checkbox
                                            sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }}
                                            onChange={(e) => handleQrChange('isForBarcode', e)} size='small' />} 
                                            label="Show in QR"
                                    />
                            }
                            {
                                ["itemMaster","inwardDataEntry","ASNTemplate"].includes(selectedSubTemplate) &&
                                    <FormControlLabel checked={state.showInLabel || false}
                                        control={<Checkbox sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }}
                                            onChange={(e) => handleQrChange('showInLabel', e)} size='small' />}
                                        label="Show in Label"
                                    />
                            }
                            
                        </Grid>
                        <Grid container spacing={1}>
                        
                            <Grid item sm={6} xs={12}>
                                <TextField variant='outlined' fullWidth label="Column heading" size='small' sx={{...inputClasses.themeBoundary}} focused required {...register("label", {required: true})}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField variant='outlined' fullWidth label="Placeholder" size='small' sx={{...inputClasses.themeBoundary}} focused required {...register("placeholder", {required: true})}/>
                            </Grid>
                        </Grid>
                        {
                           // editField.type != "mandatory" &&
                           !field.dynamic && !["UOM"].includes(field._id) && ["select", "checkbox", "radio"].indexOf(field.type) >= 0 && 
                            <Grid sx={{mt: 1}}>
                                <FormLabel sx={{...textClasses.normalText, mt: 2}}>Options</FormLabel>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Sno</TableCell>
                                            <TableCell align="center">Label</TableCell>
                                            <TableCell align="center">Value</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                options.map((op, i) => {
                                                    return(
                                                        <TableRow
                                                            key={i}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                        >
                                                            <TableCell>{i+1}</TableCell>
                                                            <TableCell>
                                                                <TextField type={"text"} variant="outlined" size='small' sx={{...inputClasses.themeBoundary}} fullWidth required value={op.label} onChange={(e) => handleOptionChange(e, "label", i)}/>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField type={"text"} variant="outlined" size='small' sx={{...inputClasses.themeBoundary}} fullWidth required value={op.value} onChange={(e) => handleOptionChange(e, "value", i)}/>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton size='small' sx={{background: "red"}} onClick={() => handleOptionDelete(i)}>
                                                                    <Remove fontSize='inherit' sx={{color: "white"}} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                        )
                                                })
                                            }
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                            >
                                                <TableCell>
                                                    <IconButton size='small' sx={{background: theme.themeOrange}} onClick={handleOptionAdd}>
                                                        <Add fontSize='inherit' sx={{color: "white"}} />
                                                    </IconButton>
                                                </TableCell>
                                                
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                                
                                
                            </Grid>
                        }
                        
                            {
                                editField.type != "mandatory" && ["text", "number", "textarea", "input"].indexOf(field.type) >= 0 &&
                                <Grid container sx={{mt: 1}} spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>Validation</InputLabel>
                                        <Select
                                            label={"Validation"}
                                            value={regex}
                                            onChange={handleRegexChange}
                                            sx={{...inputClasses.themeBoundary}}
                                        > 
                                            <MenuItem value={"-"}>Select a validation</MenuItem>
                                            {
                                                filterRegex(field.type,allRegex)?.map((r, i) => <MenuItem value={r.regex} key={i}>{r.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                    </Grid>
                                </Grid>
                            
                           
                        }

                        
                        <Grid container justifyContent="start" alignItems="start" sx={{mt: 2}}>
                            <Button variant='outlined' size='small' sx={{borderColor: theme.themeOrange, color: theme.themeOrange, textTransform: "none", "&:hover": {borderColor: theme.themeOrange}}} onClick={handleCloseModal}>
                                Cancel
                            </Button>
                            <Button variant='contained' size='small' className='mui-orange-btn' sx={{ml: 2, textTransform: "none",}} onClick={handleSubmit(handleSave)}>
                                Save Changes
                            </Button>
                        </Grid>
                        {/* </Card> */}
                    </DialogContent>
                </Dialog>
            }
        </>
     
    )
}
