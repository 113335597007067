import React, { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Typography, Divider, Button, IconButton, FormControlLabel, Checkbox, ButtonGroup, CircularProgress, Tooltip, ImageList, ImageListItem, Dialog, DialogContent, Box, FormControl, OutlinedInput, CardHeader, CardContent, FormLabel, Switch, InputAdornment } from '@mui/material'
import theme, { textClasses, buttonClasses  } from '../../../../../static/styles/theme'
import { Add, Delete, Download, FileUpload, Remove, Search, SellOutlined, Upload, Visibility } from '@mui/icons-material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import {  getFieldsLayoutForEntry,} from '../../../../Components/Utils';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
import { read, utils } from 'xlsx';
import { getCamelCaseText, showToast, _showModal } from '../../../../../services/functions';

import {  useSelector } from 'react-redux';
import ItemModal from './ItemModal';
import moment from 'moment';
import GenericCard from '../Components/DetailCard';
import GenericEditCard from '../Components/DetailDialog';
import ViewFileModal from '../Components/ViewExcelDialog';
import ItemtoPackageSection from './ItemToPackageSection';
import { DetailDialog } from './ItemSection';
import { getItemPackageComponent } from './constant';
import AsnPackageItemTableSection from './LinkedASN/AsnPackageItemTableSection';
import AsnPackageTableDialog from './LinkedASN/AsnPackageTableDialog';
import { handleDynamicChange,calculateTarget } from '../../../../../services/formFunction';


import AsnSection from './LinkedASN/AsnSection';

export default function GenericSection({
     bundleType, sectionKey, template, spiType, asn, asnTemplateHeader, receivingMethod, watch, objects,
      setObjects, dimensions, inventorySettings,receivingConfig=null
    }) {
    const receivingTypeOptions = [
        { label: "Available QTY", value: "availableQuantity" },
        { label: "Less QTY", value: "lessQuantity" },
        { label: "Extra QTY", value: "exceedQuantity" }
    ]

    let mandatoryFieldsId = useSelector(state => state.canvas.components[`${sectionKey}Template`].mandatory.filter(f => {
        if (!f.templateType) return true;
        if (f.templateType && f.templateType == template.templateType) {
            return true;
        }
        return false;
    }).map(f => f._id));

    const objectTemplateDetails = template?.otherTemplate?.find(ot => ot.templateType == `${sectionKey.toLowerCase()}DetailTemplate`)?.attributes || [];

 
    const sectionIndex = 2;
    const name = `${sectionKey} Details`
    let totalObjectCount = watch("packageQuantity");
    totalObjectCount = totalObjectCount ? totalObjectCount : 0
    let remainingObjects = totalObjectCount;
    //// //("SPI TYPE", spiType);
    
    if(!asn && ((spiType == "SPI" || spiType == "shipmentPackageOnly") && bundleType == "packageToItem")){
        remainingObjects = totalObjectCount - objects.length;
    }
    else{
        let sum = objects.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
        remainingObjects = totalObjectCount - sum;
        if(asn){
            remainingObjects=remainingObjects-Number(asn?.shipmentDetail?.receivedQuantity || 0)-Number(asn?.shipmentDetail?.rejectedQuantity || 0) +Number(asn?.shipmentDetail?.exceededQuantity || 0)
        }
    }
    

    const [selectedType, setSelectedType] = useState(receivingTypeOptions[0]);
    const [openAsnPackageTable, setOpenAsnPackageTable] = useState("")

    const { register, handleSubmit, formState: {errors}, reset, watch: watch2, setValue, getValues,  control } = useForm();
    
    const [sameObjectCount, setSameObjectCount] = useState(1);
    const [selectedObject, setSelectedObject] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    const fileInputRef = useRef();
    const [showFileData, setShowFileData] = useState(false);
    // const [itemMasterDetail, setItemMasterDetail] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [shellState, setShellState] = useState("");
    const [addItemClicked, setAddItemClicked] = useState(false);

    const [selectedEditObject, setSelectedEditObject] = useState(null);

    const [openI2P, setOpenI2P] = useState(false);
    const [itemsToAdd, setItemsToAdd] = useState([{itemDetail: []}])

    const [isManual, setIsManual] = useState(true);
    const [scanLoader, setScanLoader] = useState(false);
    const [showMasterDetail, setShowMasterDetail] = useState(false);
    const masterIdToSearch = watch2(spiType == "SPI"
        ? (bundleType == "packageToItem" ? "packageName" : "")
        : (spiType == "shipmentItemOnly" ? "SKU_Number" : "packageName")
    );

    const [allFields, setAllFields] = useState([]);

    const handleOpenI2P = () => {
        setOpenI2P(true);
    }

    const handleCloseI2P = () => {
        setOpenI2P(false);
    }

    const addItemsI2P = (pIndex, items) => {
        let oldP = [...itemsToAdd];
        oldP[pIndex].itemDetail = [...oldP[pIndex].itemDetail, ...items]
        setItemsToAdd(oldP);
    }
    ///// 

    const mappingLocations = useSelector(state => state.inward.allMapping);
    
    const autoFillOnFocus = () => {
        const socketInputs = ["weight", "length", "breadth", "height"]
        if(dimensions){
            for (let i = 0; i < allFields.length; i++) {
                let compId = allFields[i]._id
                if(socketInputs.indexOf(compId) >= 0 && dimensions[compId]){
                    setValue(compId, dimensions[compId]);
                }
            }
        }
    }

    const autoFillOnFocusForEdit = (index) => {
        if(dimensions){
            let oldItems = [...objects];
            for (let i = 0; i < allFields.length; i++) {
                let compId = allFields[i]._id
                if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setObjects(oldItems)
        }
    }
    
  
    let customizeFormAsPerSetting=(templateFields)=>{
        let tempFeilds=[...templateFields]
        // Custom Pakage ID
        if(["shipmentPackageOnly", "SPI"].indexOf(spiType) >= 0 && inventorySettings && inventorySettings.pkg.inputType.manual.status ){
            // If true
            let numRegex = "^[0-9]*$"
            let alphaRegex = "^[a-zA-Z0-9]+$"
            let fieldI = tempFeilds.map(f => f._id).indexOf("customPkg")
            
            if(fieldI >= 0){
                // Find customId field and update regex
                tempFeilds[fieldI] = {
                    ...tempFeilds[fieldI],
                    regex: inventorySettings.pkg.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
            }
            else{
                // If doesnt exist in template . Add Custom Shipment Field
                let field = {
                    type: "input",
                    label: "Custom Package Id",
                    _id: "customPkg",
                    required: true,
                    placeholder: "Enter your package id",
                    regex: inventorySettings.pkg.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
                // //// //(field);
                tempFeilds = [field].concat(tempFeilds)
            }
    
        }
        else{
            // remove from list
            let fieldI = tempFeilds.map(f => f._id).indexOf("customPkg");
            if(fieldI >= 0){
                tempFeilds.splice(fieldI, 1);
            }
        }
        // Custom Item Id
        if(["shipmentItemOnly"].indexOf(spiType) >= 0 && inventorySettings && inventorySettings.item.inputType.manual.status ){
            // If true
            let numRegex = "^[0-9]*$"
            let alphaRegex = "^[a-zA-Z0-9]+$"
            let fieldI = tempFeilds.map(f => f._id).indexOf("customItem")
            
            if(fieldI >= 0){
                // Find customId field and update regex
                tempFeilds[fieldI] = {
                    ...tempFeilds[fieldI],
                    regex: inventorySettings.item.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
            }
            else{
                // If doesnt exist in template . Add Custom Shipment Field
                let field = {
                    type: "input",
                    label: "Custom Item Id",
                    _id: "customItem",
                    required: true,
                    placeholder: "Enter your item id",
                    regex: inventorySettings.item.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
                tempFeilds = [field].concat(tempFeilds)
            }
    
        }
        else{
            // remove from list
            let fieldI = tempFeilds.map(f => f._id).indexOf("customItem");
            if(fieldI >= 0){
                tempFeilds.splice(fieldI, 1);
            }
        }
    
        if(["shipmentPackageOnly", "shipmentItemOnly"].indexOf(spiType) >= 0){
            let uomIndex = tempFeilds.map(f => f._id).indexOf("UOM");
            // //// //("UOM Index", uomIndex);
            if(uomIndex >= 0){
                // tempFeilds[uomIndex].values = allUom.map(u => ({
                //     label: u.unitName,
                //     value: u.unitValue
                // }));
            }
            else{
                // let field = {
                //     "_id": "UOM",
                //     "type": "select",
                //     "label": "Unit Of Measurement (UOM)",
                //     "description": "unit of measurements",
                //     "placeholder": "select Unit",
                //     // "values": allUom.map(u => ({
                //     //     label: u.unitName,
                //     //     value: u.unitValue
                //     // })),
                //     "required": true,
                //     "forType": "all",
                //     "elementType": "Selection Type",
                //     "sortType": 2,
                // }
                // tempFeilds.push(field);
            }
        }
        else{
            let uomIndex = tempFeilds.map(f => f._id).indexOf("UOM");
            if(uomIndex >= 0){
                tempFeilds.splice(uomIndex);
            }
            
        }

        if ((spiType == "shipmentPackageOnly" || spiType == "shipmentItemOnly") && mappingLocations.length > 0) {
            let field = {
                type: "select",
                label: "Mapping Area",
                required: true,
                _id: "mappingArea",
                values: mappingLocations || [],
                defaultValue: "PUT IT ANY WHERE"
            }
            let isMapppingAreaField=tempFeilds.findIndex(dl=>dl._id=="mappingArea")
            if (isMapppingAreaField < 0) {
                tempFeilds=tempFeilds.concat([field])
            }
            setValue("mappingArea", mappingLocations?.at(0)?.value || "");
        }
        // console.log("tempFeilds",tempFeilds)
        setAllFields([...tempFeilds])

        setIsManual(true)
        setItemsToAdd([{itemDetail: []}])
    }
    
    const layout = getFieldsLayoutForEntry(allFields, register, control, autoFillOnFocus, errors);

    const handleOpenModal = (oIndex) => {
        setSelectedObject(oIndex);
    }
    const handleCloseModal = () => {
        setSelectedObject(null);
        setAddItemClicked(false);
    }

    const handleOpenEditModal = (oIndex) => {
        setSelectedEditObject(oIndex);
    }

    const handleCloseEditModal = () => {
        setSelectedEditObject(null);
    }

    const handleDeleteObject = (oIndex) => {
        let oldObjects = [...objects];
        oldObjects.splice(oIndex, 1);
        setObjects(oldObjects);
    }

    const handlePackageAddAndItem = (data) => {

        if(data.sameDetail && sameObjectCount < 1){
            showToast("Count cannot be 0", true);
            return;
        }
        let objectCount = data.sameDetail ? sameObjectCount : 1;
        if(objects.length + objectCount > totalObjectCount){
            showToast(`Cannot add ${sectionKey} more than mentioned total quantity`, true)
        }
        else{
            if(!!getValues("itemMasterDetail") && Object.keys(getValues("itemMasterDetail")).length>0){
                data = {...data, fetchedFromItemMaster: true,itemMasterDetail:getValues("itemMasterDetail")}
            }
            else{
                data = {...data, fetchedFromItemMaster: false}
            }
            handleObjectAdd({...data});
            setAddItemClicked(true);
            setValue("itemMasterDetail",{})
        }
    }

    useEffect(() => {
        
        if(addItemClicked){
            setSelectedObject(objects.length - 1);
        }
        else{
            
        }
    }, [addItemClicked])

    const handlePackageAddAndOther = (data, cb) => {
        if(!!getValues("itemMasterDetail") && Object.keys(getValues("itemMasterDetail")).length>0){
            data = {...data, fetchedFromItemMaster: true,"itemMasterDetail":getValues("itemMasterDetail")}
        }
        else{
            data = {...data, fetchedFromItemMaster: false}
        }
        handleObjectAdd(data, cb);
    }

    const handleObjectAdd = (data, cb) => {
        if(data.sameDetail && sameObjectCount < 1){
            showToast("Count cannot be 0", true);
            return;
        }
        let objectCount =  data.sameDetail ? sameObjectCount : 1;
        let objectsToAdd;
        if(data.defaultItems){
            objectsToAdd = data.defaultItems.map(it => Number(it.count)).reduce((a, b) => a+b, 0);
        }
        else if(spiType == "SPI" || spiType == "shipmentPackageOnly"){
            objectsToAdd = objectCount
        }
        else{
            objectsToAdd = data.sameDetail ? Number(data.count) * Number(sameObjectCount) : Number(data.count);
        }
        if(objectsToAdd > remainingObjects){
            showToast(`Cannot add ${sectionKey} more than mentioned total quantity`, true)
        }
   
        else{
            let objectsToAdd = Array(objectCount).fill(0).map((_, i) => {
                delete data.sameDetail;
                return {
                    itemDetail: data.defaultItems ? data.defaultItems : [],
                    // scannedData: !isManual ? getValues("itemMasterDetail") || {} : null,
                    ...data
                }
            })
            setObjects([...objects, ...objectsToAdd]);
            let resetD = {...data};
            let rKeys = Object.keys(resetD);
            for (let i = 0; i < rKeys.length; i++) {
                resetD[rKeys[i]] = ""
            }
            // setItemMasterDetail(null)
            setValue("itemMasterDetail",{})
            reset({
                ...resetD
            });
            if(cb){
                cb();
            }
        } 
    }

    const addItems = (pIndex, items) => {
        let oldP = [...objects];
        oldP[pIndex].itemDetail = items
        setObjects(oldP);
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const getTableHeader = (headerType) => {
        let base = [];
        // base = mandatoryFields.map(of => {
        //     let label = of.label;
        //     if(headerType == "excel" && ["select", "checkbox", "radio"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values)){
        //         label = `${label} (${of.values.map(o => o.value).join(",")})`
        //     }
        //     return label
        // });
        // if(spiType == "SPI"){
        //     base = mandatoryFields.map(f => f.label);
        // }
        // else{
        //     base = mandatoryFields.filter(f => f._id != "count").map(f => f.label);
        // }
        let otherHeader = allFields.map((of) => {
            let label = of.label;
            if(headerType == "excel" && ["select", "checkbox", "radio"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values)){
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        return [...base, ...otherHeader];
    }

    const getLabelToIdMapping = () => {
        let mapping = {}
        // for (let index = 0; index < mandatoryFields.length; index++) {
        //     let label = mandatoryFields[index].label;
        //     if(["select", "checkbox", "radio"].indexOf(mandatoryFields[index].type) >= 0 && mandatoryFields[index].values != null && Array.isArray(mandatoryFields[index].values)){
        //         label = `${label} (${mandatoryFields[index].values.map(o => o.value).join(",")})`
        //     }
        //     mapping[label] = mandatoryFields[index]._id            
        // }
        for (let index = 0; index < allFields.length; index++) {
            let label = allFields[index].label;
            if(["select", "checkbox", "radio"].indexOf(allFields[index].type) >= 0 && allFields[index].values != null && Array.isArray(allFields[index].values)){
                label = `${label} (${allFields[index].values.map(o => o.value).join(",")})`
            }
            mapping[label] = allFields[index]._id            
        }
        return mapping;
    }

    const downloadSample = async () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
        const fileExt = ".xlsx";

        const excelData = {};
        const theaders = getTableHeader("excel");
        for (let i = 0; i < theaders.length; i++) {
            excelData[theaders[i]] = "Enter Value"
        }
        
        const ws = XLSX.utils.json_to_sheet([excelData]);
        const wb = { Sheets: {data: ws}, SheetNames: ['data']};
        const buffer = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
        const data = new Blob([buffer], {type: fileType});
        FileSaver.saveAs(data, `${sectionKey}_template_name` + fileExt);

    }

    const handleShowFileModal = () => {
        setShowFileData(true);
    }

    const handleCoseFileModal = () => {
        setShowFileData(false);
    }

    const handleDeleteFile = () => {
        setShellState(Math.random().toString(36));
        setSelectedFile(null);
        setFileData([]);
        setShowFileData(false);
    }

    const matchFileHeader = (fileHeader, uploadedHeader) => {
        const diff = uploadedHeader.filter( h => !fileHeader.includes(h));
        if(diff.length > 0){
            return false;
        }
        return true;
    }

    const getAndValidateField = (mappedField, raw) => {
        //// //("Found Excel Field", mappedField);
        //// //("With Excel Data", raw);
        if(mappedField._id == "UOM"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "date"){
            let newFormat = (raw && raw != "") ? moment(raw).format("YYYY-MM-DD") : null
            if(!mappedField.required){
                return [newFormat, true, ""]
            }
            if(mappedField.required && newFormat){
                return [newFormat, true, ""]
            }
            
            else{
                return [newFormat, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(["input", "number", "textarea"].indexOf(mappedField.type) >= 0){
            if(!mappedField.required){
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                //// //("Testing raw", raw);
                //// //(mappedField.regex);
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "file"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "checkbox"){
            let options = raw.split(",").map(o => o.trim());
            let invalidOptions = options.filter(o => (o != "" && mappedField.values.map( m => m.value).indexOf(o) < 0));
            if(invalidOptions.length > 0){
                return [options, false, `File Error: Invalid ${mappedField.label} values passed`]
            }
            if(!mappedField.required){
                return [options, true, ""]
            }
            else if(mappedField.required && options.length > 0){
                return [options, true, ""]
            }
            else{
                return [options, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "radio" || mappedField.type == "select"){
            let pos = mappedField.values.map(m => m.value).indexOf(raw);
            if(mappedField.required){
                if(raw == "" && pos < 0){
                    return [raw, false, `File Error: ${mappedField.label} is required`]
                }
                else if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
        }
    }

    const handleUploadFile = (upload_type) => {
        if(upload_type == "upload" && (!totalObjectCount || totalObjectCount == "")){
            showToast("Please enter a quantity in shipment first", true)
            return;
        }
        const file = selectedFile;
        const reader =  new FileReader();
        setFileLoading(true);

        reader.onerror = (e) => {
            handleDeleteFile();
            setFileLoading(false);
            showToast("File Error: Error reading file. Please select again", true);
        }

        reader.onabort = () => {
            //// //("aborted");   
        }

        reader.onload = (e) => {
            const wb = read(e.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {raw: false});
                if (rows.length == 0) {
                    showToast("Cannot upload blank file", true)
                    setFileLoading(false);
                    reader.abort();
                    return;
                }
                const labelToIdMap = getLabelToIdMapping();
                const tHeader = getTableHeader("excel");

                let NewObjects = []
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    if(!matchFileHeader(tHeader, Object.keys(row))){
                        showToast("File header doesnt match. Please re-upload with correct header", true)
                        setFileLoading(false);
                        reader.abort();
                        return;
                    }
                    else{
                        let obj = {};
                        for (let j = 0; j < tHeader.length; j++) {
                            let mappedField = allFields.filter((of) => of._id == labelToIdMap[tHeader[j]])
                            // let mappedField = mandatoryFields.filter((of) => of._id == labelToIdMap[tHeader[j]])
                            // mappedField = mappedField.concat(allFields.filter((of) => of._id == labelToIdMap[tHeader[j]]))
                            if(mappedField.length > 0){
                                mappedField = mappedField[0];
                                const [cleanValue, status, errorMessage] = getAndValidateField(mappedField, rows[i][tHeader[j]]);
                                if(status){
                                    obj[labelToIdMap[tHeader[j]]] = cleanValue
                                }
                                else{
                                    showToast(errorMessage, true);
                                    setFileLoading(false);
                                    reader.abort();
                                    return;
                                }
                            }
                            else{
                                obj[labelToIdMap[tHeader[j]]] = rows[i][tHeader[j]]
                            }
                        }
                        // if(!obj.count){
                        //     obj.count = 1;
                        // }
                        // if(spiType == "shipmentPackageOnly" || spiType == "shipmentItemOnly"){
                        //     obj.count = 1;
                        // }
                        obj.itemDetail = []
                        NewObjects.push(obj)
                    }
                }

                // setFileData(rows);
                if(upload_type == "view"){
                    setFileData(rows);
                    setShowFileData(true);
                }
                else{
                    let addingCount;
                    if(spiType == "SPI" || spiType == "shipmentPackageOnly"){
                        addingCount = NewObjects.length;
                    }
                    else{
                        addingCount = NewObjects.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
                    }
                    if(addingCount > remainingObjects){
                        showToast(`File Error: Total ${sectionKey} count should be less than equal to ${totalObjectCount}`, true)
                    }
                    else{
                        setObjects([...objects, ...NewObjects])
                    }   
                }                
            }
            setFileLoading(false);
        }
        if(file){
            reader.readAsArrayBuffer(file);
        }
        else{
            showToast("File Error: Something wrong with file")
        }
    }

    const clearForm=(changeManual=true,changeIdAdded=true,tempAll=[])=>{
        let resetD = {...getValues()};
        let rKeys = Object.keys(resetD);
        for (let i = 0; i < rKeys.length; i++) {
            resetD[rKeys[i]] = ""
        }
        reset({
            ...resetD
        });
        if(changeManual)setIsManual(true)
        if(changeIdAdded)setItemsToAdd([{itemDetail: []}])
        if(tempAll?.length>0){
            tempAll=tempAll.map(dl=>({...dl,disabled:false}))
            setAllFields([...tempAll])
        }
    }

    const handleSameCount = (e) => {
        //// //("Package Total", totalObjectCount);
        //// //("Package Remaining", remainingObjects);
        //// //("Package enter value", e.target.value);
        // let maxCap = remaining + oldItems[i].count;
        setSameObjectCount(Number(e.target.value) > remainingObjects ? remainingObjects : Number(e.target.value))
        
        
    }

    useEffect(() => {
        // reset packages if shipment quantity changes
        if(!asn || (asn && spiType == "SPI")){
            setObjects([]);
        }
        
    }, [totalObjectCount])

    useEffect(() => {
        clearForm()
        let fields=template?.otherTemplate?.find(ot => ot.templateType == `${sectionKey.toLowerCase()}DetailTemplate`)?.attributes || []
        customizeFormAsPerSetting(fields)
    }, [template,mappingLocations, spiType,bundleType])

    // For creating shell package for row wise entries in ASN Linked Entry
    // useEffect(() => {
    //     const tempOther = [...allFields]
    //     if ((spiType == "shipmentPackageOnly" || spiType == "shipmentItemOnly") && mappingLocations.length > 0) {
    //         let field = {
    //             type: "select",
    //             label: "Mapping Area",
    //             required: true,
    //             _id: "mappingArea",
    //             values: mappingLocations || [],
    //             defaultValue: "PUT IT ANY WHERE"
    //         }
    //         let isMapppingAreaField=tempOther.findIndex(dl=>dl._id=="mappingArea")
    //         if (isMapppingAreaField < 0) {
    //             setAllFields([...tempOther,field])
    //         }
    //         setValue("mappingArea", mappingLocations?.at(0)?.value || "");
    //     }
    //     if(spiType == "SPI"){
    //         setAllFields([...tempOther])
    //     }
    // }, [mappingLocations, spiType])

    useEffect(() => {
        // console.log("Changes Detected");
        setObjects([]);
        // setItemMasterDetail(null);
        // if(asn && (spiType == "shipmentPackageOnly" || spiType == "shipmentItemOnly")){
        //     setObjects([{itemDetail: [], count: 0}])
        // }
        // else{
        //     setObjects([])
        // }
    }, [asn && asn.ASN_Id || "", template, spiType])

    const checkFormuMultiSku = (dataObj) => {
        if (dataObj.itemDetail.length > 0) {
            if (dataObj.itemDetail.length == 1) return false
            let SKU_Ids = new Set()
            dataObj.itemDetail.map(item => {
                SKU_Ids.add(item.SKU_Number);
            })
            return SKU_Ids.size > 1 ? true : false
        }
        return false
    }
    const getOpenASNType=(receivingMethodValue="")=>{
        let openType=""
        if(["pallets", "packages"].includes(receivingMethodValue)){
            openType =receivingMethodValue?.split("s")?.at(0)
        }
        else if(["bothPackageItem"].includes(receivingMethodValue)){
            openType ="package"
        }
         else if(["bothPalletItem"].includes(receivingMethodValue)){
            openType ="package"
        }
        else if(["individualItems"].includes(receivingMethodValue)){
            openType ="bundleItem"
        }
       return openType
    }

    const ASNItemSectionRender=()=>{
        
        // ((typeof selectedObject == "number" && selectedObject >= 0 && spiType == "SPI") ||
        //                         (typeof selectedEditObject == "number" && selectedEditObject >= 0 && spiType == "shipmentItemOnly"))
        
        let tempItem=[]
        let multiSKUSelected=false
        let totalItemCount=0
        if(spiType == "SPI"){
            tempItem=[...(objects[selectedObject]?.itemDetail || [])]
            multiSKUSelected=checkFormuMultiSku(objects[selectedObject] || {})
            totalItemCount=tempItem.reduce((a,b)=>a+Number(b?.count || 0),0)
        }
        else{
            tempItem=objects[selectedEditObject] && [objects[selectedEditObject]] || []
            totalItemCount=Number(objects[selectedEditObject]?.count || 0)
        }

        return  <Dialog
                open={getOpenASNType(receivingMethod?.value || "")}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"xl"}
        >
            <DialogContent>
                <AsnPackageItemTableSection
                        open={getOpenASNType(receivingMethod?.value || "")}
                        template={template}
                        receivingType={selectedType}
                        selectedAsnItems={tempItem}
                        idSettings={inventorySettings ? inventorySettings : null}
                        openType="edit"
                        onClose={() => {
                            if(spiType == "SPI"){
                                handleCloseModal()
                            }
                            else{
                                handleCloseEditModal()
                            }
                        }}
                        totalItemCount={totalItemCount}
                        itemDetail={tempItem}
                        saveItemHandler={(newItem = [],indexInsert) => {
                            // console.log('submit item(s)',newItem)
                            if(spiType == "SPI"){
                                handleCloseModal()
                                let oldObjects = [...objects];
                                oldObjects[indexInsert].itemDetail = [...newItem];
                                oldObjects[indexInsert].count = newItem.reduce((a, b) =>Number(a || 0) + Number(b.count || 0), 0) || 0;
                                setObjects(oldObjects);
                            }
                            else{
                                let oldObjects = [...objects];
                                oldObjects.splice(selectedEditObject, 1, ...newItem);
                                setObjects([...oldObjects]);
                                handleCloseEditModal()
                            }
                        }}
                        multiSKUSelected={multiSKUSelected}
                        {...(spiType == "SPI" ? { pIndex: 0, packageObj: objects[selectedObject] } : {})}
                />
            </DialogContent>
        </Dialog>

        
    }

    const PackageList = (
        <>
            <Grid container>
                <Grid container item sm={1} xs={2} alignItems="center" justifyContent={"center"}>
                    <IconButton
                        disabled={totalObjectCount < 5}
                        onClick={() => setSlideIndex(slideIndex >= 1 ? slideIndex - 1: slideIndex)}
                        sx={{backgroundColor: theme.themeOrange, color: "white", "&:hover": {backgroundColor: theme.themeOrange}}}
                    >
                        <KeyboardArrowLeft/>
                    </IconButton>
                </Grid>
                <Grid container item sm={10} xs={8}>
                    <Grid container direction={"row"} spacing={1} sx={{p: 2}}>
                        {
                            objects.slice(slideIndex * 4, slideIndex * 4 + 4).map((p, i) => {
                                return (
                                    <Grid item sm={3} xs={6} key={i}>
                                        <GenericCard
                                            asn={asn?.itemDetail?.length>0}
                                            spiType={spiType}
                                            sectionKey={sectionKey}
                                            pIndex={slideIndex * 4 + i}
                                            packageObj={p}
                                            dimensions={dimensions || {}}
                                            handleOpenModal={handleOpenModal}
                                            handleOpenEditModal={handleOpenEditModal}
                                            handleDeleteObject={handleDeleteObject}
                                            mandatoryFieldsId={mandatoryFieldsId}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container item sm={1} xs={2} alignItems="center" justifyContent={"center"}>
                    <IconButton
                        disabled={totalObjectCount < 5}
                        onClick={() => setSlideIndex(slideIndex < Math.ceil(objects.length / 4) - 1 ? slideIndex + 1 : slideIndex)}
                        sx={{backgroundColor: theme.themeOrange, color: "white", "&:hover": {backgroundColor: theme.themeOrange}}}
                    >
                        <KeyboardArrowRight/>
                    </IconButton>
                </Grid>
                {asn?.itemDetail?.length > 0
                    ?
                    <>
                        {
                            (typeof selectedEditObject == "number" && selectedEditObject >= 0 && (spiType == "shipmentPackageOnly" || spiType == "SPI")) ?
                                <AsnPackageTableDialog
                                    open={getOpenASNType(receivingMethod?.value || "")}
                                    template={template}
                                    receivingType={selectedType}
                                    onClose={() => {
                                        handleCloseEditModal()
                                    }}
                                    items={objects[selectedEditObject] && [objects[selectedEditObject]] || []}
                                    handleSubmitPackages={(pkg) => {
                                        let oldObjects = [...objects];
                                        oldObjects.splice(selectedEditObject, 1, ...pkg);
                                        setObjects([...oldObjects]);
                                        handleCloseEditModal()
                                    }}
                                    idSettings={inventorySettings ? inventorySettings : null}
                                    multiSKUSelected={spiType == "SPI" ? checkFormuMultiSku(objects[selectedEditObject] || []) : false}
                                    spiType={spiType}
                                    openType="edit"
                                    receivingMethod={receivingMethod}
                                />
                                : ""
                        }
                        {
                            ((typeof selectedObject == "number" && selectedObject >= 0 && spiType == "SPI") ||
                                (typeof selectedEditObject == "number" && selectedEditObject >= 0 && spiType == "shipmentItemOnly")) ?
                                ASNItemSectionRender()
                                : ""
                        }
                    </>
                    :
                    <>
                        <GenericEditCard
                            asn={asn}
                            spiType={spiType}
                            sectionKey={sectionKey}
                            allFields={allFields}
                            // allFields={mandatoryFields.concat(allFields)}
                            objects={objects}
                            setObjects={setObjects}
                            oIndex={selectedEditObject}
                            totalObjectCount={totalObjectCount}
                            handleCloseModal={handleCloseEditModal}
                            handleFocus={autoFillOnFocusForEdit}
                            mappingLocations={mappingLocations}
                            bundleType={bundleType}
                        />
                        {
                            spiType == "SPI" &&
                            <ItemModal template={template} pIndex={selectedObject} packages={objects} handleCloseModal={handleCloseModal} addItems={addItems} idSettings={inventorySettings ? inventorySettings.item : null} asn={asn} />
                        }
                    </>
                }
            </Grid>
            
            <Divider/>
        </>
    )

    const handleScanChange=(event)=>{
        if(!["shipmentItemOnly", "shipmentPackageOnly","SPI"].includes(spiType)) return
        let checked = event.target.checked || false
        let tempAll=[...allFields]
        let componentId=""
        if(spiType=="SPI"){
            componentId=bundleType == "packageToItem" ? "packageName" : ""
        }
        else{
            componentId=(spiType == "shipmentItemOnly" ? "SKU_Number" : "packageName")
        }
        let compIndex = tempAll?.findIndex(dl => dl._id == componentId)
        // console.log(compIndex,"compIndex",tempAll)
        if(componentId &&  compIndex>=0){
            if (checked  && ["shipmentItemOnly", "shipmentPackageOnly","SPI"].includes(spiType)) {
                let temComp = getItemPackageComponent(tempAll,tempAll[compIndex])
                // console.log("temComp",temComp)
                
                tempAll.splice(compIndex, 1, temComp)
            }
            else{
                let initialCom=objectTemplateDetails.find(dl=> dl._id == componentId) || {}
                tempAll.splice(compIndex, 1, {...initialCom})
            }
            // console.log("tempAll",tempAll)
            tempAll=tempAll.map(dl=>({...dl,disabled:false}))
            setAllFields([...tempAll])
            clearForm()
        }
        setIsManual(!checked)
        setValue("itemMasterDetail",{})
        setItemsToAdd([{itemDetail: []}])
    }

    const handleDynamicSearchChange=async(value,targetId)=>{
        let targetField = allFields.find(dl => dl._id == targetId)
        let resetValue = true
        if (targetField?.type && ["select"].includes(targetField.type) && targetField.setTargetValue) {
            if (value) {
                let details =  {};
                await handleDynamicChange(details, targetField, { value: value || "" }, "itemDetail", ["tableHeader"])
                if (details?.itemDetail && Object.keys(details?.itemDetail)?.length > 0) {

                    let mappingArea = details?.itemDetail?.mappingArea?.length > 0 && details?.itemDetail?.mappingArea || []
                    let formData=details?.itemDetail?.formData || {}
                    let tableHeader=details?.tableHeader || {}
                    let tempAll = [...allFields]
                    let disabled = []
                    let allKeyValues=getValues() || {}
                    for (let info of tempAll) {
                        let readKey = info?._id
                        let insertKey = info?._id
                        if (["mappingArea", "count"].includes(info._id)) continue;
                        if (["packageName", "SKU_Number"].includes(readKey)) {
                            readKey = "SKU_ID"
                        }
                        else if (["packageDescription", "SKU_Name"].includes(readKey)) {
                            readKey = "SKU_Detail"
                        }
                        let tempValue = ""
                        if (formData && formData[readKey]) {
                            tempValue = formData[readKey]
                        }
                        if (tempValue) {
                            info.disabled = true
                            disabled.push(insertKey)
                        }
                        else {
                            info.disabled = false
                        }
                        if (!!tempValue && info.type == "checkbox") {
                            tempValue = []
                            info.disabled = false
                            disabled.pop()
                        }

                        allKeyValues[insertKey]=tempValue
                    }

                    let isMapppingAreaField = tempAll.findIndex(dl => dl._id == "mappingArea")
                    if (mappingArea?.length && isMapppingAreaField >= 0) {
                        let restrict = mappingArea?.map(dl => ({ label: dl, value: dl }))
                        tempAll.splice(isMapppingAreaField, 1, {
                            ...tempAll[isMapppingAreaField],
                            values: [...restrict],
                            label: (tempAll[isMapppingAreaField]?.label || "") + " (Restricted)"
                        })
                        setValue("mappingArea", mappingArea?.at(0) || "PUT IT ANY WHERE");
                    }
                    resetValue = false
                    allKeyValues={
                        ...allKeyValues, disabled,
                        itemMasterDetail:{tableHeader,formData}
                    }
                    reset(allKeyValues)
                    setAllFields(tempAll)
                }
            }
        }
        if (resetValue) {
            clearForm(false, false, allFields)
            setValue("mappingArea", "PUT IT ANY WHERE");
            setValue("disabled", [])
            setValue("itemMasterDetail", {})
        }
    }


    const countWatch = watch2("count");
    //// //("Count Watch Main", countWatch);
    useEffect(() => {

        if(spiType == "shipmentItemOnly"){
            //// //("Watch");
            if(countWatch && countWatch > 1){
                setValue("USN_Value", "Not Applicable")
            }
            else{
                setValue("USN_Value", "")
            }
        }
    }, [countWatch])
    
    useEffect(() => {
        let componentId=false
        if(["SPI","shipmentItemOnly","shipmentPackageOnly"].includes(spiType)) {
            if(spiType=="SPI"){
                componentId=(bundleType == "packageToItem" ? "packageName" : "")
                // componentId=(bundleType == "itemToPackage" ? "SKU_Number" : "packageName")
            }
            else{
                componentId=(spiType == "shipmentItemOnly" ? "SKU_Number" : "packageName")
            }
        }
    
        if(!asn && !isManual && componentId){
           handleDynamicSearchChange(masterIdToSearch,componentId)
        }
    }, [masterIdToSearch])



    const DynamicForm = (
        <>
            <Grid container spacing={4} sx={{p: 2}}>
                {   
                    layout.single.map((field, i) => {
                        return (
                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                {
                                    field
                                }
                                {!asn && !isManual && (i == allFields?.findIndex(dl => ["SKU_Number", "packageName"].includes(dl?._id))) ?
                                    (Object.keys(getValues("itemMasterDetail") || {}))?.length > 0
                                        ? <>
                                            <span style={{ color: theme.themeOrange, cursor: "pointer", marginRight: "5px", textDecoration: "underline", }}
                                                onClick={() => setShowMasterDetail(true)}
                                            >
                                                View Detail
                                            </span>
                                            <span style={{ cursor: "pointer", marginLeft: "5px", color: theme.themeBlack, textDecoration: "underline" }}
                                                onClick={() => clearForm(false,false,allFields)}
                                            >
                                                Clear Detail
                                            </span>
                                        </>
                                        : <span style={{ color: theme.themeOrange }}>Not Scanned</span>
                                    : ""
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container spacing={4} sx={{p: 2}}>
                {   
                    layout.multi.map((field, i) => {
                        return (
                            <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                {
                                    field
                                }
                            </Grid>
                        )
                    })
                }

            </Grid>
            {showMasterDetail
                ? <DetailDialog
                    onClose={() => setShowMasterDetail(false)}
                    data={{
                        tableHeader: getValues("itemMasterDetail")?.tableHeader || [],
                        itemDetail: { formData: getValues("itemMasterDetail")?.formData || {} },
                    }}
                />
                : ""}
        </>
    )

    const SectionHeader = (
        <Grid container sx={{backgroundColor: theme.themeGray, p: "12px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", fontWeight:"600"}}>
            <Grid container item sm={6} xs={6} direction="row">
                <Typography sx={{...textClasses.boldText}}>
                    {sectionIndex}. {getCamelCaseText(name)}
                </Typography>
                {
                    !asn && 
                    <FormControlLabel
                        sx={{ml: 2}}
                        control={
                            <>
                                <FormLabel sx={{ color: theme.themeOrange, ...textClasses.t12n }}>Manual</FormLabel>
                                <Switch
                                    size='small'
                                    color="warning"
                                    checked={!isManual}
                                    onChange={(event) => handleScanChange(event)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <FormLabel sx={{ color: theme.themeOrange, ...textClasses.t12n }}>Scan</FormLabel>
                            </>
                        }
                    />
                }
            </Grid>
            
            <Grid container direction={"row"} item sm={6} xs={6} justifyContent='end' alignItems="end">
                {
                    totalObjectCount
                    ?
                    <>
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>
                            Total {asn && "Items" }- {totalObjectCount}
                        </Typography>
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, ml: 1}}>
                            Remaining {asn && "Items" }- {remainingObjects}
                        </Typography>
                    </>
                    :
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, ml: 1}}>
                            Enter {sectionKey} quantity
                        </Typography>
                }
                
            </Grid>
        </Grid>
    )

    return (
        <>
            {
                !asn
                ?

                    <Paper key={sectionIndex} elevation={1}>
                        { SectionHeader }
                        {
                            bundleType == "packageToItem" && isManual && 
                            <>
                                <ViewFileModal
                                    showFileData={showFileData}
                                    fileData={fileData}
                                    handleClose={handleCoseFileModal}
                                    header={getTableHeader()}
                                />
                                <Grid container sx={{mt: 1}}>
                                    <Grid container item sm={12} justifyContent="end" alignItems="end">
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content" }}>
                                                <a target="_blank" rel="noopener noreferrer" download>
                                                    <Button
                                                        variant="contained"
                                                        spacing={1}
                                                        className='RF_sampledownload-btn'
                                                        sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px", backgroundColor: "black" }}
                                                        onClick={downloadSample}
                                                    >
                                                        <Download /> Sample Download
                                                    </Button>
                                                </a>
                                                <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px" }}>
                                                    {/* <TextField size='small' type="file" sx={{ml: 1, mr: 1}} id="upload" className="RF_fileupload-input" onChange={handleFileChange} ref={fileInputRef} key={shellState}/> */}
                                                    <input
                                                        type="file"
                                                        name="upload"
                                                        id="upload"
                                                        className='RF_fileupload-input'
                                                        onChange={handleFileChange} ref={fileInputRef} key={shellState}
                                                    />
                                                </FormControl>
                                                <Button
                                                    variant="outlined"
                                                    spacing={1}
                                                    className='RF_outline-btn'
                                                    sx={{ marginRight: "5px", borderRadius: "8px" }}
                                                    disabled={selectedFile == null} onClick={handleDeleteFile}
                                                    >
                                                    <Delete />
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    spacing={1}
                                                    className='RF_outline-btn'
                                                    color="primary"
                                                    sx={{ marginRight: "5px", borderRadius: "8px" }}
                                                    onClick={() => handleUploadFile("view")}
                                                    // disabled={!fileData || fileData.length  == 0} onClick={handleShowFileModal}
                                                >
                                                    <Visibility />
                                                </Button>
                                                {
                                                    fileLoading 
                                                    ?
                                                        <>
                                                            <CircularProgress size="2rem" sx={{color: theme.themeOrange}}/>
                                                            <Typography sx={{...textClasses.t12n, ml: 1}}>Please wait...</Typography>
                                                        </>
                                                    :
                                                        <Button
                                                            variant="contained"
                                                            spacing={1}
                                                            className={selectedFile == null ? "" : 'RF_uploadfile-btn'}
                                                            sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px" }}
                                                            disabled={selectedFile == null} onClick={() => handleUploadFile("upload")}
                                                        >
                                                            <FileUpload /> Upload File
                                                        </Button>
                                                }
                                                
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Divider sx={{mt: 1}}/>
                            </>
                        }
                        {
                            objects.length > 0 && bundleType == "packageToItem" && 
                            PackageList
                        }
                        {
                            bundleType == "packageToItem" &&
                            <>
                                {
                                    DynamicForm
                                }
                                {/* {
                                    !asn && itemMasterDetail &&
                                    <Paper elevation={1} sx={{p: 2, m: 2}}>
                                        <Grid container>
                                            {
                                                itemMasterDetail && itemMasterDetail.templateHeader && itemMasterDetail.templateHeader.tableHeader && itemMasterDetail.templateHeader.tableHeader.filter(f => (typeof itemMasterDetail.data.formData[f.name] == "string") && itemMasterDetail.data.formData[f.name].toString().length < 25).map((f, i) => {
                                                    return (
                                                        <Grid container item sm={4} xs={4} alignItems="center" key={i} sx={{mb: 2}}>
                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Typography sx={{...textClasses.boldText, ml: 2}}>{itemMasterDetail.data.formData[f.name]}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Grid container>
                                            {
                                                itemMasterDetail && itemMasterDetail.templateHeader && itemMasterDetail.templateHeader.tableHeader && itemMasterDetail.templateHeader.tableHeader.filter(f => (typeof itemMasterDetail.data.formData[f.name] != "string") || (itemMasterDetail.data.formData[f.name] && itemMasterDetail.data.formData[f.name].toString().length > 25)).map((f, i) => {
                                                    return (
                                                        <Grid container item sm={4} xs={4} alignItems="center" key={i} sx={{mb: 2}}>
                                                            <Grid container>
                                                                <Grid container item sm={4} xs={6}>
                                                                    <Typography sx={{...textClasses.normalText, color: "#827878"}}>{f.label} : </Typography>
                                                                </Grid>
                                                                <Grid container item sm={8} xs={6}>
                                                                    <Tooltip title={itemMasterDetail.data.formData[f.name]}>
                                                                        <Typography sx={{...textClasses.boldText, ml: 2}}>{itemMasterDetail.data.formData[f.name].toString().substring(0, 100)}</Typography>
                                                                    </Tooltip>
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Paper>
                                } */}
                                {
                                    // spiType == "SPI" && 
                                    <Grid container sx={{p: 2}} alignContent="center">
                                        <FormControlLabel {...register('sameDetail')} control={<Checkbox defaultChecked sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} />} label={`To Add ${sectionKey} with these details (Enter the ${sectionKey} counts)`}/>
                                        <ButtonGroup size='small' sx={{mt: 1, ".MuiButtonGroup-grouped": {minWidth: "25px"}}}>
                                            <Button key="one" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} disabled={sameObjectCount <= 1} onClick={() => setSameObjectCount(sameObjectCount > 1 ? sameObjectCount - 1 : 1)}>
                                                <Remove fontSize='inherit'/>
                                            </Button>
                                            <input
                                                type="number"
                                                style={{border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center", height: "25px"}}
                                                value={sameObjectCount || null}
                                                onChange={handleSameCount}
                                                onWheel={(event) => event.target.blur()}
                                                
                                                onKeyDown={(e) => {
                                                    if(['e', 'E', '+', '-'].includes(e.key)){
                                                        e.preventDefault();
                                                    }
                                                    else if(e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)){
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                            {/* <Button key="two" disabled size='small' sx={{...buttonClasses.smallOrange, border: "1px solid #FF2700", backgroundColor: "white", fontSize: "12px"}}>
                                                {sameObjectCount}
                                            </Button> */}
                                            <Button key="three" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} disabled={sameObjectCount >= remainingObjects} onClick={() => setSameObjectCount(sameObjectCount < remainingObjects ? sameObjectCount + 1 : remainingObjects)}>
                                                <Add fontSize='inherit'/>
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                }
                                
                                <Grid container sx={{p: 2, pb: 3}}>
                                    {
                                        spiType == "SPI" &&
                                        <Button size='small' variant='contained' sx={{...buttonClasses.outlined, ...textClasses.boldText, maxHeight: "36px", color: theme.themeOrange, pl: 3, pr: 3, mr: 3}} onClick={handleSubmit(handlePackageAddAndItem)}>
                                            Submit & Add Item Details
                                        </Button>
                                    }
                                    
                                    <Button size='small' variant='contained' sx={{...buttonClasses.orange, ...textClasses.boldText, maxHeight: "36px", minHeight: "40px", color: "white"}} onClick={handleSubmit((data) => handlePackageAddAndOther(data))}>
                                        Submit & Add Other {sectionKey} details
                                    </Button>
                                </Grid>
                                
                            </>
                        }
                        {
                            bundleType == "itemToPackage" &&
                            <>
                                {
                                    spiType == "SPI" && 

                                    <ItemtoPackageSection
                                        asn={asn}
                                        spiType={spiType}
                                        sectionKey={sectionKey}
                                        open={openI2P}
                                        handleCloseModal={handleCloseI2P}
                                        totalObjectCount={totalObjectCount}
                                        template={template}
                                        pIndex={0}
                                        packages={itemsToAdd}
                                        setPackages={setItemsToAdd}
                                        packageObj={itemsToAdd[0] || {}}
                                        addItems={addItemsI2P}
                                        objects={objects}
                                        setObjects={setObjects}
                                        PackageList={PackageList}
                                        packageFields={allFields}
                                        // packageFields={mandatoryFields.concat(allFields)}
                                        idSettings={inventorySettings ? inventorySettings.item : null}
                                        isManual={isManual}
                                        setIsManual={setIsManual}
                                        DynamicForm={DynamicForm}
                                        handlePackageAddAndOther={handlePackageAddAndOther}
                                        handleSubmit={handleSubmit}
                                        setValue={setValue}
                                        bundleType={bundleType}
                                    />
                                }
                            </>
                        }
                        
                    </Paper>
                :
                    <>
                        {
                            objects.length > 0 &&
                            <Paper elevation={1}>
                                { SectionHeader }
                                {
                                    PackageList
                                    // spiType == "SPI"
                                    // ?
                                    //     PackageList
                                    // :
                                    //     <Grid container sx={{p: 2}}>
                                    //         <Button variant='outlined' sx={{...buttonClasses.lynkitOrangeEmpty}} onClick={() => setAsnPIndex(0)}>View Added {sectionKey}</Button>
                                    //     </Grid>
                                }
                            </Paper>
                        }
                    </>
            }
                    
            {
                asn && 
                <Paper elevation={1} sx={{mt: 2}}>
                    <AsnSection 
                        template={template}
                        asn={asn}
                        asnTemplateHeader={asnTemplateHeader}
                        spiType={spiType}
                        objects={objects}
                        setObjects={setObjects}
                        inventorySettings={inventorySettings}
                        receivingMethod={receivingMethod}
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                        openAsnPackageTable={openAsnPackageTable}
                        setOpenAsnPackageTable={setOpenAsnPackageTable}
                        receivingTypeOptions={receivingTypeOptions}
                        receivingConfig={receivingConfig}
                    />
                </Paper>
            }
            
        </>
    )
}

