export const removeUntilE = (str) => {


    if (str === "rejected") {
        return "Reject";
    } else if (str === "approved") {
        return "Approve";
    } else {
        return str;
    }
}