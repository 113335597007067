import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import {
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Button,
  TextField,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Chip,
  Paper,
  IconButton,
  Menu,
  Tooltip,
  setRef,
  Autocomplete,
  FormControlLabel,
  Radio,
} from "@mui/material";
// import { makeStyles } from '@mui/system'
import { DataGrid } from "@mui/x-data-grid";
// import EmptyPage from './EmptyPage';
import {
  Add,
  ArrowDownward,
  Cancel,
  Clear,
  Delete,
  ExpandMore,
  KeyboardArrowDown,
  Search,
  WrongLocation,
} from "@mui/icons-material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTemplateStatus,
  getAllTemplates,
  getAllUsers,
  getStatusCount,
  removeTemplates,
  getTemplateTypes,
  getAllASN,
  getASNStatusCount,
  rejectAsn,
  closeAsn,
  getAsnDetails,
  getTemplateHeader,
} from "../../../redux/actions";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import { Link } from "react-router-dom";
// import TemplatesTable from "./TemplatesTable"
import TemplatesTable from "./Table";

import {
  templateTypes,
  statusTypes,
  getTemplateTypeName,
} from "../../../services/constants";
import {
  capitalizeSentence,
  getCamelCaseText,
  getShortText,
  showToast,dataReadFunction,
} from "../../../services/functions";
import { useForm } from "react-hook-form";
import history from "../../../services/history";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllInventoryAdjustment } from "../../../redux/actions/InventoryAdjustment";
import { FETCH_ASN_DETAIL, FETCH_TEMPLATE_HEADER } from "../../../redux/types";
import GenericCustomiseHeader from "../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../utils/hooks";
import GenericLoader from "../../Components/Generic/GenericLoader";
import CloseDialog from "./CloseDialog";

const ConfirmDialog = ({
  status,
  handleClose,
  selectedEntries,
  setSelectedEntries,
  refreshTableData,
  handleRemoveSelection,
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const size = selectedEntries.length;
  const [reason, setReason] = useState("");

  const callBack = (data) => {
    showToast(data.message, false);
    refreshTableData();
    handleClose();
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
  };

  const handleStatusChange = () => {
    let payload = {
      ASN_Id:
        selectedEntries?.length == 1
          ? selectedEntries[0].ASN_Id
          : selectedEntries?.map((e) => e.ASN_Id),
      reason: reason,
    };
    dispatch(
      rejectAsn(payload, (data) => {
        if (data) {
          // showToast(data.message, false);
          // handleClose();
          // refreshContent(asn.ASN_Id);
          callBack(data);
        }
      })
    );
  };

  useEffect(() => {
    setReason("");
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            You want to {status} the ASN
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedEntries?.slice(0, showAll ? size : 5)?.map((st, i) => {
            return (
              <Grid item sm={4} xs={6} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {st.ASN_Id && st.ASN_Id.length > 15
                        ? st.ASN_Id.substring(0, 15) + "..."
                        : st.ASN_Id}
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st._id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container direction={"row"} sx={{ mt: 1 }}>
          <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Grid>
        <Grid container sx={{ p: 1, mt: 3 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              height: "40px",
              minWidth: "150px",
            }}
            onClick={handleClose}
          >
            No, Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.small,
              backgroundColor: theme.themeOrange,
              minWidth: "150px",
            }}
            disabled={selectedEntries.length <= 0}
            onClick={handleStatusChange}
          >
            Yes, {status}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ReceiveDialog = ({ status, entry, handleClose }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [method, setMethod] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const asnDetail = useSelector((state) => state.asnEntry.asnDetail);
  const templateHeader = useSelector((state) => state.inventory.templateHeader);
  ////("Entry", entry);

  const getSpiType = (template) => {
    if (template) {
      let type;
      if (!template.otherTemplate || template.otherTemplate.length == 0) {
        type = "shipmentOnly";
      } else if (template.otherTemplate.length == 2) {
        type = "SPI";
      } else if (
        template.otherTemplate.length == 1 &&
        template.otherTemplate[0].templateType == "packageDetailTemplate"
      ) {
        type = "shipmentPackageOnly";
      } else {
        type = "shipmentItemOnly";
      }

      return type;
    }
  };

  const allTemplates = useSelector(state => state.templates.allTemplates).filter((t) => {
    if (["packagingPI", "palletingPI", "bothPackageItem", "bothPalletItem"].includes(method) && getSpiType(t) == "SPI") {
      return true;
    }
    if (["pallets", "packages"].includes(method) && ["SPI", "shipmentPackageOnly"].includes(getSpiType(t))) {
      return true;
    }
    // else if(method == "bothPackageItem" && getSpiType(t) == "SPI"){
    //     return true;
    // }
    // else if ((method == "packagingPI" || method == "palletingPI") && getSpiType(t) == "SPI") {
    //     return true;
    // }
    else if (method == "individualItems" && getSpiType(t) == "shipmentItemOnly") {
      return true;
    }
    return false;
  });

  let allMethods = [
    { label: "Pallets", value: "pallets", templateType: ["SPI", "SP"], isBoth: true, isPackage: true },
    { label: "Packages", value: "packages", templateType: ["SP", "SPI"], isPackage: true, isBoth: true },
    { label: "Both(Package & Item)", value: "bothPackageItem", templateType: ["SPI"], isBoth: true },
    { label: "Both(Pallet & Item)", value: "bothPalletItem", templateType: ["SPI"], isBoth: true },
    { label: "Loose or Individual Items", value: "individualItems", templateType: ["SI"], isItem: true },
    // {label:"Only Quantity",value:"onlyQuantity",templateType:["S"],isBoth:false,isPackage:false,isItem:false},
    // {label: "Packaging (Package & Item)",value: "packagingPI",templateType: ["SPI"],isBoth: true},
    // {label: "Palleting (Package & Item)",value: "palletingPI",templateType: ["SPI"],isBoth: true},
  ]

  // const handleStatusChange = () => {
  //     dispatch(
  //         rejectAsn({
  //             ASN_Id: asn.ASN_Id,
  //             reason: reason
  //         }, (data) => {
  //             if(data){
  //                 showToast(data.message, false);
  //                 handleClose();
  //                 refreshContent(asn.ASN_Id);
  //             }
  //         })
  //     )
  // }

  useEffect(() => {
    setReason("");
    if (status == "receive" && entry) {
      dispatch(
        // getAllTemplates({type: "wms", templateType: "inwardDataEntry", status: "published"}, (data) => {

        // })
        getAllTemplates(
          {
            type: "wms",
            templateType: "inwardDataEntry",
            status: "published",
            fetchAttributes: true,
          },
          (data) => {
            if (data && data.length == 1) {
              setSelectedTemplate(data[0]);
            }
          }
        )
      );
      // dispatch(getAsnDetails({ ASN_ID: entry.ASN_Id,projection:{ASN_Id:1},shipmentProjection:{ASN_Id:1} }));
      dispatch(getTemplateHeader({ template_id: entry.template_id }));
    }
  }, [status]);

  return (
    <Dialog
      open={status != null}
      onClose={handleClose}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        {status == "receive" && (
          <>
            <Grid container justifyContent={"center"} alignItems="center">
              <Typography sx={{ ...textClasses.cardTitle }}>
                Select Inward Data Entry Template & Receiving Method
              </Typography>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid container item sm={4}>
                <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                  Receiving Method :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
                {allMethods?.map((m, i) => {
                  return (
                    <Grid item sm={6} key={i}>
                      <FormControlLabel
                        onClick={() => setMethod(m.value)}
                        checked={method == m.value}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {m.label}
                          </Typography>
                        }
                        control={
                          <Radio
                            value={m.value}
                            sx={{
                              "&, &.Mui-checked": { color: theme.themeOrange },
                            }}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid container item sm={4}>
                <FormLabel required sx={{ mt: 1, color: "#000000" }}>
                  Select Template :
                </FormLabel>
              </Grid>
              <Grid container item sm={8}>
                <Autocomplete
                  value={selectedTemplate}
                  onChange={(e, option) => {
                    setSelectedTemplate(option);
                  }}
                  getOptionLabel={(option) =>
                    capitalizeSentence(option.name || "")
                  }
                  size="small"
                  options={allTemplates}
                  sx={{
                    ...inputClasses.filterField,
                    minWidth: "220px",
                    m: 0,
                    ".MuiInputBase-input": {
                      fontSize: "14px",
                      color: "#455A64",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#455A64", ...textClasses.normalText }}
                      {...params}
                      label="Template"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ p: 1, mt: 3 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  height: "40px",
                  minWidth: "150px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 1,
                  ...buttonClasses.small,
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                }}
                disabled={selectedTemplate == null || method == null}
                onClick={() =>
                  history.push("/dashboard/data/inward", {
                    asnDetail: {ASN_Id:entry?.ASN_Id},
                    // asnDetail: asnDetail,
                    asnTemplateHeader: templateHeader,
                    inwardTemplate: selectedTemplate,
                    receivingMethod: method,
                  })
                }
              >
                {status == "receive" ? "Receive" : { status }}
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const getSpentTime = (then, now) => {
  if (then != null) {
    then = moment(then);
    now = now ? moment(now) : moment();

    let dayDiff = now.diff(then, "days");
    let hourDiff = now.diff(then, "hours");
    let minDiff = now.diff(then, "minutes");
    let timeSpent;

    if (dayDiff < 1) {
      if (hourDiff < 1) {
        timeSpent = `${minDiff} min ago`;
      } else {
        timeSpent = `${hourDiff} hrs ago`;
      }
    } else {
      timeSpent = `${dayDiff} d ago`;
    }
    return timeSpent;
  } else {
    return "-";
  }
};

export default function AdvanceShipment() {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.allTemplates);
  const adjustments = useSelector(
    (state) => state.inventoryAdjustment.allAdjustments
  );
  // const totalRecords = useSelector(state => state.templates.totalRows)
  const advanceShipment = useSelector((state) => state.asnEntry.allASN);
  const totalRecords = useSelector((state) => state.asnEntry.totalRows);

  const statusCount = useSelector((state) => state.asnEntry.statusCount);
  const { AllUserData } = useSelector((state) => state.users);
  const allTemplateTypes = useSelector(
    (state) => state.templates.templateTypes
  );
  const [tabIndex, setTabIndex] = useState(0);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loader, setLoader] = useState(false);

  const [idFilter, setIdFilter] = useState("");
  const [poFilter, setPoFilter] = useState("");
  const [product_IDFilter, setProduct_IDFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [receivedAt, setReceivedAt] = useState(null);
  const statusList = [
    // { label: "Partially Received", value: "partiallyReceived" },
    // { label: "Fully Received", value: "received" },
    { label: "Incoming", value: "incoming" },
    { label: "Partially Received", value: "partialReceived" },
    { label: "Received", value: "received" },
    { label: "Rejected", value: "rejected" },
    { label: "Drafted", value: "drafted" },
  ];
  // //(statusList,"stttats")
  const debouncedIdFilter = useDebounce(idFilter, 500);
  const debouncedPoFilter = useDebounce(poFilter, 500);
  const debouncedProduct_IDFilter = useDebounce(product_IDFilter, 500);

  const isFilterApplied = (() => {
    if (tabIndex == 0 && statusFilter) {
      return true;
    }
    if (idFilter || poFilter | createdAt || receivedAt || product_IDFilter) {
      return true;
    }
    return false;
  })();

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const openAction = Boolean(anchorEl);

  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };
  const handleCloseAction = () => {
    setActionConfirmModal(null);
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const [selectedEntries, setSelectedEntries] = useState([]);
  ////("Selected Entries", selectedEntries);

  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [confirmModal, setConfirmModal] = useState(null);
  const [closeModal, setCloseModal] = useState(null);
  const [actionConfirmModal, setActionConfirmModal] = useState(null);
  const [receiveModal, setReceiveModal] = useState(null);
  const [actionReceiveModal, setActionReceiveModal] = useState(null);
  const [closeReason, setCloseReason] = useState("")

  const isSectionRejectable = () => {
    let flag = true;
  };

  const isSelectionRejectable = (() => {
    let flag = true;
    if (
      selectedEntries.filter((st) => ["incoming"].indexOf(st.status) < 0)
        .length > 0
    ) {
      flag = false;
    }
    return flag;
  })();

  const isSelectionDisable = (() => {
    let flag = true;
    if (
      selectedTemplates.filter(
        (st) => st.status == "drafted" || st.status == "disabled"
      ).length > 0
    ) {
      flag = false;
    }
    return flag;
  })();

  const handleTabChange = (event, value) => {
    switch (value) {
      case 0:
        setStatusFilter(null);
        break;
      case 1:
        setStatusFilter(statusList[0]);
        break;
      case 2:
        setStatusFilter(statusList[1]);
        break;
      case 3:
        setStatusFilter(statusList[2]);
        break;
      case 4:
        setStatusFilter(statusList[3]);
        break;
      case 5:
        setStatusFilter(statusList[4]);
        break;
    }
    setPage(0);
    setTabIndex(value);
  };

  const handleCheckAll = () => {
    if (selectedEntries.length < advanceShipment.length) {
      let sT = advanceShipment?.map((t) => ({
        _id: t._id,
        status: t.status,
        name: t.name,
        ASN_Id: t.ASN_Id,
        totalQuantity: t.totalQuantity,
        receivedQuantity: t.receivedQuantity,
        rejectedQuantity: t.rejectedQuantity,
        remainingQuantity: t.remainingQTY,
        closedQuantity: t.closedQuantity,
        exceedQuantity: t.exceedQuantity,
      }));
      setSelectedEntries(sT);
    }
    else {
      setSelectedEntries([]);
    }
  };

  //"Action"
  const [tableName, tableType] = ["asnHeader", "ASNTable"];

  const tableHeader = useSelector((state) => state.customHeader["asnHeader"]);

  //   let validHeaderIds =
  //     tableHeader && tableHeader.validHeader
  //       ? tableHeader.validHeader.map((h) => h.fieldKey)
  //       : [];
  let header =
    tableHeader && tableHeader?.validHeader?.length > 0
      ? tableHeader?.validHeader?.map((h) => {
        if (h.handleType === "selectRow") {
          return (
            <Checkbox
              data-testid="checkbox"
              checked={
                advanceShipment.length != 0 &&
                selectedEntries.length == advanceShipment.length
              }
              onChange={handleCheckAll}
              size="small"
            />
          )
        }
        return h.fieldName
      })
      : [];

  const filterIndex = {
    ASN_Id:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`ASN_Id`)
        : -1,
    purchaseOrderID:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`purchaseOrderID`)
          : -1,
    product_IDs:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`product_IDs`)
        : -1,
    status:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`status`)
        : -1,
    receivedDate:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`receivedDate`)
        : -1,
    submissionDate:
      tableHeader && tableHeader?.validHeader?.length > 0
        ? tableHeader?.validHeader?.map((h) => h.mappingPath).indexOf(`submissionDate`)
        : -1,
  };

  // const getTableCell = (id, row, headerData) => {

  //    if(headerData?.isFromRenderFunction === false){

  //     return (
  //         <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
  //             {/* {row.receivedDate ? moment(row.receivedDate).format("DD MMM YYYY hh:mm A"): "-"} */}
  //             {row[row.mappingPath] ?? "-" }
  //         </Typography>
  //     )

  //    }

  //     if (id == "ASN_Id") {
  //         return (
  //             <Link to={`/dashboard/data/view-asn/${row.ASN_Id}`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange, textAlign: "center" }}>
  //                 {row.ASN_Id ? row.ASN_Id: "-"}
  //             </Link>
  //         )
  //     }
  //     else if (id == "purchaseOrderID") {
  //         return (
  //             <Link to={`/dashboard/data/view-asn/${row.ASN_Id}`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange, textAlign: "center" }}>
  //                 {row.purchaseOrderID? row.purchaseOrderID: "-"}
  //             </Link>
  //         )
  //         // return (
  //         //     <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
  //         //         {row.purchaseOrderID? row.purchaseOrderID: "-"}
  //         //     </Typography>
  //         // )
  //     }
  //     else if (id == "product_IDs") {
  //         return (
  //             <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
  //                 {
  //                     row.product_IDs
  //                         ? Array.isArray(row.product_IDs) ? row.product_IDs.join(", ") : row.product_IDs
  //                         : "-"
  //                 }
  //             </Typography>
  //         )
  //     }
  //     else if (id == "totalQuantity") {
  //         return (
  //             <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
  //                 {row.totalQuantity? row.totalQuantity: "-"}
  //             </Typography>
  //         )
  //     }
  //     else if(id == "status"){
  //         const statusColor = {
  //             partialreceived: "#3E621E",
  //             received: "#3E621E",
  //             rejected: "#E9001A",
  //             drafted: "#FF0101",
  //             incoming: "#FF7200",
  //             default: "#000"
  //         }
  //         const statusText = {
  //             partialreceived: "Partially Received",
  //             received: "Received",
  //             rejected: "Rejected",
  //             drafted: "Drafted",
  //             incoming: "Incoming"

  //         }

  //         return (
  //             <>
  //             {
  //                 row.status=="drafted"?
  //                 <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase() ] : "-"}</Typography>
  //                 :
  //                 <></>

  //             }
  //             {
  //                 row.status=="incoming"?
  //                 <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase()] : "-"} ({row.totalQuantity})</Typography>
  //                 :
  //                 <>
  //                 </>

  //             }
  //             {
  //                 row.status=="received"|| row.status=="partialReceived"?
  //                 <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase()] : "-"} ({row.receivedQuantity})</Typography>
  //                 :
  //                 <></>

  //             }

  //             </>
  //         )
  //     }
  //     else if(id == "submissionDate"){
  //         return (
  //             <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
  //                 {moment(row.submissionDate).format("DD MMM YYYY hh:mm A")}
  //             </Typography>
  //         )
  //     }
  //     else if(id == "receivedDate"){
  //         return (
  //             <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
  //                 {row.receivedDate ? moment(row.receivedDate).format("DD MMM YYYY hh:mm A"): "-"}
  //             </Typography>
  //         )
  //     }
  //     else{
  //         return (
  //             <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
  //                 {"-"}
  //             </Typography>
  //         )
  //     }
  // }

  const getTableCell = (header, row, i) => {
    let value = ""
    if (header?.mappingPath) {
      value = dataReadFunction({ name: header?.mappingPath || "" }, row)
      if ((!value || (Array.isArray(value) || value.length == 0)) && header?.mappingPathKeys?.length > 0) {
        for (let ele of header?.mappingPathKeys) {
          value = dataReadFunction({ name: ele || "" }, row)
          if (value && (Array.isArray(value) ? value.length > 0 : true)) {
            break;
          }
        }
      }
    }

    if (header?.handleType) {
      if (header?.handleType === "selectRow") {
        return (
          <Checkbox
            size="small"
            checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
            onChange={(e) => handleCheckChange(e, row)}
          />
        );
      }
      else if (header?.handleType === "showDetail") {
        return (
          <Link
            to={`/dashboard/data/view-asn/${row.ASN_Id}`}
            style={{
              ...textClasses.normalText,
              textDecoration: "none",
              color: theme.themeOrange,
              textAlign: "center",
            }}
          >
            {value ?? "-"}
          </Link>
        );
      }
      else if (header?.handleType === "action") {
        return (
          <Typography
            data-testid="dropdown"
            sx={{
              ...textClasses.normalText,
              color: theme.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, i)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      }
    }
    else if (header?.isFromRenderFunction === true) {
      if (header?.renderFunction === "srNo") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {page == 0 ? i + 1 : (page * limit) + (i + 1)}
          </Typography>
        );
      } else if (header?.renderFunction === "status") {
        const statusColor = {
          partialreceived: "#3E621E",
          received: "#3E621E",
          rejected: "#E9001A",
          drafted: "#FF0101",
          incoming: "#FF7200",
          default: "#000",
        };
        const statusText = {
          partialreceived: "Partially Received",
          received: "Received",
          rejected: "Rejected",
          drafted: "Drafted",
          incoming: "Incoming",
        };

        return (
          <>
            {row.status == "drafted" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"}
              </Typography>
            ) : (
              <></>
            )}
            {row.status == "incoming" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"} (
                {row.totalQuantity})
              </Typography>
            ) : (
              <></>
            )}
            {row.status == "received" || row.status == "partialReceived" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"} (
                {row.receivedQuantity})
              </Typography>
            ) : (
              <></>


            )}
            {row.status == "rejected" ? (
              <Typography
                sx={{
                  ...textClasses.normalText,
                  textAlign: "center",
                  color:
                    statusColor[
                    row.status ? row.status.toLowerCase() : "default"
                    ],
                }}
              >
                {row.status ? statusText[row.status.toLowerCase()] : "-"}
              </Typography>
            ) : (
              <></>
            )}



          </>
        );
      }
    }
    else {
      if (header?.fieldType === "array") {
        return (
          <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {header.mappingPath && value
              ? Array.isArray(value)
                ? value.join(", ")
                : value
              : "-"}
          </Typography>
        );
      } else if (header?.fieldType === "date") {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath && value
              ? moment(value).format(
                header?.format || "DD MMM YYYY hh:mm A"
              )
              : "-"}
          </Typography>
        );
      } else {
        return (
          <Typography
            sx={{
              ...textClasses.normalText,
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {header.mappingPath ? value : "-"}
          </Typography>
        );
      }
    }
  };

  // const tableHeader = [<Checkbox data-testid="checkbox" checked={advanceShipment.length != 0 && selectedEntries.length == advanceShipment.length} onChange={handleCheckAll} size='small' />,"S.No","ASN ID", "W.O./P.O. Number", "Ordered QTY", "Status", "ASN Submission at", "ASN Received at", "Action"]
  const tableRows = advanceShipment?.map((row, i) => {
    let items = [];

    const validHeaders = tableHeader?.validHeader ?? [];
    for (const header of validHeaders) {
      items.push(getTableCell(header, row, i));
    }

    // for (let j = 0; j < validHeaderIds.length; j++) {
    //     if(validHeaderIds[j] == "srNo"){
    //         items.push(
    //             <Typography sx={{...textClasses.normalText, textAlign: "center"}}>{i + 1}</Typography>
    //         )
    //     }
    //     else if(validHeaderIds[j] == "checkbox"){
    //         items.push(
    //             <Checkbox
    //                 size="small"
    //                 checked={selectedEntries.map(o => o._id).indexOf(row._id) >= 0}
    //                 onChange={(e) => handleCheckChange(e, row)}
    //             />
    //         )
    //     }
    //     else if(validHeaderIds[j] == "action"){
    //         items.push(
    //             <Typography data-testid="dropdown" sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
    //         )
    //     }
    //     else{
    //         const headerData = tableHeader.validHeader?.find((header)=> header.fieldKey === validHeaderIds[j])
    //         items.push(
    //             getTableCell(validHeaderIds[j], row, headerData)
    //         )
    //     }
    // }
    // items.push(
    //     <Checkbox
    //         size="small"
    //         checked={selectedEntries.map(o => o._id).indexOf(row._id) >= 0}
    //         onChange={(e) => handleCheckChange(e, row)}
    //     />
    // );
    // items.push(
    //     <>
    //     {i+1}
    //     </>
    // )
    // items.push(
    //     <Link to={`/dashboard/data/view-asn/${row.ASN_Id}`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange, textAlign: "center" }}>
    //             {row.ASN_Id ? row.ASN_Id: "-"}
    //     </Link>
    // );
    // items.push(
    //     <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
    //         {row.purchaseOrderID? row.purchaseOrderID: "-"}
    //     </Typography>
    // );
    // items.push(
    //     <Typography sx={{...textClasses.normalText, textAlign: "center"}}>
    //         {row.totalQuantity? row.totalQuantity: "-"}
    //     </Typography>
    // );
    // // items.push(
    // //     <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange, textDecoration: "underline" }}>
    // //         {
    // //             row.adjustmentCategory.actionType == "Add" ? "+" : "-"
    // //         }
    // //         {
    // //             row.adjustmentCategory.quantity
    // //         }
    // //     </Typography>
    // // );

    // // items.push(
    // //     <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
    // //         {row.referenceNumber}
    // //     </Typography>
    // // );

    // const statusColor = {
    //     partialreceived: "#3E621E",
    //     received: "#3E621E",
    //     rejected: "#E9001A",
    //     drafted: "#FF0101",
    //     incoming: "#FF7200",
    //     default: "#000"
    // }
    // const statusText = {
    //     partialreceived: "Partially Received",
    //     received: "Received",
    //     rejected: "Rejected",
    //     drafted: "Drafted",
    //     incoming: "Incoming"

    // }

    // items.push(
    //     <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status ? row.status.toLowerCase() : "default"] }}>{row.status ? statusText[row.status.toLowerCase() ] : "-"}</Typography>
    // );

    // items.push(
    //     <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
    //         {moment(row.submissionDate).format("DD MMM YYYY hh:mm A")}
    //     </Typography>
    // );

    // items.push(
    //     <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform:"none" }}>
    //         {row.receivedDate ? moment(row.receivedDate).format("DD MMM YYYY hh:mm A"): "-"}
    //     </Typography>
    // );

    // // items.push(
    // //     <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
    // //         {row.reasonKey}
    // //     </Typography>
    // // );

    // // // actions
    // items.push(
    //     <Typography data-testid="dropdown" sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
    // )
    return items;
  });

  ////("Table Header", validHeaderIds);
  ////("Table rows", tableRows);

  const handleCheckChange = (event, row) => {
    if (event.target.checked) {
      handleAddSelection(row);
    } else {
      handleRemoveSelection(row._id);
    }
  };

  const handleFilterChange = (data) => {
    ////(data);
  };

  const handleAddSelection = (obj) => {
    setSelectedEntries([...selectedEntries, obj]);
  };

  const handleRemoveSelection = (id) => {
    let oldEntries = [...selectedEntries];
    const i = oldEntries?.map((o) => o._id)?.indexOf(id);
    if (i >= 0) {
      oldEntries.splice(i, 1);
      setSelectedEntries(oldEntries);
      if (oldEntries.length == 0) {
        handleCloseConfirmModal();
      }
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(null);
  };

  const handleCloseReceiveModal = () => {
    setReceiveModal(null);
  };

  const handleCloseActionReceiveModal = () => {
    setActionReceiveModal(null);
  };

  const handleClearFilter = () => {
    setIdFilter("");
    setPoFilter("");
    setCreatedAt(null);
    setReceivedAt(null);
    setProduct_IDFilter("");
    setPage(0);
    setLimit(10);
    if (tabIndex == 0) {
      setStatusFilter(null);
    }
  };

  // useEffect(() => {
  //     dispatch(getAllUsers({ "isAdminIncluded": true }));
  //     dispatch(
  //         getTemplateTypes()
  //     )
  // }, [])

  const refreshTableData = (pageNo, limitNo) => {
    setLoader(true);
    const payload = {
      page: pageNo,
      limit: limitNo,
    };
    if (idFilter && idFilter != "") {
      payload["ASN_ID"] = idFilter;
    }
    if (poFilter && poFilter != "") {
      payload["purchaseOrderID"] = poFilter;
    }
    if (product_IDFilter && product_IDFilter != "") {
      payload["product_ID"] = product_IDFilter;
    }
    if (statusFilter != null) {
      payload["status"] = statusFilter.value;
    }
    if (createdAt && createdAt != null) {
      payload["submissionDate"] = createdAt;
    }
    if (receivedAt && receivedAt != null) {
      payload["receivedDate"] = receivedAt;
    }

    dispatch(
      getAllASN(payload, () => {
        setLoader(false);
      })
    );
    payload.status = "";
    dispatch(getASNStatusCount(payload));

    // Do not remove these, View ASN depends on these 2
    dispatch({ type: FETCH_ASN_DETAIL, payload: { data: null, count: 0 } });
    dispatch({ type: FETCH_TEMPLATE_HEADER, payload: null });
  };

  let fetchData = () => {
    refreshTableData(page + 1, limit);
    setSelectedTemplates([]);
    setConfirmModal(null);
    setReceiveModal(null);
    setActionConfirmModal(null);
    setAnchorEl(null);
    setActionIndex(null);
  };

  useEffect(() => {
    fetchData();
  }, [statusFilter, createdAt, receivedAt, page, limit]);

  useEffect(() => {
    fetchData();
  }, [debouncedIdFilter, debouncedPoFilter, debouncedProduct_IDFilter]);

  const handleCloseModal = useCallback(() => {
    setCloseModal(null)
    setCloseReason("")
  }, [])

  const callBack = (data) => {
    showToast(data.message, false);
    refreshTableData();
    handleCloseModal();
    if (setSelectedEntries) {
      setSelectedEntries([]);
    }
  };

  const handleCloseConfirm = () => {
    let payload = {
      ASN_Id:
        actionIndex !== null ? advanceShipment[actionIndex].ASN_Id : selectedEntries.length == 1
          ? selectedEntries[0].ASN_Id
          : selectedEntries?.map((e) => e.ASN_Id),
      closingType: "ASN",
      reason: closeReason,
    };
    dispatch(
      closeAsn(payload, (data) => {
        if (data) {
          callBack(data);
        }
      })
    );
  };

  return (
    <>
      {
        // !loader && templates.length == 0
        // ?
        //     <EmptyPage/>
        // :
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                Advance Shipment Notice
              </Typography>
            </Grid>
            <Grid container item sm={6} justifyContent="end" alignItems="end">
              {permissions &&
                (permissions.permissions || []).some(
                  (item) => item.permissionId === "createASN"
                ) ? (
                <Button
                  data-testid="add"
                  variant="outlined"
                  size="small"
                  startIcon={<Add />}
                  sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                  onClick={() => history.push("/dashboard/data/asn")}
                >
                  Create New
                </Button>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
            <Tabs
              value={tabIndex}
              aria-label="disabled tabs example"
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
                "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
              }}
            >
              <Tab
                label={`All ASN (${statusCount && statusCount.totalCount
                    ? statusCount.totalCount
                    : 0
                  })`}
                {...a11yProps(0)}
                sx={{ ...textClasses.normalText, textTransform: "none" }}
              />
              {statusList?.map((s, i) => {
                return (
                  <Tab
                    data-testid={`${s.label}`}
                    label={`${s.label} (${statusCount &&
                        statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value}Count`
                        ]
                        ? statusCount[
                        `${s.value == "incoming" ? "upcomming" : s.value
                        }Count`
                        ]
                        : 0
                      })`}
                    {...a11yProps(1)}
                    sx={{ ...textClasses.normalText, textTransform: "none" }}
                    key={i}
                  />
                  // <Tab  data-testid= {`${s.label}`} label={`${s.label} (${statusCount && statusCount[`${s.value}Count`] ? statusCount[`${s.value}Count`]: 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} key={i} />
                );
              })}
            </Tabs>
          </Box>
          <Grid container spacing={2}>
            {filterIndex.ASN_Id >= 0 && tableHeader.validHeader[filterIndex.ASN_Id]?.isSearchable === true && (
              <Grid item sx={4} sm={2}>
                <FormControl variant="outlined" size="small">
                  <OutlinedInput
                    data-testid="search-input"
                    value={idFilter}
                    onChange={(e) => {
                      setIdFilter(e.target.value);
                      setPage(0);
                      setLimit(10);
                    }}
                    placeholder={`Search by ${tableHeader.validHeader[filterIndex.ASN_Id].fieldName
                      }`}
                    sx={{
                      ...inputClasses.filterField,
                      "& input::placeholder": { fontSize: "14px" },
                      "& .MuiSelect-iconOutlined": {
                        display: idFilter != "" ? "none" : "",
                      },
                    }}
                    endAdornment={
                      idFilter == "" ? (
                        <InputAdornment position="end">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ) : (
                        <IconButton
                          sx={{
                            visibility: idFilter != "" ? "visible" : "hidden",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setIdFilter("");
                            setPage(0);
                            setLimit(10);
                          }}
                        >
                          <Clear fontSize="inherit" />
                        </IconButton>
                      )
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {filterIndex.purchaseOrderID >= 0 && tableHeader.validHeader[filterIndex.purchaseOrderID]?.isSearchable === true && (
              <Grid item sx={4} sm={2}>
                <FormControl variant="outlined" size="small">
                  <OutlinedInput
                    data-testid="poNumber"
                    value={poFilter}
                    onChange={(e) => {
                      setPoFilter(e.target.value);
                      setPage(0);
                      setLimit(10);
                    }}
                    placeholder={`Search by ${tableHeader.validHeader[filterIndex.purchaseOrderID]
                        .fieldName
                      }`}
                    sx={{
                      ...inputClasses.filterField,
                      "& input::placeholder": { fontSize: "14px" },
                      "& .MuiSelect-iconOutlined": {
                        display: poFilter != "" ? "none" : "",
                      },
                    }}
                    endAdornment={
                      poFilter == "" ? (
                        <InputAdornment position="end">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ) : (
                        <IconButton
                          sx={{
                            visibility: poFilter != "" ? "visible" : "hidden",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setPoFilter("");
                            setPage(0);
                            setLimit(10);
                          }}
                        >
                          <Clear fontSize="inherit" />
                        </IconButton>
                      )
                    }
                  />
                </FormControl>
              </Grid>
            )}
            {filterIndex.product_IDs >= 0 && tableHeader.validHeader[filterIndex.product_IDs]?.isSearchable === true && (
              <Grid item sx={4} sm={2}>
                <FormControl variant="outlined" size="small">
                  <OutlinedInput
                    data-testid="poNumber"
                    value={product_IDFilter}
                    onChange={(e) => {
                      setProduct_IDFilter(e.target.value);
                      setPage(0);
                      setLimit(10);
                    }}
                    placeholder={`Search by ${tableHeader.validHeader[filterIndex.product_IDs].fieldName
                      }`}
                    sx={{
                      ...inputClasses.filterField,
                      "& input::placeholder": { fontSize: "14px" },
                      "& .MuiSelect-iconOutlined": {
                        display: poFilter != "" ? "none" : "",
                      },
                    }}
                    endAdornment={
                      product_IDFilter == "" ? (
                        <InputAdornment position="end">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ) : (
                        <IconButton
                          sx={{
                            visibility:
                              product_IDFilter != "" ? "visible" : "hidden",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setProduct_IDFilter("");
                            setPage(0);
                            setLimit(10);
                          }}
                        >
                          <Clear fontSize="inherit" />
                        </IconButton>
                      )
                    }
                  />
                </FormControl>
              </Grid>
            )}

            {filterIndex.status >= 0 && tableHeader.validHeader[filterIndex.status]?.isSearchable === true && tabIndex === 0 && (
              <Grid item sx={4} sm={2}>
                <Autocomplete
                  data-testid="status"
                  value={statusFilter}
                  onChange={(e, option) => {
                    setStatusFilter(option);
                    setPage(0);
                    setLimit(10);
                  }}
                  // getOptionLabel={(option) => option.label}
                  size="small"
                  fullWidth
                  options={statusList}
                  sx={{
                    ...inputClasses.filterField,
                    ".MuiInputBase-input": {
                      fontSize: "15px",
                      color: "#455A64",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "#455A64", ...textClasses.normalText }}
                      {...params}
                      label={`${tableHeader.validHeader[filterIndex.status].fieldName
                        }`}
                    />
                  )}
                />
              </Grid>
            )}
            {filterIndex.receivedDate >= 0 && tableHeader.validHeader[filterIndex.receivedDate]?.isSearchable === true && (
              <Grid item sx={4} sm={2}>
                <DatePicker
                  data-testid="receivedAt"
                  value={receivedAt}
                  onChange={(d) => {
                    setReceivedAt(d);
                    setPage(0);
                    setLimit(10);
                  }}
                  label={`${tableHeader.validHeader[filterIndex.receivedDate].fieldName
                    }`}
                  sx={{ ...inputClasses.filterField }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </Grid>
            )}
            {filterIndex.submissionDate >= 0 && tableHeader.validHeader[filterIndex.submissionDate]?.isSearchable === true && (
              <Grid item sx={4} sm={2}>
                <DatePicker
                  data-testid="createdAt"
                  value={createdAt}
                  onChange={(d) => {
                    setCreatedAt(d);
                    setPage(0);
                    setLimit(10);
                  }}
                  label={
                    tableHeader.validHeader[filterIndex.submissionDate]
                      .fieldName
                  }
                  sx={{ ...inputClasses.filterField }}
                  slotProps={{ textField: { size: "small" } }}
                />
              </Grid>
            )}

            {isFilterApplied && (
              <Grid container item sx={4} sm={2} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFilter}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
            <Grid container item sx={4} sm={2} alignItems={"center"}>
              <GenericCustomiseHeader
                tableName={"asnHeader"}
                tableType={"ASNTable"}
              />
            </Grid>
          </Grid>
          <ConfirmDialog
            status={confirmModal}
            handleClose={handleCloseConfirmModal}
            selectedEntries={selectedEntries}
            setSelectedEntries={setSelectedEntries}
            refreshTableData={() => refreshTableData(page + 1, limit)}
            handleRemoveSelection={handleRemoveSelection}
          />
          <ReceiveDialog
            status={receiveModal}
            entry={selectedEntries.length > 0 ? selectedEntries[0] : null}
            handleClose={handleCloseReceiveModal}
          />
          {selectedEntries.length > 0 && (
            <Grid container sx={{ mt: 2, textTransform: "none" }}>
              {selectedEntries.length == 1 && (
                <>
                  {(selectedEntries[0].status == "drafted" || (selectedEntries[0].status == "incoming" && selectedEntries[0].rejectedQuantity === 0)) && (
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        mr: 1,
                        textTransform: "none",
                      }}
                      onClick={() =>
                        history.push(
                          `/dashboard/data/edit-asn/${selectedEntries[0]._id}`
                        )
                      }
                    >
                      Edit
                    </Button>
                  )}

                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() =>
                      history.push(
                        `/dashboard/data/view-asn/${selectedEntries[0].ASN_Id}`
                      )
                    }
                  >
                    View
                  </Button>
                  {["incoming", "partialReceived"].indexOf(
                    selectedEntries[0].status
                  ) >= 0 && (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          mr: 1,
                          backgroundColor: "#3E621E",
                          border: "1px solid #3E621E",
                          textTransform: "none",
                        }}
                        onClick={() => setReceiveModal("receive")}
                      >
                        Receive
                      </Button>
                    )}
                </>
              )}

              {!!selectedEntries.some((entry) => entry.status === "partialReceived")
                && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      backgroundColor: "#3E621E",
                      border: "1px solid #3E621E",
                      textTransform: "none",
                    }}
                    onClick={() => setCloseModal("close")}
                  >
                    Close
                  </Button>
                )}

              {isSelectionRejectable && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    ...buttonClasses.lynkitOrangeFill,
                    backgroundColor: "#E9001A",
                    border: "1px solid #E9001A",
                    ml: 1,
                  }}
                  onClick={() => setConfirmModal("Reject")}
                >
                  Reject
                </Button>
              )}
            </Grid>
          )}
          <Box container sx={{ mt: 2 }}>
            {
              loader ? (
                <Grid
                  data-testid="loader"
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ p: 15 }}
                >
                  <Grid item>
                    <GenericLoader />
                  </Grid>
                </Grid>
              ) : (
                <GenericTable
                  data-testid="asn-table"
                  header={header}
                  rows={tableRows}
                  pageCount={totalRecords}
                  pageNumber={page}
                  handleChangePage={(event, pagevalue) => {
                    setPage(pagevalue);
                  }}
                  handleChangeRowsPerPage={(event) => {
                    setLimit(+event.target.value);
                  }}
                  rowsPerPage={limit}
                />
              )
              // <TemplatesTable
              //     header={header}
              //     rows={tableRows}
              //     totalRows={totalRecords}
              //     page={page}
              //     limit={limit}
              //     setPage={setPage}
              //     setLimit={setLimit}
              // />
            }
          </Box>
          <ConfirmDialog
            status={actionConfirmModal}
            handleClose={handleCloseAction}
            selectedEntries={
              actionIndex != null ? [advanceShipment[actionIndex]] : []
            }
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />
          <CloseDialog
            status={closeModal}
            closeReason={closeReason}
            handleConfirm={handleCloseConfirm}
            setCloseReason={setCloseReason}
            subTitle={"You want to close the further receiving."}
            handleClose={handleCloseModal}
            selectedEntries={
              actionIndex !== null ? [advanceShipment[actionIndex]] : selectedEntries.filter((entry) => entry.status === "partialReceived")
            }
            handleRemoveSelection={handleRemoveSelection}
            refreshTableData={() => refreshTableData(page + 1, limit)}
          />
          <ReceiveDialog
            status={actionReceiveModal}
            entry={actionIndex != null ? advanceShipment[actionIndex] : null}
            handleClose={handleCloseActionReceiveModal}
          />
          {actionIndex != null && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={actionIndex != null}
              onClose={handleCloseAction}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                display:
                  actionIndex != null ? "block !important" : "none !important",
              }}
            >
              <MenuItem
                key={1}
                sx={{ ...textClasses.normalText }}
                onClick={() =>
                  history.push(
                    `/dashboard/data/view-asn/${advanceShipment[actionIndex].ASN_Id}`
                  )
                }
              >
                View
              </MenuItem>
              {(advanceShipment[actionIndex].status == "drafted" || (advanceShipment[actionIndex].status == "incoming" && advanceShipment[actionIndex].rejectedQuantity === 0)) && (
                <MenuItem
                  key={0}
                  sx={{ ...textClasses.normalText }}
                  onClick={() =>
                    history.push(
                      `/dashboard/data/edit-asn/${advanceShipment[actionIndex]._id}`
                    )
                  }
                >
                  Edit
                </MenuItem>
              )}

              {["incoming", "partialReceived"].indexOf(
                advanceShipment[actionIndex].status
              ) >= 0 && (
                  <MenuItem
                    key={2}
                    sx={{ ...textClasses.normalText }}
                    onClick={() => setActionReceiveModal("receive")}
                  >
                    Receive
                  </MenuItem>
                )}
              {advanceShipment[actionIndex].status == "incoming" && (
                <MenuItem
                  key={3}
                  sx={{ ...textClasses.normalText }}
                  onClick={() => setActionConfirmModal("Reject")}
                >
                  Reject
                </MenuItem>
              )}
              {advanceShipment[actionIndex].status == "partialReceived" && (
                <MenuItem
                  key={3}
                  sx={{ ...textClasses.normalText }}
                  onClick={() => setCloseModal("close")}
                >
                  Close
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      }
    </>
  );
}
