import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Typography, Breadcrumbs, Autocomplete, CardHeader, CircularProgress, TextField
} from "@mui/material";
import Paper from '@mui/material/Paper';
import { Add, ExpandMore, Search, Edit, Delete } from '@mui/icons-material';
import colors, { buttonClasses, text } from '../../../static/styles/theme';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import group from '../../../static/images/templates/Group.png'
import pana from '../../../static/images/templates/pana.svg'
import theme, { textClasses } from "../../../static/styles/theme";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditView from './EditView'
import DeleteWorkflow from './DeleteWorkflow';
// EditBlockModel
const Workflow = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [editView, setEditView] = useState(false)
    const [deleteState, setDeleteState] = useState(false)
    const [status, setStatus] = useState('')
    const [item, setItem] = useState({})
    const [warehouse, setWarehouse] = useState([
        { label: 'Select Warehouse', value: 'Select Warehouse' }
    ])
    const [workflowState, setworkflowState] = useState([
        {
            type: 'Inward Operations',
            subText: '* add all your inward process steps in their completion order. you can create multiple workflows and update them anytime.',
            operations: [
                {
                    operationName: 'Workspace-1',
                    tasks: [
                        {
                            taskNames: 'Advanced Shipping notice.'
                        },
                        {
                            taskNames: 'Planning.'
                        },
                        {
                            taskNames: 'Activity Tracking.'
                        }
                    ]
                },
                {
                    operationName: 'Workspace-2',
                    tasks: [
                        {
                            taskNames: 'Advanced Shipping notice.'
                        },
                        {
                            taskNames: 'Planning.'
                        },
                        {
                            taskNames: 'Activity Tracking.'
                        },
                        {
                            taskNames: 'Quality Check.'
                        }
                    ]
                }
            ]

        },
        {
            type: 'In-house Operations',
            subText: '* define your in-house warehouse operations ',
            operations: [
                {
                    operationName: 'Workspace-2',
                    tasks: []
                }
            ]
        },
        {
            type: 'Outward Operations',
            subText: '* add all your Outward process steps in their completion order.',
            operations: [
                {
                    operationName: 'Workspace-2',
                    tasks: []
                }
            ]
        },

    ])
    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);


        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };
    }

    return (

        <>
            {workflowState.length === 0 ?
                <div>
                    <Grid container>
                        <Grid item sm={6}>
                            <Typography variant="h6">
                                Manage Workflows
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={6}
                            justifyContent="end"
                            alignItems="end"
                            gap={1}
                        >
                        </Grid>

                    </Grid>

                    <div className='pOuterParent' >
                        <div className='pInnerTop pAlignCent'>
                            <img src={pana} alt='' />
                        </div>
                        <div className='pInnerBody pAlignCent pjustifySBtw'>
                            <span style={{ color: '#ff7200', fontSize: '24px', fontWeight: '700' }}>Make your workflow your way</span>
                            <span style={{ color: '#000', fontSize: '14px', fontWeight: '600' }}>Workflows respresentyour warehouse operations and control how the team memeber's progress their work/assigned jobs(s).</span>
                            <Button
                                variant="contained"
                                size="small"
                                data-testid='create'
                                startIcon={<Add />}
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    paddingTop: "5.5px",
                                    paddingBottom: "5.5px",
                                }}
                                onClick={() => history.push("/dashboard/setting/create-workflow")}
                            >
                                Create Workflow(s)
                            </Button>

                        </div>
                        <div className='pInnerBottom '>
                            <div className='pCard' data-testid='table1'>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '800' }}>
                                    Steps to be followed :
                                </div>
                                <div className='pCardBody'>
                                    <span className='cardPoints'>• Click on 'Create Workflow' button, It redirects to a new page.</span>
                                    <span className='cardPoints'>• To create work flow, select warehouse from the dropdown first.</span>
                                    <span className='cardPoints'>• You will find a default warehouse work flow, here you needs to add the process steps in their completion order.</span>
                                    <span className='cardPoints'>• Drag and drop standard warehouse operations steps to create the your workflow. </span>
                                    <span className='cardPoints'>• Use options to add new steps and re-order the same. </span>
                                    <span className='cardPoints'>• After creating, submit and it’s done. </span>
                                </div>

                            </div>
                            <div className='pCard' data-testid='table2'>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '800' }}>
                                    What is a warehouse workflow ?
                                </div>
                                <div className='pCardTop' style={{ color: '#000', fontSize: '14px', fontWeight: '400', marginTop: '10px' }}>
                                    Two concepts define the warehouse workflow-
                                </div>
                                <div className='pCardBody'>
                                    <span className='cardPoints' style={{ marginBottom: '15px' }}>1.) Operations : the steps in your warehouse operations that describe the journey of any shipments, packages or items with in the warehouse.</span>
                                    <span className='cardPoints'>2.) Transitions : how an shipment, packages or items can move between operations or statuses.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :


                <Grid container sx={{ flexDirection: "column" }}>
                    <Grid item >
                        <Typography sx={{ fontSize: "24px", fontWeight: 600 }} data-testid='manage'>
                            Manage Workflow(s)
                        </Typography>

                    </Grid>
                    <div className="customCard">
                        <div className="customCardLeft" data-testid='mainTable' >
                            <div className='clHeader' >
                                <span style={{ position: 'relative', top: '10px' }}>My Workflow(s)</span>
                                <Autocomplete
                                    sx={{ height: "10px", width: '30%', padding: "0px", marginTop: "0px" }}
                                    // className='multi-select'
                                    // id="select-Rack-label"
                                    size="small"
                                    multiple
                                    disableCloseOnSelect
                                    limitTags={2}
                                    data-testid='warehouse'
                                    // defaultValue={[label:"warehouse", value:'warehouse']}
                                    options={warehouse}
                                    // getOptionLabel={(option) => option.name}
                                    // getOptionDisabled={(option) =>
                                    //     checkdisableValue(option.cellId)
                                    // }
                                    // onChange={(event, option) => {
                                    //     handleSelectRackChange(index, option)
                                    // }}
                                    // renderOption={(props, option, { selected }) => (
                                    //     <MenuItem
                                    //         key={option.cellId}
                                    //         value={option.cellId}
                                    //         sx={{ justifyContent: "space-between" }}
                                    //         {...props}
                                    //     >
                                    //         {option.name}
                                    //         {selected ? <CheckIcon color="info" /> : null}
                                    //     </MenuItem>
                                    // )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Warehouse"
                                            placeholder="Select Warehouse"
                                        />
                                    )}
                                />
                            </div>
                            <div style={{ borderBottom: '1px solid #D9D9D9', width: '97%', height: '20px', margin: 'auto', position: 'relative', top: '-13px' }}>
                                <span style={{ fontSize: '12px' }}>

                                </span>
                            </div>
                            {workflowState.map((item, Parentindex) => {
                                return (
                                    <div  key={Parentindex} className="pcardContainer" style={{ height: '239px' }}>
                                        <div className="pcardCTop" style={{ paddingTop: '0px' }}>
                                            <span data-testid='heading' className="operationName">{item.type}</span>
                                            <span className="operationText">{item.subText}</span>
                                        </div>

                                        <div className="pcardCBodyNew" >
                                            <div className='viewCardArrow' style={{ position: 'absolute', left: '0px' }}>
                                                <ChevronLeftIcon id={`slideLeft-${Parentindex}`} style={{ cursor: "pointer" }} onClick={() => slide(Parentindex)} />
                                            </div>


                                            <div className='ccontaoner' >
                                                <div className='constantCard' data-testid='addWorkflow' style={{ display: 'flex', justifyContent: 'center', marginRight: '20px', alignItems: 'center' }}>
                                                    <Add style={{ color: '#ff7200', cursor: 'pointer' }} onClick={() => history.push('/dashboard/setting/create-workflow')} />
                                                    <span style={{ color: '#ff7200', fontSize: '14px', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => history.push('/dashboard/setting/create-workflow')}>Add New</span>
                                                </div>
                                                <div className='cardP' id={`container-${Parentindex}`}>

                                                    {item.operations.map((data, ind) => {
                                                        return (
                                                            data.tasks.length !== 0 ?
                                                                <div key={ind} className='viewCard' style={{ marginRight: '20px' }}>
                                                                    <div className='viewCardHeader'>
                                                                        <span className='viewHeading'>{data && data.operationName}</span>
                                                                        <span>
                                                                            {/* <Edit onClick={() => { setEditView(true) }} className='myIcon' /> */}
                                                                            <VisibilityIcon data-testid='view' onClick={() => { setItem(data); setEditView(true) }} className='myIcon' />
                                                                            <Delete
                                                                            data-testid='delete'
                                                                                onClick={() => {
                                                                                    setStatus("Delete")
                                                                                    setDeleteState(true)
                                                                                    setItem(data)
                                                                                }} className='myIcon' />
                                                                        </span>

                                                                    </div>

                                                                    <div className='viewCradBody'>
                                                                        {data.tasks.map((list) => {
                                                                            return (
                                                                                <div data-testid='tasks' className="customOrangeBtn mgAll" style={{ width: '100%', cursor: 'pointer', textAlign: 'center', marginLeft: '0px' }}>
                                                                                    {list && list.taskNames}
                                                                                </div>
                                                                            )
                                                                        })}

                                                                    </div>

                                                                </div>
                                                                : null
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className='viewCardArrow' style={{ position: 'absolute', right: '0px' }}>
                                                <ChevronRightIcon id={`slideRight-${Parentindex}`} style={{ cursor: "pointer" }} onClick={() => slide(Parentindex)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="customCardRight crChanges">
                            <div className='cRConstant'>
                                <img style={{ width: '60%' }} src={group} alt='' />
                                <span style={{ color: '#ff7200', fontSize: '22px', fontWeight: '900' }}>Make Your Work<br />flow Your Way</span>
                                <span style={{ color: '#ff7200', fontSize: '14px', fontWeight: '600' }}>Workflows represent your warehouse operations and control how the team member’s progress with their work/ assigned job(s). Add process completion rule and define your workflow movement rules. </span>
                            </div>
                        </div>

                    </div>
                    {editView && <EditView
                        open={editView}
                        hide={() => {
                            setEditView(false)
                            setStatus('edit')
                        }}
                        status={status}
                        item={item}
                    />}

                    {deleteState && <DeleteWorkflow
                        open={deleteState}
                        hide={() => {
                            setDeleteState(false)
                        }}
                        action={status}
                        item={item}
                    />}
                </Grid>



            }

        </>
    )
}

export default Workflow;