import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses  } from '../../../../../static/styles/theme'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ItemSection from './ItemSection';

const ItemModal = ({template, spiType, pIndex, packages, handleCloseModal, addItems, setIsItemClicked, inputRef, idSettings, asn}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            open={pIndex != null ? true : false}
            onClose={handleCloseModal}
            scroll={"paper"}
            PaperProps={{sx: {width: "100%"}}}
            fullScreen={fullScreen}
            maxWidth="lg"
        >
            <DialogContent>
                {
                    pIndex != null && pIndex >= 0 &&
                    <ItemSection
                        template={template}
                        spiType={spiType}
                        pIndex={pIndex}
                        packageObj={packages[pIndex]}
                        addItems={addItems}
                        packages={packages}
                        handleCloseModal={handleCloseModal}
                        setIsItemClicked={setIsItemClicked}
                        idSettings={idSettings}
                        asn={asn}
                    />

                }
                
            </DialogContent>
        </Dialog>
    )
}

export default ItemModal;