import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormControl, Grid, OutlinedInput,Autocomplete, Button,TextField, FormLabel, Typography, Tab, Tabs, Box, Checkbox,
     Breadcrumbs, Card
} from '@mui/material'
import { ToastContainer } from 'react-toastify';
import { buttonClasses,inputClasses } from '../../../../static/styles/theme';
import { selectStylesOverride } from '../../../../utils/util';
import { getCamelCaseText } from '../../../../services/functions';
import { showToast } from '../../../../services/functions';
import { addUserRoles, getAllUserRoles, editUserRoles , editSuperAdminRole, getSuperAdminCompanyData, createSuperAdminRole} from '../../../../redux/actions';
import AlertDialog from '../../../Components/DialogBox/dialogbox';
import theme, { textClasses } from '../../../../static/styles/theme';
import { Link, useLocation } from "react-router-dom";
import { getAllPermisssions } from '../../../../redux/actions/Permissions'
import { useHistory } from 'react-router';
import GenericLoader from '../../../Components/Generic/GenericLoader';
import SelectImage from "../../../../static/images/templates/amicoDataEntry.svg"
function AddRole(props) {
    const users = JSON.parse(localStorage.getItem("user")) || {}
    const userType = users?.userType || ""
    const dispatch = useDispatch(); 
    const location = useLocation();
    const history = useHistory()
    const data = location.state
    const selectedCard = data && data.selectedCard ? data.selectedCard : []
    const companyId=data?.companyId || "";
    const { AllPermissions } = useSelector((state) => state.permissions);
    const { userPermissions} = useSelector((state) => state.userPermission);
    const { message, error } = useSelector((state) => state.common);
    const [loader, setLoader] = useState(true)
    const [allCheck, setAllCheck] = useState(false)
    const [roleName, setRoleName] = useState('')
    const [isFeildTouched, setIsFeildTouched] = useState(false)
    const [callStatusChange, setCallStatusChange] = useState(false);
    const [editCallStatus, setEditCalStatus] = useState(false)
    const [roleId, setRoleId] = useState('')
    const [dialog, setDialog] = useState(false)
    const [actionStatus, setActionStatus] = useState('')
    const [companiesList, setCompaniesList] = useState([])
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState(null)
    const [addRoleData, setAddRoleData] = useState({
        roleName: '',
        permissions: []
    })
    const textCapitalize = (d) => {

        let dataArr = d.split(/(?=[A-Z])/)
        let label = ""
        for (let data of dataArr) {
            data = data.slice(0, 1).toUpperCase() + data.slice(1).toLowerCase()
            if (label) {
                label = label + " " + data
            }
            else {
                label = data
            }
        }
        return label

 
    }
    const allChecked = (e) => {
        let temp = { ...addRoleData };

        if (e.target.checked === true) {
            temp.permissions.length = 0
            for (let i = 0; i < AllPermissions.length; i++) {
                let keyArr = AllPermissions[i]
                for (let j = 0; j < keyArr.assignedPermissions.length; j++) {
                    let keyName = keyArr.assignedPermissions[j].permissionId
                    temp.permissions.push(keyName)
                    setAddRoleData(temp)
                    setAllCheck(true)
                }
            }
        }
        else {
            temp.permissions.length = 0
            setAddRoleData(temp)
            setAllCheck(false)
        }
    }
    const checkboxChange = (data) => {

        let temp = { ...addRoleData }
        let res = []
        if (!temp.permissions.includes(data.permissionId)) {
            temp.permissions.push(data.permissionId)
            setAddRoleData(temp)
        }
        else {
            temp.permissions.splice(temp.permissions.indexOf(data.permissionId), 1);
            setAddRoleData(temp)
        }

        for (let i = 0; i < AllPermissions.length; i++) {
            let keyArr = AllPermissions[i]
            for (let j = 0; j < keyArr.assignedPermissions.length; j++) {
                let keyName = keyArr.assignedPermissions[j].permissionId
                res.push(keyName)
            }

        }
        if (res.length === temp.permissions.length) {
            setAllCheck(true)
        }
        else {
            setAllCheck(false)
        }

        setIsFeildTouched(true)
    }
    const handleSubmit = () => {
        if (actionStatus === 'addnew') {
            let temp;

            if (userType == "superAdmin") {
                temp = {
                    ...addRoleData,
                    companyId: selectedCompanyDetail?._id,
                    customRoleName: roleName,
                };
                delete temp.roleName;
            } else {
                temp = {
                    ...addRoleData,
                    roleName: roleName,
                };
                delete temp.customRoleName; 
            }
        
            setAddRoleData(temp);
        
            if ((userType == "superAdmin" && temp.customRoleName === "") || temp.roleName === "") {
                showToast("Please enter role name", true);
                return;
            } else if (temp.permissions.length === 0) {
                showToast("Please select at least one permission", true);
                return;
            } else {
                setCallStatusChange(true);
                if (userType == "superAdmin") {
                    dispatch(createSuperAdminRole(temp, () => {
                        setCallStatusChange(false);
                    }));
                } else {
                    dispatch(addUserRoles(temp, () => {
                        setCallStatusChange(false);
                    }));
                }
            }
        }
        else {

            let temp = { ...addRoleData }
            temp.roleName = roleName
            setAddRoleData(temp)

            let bodyData = {
                roleId: roleId,
                permissions: addRoleData.permissions,
                roleName: temp.roleName
            }

            if (temp.roleName === "") {
                showToast("Please enter role name", true);
                return;
            }
            else if (temp.permissions.length === 0) {
                showToast("Please select atleast one permission", true);
                return;
            }
            else {
                setDialog(true)
            }
        }


    }

    useEffect(() => {
        if (message && callStatusChange) {
            showToast(message, false)
            setCallStatusChange(false)
            history.push('/dashboard/user_management/user-role')
            dispatch(getAllUserRoles({makeGroup:true}))
        }

        if (error && callStatusChange) {
            showToast(error, true)
            setCallStatusChange(false)
        }
    }, [message, error])


    const handleEdit = () => {
        let temp = { ...addRoleData }
        temp.roleName = roleName
        setAddRoleData(temp)

        let bodyData = {
            roleId: roleId,
            permissions: addRoleData.permissions,
            ...userType=="superAdmin" ?{ companyId: companyId} :{roleName: temp.roleName}
        }
        setEditCalStatus(true)
        userType=="superAdmin" ?  dispatch(editSuperAdminRole(bodyData, () => {
            setEditCalStatus(false)
        })) :
        dispatch(editUserRoles(bodyData, () => {
            setEditCalStatus(false)
        }))
    }
    const categoryWiseCheck = (e,item) => {
        let temp = { ...addRoleData };
        if (e.target.checked === true) {
                for (let j = 0; j < item.assignedPermissions.length; j++) {
                    let keyName = item.assignedPermissions[j].permissionId
                    if (!temp.permissions.includes(keyName)) {
                        temp.permissions.push(keyName)
                        setAddRoleData(temp)
                    }  
                    else{}
                }
        }
        else {
            for (let k = 0; k < item.assignedPermissions.length; k++) {
            temp.permissions.splice(temp.permissions.indexOf(item.assignedPermissions[k].permissionId), 1);
            setAddRoleData(temp)
            }
        }
        setIsFeildTouched(true)
    }

    const disableSubmitButton=()=>{
        if(!roleName) return true
        if(!addRoleData || !addRoleData.permissions || addRoleData.permissions.length==0) return true
        if(!isFeildTouched) return true
        return false
    }
    useEffect(() => {
        if (message && editCallStatus) {
            showToast(message, false)
            setEditCalStatus(false)
            setDialog(false);
            history.push('/dashboard/user_management/user-role')
            dispatch(getAllUserRoles())
        }

        if (error && editCallStatus) {
            showToast(error, true)
            setEditCalStatus(false)
        }
    }, [message,error])

    useEffect(() => {
        setLoader(true)
        const bodyData = userType === "superAdmin" ? { roleId } : {};
        dispatch(getAllPermisssions(bodyData, () => {
            setLoader(false)
        }))
    }, [roleId,userType])


    // useEffect(() => {
    //     setLoader(true)
    //     const bodyData = {}
    //     dispatch(getAllPermisssions(bodyData, () => {
    //         setLoader(false)
    //     }))
    // }, [])
    useEffect(() => {
        if (userType == "superAdmin") {
            dispatch(getSuperAdminCompanyData({ projections: { _id: 1, companyName: 1, type: 1 }, type: 'company' }, ({ data = [] }) => {
                setCompaniesList(data)
                // if (data) {
                //     setSelectedCompanyDetail(data?.at(0) || {})
                // }
            }))
        }
        // else {
        //     getRoleData()
        // }
        // setInitial(false)
    }, [selectedCompanyDetail])


    useEffect(() => {
        if (data && data.status === 'addnew') {
            setActionStatus(data.status)
        }
        else {
            let temp = { ...addRoleData }
            let array = selectedCard && selectedCard.permissions ? selectedCard.permissions : []

            setActionStatus(data && data.status ? data.status : '')
            setRoleName(selectedCard.roleName)
            setRoleId(selectedCard._id)
            for (let i = 0; i < array.length; i++) {
                let keyName = array[i].permissionId
                temp.permissions.push(keyName)
                setAddRoleData(temp)
            }
        }
    }, [data])

    return (
        <Grid container sx={{ flexDirection: "column" }}>
            <Grid container sx={{display:'flex',justifyContent:"space-between"}}>
            <Grid item>
                <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                    Manage User Role's
                </Typography>
                <Breadcrumbs sx={{ mb: 1 }}>
                    <Typography
                        sx={{
                            ...textClasses.normalText,
                            fontSize: "12px",
                            color: theme.themeOrange,
                        }}
                    >
                        <Link
                            style={{ color: theme.themeOrange,textDecoration:"none" }}
                            to="/dashboard/user_management/user-role"
                        >
                            Manage User Role
                        </Link>
                    </Typography>

                    <Typography
                        sx={{ ...textClasses.normalText, fontSize: "12px" }}
                    >
                     {actionStatus === 'addnew' ? 'Create Role' : 'Edit Role'}
                    </Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item >
                {actionStatus === 'addnew' && userType=="superAdmin" && (userPermissions?.permissions?.find(dl => dl.permissionId == "createAdmin"))
              &&
                <Autocomplete
                    data-testid="select-template-dropdown"
                    value={selectedCompanyDetail || {}}
                    onChange={(e, option) => {
                        setSelectedCompanyDetail(option)
                    }}
                    getOptionLabel={(option) => getCamelCaseText(option?.companyName)}
                    size="small"
                    options={companiesList || []}
                    sx={{
                        ...inputClasses.textField,
                        ...selectStylesOverride,
                        minWidth: "250px",
                        m: 0,

                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ color: "#455A64", ...textClasses.normalText }}
                            {...params}
                            label="Select Company*"
                        />
                    )}
             />
                }
             </Grid>
             </Grid>
            <Card sx={{ mt: 2 }}>
                {(actionStatus === 'addnew' && selectedCompanyDetail && userType === 'superAdmin') || actionStatus === 'edit' || userType != "superAdmin" ?
                <Grid container padding={1}>
                    <Grid item sm={6} flexDirection={'column'} paddingRight={2} sx={{ borderRight: "1px solid #D9D9D9", height: "auto", overflow: "auto" }}>
                        <Typography variant='h6' sx={{ paddingLeft: "10px", fontSize: "20px", fontWeight: "600" }}>
                            {actionStatus === 'addnew' ? 'Create Custom Role' : 'Edit Custom Role'}
                        </Typography>
                        <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
                            <Box margin={2}>
                                <FormControl variant='outlined' size='small' fullWidth error>
                                    <OutlinedInput
                                        data-testid='inputElem'
                                        value={roleName}
                                        onChange={(e) => {
                                            setRoleName(e.target.value)
                                            setIsFeildTouched(true)
                                        }}
                                        // disabled={action === "view" ? true : false}
                                        // error={errorMessage.name ? true : false}
                                        required
                                        type='text'
                                        sx={{ fontSize: "12px", boxShadow: 1, "& input::placeholder": { fontSize: "14px", fontWeight: "400" }, borderRadius: '10px' }}
                                        placeholder='Role Name *'
                                    />
                                </FormControl>
                            </Box>

                           {(userPermissions?.permissions?.find(dl => dl.permissionId == "editRole") || 
                           userPermissions?.permissions?.find(dl => dl.permissionId == "addRole") || userPermissions?.permissions?.find(dl => dl.permissionId == "createAdmin")) &&
                            <Box sx={{ marginTop: "25px", padding: "2px 10px 2px 10px", display: "flex", justifyContent: "space-between" }}>
                                <Button
                                    data-testid= 'createBtn'
                                    variant="outlined"
                                    disabled={disableSubmitButton()}
                                    sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', width: '20%', height: '33px' }}
                                    onClick={() => handleSubmit()}
                                >
                                    {actionStatus === 'addnew' ? 'Create Role' : 'Edit Role'}
                                </Button>

                            </Box>}


                        </Box>

                    </Grid>

                    <Grid item sm={6} paddingRight={2} sx={{ height: "auto", overflow: "auto" }}>
                        <Typography variant='h6' sx={{ paddingLeft: "15px", fontSize: "20px", fontWeight: "600" }}>
                            User Role Control
                        </Typography>
                        <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
                           { AllPermissions.length ? <Box margin={1}>
                                <Box >
                                    <div style={{ display: 'block' }}>
                                        <Checkbox
                                        data-testid='selectall'
                                            onChange={(e) => {
                                                allChecked(e)
                                                setIsFeildTouched(true)
                                            }}
                                            checked={allCheck === true ? true : false}
                                            size='small' sx={{ marginBottom: '4px' }} />
                                        <span style={{ color: '#000', fontWeight: '900' }}>Select All</span>
                                    </div>
                                </Box>
                            </Box>:""}
                            {loader
                                ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                    <Grid item><GenericLoader /></Grid>
                                </Grid>
                                :
                                AllPermissions.length ? AllPermissions.map((item, index) => {
                                    return (
                                        <div key={index} className='priyanshuCheckBox' style={{ marginTop: '-3px' }}>
                                            <div className='priyanshuMainCheck'>
                                                <div style={{width:'100%',paddingLeft :'35px'}}>
                                                <Checkbox
                                                    data-testid='categoryCheckbox'
                                                    onChange={(e) => categoryWiseCheck(e,item,index)}
                                                    checked ={item.assignedPermissions.every(data => addRoleData.permissions.includes(data.permissionId))}
                                                    size='small' sx={{ marginBottom: '4px' }} 
                                                    />
                                                <span className='priyanshucheckheading'> {textCapitalize(item && item.categoryKey ? item.categoryKey : '-')}    </span>
                                                </div>
                                               
                                            </div>
                                            {item.assignedPermissions.map((data, ind) => {
                                                return (
                                                    <div key={ind} className='priyanshuTwoContainer'>
                                                        <Checkbox
                                                         data-testid='subcategory'
                                                            onChange={() => checkboxChange(data)}
                                                            checked={addRoleData.permissions.includes(data.permissionId) ? true : false}
                                                            size='small' sx={{ marginBottom: '4px' }} />
                                                        <span data-testid='checkHead' className='priyanshuCheckText' style={{ paddingLeft: '5px' }}>{data && data.permissionName ? data.permissionName : '-'}</span>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )
                                }) : ''
                            }

                        </Box>
                    </Grid>

                </Grid>
                :
                <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 2 }}>
                <img src={SelectImage} width={300} height={400}/>
                <Typography sx={{ ...textClasses.cardTitle, mt: 1}}>Hey, Please select the company first...</Typography>
            </Grid>
                }

            </Card>
            {dialog &&
                <AlertDialog
                    viewDialog={dialog}
                    sx={{borderRadius:"50px"}}
                    title={<Typography sx={{ fontWeight: '1000', fontSize: '24px', textAlign: "center" }}>
                        <span>
                            Are you sure ?
                        </span>
                        <br />
                        <span>
                            You want to update User Role.
                        </span>
                    </Typography>
                    }
                    // subTitle={`You want to update User Role.`}
                    body={"Once you update the user role, it will automatically affect the existing team member's accessibility on portal."}
                >

                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => setDialog(false)}
                        sx={{ ...buttonClasses.lynkitOrangeEmpty, m:1, fontSize: ".7rem", minWidth: "100px" }}
                    >Cancel</Button>

                    <Button
                        fullWidth
                        variant="outlined"
                        sx={{ ...buttonClasses.lynkitOrangeFill, m:1, fontSize: ".7rem", border: 'none',minWidth: "100px" }}
                        onClick={() => handleEdit()}
                    >
                        Yes, Update
                    </Button>
                </AlertDialog>
            }
            <ToastContainer position="bottom-center" autoClose={false} closeOnClick={false} />

        </Grid>

    )
}

export default AddRole;

 // <>
        //     <CustomModel
        //         show={open}
        //         onClose={() => hide()}
        //     >
        //         <Grid container padding={1}>
        //             <Grid item sm={6} flexDirection={'column'} paddingRight={2} sx={{ borderRight: "1px solid #D9D9D9", height: "auto", overflow: "auto" }}>
        //                 <Typography variant='h6' sx={{ paddingLeft: "10px", fontSize: "20px", fontWeight: "600" }}>
        //                     {actionStatus === 'addnew' ? 'Create Custom Role' : 'Edit Role'}
        //                 </Typography>
        //                 <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
        //                     <Box margin={2}>
        //                         <FormControl variant='outlined' size='small' fullWidth error>
        //                             <OutlinedInput
        //                                 value={roleName}
        //                                 onChange={(e) => setRoleName(e.target.value)}
        //                                 // disabled={action === "view" ? true : false}
        //                                 // error={errorMessage.name ? true : false}
        //                                 required
        //                                 type='text'
        //                                 sx={{ fontSize: "12px", boxShadow: 1, "& input::placeholder": { fontSize: "14px", fontWeight: "400" }, borderRadius: '10px' }}
        //                                 placeholder='Role Name *'
        //                             />
        //                         </FormControl>
        //                     </Box>

        //                     {<Box sx={{ marginTop: "25px", padding: "2px 10px 2px 10px", display: "flex", justifyContent: "space-between" }}>
        //                         <Button
        //                             fullWidth
        //                             variant="outlined"
        //                             onClick={() => hide()}
        //                             sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem" }}
        //                         >Cancel</Button>

        //                         <Button
        //                             fullWidth
        //                             variant="outlined"
        //                             sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none' }}
        //                             onClick={() => handleSubmit()}
        //                         >
        //                             {actionStatus === 'addnew' ? 'Create Role' : 'Edit Role'}
        //                         </Button>

        //                     </Box>}


        //                 </Box>

        //             </Grid>

        //             <Grid item sm={6} paddingRight={2} sx={{ height: "auto", overflow: "auto" }}>
        //                 <Typography variant='h6' sx={{ paddingLeft: "15px", fontSize: "20px", fontWeight: "600" }}>
        //                     User Role Control
        //                 </Typography>
        //                 <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
        //                     <Box margin={1}>
        //                         <Box >
        //                             <div style={{ display: 'block' }}>
        //                                 <Checkbox
        //                                     onChange={(e) => allChecked(e)}
        //                                     checked={allCheck === true ? true : false}
        //                                     size='small' sx={{ marginBottom: '4px' }} />
        //                                 <span style={{ color: '#000', fontWeight: '900' }}>Select All</span>
        //                             </div>
        //                         </Box>
        //                     </Box>
        //                     {/* {AllPermissions.length ? AllPermissions.map((item, index) => {
        //                         return (
        //                             <div key={index} className='priyanshuCheckBox' style={{ marginTop: '-3px' }}>
        //                                 <div className='priyanshuMainCheck'>
        //                                     <span className='priyanshucheckheading'> {textCapitalize(item && item.categoryKey ? item.categoryKey : '-')}    </span>
        //                                 </div>
        //                                 {item.assignedPermissions.map((data, ind) => {
        //                                     return (
        //                                         <div key={ind} className='priyanshuTwoContainer'>
        //                                             <Checkbox
        //                                                 onChange={() => checkboxChange(data)}
        //                                                 checked={addRoleData.permissions.includes(data.permissionId) ? true : false}
        //                                                 size='small' sx={{ marginBottom: '4px' }} />
        //                                             <span className='priyanshuCheckText' style={{ paddingLeft: '5px' }}>{data && data.permissionName ? data.permissionName : '-'}</span>
        //                                         </div>
        //                                     )
        //                                 })}

        //                             </div>
        //                         )
        //                     }) : ''} */}

        //                 </Box>

        //             </Grid>
        //         </Grid>

        //     </CustomModel>

          
        // </>