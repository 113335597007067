import React from "react";
import { Router } from "react-router-dom";
import history from "./../services/history";
import CustomRoutes from "./../routes";
// import {ConnectedRouter} from "react-router-redux";
import { Provider } from "react-redux";
import configureStore from "../redux/store/index";
// import { createTheme, ThemeProvider } from '@mui/material';
import "../static/css/wms.css"

import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "../static/styles/theme";
// import store from '../redux/store';

export const store = configureStore();

const appFont = createTheme({
	typography: {
		fontFamily: ["Nunito", "serif"].join(","),
	},
	components: {
		palette: {
			// primary: {
			//   main: '#0971f1',
			//   darker: '#053e85',
			// },
			// neutral: {
			//   main: '#64748B',
			//   contrastText: '#fff',
			// },
			dark: {
			  main: '#000000',
			  contrastText: '#fff',
			},
		  },
		MuiCssBaseline: {
			styleOverrides: {
				html: {
					"*::-webkit-scrollbar": {
						width: "3px",
						height: "3px",
					},
					"*::-webkit-scrollbar-track": {
						background: "inherit",
						boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
					},
					"*::-webkit-scrollbar-thumb": {
						backgroundColor: theme.themeGray,
						borderRadius: "20px",
						border: theme.themeGray,
					},
					"*::-webkit-scrollbar-corner": {
						background: "inherit",
					},
				},
			},
		},
	},
});

function App() {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Provider store={store}>
				<Router history={history}>
					<ThemeProvider theme={appFont}>
						<CustomRoutes />
					</ThemeProvider>
				</Router>
			</Provider>
		</LocalizationProvider>
	);
}
export default App;
