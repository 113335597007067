import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { selectStylesOverride } from '../../../utils/util';

const GenericUserInputComponent = ({ label, value = "", onChange, width }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue) && numericValue > 0) {
        onChange(numericValue);
      }
      else if (value === "") {
        onChange("");
      }
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        label={label}
        variant="outlined"
        value={value || ""}
        onChange={handleChange}
        sx={{
          fontSize: "12px",
          ...selectStylesOverride,
          "& .MuiOutlinedInput-input": { height: "7px", width: width, lineHeight: '0.8em' },
          "& .MuiInputLabel-root": { lineHeight: '0.9em' }
        }}
      />
    </Box>
  );
};

export default GenericUserInputComponent;
