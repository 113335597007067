

function NumberInt(data) {
    return data
}

export const component = ({ type = "jobName"}) => {

    if (type == "jobName") {
        return {
            "_id": "jobName",
            "type": "select",
            "label": "Search By Job Name",
            "description": "Search By Job Name",
            "placeholder": "Search By Job Name",
            "elementType": "Selection Type",
            "sortType": NumberInt(2),
            "value": "",
            "dynamic": true,
            "auth": true,
            "keyForValue": "name",
            "keyForLabel": "name",
            "extraValue":["_id"],
            "apiDomain": "",
            "baseURL":"",
            "apiEndPoint": "jobType/getJobTypesList",
            "apiMethod": "post",
            "name": "Supplier List",
            "apiDataReturnKey": "data",
            "apiParameters": [
                {
                    "label": "fetch_for",
                    "value": "getForFilter"
                },
                {
                    "label": "page",
                    "value": NumberInt(1)
                },
                {
                    "label": "limit",
                    "value": NumberInt(25)
                },
                {
                    "label": "jobName",
                    "value": "onSearch"
                }
            ],
            "setTargetValue": false,
        }
    }
    else if (type == "mappingArea") {
        return {
            "_id": "MappingArea",
            "type": "select",
            "label": "Search By Mapping Area",
            "description": "Search By Mapping Area",
            "placeholder": "Search By Mapping Area",
            "elementType": "Selection Type",
            "sortType": NumberInt(2),
            "value": "",
            "dynamic": true,
            "auth": true,
            "keyForValue": "value",
            "keyForLabel": "label",
            "extraValue":[""],
            "apiDomain": "",
            "baseURL":"",
            "apiEndPoint": "areaMapping/getAreaMapping",
            "apiMethod": "post",
            "name": "Supplier List",
            "apiDataReturnKey": "data",
            "apiParameters": [
                {
                    "label": "allAreaNameOnly",
                    "value": true
                },
                {
                    "label": "page",
                    "value": NumberInt(1)
                },
                {
                    "label": "limit",
                    "value": NumberInt(25)
                },
                {
                    "label": "areaName",
                    "value": "onSearch"
                }
            ],
            "setTargetValue": false,
        }
    }
    else {
        return {
            _id: "SKU",
            type: "select",
            icon: "simple-icon-note",
            label: "Search By Component",
            description: "Search By Component",
            placeholder: "Search By Component",
            className: "form-control",
            subtype: "text",
            regex: "",
            handle: true,
            forType: "all",
            elementType: "Selection Type",
            value: "",
            required: true,
            dynamic: true,
            auth: true,
            keyForValue: "label",
            keyForLabel: "value",
            apiDomain: "/v1/",
            apiEndPoint: "inventory/getDistinctMasterList",
            apiMethod: "post",
            name: "Item Master List",
            apiDataReturnKey: "data",
            apiParameters: [
              {
                label: "isSkipTemplate",
                value: true,
              },
              {
                label: "master_Type",
                value: "item-master",
              },
              {
                label: "page",
                value: 1,
              },
              {
                label: "limit",
                value: 25,
              },
              {
                label: "base_ID",
                value: "onSearch",
                },
            ],
            "setTargetValue": true,
            "targetAPI": "scan/scanEntityMaterData",
            "targetAPIMethod": "post",
            "targetParameters": [
                {
                    "label": "isSkipTemplate",
                    "value": true
                },
                {
                    "label": "base_ID",
                    "value": "$self.value"
                },
                {
                    "label": "master_Type",
                    "value": "item-master"
                }
            ],
            "targetApiDataReturnKey": "data",
            "onChangeSet": {
                "SKU_ID": "$response.formData.SKU_ID",
            },
            "values": [],
          };
    }
}