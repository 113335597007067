import React, { useEffect, useRef, useState } from 'react'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses  } from '../../../../../static/styles/theme'

const Table = ({header, rows}) => {
    return (
        <table
            style={{
                display: "block",
                maxWidth: "-moz-fit-content",
                maxWidth: "fit-content",
                margin: "0 auto",
                overflowX: "auto",
                whiteSpace: "nowrap"
            }}
        >
            <thead style={{backgroundColor: theme.themeGray}}>
                <tr className='roundedRow'>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}} key={i}>
                                    {
                                        head.includes("(") ? head.split("(")[0] : head
                                    }
                                </td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "200px", ...textClasses.boldText, textAlign: "center"}} key={i}>
                                    {
                                        head.includes("(") ? head.split("(")[0] : head
                                    }
                                </td>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{borderBottom: "1px solid #0000002B"}}   
                                >
                                    {
                                        row.map((col, j) => {
                                            return (
                                                // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                                    {col}
                                                </td>    
                                            )
                                        })
                                    }
                                
                                </tr>
                            ))
                        }  
                         
                    </>    
                }
            </tbody>
        </table>
    )
}

export default Table;