import React, { useEffect, useState } from "react"
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button,OutlinedInput, FormControl,IconButton, InputAdornment} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Clear, Search, } from "@mui/icons-material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { getConnectedSKUComponentDetails } from "../../../redux/actions";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import { useDebounce } from "../../../utils/hooks";

const ConnectedComponentsTable = ({ viewModal, setViewModal, sharedObj }) => {
  const dispatch = useDispatch();
  const [skuComponentTable, setSkuComponentTable] = useState([])
  const [skuComponentTableRows, setSkuComponentTableRows] = useState(0)
  const [searchValue,setSearchValue] = useState("")
  const [loader, setLoader] = useState(true);
  const [pagination, setPagination] = useState({ page: 0, limit: 10 })
 const debouncedSearchValue = useDebounce(searchValue, 500)
  const getSKUComponentTableDetails = (page, limit) => {
    let payload = {
      itemMasterId: sharedObj?._id,
      fetchFormData:false,
      searchValue:searchValue,
      page: page,
      limit: limit
    }
    setLoader(true)
    dispatch(getConnectedSKUComponentDetails(payload, (data) => {
      if (data.success) {
        setLoader(false)
        setSkuComponentTable(data?.data)
        setSkuComponentTableRows(data?.count)
      }
    }))
  }
  const tableHeaders = ["S.No", "Component ID", "Component Name", "Per Kit QTY"]
  const tableRows = skuComponentTable?.map((row, index) => {
    let item = [];
    item.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {(index + 1) + (pagination?.page * pagination?.limit)}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {row?.SKU_ID}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.SKU_Detail}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.quantity}
      </Typography>
    )


    return item;
  })
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value)
    setPagination({ ...pagination, page: 0 })
  }
  useEffect(() => {
    getSKUComponentTableDetails(pagination?.page + 1, pagination?.limit)
  }, [pagination.page,pagination.limit,debouncedSearchValue])
  return (
    <>

      <AlertDialog
        viewDialog={viewModal === "viewComponent"}
        handleClose={() => { setViewModal("") }}
        viewSearchInput={true}
        showDivider={true}
        width="70%"
        // searchTitle="Sales Order ID:"
        title="Connected Components Details"
        searchInputDisplay={
          <FormControl variant="outlined" size="small" fullWidth sx={{ width: 270, }}>
              {/* <Typography sx={{ display: 'inline-block', marginRight: 1, ...textClasses.t15nBold }} >
        Sales Order ID
      </Typography> */}
              <OutlinedInput
                  data-testid="search-input"
                  fullWidth
                  value={searchValue}
                  onChange={handleSearchInputChange}
                  sx={{
                      ...inputClasses.filterField,
                      "&:focus": {
                          borderColor: theme.themeOrange,
                      },
                      "&:before": {
                          borderColor: theme.themeOrange,
                      },
                      "&:after": {
                          borderColor: theme.themeOrange,
                      },
                      "& input::placeholder": { fontSize: "14px" },
                  }}
                  placeholder="Search By Component ID & Name"
                  endAdornment={
                    searchValue == ""
                          ? (
                              <InputAdornment position="end">
                                  <Search fontSize="small" />
                              </InputAdornment>
                          ) : (
                              <IconButton
                                  sx={{
                                      visibility: searchValue != "" ? "visible" : "hidden",
                                      fontSize: "14px",
                                  }}
                                  onClick={() => {
                                      setSearchValue("")
                                  }}
                              >
                                  <Clear fontSize="inherit" />
                              </IconButton>
                          )
                  }
              />
          </FormControl>
      }
        body={
          <>
            {loader ?
              <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                <Grid item><GenericLoader /></Grid>
              </Grid> :
              <Box sx={{ width: "inherit" }}>
                <GenericTable
                  data-testid="connected-components-table"
                  header={tableHeaders}
                  rows={tableRows}
                  pageCount={skuComponentTableRows || 0}
                  pageNumber={pagination.page}
                  handleChangePage={(event, pagevalue) => {
                    setPagination({ ...pagination, page: pagevalue })
                  }}
                  handleChangeRowsPerPage={(event) => {
                    setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                  }}
                  rowsPerPage={pagination.limit}
                />
              </Box>
            }
          </>
        }

      >
        <Button
          variant="outlined"
          data-testid="cancel-btn"
          onClick={() => { setViewModal("") }}
          sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
        >Cancel
        </Button>
      </AlertDialog>
    </>
  )
}
export default ConnectedComponentsTable;