import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from "@mui/material";
import theme, {
    textClasses,
} from "../../../static/styles/theme";
import {
    getFieldsLayoutForEntry,
} from "../../Components/Utils";


const UserDetailSection = ({
    details, formHooks
}) => {
    const { register, control, setValue, errors } = formHooks;
    function autoFillOnFocus() { }

    const layout = getFieldsLayoutForEntry(
        (details?.length > 0 && details) || [],
        register,
        control,
        autoFillOnFocus,
        errors
    );

    return (
        <>
            <Grid
                container
                sx={{
                    marginTop: "2%",
                    width: "95%",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <Grid item sm={12} xs={10}>
                    <Card sx={{ borderRadius: "8px" }}>
                        <CardHeader
                            sx={{
                                p: 2,
                                borderRadius: "8px 8px 0px 0px",
                                backgroundColor: theme.themeGray,
                                color: "black",
                                boxShadow: "0px 1px 10px gray",
                            }}
                            title={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography sx={{ ...textClasses.normalText }}>
                                        2. User Details
                                    </Typography>
                                </Box>
                            }
                        />
                        <CardContent>
                            <Grid container sx={{ mb: 1 }} spacing={2}>
                                {layout.single.map((field, i) => {
                                    // if(field._id=="saleOrderId" && poGenerated){
                                    //     field.disabled = true
                                    // }
                                    return (
                                        <Grid
                                            container
                                            item
                                            xs={6}
                                            sm={4}
                                            justifyContent={"center"}
                                            alignItems="center"
                                            key={i}
                                        >
                                            {field}
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <Grid container spacing={2} sx={{ pt: 1 }}>
                                {layout.multi.map((field, i) => {
                                    return (
                                        <Grid
                                            container
                                            item
                                            xs={6}
                                            sm={4}
                                            justifyContent={"center"}
                                            alignItems="center"
                                            key={i}
                                        >
                                            {field}
                                        </Grid>
                                    );
                                })}
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default UserDetailSection;