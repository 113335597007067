import { FETCH_CONNECTED_SKU_DETAIL} from "../types"


const INIT_STATE ={
    connectedSKUDetailData :[],
    totalRows:0,
}

export default (state=INIT_STATE, action) =>{
    switch(action.type) {
        case FETCH_CONNECTED_SKU_DETAIL :return {...state, connectedSKUDetailData: action.payload.data, totalRows: action.payload.count}
        default: return state
    }
}