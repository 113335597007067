import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormGroup,
  Tooltip,
  IconButton
} from "@mui/material";
import AreaUtilizationStep2 from "./AreaUtilizationStep2";
import theme, {
  textClasses,
  buttonClasses,
} from "../../../../../static/styles/theme";
import { grey, orange } from "@mui/material/colors";
import { GridCloseIcon, GridSearchIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  saveStorageCellCapacity,
  saveWarehouseCapacity,
} from "../../../../../redux/actions";

import { selectStylesOverride } from "../../../../../utils/util";
import { showToast } from "../../../../../services/functions";
import { component, units } from "../utils";
import TypeAndSearch from "../../../TypeAndSearch";
import CreateIcon from "@mui/icons-material/Create";
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import { Cancel } from "@mui/icons-material";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
const utilizationInitialState = {
  totalAreaFeet: "unit",
  totalAreaLength: "",
  totalAreaWidth: "",
  nonStorageFeet: "",
  nonStorageLength: "",
  nonStorageWidth: "",
  stackHeightFeet: "",
  stackHeight: "",
};

const inventoryTypes = [
  { label: "Loose Items", value: "looseItems" },
  { label: "Boxes or Packages", value: "boxOrPackages" },
  // { label: "Pallets", value: "pallets" },
];
const capacityMatrixOptions = [
  { label: "Primary", value: "isPrimary" },
  { label: "Secondary", value: "isSecondary" },
  { label: "Not Applicable", value: "notApplicable" },
];

const AreaUtilizationStep1 = (props) => {
  let {
    showModel,
    setShowModel,
    floorId,
    floorInfo,
    fetchAreautilization,
  } = props
  let cellCapacityDetail = showModel == "updateCapacity" ? props.cellCapacityDetail : {}
  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const { cellStorageCapacity } = useSelector((state) => state.warehouseData);
  const initialStorageCellData = cellCapacityDetail?.capacityDetail?.cellDetail?.map((item) => {
    item.value = item.name
    item.label = item.name

    return item;
  }) || [];

  const [areaType, setAreType] = useState(cellCapacityDetail?.capacityDetail?.areaType || "block");
  const [storageCell, setStorageCell] = useState(initialStorageCellData);
  const [entryQty, setEntryQty] = useState("");
  const [entryWeight, setEntryWeight] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const [step, setStep] = useState(1);
  const [weightCapacity, setWeightCapacity] = useState({
    maxWeight: cellCapacityDetail?.capacityDetail?.weightCapacity?.maxWeight,
    unit:  cellCapacityDetail?.capacityDetail?.weightCapacity?.unit,
  });
  const spaceDimensionsInitialState = {
    demFeet: "",
    demLength: cellCapacityDetail?.capacityDetail?.dimension?.length,
    demWidth: cellCapacityDetail?.capacityDetail?.dimension?.width,
    demHeight: cellCapacityDetail?.capacityDetail?.dimension?.height,
    entryQty: "",
    entryBox: "",
    entryWeight: "",
    entryWeightKg: "",
  };
console.log("storage",cellStorageCapacity)
  const [acceptedInventoryType, setAcceptedInventoryType] = useState({
    looseItems: {
      isActive: false,
    },
    boxOrPackages: {
      isActive: false,
    },
    // pallets: {
    //   isActive: false,
    // },
  });
  const initialRowData = [
    {
      SKU_ID: "default",
      maxCount: 1,
      type: "looseItems",
      label: "Loose Items",
    },
    {
      SKU_ID: "default",
      maxCount: 1,
      type: "boxOrPackages",
      label: "Package or Box",
    },
  ];
  const [SKU_IDs, setSKU_IDs] = useState([]);

  const initialCapacityMatrix = {
    count: {
      isPrimary: true,
      isSecondary: false,
      notApplicable: false,
    },
  };
  let initialCapacityMatrixData = {
    count: {
      isPrimary: false,
      isSecondary: false,
      notApplicable: false,
    },
    weight: {
      isPrimary: false,
      isSecondary: false,
      notApplicable: false,
    },
    dimension: {
      isPrimary: false,
      isSecondary: false,
      notApplicable: false,
    }
  };
  const [capacityMatrix, setCapacityMatrix] = useState(initialCapacityMatrixData);

  const [utilizationMatrixData, setUtilizationMatrixData] = useState(
    utilizationInitialState
  );

  const [spaceDimensions, setSpaceDimensions] = useState(
    spaceDimensionsInitialState
  );

  const totalArea =
    utilizationMatrixData.totalAreaLength *
    utilizationMatrixData.totalAreaWidth || 0;

  const nonStorageArea =
    utilizationMatrixData.nonStorageLength *
    utilizationMatrixData.nonStorageWidth || 0;

  const warehouseCapacity =
    totalArea > nonStorageArea
      ? (totalArea - nonStorageArea) * utilizationMatrixData.stackHeight
      : "0";

  const style = {
    marginTop: "55px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width:'900px'
  };
  const weightUnit = [
    {
      label: "kg",
      value: "kg",
    },
    {
      label: "gram",
      value: "gm",
    },
  ];
  const qauntityUnit = [
    {
      label: "box",
      value: "box",
    },
    {
      label: "nos",
      value: "nos",
    },
  ];

  const radioBtns = [
    {
      value: "block",
      label: "Block",
    },
    // {
    //   value: "rack",
    //   label: "Rack",
    // },
    {
      value: "shelves",
      label: "Shelves",
    },
    // {
    //   value: "Bin",
    //   label: "Bin",
    // },
  ];

  useEffect(() => {
    const floorCapacity = cellStorageCapacity?.floorCapacity;
    setDisableForm(floorCapacity?.isCapacityDefined);
    setUtilizationMatrixData({
      ...utilizationMatrixData,
      totalAreaFeet: floorCapacity?.totalDimension?.unit,
      totalAreaLength: floorCapacity?.totalDimension?.length,
      totalAreaWidth: floorCapacity?.totalDimension?.width,
      nonStorageLength: floorCapacity?.nonStorageDimension?.length,
      nonStorageWidth: floorCapacity?.nonStorageDimension?.width,
      stackHeight: floorCapacity?.maxStackHeight?.height,
      floorId: floorId,
    });
  }, [cellStorageCapacity?.floorCapacity]);
  const capacityMatrixFunction = () => {
    if (cellCapacityDetail?.capacityDetail?.capacityMatrix) {
      let capacityMatrixDetailsData = cellCapacityDetail?.capacityDetail?.capacityMatrix;
      for (let key in capacityMatrixDetailsData) {
        if (key === "isPrimary" || key === "isSecondary") {
          initialCapacityMatrixData["count"][key] = capacityMatrixDetailsData[key] === "count";
          initialCapacityMatrixData["weight"][key] = capacityMatrixDetailsData[key] === "weight";
          initialCapacityMatrixData["dimension"][key] = capacityMatrixDetailsData[key] === "dimension";
        }
        initialCapacityMatrixData["count"]["notApplicable"] = capacityMatrixDetailsData["notApplicable"].includes("count");
        initialCapacityMatrixData["weight"]["notApplicable"] = capacityMatrixDetailsData["notApplicable"].includes("weight");
        initialCapacityMatrixData["dimension"]["notApplicable"] = capacityMatrixDetailsData["notApplicable"].includes("dimension");
      }
      setCapacityMatrix(initialCapacityMatrixData)

    }
  }


  useEffect(() => {
    if (showModel == "updateCapacity") {
      if (cellCapacityDetail?.capacityDetail?.acceptedInventoryType?.length > 0) {
        let tempInventoryTypes = cellCapacityDetail?.capacityDetail?.acceptedInventoryType?.reduce((accu, current) => {
          accu[current] = {
            isActive: true,
          }
          return accu
        }, {})
        setAcceptedInventoryType(tempInventoryTypes)
      }
      capacityMatrixFunction();
      if (cellCapacityDetail?.data?.length > 0) {
        setSKU_IDs(cellCapacityDetail?.data)
      }
    }

  }, [cellCapacityDetail, showModel]);
  const handlespaceDimensions = (e) => {
    setSpaceDimensions({
      ...spaceDimensions,
      [e.target.name]: e.target.value,
    });
  };

  const handleRadio = (e) => {
    setAreType(e.target.value);
  };

  const handleChange = (event) => {
    setUtilizationMatrixData({
      ...utilizationMatrixData,
      [event.target.name]: event.target.value,
    });
  };
  const handleQty = () => {
    if (entryQty) {
      setEntryQty(false);
    } else {
      setEntryQty(true);
    }
  };
  const handleWeight = () => {
    if (entryWeight) {
      setEntryWeight(false);
    } else {
      setEntryWeight(true);
    }
  };
  const handleModal = () => {
    setShowModel(false);
  };

  const handleSubmitDimensions = () => {
    const payload = {
      areaType: areaType,
      saveStep: 1,
      updateUtilization: showModel === "updateCapacity" ? true : false,
      storageDimension: {
        length: spaceDimensions.demLength,
        width: spaceDimensions.demWidth,
        height: spaceDimensions.demHeight,
        unit: utilizationMatrixData.totalAreaFeet,
      },
      floorId: floorId,
      cellId: storageCell.map((item) => item.cellId),
      // quantityCapacity: {
      //   quantity: spaceDimensions.entryQty,
      //   unit: showqauntityUnit,
      // },
      // weightCapacity: {
      //   weight: spaceDimensions.entryWeightKg,
      //   unit: showWeightUnit,
      // },
    };

    dispatch(
      saveStorageCellCapacity(payload, ({ success, message }) => {
        showToast(message, !success);
        if (success) {
          fetchAreautilization(floorInfo);
          handleModal()
        }
      })
    );
  };

  const handleSubmitDimensionsForStep2 = () => {
    const payload = {
      areaType: areaType,
      saveStep: 2,
      updateUtilization: showModel === "updateCapacity" ? true : false,
      storageDimension: {
        length: spaceDimensions.demLength,
        width: spaceDimensions.demWidth,
        height: spaceDimensions.demHeight,
        unit: utilizationMatrixData.totalAreaFeet,
      },
      floorId: floorId,
      cellId: storageCell.map((item) => item.cellId),
      acceptedInventoryType: acceptedInventoryType,
      capacityMatrix: capacityMatrix,
      SKU_IDs: SKU_IDs.map((id) => {
        const sku = id;
        delete sku.label;
        return sku;
      }),
      weightCapacity: weightCapacity,
    };

    dispatch(
      saveStorageCellCapacity(payload, ({ success, message }) => {
        showToast(message, !success);
        if (success) {
          fetchAreautilization(floorInfo);
          handleModal()
        }
      })
    );
  };

  const handleUpdate = () => {
    const payload = {
      totalDimension: {
        length: utilizationMatrixData.totalAreaLength,
        width: utilizationMatrixData.totalAreaWidth,
        unit: utilizationMatrixData.totalAreaFeet,
      },
      floorId: floorId,
      nonStorageDimension: {
        length: utilizationMatrixData.nonStorageLength,
        width: utilizationMatrixData.nonStorageWidth,
        unit: utilizationMatrixData.totalAreaFeet,
      },
      maxStackHeight: Number(utilizationMatrixData.stackHeight || 0),
    };
    dispatch(
      saveWarehouseCapacity(payload, ({ success, message }) => {
        showToast(message, !success);
        if (success) {
          fetchAreautilization(floorInfo);
        }
      })
    );
  };

  const enableSubmit = () => {
    return Boolean(
      !!storageCell.length &&
      !!spaceDimensions.demHeight &&
      !!spaceDimensions.demWidth &&
      !!spaceDimensions.demLength
    );
  };

  const handleSubmitAndAddCapacity = () => {
    setStep(2);
  };

  const clearStates = () => {
    setStorageCell([])
    setSpaceDimensions(spaceDimensionsInitialState);
  }

  const numberOfRows = Math.ceil(
    (storageCell?.length || 0) / 4
  );
  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
    const itemdata = storageCell?.map(
      (dl) => dl.value
    );
    const item = itemdata[index];
    return (
      <div style={{ ...style, padding: '1px',  }}>
        {/* <CustomChip
          data={item || []}
          makeDisabled={false}
          handleDelete={""}
          readLabelKey={"$self"}
        /> */}
        {item && (
          <div style={{ margin: '6px' }}>
            <Grid
              container
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                backgroundColor: theme.themeLightOrange,
                border: `1px solid ${theme.themeOrange}`,
                borderRadius: "12px",
                pl: 1,
                pr: 1,
                minHeight: "32px",
                ml: 2,
                mr: 2
              }}
            >
              <Grid item sm={10} xs={8}>
                {item && item.length > 12 ? (
                  <Tooltip arrow placement="top" title={item}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {item && item.length > 20
                        ? item.substring(0, 20) + "..."
                        : item}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "end" }}>
                <IconButton
                  sx={{ alignContent: "right", padding: '1px' }}
                  onClick={() => {
                    const filteredData = storageCell.filter((_, i) => i !== index);
                    setStorageCell(filteredData);
                  }}
                >
                  <Cancel sx={{ color: theme.themeOrange }} />
                </IconButton>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {["submitCapacity", "updateCapacity"].includes(showModel) ? (
        <Drawer
          anchor={"right"}
          open={showModel}
        //  onClose={toggleDrawer("right", false)}
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                data-testid="heading"
                sx={{
                  ...textClasses.cardTitle,
                  fontWeight: "700",
                  margin: "-15px 0 10px 0",
                }}
              >
                Define area utilization matrix
              </Typography>

              {step === 2 && (
                <Button
                  variant="contained"
                  spacing={1}
                  className="RF_sampledownload-btn"
                  // disabled={!enableSubmit()}
                  onClick={() => setStep(1)}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    marginRight: "4px",
                    padding: "5px 35px",
                  }}
                >
                  Previous Step
                </Button>
              )}
              {step === 1 && (
                <GridCloseIcon
                  sx={{
                    marginLeft: "32%",
                    cursor: "pointer",
                  }}
                  onClick={handleModal}
                />
              )}
            </Box>

            {step === 1 ? (
              <>
                <Box
                  data-testid="capacityBlock"
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRadius: "8px",
                    padding: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                      <Box>
                        <Typography
                          id="modal-modal-title"
                          className="mb-3"
                          sx={{
                            ...textClasses.t20n,
                            fontWeight: "600",
                          }}
                        >
                          Warehouse Capacity
                        </Typography>
                      </Box>
                      <Box
                        hidden={!utilizationMatrixData.totalAreaFeet}
                        sx={{
                          border: "1px solid  #455A64",
                          borderRadius: "8px",
                          cursor: "pointer",
                          color: "#455A64",
                          padding: "5px 5px 0px 5px",
                        }}
                      >
                        <CreateIcon onClick={() => setDisableForm(false)} />
                      </Box>
                    </Box>
                    <Typography
                      data-testid="banner"
                      sx={{
                        textAlign: "center",
                        marginRight: "-20px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        // marginTop: "30px",
                        backgroundColor: "#FF7200",
                        border: "1px solid #FF7200",
                        // borderRadius: "5px",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        // bgcolor: "#ff720014",
                        padding: "2px 30px",
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      Your Warehouse Capacity Is {warehouseCapacity || 0}{" "} 
                      cubic {utilizationMatrixData.totalAreaFeet || "unit"}
                    </Typography>

                  </Box>
                  <Grid container spacing={2} sx={{ flexWrap: "nowrap" }}>
                    <Grid item xs={2.5}>
                      <Typography
                        sx={{
                          ...textClasses.boldText,
                          color: "#000000",
                        }}
                      >
                        Total Area :
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ ...selectStylesOverride }}
                      >
                        <Autocomplete
                          size="small"
                          data-testid="unitDropdown"
                          labelId="jobName-label"
                          sx={{ height: "1.1rem" }}
                          disabled={disableForm}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<Typography>unit</Typography>}
                              sx={{
                                ...selectStylesOverride,
                                fontSize: "14px",
                                "& input::placeholder": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                          )}
                          options={units}
                          onChange={(e, option) =>
                            setUtilizationMatrixData({
                              ...utilizationMatrixData,
                              totalAreaFeet: option?.value || "",
                            })
                          }
                          value={
                            units?.find(
                              (dl) =>
                                dl.value == utilizationMatrixData.totalAreaFeet
                            )?.label || ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              value={option?.value || ""}
                              key={option?.value || ""}
                              {...props}
                            >
                              <Typography
                                sx={{
                                  fontSize: ".8rem",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {option?.label || ""}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      <TextField
                        data-testid="length"
                        id="outlined-basic"
                        label="Length"
                        type="number"
                        variant="outlined"
                        sx={{
                          width: 80,
                          ...selectStylesOverride,
                          fontSize: "14px",
                        }}
                        size="small"
                        name="totalAreaLength"
                        disabled={disableForm}
                        value={utilizationMatrixData.totalAreaLength}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        data-testid="width"
                        id="outlined-basic"
                        label="Width"
                        type="number"
                        variant="outlined"
                        sx={{ width: 80, ...selectStylesOverride }}
                        size="small"
                        name="totalAreaWidth"
                        disabled={disableForm}
                        value={utilizationMatrixData.totalAreaWidth}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#FF7200",
                          textDecoration: "underline",
                          marginTop: "15px",
                          ...textClasses.t14n,
                          fontStyle: "italic",
                          fontWeight: "700",
                        }}
                      >
                        Total area is {totalArea}{" "}
                       sq. {utilizationMatrixData.totalAreaFeet || "unit"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    className="mt-2"
                    sx={{ flexWrap: "nowrap" }}
                  >
                    <Grid item xs={2.5}>
                      <Typography
                        data-testid="nonStorageArea"
                        sx={{
                          ...textClasses.boldText,
                          color: "#000000",
                        }}
                      >
                        Non-Storage Area :
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ ...selectStylesOverride }}
                      >
                        <Autocomplete
                          size="small"
                          data-testid="nonStorageUnitDropdown"
                          labelId="jobName-label"
                          disabled={disableForm}
                          sx={{ height: "1.1rem", fontSize: "14px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<Typography>unit</Typography>}
                              sx={{
                                ...selectStylesOverride,
                                fontSize: "14px",
                                "& input::placeholder": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                          )}
                          options={units}
                          onChange={(e, option) =>
                            setUtilizationMatrixData({
                              ...utilizationMatrixData,
                              totalAreaFeet: option?.value || "",
                            })
                          }
                          value={
                            units?.find(
                              (dl) =>
                                dl.value == utilizationMatrixData.totalAreaFeet
                            )?.label || ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              value={option?.value || ""}
                              key={option?.value || ""}
                              {...props}
                            >
                              <Typography
                                sx={{
                                  fontSize: ".8rem",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {option?.label || ""}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        data-testid="nonStorageAreaLength"
                        id="outlined-basic"
                        label="Length"
                        type="number"
                        variant="outlined"
                        disabled={disableForm}
                        sx={{ width: 80, ...selectStylesOverride }}
                        size="small"
                        name="nonStorageLength"
                        value={utilizationMatrixData.nonStorageLength}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={1.5}>
                      <TextField
                        data-testid="nonStorageAreaWidth"
                        id="outlined-basic"
                        label="Width"
                        type="number"
                        variant="outlined"
                        disabled={disableForm}
                        sx={{ width: 80, ...selectStylesOverride }}
                        size="small"
                        name="nonStorageWidth"
                        value={utilizationMatrixData.nonStorageWidth}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#FF7200",
                          textDecoration: "underline",
                          marginTop: "15px",
                          ...textClasses.t14n,
                          fontStyle: "italic",
                          fontWeight: "700",
                        }}
                      >
                        Total area is {nonStorageArea}{" "}
                       sq. {utilizationMatrixData.totalAreaFeet || "unit"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="mt-2">
                    <Grid item xs={2.8}>
                      <Typography
                        sx={{
                          ...textClasses.boldText,
                          color: "#000000",
                        }}
                      >
                        Maximum Stack Height :
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ ...selectStylesOverride }}
                      >
                        <Autocomplete
                          size="small"
                          labelId="jobName-label"
                          disabled={disableForm}
                          sx={{ height: "1.1rem", fontSize: "14px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={<Typography>unit</Typography>}
                              sx={{
                                ...selectStylesOverride,
                                fontSize: "14px",
                                "& input::placeholder": {
                                  fontSize: "14px",
                                },
                              }}
                            />
                          )}
                          options={units}
                          onChange={(e, option) =>
                            setUtilizationMatrixData({
                              ...utilizationMatrixData,
                              totalAreaFeet: option?.value || "",
                            })
                          }
                          value={
                            units?.find(
                              (dl) =>
                                dl.value == utilizationMatrixData.totalAreaFeet
                            )?.label || ""
                          }
                          renderOption={(props, option, { selected }) => (
                            <MenuItem
                              value={option?.value || ""}
                              key={option?.value || ""}
                              {...props}
                            >
                              <Typography
                                sx={{
                                  fontSize: ".8rem",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {option?.label || ""}
                              </Typography>
                            </MenuItem>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.4}>
                      <TextField
                        id="outlined-basic"
                        label="Height"
                        type="number"
                        variant="outlined"
                        disabled={disableForm}
                        sx={{ width: 80, ...selectStylesOverride }}
                        size="small"
                        name="stackHeight"
                        value={utilizationMatrixData.stackHeight}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container xs sx={{ display: "flex", gap: "1rem" }}>
                    <Button
                      variant="contained"
                      spacing={1}
                      className="RF_sampledownload-btn"
                      disabled={!utilizationMatrixData.stackHeight}
                      onClick={handleUpdate}
                      sx={{
                        ...buttonClasses.lynkitBlackFill,
                        marginRight: "4px",
                        margin: "20px 0 0 -10px",
                        padding: "5px 35px",
                      }}
                    >
                      Update
                    </Button>
                    {/* <Button
                      variant="contained"
                      spacing={1}
                      className="RF_sampledownload-btn"
                      disabled={!utilizationMatrixData.stackHeight}
                      onClick={() => setDisableForm(false)}
                      hidden={!utilizationMatrixData.totalAreaFeet}
                      // onClick={handleUpdate}
                      sx={{
                        marginRight: "4px",
                        borderRadius: "8px",
                        backgroundColor: "black",
                        textTransform: "none",
                        margin: "20px 0 0 -10px",
                        padding: "5px 35px",
                      }}
                    >
                      Edit
                    </Button> */}
                  </Grid>
                </Box>

                <Box
                  data-testid="spaceDimension"
                  sx={{
                    width: "100%",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderRadius: "8px",
                    marginTop: "20px",
                  }}
                >
                  <Typography
                    data-testid="storageSpaceDimension"
                    sx={{
                      ...textClasses.cardTitle,
                      fontWeight: "700",
                      margin: "-15px 0 0 10px",
                      paddingTop: "10px",
                    }}
                  >
                    Storage Space Dimensions
                  </Typography>
                  <Box
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                      borderRadius: "8px",
                      m: "8px",
                      p: "8px",
                    }}
                  >
                    <Grid
                      container
                      xs={12}
                      spacing={2}
                      className="container mt-1"
                    >
                      <Grid item xs={2}>
                        <Typography
                          data-testid="areaType"
                          sx={{
                            ...textClasses.boldText,
                            color: "#000000",
                            alignSelf: "center",
                          }}
                        >
                          Area Type :
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            value={areaType}
                            name="row-radio-buttons-group"
                            sx={{ flexWrap: "nowrap" }}
                          >
                            {radioBtns.map((item) => (
                              <FormControlLabel
                                disabled={!!storageCell.length}
                                data-testid={`${item.value}`}
                                onChange={handleRadio}
                                value={item.value}
                                control={
                                  <Radio
                                    sx={{
                                      color: grey[800],
                                      "&.Mui-checked": {
                                        color: orange[800],
                                      },
                                    }}
                                  />
                                }
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {areaType === "block" ? (
                        <Grid item xs={4}>
                          {/* <Box
                sx={{
                  width: 300,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              > */}
                          <TypeAndSearch
                            component={component("block", floorId)}
                            value={storageCell}
                            setEmptyOnChange={false}
                            handleChange={(e, option) => {
                              if (
                                option &&
                                !storageCell.find(
                                  (item) => item.value === option.value
                                )
                              ) {
                                setStorageCell([option, ...storageCell]);
                              }
                            }}
                          />
                          {/* </Box> */}
                        </Grid>
                      ) : (
                        <Grid container item xs={6} spacing={2}>
                          <Grid item xs={6}>
                            <TypeAndSearch
                              fullWidth
                              component={component("rack", floorId)}
                              value={""}
                              setEmptyOnChange={false}
                              handleChange={(e, option) => {
                                if (
                                  option &&
                                  !storageCell.find(
                                    (item) => item.value === option.value
                                  )
                                ) {
                                  setStorageCell([option, ...storageCell]);
                                }
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TypeAndSearch
                              fullWidth
                              component={component("shelves", floorId)}
                              value={storageCell}
                              setEmptyOnChange={false}
                              handleChange={(e, option) => {
                                const fetchBlock = option?.value.split("_")


                                const existingBlock = fetchBlock && fetchBlock[0] && storageCell.find((option) => option.value === fetchBlock[0])
                                if (existingBlock) {
                                  showToast("This entire block is Already selected", true)
                                } else
                                  if (
                                    option &&
                                    !storageCell.find(
                                      (item) => item.value === option.value
                                    )
                                  ) {
                                    setStorageCell([option, ...storageCell]);
                                  }
                              }}
                            />
                              {/* <Grid item xs={12}>
                                <FormGroup>
                                  <FormControlLabel control={<Checkbox defaultChecked />} label="Select all Shelves" />
                                </FormGroup>
                              </Grid> */}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {storageCell?.length === 0 ? null :
                      <Box
                        sx={{
                          border: "2px solid #FF7200",
                          borderRadius: "8px",
                          padding: "0px 20px 10px 0",
                          marginBottom: "20px",
                          width: '100%',
                          height: '210px'
                        }}
                      >
                        <AutoSizer>
                          {({ width, height }) => {
                            return (
                              <FixedSizeGrid
                                columnCount={4} // Number of columns
                                columnWidth={190} // Width of each column
                                height={numberOfRows < 3 ? numberOfRows * 50 : 210} // Total height of the grid
                                rowCount={numberOfRows}
                                rowHeight={50} // Height of each row
                                width={width} // Total width of the grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: '792px',
                                  position: 'absolute',
                                  left: '8px'

                                }}
                              >
                                {cellRenderer}
                              </FixedSizeGrid>
                            );
                          }}
                        </AutoSizer>
                      </Box>}
                    <Box
                      sx={{
                        marginTop: "12px",
                      }}
                    >

                    </Box>
                  </Box>

                  <Box
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                      borderRadius: "8px",
                      m: "8px",
                      p: "8px",
                    }}
                  >
                    <Grid container spacing={2} sx={{ p: "8px" }}>
                      <Grid container item xs={2.5}>
                        <Grid item xs={12}>
                          <Typography
                            data-testid="dimensionBlock"
                            sx={{
                              ...textClasses.boldText,
                              color: "#000000",
                            }}
                          >
                            Dimensions :
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={9.5}>
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={2.4}>
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{ ...selectStylesOverride }}
                            >
                              <Autocomplete
                                data-testid="dimensionUnitDropdown"
                                size="small"
                                labelId="jobName-label"
                                sx={{ height: "1.1rem" }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={<Typography>unit</Typography>}
                                    sx={{
                                      ...selectStylesOverride,
                                      fontSize: "14px",
                                      "& input::placeholder": {
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                )}
                                options={units}
                                onChange={(e, option) =>
                                  setUtilizationMatrixData({
                                    ...utilizationMatrixData,
                                    totalAreaFeet: option?.value || "",
                                  })
                                }
                                value={
                                  units?.find(
                                    (dl) =>
                                      dl.value ==
                                      utilizationMatrixData.totalAreaFeet
                                  )?.label || ""
                                }
                                renderOption={(props, option, { selected }) => (
                                  <MenuItem
                                    value={option?.value || ""}
                                    key={option?.value || ""}
                                    {...props}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: ".8rem",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {option?.label || ""}
                                    </Typography>
                                  </MenuItem>
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              data-testid="dimensionLengthField"
                              id="outlined-basic"
                              label="Length"
                              type="number"
                              variant="outlined"
                              sx={{ width: 80, ...selectStylesOverride }}
                              size="small"
                              name="demLength"
                              value={spaceDimensions?.demLength}
                              onChange={handlespaceDimensions}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              data-testid="dimensionWidthField"
                              id="outlined-basic"
                              label="Width"
                              variant="outlined"
                              type="number"
                              sx={{ width: 80, ...selectStylesOverride }}
                              size="small"
                              name="demWidth"
                              value={spaceDimensions?.demWidth}
                              onChange={handlespaceDimensions}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              data-testid="dimensionHeightField"
                              id="outlined-basic"
                              label="Height"
                              variant="outlined"
                              type="number"
                              sx={{ width: 80, ...selectStylesOverride }}
                              size="small"
                              name="demHeight"
                              value={spaceDimensions?.demHeight}
                              onChange={handlespaceDimensions}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              ...textClasses.cardTitle,
                              fontWeight: "400",
                              fontSize: "12px",
                              fontStyle: "italic",
                              margin: "-15px 0 0 10px",
                              paddingTop: "12px",
                            }}
                          >
                            *dimensions will be same for above selected
                            locations
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography
                    data-testid="infoAboutNextPage"
                    sx={{
                      textAlign: "center",
                      margin: "8px",
                      border: "1px solid #FF7200",
                      borderRadius: "5px",
                      bgcolor: "#ff720014",
                      padding: "10px 30px",
                      color: "#FF7200",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                    Next Step : Define Location Wise Inventory Holding Capacity
                    By Clicking On ‘Submit & Add Capacity’ Button
                  </Typography>

                  {/* <Grid container spacing={2} className="mt-3 ms-1">
                <Grid item xs={2.1}>
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: "#000000",
                    }}
                  >
                    Capacity :
                  </Typography>
                </Grid>
                <Grid item xs={0}>
                  <Checkbox
                    {...label}
                    onChange={handleQty}
                    sx={{
                      margin: "-10px 0 0 0",
                      color: grey[800],
                      "&.Mui-checked": {
                        color: orange[600],
                        ...selectStylesOverride,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0}>
                  <Typography
                    sx={{
                      margin: "2px 0 0 -16px ",
                      ...textClasses.t14n,
                      fontWeight: "600",
                      color: "#827878",
                    }}
                  >
                    As QTY
                  </Typography>
                </Grid>

                <Grid item xs={0}>
                  <Checkbox
                    {...label}
                    onChange={handleWeight}
                    sx={{
                      margin: "-10px 0 0 0",
                      color: grey[800],
                      "&.Mui-checked": {
                        color: orange[600],
                        ...selectStylesOverride,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      margin: "2px 0 0 -16px ",
                      ...textClasses.t14n,
                      fontWeight: "600",
                      color: "#827878",
                    }}
                  >
                    As Weight
                  </Typography>
                </Grid>
              </Grid> */}
                  {/* <Grid
                container
                spacing={2}
                className="mt-2 d-flex justify-content-center"
              >
                <Grid item xs={4.3}>
                  <TextField
                    id="outlined-basic"
                    label="entry qty"
                    variant="outlined"
                    type="number"
                    sx={{ width: 180, ...selectStylesOverride }}
                    size="small"
                    disabled={!entryQty}
                    name="entryQty"
                    value={spaceDimensions.entryQty}
                    onChange={handlespaceDimensions}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    // value={areaType}
                    disabled={!entryQty}
                    options={qauntityUnit}
                    onChange={(e, option) =>
                      setShowqauntityunit(option?.label || "")
                    }
                    sx={{ width: 80, ...selectStylesOverride }}
                    renderInput={(params) => (
                      <TextField {...params} label={"Unit"} />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="mt-0  d-flex justify-content-center"
              >
                <Grid item xs={4.3}>
                  <TextField
                    id="outlined-basic"
                    label="enter weight"
                    variant="outlined"
                    type="number"
                    sx={{ width: 180, ...selectStylesOverride }}
                    size="small"
                    disabled={!entryWeight}
                    name="entryWeightKg"
                    value={spaceDimensions.entryWeightKg}
                    onChange={handlespaceDimensions}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    // value={weightUnit}
                    disabled={!entryWeight}
                    onChange={(e, option) =>
                      setShowWeightUnit(option?.label || "")
                    }
                    options={weightUnit}
                    sx={{ width: 80, ...selectStylesOverride }}
                    renderInput={(params) => (
                      <TextField {...params} label={"Unit"} />
                    )}
                  />
                </Grid>
              </Grid> */}
                  <Button
                    variant="contained"
                    spacing={1}
                    // className="RF_sampledownload-btn"
                    // disabled={!enableSubmit()}
                    onClick={clearStates}
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      marginRight: "4px",
                      margin: "20px 0 12px 16px",
                      padding: "5px 35px",
                    }}
                  >
                    Clear All
                  </Button>
                  <Button
                    // className="AllocateCancel-btn"
                    sx={{
                      ...buttonClasses.lynkitOrangeEmpty,
                      marginRight: "4px",
                      margin: "20px 0 12px 16px",
                      padding: "5px 35px",
                      
                    }}
                    disabled={!enableSubmit()}
                    onClick={handleSubmitDimensions}
                    variant="outlined"
                  >
                    {showModel === "updateCapacity" ? "Update" : "Submit"}
                  </Button>
                  <Button
                    variant="contained"
                    spacing={1}
                    disabled={!enableSubmit()}
                    onClick={handleSubmitAndAddCapacity}
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      marginRight: "4px",
                      borderRadius: "8px",
                      // backgroundColor: "black",
                      textTransform: "none",
                      margin: "20px 0 12px 16px",
                      padding: "5px 35px",
                    }}
                  >
                    {showModel === "updateCapacity" ? "Update Capacity" : "Submit and Add Capacity"}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <AreaUtilizationStep2
                  storageCell={storageCell}
                  areaType={areaType}
                  setStorageCell={setStorageCell}
                  inventoryTypes={inventoryTypes}
                  capacityMatrixOptions={capacityMatrixOptions}
                  acceptedInventoryType={acceptedInventoryType}
                  setAcceptedInventoryType={setAcceptedInventoryType}
                  capacityMatrix={capacityMatrix}
                  setCapacityMatrix={setCapacityMatrix}
                  weightCapacity={weightCapacity}
                  setWeightCapacity={setWeightCapacity}
                  SKU_IDs={SKU_IDs}
                  setSKU_IDs={setSKU_IDs}
                  handleSubmitDimensionsForStep2={
                    handleSubmitDimensionsForStep2
                  }
                  initialCapacityMatrix={initialCapacityMatrix}
                  initialRowData={initialRowData}
                  cellCapacityDetail={cellCapacityDetail}
                />
              </>
            )}
          </Box>
        </Drawer>
      ) : (
        ""
      )}
    </>
  );
};
export default AreaUtilizationStep1;
