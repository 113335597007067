export const UserData = [
    {
        "approved": true,
        "isOnDuty": false,
        "_id": "6344f9ab08692b1f1c5afcc0",
        "name": "Worker 1",
        "email": "codin@gmail.com",
        "mobile": "7619826349",
        "base": "Coding Ninjas",
        "userType": "worker",
        "designation": "Worker",
        "company": "Ninja Coding",
        "permissions": {
            "new_gridView": true,
            "dashboard": true,
            "addAreaMapping": true,
            "jobTypeCreation": true,
            "viewJobList": true,
            "gateIngateOut": true
        },
        "createdAt": "2022-10-11T05:05:47.050Z",
        "updatedAt": "2023-06-14T06:53:51.316Z",
        "__v": 0,
        "viewDutyLogs": true,
        "viewJobList": true,
        "viewShipmentDetail": true
    },
    {
        "approved": true,
        "isOnDuty": false,
        "_id": "6464b0954fb3ef4388a1da98",
        "name": "Aadi",
        "email": "aadi@lynkit.in",
        "mobile": "7878779541",
        "base": "Coding Ninjas",
        "userType": "worker",
        "designation": "Worker",
        "company": "Ninja Coding",
        "permissions": {
            "viewUsers": true,
            "createUsers": true,
            "addAreaMapping": true,
            "jobTypeCreation": true,
            "viewJobList": true,
            "gateIngateOut": true
        },
        "createdAt": "2023-05-17T10:46:45.722Z",
        "updatedAt": "2023-05-17T10:46:45.722Z",
        "__v": 0
    }
]

export const designation = [
    { 'Designation': 'designation' }
]

export const currentStatus = [
    // { 'label': "All Status" , id :'all' },
    { 'label': "Active" , value :true },
    { 'label': "Blocked" , value :false }
]

export const role = [
    { 'worker': 'worker' },
]