
import React from "react";
import {
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";

import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";


const Detailview = ({isSummary=false, headingText="",detailText="",detailChilden=false }) => {

    return (
        <>
            <Typography
                sx={{
                    ...textClasses.normalText,
                    mb: 2,
                    p: 1,
                    color: theme.themeOrange,
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                }}
            >   
                {
                    isSummary ?
                        <details>
                            <summary>{headingText}</summary>
                            {detailChilden
                                ? detailChilden
                                : <p style={{ padding: "10px" }}>{detailText}</p>
                            }
                        </details>
                        : headingText
                }
            </Typography>
        </>
    )

}

export default Detailview;