import {FETCH_START, FETCH_ERROR, FETCH_SUCCESS} from "../types"
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const addReasonAPI = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('/inventoryAdjustment/createAdjustmentReason', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true);  
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
            showToast(error.message, true);
            if (cb) {
                cb({})
            }
        });
    }
};
