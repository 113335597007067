import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, FormControlLabel, Grid, Radio, TextField,Box, Typography, FormLabel, Select, Button, MenuItem, IconButton, TablePagination, FormControl, Autocomplete } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import colors, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme';
import theme from '../../../static/styles/theme';
import { Add, Remove, RemoveCircle } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { selectStylesOverride } from "../../../utils/util";
import { allocateTag, deallocateTag, getInventoryForModal, getItemDetail, getRfidTags, getUsedRfidTags } from '../../../redux/actions';


let tempSelect={tagId:"",isAdd:false,packageId:"",itemId:"",isItemExist:false}

const AllocateSection = ({invType,data, handleCloseModal, allTags, selectedinv, allocateRfid, deallocateRfid, usedTags, totalRows, page, setPage, limit, setLimit}) => {
  const dispatch = useDispatch();
  const itemData = useSelector((state) => state.inventory.itemDetail);

    const [selectedData, setSelectedData] = useState({...tempSelect})
    const [rfidEPC, setRfidEPC] = useState("")
    const [inventories, setInventories] = useState([])
    

    const handleMap = () => {
        // allocateRfid(tagId, itemId);
        setSelectedData({...tempSelect})
    }
    // ////("Used Tags");
    // ////(usedTags);

    const handleRemoveTag = (tagId) => {
        deallocateRfid(tagId);
    }

    const header = ["Item ID(s)", "EPC", "Action"]
    const rows = usedTags.map((used, i) => {
        let row = []
        row.push(
            <Typography sx={{...inputClasses.normalText}}>{used.itemId}</Typography>
        )
        row.push(
            <Typography sx={{...inputClasses.normalText}}>{used.epc}</Typography>
        )
        row.push(
            <IconButton onClick={() => handleRemoveTag(used.tagId)}>
                <RemoveCircle sx={{color: "red"}}/>
            </IconButton>
        )
        return row
    })

    const selectItemHandler =(item ={})=>{
      setInventories([])
      // setSelectedData()
      if(invType=="Package" && item?.isItemExist){
        let payload = {
            // shipmentId: data.shipmentId,   
            packageId: item.value || ""
        }
        setSelectedData({...selectedData,isItemExist:true})
        dispatch(getInventoryForModal("itemDetail",payload,((data=[])=>{
          setInventories((data || [])?.map(dl=>({label:dl.itemId,value:dl.itemId})))
        }))
        )
      }
      else{
        setSelectedData({...selectedData,isItemExist:false})
      }
    }
    return (
      <>
        <Grid container>
          <Grid item sm={4} xs={4}>
            <Typography sx={{ ...textClasses.cardTitle }}>
              RFID Mapping
            </Typography>
          </Grid>
          {/* <Grid container direction={"row"} item sm={8} xs={8} alignItems="center" justifyContent={"end"}>
                    <Typography sx={{...textClasses.normalText, fontSize: "20px", mr: 1}}>Shipment Id: </Typography>
                    <TextField variant='outlined' size='small' sx={{...inputClasses.shadowField, color: colors.themeOrange}} type={"text"} value={data && data.shipmentId} disabled/>
                </Grid> */}
        </Grid>
        <Grid container sx={{ mt: 1 }}>
          {/* <Grid container item sm={2} xs={3} alignItems="center">
                    <FormLabel required sx={{...textClasses.normalText, color: colors.themeOrange}}>Mapping</FormLabel>
                </Grid> */}
          {/* <Grid container direction={"row"} item sm={6} xs={6} alignItems="center" justifyContent={"start"}>
                    <FormControlLabel control={<Radio size="small" sx={{color: colors.themeOrange, "&, &.Mui-checked": {color: colors.themeOrange}}}/>} label="Individual Item(s)" />
                </Grid> */}
        </Grid>
        <Grid container>
          {/* <Grid container item sm={2} xs={3} alignItems="center"></Grid> */}
          <Grid
            container
            direction={"row"}
            item
            sm={12}
            xs={12}
            // alignItems="center"
            // justifyContent={"start"}
          >
            <Grid container spacing={2}>
              <Grid item sm={3.6} xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            {["Item","Kit"].includes(invType)?"Select item id":'Select package id'}
                            {/* Select item id */}
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      )}
                    getOptionLabel={option=>option.label}
                    options={selectedinv|| []}
                    onChange={(e, option) => selectItemHandler(option)}
                    // value={itemId || ""}
                  />
                </FormControl>
              </Grid>
              {selectedData.isItemExist == true?<Grid item sm={3.6} xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Select item id
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      )}
                    getOptionLabel={option=>option.label}
                    options={inventories|| []}
                    // onChange={(e, option) => selectItemHandler(option)}
                    // value={itemId || ""}
                  />
                </FormControl>
              </Grid>:'' }
                
              <Grid item sm={3.6} xs={4}>
              <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            RFID (EPC)
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.tagId} ({option.epc})
                        </Box>
                      )}
                    getOptionLabel={option=>option.tagId}
                    options={allTags|| []}
                    onChange={(e, option) => setSelectedData({...selectedData,tagId:option?.epc})}
                    // value={itemId || ""}
                  />
                </FormControl>
              </Grid>
              <Grid
                container
                item
                sm={1}
                xs={2}
                alignItems="center"
                justifyContent={"center"}
              >
                <Button
                  disabled={!selectedData?.isAdd}
                  variant="outlined"
                  sx={{
                    ...buttonClasses.small,
                    color: "white",
                    backgroundColor: "black",
                    "&:hover": { backgroundColor: "black", color: "white" },
                    "&:disabled": {
                      cursor: "not-allowed !important",
                      backgroundColor: "gray",
                      color: "white",
                    },
                  }}
                  onClick={handleMap}
                >
                  Map
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Table
            header={header}
            rows={rows}
            totalRows={totalRows}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        </Grid>
        <Grid container sx={{ p: 1 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              ...buttonClasses.outlined,
              borderColor: colors.themeOrange,
              color: colors.themeOrange,
              minWidth: "100px",
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </Grid>
      </>
    );
}


export default function Rfid({invType,data, showModal, handleCloseModal, refreshInvetory}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const allTags = useSelector(state => state.inventory.allTags);
    const usedTags = useSelector(state => state.inventory.usedTags);
    const totalRows = useSelector(state => state.inventory.usedTagsRows);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const refreshData = () => {
        dispatch(
            getRfidTags()
        )
        // let payload = {
        //     // shipmentId: data.shipmentId,   
        //     packageId: data.packageId ? data.packageId : ""
        // }
        // dispatch(
        //     getInventoryForModal("itemDetail", {
        //         ...payload
        //     })
        // )

        dispatch(
            getUsedRfidTags({
                shipmentId: data.shipmentId
            })
        )
    }

    const allocateRfid = (tagId, mappingId) => {
        dispatch(
            allocateTag({
                tagId: tagId,
                mappingId: mappingId
            }, () => {
                refreshData();
                refreshInvetory();
            })
        )
    }

    const deallocateRfid = (tagId) => {
        dispatch(
            deallocateTag({
                tagId: tagId
            }, () => {
                refreshData();
                refreshInvetory();
            })
        )
    }

    useEffect(() => {
        if(data){
            refreshData();
        }
    }, [data, page, limit])

    return (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            scroll={"paper"}
            fullScreen={fullScreen}
            PaperProps={{sx: {width: "80%"}}}
            maxWidth="80%"
            
        >
            <DialogContent dividers>
                <AllocateSection
                invType={invType}
                    data={data}
                    handleCloseModal={handleCloseModal}
                    allTags={allTags}
                    selectedinv={data}
                    allocateRfid={allocateRfid}
                    deallocateRfid={deallocateRfid}
                    usedTags={usedTags}
                    totalRows={totalRows}
                    page={page}
                    setPage={setPage}
                    limit={limit}
                    setLimit={setLimit}
                />
            </DialogContent>
            
        </Dialog>
    )
}


const Table = ({header, rows, totalRows, page, limit, setPage, setLimit}) => {
    return (
        <>
            <table style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap"}}>
                <thead style={{backgroundColor: theme.themeGray}}>
                    <tr>
                        {
                            header.slice(0, 2).map((head, i) => {
                                return (
                                    <td style={{padding: "14px 10px 14px 14px", ...textClasses.boldText, textAlign: "left"}}>{head}</td>
                                )
                            })
                        }
                        {
                            header.slice(2, header.length).map((head, i) => {
                                return (
                                    <td style={{padding: "13px 10px 13px 10px", ...textClasses.boldText, textAlign: "center"}}>{head}</td>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length > 0
                        ?
                            <>
                                {
                                    rows.map((row, i) => (
                                        <tr
                                            key={i}
                                            style={{borderBottom: "1px solid #0000002B"}}   
                                        >
                                            {
                                                row.slice(0, 2).map((col, j) => {
                                                    return (
                                                        // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                        <td style={{textAlign: "left", padding: "14px 5px 14px 14px", ...textClasses.normalText}}>
                                                            {col}
                                                        </td>    
                                                    )
                                                })
                                            }
                                            {
                                                row.slice(2, row.length).map((col, j) => {
                                                        return (
                                                            // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                            <td style={{textAlign: "center", padding: "14px 5px 14px 14px", ...textClasses.normalText}}>
                                                                {col}
                                                            </td>    
                                                        )
                                                })
                                            }
                                        
                                        </tr>
                                    ))
                                }
                                    
                            </>
                        :
                            <tr
                                sx={{'&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <td component="th" scope="row" colSpan={100} style={{padding: "14px 0 14px 0" ,...textClasses.normalText, textAlign: "center", overflowWrap: "break-word"}}>
                                    No Allocation Found                                       
                                </td>   
                            
                            </tr>
                            
                                
                    }
                </tbody>
            </table>
            <Grid container alignItems={"center"} justifyContent="center">
                <TablePagination
                    sx={{
                        ".MuiTablePagination-selectLabel":{pt: "10px"},
                        ".MuiTablePagination-displayedRows": {pt: "10px"}
                    }}
                    component="div"
                    count={totalRows}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    rowsPerPage={limit}
                    onRowsPerPageChange={(e) => {setLimit(e.target.value); setPage(0)}}
                />
            </Grid>
        </>
      
      
    )
}