import React, { useState } from "react";
import AlertDialog from "../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment, InputLabel, FormHelperText } from "@mui/material";
import theme, { textClasses, buttonClasses, inputClasses } from "../../static/styles/theme";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GenericLoader from "../Components/Generic/GenericLoader";
import { changePasswordCase } from "../../redux/actions";
import { showToast } from "../../services/functions";
import { encryptTheData } from "../../services/Api";
const ChangePasswordView = ({ viewModal, setViewModal, handleMenuClose }) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dataReceieved, isDataReceived] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };
    const handleChangePassword = (data) => {
        isDataReceived(true)
        let encryptedData={payloadData:encryptTheData(data)}
        dispatch(
            changePasswordCase(encryptedData, (response = {}) => {
                showToast(response.message, !response.success);
                setLoader(false)
                isDataReceived(false)
                if (response.success) {
                    reset();
                    setViewModal({ ...viewModal, type: '' })
                    handleMenuClose();
                }
            })
        )
    }
    return (
        <>
            <AlertDialog
                viewDialog={viewModal.type === "viewChangePassword"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                viewSearchInput={true}
                showDivider={true}
                width="35%"
                // actionsCSS={{ justifyContent: 'flex-start' }}
                body={
                    <>
                        {loader ?
                            <Grid item container data-testid="loader" direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 5, height: "80vh" }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid>
                            : <>
                                <Grid container sx={{ p: 2 }} alignItems={"center"} justifyContent={"center"}>
                                    <Grid item sm={12} xs={12}>
                                        <Typography align='center' sx={{ fontWeight: "600", fontSize: "20px", lineHeight: "27px", }}>Change Password</Typography>
                                    </Grid>
                                </Grid>
                                <Grid sx={{ pl: 4, pr: 4, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <FormControl fullWidth variant="outlined" size="small" sx={inputClasses.textField}>
                                        <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                        <OutlinedInput
                                            error={errors.password ? true : false}
                                            {...register("password", { required: true })}
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            data-testid="password-input"
                                            label="Current Password"
                                            fullWidth
                                            placeholder='Enter Current Password'
                                        />
                                        {
                                            errors.password && <FormHelperText id="outlined-weight-helper-text" style={{ color: "#d32f2f" }}>Please enter current password</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid sx={{ pl: 4, pr: 4, mt: 2 }} container direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <FormControl fullWidth variant="outlined" size="small" sx={inputClasses.textField}>
                                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                        <OutlinedInput
                                            error={errors.newPassword ? true : false}
                                            {...register("newPassword", { required: true })}
                                            id="outlined-adornment-password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownNewPassword}
                                                        edge="end"
                                                        data-testid="show-password"
                                                    >
                                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            data-testid="new-password-input"
                                            label="New Password"
                                            fullWidth
                                            placeholder="Enter New Password"
                                        />
                                        {
                                            errors.newPassword && <FormHelperText id="outlined-weight-helper-text" style={{ color: "#d32f2f" }}>Please enter new password</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid container item sx={{ pl: 3, pr: 4, mt: 2, }}>
                                    <Button variant="outlined"
                                        data-testid="change-password-btn" sx={{ ...buttonClasses.lynkitOrangeFill, m: 1, fontSize: ".7rem", minWidth: "130px" }} onClick={handleSubmit(handleChangePassword)} disabled={dataReceieved}>
                                        Change Password
                                    </Button>
                                </Grid>
                            </>
                        }
                    </>
                }
            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >
                    Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ChangePasswordView;
