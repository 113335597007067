import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    TextField,
    Typography,
    Breadcrumbs,
    Autocomplete,
} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../../static/styles/theme";
import { Link, useLocation } from 'react-router-dom';
import { showToast, validateFieldsMultiple, getCamelCaseText } from "../../../../../services/functions";
import { addFloor, getSuperAdminCompanyData, getTemplate,updateCompany ,getCompanyDetails} from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../../services/history";
import MultipleFormSave from "../../MultipleFormSave";
import { selectStylesOverride } from "../../../../../utils/util";
import GenericLoader from "../../../../Components/Generic/GenericLoader";


const AddFloor = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editableFloorId = queryParams.get("id")
    const dispatch = useDispatch();
    const [loader,setLoader] = useState("")
    const [items, setItems] = useState([{}]);
    const [errors, setErrors] = useState([])
    const [dataReceieved, isDataReceived] = useState(false)
    const [templateDetail, setTemplateDetail] = useState([])
    const [selectedWarehouseDetail, setSelectedWarehouseDetail] = useState({})
    const [warehouseList, setWarehouseList] = useState([])
    let isEditable = false;
    isEditable = editableFloorId ?true:false;
    const handleSubmitData = () => {
        const tempError = validateFieldsMultiple(templateDetail?.attributes, items)
        setErrors(tempError);
        if (tempError?.length > 0) {
            let errorDetail = Object.values(tempError[0] || {})?.at(0) || {}
            showToast(errorDetail.message, true);
            return;
        }
        isDataReceived(true)
        let payload = {
            warehouseId: selectedWarehouseDetail?._id,
            floorData: items
        };

        if(isEditable){
            dispatch(updateCompany({ updatedData: items[0], type: "floor", componentId: editableFloorId }, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/warehouseFloor")
                }
            }))
        }else{
            dispatch(addFloor(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/warehouseFloor")
                }
            }))
        }
    }

    const handleClearAll = (attributes = [], data = {}) => {
        let resetD = items?.map(dl => {
            let tempObj = {}
            for (let ele of attributes) {
                if(data[ele?._id]){
                    tempObj[ele?._id] = data[ele?._id]
                }
                else{

                    tempObj[ele?._id] = ele?.type == "checkbox" ? [] : ""
                }
            }
            return tempObj;
        })
        setItems(resetD)
    }
  
    useEffect(() => {
        setLoader("getDynamicTemplate")
        dispatch(getTemplate({ type: "floor" }, (data) => {
            setTemplateDetail(data)
            setLoader("")
            if (isEditable) {
                dispatch(getCompanyDetails({ _id: editableFloorId }, (detailData) => {
                    handleClearAll(data?.attributes || [], detailData?.data?.formData)
                    setSelectedWarehouseDetail({_id:detailData?.data?.warehouseId,name:detailData?.data?.name})
                    setWarehouseList([{_id:detailData?.data?.warehouseId,name:detailData?.data?.name}])
                }))
            }
        }))
        if(!isEditable){
        dispatch(getSuperAdminCompanyData({ projections: { _id: 1, name: 1, type: 1 }, type: 'warehouse' }, (data) => {
            setWarehouseList(data)
        }))
    }
    }, [])
    return (<>
    <Grid container sx={{display:'flex',justifyContent:'space-between'}}>
        <Grid item sm={6}>
        <Typography sx={{ ...textClasses.cardTitle }}>Manage Floor</Typography>

        <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-floor">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/warehouseFloor">
                    Manage Floor
                </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>{isEditable ? "Edit Floor":"Create New Floor"}</Typography>
        </Breadcrumbs>
        </Grid>
        <Grid item sm={3}>
            <Autocomplete
                data-testid="select-warehouse-dropdown"
                value={selectedWarehouseDetail}
                onChange={(e, option) => {
                    setSelectedWarehouseDetail(option)
                }}
                getOptionLabel={(option) => getCamelCaseText(option?.name)}
                size="small"
                options={warehouseList?.data || {}}
                sx={{
                    ...inputClasses.textField,
                    ...selectStylesOverride,
                    minWidth: "280px",
                    m: 0,
                    ml: 2,
                    ".MuiInputBase-input": {
                        fontSize: "14px",
                        color: "#455A64",
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        sx={{ color: "#455A64", ...textClasses.normalText }}
                        {...params}
                        label="Select Warehouse*"
                    />
                )}
                disabled={isEditable}

            />
        </Grid>
    </Grid>
    { loader == "getDynamicTemplate" ?
          <Grid
            data-testid="loader"
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid> :
        <MultipleFormSave
            formAttributes={templateDetail?.attributes}
            type={"floor"}
            items={items}
            setItems={setItems}
            errors={errors} 
            isEditable={isEditable}
            />
}
        <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}  >
            <Button
                variant='outlined'
                size='small'
                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                onClick={()=>handleClearAll(templateDetail?.attributes || [],{})}
            >
                Clear All
            </Button>
            <Button
                variant='contained'
                size='small'
                disabled={dataReceieved}
                sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                onClick={handleSubmitData}
            >
                {isEditable ? "Update":"Submit"}
            </Button>
        </Grid>
    </>)
}

export default AddFloor;