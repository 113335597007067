import { Card, Grid, Typography, Paper, Badge, Popover, MenuItem ,Tooltip, Divider,Checkbox ,Select,FormControl,InputLabel    } from '@mui/material'
import React, { useState,useEffect } from 'react'
import theme from '../../../../static/styles/theme'
import moment from "moment"
import black_man from '../../../../static/images/mapImage/Person_Black.png';
import grey_man from '../../../../static/images/mapImage/Person_Grey.png';
import green_man from '../../../../static/images/mapImage/Person_Green.png';
import red_man from '../../../../static/images/mapImage/Person_RED.png';

import black_helmet_man from '../../../../static/images/mapImage/Helmet_Black.png';
import grey_helmet_man from '../../../../static/images/mapImage/helmet_Grey.png';
import green_helmet_man from '../../../../static/images/mapImage/helmet_Green.png';
import red_helmet_man from '../../../../static/images/mapImage/helmet.png';

import black_crate from '../../../../static/images/mapImage/Crate_Black.png';
import gray_crate from '../../../../static/images/mapImage/crate_Gray.png';
import green_crate from '../../../../static/images/mapImage/crate_Green.png';
import red_crate from '../../../../static/images/mapImage/crate.png';
import stall from '../../../../static/images/mapImage/IWS_Stall_2023_v1.jpg';

import { getWarehouseAnalytics, getZoneUser, getZone } from "../../../../redux/actions/MainDashboard";
import { useDispatch, useSelector } from 'react-redux';

// import * as THREE from 'three';
// import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export default function Map() {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopoverId, setOpenedPopoverId] = useState(null);
    const [infoChecked, setInfoChecked] = useState(false);
    const { allZoneUser } = useSelector(state => state.mainDashboard);
    const [infoType, setInfoType] = useState("");
    const [zoneWiseData, setZoneWiseData] = useState({zoneFirst:{},zoneTwo:{},zoneThird:{}});
    const [allZoneUserData, setAllZoneUserData] = useState([])
    const [condition, setCondition] = useState(true)
    
    const popOverOpen = (event,_id) => {
        if(_id){
            setAnchorEl(event.currentTarget);
            setOpenedPopoverId(_id)
        }
    };
    
    const infoTypeCheckedHandler=(e)=>{
        let checked=e.target.checked
        if(!checked){
            setInfoType("")
        }
        // //("checked>>>>>",checked);
        localStorage.removeItem("infoType")
        setInfoChecked(checked)
    }

    const infoTypeHandler =(val)=>{
        setInfoType(val)
        localStorage.setItem("infoType",JSON.stringify({infoType:val,checked:infoChecked}))
    }

    const popOverClose = () => {
        setOpenedPopoverId(null)
        setAnchorEl(null);
    };

    const itemrenderFunction=(user)=>{
        let val=""
        if(user){
            if(infoType=="name"){
               val=user.name || "" 
            }
            // else if(infoType=="employeeId"){
            //     val=user.employeeId || "" 
                
            // }
            // else if(infoType=="assignedAsset"){
            //     val=user.assignedAsset || "" 
                
            // }
            else if(infoType=="assetStatus"){
                val=user.assetStatus || "" 
                
            }
            else if(infoType=="currentZone"){
                val=user.currentZone && user.currentZone.zoneName || "" 

            }
        }

        return val
    }

    const renderIcon=(user,user_index,h)=>{
        // //("infoType && infoChecked",{infoType ,infoChecked});
        return <span key={user_index}>
            <Tooltip title={user.name || ""} placement="top">
                {infoType && infoChecked
                        ?
                        <Badge
                            key={user_index}
                            aria-describedby={id}
                            variant="contained"
                            sx={{
                                mr: 1.2, ml: 1.2, pt: 1, cursor: "pointer",
                                "& .MuiBadge-badge": {
                                    color: theme.themeOrange,
                                    backgroundColor: 'grey',
                                },

                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: "right",
                            }}

                            onClick={(event) => popOverOpen(event, user._id || "")}
                            badgeContent={<Typography variant='' sx={{ color: "white", wordBreak: "keep-all" }}>
                                {itemrenderFunction(user)}
                            </Typography>
                            }
                        >
                            <img 
                             src={imageRender(user)} 
                            style={{ width: user.userTypeIcon && user.userTypeIcon == "crate" && "1.5vw" || "1.5vw"}} 
                            />
                        </Badge>
                        :<img 
                        onClick={(event) => popOverOpen(event, user._id || "")}
                            src={imageRender(user)} 
                            style={{ width: user.userTypeIcon && user.userTypeIcon == "crate" && "1.5vw" || "1.5vw" ,cursor: "pointer",margin:"0 0 0 8px"}} 
                        
                        />
                }
            </Tooltip>
            <Popover
                id={id}
                key={user_index}
                open={openedPopoverId === user._id}
                anchorEl={anchorEl}
                onClose={popOverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    boxShadow: 2,
                }}

            >
                <Grid container direction={"row"}>
                    <Grid item xs={12} sx={{ p: 1, }}>
                        <Typography sx={{ color: theme.themeOrange }}>{user.name || ""}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        <Typography>Asset Id : {user.assignedAsset || ""}</Typography>
                        <Typography>Authorised Zone :  {user.assignedAuthZone && user.assignedAuthZone.map((el) => el.zoneName).join(", ")}</Typography>
                        <Typography>Packet Time :  {user.assetPacketTime && moment(user.assetPacketTime).format('YYYY-MM-DD h:mm A') || ""}</Typography>
                    </Grid>
                </Grid>
            </Popover>
        </span>
    }

    // item.assetStatus === 'offline' ? 'black'
    // : item.assignedAuthZone.length == 0 && item.assignedUnauthZone.length == 0 ? 'gray'
    //     : item.assignedAuthZone.filter((ele) => ele._id == item.currentZone._id).length > 0 ? 'green' : 'red'

    const imageRender=({userTypeIcon,assetStatus,assignedAuthZone=[],assignedUnauthZone=[],currentZone})=>{

        //  //("fdvgrbgh>>>>>",{userTypeIcon,assetStatus,assignedAuthZone,assignedUnauthZone,currentZone});
        let key = assetStatus === 'offline' ? 'black'
            : assignedAuthZone.length == 0 && assignedUnauthZone.length == 0 ? 'grey'
                : assignedAuthZone.find((ele) => ele._id == currentZone._id)? 'green' : 'Red'
   
        // let img="../../../../static/images/mapImage/"

        if(!userTypeIcon || userTypeIcon=="user"){
             if(key=="grey"){
                return grey_man
            }
            else if(key=="green"){
                return green_man
                
            }
            else if(key=="Red"){
                return red_man
                
            }
            else{
                //black
                return black_man

            }
            
        }
        else if(userTypeIcon=="helmet"){
            if(key=="grey"){
                return grey_helmet_man
            }
            else if(key=="green"){
                return green_helmet_man
                
            }
            else if(key=="Red"){
                return red_helmet_man 
            }
            else{
                //black
                return black_helmet_man
            }
           
        }
        else if(userTypeIcon=="crate"){
            if(key=="grey"){
                return gray_crate
            }
            else if(key=="green"){
                return green_crate
                
            }
            else if(key=="Red"){
                return red_crate
                
            }
            else{
                //black
                return black_crate
            }
        }
        
    }
    
    useEffect(()=>
    {
        if(condition)
        {
            dispatch(getZoneUser({}))
            setCondition(false)
            
            // let width = window.innerWidth;
            // let height = window.innerHeight;
            // var scene = new THREE.Scene();
            // scene = new THREE.Scene();
            // scene.background = new THREE.Color( 0xffffff );
            // scene.fog = new THREE.Fog( 0xffffff, 1000, 4000 );

            // var camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
            // camera.position.z = 5;
            // scene.add(camera);

            // var renderer = new THREE.WebGLRenderer();
            // renderer.setSize( width, height );
            // document.body.appendChild( renderer.domElement );

            // scene.add( new THREE.AmbientLight( 0x666666 ) );


            // const loader1 = new THREE.CubeTextureLoader();
            // const texture = loader1.load([ stall ]);
            // scene.background = texture;


            // var cameraControls = new OrbitControls( camera, renderer.domElement );
			// 	// cameraControls.target.set( 0, 50, 0 );
			// 	// cameraControls.update();

            // function animate() {
            //     requestAnimationFrame( animate );
            //     renderer.render( scene, camera );
            // }
            // animate();
        }
        else{
            setAllZoneUserData(allZoneUser || [])
        }  
    },
    [allZoneUser])

    useEffect(()=>
    {
        if(!condition){
            setInterval(() => {
                (window?.location?.pathname || "")?.split('/').includes('map') && dispatch(getZoneUser({}))
            }, 1000 * 10);
        }
    },[])
   
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(()=>
    {
        if(allZoneUserData && allZoneUserData.length>0)
        {
            let zoneFirst={},zoneTwo={},zoneThird={}
            for(let ele of allZoneUserData){
                // //("ele>>>",ele);
                if(ele.zoneName.includes("Zone 1")){
                    zoneFirst={...ele}
                }
                else if(ele.zoneName.includes("Zone 2")){
                    zoneTwo={...ele}
                    
                }
                else if(ele.zoneName.includes("Zone 3")){
                    zoneThird={...ele}
                }
            }
            setZoneWiseData({zoneFirst,zoneTwo,zoneThird}); 
            let infoData=JSON.parse(localStorage.getItem("infoType"))
            // //("infoData>>>>>>>>>>>>>>>>>>>>>>>>>>>>",infoData);
            // window.alert(infoData)
            if(infoData && infoData.checked){
                setInfoChecked(true)
                if(infoData.infoType) setInfoType(infoData.infoType)
            }
            else{
                setInfoChecked(false)
                setInfoType("")
            }
        }
        else
        {
            setZoneWiseData({zoneFirst:{},zoneTwo:{},zoneThird:{}}); 
        }
    },
    [allZoneUserData])

    // return (
    //     <>
   
    //     </>
    // )
    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems="center">
                <Paper sx={{width:"35vw"}}>
                    <Grid container justifyContent={"center"} alignItems="center" xs={12} item>
                        <Grid item xs={3}
                            sx={{ p: 1 }}
                            container
                            justifyContent={"center"}
                            alignItems="center"
                            direction={"row"}
                        >
                            <Grid item container justifyContent={"center"} alignItems="center" xs={12}>
                                <Grid item >
                                    <Paper sx={{ backgroundColor: "black", width: "1.5vw", height: "3vh",mr:1 }}></Paper>
                                </Grid>
                                <Grid item >Offline</Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={3}
                            sx={{ p: 1 }}
                            container
                            justifyContent={"center"}
                            alignItems="center"
                            direction={"row"}
                        >
                            <Grid item container justifyContent={"center"} alignItems="center" xs={12}>
                                <Grid item >
                                    <Paper sx={{ backgroundColor: "grey", width: "1.5vw", height: "3vh" ,mr:1}}></Paper>
                                </Grid>
                                <Grid item >General</Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={3}
                            sx={{ p: 1 }}
                            justifyContent={"center"}
                            container
                            alignItems="center"
                            direction={"row"}
                        >
                            <Grid container justifyContent={"center"} alignItems="center" xs={12} item>
                                <Grid item >
                                    <Paper sx={{ backgroundColor: "green", width: "1.5vw", height: "3vh" ,mr:1}}></Paper>
                                </Grid>
                                <Grid item >In Zone</Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={3}
                        container
                            sx={{ p: 1 }}
                            justifyContent={"center"}
                            alignItems="center"
                            direction={"row"}
                        >
                            <Grid container justifyContent={"center"} alignItems="center" xs={12} item>
                                <Grid item >
                                    <Paper sx={{ backgroundColor: "red", width: "1.5vw", height: "3vh" ,mr:1}}></Paper>
                                </Grid>
                                <Grid item >Out Zone</Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Paper>
                <Paper sx={{width:"25vw"}}>
                    <Grid container justifyContent={"center"} alignItems="center" xs={12} item>
                        <Grid item xs={5}>
                            <Grid container justifyContent={"center"} alignItems="center" xs={12} item>
                                <Grid item >
                                    <Checkbox  
                                        checked={infoChecked || false}
                                        label="View Label" 
                                        onChange={infoTypeCheckedHandler}
                                    />
                                </Grid>
                                <Grid item >View label</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={7}
                            sx={{ p: 1 }}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <FormControl fullWidth sx={{minWidth: 120 }} size="small" disabled={infoChecked ?false : true}>
                                <InputLabel id="demo-simple-select-label">Label Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={infoType || ""}
                                    onChange={(e)=>infoTypeHandler(e.target.value)}
                                    // sx={{ width: 150 }}
                                >
                                    <MenuItem value={"name"}>User Name</MenuItem>
                                    {/* <MenuItem value={"employeeId"}>Employee Id</MenuItem> */}
                                    {/* <MenuItem value={"assignedAsset"}>Assigned Asset</MenuItem> */}
                                    <MenuItem value={"assetStatus"}>Asset Status</MenuItem>
                                    <MenuItem value={"currentZone"}>Current Zone</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid container direction={"row"}  justifyContent={"center"} alignItems="start">
                <Paper className='mapBackground' sx={{ mt: 3, borderRadius: "5px",width:"80vw",height: "108vh"}}>
                    <Grid item container direction={"row"} xs={12} sx={{ height: "61vh" }}>
                        <Grid item
                            sx={{ p: 2.5, height: "inherit",width:"28vw",  }}
                        >
                            <Typography variant='h6' sx={{ color: theme.themeOrange }}>Empty Space</Typography>
                        </Grid>
                        <Grid item //zone 1
                            sx={{ p: 2.5,pt:4,   height: "inherit",width:"19vw", }}
                            // backgroundColor:"red" 
                        >
                            {zoneWiseData && zoneWiseData["zoneFirst"] && zoneWiseData["zoneFirst"].users && zoneWiseData["zoneFirst"].users.length>0
                                ?zoneWiseData["zoneFirst"].users.slice(0,15).map((ele,index)=>{
                                    return renderIcon(ele,index)
                                })
                                :""  
                            }
                        </Grid>
                        <Grid item 
                            sx={{ p: 3,pt:4,  height: "inherit", width:"33vw", }}
                        >
                            {zoneWiseData && zoneWiseData["zoneThird"] && zoneWiseData["zoneThird"].users && zoneWiseData["zoneThird"].users.length>0
                                ?zoneWiseData["zoneThird"].users.slice(0,32).map((ele,index)=>{
                                    return renderIcon(ele,index)
                                })
                                :""  
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}  container direction={"row"} sx={{height: "47vh"}}>
                        <Grid item 
                            sx={{ p: 2.5,  height: "inherit",width:"15vw",}}
                        >
                        
                            {zoneWiseData && zoneWiseData["zoneThird"] && zoneWiseData["zoneThird"].users && zoneWiseData["zoneThird"].users.length>32
                                ?zoneWiseData["zoneThird"].users.slice(32,41).map((ele,index)=>{
                                    return renderIcon(ele,32+index,)
                                })
                                :""  
                            }
                        </Grid>
                        <Grid item 
                            sx={{ p: 2.5,pl:1,   height: "inherit",width:"14vw",}}
                        >
                            {zoneWiseData && zoneWiseData["zoneThird"] && zoneWiseData["zoneThird"].users && zoneWiseData["zoneThird"].users.length>41
                                ?zoneWiseData["zoneThird"].users.slice(41,47).map((ele,index)=>{
                                    return renderIcon(ele,41+index)
                                })
                                :""  
                            }
                        </Grid>
                        <Grid item 
                            sx={{ p: 1.5,pl:1,pt:2.5,   height: "inherit",width:"19vw",}}
                        >
                            {zoneWiseData && zoneWiseData["zoneThird"] && zoneWiseData["zoneThird"].users && zoneWiseData["zoneThird"].users.length>47
                                ?zoneWiseData["zoneThird"].users.slice(47,58).map((ele,index)=>{
                                    return renderIcon(ele,47+index)
                                })
                                :""  
                            }
                        </Grid>
                        <Grid item 
                            sx={{ p: 2,   height: "inherit",width:"16vw",}}
                        >
                            {zoneWiseData && zoneWiseData["zoneThird"] && zoneWiseData["zoneThird"].users && zoneWiseData["zoneThird"].users.length>58
                                ?zoneWiseData["zoneThird"].users.slice(58,67).map((ele,index)=>{
                                    return renderIcon(ele,58+index)
                                })
                                :""  
                            }
                        </Grid>
                        <Grid item
                            sx={{ p: 2,pl:1,  height: "inherit",width:"15vw",}}
                        >
                            {zoneWiseData && zoneWiseData["zoneTwo"] && zoneWiseData["zoneTwo"].users && zoneWiseData["zoneTwo"].users.length>0
                                ?zoneWiseData["zoneTwo"].users.slice(0,9).map((ele,index)=>{
                                    return renderIcon(ele,index)
                                })
                                :""  
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    )
}
