import { useState, useEffect } from "react"
import {
    Button,
    Typography,
    Stack,
    ButtonGroup,
    Box

} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import theme, {
    buttonClasses,
    textClasses,
} from "../../../static/styles/theme";
import { styled } from '@mui/material/styles';
import { showToast } from "../../../services/functions";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

let initialRange = {
    start: "",
    end: ""
}
const GenericRangeFilter = ({ minLabel, maxLabel, initialState = {}, handleRangeChange }) => {
    const [range, setRange] = useState(initialRange)
    const handleCountStartChange = (type, e) => {
        if (type)
            if (type == "decrement") {
                if (range.start > 0)
                    setRange(prevRange => ({
                        ...prevRange,
                        start: (prevRange.start || 0) - 1
                    }));

            }
            else if (type == "increment") {
                if (range.start < range.end) {
                    setRange(prevRange => ({
                        ...prevRange,
                        start: (prevRange.start || 0) + 1
                    }));

                }
            }
            else { // user typing the input is handling here
                let value = parseInt(e.target.value, 10) || "";
                if (!isNaN(value) && value > -1) {
                    if(range?.end>0){
                        value=Math.min(range?.end,value)
                    }
                    // && value <= range.end
                    setRange(prevRange => ({
                        ...prevRange,
                        start: value
                    }));
                }
            }
    }

    const handleCountEndChange = (type, e) => {
        if (type)
            if (type == "decrement") {
                if (range.end > range.start) {
                    setRange(prevRange => ({
                        ...prevRange,
                        end: (prevRange.end || 0) - 1
                    }))
                }
            }
            else if (type == "increment") {

                setRange(prevRange => ({
                    ...prevRange,
                    end: (prevRange.end || 0) + 1
                }))

            }
            else { // user typing the input is handling here
                let value = parseInt(e.target.value, 10) || "";
                if (!isNaN(value)) {
                    let value = parseInt(e.target.value, 10) || "";
                    // if (range?.start > 0) {
                    //     value = Math.max(range?.end, value)
                    // }
                    setRange(prevRange => ({
                        ...prevRange,
                        end: value
                    }));
                }
            }
    }

    const handleClearRange = () => {
        setRange(initialRange)
        handleRangeChange(initialRange)
    }
    const handleApply = () => {
        if (range.start>0 && range.end>0 && range.start > range.end) {
            showToast(`${minLabel} must be less than ${maxLabel}!`, true)
            return;
        }
        handleRangeChange(range)
    }
    useEffect(() => {
        setRange(initialState)

    }, [initialState])
    return (
        <>
            <Stack spacing={1} sx={{ borderRadius: 50 }}>
                <Item sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 2 }}>  <Typography>{minLabel}: </Typography>

                    <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }}>
                        <Button key="one" size='small' sx={{
                            ...buttonClasses.smallOrange, fontSize: "12px",
                            backgroundColor: range.start <= 0 ? 'grey' : theme.themeOrange
                        }}
                            onClick={() => handleCountStartChange("decrement")} disabled={range.start <= 0}
                        >
                            <Remove fontSize='inherit' sx={{ color: 'white' }} />
                        </Button>

                        <input
                            type="number"
                            style={{ border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center" }}
                            value={range.start}
                            onChange={(e) => handleCountStartChange("input", e)}
                            onWheel={(event) => event.target.blur()}

                            onKeyDown={(e) => {
                                if (['e', 'E', '+', '-'].includes(e.key)) {
                                    e.preventDefault();
                                }
                                else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <Button key="three" size='small' sx={{ ...buttonClasses.smallOrange, fontSize: "12px", backgroundColor: range.start == range.end ? 'grey' : theme.themeOrange }}
                            onClick={() => handleCountStartChange("increment")} disabled={range.start == range.end}
                        >
                            <Add fontSize='inherit' sx={{ color: 'white' }} />
                        </Button>
                    </ButtonGroup> </Item>
                <Item sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 2 }}>
                    <Typography>{maxLabel}: </Typography>


                    <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }}>
                        <Button key="one" size='small' sx={{ ...buttonClasses.smallOrange, fontSize: "12px", backgroundColor: range.end <= 0 || range.start == range.end ? 'grey' : theme.themeOrange }}
                            onClick={() => handleCountEndChange("decrement")} disabled={range.end <= 0 || range.start == range.end}
                        >
                            <Remove fontSize='inherit' sx={{ color: 'white' }} />
                        </Button>
                        <input
                            type="number"
                            style={{ border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center" }}
                            value={range.end}
                            onChange={(e) => handleCountEndChange("input", e)}
                            onWheel={(event) => event.target.blur()}

                            onKeyDown={(e) => {
                                if (['e', 'E', '+', '-'].includes(e.key)) {
                                    e.preventDefault();
                                }
                                else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <Button key="three" size='small' sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
                            onClick={() => handleCountEndChange("increment")}
                        >
                            <Add fontSize='inherit' sx={{ color: 'white' }} />
                        </Button>
                    </ButtonGroup></Item>
                <Item sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 2 }}>
                    <Typography
                        sx={{
                            ...textClasses.boldText,
                            color: theme.themeOrange,
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={handleClearRange}
                    >
                        Clear
                    </Typography>


                    <Typography
                        sx={{
                            ...textClasses.boldText,
                            color: theme.themeOrange,
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={handleApply}
                    >
                        Apply
                    </Typography>
                </Item>
            </Stack>
        </>
    )
}
export default GenericRangeFilter;