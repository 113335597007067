import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    FormControl, Autocomplete, Grid, Menu, InputAdornment, OutlinedInput, Button, TextField,
    Select, MenuItem, FormLabel, InputLabel, Typography, Tab, Tabs, Box, Checkbox
} from '@mui/material'
import { Add, Search, KeyboardArrowDown } from '@mui/icons-material';
import GenericTable from '../../Components/CustomTable/GenericTable';
import AddUsersModel from './Components/AddUsersModel';
import moment from 'moment';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../static/styles/theme';
import { currentStatus, designation, } from './ConstantData/ConstantData';
import { getAllUsers, getActiveBlockCounts } from '../../../redux/actions/ManageTeam';
import { getCompany } from '../../../redux/actions/SuperAdmin';
import EditBlockModel from './Components/EditBlockModel';
import { getAllUserRoles } from '../../../redux/actions/UserRole';
import { selectStylesOverride } from "../../../utils/util";
import { DatePicker } from '@mui/x-date-pickers';
import { useDebounce } from "../../../utils/hooks";
import { dateTimeFormater, capitalizeSentence } from "../../../services/functions";
import GenericLoader from '../../Components/Generic/GenericLoader';
import ViewUserProductvity from './Components/ViewUserProductvity';




function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const initFilters = {
    initail: true,
    page: 1,
    limit: 10,
    approved: "all",
    searchValue: "",
    userRole: "",
    createdOn: null
};

function UserManagement(props) {
    const dispatch = useDispatch();
    const { AllUserData, counts = 0 } = useSelector((state) => state.users);
    const { userPermissions } = useSelector((state) => state.userPermission);
    const { warehouseDetail } = useSelector(state => state.superadmin);
    const { AllUserRoleData } = useSelector((state) => state.UserRoles);

    const [condition, setCondition] = useState(true);
    const [tableIndex, setTableIndex] = useState("");
    const [selectedValue, setSelectedValue] = useState({});

    // const [selectedCount, setSelectedCount] = useState(0);
    const [tableData, setTableData] = useState([])
    const [pageCount, setPageCount] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [addNew, setAddNew] = useState(false);
    const [warehouseData, setWarehouseData] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [actionStatus, setActionStatus] = useState("addnew");
    const [editBlock, setEditBlock] = useState(false);
    const [checkedObj, setCheckedObj] = useState([])
    const [loader, setLoader] = useState(true)
    const [actionIndex, setActionIndex] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const [filter, setFilter] = useState({ ...initFilters });

    const debouncedSearchValue = useDebounce(filter.searchValue, 500);

    const handleClose = (action) => {
        setAnchorEl(null);
        handleAction(action, tableIndex)
        setActionIndex(null)
    };

    const handleOptions = (action) => {
        handleActionForEdit(action, tableIndex)
    }
    function checkIsActive() {
        return !!selectedRows?.length && selectedRows.every(st => st?.status)
    }
    function checkIfBothStatus() {
        return !!selectedRows?.length && selectedRows.some(st => st?.status) && selectedRows.some(st => !st?.status)
    }

    const isActive = checkIsActive();
    const isBlock = !isActive;
    const isBoth = checkIfBothStatus()

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
        setTableIndex(i)
    }
    const handleCloseAction = () => {
        // setActionConfirmModal(null);
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    const tableHeader = () => {
        let head = ["S.No.", "Name", "Email", "Phone","View Productivity", "Warehouse", "User Role", "Status", "Last Active", "Added On",]
        
        let allSelctedIDs=selectedRows?.map(item=>item.id)
        if (AllUserData?.data?.length > 0 && (
            userPermissions?.permissions?.find(dl => dl.permissionId == "viewTeamMembers") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "editTeamMember") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "deleteTeamMember")
        )) {
            head.unshift(< Typography sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange }}>
                <Checkbox
                    checked={tableData?.length >0 && tableData.every(item =>allSelctedIDs.includes(item?._id))  ? true : false}
                    onChange={(event) => allHandleChecked(event)}
                    size='small' />
            </Typography >)
            head.push("Action")
        }
        return head

    }

    const tableRows = tableData.length !== 0 ? tableData.map((row, index) => {
        let items = [];
        items.push(

            < Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                {((filter.page - 1) * (filter.limit)) + index + 1}
            </Typography >
        );
        items.push(
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", color: theme.themeOrange, cursor: 'pointer' }} onClick={() => { handleAction("view", index) }}>
                {capitalizeSentence(row?.name || "-")}
            </Typography >
        );
        items.push(
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", }}>
                {row.email ? row.email : "-"}
            </Typography >
        );
        items.push(
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", }}>
                {row.mobile ? row.mobile : "-"}
            </Typography >
        );
        items.push(
            <Typography
                variant="caption"
                key={((filter.page - 1) * (filter.limit)) + index + 1}
                onClick={() => { handleAction("viewProductivity", index) }}
                sx={{
                    cursor: "pointer",
                    color:theme.themeOrange,
                    textDecoration: "underline",

                }}
            >
                View
            </Typography>
        );
        items.push(
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", }}>
                {warehouseDetail?.data && warehouseDetail?.data.find(obj => obj?._id === row.warehouseId)?.name || '-'}
            </Typography >
        );
        items.push(
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", }}>
                {capitalizeSentence(AllUserRoleData && AllUserRoleData.find(role => role._id === row.roleId)?.roleName || "-")}
            </Typography >
        );
        items.push(row.approved === true ?
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", color: "green" }}>
                Active
            </Typography >
            :
            < Typography sx={{ ...textClasses.t13n, textAlign: "center", color: theme.themeRed }}>
                Blocked
            </Typography >
        );

        row?.activeTime ?
            items.push(
                < Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                    {row?.activeTime && dateTimeFormater(row?.activeTime) || '-'}
                </Typography >
            ) :
            items.push(
                < Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                    {'-'}
                </Typography >
            )

        items.push(
            <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                {row?.createdAt && dateTimeFormater(row?.createdAt) || '-'}
            </Typography>
        );


        if (AllUserData?.data?.length > 0 && (
            userPermissions?.permissions?.find(dl => dl.permissionId == "viewTeamMembers") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "editTeamMember") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") ||
            userPermissions?.permissions?.find(dl => dl.permissionId == "deleteTeamMember")
        )) {
            items.unshift(< Typography sx={{ color: theme.themeOrange }}>
                <Checkbox
                    data-testid='checkbox'
                    checked={selectedRows?.findIndex(dl=>dl?.id==row._id)>=0 ? true : false}
                    onChange={(event) => handleSelect(event, index)}
                    size='small' />
            </Typography >)
            items.push(
                selectedRows?.length >= 2 ?
                    <Typography data-testid="dropdown" sx={{ ...textClasses.t13n, color: 'grey', cursor: "pointer" }} >Action <KeyboardArrowDown /></Typography>
                    :
                    <Typography data-testid="dropdown" sx={{ ...textClasses.t13n, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, index)}>Action <KeyboardArrowDown /></Typography>

            );
        }
        return items;
    }) : []





    const handleOpenAddNew = () => {
        setActionStatus("addnew")
        setAddNew(true)
    }

    const allHandleChecked = (e) => {
        let data = [...tableData];
        let tempRows = [...selectedRows]
        if (e.target.checked === true) {
            let allIDs = tempRows?.map(data => data?.id)
            let sT = tableData?.filter(dl => dl?._id && !allIDs.includes(dl?._id)).map(data => ({ name: data?.name || '', id: data?._id, status: data?.approved }))
            setSelectedRows([...sT, ...tempRows]);
        }
        else {
            let allIDs = data?.map(data => data?._id)
            setSelectedRows(tempRows?.filter(dl => dl?.id && !allIDs.includes(dl?.id)))
        }
    }

    const handleSelect = (e, index) => {
        let data = [...tableData];
        let id = data[index]._id
        let name = data[index].name
        let approved = data[index].approved

        if (selectedRows?.findIndex(dl=>dl?.id==id)<0) {
            setTableIndex(index)
            handleAddSelection(id, name, approved);
        }
        else {
            handleRemoveSelection(id);
        }
    }

    const handleAddSelection = (id, name, approved) => {
        let obj = {
            name: name,
            id: id,
            status: approved
        }
        setSelectedRows([...selectedRows, obj]);
    }

    const handleRemoveSelection = (id) => {
        let tempId = id || ''
        let oldTemplates = [...selectedRows];
        const i = oldTemplates.map(o => o.id).indexOf(tempId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedRows(oldTemplates);
        }

    }


    const handleAction = (action, index) => {

        setSelectedRows([])
        let data = [...tableData];
        let obj = { ...data[index] }
        if (action === 'edit' || action === 'view') {
            setSelectedValue(obj);
            setAddNew(true)
            setActionStatus(action);
        }
        else if (action === 'Activate' || action === 'Delete' || action === 'Block') {
            let id = obj._id
            let name = obj.name
            let approved = obj.approved
            setEditBlock(true)
            setActionStatus(action);
            let detail = {
                name: name,
                id: id,
                status: approved
            }
            setSelectedRows([detail]);
        }
        else if (action == "viewProductivity") {
            setActionStatus(action);
            let detail = {
                name: obj?.name || "",
                id: obj?._id || "",
                designation: obj?.designation || "",
                status: obj?.approved || "",
            }
            setSelectedValue(detail);
        }
    }

    const handleActionForEdit = (action, index) => {
        let data = [...tableData];
        let obj = { ...data[index] }

        if (action === 'edit' || action === 'view') {
            setSelectedValue(obj);
            setAddNew(true)
            setActionStatus(action);

        }
        else if (action === 'Activate' || action === 'Delete' || action === 'Block') {
            setEditBlock(true)
            setActionStatus(action);
        }
        else { }
    }

    const handleViewEdit = (action) => {
        if (selectedValue?._id) {
            setActionStatus(action);
            setAddNew(true)
        }
    }

    const get_all_user = (filer_d, fetchCount = false) => {
        setLoader(true)
        if (filer_d.makeInitial) {
            setFilter({ ...filer_d, makeInitial: false })
        }
        dispatch(getAllUsers(filer_d, () => {
            setLoader(false)
            if (fetchCount) dispatch(getActiveBlockCounts({}))
        }));
    }

    const handleTabChange = (event, value) => {
        let approved = "all"
        if (value == 1) approved = true
        else if (value == 2) approved = false
        get_all_user({ ...initFilters, initail: true, makeInitial: true, approved });
        setTabIndex(value)
    }

    const clearSearch = (fetchCount = false) => {
        // get_all_user({ ...initFilters, initail: false, makeInitial: true });
        // setTabIndex(0);
        get_all_user({ ...initFilters, initail: true, makeInitial: true, approved: tabIndex == 0 ? "all" : filter.approved }, fetchCount);
        // setTabIndex( filter.approved == "all" ? 0 : filter.approved? 1: 2);
    }


    const viewClearButton = () => {
        for (let key in filter) {
            if (["searchValue", "userRole", "createdOn"].includes(key) && filter[key]) {
                return true
            }
            if (tabIndex == 0 && key == "approved" && filter[key] != "all") {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        ////("filter.limit", filter);
        if (!filter.initail) {
            get_all_user(filter)
        }
    }, [filter.page, filter.limit, filter.approved, filter.userRole, filter.createdOn]);


    useEffect(() => {
        if (!filter.initail) {
            get_all_user(filter)
        }
    }, [debouncedSearchValue]);


    useEffect(() => {
        let userData = null
        if (localStorage.getItem('token')) {
            userData = localStorage.getItem('user') || {};
        }
        if (condition) {
            get_all_user(initFilters, true)
            dispatch(getAllUserRoles({}))
            dispatch(getCompany({ "companyName": userData?.company || "", "type": "warehouse" }));
        }
        else {
            if (warehouseDetail) {
                setWarehouseData(warehouseDetail?.data || [])
            }
            if (AllUserData) {
                const updatedData = AllUserData?.data?.map(item => ({ ...item, isChecked: false })) || []
                setTableData(updatedData || [])
                setPageCount(AllUserData?.count || 0)
            }
        }

        if (condition) {
            setCondition(false)
        }
    }, [AllUserData, warehouseDetail]);

    return (
        <>
            <Grid container data-testid="userManagement-dashboard">
                <Grid item sm={4}>
                    <Typography variant='h6' sx={{ fontSize: "24px", fontWeight: "600" }}>Manage Team</Typography>
                </Grid>
                <Grid container item sm={8} justifyContent="end" alignItems="end">
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "addNewMember") && <Button
                        data-testid="addNew"
                        role="signInUserButtons"
                        variant="contained"
                        size="small"
                        startIcon={<Add />}
                        sx={{ ...buttonClasses.lynkitOrangeEmpty, textTransform: 'none' }}
                        onClick={() => handleOpenAddNew()}
                    >
                        Add New
                    </Button>}


                    {/* <Button
                        variant="outlined"
                        className='UM_export-btn'
                        sx={{ color: "black", border: "1px solid black" }}
                    ><DownloadIcon /> Export</Button> */}
                </Grid>
            </Grid>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    onChange={handleTabChange}
                    style={{ height: "20px" }}
                    sx={{ '& .MuiTabs-indicator': { backgroundColor: theme.themeOrange }, "& .MuiTab-root.Mui-selected": { color: theme.themeOrange } }}
                >
                    <Tab data-testid="allMember" label={`All Member (${counts?.data?.totalUser || 0}) `} {...a11yProps(0)} sx={{ fontSize: "14px", textTransform: 'none' }} onClick={() => {
                        // setFilter({...filter,approved:"all"})

                    }} />
                    <Tab data-testid="active" label={`Active (${counts?.data?.enabledUser || 0})`} {...a11yProps(1)} sx={{ fontSize: "14px", textTransform: 'none' }} onClick={() => {
                        // setFilter({...filter,approved:"true"})

                    }} />
                    <Tab data-testid="block" label={`Blocked (${counts?.data?.disabledUser || 0})`} {...a11yProps(2)} sx={{ fontSize: "14px", textTransform: 'none' }} onClick={() => {
                        // setFilter({...filter,approved:"false"})
                    }} />

                </Tabs>

            </Box>

            {counts?.data?.totalUser ?
                <Grid container spacing={2} sx={{ marginTop: "2px" }}>
                    <Grid item sx={2} sm={3}>
                        <FormControl fullWidth variant="outlined" size="small" sx={{ ...selectStylesOverride }}>
                            <OutlinedInput
                                onChange={(e) =>
                                    setFilter((f) => ({
                                        ...f,
                                        searchValue: e.target.value,
                                        initail: false,
                                        page: 1
                                    }))
                                }
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                value={filter.searchValue || ""}
                                placeholder="Search by name, email, phone"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>


                    </Grid>
                    <Grid item sx={4} sm={2}>
                        <FormControl variant='outlined' size='small' fullWidth >
                            <Autocomplete
                                size='small'
                                data-testid="userRole"
                                options={AllUserRoleData && AllUserRoleData.map(dl => ({ label: capitalizeSentence(dl.roleName), value: dl._id }))}
                                sx={{ height: "1.1rem", }}
                                onChange={(event, option) => {
                                    setFilter((f) => ({
                                        ...f,
                                        userRole: option?.value || "",
                                        initail: false,
                                        page: 1
                                    }))
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        sx={{

                                            fontSize: "12px",
                                            ...selectStylesOverride,
                                        }}
                                        {...params}

                                        label={<Typography sx={{ fontSize: "12px" }}>User Role</Typography>}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                        <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            {option?.label || ""}
                                        </Typography>
                                    </MenuItem>
                                )}
                                value={{ value: filter.userRole, label: capitalizeSentence(AllUserRoleData.find(dl => dl._id == filter.userRole)?.roleName || "") }}
                            />
                        </FormControl>
                    </Grid>
                    {tabIndex == 0 && <Grid item sx={4} sm={2}>
                        <FormControl variant='outlined' size='small' fullWidth>


                            <Autocomplete
                                size='small'
                                data-testid="userRole"
                                options={currentStatus}
                                sx={{ height: "1.1rem", }}
                                onChange={(event, option) => {
                                    setFilter((f) => ({
                                        ...f,
                                        approved: option ? option?.value || false : "all",
                                        initail: false,
                                        page: 1
                                    }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{

                                            fontSize: "12px",
                                            ...selectStylesOverride,
                                        }}
                                        {...params}

                                        label={<Typography sx={{ fontSize: "12px" }}>User Status</Typography>}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                        <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            {option?.label || ""}
                                        </Typography>
                                    </MenuItem>
                                )}
                                value={{ value: filter.approved, label: filter.approved != "all" && currentStatus.find(dl => dl.value == filter.approved)?.label || "" }}
                            />
                        </FormControl>
                    </Grid>}
                    <Grid item sx={4} sm={2}>
                        <DatePicker
                            label="Created At"
                            value={filter.createdOn}
                            className='customDatePicker'
                            onChange={(newValue) => {
                                setFilter((f) => ({
                                    ...f,
                                    createdOn: newValue,
                                    initail: false,
                                }));
                            }}
                            format="DD/MM/YYYY"
                            sx={{ ...inputClasses.shadowField, fontSize: '8px' }}
                            slotProps={{ textField: { size: "small" } }}
                        />

                    </Grid>
                    {viewClearButton() &&
                        <Grid container item alignItems={"center"} sx={{ flexBasis: "10%" }}>
                            <Button type="button">
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange, textDecoration: "underline", cursor: "pointer",
                                        textTransform: "none"
                                    }}
                                    onClick={() => clearSearch()}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Button>

                        </Grid>
                    }
                </Grid>
                : ""
            }


            {selectedRows.length > 0 ?

                <Box mt={0.7} data-testid='edit'  >
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "editTeamMember") && selectedRows.length <= 2 ?
                        <Button
                            size='small'
                            data-testid='edit'
                            variant="contained"
                            className='UM_uploadfile-btn'
                            disabled={selectedRows.length >= 2 ? true : false}
                            onClick={() => { handleViewEdit('edit'); handleOptions('edit') }}
                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                        > Edit </Button>
                        : ''}
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "viewTeamMembers") && selectedRows.length <= 2 ?
                        <Button
                            size='small'
                            data-testid="view"
                            variant="contained"
                            className='UM_uploadfile-btn'
                            disabled={selectedRows.length >= 2 ? true : false}
                            onClick={() => { handleViewEdit('view'); handleOptions('view') }}
                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                        > View </Button>
                        : ''}

                    {userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") && isActive && !isBoth ?
                        <Button
                            size='small'
                            data-testid="block"
                            variant="contained"
                            className='UM_uploadfile-btn'
                            onClick={() => {
                                setActionStatus("Block")
                                setEditBlock(true)
                            }}
                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", mr: 1, textTransform: "none" }}
                        >
                            Block
                        </Button>
                        : ''
                    }

                    {userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") && isBlock && !isBoth ?
                        <Button
                            size='small'
                            data-testid="block"
                            variant="contained"
                            className='UM_uploadfile-btn'
                            onClick={() => {
                                setActionStatus("Activate")
                                setEditBlock(true)
                            }}
                            sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", textTransform: "none" }}
                        > Activate
                        </Button>
                        : ''
                    }

                    {userPermissions?.permissions?.find(dl => dl.permissionId == "deleteTeamMember") && <Button
                        size='small'
                        data-testid="delete"
                        variant="contained"
                        className='UM_delete-btn'
                        onClick={() => {
                            setActionStatus("Delete")
                            setEditBlock(true)
                        }}
                        sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", textTransform: 'none' }}
                    > Delete </Button>}
                </Box> : ""}


            <Box mt={selectedRows?.length ? 1 : 2}>
                {loader
                    ? <Grid data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                        <Grid item><GenericLoader /></Grid>
                    </Grid>
                    :
                    <GenericTable
                        header={tableHeader()}
                        rows={tableRows}
                        pageCount={pageCount}
                        pageNumber={filter.page - 1}
                        handleChangePage={(event, pagevalue) => {
                            setFilter((f) => ({
                                ...f,
                                page: pagevalue + 1,
                                initail: false
                            }));
                        }}
                        handleChangeRowsPerPage={(event) => {
                            setFilter((f) => ({
                                ...f,
                                page: 1,
                                limit: +event.target.value,
                                initail: false
                            }));
                        }}
                        rowsPerPage={filter.limit}
                    />
                }
            </Box>
            {
                addNew && <AddUsersModel
                    open={addNew}
                    hide={(type="") => {
                        setAddNew(false)
                        setEditBlock(false)
                        setSelectedValue({})
                        setActionStatus("")
                        if(type=="success"){
                            setSelectedRows([])
                        }
                    }}
                    callFetchUsers={clearSearch}
                    selectedValue={selectedValue}
                    warehouseData={warehouseData}
                    action={actionStatus}
                    AllUserRoleData={AllUserRoleData}
                />
            }
            {
                editBlock && <EditBlockModel
                    open={editBlock}
                    hide={(type="") => {
                        setAddNew(false)
                        setEditBlock(false)
                        setSelectedValue({})
                        setActionStatus("")
                        if(type=="success"){
                            setSelectedRows([])
                        }
                    }}
                    tableData={tableData}
                    action={actionStatus}
                    callFetchUsers={clearSearch}
                    setEditBlock={setEditBlock}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                />
            }
            {
                actionStatus=="viewProductivity" && selectedValue &&<ViewUserProductvity
                    open={actionStatus=="viewProductivity"}
                    hide={(type="") => {
                        setAddNew(false)
                        setActionStatus("")
                        setSelectedValue({})
                    }}
                    selectedValue={selectedValue}
                />
            }
            {
                actionIndex != null &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                >
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "editTeamMember") &&
                        <MenuItem key={1} onClick={() => handleClose("edit")}>Edit</MenuItem>}

                    {userPermissions?.permissions?.find(dl => dl.permissionId == "viewTeamMembers") &&
                        <MenuItem key={2} onClick={() => handleClose("view")}>View</MenuItem>}
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") &&
                        [false].indexOf(tableData[actionIndex].approved) >= 0 &&
                        <MenuItem key={3} onClick={() => handleClose("Activate")}>Activate</MenuItem>
                    }
                    {
                        userPermissions?.permissions?.find(dl => dl.permissionId == "disableEnableTeamMember") &&
                        [true].indexOf(tableData[actionIndex].approved) >= 0 &&
                        <MenuItem key={5} onClick={() => handleClose("Block")}>Block</MenuItem>
                    }
                    {userPermissions?.permissions?.find(dl => dl.permissionId == "deleteTeamMember") &&
                        <MenuItem key={4} onClick={() => handleClose("Delete")}>Delete</MenuItem>}
                </Menu>

            }
        </>
    )
}

export default UserManagement
