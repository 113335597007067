
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CustomModel from "../../CustomModels/CustomModel1";
import theme, { textClasses, buttonClasses } from "../../../../static/styles/theme";
import {
  Autocomplete,
  TextField,
  Button,
  Select,
  MenuItem,
  Grid,
  Typography, Tooltip, Box
} from "@mui/material";
import { selectStylesOverride } from "../../../../utils/util";
import { showToast } from "../../../../services/functions";
import selectDownload from "../../../../../src/static/images/data_entry/select_download.png";
import GenericFileUpload from "../../Generic/GenericFileUpload";
import { UpdateBulkUploadData, getMappedMasterId, flushMappedIds } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import GenericLoader from "../../Generic/GenericLoader";
import { Grid as VirtualGrid, List, AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

let initial_state = { step: 0, error: false, invalidIds: [] }

const BulkUploadMapping = ({ open, hide, selectedData, sampleFile, distinctMasters, handleRefreshTable }) => {

  const fileInputRef = useRef()
  const dispatch = useDispatch();
  const mappedMasterIds = useSelector(state => state.areaMappingData.mappedMasterIds)

  const [payload, setPayload] = useState({ floorId: "", file: "", mappingArea: "", category: "" })
  const [isOutput, setIsOutput] = useState({ ...initial_state })
  const [isLoader, setIsLoader] = useState(false)
  const numberOfRows = Math.ceil((isOutput?.invalidIds?.length || 0) / 3);

  useEffect(() => {
    if (selectedData) {
      setPayload(prev => ({
        ...prev,
        floorId: selectedData?._id?.floorId,
        mappingArea: selectedData?._id?.subProductType,
        category: categoryOptions[0]?.value,
      }))
    }

  }, [selectedData])

  const categoryOptions = useMemo(() => {
    const options = [];
    for (let master in distinctMasters) {
      if (distinctMasters[master] === true) {
        let label, value;
        if (master === "item-master") {
          label = "Item Master";
          value = "itemMaster";
        }
        else if (master === "custom-master") {
          label = "Custom Master";
          value = "customMaster";
        }
        options.push({ label: label, value: value })
      }
    }
    return options

  }, [distinctMasters])

  const handleFileChange = useCallback((file) => {
    setPayload(prev => ({
      ...prev,
      file: file.target.files,
    }))
  }, [setPayload])

  const handleUpload = () => {
    if (payload) {
      if (!payload?.file) {
        showToast("Excel File required!", true);
        return;
      }
      const formData = new FormData();
      formData.append('file', payload?.file[0]);
      formData.append('floorId', payload?.floorId);
      formData.append('mappingArea', payload?.mappingArea);
      formData.append('category', payload?.category)
      setIsLoader(true)
      dispatch(UpdateBulkUploadData(formData, ({ success = false, message, invalidIds = [] }) => {
        showToast(message, !success);
        setIsLoader(false)
        if (success) {
          hide()
          handleRefreshTable()
          setIsOutput({ ...initial_state })
        }
        else if(!success && invalidIds?.length>0){
          setIsOutput({ step: 1, error: true, invalidIds })
        }
      }))
    }
  }

  const isDisableUpload = useMemo(() => {
    return !payload?.category || !payload?.floorId || !payload?.mappingArea || !payload?.file
  })

  const handleDeleteFile = useCallback(() => {
    setPayload(prev => ({
      ...prev,
      file: "",
    }))
  }, [setPayload])

  const handleChangeCategory = useCallback((option) => {
    setPayload(prev => ({
      ...prev,
      category: option?.value,
    }))
  }, [setPayload])

  const handleChangeFamily = useCallback((option) => {
    setPayload(prev => ({
      ...prev,
      mappingArea: option?.value,
    }))
  }, [setPayload])

  const handleDownloadExistingData = useCallback((data) => {
    let excelData = [
      ["Entity Id"]
    ]
    let arr = data;
    for (let el of arr) {
      excelData.push([el?.entityID || ""])
    }

    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'data.xlsx');

    dispatch(flushMappedIds(() => {
    }))
  }, [mappedMasterIds, dispatch])

  const handleDownload = useCallback(() => {
    const masterIdsPayload = {
      floorId: payload?.floorId, mappingArea: payload?.mappingArea, category: payload?.category
    }
    dispatch(getMappedMasterId(masterIdsPayload, (obj) => {
      if (obj?.data?.length) {
        handleDownloadExistingData(obj?.data)
      } else {
        let excelData = [
          ["Entity Id"]
        ]
        // let arr=["ITEM003"];
        // for(let el of arr){
        // excelData.push([el || ""])
        // }

        const ws = XLSX.utils.aoa_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'data.xlsx');
      }
    }))
  }, [payload, dispatch, mappedMasterIds, handleDownloadExistingData])

  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 3 + columnIndex; // Assuming 3 columns
    const item = isOutput?.invalidIds[index];

    return (
      <div style={{ ...style, padding: "8px" }}>
        {item && (
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.themeLightOrange,
              border: `1px solid ${theme.themeOrange}`,
              borderRadius: "12px",
              pl: 1,
              pr: 1,
              minHeight: "32px",
            }}
          >
            <Grid item sm={10} xs={8}>
              {item && item.length > 12 ? (
                <Tooltip arrow placement="top" title={item}>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item && item.length > 20
                      ? item.substring(0, 20) + "..."
                      : item}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {item}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      minWidth={500}
      maxWidth={850}
      pt={1}
      pb={3}
      pl={3}
      pr={3}
      viewCloseIcon={["Edit", "Add"].includes(open)}
      childrenMaxHeight={"inherit"}
      Title={["Edit", "Add"].includes(open) && `${open} Job Type`}
    >
      <Grid container padding={1} data-testid="uploadModal" className="ppmodal">
        <Grid container item width="100%">
          <Grid
            container
            item
            sm={5}
            xs={5}
            sx={{
              padding: "16px 0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            position="relative"
            alignContent="center"
            alignItems="center"
            minWidth="100%"
          >
            <Typography
              sx={{ ...textClasses.t20n, mt: "5px", fontWeight: "800" }}
            >
              Upload Excel File To Map
            </Typography>
            <Grid item sm={4}>
              <Autocomplete
                size="small"
                defaultValue={categoryOptions[0]}
                options={categoryOptions}

                onChange={(e, option) => {
                  handleChangeCategory(option)
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: "12px",
                      ...selectStylesOverride,
                    }}
                    {...params}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        Category
                      </Typography>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color: option.isCreateOption ? "#ff7200" : "#727376",
                      fontSize: "14px",
                    }}
                  >
                    {option.label}
                  </li>
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Autocomplete
                size="small"
                options={selectedData && [{ label: selectedData?._id?.subProductType, value: selectedData?._id?.subProductType }]}
                onChange={(e, option) => {
                  handleChangeFamily(option)
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: "12px",
                      ...selectStylesOverride,
                    }}
                    {...params}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        Family
                      </Typography>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color: option.isCreateOption ? "#ff7200" : "#727376",
                      fontSize: "14px",
                    }}
                  >
                    {option.label}
                  </li>
                )}
                value={payload?.mappingArea}
              />
            </Grid>
          </Grid>
          {isLoader
            ?
            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15, pt: 10, pb: 10 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid>
            :
            isOutput?.step == 1 && isOutput?.error && isOutput?.invalidIds.length > 0
              ?
              <Grid
                container
                item
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                border={1}
                sx={{
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "space-between",
                  borderRadius: "10px",
                  padding: "1rem",
                  borderColor: "grey.500",
                }}
              >
                <Box style={{
                  width: "100%",
                  height: "300px",
                }}>
                  {
                    <AutoSizer>
                      {({ width, height }) => {
                        return (
                          <FixedSizeGrid
                            columnCount={3} // Number of columns
                            columnWidth={243} // Width of each column
                            height={numberOfRows < 3 ? numberOfRows * 50 : 300} // Total height of the grid
                            rowCount={numberOfRows}
                            rowHeight={50} // Height of each row
                            width={width} // Total width of the grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {cellRenderer}
                          </FixedSizeGrid>
                        );
                      }}
                    </AutoSizer>
                  }
                </Box>
              </Grid>
              : <Grid
                container
                item
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                border={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  borderRadius: "10px",
                  padding: "1rem",
                  borderColor: "grey.500",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction={"column"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img src={selectDownload} style={{ maxWidth: "200px" }} />
                  <Typography sx={{ ...textClasses.cardTitle, mt: 2 }}>
                    Download the sample file first...
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "center",
                      maxWidth: "600px",
                      mt: 2,
                    }}
                  >
                    Hey, please download the sample file as per the selected category and add your item master data in same format, to map data with selected area.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", p: 1 }}>
                <GenericFileUpload
                    sampleFile={sampleFile}
                    onChange={handleFileChange}
                    onUpload={handleUpload}
                    payload={payload}
                    previewAvailable={false}
                    onDelete={handleDeleteFile}
                    handleDownload={handleDownload}
                    isDisableUpload={isDisableUpload}
                />                </Grid>

              </Grid>
          }

          {/* button */}
          <Grid
            container
            item
            direction={"row"}
            sm={12}
            xs={12}
            sx={{
              ml: 1, pt: 2, display: "flex", flexDirection: "row",
              justifyContent: isOutput?.step == 1 ? "space-between" : "end",
              alignItems: "center"
            }}
          >
            {isOutput?.step == 1 && <Alert severity="warning">
              Please remove above listed SKU's from your excel and upload again.
            </Alert>}
            <Box>
              <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "130px",
                }}
                onClick={hide}
              >
                Cancel
              </Button>
              {isOutput?.step == 1 && <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitOrangeFill,
                  minHeight: "36px",
                  minWidth: "130px",
                  ml: 2,
                }}
                onClick={() => setIsOutput({ ...isOutput, step: 0 })}
              >
                Go Back
              </Button>
              }

              {isOutput?.step == 0 && isOutput?.error && isOutput?.invalidIds.length > 0 && <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitOrangeFill,
                  minHeight: "36px",
                  minWidth: "130px",
                  ml: 2,
                }}
                onClick={() => setIsOutput({ ...isOutput, step: 1 })}
              >
                View Error
              </Button>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default BulkUploadMapping;
