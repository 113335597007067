const intialState = {
    RFIDTableData: {}
};


const RFIDReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_RFID_DATA":
            return { ...state, RFIDTableData: payload }
        default:
            return state;
    }
}
export default RFIDReducer;