import React, { useState, useEffect } from "react"
import CustomModel from "../../Components/CustomModels/CustomModel1";
import { styled, useTheme } from '@mui/material/styles'
import {
    ListItemText, Grid, IconButton, Typography, Autocomplete, FormControl, TextField, Box, Button, FormGroup,
    FormControlLabel, Checkbox, OutlinedInput, MenuItem
} from "@mui/material";
import { getAllTemplates } from "../../../redux/actions";
import { getCamelCaseText, showToast } from "../../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import theme, {
    textClasses,
    cardClasses,
    inputClasses,
    buttonClasses,
} from "../../../static/styles/theme";
import { selectStylesOverride } from "../../../utils/util";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Chip from '@mui/material/Chip';
import AddIcon from "../../../static/images/Icon/AddIcon.png";
import CustomChip from "../../Components/CustomChip";
import SelectImage from "../../../static/images/salesOrder/Share link-amico.svg"
import { shareSalesOrderProcessingForm, getEsopFormTableDataAPI } from "../../../redux/actions";
import GenericTable from "../../Components/CustomTable/GenericTable";
import GenericLoader from "../../Components/Generic/GenericLoader";
import { deepCopy_Object } from "../../../services/functions";
import CutomerSupplierAdd from "../TMS/Addressbook/cutomerSupplierAdd";

let initialCheckboxItems = [
    { label: "Name", checked: false, value: "name" },
    { label: "Email", checked: false, value: "email" },
    { label: "Mobile Number", checked: false, value: "mobileNumber" },
    { label: "Company Name", checked: false, value: "companyName" }
];

let initialSKUDropdownItems = [
    { label: "SKU ID", checked: true, value: "SKU_ID", disabled: true },
    { label: "SKU Name", checked: true, value: "SKU_Detail", disabled: true },
    { label: "Current Stock", checked: false, value: "currentStock" },
    { label: "Total In (Till Date)", checked: false, value: "totalInStock" },
    { label: "Total Out (Till Date)", checked: false, value: "totalOutStock" },

];

export { initialSKUDropdownItems, initialCheckboxItems }

const ESOFormPage = (props) => {
    const { open, hide, action, item } = props
    const { userPermissions } = useSelector((state) => state.userPermission);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const allTemplates = useSelector((state) => state.templates.allTemplates);
    const template = useSelector(state => state.inward.selectedTemplate);
    const [addEmail, setAddEmail] = useState("")
    const [visibleEmail, setVisibleEmail] = useState([]);
    const [checkboxItems, setCheckboxItems] = useState([...initialCheckboxItems]);
    const [dropdownSKUItems, setDropdownSKUItems] = useState(initialSKUDropdownItems.slice(0, 2))
    const [passcode, setPasscode] = useState("");
    const [step, setStep] = useState(1);
    const [esopFormTableData, setEsopFormTableData] = useState({})
    const formTableDataRowCount = esopFormTableData?.count
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);
    const [passLink, setPassLink] = useState({ passcode: '', linkURL: '', type: '' })
    const [tableLoader, setTableLoader] = useState(false);
    const [connectedCust_Sup_Detail, setConnectedCust_Sup_Detail] = useState({});
    const dispatch = useDispatch();
    const handleEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (regex.test(addEmail)) {
            setVisibleEmail([addEmail, ...visibleEmail]);
            setAddEmail("");
        } else {
            showToast("Please enter valid email", true);
            return;
        }
    };
    const eSOPFormTableLoad = (page, limit) => {
        dispatch(
            getEsopFormTableDataAPI({ page: page, limit: limit }, (data) => {
                setEsopFormTableData(data)
                setTableLoader(false)

            })
        )
    }
    const searchMaster = async (value, option = {}) => {
        let tempMaster = option?.value && option || {};
        setConnectedCust_Sup_Detail(tempMaster);
    };


    useEffect(() => {
        dispatch(
            getAllTemplates({
                type: "wms",
                templateType: "salesOrderTemplate",
                status: "published",
            },
                (data) => {
                    if (data && data.length == 1) {
                        setSelectedTemplate(data[0]);
                    }
                }
            )
        );
        eSOPFormTableLoad(page, limit);
    }, []);


    const handleCheckboxChange = (index) => {
        let prevItems = deepCopy_Object(checkboxItems)
        prevItems[index]["checked"] = !(prevItems[index]?.checked || false)
        setCheckboxItems(prevItems);
    };

    const checkedItems = checkboxItems?.filter(item => item.checked == true).map(item => item.value);
    const checkedItems2 = dropdownSKUItems?.map(item => item.value);

    const renderTableCells = (columnData) => {
        if (Array.isArray(columnData)) {
            return (
                <Typography sx={{ ...textClasses.normalText }}>
                    {Array.isArray(columnData) ? columnData.join(", ") : columnData}
                </Typography>
            )
        } else {
            return (
                <Typography sx={{ ...textClasses.normalText }}>
                    {columnData}
                </Typography>
            );
        }
    };

    const handleCopy = (field) => {

        if (field == "passcode" && passLink?.passcode) {
            navigator.clipboard.writeText(passLink?.passcode)
                .then(() => {
                    showToast("Passcode Copied")
                })
                .catch((error) => {
                    console.error('Error copying text: ', error);
                });
        }
        if (field == "linkURL" && passLink?.linkURL) {
            navigator.clipboard.writeText(passLink?.linkURL)
                .then(() => {
                    showToast("Link URL Copied")
                })
                .catch((error) => {
                    console.error('Error copying text: ', error);
                });
        }
    };

    const tableHeader = ["S.No", "Selected Template","Customer Detail", "Required Information", "Stock Visibility", "Email(s)", "Link & Passcode"]

    const tableRows = esopFormTableData?.data?.map((row, i) => {
        let item = [];
        const templateName = allTemplates?.find(dl => dl?._id === row?.templateId)?.name;
        const requiredInformation = row?.informationToBeCaptured?.map((ele) => {
            return checkboxItems?.find(dl => dl?.value === ele)?.label
        })
        const requiredInformationSKU = row?.informationShownForSKU?.map((ele) => {
            return initialSKUDropdownItems?.find(dl => dl?.value === ele)?.label
        })

        const serialNumber = ((page - 1) * limit) + (i + 1);
        item.push(<Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
            {serialNumber}
        </Typography>)
        item.push(renderTableCells(templateName));
        item.push(renderTableCells(row?.['customerSupplierDetails']?.name || "-"))
        item.push(renderTableCells(requiredInformation));
        item.push(renderTableCells(requiredInformationSKU));
        item.push(renderTableCells(row?.sendToEmail));
        item.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: 'pointer', mb: 2 }} onClick={() => {
                setPassLink({
                    passcode: row?.passcode, linkURL: row?.generatedLink, type: 'viewed'
                })
                setStep(3)
            }}>
                View
            </Typography>
        )
        return item;

    })
    const handleShareData = () => {
        const payload = {
            templateId: selectedTemplate?._id,
            informationToBeCaptured: checkedItems || [],
            informationShownForSKU: checkedItems2 || [],
            sendToEmail: visibleEmail || [],
            passcode: passcode,
            supplierCustomerId: connectedCust_Sup_Detail?.value
        }
        dispatch(
            shareSalesOrderProcessingForm(payload, (data = {}) => {
                showToast(data.message, !data.success);
                if (data) {
                    setPassLink({
                        passcode: data?.data?.passcode, linkURL: data?.data?.linkURL, type: 'created'
                    })
                }
                if (data.success) {
                    eSOPFormTableLoad(page, limit);
                    setStep(3)
                }
            })
        );
    }
    const handleShareButtonDisability = () => {
        if (selectedTemplate == null) {
            return true
        }
        if (passcode === "" || passcode.length < 6 || !/^\d+$/.test(passcode)) {
            return true;
        }
    }
    return (<>
        <CustomModel
            show={open}
            autoClose={false}
            onClose={() => hide()}
            childrenMaxHeight="70vh"
            viewCloseIcon={true}
        
            Title={
                step == 1 ?
                    <>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }} data-testid="header">Share Sales Order Processing Form</Typography>
                        <Typography variant="caption" sx={{ position: "relative", top: "-15px" }}>An unique form link will be auto generated once you fill the below Details
                            and click on share button</Typography>
            
                    </> : step == 2 ?
                        <Grid container>
                            <IconButton sx={{ color: theme.themeBlack, textAlign: 'center' }} data-testid="back-btn">
                                <ArrowBackOutlinedIcon onClick={() => {
                                    setStep(1)

                                }} /> </IconButton>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 0.6, color: theme.themeOrange }} data-testid="header">View History</Typography>

                        </Grid> : step == 3 && passLink?.type == "viewed" ?
                            <>
                                <IconButton sx={{ color: theme.themeBlack }}>
                                    <ArrowBackOutlinedIcon onClick={() => {
                                        setStep(2)

                                    }} /> </IconButton>


                            </> :
                            <></>
            }
            actionButton={
                step == 1 ?
                    
                    <Grid item sm={12} sx={{display:'flex',justifyContent:'space-between'}}>
                        <Box sx={{display:'flex',gap:2}}>
                        <Button
                            variant="outlined"
                            size="medium"
                            data-testid="cancel-btn"
                            sx={{
                                ...buttonClasses.lynkitBlackEmpty,
                                width: 200,
                                ml: 2
                            }}
                            onClick={() => hide()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            data-testid="share-btn"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                width: 200
                            }}
                            disabled={handleShareButtonDisability()}
                            onClick={handleShareData}
                        >
                            Generate Link & Share
                        </Button>
                        </Box>
                   <Box sx={{display:'flex',justifyContent:'center',alignItems:'center' }}>
                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: 'pointer'}}
                     onClick={() => {
                        eSOPFormTableLoad(page, limit)
                        setStep(2)
                    }}>
                        View History
                    </Typography>
              
                    </Box>
                </Grid>
                    : step === 2 ?
                        <></>
                        :
                        step === 3 ?
                            <>
                                <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>

                                    <TextField
                                        data-testid="link-textfield"
                                        sx={{
                                            ...selectStylesOverride,
                                            ...textClasses.t12n,
                                            width: "60%",
                                        }}
                                        size="small"
                                        label="Generated Link"
                                        variant="outlined"
                                        value={passLink?.linkURL}
                                    />

                                    <Button
                                        autoFocus
                                        variant="contained"
                                        spacing={1}
                                        // disabled={!addEmail}
                                        onClick={() => { handleCopy("linkURL") }}
                                        sx={{
                                            ...buttonClasses.lynkitBlackFill,
                                            // position:"relative",
                                            // left:"-10px"
                                            margin: "-1px 0px 0px -15px",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0"
                                        }}
                                    >
                                        Copy
                                    </Button>

                                </Grid>
                                <Grid item sm={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>

                                    <TextField
                                        data-testid="passcode-textfield"

                                        sx={{
                                            ...selectStylesOverride,
                                            ...textClasses.t12n,
                                            width: "60%",
                                        }}
                                        size="small"
                                        label="Generated Passcode"
                                        variant="outlined"
                                        value={passLink?.passcode}
                                    />

                                    <Button
                                        data-testid="copy-btn"
                                        autoFocus
                                        variant="contained"
                                        spacing={1}
                                        // disabled={!addEmail}
                                        onClick={() => { handleCopy("passcode") }}
                                        sx={{
                                            ...buttonClasses.lynkitBlackFill,
                                            // position:"relative",
                                            // left:"-10px"
                                            margin: "-1px 0px 0px -15px",
                                            borderTopLeftRadius: "0",
                                            borderBottomLeftRadius: "0"
                                        }}
                                    >
                                        Copy
                                    </Button>

                                </Grid></> : <></>
            }

        >

            {step == 1 ? <Grid container spacing={1}>

                <Grid item sm={12}>
                    <Typography variant="h6" sx={{ ...textClasses.t15nBold }} >Select Template </Typography>
                </Grid>
                <Grid item sm={6} >
                    <Autocomplete
                        data-testid="select-template-dropdown"
                        value={selectedTemplate}
                        onChange={(e, option) => {
                            setSelectedTemplate(option);
                        }}
                        getOptionLabel={(option) => getCamelCaseText(option.name)}
                        size="small"
                        options={allTemplates}
                        sx={{
                            ...inputClasses.textField,
                            ...selectStylesOverride,
                            minWidth: "220px",
                            m: 0,
                            ml: 2,
                            ".MuiInputBase-input": {
                                fontSize: "14px",
                                color: "#455A64",
                            },
                        }}
                        renderInput={(params) => (
                            <TextField
                                sx={{ color: "#455A64", ...textClasses.normalText }}
                                {...params}
                                label="Select Template*"
                            />
                        )}
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormControl sx={{ ...selectStylesOverride, }}>

                        <OutlinedInput
                            data-testid="input-passcode"
                            value={passcode}
                            onChange={(e) => setPasscode(e.target.value)}
                            placeholder='Create Passcode* (min 6 digit integer)'
                            // label="Password asd"
                            sx={{
                                ...inputClasses.textField,
                                ...selectStylesOverride,
                                minWidth: "336px",
                                height: "38px",
                                m: 0,


                                "& input::placeholder": { ...textClasses.normalText },
                                // "& .MuiSelect-iconOutlined": {display: filters.entityId != "" ? 'none': ''}
                            }}
                        />
                    </FormControl>

                </Grid>
                <Grid item sm={12}>
                    <Typography variant="h6" sx={{ ...textClasses.t15nBold }} >Invite People</Typography>
                </Grid>

                <Grid item sm={12}>
                    <Box sx={{
                        mt: 1, m: 0,
                        ml: 2,
                    }}>
                        <TextField
                            data-testid="input-email"
                            sx={{
                                ...selectStylesOverride,
                                ...textClasses.t12n,
                                width: "284px",
                            }}
                            size="small"
                            label="Enter email(s) to sent form link"
                            variant="outlined"
                            onChange={(e) => {
                                setAddEmail(e.target.value);
                            }}
                            value={addEmail}
                        />

                        <Button
                            autoFocus
                            variant="contained"
                            spacing={1}
                            data-testid="add-btn"
                            // disabled={!addEmail}
                            onClick={() => handleEmail()}
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                // position:"relative",
                                // left:"-10px"
                                margin: "-1px 0px 0px -15px",
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0"
                            }}
                        >
                            <img
                                style={{
                                    marginRight: "10px",
                                }}
                                src={AddIcon}
                            />
                            Add
                        </Button>
                        {visibleEmail?.length > 0 ?
                            <Grid container xs={12} sx={{ mt: 1 }} spacing={1}>
                                <CustomChip
                                    data={visibleEmail || []}
                                    makeDisabled={false}
                                    handleDelete={(eleIndex, elem) => setVisibleEmail(visibleEmail.filter((dl, index) => index !== eleIndex))}
                                    readLabelKey={"$self"}
                                />
                            </Grid>
                            : ""}
                    </Box>
                </Grid>
                {userPermissions?.permissions?.find(dl => dl.permissionId == "get_business_partners") && template?.connectedWithAddressbook?.at(0) &&
                    <Grid item sm={12}>
                        {/* <Grid item sm={12}> */}
                        <Typography variant="h6" sx={{ ...textClasses.t15nBold, mb: 1 }} >Select Customer</Typography>
                        {/* </Grid> */}
                        <CutomerSupplierAdd
                            connectedCust_Sup_Detail={connectedCust_Sup_Detail}
                            searchMaster={searchMaster}
                            type={"Customer"}
                            optional={false}
                            isBodyVisible={false}
                            connectedWithAddressBook={template?.connectedWithAddressbook}
                            heading={"Customer Detail"}
                        />
                    </Grid>
                }
                <Grid item sm={12}>
                    <Typography variant="h6" sx={{ ...textClasses.t15nBold }} >Select Stock Information to be show</Typography>
                </Grid>
                <Grid item sm={12}>
                    <FormControl
                        size="small"
                        data-testid={open + "-actionRender-jobtype"}
                    >
                        <Autocomplete
                            data-testid="select-sku-info-dropdown"
                            multiple
                            labelId="action"
                            size='small'
                            sx={{
                                fontSize: "12px",
                                ...selectStylesOverride,
                                "& input::placeholder": {
                                    fontSize: "12px",
                                },
                                ml: 2,
                                width:'357px'
                            }}
                            limitTags={2}
                            options={initialSKUDropdownItems}
                            onChange={(event, option) => {
                                // console.log("options>>>>", option);
                                let tempOption = option
                                if (tempOption?.length == 0) {
                                    tempOption = initialSKUDropdownItems.slice(0, 2)
                                }
                                if (option)
                                    // dispatch({ type: "change_action", value: option })
                                    setDropdownSKUItems(tempOption)
                            }}
                            disableCloseOnSelect
                            renderOption={(props, option, { selected }) => {
                                const { label, value } = option

                                return (
                                    <MenuItem value={value} key={value} sx={{ ml: 2 }} {...props} disabled={option?.disabled}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                )

                            }
                            }
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.label}
                                        {...getTagProps({ index })}
                                        disabled={["SKU_ID", "SKU_Detail"].includes(option?.value)}
                                    />
                                ))
                            }
                            renderInput={(params) => {
                                return (

                                    <TextField
                                        {...params}
                                        disabled
                                        variant="outlined"
                                        icon
                                        label={<Typography sx={{ fontSize: "14px" }}>Select from dropdown</Typography>}
                                    />
                                )
                            }
                            }
                            value={dropdownSKUItems}
                        />
                    </FormControl>

                </Grid>

                <Grid item sm={12}>
                    <Typography variant="h6" sx={{ ...textClasses.t15nBold }} >Select Required informations to be captured (Optional)</Typography>
                </Grid>
                <Grid item sm={12} columnSpacing={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', ml: 2 }} data-testid="checkbox-info">
                        {checkboxItems?.map((item, index) => (
                            <FormControlLabel
                                key={item.index}
                                control={<Checkbox checked={item.checked} onChange={() => handleCheckboxChange(index)} />}
                                label={item.label}
                                sx={{ marginRight: '35px' }}
                            />
                        ))}
                    </FormGroup>

                </Grid>
          

            </Grid> : step == 2 ?
                tableLoader ? (
                    <GenericLoader />
                )
                    :

                    <Grid item sm={12}>
                        <GenericTable
                            data-testid="eSOPForm-data-table"
                            header={tableHeader}
                            rows={tableRows}
                            pageCount={formTableDataRowCount}
                            pageNumber={page - 1}
                            handleChangePage={(event, pagevalue) => {
                                setTableLoader(true)
                                eSOPFormTableLoad(pagevalue + 1, limit)
                                setPage(pagevalue + 1);
                            }}
                            // handleChangeRowsPerPage={(event) => {
                            //     setLimit(+event.target.value);
                            // }}
                            rowsPerPage={limit}
                            isHideRowPerPage={true}
                        />


                    </Grid> : step == 3 ?
                    <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                        <img src={SelectImage} width="280px" />
                        <Typography sx={{ ...textClasses.t16n, textAlign: "center", mt: 1, fontWeight: '600' }}>
                            {passLink?.type == "created" ? "Sales Order Processing e-form link has been created successfully. Use the below Passcode and Link." : "Please find attached Passcode and Link"}
                        </Typography>

                    </Grid> : <></>
            }



        </CustomModel>
    </>)
}
export default ESOFormPage;