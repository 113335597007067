import React, { useEffect, useState } from "react";
import { useDebounce } from "../../../../utils/hooks";
import {
    Grid,
    Button,
    MenuItem,
    Typography,
    Checkbox,
    Paper,
    Menu,
} from "@mui/material";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../services/history";
import theme, {
    buttonClasses,
    inputClasses,
    textClasses,
    cardClasses,
} from "../../../../static/styles/theme"
import {
    Add,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { getSuperAdminCompanyData, getCompanyStatusCount, getCompanyDetails, getCompanyTypeWiseCount, getTemplateHeader } from "../../../../redux/actions";
import { capitalizeSentence, showToast, renderHeadersFilter, dataReadFunction } from "../../../../services/functions";
import GenericCustomiseHeader from "../../../Components/Generic/GenericCustomiseHeader";
import GenericTable from "../../../Components/CustomTable/GenericTable";
import GenericLoader from "../../../Components/Generic/GenericLoader";
import GenericPageConfig from "../../../Components/Generic/GenericPageConfig";
import ViewRowDetailCard from "../ViewRowDetailCard";
import EnableDisableDialog from "../EnableDisableDialog";
import ViewWarehouseFloorModal from "../Company/ViewWarehouseFloorModal";


let initial_state = {
    "search": {},
    "other": {},
}
const warehouseDetailTemplateHeader2 = [
    {
        "label": "Company Name",
        "name": "companyName",
        "type": "input",
        "format": "",
    },
    {
        "label": "All Floors",
        "name": "totalFloorCount",
        "type": "showDetail",
        "handleType": "viewFloors",
        "format": "",
    },
    {
        "label": "Created At",
        "name": "createdAt",
        "type": "date",
        "format": "",
    },
    {
        "label": "Updated At",
        "name": "updatedAt",
        "type": "date",
        "format": "",
    },

]
const floorDetailTemplateHeader2 = [
    {
        "label": "Company Name",
        "name": "companyName",
        "type": "input",
        "format": "",
    },
    {
        "label": "Warehouse Name",
        "name": "warehouseName",
        "type": "input",
        "format": "",
    },
    {
        "label": "Created At",
        "name": "createdAt",
        "type": "date",
        "format": "",
    },
    {
        "label": "Updated At",
        "name": "updatedAt",
        "type": "date",
        "format": "",
    },

]

const WarehouseFloorPage = () => {
    const dispatch = useDispatch();
    const { pageTitle = "Warehouse And Floor Detail" } = useSelector(state => state?.DynamicPageConfig?.WarehouseFloorPage) || {};
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};
    const [statusFilter, setStatusFilter] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [filter, setFilter] = useState(initial_state);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState("");
    const [wfData, setWfData] = useState({
        data: [],
        count: {}
    })
    const [warehouseFloorStatusCount, setWarehouseFloorStatusCount] = useState({})
    const [warehouseFloorTypeWiseCount, setWarehouseFloorTypeWiseCount] = useState({})
    const [cardType, setCardType] = useState("")
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [warehouseDetailTemplateHeader, setWarehouseDetailTemplateHeader] = useState([])
    const [floorDetailTemplateHeader, setFloorDetailTemplateHeader] = useState([])
    const [viewWarehouseFloorDetail, setViewWarehouseFloorDetail] = useState(false)
    const [warehouseDetailData, setWarehouseDetailData] = useState({})
    const [floorDetailData, setFloorDetailData] = useState({})
    // const [selectedCompanyDetail, setSelectedCompanyDetail] = useState({})
    // const [selectedWarehouseDetail, setSelectedWarehouseDetail] = useState({})
    // const [companiesList, setCompaniesList] = useState([])
    // const [warehousesList,setWarehousesList] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const [initialRender, setInitialRender] = useState(true)
    const [viewModal, setViewModal] = useState({ type: "", action: "", origin: "" })
    const debouncedFilterSearch = useDebounce(filter?.search, 500);
    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
    };
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        if (setSelectedEntries) {
            setSelectedEntries([]);
        }
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);
    };

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    };

    const handleClearFilter = () => {
        setFilter(initial_state)
        setPage(0);
        setLimit(10);
        if (tabIndex == 0) {
            setStatusFilter(null);
        }
    };
    const handleRowAndViewClick = (selectedRowData) => {
        setViewWarehouseFloorDetail(true)
        let payload = {
            _id: selectedRowData?._id,
        }
        setLoader("cardRowDetail")
        dispatch(getCompanyDetails(payload, ({ data = {}, success }) => {
            setLoader("")
            if (success) {
                cardType == "Warehouse" ? setWarehouseDetailData({ ...data, ...(data?.formData || {}) }) : setFloorDetailData({ ...data, ...(data?.formData || {}) })
            }
        }))
        dispatch(getTemplateHeader({ type: cardType.toLowerCase() }, (data) => {
            cardType == "Warehouse" ? setWarehouseDetailTemplateHeader([...data?.templateHeader, ...warehouseDetailTemplateHeader2]) : setFloorDetailTemplateHeader([...data?.templateHeader, ...floorDetailTemplateHeader2])
        }))
    }
    const handleTabChange = (value, statusObject = {}) => {
        setStatusFilter(statusObject)
        setPage(0);
        setTabIndex(value);
        setSelectedEntries([])
        setViewWarehouseFloorDetail(false)
        refreshTableData(1, limit, value && statusObject?.value || "", false, value)

    };
    const handleTypeChange = (type) => {
        setSelectedEntries([])
        setCardType(type);
        // setSelectedRow(null);
        setPage(0);
        setLimit(10);
        setViewWarehouseFloorDetail(false)
    };
    const handleAddSelection = (obj) => {
        setSelectedEntries([...selectedEntries, obj]);
    };

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedEntries];
        const i = oldEntries.map((o) => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedEntries(oldEntries);
            if (oldEntries.length == 0) {
                handleCloseConfirmModal();
            }
        }
    };
    const handleCloseDetail = () => {
        setViewWarehouseFloorDetail(false)
    }
    const isFilterApplied = (() => {
        if (tabIndex == 0 && statusFilter?.value) {
            return true;
        }
        const { status, ...rest } = filter["other"] || {}
        if (Object.values(filter["search"])?.some(dl => dl && dl.length > 0) || Object.values(rest).some(dl => dl && dl.length > 0)) {
            return true;
        }
        return false;
    })();


    const handleCheckAll = () => {
        if (selectedEntries.length < wfData?.data?.length) {
            let sT = wfData?.data?.map((t) => ({
                _id: t._id,
                status: t.status,
                name: t.name,
            }));
            setSelectedEntries(sT);
        } else {
            setSelectedEntries([]);
        }
    };

    const handleCheckChange = (event, row) => {
        if (event.target.checked) {
            handleAddSelection(row);
        } else {
            handleRemoveSelection(row._id);
        }
    };

    const tableHeader = useSelector((state) => cardType == "Warehouse" ? state.customHeader["warehouseTable"] : state.customHeader["floorTable"]);
    let header = tableHeader && tableHeader?.validHeader && tableHeader.validHeader.map((h) => {
        if (h.handleType === "selectRow") {
            return (
                <Checkbox
                    data-testid="checkbox"
                    checked={
                        wfData?.data?.length != 0 &&
                        selectedEntries.length == wfData?.data?.length
                    }
                    onChange={handleCheckAll}
                    size="small"
                />
            )
        }
        return h.fieldName
    })
        || [];


    const getTableCell = (header, row, i) => {

        let value = ""
        if (header?.mappingPath) {
            value = dataReadFunction({ name: header?.mappingPath || "" }, row)
        }

        if (header?.handleType) {
            if (header?.handleType === "selectRow") {
                return (
                    <Checkbox
                        size="small"
                        checked={selectedEntries?.map((o) => o._id).indexOf(row._id) >= 0}
                        onChange={(e) => handleCheckChange(e, row)}
                    />
                );
            }
            else if (header?.handleType === "showDetail") {
                return (
                    <Typography
                        onClick={() => handleRowAndViewClick(row)}
                        style={{
                            ...textClasses.normalText,
                            color: theme.themeOrange,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
            else if (header?.handleType === "viewFloors") {
                return (
                    value?.length > 0 ? <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }}
                        onClick={() => {
                            setViewModal({ ...viewModal, type: header?.handleType, action: row?._id, origin: row?.type })
                        }}>
                        {value
                            ? Array.isArray(value)
                                ? value?.length > 0 && value.join(", ") || "-"
                                : value
                            : "-"
                        }
                    </Typography> : "-"
                )
            }
            else if (header?.handleType === "viewGrid") {
                let isAllowed = permissions?.permissions?.some((item) => item.permissionId === "manageFloorGrid")
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: isAllowed ? "pointer" : "",
                            textDecoration: isAllowed ? "underline" : "",
                        }}
                        {...isAllowed ?
                            {
                                onClick: (e) => {
                                    if (isAllowed) { }
                                    if (row?.isGridExist) {
                                        history.push("/dashboard/grid/viewGrid/" + row?._id, { floorDetail: row })
                                    }
                                    else {
                                        history.push("/dashboard/grid/addGrid/" + row?._id, { floorDetail: row })
                                    }
                                }
                            } : {}
                        }
                    >
                        {row?.isGridExist
                            ? (isAllowed ? "View" : "Grid Created")
                            : (isAllowed ? "Create" : "Grid Not Created")}
                    </Typography>
                );
            }
            else if (header?.handleType === "action") {
                return (
                    <Typography
                        data-testid="dropdown"
                        sx={{
                            ...textClasses.normalText,
                            color: header?.color || theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleOpenAction(e, i)}
                    >
                        Action <KeyboardArrowDown />
                    </Typography>
                );
            }
        }
        else if (header?.isFromRenderFunction === true) {
            let no;
            if (page === 0) {
                no = i + 1;
            } else {
                no = page * 10 + (i + 1);
            }
            if (header?.renderFunction === "srNo") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: header?.color || theme.themeText }}>
                        {no}
                    </Typography>
                );
            }
            else if (header?.renderFunction === "status") {
                let option = header?.values?.find(o => o.value == value) || {}
                return (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                textTransform: "capitalize",
                                textAlign: "center",
                                color: option?.color || theme.themeText,
                            }}
                        >
                            {option?.label || "-"}
                        </Typography>
                    </>
                );
            }
        }
        else {
            if (header?.fieldType === "array") {
                return (
                    <Typography sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "capitalize", color: header?.color || theme.themeText }}>
                        {value && Array.isArray(value) ? value.join(", ") : value || "-"}
                    </Typography>
                );
            }
            else if (header?.fieldType === "date") {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", color: header?.color || theme.themeText }}
                    >
                        {value ? moment(value).format(header?.format || "DD MMM YYYY hh:mm A") : "-"}
                    </Typography>
                );
            }
            else {
                return (
                    <Typography
                        sx={{ ...textClasses.normalText, textAlign: "center", textTransform: "none", textTransform: "capitalize", color: header?.color || theme.themeText }}
                    >
                        {value ?? "-"}
                    </Typography>
                );
            }
        }
    };

    const tableRows = (wfData?.data)?.map((row, i) => {
        let items = [];

        const validHeaders = tableHeader?.validHeader ?? [];
        for (const header of validHeaders) {
            items.push(getTableCell(header, row, i));
        }
        return items;
    });



    const refreshTableData = (pageNo, limitNo, status = "", fetchCount = true) => {
        setLoader("tableData");
        setWfData({
            data: [],
            count: {}
        })
        const payload = {
            page: pageNo,
            limit: limitNo,
            type: cardType.toLowerCase(),
            status: status || ""
        };
        if (filter && Object.keys(filter).length > 0) {
            for (let key in filter) {
                if (key && Object.keys(filter[key]).length > 0) {
                    Object.assign(payload, filter[key])
                }
            }

        }
        dispatch(
            getSuperAdminCompanyData(payload, (data = {}) => {
                setWfData({ data: data?.data, count: data?.count })
                setLoader("");
            })
        );

        dispatch(getCompanyStatusCount({ ...payload, status: "" }, (data = {}) => {
            setWarehouseFloorStatusCount(data?.data);
        }));

        dispatch(getCompanyTypeWiseCount({ ...payload, status: "" }, (data = {}) => {
            setWarehouseFloorTypeWiseCount(data?.data);
        }));

    };


    let fetchData = (fetchCount = false) => {
        refreshTableData(page + 1, limit, statusFilter?.value || "", fetchCount, tabIndex);
        // setSelectedTemplates([]);
        setConfirmModal(null);
        // setReceiveModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);
        setViewWarehouseFloorDetail(false)
    };

    useEffect(() => {
        if (cardType) {
            fetchData(initialRender);
            if (initialRender) {
                setInitialRender(false)
            }
        }

    }, [page, limit, filter.other, debouncedFilterSearch, cardType]);

    // useEffect(() => {
    //     dispatch(getSuperAdminCompanyData({ projections: { _id: 1, companyName: 1, type: 1 }, type: 'company' }, ({data = []}) => {
    //         setCompaniesList(data)
    //     }))
    //     if (cardType == "Floor") {
    //         dispatch(getSuperAdminCompanyData({ projections: { _id: 1, name: 1, type: 1, warehouseId: 1 }, type: 'warehouse' }, ({ data = [] }) => {
    //             setWarehousesList(data)
    //         }))
    //     }
    // }, [cardType])
    return (
        <>
            <Grid container>
                <Grid item sm={8}>
                    <Typography sx={{ ...textClasses.cardTitle }}>
                        {pageTitle}
                    </Typography>
                </Grid>

                {/* Below commented code is for having the filters on warehouse floor page */}

                {/* <Grid item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
            <Autocomplete
              value={selectedCompanyDetail || {}}
              onChange={(e, option) => {
                setSelectedCompanyDetail(option);
                setPage(0);
                setLimit(10);
              }}
              getOptionLabel={(option) => option?.companyName || ""}
              size="small"
              options={companiesList|| []}
              sx={{
                ...inputClasses.filterField,
                minWidth: "250px",
                ".MuiInputBase-input": {
                  fontSize: "15px",
                  color: "#455A64",
                },
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "#455A64", ...textClasses.normalText }}
                  {...params}
                  label="Select Company"
                />
              )}
            />
            </Grid>  
                
            {cardType == "Floor" && (
            <Grid item sm={4}  sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
              <Autocomplete
                value={selectedWarehouseDetail || {}}
                onChange={(e, option) => {
                  setSelectedWarehouseDetail(option);
                  setPage(0);
                  setLimit(10);
                }}
                getOptionLabel={(option) => option?.name || ""}
                size="small"
                options={warehousesList || []}
                sx={{
                  ...inputClasses.filterField,
                  minWidth: "250px",
                  ml: 2,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label="Select Warehouse"
                  />
                
                )}
              />
                </Grid>
             )}  */}
                <Grid container item sm={4} sx={{ display: 'flex', alignItems: "center", justifyContent: 'flex-end' }}>
                    {cardType == "Warehouse" &&
                        (permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "createWarehouse"
                            ) ? (
                            <Button
                                data-testid="add"
                                variant="outlined"
                                size="small"
                                startIcon={<Add />}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                                onClick={() => history.push("/dashboard/warehouse/add")}
                            >
                                Add Warehouse
                            </Button>) : null)
                    }
                    {cardType == "Floor" &&
                        (permissions &&
                            (permissions.permissions || []).some(
                                (item) => item.permissionId === "createFloor"
                            ) ? (
                            <Button
                                data-testid="add"
                                variant="outlined"
                                size="small"
                                startIcon={<Add />}
                                sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                                onClick={() => history.push("/dashboard/floor/add")}
                            >
                                Add Floor
                            </Button>) : null)
                    }

                </Grid>
                <GenericPageConfig
                    storedInPage={"WarehouseFloorPage"}
                    pageName={"warehouseAndFloor"}
                    pageTabCount={warehouseFloorTypeWiseCount}
                    pageStatusCount={warehouseFloorStatusCount}
                    handlePageTabChange={handleTypeChange}
                    selectedPageTab={cardType}
                    selectedStatusTab={tabIndex}
                    handleStatusTabChange={handleTabChange}
                    disabledPageTabs={loader}
                    disabledStatusTabs={loader}

                />
            </Grid>
            <Grid container spacing={1} xs={12}>
                {renderHeadersFilter(tableHeader, filter, setFilter, setPage, setLimit, tabIndex)}
                {isFilterApplied && (
                    <Grid container item sx={2} alignItems={"center"}>
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={handleClearFilter}
                        >
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                )}
                <Grid container item sx={4} sm={2} alignItems={"center"}>
                    <GenericCustomiseHeader
                        tableName={cardType == "Warehouse" ? "warehouseTable" : "floorTable"}
                        tableType={cardType == "Warehouse" ? "warehouseTable" : "floorTable"}
                    />
                </Grid>
            </Grid>
            {selectedEntries?.length > 0 &&
                <Grid container sx={{ mt: 2, textTransform: "none" }}>
                    {selectedEntries.length == 1 && permissions && (
                        <>
                            {(permissions.permissions || []).some(
                                (item => ["viewWarehouse", "viewFloor"].includes(item.permissionId))
                            ) && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            mr: 1,
                                            textTransform: "none",
                                        }}
                                        onClick={() => handleRowAndViewClick(selectedEntries[0])}
                                    >
                                        View
                                    </Button>
                                )}
                            {(permissions.permissions || []).some(
                                (item => ["editWarehouse", "editFloor"].includes(item.permissionId))
                            ) &&
                                (<Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ...buttonClasses.lynkitOrangeFill,
                                        mr: 1,
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        cardType == "Warehouse" ?
                                            history.push(`/dashboard/warehouse/edit?id=${selectedEntries[0]._id}`) :
                                            history.push(`/dashboard/floor/edit?id=${selectedEntries[0]._id}`)
                                    }}
                                >
                                    Edit
                                </Button>
                                )}
                        </>
                    )}
                    {
                        permissions &&
                        (permissions.permissions || []).some(
                            (item => ["changeWarehouseStaus", "changeFloorStatus"].includes(item.permissionId))
                        ) && (
                            <>
                                {selectedEntries?.every(entry => entry.status === "active") && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            mr: 1,
                                            textTransform: "none",
                                        }}
                                        onClick={() => setConfirmModal("disable")}
                                    >
                                        Disable
                                    </Button>
                                )}
                                {selectedEntries?.every(entry => entry.status === "inActive") && (
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses.lynkitOrangeFill,
                                            mr: 1,
                                            textTransform: "none",
                                        }}
                                        onClick={() => setConfirmModal("enable")}
                                    >
                                        Enable
                                    </Button>
                                )}
                            </>
                        )
                    }

                </Grid>

            }
            <Grid container sx={{ mt: 2 }}>
                <Grid item sm={viewWarehouseFloorDetail ? 8 : 12} xs={12}>
                    <Paper>
                        {loader == "tableData" ? (
                            <Grid
                                data-testid="loader"
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                sx={{ p: 10 }}
                            >
                                <Grid item>
                                    <GenericLoader />
                                </Grid>
                            </Grid>
                        ) : (
                            <GenericTable
                                header={header}
                                rows={tableRows}
                                pageCount={wfData?.count}
                                pageNumber={page}
                                handleChangePage={(event, pagevalue) => {
                                    setPage(pagevalue);
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setLimit(+event.target.value);
                                }}
                                rowsPerPage={limit}
                            />
                        )}
                    </Paper>
                </Grid>
                {
                    viewWarehouseFloorDetail && (
                        <Grid item sm={4} xs={12} sx={{ p: 1, pt: 0 }}>
                            <ViewRowDetailCard
                                detailData={cardType == "Warehouse" ? warehouseDetailData : floorDetailData}
                                onClose={handleCloseDetail}
                                readKey={'name'}
                                cardHeader={cardType == "Warehouse" ? warehouseDetailTemplateHeader : floorDetailTemplateHeader}
                                loader={loader == "cardRowDetail"}
                            />
                        </Grid>
                    )
                }
                {/* this is for the disable and enable buttons */}

                <EnableDisableDialog
                    status={confirmModal}
                    handleClose={handleCloseConfirmModal}
                    selectedEntries={selectedEntries}
                    setSelectedEntries={setSelectedEntries}
                    refreshTableData={() => {
                        refreshTableData(page + 1, limit)
                        setTabIndex(0)
                    }}
                    handleRemoveSelection={handleRemoveSelection}
                    cardType={cardType}
                />
                {/* this is for the disable and enable action menu items */}

                <EnableDisableDialog
                    status={actionConfirmModal}
                    cardType={cardType}
                    handleClose={handleCloseAction}
                    selectedEntries={
                        actionIndex != null ? [wfData?.data[actionIndex]] : []
                    }
                    refreshTableData={() => {
                        refreshTableData(page + 1, limit)
                        setTabIndex(0)
                    }}
                />
                {viewModal?.type &&
                    <ViewWarehouseFloorModal
                        viewModal={viewModal}
                        setViewModal={setViewModal}
                    />
                }

                {actionIndex != null && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={actionIndex != null}
                        onClose={handleCloseAction}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                        sx={{
                            display:
                                actionIndex != null ? "block !important" : "none !important",
                        }}
                    >
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item => ["viewWarehouse", "viewFloor"].includes(item.permissionId))
                            ) ? (
                            <MenuItem
                                key={1}
                                sx={{ ...textClasses.normalText }}
                                onClick={() => {
                                    handleRowAndViewClick(wfData?.data[actionIndex])
                                    handleCloseAction()
                                }}
                            >
                                View
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {permissions &&
                            (permissions.permissions || []).some(
                                (item => ["editWarehouse", "editFloor"].includes(item.permissionId))
                            ) ? (
                            <MenuItem
                                key={1}
                                sx={{ ...textClasses.normalText }}
                                onClick={() => {
                                    cardType == "Warehouse" ?
                                        history.push(`/dashboard/warehouse/edit?id=${wfData?.data[actionIndex]?._id}`) :
                                        history.push(`/dashboard/floor/edit?id=${wfData?.data[actionIndex]?._id}`)
                                }}
                            >
                                Edit
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {permissions &&
                            (permissions.permissions || []).some((item => ["changeWarehouseStaus", "changeFloorStatus"].includes(item.permissionId))) && (
                                <>
                                    {wfData?.data[actionIndex]?.status == "active" && (
                                        <MenuItem
                                            key={2}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("disable")}
                                        >
                                            Disable
                                        </MenuItem>
                                    )}
                                    {wfData?.data[actionIndex]?.status == "inActive" && (
                                        <MenuItem
                                            key={3}
                                            sx={{ ...textClasses.normalText }}
                                            onClick={() => setActionConfirmModal("enable")}
                                        >
                                            Enable
                                        </MenuItem>
                                    )}
                                </>
                            )}
                    </Menu>
                )}
            </Grid>
        </>
    );
}

export default WarehouseFloorPage;