import theme,{inputClasses} from "../static/styles/theme";

export const selectStylesOverride = {
	// "& [class*='MuiInputBase-root-MuiOutlinedInput-root'].Mui-focused .MuiOutlinedInput-notchedOutline":
	// 	{
	// 		borderColor: theme.themeOrange,
	// 		borderWidth: 1,
	// 	},
	// "& [class*='MuiFormLabel-root-MuiInputLabel-roo'].Mui-focused": {
	// 	color: theme.themeBlack,
	// },
	...inputClasses.themeBoundary
};

export function parseCamelCase(str) {
	let result = "";

	str.split("").map((s, i) => {
		if (s.match(/[A-Z]/)) {
			result += " " + s;
		} else if (i == 0) {
			result += s.toUpperCase();
		} else {
			result += s;
		}
	});
	return result;
}