import React, { useEffect, useState } from "react";
import AlertDialog from "../../Components/DialogBox/dialogbox"
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, Link, FormControlLabel, Checkbox } from "@mui/material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { getWorkOrderListConfig,generateJobPickList } from "../../../redux/actions";
import samplePicklist from "../../../static/file/samplePicklist.pdf"
import { b64toBlob } from "../../../utils/services";
import {showToast } from "../../../services/functions";

const ViewWorkOrderList = ({ checkedRow, setCheckedRow, _id }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [labelText, setLabelText] = useState("random Text")
    const [workOrderListData, setWorkOrderListData] = useState({})
    const [dataReceieved,isDataReceived] = useState(false)
    const handleSelectAll = (keyProp) => {
        let tempList = { ...workOrderListData } 
        if(tempList["allHeaders"] && tempList["allHeaders"][keyProp] && tempList["allHeaders"][keyProp]["header"]){
            const allSelected = tempList["allHeaders"][keyProp]["header"].every(item => item.isSelected);
            tempList["allHeaders"][keyProp]["header"] = tempList["allHeaders"][keyProp]["header"].map(item => ({
                ...item,
                isSelected: !allSelected
            }));
            setWorkOrderListData(tempList);
        }
    };
    const handleIndividualCheckBox = (value,keyProp) => {
        let tempList={...workOrderListData}
        tempList["allHeaders"][keyProp]["header"] =  tempList["allHeaders"][keyProp]["header"].map((item) => {

            if (item.field_name === value) {
                return {
                    ...item,
                    isSelected: !item.isSelected,
                };
            }
            return item;
        });
        setWorkOrderListData(tempList)
    };

    const anyFieldSelected = Object.values(workOrderListData?.allHeaders || {}).some(header => {
        return header.header.some(dl => dl.isSelected);
    });

    const handleGeneratePDFClick = () => {
        isDataReceived(true);
      let selectedFields = {
        orderDetailHeader: [],
        inventoryDetailHeader: [],
        customerDetailHeader: [],
        formTemplateDetail: [],
        kitDetailHeader: []
    };
    Object.keys(workOrderListData?.allHeaders || {}).forEach(key => {
        let checkedHeaders = workOrderListData.allHeaders[key].header
            .filter(dl => dl.isSelected)
            .map(dl => dl.field_id);
        selectedFields[key] = checkedHeaders;
    });   

        dispatch(
            generateJobPickList(
                {
                    jobComponentId: _id,
                    get_type: "pdf",
                    selectedFields: selectedFields,
                    fetch_from: "web"
                },
                ({ success, message, data }) => {
                    
                    showToast(message, !success);
                    if (success && data ) {
                        let blob = b64toBlob(data, "application/pdf");
                        let url = URL.createObjectURL(blob);
                        window.open(url, "_blank");
                        isDataReceived(false)
                    }
                }
            )
        );
    };

    const getWorkOrderListData = () => {
        setLoader(true)
        dispatch(getWorkOrderListConfig({ jobComponentId: _id, selectAll: true }, (data) => {
            setLoader(false)
            setWorkOrderListData(data?.data || {})
        }))
    }
    useEffect(() => {
        getWorkOrderListData();
    }, [])
    return (
        <AlertDialog
            viewDialog={checkedRow.type === "viewWorkOrderList"}
            handleClose={() => setCheckedRow({ ...checkedRow, type: '' })}
            // viewSearchInput={true}
            showDivider={true}
            width="70%"
            title="Generate Work Order List PDF"
            actionsCSS={{ justifyContent: 'flex-start' }}
            body={
                <>
                    {loader || dataReceieved?
                        <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                            <Grid item><GenericLoader /></Grid>
                        </Grid>
                        :
                        <Box sx={{ width: "inherit" }}>
                            <Typography sx={{ ...textClasses.normalText, mb: 1, }}>
                                *Select details to be shown in PDF  <Link
                                    data-testid="viewSample"
                                    style={{
                                        color: "#FF7200",
                                        textDecoration: "underline",
                                    }}
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                        window.open(samplePicklist, "_blank");
                                    }} 
                                >
                                    View Sample
                                </Link>
                            </Typography>
                            <Grid container sx={{ display: 'flex', flexDirection: 'row', gap: '21px' }} xs={12}>
                                {workOrderListData  && workOrderListData?.allHeaders && Object.entries(workOrderListData?.allHeaders)?.map(([keyProp, value = {}]) => {
                                    return (<WorkListBox
                                        data-testid="worklistbox"
                                        labelText={value?.title || ""}
                                        setLabelText={setLabelText}
                                        keyProp={keyProp}
                                        header={value?.header || []}
                                        workOrderListData={workOrderListData}
                                        setWorkOrderListData={setWorkOrderListData}
                                        handleSelectAll={handleSelectAll}
                                        handleIndividualCheckBox ={handleIndividualCheckBox}
                                    />)
                                })}

                            </Grid>
                        </Box>
                    }
                </>
            }

        >

            <Button
                data-testid="cancel-btn"
                variant="outlined"
                size="small"
                onClick={() => { setCheckedRow({ ...checkedRow, type: '' }) }}
                sx={{ ...buttonClasses.lynkitOrangeEmpty, width: '120px', mr: 1 }}
            >Cancel
            </Button>

            {/* <Button
                data-testid="savebtn"
                variant="outlined"
                size="small"
                sx={{ ...buttonClasses.lynkitBlackFill, width: '120px', mr: 1 }}
            // onClick={handleShareFormClick}
            >
                Save
            </Button> */}


            <Button
                data-testid="generatePDF-btn"
                variant="contained"
                size="small"
                disabled={!anyFieldSelected || dataReceieved}
                sx={{ ...buttonClasses.lynkitOrangeFill, width: '120px' }}
            onClick={handleGeneratePDFClick}
            >
                Generate PDF
            </Button>

        </AlertDialog>
    )
}

const WorkListBox = ({ labelText, setLabelText, keyProp, header = [], workOrderListData, setWorkOrderListData,handleSelectAll,handleIndividualCheckBox }) => {
    const [editMode, setEditMode] = useState(false);
    const [editedLabelText, setEditedLabelText] = useState(labelText);

    // const handleEditClick = () => {
    //     setEditMode(true);
    // };

    // const handleSave = () => {
    //     setLabelText(editedLabelText);
    //     setEditMode(false);
    // };

    // const handleCancel = () => {
    //     setEditedLabelText(labelText);
    //     setEditMode(false);
    // };

    return (
        <>
            <Grid xs={3.8} container item sx={{ border: '1px solid gray', borderRadius: 2, p: 2 }}>
                <FormControlLabel
                    data-testid="boxheaders"
                    sx={{
                        ...textClasses.t15nBold,
                        display: "flex",
                        alignItems: "center",
                    }}
                    label={
                        editMode ? (
                            <input
                                type="text"
                                value={editedLabelText}
                                onChange={(e) => setEditedLabelText(e.target.value)}
                            />
                        ) : (
                            labelText
                        )
                    }
                    control={
                        <Checkbox
                            data-testid="checkbox"
                            onChange={() => handleSelectAll(keyProp)}
                            checked={header.every((dl) => dl.isSelected == true)}
                        />
                    }
                />
                {/* {editMode ? (
                    <>
                        <IconButton onClick={handleSave}>
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                            <ClearIcon />
                        </IconButton>
                    </>
                ) : (
                    <IconButton onClick={handleEditClick}>
                        <EditNoteOutlinedIcon />
                    </IconButton>
                )} */}
                <Grid
                    container
                    item
                    fullWidth
                    sx={{
                        overflowY: "auto",
                        height: "35vh",
                        marginBottom: "10px",
                    }}
                >
                    {header?.map((item) => {
                        return (<>
                            <Grid
                                data-testid="boxesitems"
                                xs={12}
                                sx={{
                                    marginLeft: "5%",
                                    ...textClasses.t14n,
                                    color: "#827878",
                                }}
                                item
                            >
                                <Checkbox
                                    value={item.field_name}
                                    onChange={(e) =>
                                        handleIndividualCheckBox(item.field_name, keyProp)
                                    }
                                    checked={item.isSelected}
                                />
                                {item.field_name}
                            </Grid>
                        </>)
                    })}
                </Grid>
            </Grid>
        </>
    )
}
export default ViewWorkOrderList;