import React, { useState, useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import theme from "../../../static/styles/theme";
import { selectStylesOverride } from "../../../utils/util";

const MultiSelectAll = ({ items, selectAllLabel, onChange, value, label, limitTag=1, defaultSelectAll=true }) => {
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [intitalRender, setInitialRender] = useState(true);
  const multiSelectRef = useRef(null);
  useEffect(() => {
    if(items?.length && !selectedOptions?.length) {
      setInitialRender(false);
      if(defaultSelectAll){
        setSelectedOptions(items);
      }
    }
  },[items])

  useEffect(() => {
    if(!intitalRender) {
      onChange(selectedOptions);
    }
  }, [selectedOptions]);

  const handleToggleOption = (selectedOptions) =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = (option) => `${option.name}`;
  const allItemsSelected = () => {
    if (filteredOptions?.length !== items.length) {
      const excludedFilteredOptions = filteredOptions?.filter(
        (opt) => !selectedOptions.find((selOpt) => selOpt.name === opt.name)
      );
      if (excludedFilteredOptions?.length > 0) {
        return false;
      }
      return true;
    }
    const allSelected =
      items.length > 0 && items.length === selectedOptions.length;
    return allSelected;
  };

  const clearSelected = (selOptions) => {
    if (selOptions.length > 0) {
      setSelectedOptions(
        selectedOptions.filter(
          (item) =>
            !selOptions.find((selOption) => selOption.name === item.name)
        )
      );
    } else {
      setSelectedOptions([]);
    }
  };

  const handleSelectAll = (isSelected) => {
    let selectedList = [];
    if (
      filteredOptions?.length > 0 &&
      filteredOptions.length !== items.length
    ) {
      selectedList = items.filter((item) =>
        filteredOptions.find(
          (filteredOption) => filteredOption.name === item.name
        )
      );
    }
    if (isSelected) {
      if (selectedList.length > 0) {
        setSelectedOptions([...selectedOptions, ...selectedList]);
      } else {
        setSelectedOptions(items);
      }
    } else {
      clearSelected(selectedList);
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll(!allItemsSelected());
  };

  const handleChange = (event, selectedOptions, reason) => {
    let result = null;
    if (reason === "clear") {
      handleClearOptions();
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option._id === "select-all")) {
        handleToggleSelectAll();
        result = items.filter((el) => el._id !== "select-all");
      } else {
        handleToggleOption(selectedOptions);
        result = selectedOptions;
      }
    }
  };

  const handleCheckboxChange = (selected, option) => {
    if (option._id === "select-all") {
      handleToggleSelectAll();
    } else if (!selected) {
      const result = [...selectedOptions, option];
      setSelectedOptions(result);
    } else {
      const result = selectedOptions.filter(
        (selOption) => selOption._id !== option._id
      );
      setSelectedOptions(result);
    }
  };

  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option._id === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allItemsSelected() }
        : {};
    return (
      <Grid container key={option.name}>
        <Grid item xs={12} sx={{ pl: 1, pr: 1 }}>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={(e) => handleCheckboxChange(selected, option)}
          >
            <Checkbox
              key={option.name}
              checked={selected}
              {...selectAllProps}
              sx={{ mr: 1 }}
            />
            <span>{getOptionLabel(option)}</span>
          </div>
        </Grid>
      </Grid>
    );
  };

  const debouncedStateValue = debounce((newVal) => {
    if (newVal && !isEqual(newVal, filteredOptions)) {
      setFilteredOptions(newVal);
    }
  }, 50);

  const updateFilteredOptions = (filtered) => {
    debouncedStateValue(filtered);
  };

  const inputRenderer = (params) => (
    <TextField
      {...params}
      variant="outlined"
      label={label}
      placeholder={label}
      sx={{
        ...selectStylesOverride,
        color: theme.themeOrange,
      }}
    />
  );

  const filter = createFilterOptions();

  return (
    <Autocomplete
    sx={{
      margin:'10px 0 8px 0'
    }}
      ref={multiSelectRef}
      multiple
      size="small"
      limitTags={limitTag}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, val) => option._id === val._id}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        updateFilteredOptions(filtered);
        return [{ name: selectAllLabel, _id: "select-all" }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

export default MultiSelectAll;
