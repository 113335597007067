import React, { useEffect, useState, useRef } from 'react'
import {
    Typography, TextField, FormControl, FormLabel, InputLabel, Select, MenuItem, FormControlLabel,
    Checkbox, RadioGroup, Radio, FormGroup, Box, Grid, Autocomplete, Tooltip
} from '@mui/material'
import theme, { inputClasses, textClasses } from '../../../static/styles/theme'
import CheckIcon from "@mui/icons-material/Check";
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import no_data_found from '../../../static/images/no_data_found.svg'
import { Add } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changeUomModalStatus } from '../../../redux/actions';
import AddUom from '../Uom/AddUom';
import axios from '../../../services/Api';
import config from '../../../services/config';
import { customAPI_For_APIPara } from "../../../services/formFunction"
import { debounce } from '@mui/material/utils';
import { selectStylesOverride } from '../../../utils/util';
import moment from 'moment'
import { getShortText } from '../../../services/functions';


const CustomInput = ({ component, autoFillOnFocus, errors, field, multiline, inputProps, ...other }) => {
    const dispatch = useDispatch();
    const [selectOptions, setSelectOptions] = useState([]);
    const [initial, setInitial] = useState(true);
    const [searching, setSearching] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [enableFetch, setEnableFetch] = useState(false);
    const openModal = useSelector(state => state.uom.openModal); // Only for UOM Modal

    const usePrevious = (value = "") => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    };


    const prevData = usePrevious(other?.value || field.value || (component.type == "checkbox" && [] || ""));
    const prevInput = usePrevious(inputValue);


    useEffect(() => {

        if (component?.setDefaultValue && component?.defaultValueFunction && !prevData) {
            // &&  prevData!=eval(component.defaultValueFunction)
            let eValue = eval(component?.defaultValueFunction || "")
            // console.log(eValue,"evaluue")

            if (component.type == "date") {
                eValue = moment(eValue).format("YYYY-MM-DD")
                // console.log("moment",eValue);
            }
            // console.log("eValue2", {eValue,field,other});
            // console.log("eValue", prevData);
            if (field.onChange) {
                setTimeout(() => {
                    field.onChange({ target: { value: eValue } })

                }, 400);
            }
            else if (other.onChange) {
                setTimeout(() => {
                    other.onChange({ target: { value: eValue } })
                }, 400);
            }
        }
    }, [component.setDefaultValue]);



    // const fetch = React.useMemo(() => 
    //     debounce(async(component, value, setOptions, setSearchingStatus) => {
    //         setSearchingStatus("Searching...");
    //         try {
    //             if(value){
    //                 await customAPI_For_APIPara(component, value,(results=[]) => {
    //                     setOptions(results);
    //                     setSearchingStatus("");
    //                 });
    //             }
    //             else{
    //                 setOptions([]);
    //                 setSearchingStatus("");  
    //             }
    //         } catch (error) {
    //             console.error("Failed to fetch data:", error);
    //             setOptions([]);
    //             setSearchingStatus("Failed to fetch");
    //         }
    //     }, 400),
    //     [],
    // );

    // useEffect(async() => {
    //     let active = true; 
    //     const fetchData = async () => {
    //         // console.log("initial1",{
    //         //     component_id:component._id,
    //         //     other_value:other?.value ,
    //         //     field_value:field?.value ,
    //         //     dynamic:component?.dynamic,
    //         //     dontFetch:component?.dontFetch,
    //         //     initial,prevData
    //         // });
    //         if (initial && component?.dynamic && !component.dontFetch) {// (other?.value || field.value) !== prevData &&
    //             // console.log("initial2",initial);
    //             await fetch(component, other?.value || field.value, setSelectOptions, setSearching);
    //         }
    //         setInitial(false);
    //     };
    //     fetchData();     
    //     return () => {
    //         active = false;
    //     };
    // }, [other?.value,field.value]);


    // useEffect(async() => {
    //     let active = true;
    //     if (!initial && component?.dynamic && !component.dontFetch && enableFetch) {
    //         // console.log("inputValue2",inputValue);
    //         if (!inputValue) {
    //             let temp_option = []
    //             // if (component?.value) {
    //             //     await fetch(component,component?.value, setSelectOptions, setSearching);
    //             // }
    //             // else{
    //             setSelectOptions(temp_option)
    //             setSearching("")
    //             // }
    //             setEnableFetch(false);
    //             return undefined;
    //         }
    //         else if (prevInput != inputValue) {
    //             await fetch(component, inputValue, setSelectOptions, setSearching);
    //         }
    //         setEnableFetch(false);
    //     }

    //     return () => {
    //         active = false;
    //     };
    // }, [inputValue]);

    const fetch = React.useMemo(
        () =>
            debounce(async (component, value, callback) => {
                setSearching("Searching...")
                await customAPI_For_APIPara(component, value, callback)
            }, 400),
        [],
    );

    useEffect(async () => {
        let active = true;
        if (initial) {
            if (component?.dynamic && !component.dontFetch) {
                fetch(component, other?.value || field.value, (results) => {
                    let temp_option = []
                    if (active && results) {
                        temp_option = [...results];
                    }
                    setSearching("")
                    setSelectOptions(temp_option)
                });
            }
            setInitial(false)
        }
        return () => {
            active = false;
        };
    }, [other?.value, field.value]);


    useEffect(() => {
        let active = true;
        // console.log(component?._id,"bbb1",inputValue!=prevInput,enableFetch,inputValue)
        // if (!initial && component?.dynamic && !component.dontFetch && inputValue!=prevInput) {
        if (!initial && component?.dynamic && !component.dontFetch && enableFetch) {
            if (!inputValue) {
                // setSelectOptions([])
                // setSearching("")
                // setEnableFetch(false)
                fetch(component, "", (results) => {
                    // console.log(results,"bbb12",inputValue!=prevInput,enableFetch,inputValue)
                    setSearching("")
                    let newOptions = [];
                    if (active && results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setSelectOptions(newOptions);
                    setEnableFetch(false)
                });
                // return undefined;
            }
            else if (prevInput != inputValue) {
                fetch(component, inputValue, (results) => {
                    setSearching("")
                    let newOptions = [];
                    if (active && results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setSelectOptions(newOptions);
                    setEnableFetch(false)
                });
            }
            else {
                setEnableFetch(false)
            }
        }

        return () => {
            active = false;
        };
    }, [inputValue]);

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleOpenUomModal = () => {
        dispatch(changeUomModalStatus(true));
    }

    const handleSelect = (idChecked, values = []) => {
        const newValue = (Array.isArray(values) && values || [])?.includes(idChecked)
            ? values?.filter((id) => id !== idChecked)
            : [...values, idChecked];

        // console.log("idChecked, values",{idChecked, values,newValue});
        // console.log("newValue",{newValue});
        return newValue;
    }

    let basicProps = {
        size: "small",
        fullWidth: true,
        type: component.type,
        required: component.required,
        multiline: component.type == "textarea",
        rows: component.type == "textarea" ? 3 : 1,
        label: component.label,
        placeholder: component.placeholder,
        onFocus: autoFillOnFocus ? autoFillOnFocus : () => { },
        error: errors && errors[component._id] ? true : false
    }

    if (component.type == "number") {
        basicProps.onWheel = (event) => event.target.blur()
        basicProps.onKeyDown = (e) => {
            if (['e', 'E', '+', '-'].includes(e.key)) {
                e.preventDefault();
            }
            else if (e.key == '0' && (e.target.value == "" || e.target.value[0] == '0')) {
                e.preventDefault();
            }
        }
        basicProps = {
            ...field,
            ...basicProps
        }
    }
    if (component.type == "date") {
        basicProps.InputLabelProps = { shrink: true }
        basicProps = {
            ...field,
            ...basicProps
        }
    }
    if (component.type == "file") {
        basicProps.focused = true;
        basicProps.onChange = async (e) => {
            const file = await convertDocsBase64(e.target.files[0])
            field.onChange(file)
        };
    }

    if (component.type != "file") {
        basicProps = {
            ...field,
            ...basicProps
        }
    }

    if (["input", "number", "textarea", "date", "file", "searchTags"].indexOf(component.type) >= 0) {
        // console.log("component.type",component.type,other.value,field.value)
        return (
            <TextField
                {...basicProps}
                {...other}
                sx={{ ...inputClasses.themeBoundary }}
                InputProps={
                    inputProps ? { endAdornment: inputProps } : {}
                }
            />
        )
    }
    else if (component.type == "select") {
        // console.log(component._id,"value",field.value, other.value, selectOptions)
        let { onChange, handleDataChange, ...rest } = other
        return (
            <>
                <FormControl
                    fullWidth={basicProps.fullWidth}
                    size={basicProps.size}
                    required={basicProps.required}
                    error={basicProps.error}
                >
                    {
                        !component.dynamic
                            ? <>
                                <InputLabel>{component.label}</InputLabel>
                                <Select
                                    {...field}
                                    label={`${component?.label}${component?.required ? "*" : ""}`}
                                    onFocus={basicProps.onFocus}
                                    {...rest}
                                    onChange={handleDataChange}
                                    sx={{ ...inputClasses.themeBoundary }}
                                >
                                    <MenuItem sx={{ color: "gray" }} value={""}>{component.placeholder ? component.placeholder : "Select"}</MenuItem>
                                    {
                                        component.values && component.values.map((op, i) => {
                                            return <MenuItem value={op["value"]} key={i}>{op["label"]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </>
                            :
                            <Autocomplete
                                id="gmap-demo"
                                {...field}
                                {...rest}
                                sx={{ ...inputClasses.themeBoundary, marginRight: '-10px' }}
                                onFocus={basicProps.onFocus}
                                filterOptions={(x) => x}
                                options={selectOptions}
                                {...((component.renderOption?.length > 0 || component.suffix) > 0 ? {
                                    value: (selectOptions?.length && (other.value || field.value) &&
                                        selectOptions.find(dl => dl.value == (other.value ? other.value : field.value))?.label)
                                        || (other.value ? other.value : field.value) || ""
                                } : {})}
                                autoComplete
                                size="small"
                                includeInputInList
                                filterSelectedOptions
                                noOptionsText={searching
                                    ? searching
                                    : inputValue && "No Data Found" || "Type Here"
                                }
                                onChange={(event, newValue) => {
                                    handleDataChange({ target: { value: newValue?.value || "" } }, newValue)
                                    setEnableFetch(!newValue)
                                    // setEnableFetch(false)
                                    setSelectOptions(newValue ? [newValue] : []);

                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                    setSelectOptions([])
                                    setSearching(newInputValue ? "Typing..." : "")
                                    setEnableFetch(true)
                                    // setEnableFetch(newInputValue!=prevInput)

                                }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label={`${component.label}${component.required ? "*" : ""}`}
                                        fullWidth
                                        sx={{
                                            ...selectStylesOverride,
                                        }}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => {
                                    let isTrue = false
                                    if (component._id == "UOM" && props && props["data-option-index"] == 0) {
                                        isTrue = true
                                    }
                                    return (
                                        <>
                                            {
                                                isTrue && <MenuItem
                                                    sx={{ color: theme.themeOrange }}
                                                    onClick={handleOpenUomModal}
                                                >
                                                    <Add fontSize='inherit' /> Add new unit
                                                </MenuItem>
                                            }
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                sx={{ justifyContent: "space-between" }}
                                                {...props}
                                            >
                                                <Tooltip sx={{
                                                    width: '50px'
                                                }} title={option.label}>
                                                    <Typography>
                                                        {getShortText(option.label, 21)}
                                                    </Typography>

                                                </Tooltip>
                                                {selected ? <CheckIcon color="info" /> : null}
                                            </MenuItem>
                                        </>
                                    )
                                }}
                            />
                    }
                </FormControl>
                <AddUom open={openModal} />
            </>
        )
    }
    else if (component.type == "radio") {
        const innerComp = (
            <RadioGroup
                row
                {...field}
                sx={{ mt: 1 }}
            >
                {
                    component.values && component.values.map((op, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                {...other}
                                label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                control={<Radio value={op.value} size="small" sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
                            />
                        )
                    })
                }
            </RadioGroup>
        )
        if (multiline != null && multiline == false) {
            return (
                <Grid container justifyContent={"left"} alignItems="center">
                    {innerComp}
                </Grid>
            )
        }
        return (
            <div style={{ ...inputClasses.shadowField, border: `1px solid ${basicProps.error ? "#e74c3c" : "#D9D9D9"}`, width: "100%", padding: "10px" }}>
                <FormControl fullWidth size='small' {...other}>
                    <FormLabel
                        sx={{
                            color: "rgb(0 0 0 / 60%) !important",
                            "& .MuiFormLabel-root.Mui-focused": {
                                color: "#F77200",
                            }
                        }}
                    >
                        {component.label}
                    </FormLabel>
                    {innerComp}
                </FormControl>
            </div>
        )
    }
    else if (component.type == "checkbox") {
        let { onChange, ...rest } = other
        const innerComp = (
            <FormGroup
                row
                sx={{ mt: 1 }}
            >
                {
                    component.values && component.values.map((op, i) => {
                        // console.log("oppp",op)
                        // console.log("fff",field)

                        return (
                            <FormControlLabel

                                key={i}
                                {...rest}
                                label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}

                                control={<Checkbox
                                    checked={field?.value?.includes(op.value)}
                                    onChange={() => field.onChange(handleSelect(op.value, field.value || []))}
                                    // onChange={() => onChange({target:{value:handleSelect(op.value, field.value || [])}})}

                                    size="small"
                                    sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
                            />
                        )
                    })
                }
            </FormGroup>
        )
        if (multiline != null && multiline == false) {
            return (
                <Grid container justifyContent={"left"} alignItems="center">
                    {innerComp}
                </Grid>
            )
        }
        return (
            <div style={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormControl fullWidth size='small' {...other}>
                    <FormLabel
                        sx={{
                            color: "rgb(0 0 0 / 60%) !important",
                            "& .MuiFormLabel-root.Mui-focused": {
                                color: "#F77200",
                            }
                        }}
                    >
                        {component.label}
                    </FormLabel>
                    {innerComp}
                </FormControl>
            </div>
        )
    }
}

export const CustomControlledInput = ({
    component, control, errors, autoFillOnFocus, register, action, multiline, inputProps,
    handleChange = false
}) => {
    return (
        <>
            <Grid container justifyContent={"center"} alignItems="center">
                <Controller
                    name={component._id}
                    control={control}
                    ref={register}
                    rules={{
                        required: {
                            value: component.required,
                            message: `${component.label} is required`
                        },
                        pattern: {
                            value: component.regex ? new RegExp(component.regex) : "",
                            message: `${component.errorText || "Invalid value"} for ${component.label}`
                        }
                    }}
                    defaultValue={component.defaultValue || ""}
                    render={
                        ({ field }) => (
                            <CustomInput
                                component={component}
                                autoFillOnFocus={autoFillOnFocus}
                                errors={errors}
                                field={{
                                    ...field,
                                    ...(handleChange ? {
                                        onChange: (event) => handleChange(event, component?._id || "")
                                    } : {})
                                }}
                                handleDataChange={(...rest) => {
                                    if (component.onChange) {
                                        component.onChange(...rest)
                                    }
                                    else if (field.onChange) {
                                        field.onChange(...rest)
                                    }
                                    else {
                                        // console.log("no onChange")
                                    }
                                }}
                                disabled={(() => {
                                    // console.log("feild",field,component)
                                    if (action == "view") {
                                        return true;
                                    }
                                    if (component.disabled != null) {
                                        return component.disabled
                                    }
                                    return false;
                                    // disabled != null ? disabled : false
                                })()}
                                inputProps={inputProps}
                                // disabled={action === 'view' ? true : (component.disabled)}
                                multiline={multiline != null ? multiline : null}
                            />
                        )
                    }
                />
            </Grid>
            {/* {console.log("errors12",errors)} */}
            {errors && errors[component._id] &&
                <Grid container>
                    <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                        {errors && errors[component._id] ? errors[component._id].message : ""}
                    </Typography>
                </Grid>
            }
        </>
    )
}

export const getInputField = ({ component,componentValues={}, disabled = false, errors = {}, handleChange = () => { } }) => {
    switch (component.type) {
        case "input": return (
            <>
                <TextField
                    required={component.required} size='small' fullWidth disabled={disabled} label={component.label}
                    placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }}
                    onChange={(event) => {
                        handleChange(component, event)
                    }}
                    value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "number": return (
            <>
                <TextField
                    required={component.required} type="number" size='small' fullWidth disabled={disabled}
                    label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }}
                    onChange={(event) => {
                        handleChange(component, event)
                    }}
                    value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "date": return (
            <>
                <DatePicker
                    disabled
                    label={component.label}
                    sx={{ ...inputClasses.shadowField }}
                    slotProps={{ textField: { size: "small", fullWidth: true, required: component.required } }}
                    onChange={(date)=>{
                        handleChange(component,date)
                    }}
                    value={componentValues[component?._id] || ""}
                />                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "textarea": return (
            <>
                <TextField 
                required={component.required} multiline rows={3} size='small' disabled={disabled}
                 fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} 
                 onChange={(event)=>{
                    handleChange(component,event)
                }}
                value={componentValues[component?._id] || ""}
                 />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "searchTags": return (
            <Grid sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormLabel required={component.required} component="legend">{component.label}</FormLabel>
                {
                    component.values && component.values.map((op, i) => {
                        return <FormControlLabel
                            disabled={disabled}
                            control={<Checkbox sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                            value={op.value} key={i} size='small' />}
                            label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                            onChange={(event) => {
                                handleChange(component, event)
                            }}
                            checked={(componentValues[component?._id] || []).includes(op.value)}
                        />
                    })
                }                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
        case "file": return (
            <>
                <TextField 
                required={component.required} type="file" size='small' 
                fullWidth disabled={disabled} label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} 
                onChange={(event) => {
                    handleChange(component, event)
                }}
                value={componentValues[component?._id] || ""}
                />
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>)
        case "select": return (
            <>
                <FormControl required={component.required} fullWidth size='small' disabled={disabled}>
                    <InputLabel>{component.label}</InputLabel>
                    <Select
                        sx={{ ...inputClasses.shadowField }}
                        label={component.label}
                        onChange={(event) => {
                            handleChange(component, event)
                        }}
                        value={componentValues[component?._id] || ""}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return <MenuItem value={op.value} key={i}>{op.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>            {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>

        )
        case "checkbox": return (
            <Grid  justifyContent={"center"} alignItems="center" sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormLabel required={component.required} component="legend">{component.label}</FormLabel>
                {
                    component.values && component.values.map((op, i) => {
                        return <FormControlLabel 
                            disabled={disabled}
                            control={<Checkbox
                                sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                                value={op.value} key={i} size='small'
                                checked={(componentValues[component?._id] || []).includes(op.value)}
                            />} label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                            onChange={(event) => {
                                handleChange(component, event)
                            }}

                        />
                    })
                }
                {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>
        )
        case "radio": return (
            <Grid sx={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
                <FormControl>
                    <FormLabel required={component.required} id="demo-row-radio-buttons-group-label">{component.label}</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ mt: 1 }}
                        onChange={(event) => {
                            handleChange(component, event)
                        }}
                        value={componentValues[component?._id] || ""}

                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return <FormControlLabel disabled={disabled} control={<Radio sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} value={op.value} key={i} size="small" />} label={op.label} key={i} />
                            })
                        }
                    </RadioGroup>
                </FormControl>
                {errors && errors[component._id] &&
                    <Grid >
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </Grid>

        )
        default:
            <>
                <TextField 
                required={component.required} size='small' fullWidth disabled={disabled} 
                label={component.label} placeholder={component.placeholder} 
                sx={{ ...inputClasses.shadowField }} 
                onChange={(event) => {
                    handleChange(component, event)
                }}
                />
                value={componentValues[component?._id] || ""}
                {errors && errors[component._id] &&
                    <Grid>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors && errors[component._id] ? errors[component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
    }
}

export const getFieldsLayout = ({ fields, disabled = false,componentValues={},errors = {}, handleChange }) => {
    let layout = {
        single: [],
        multi: []
    };
    for (let i = 0; i < fields.length; i++) {
        let component = fields[i]
        if (["input", "number", "date", "searchTags", "file", "select"].indexOf(fields[i].type) >= 0) {

            layout.single.push(
                getInputField({ component, disabled,componentValues, errors, handleChange })
            )
        }
        else {
            layout.multi.push(
                getInputField({ component, disabled,componentValues, errors, handleChange })
            )
        }

    }
    return layout;

}

export const getInputFieldForTableRow = (component, items, index, handleChange, handleFocus, errors, showLabelForMulti, inputProps, disabled) => {


    switch (component?.type) {
        case "input":
        case "number":
        case "textarea":
        case "date": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    <CustomInput
                        component={component}
                        autoFillOnFocus={() => handleFocus(index)}
                        errors={errors[index]}
                        field={{}}
                        // defaultValue={calculateFieldValue({value:items[index][component._id] || ""})}
                        value={items[index][component._id]
                            ? items[index][component._id]
                            : component.type == "checkbox" && [] || ""
                        }
                        onChange={(e) => handleChange(e, index, component._id)}
                        inputProps={inputProps}
                        disabled={(() => {
                            if (disabled == null) {
                                if (component.disabled != null) {
                                    return component.disabled
                                }
                                return false;
                            }
                            return disabled;
                            // disabled != null ? disabled : false
                        })()}
                    />
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )

        case "searchTags": return (
            <TextField onFocus={() => handleFocus(index)} value={items[index][component._id] ? items[index][component._id] : ""} onChange={(e) => handleChange(e, index, component._id)} required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} />
        )
        case "file": return (
            <>
                <TextField
                    size='small'
                    focused
                    fullWidth
                    type={"file"}
                    label={component.label}
                    required={component.required}
                    onFocus={() => handleFocus(index)}
                    onChange={(e) => handleChange(e, index, component._id)}
                    sx={{ ...inputClasses.shadowField }}
                />
                {
                    (items[index][component._id] || [])?.length > 0 && (items[index][component._id] || [])?.at(0)?.name &&
                    <Typography sx={{ ...textClasses.t12n, mt: "5px" }}>Selected File: {(items[index][component._id] || [])?.at(0)?.name}</Typography>
                }
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        case "select":
            return (
                <>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <CustomInput
                            component={component}
                            autoFillOnFocus={() => handleFocus(index)}
                            errors={errors[index]}
                            field={{}}
                            // defaultValue={calculateFieldValue({value:items[index][component._id]})}
                            value={items[index][component._id]
                                ? items[index][component._id]
                                : component.type == "checkbox" && [] || ""
                            }
                            // onChange={(e) => handleChange(e, index, component._id)}
                            handleDataChange={(e) => handleChange(e, index, component._id)}

                            disabled={(() => {
                                if (disabled == null) {
                                    if (component.disabled != null) {
                                        return component.disabled
                                    }
                                    return false;
                                }
                                return disabled;
                                // disabled != null ? disabled : false
                            })()}
                        />

                    </Grid>
                    {
                        <Grid container>
                            <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                                {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                            </Typography>
                        </Grid>
                    }
                </>
            )
        case "checkbox": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    {
                        showLabelForMulti &&
                        <FormLabel required={component.required} sx={{ mr: 1, mt: 1 }}>{component.label}: </FormLabel>
                    }

                    <FormGroup
                        row
                        sx={{ mt: 1 }}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                        control={
                                            <Checkbox
                                                checked={items[index][component._id] && items[index][component._id].includes(op.value)}
                                                onChange={(e) => handleChange({ checked: e.target.checked, value: op.value }, index, component._id)}
                                                size="small"
                                                sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                                            />
                                        }
                                    />
                                )
                            })
                        }
                    </FormGroup>
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }

            </>

        )
        case "radio": return (
            <>
                <Grid container justifyContent={"center"} alignItems="center">
                    {
                        showLabelForMulti &&
                        <FormLabel required={component.required} sx={{ mr: 1 }}>{component.label}: </FormLabel>
                    }
                    <RadioGroup
                        row
                        value={items[index][component._id] ? items[index][component._id] : ""}
                        // defaultValue={calculateFieldValue({value:items[index][component._id] || ""})}
                        onChange={(e) => handleChange(e, index, component._id)}
                    >
                        {
                            component.values && component.values.map((op, i) => {
                                return (
                                    <FormControlLabel
                                        key={i}
                                        label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
                                        control={<Radio sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} value={op.value} size="small" />}
                                    />
                                )
                            })
                        }
                    </RadioGroup>
                </Grid>
                {
                    <Grid container>
                        <Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: 'left', mt: "5px", ml: "5px" }}>
                            {errors[index] && errors[index][component._id] ? errors[index][component._id].message : ""}
                        </Typography>
                    </Grid>
                }
            </>
        )
        default:
            <TextField onFocus={() => handleFocus(index)} value={items[index][component._id] ? items[index][component._id] : ""} onChange={(e) => handleChange(e, index, component._id)} required size='small' fullWidth label={component.label} placeholder={component.placeholder} sx={{ ...inputClasses.shadowField }} />
    }
}

export const getFieldsLayoutForEntry = (fields, register, control, handleFocus, errors, action, handleChange) => {
    let layout = {
        single: [],
        multi: []
    };
    // console.log("fields",fields?.length)
    for (let i = 0; i < fields.length; i++) {
        if (["input", "number", "date", "searchTags", "file", "select"].indexOf(fields[i].type) >= 0) {
            layout.single.push(
                <CustomControlledInput action={action}
                    handleChange={handleChange}
                    register={register} component={fields[i]} control={control} errors={errors} autoFillOnFocus={handleFocus} />
            )
        }
        else {
            layout.multi.push(
                <CustomControlledInput action={action}
                    register={register}
                    handleChange={handleChange}
                    component={fields[i]} control={control} errors={errors} autoFillOnFocus={handleFocus} />
            )
        }

    }
    return layout;

}

export const DataNotFound = ({height='250px', width='250px',label="No Data Found"}) => {
    return <>
        <Box>
            <img src={no_data_found} height={height} width={width} />
            <Typography  sx={{ textAlign: label !== "No Data Found" ? 'center' : '' }}>{label}</Typography>
        </Box>
    </>
}