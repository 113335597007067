import { FETCH_DYNAMIC_PAGE_CONFIG} from "../types"

const INIT_STATE = {
    dynamicPageConfigDetail: {},
}
export default (state = INIT_STATE, action) => {
    const storedInPage = action.payload && action.payload.storedInPage ? action.payload.storedInPage : ""
    switch (action.type) {

        case FETCH_DYNAMIC_PAGE_CONFIG: 
            let newState = {...state}
            newState[storedInPage] = action.payload.data
            return newState
        default:
            return state;
    }
  }