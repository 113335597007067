import React from "react";
import { Cancel } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import theme, { textClasses, buttonClasses } from "../../../static/styles/theme";
import { Grid as VirtualGrid, List, AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";

const CloseDialog = ({
    status,
    asnId,
    closeReason,
    selectedProducts,
    setCloseReason,
    handleClose,
    subTitle,
    selectedEntries,
    handleConfirm,
    handleRemoveSelection,
  }) => {
  
   const orderedQty = selectedEntries.reduce((acc, entry)=>{
    return acc+entry.totalQuantity

   },0)

   const receivedQuantity = selectedEntries.reduce((acc, entry)=>{
    return acc+ entry.receivedQuantity

   },0)

   const remainingQuantity = selectedEntries.reduce((acc, entry)=>{
    return acc + ((entry.totalQuantity ?? 0) - ((entry.receivedQuantity ?? 0)-(entry?.exceedQuantity ?? 0)) - (entry.rejectedQuantity ?? 0) - (entry?.closedQuantity ?? 0))
   },0)

    const numberOfRows = Math.ceil(selectedEntries.length / 4);

  const cellRenderer = ({ columnIndex, rowIndex, style }) => {
    const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
    const item = status === "CloseASNItem" ?  selectedProducts[index] : selectedEntries[index]?.ASN_Id;

    return (
      <div style={{ ...style, padding: "8px" }}>
        {item && (
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              backgroundColor: theme.themeLightOrange,
              border: `1px solid ${theme.themeOrange}`,
              borderRadius: "12px",
              minHeight: "32px",
            }}
          >
            <Grid item>
              {item && item.length > 12 ? (
                <Tooltip arrow placement="top" title={item}>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: theme.themeOrange,
                      textAlign: "start",
                    }}
                  >
                    {item && item.length > 20
                      ? item.substring(0, 20) + "..."
                      : item}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {item}
                </Typography>
              )}
              
            </Grid>
            {handleRemoveSelection && (status === "CloseASNItem" ? selectedProducts.length > 1 : selectedEntries.length > 1) && (
                   <Grid item>
                     <IconButton
                       sx={{ alignContent: "right" }}
                       onClick={() => handleRemoveSelection(status === "CloseASNItem" ? selectedProducts[index] : selectedEntries[index]._id)}
                     >
                       <Cancel sx={{ color: theme.themeOrange }} />
                     </IconButton>
                   </Grid>
                 )}
          </Grid>
        )}
      </div>
    );
  };


    return (
      <Dialog
        open={status != null}
        onClose={handleClose}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"md"}
      >
        <DialogContent>
          <Grid container justifyContent={"center"} alignItems="center">
            <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
              Are you Sure ?
            </Typography>
          </Grid>
          <Grid container justifyContent={"center"} alignItems="center">
            <Typography sx={{ ...textClasses.cardTitle }} data-testid="subTitle">
                {subTitle}
            </Typography>
          </Grid>
          <Grid container spacing={1} mb={2} mt={2}>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      backgroundColor: theme.themeLightOrange,
                      border: `1px solid ${theme.themeOrange}`,
                      borderRadius: "8px",
                      minHeight: "25px",
                    }}
                  >
                    <Grid item sm={10} xs={8}>
                      <Typography
                       data-testid="orderedQuantity"
                        sx={{
                          ...textClasses.normalText,
                          color: theme.themeOrange,
                          textAlign: "start",
                        }}
                      >
                        Ordered QTY : {orderedQty}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
             <Grid item xs={3}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      color: "#3E621E",
                      backgroundColor: "#9CDC9F",
                      border: `1px solid #3E621E`,
                      borderRadius: "8px",
                      minHeight: "25px",
                    }}
                  >
                    <Grid item sm={10} xs={8}>
                      <Typography
                      data-testid="receivedQuantity"
                        sx={{
                          ...textClasses.normalText,
                          color: "#3E621E",
                          textAlign: "start",
                        }}
                      >
                        Received QTY : {receivedQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
               <Grid item xs={3}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      color: "#E9001A",
                      backgroundColor: "#FAB6B6",
                      border: `1px solid #E9001A`,
                      borderRadius: "8px",
                      minHeight: "25px",
                    }}
                  >
                   <Grid item sm={10} xs={8}>
                      <Typography
                      data-testid="remainingQuantity"
                        sx={{
                          ...textClasses.normalText,
                          color: "#E9001A",
                          textAlign: "start",
                        }}
                      >
                        Remaining QTY : {remainingQuantity}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
           
          </Grid>
          {/* {
          status === "CloseASNItem" ? <Grid xs={12} container>
                  <Grid
                  container
                    item
                    xs={4}
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      backgroundColor: theme.themeLightOrange,
                      border: `1px solid ${theme.themeOrange}`,
                      borderRadius: "12px",
                      minHeight: "40px",
                    }}
                  >
                    <Grid item>
                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          color: theme.themeOrange,
                          textAlign: "start",
                        }}
                      >
                        {selectedEntries[0].ASN_Id && selectedEntries[0].ASN_Id.length > 15
                          ? selectedEntries[0].ASN_Id.substring(0, 15) + "..."
                          : selectedEntries[0].ASN_Id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> : */}
                  <Grid>
                      <Typography
                        sx={{
                          ...textClasses.boldText,
                        }}
                      >
                       {status === "CloseASNItem" ? "Selected Product ID(s)" : "Selected ASN ID(s)"}
                      </Typography>
                    </Grid>
                 <Grid container sx={{ mt: 1 }} spacing={1} data-testid="asnIds">

                <div style={{ width: "100%", height: numberOfRows < 4 ? numberOfRows * 60 : 300 }}>
                  {
                    <AutoSizer>
                      {({ width, height }) => {
                        return (
                          <FixedSizeGrid
                            columnCount={4} // Number of columns
                            columnWidth={200} // Width of each column
                            height={numberOfRows < 4 ? numberOfRows * 60 : 300} // Total height of the grid
                            rowCount={numberOfRows}
                            rowHeight={60} // Height of each row
                            width={width} // Total width of the grid
                          >
                            {cellRenderer}
                          </FixedSizeGrid>
                        );
                      }}
                    </AutoSizer>
                  }
                </div>
       </Grid>
       {/* } */}
         
          <Grid container direction={"row"} sx={{ mt: 1 }}>
            <Typography sx={{ ...textClasses.boldText }}>Reason*</Typography>
            <TextField
              data-testid="reason"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              value={closeReason}
              onChange={(e) => setCloseReason(e.target.value)}
            />
          </Grid>
          <Grid container sx={{ p: 1, mt: 3 }}>
            <Button
             data-testid="cancelButton"
              variant="outlined"
              size="small"
              sx={{
                ...buttonClasses.lynkitBlackFill,
                height: "40px",
                minWidth: "150px",
              }}
              onClick={handleClose}
            >
              No, Cancel
            </Button>
            <Button
             data-testid="confirmButton"
              variant="contained"
              size="small"
              sx={{
                ml: 1,
                ...buttonClasses.small,
                backgroundColor: theme.themeOrange,
                minWidth: "150px",
              }}
              disabled={selectedEntries.length <= 0}
              onClick={handleConfirm}
            >
              Yes, Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  export default CloseDialog;