import React from "react";
import { Typography, Grid, Button, FormControl, OutlinedInput, Card } from "@mui/material"
import SelectImage from "../../../static/images/salesOrder/Done-rafiki.svg"
import theme, {
    textClasses,
    cardClasses,
 
} from "../../../static/styles/theme";

 

const ExternalFormCompletion = () => {

    return (<Grid container justifyContent="center" alignItems="center" height="100vh" backgroundColor= "#F77200" >
        <Card item sx={{ p: 3, ...cardClasses.basic, minWidth: "64vw", minHeight: "20vh" }} className="mui-custom-card">
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={SelectImage} width="450" height="300" />
            </Grid>
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ ...textClasses.t16n, textAlign: "center", mt: 1, fontWeight: '600' }}>
                    Sales Order Form has been Successfully Submitted.
                </Typography>
            </Grid>
        </Card>
    </Grid>)
}

export default ExternalFormCompletion;