import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_CONNECTED_SKU_DETAIL
  } from "../types";
  import axios from "../../services/Api";
  import { showToast } from "../../services/functions";
export const getConnectedSKUComponentDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('inventory/getItemMasterComponentsDetail', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success){
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_CONNECTED_SKU_DETAIL, payload: {data: data?.data || {}, count: data?.count || 0}})
            } 
            else{
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_CONNECTED_SKU_DETAIL, payload: {data: {}, count: 0}})
            }

            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(cb){
                cb({success:false, message: error.message})
            }
        });
    }
};