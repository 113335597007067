import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Checkbox,
  DialogActions,
  Button,
} from "@mui/material";
import theme, {
  textClasses,
  inputClasses,
  buttonClasses,
} from "../../../static/styles/theme";
import GenericCollapsableItemList from "../../Components/Generic/GenericCollapsableItemList";
import CloseIcon from "@mui/icons-material/Close";
import { Clear, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getGridBlockData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import GenericLoader from "../../Components/Generic/GenericLoader";


import { useDebounce } from "../../../utils/hooks";
const entityTypeOptions = [
  { label: "Package Only", value: "packageOnly" },
  { label: "Shipment Only", value: "shipmentOnly" },
  { label: "Item Only", value: "itemOnly" },
];

const SelectInventories = ({
  open,
  handleRemoveSelectedObject,
  cell,
  onClose,
  selectedItems,
  setSelectedItems,
  onSubmit,
  warehouse,
  floor,
  category,
  jobType
}) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ limit: 100, page: 1 });
  const [gridData, setGridData] = useState([]);
  const [loader, setLoader] = useState(false);

  const gridBlockData = useSelector(
    (state) => state.warehouseData.gridBlockDataByLocation?.data
  );
  const avaialableInventoryData = useSelector(
    (state) => state.warehouseData.gridBlockDataByLocation?.availableInventory
  );

  const gridDataCount = useSelector(
    (state) => state.warehouseData.gridBlockDataByLocation?.count
  );
  const debouncedEntityId = useDebounce(filter?.entityID, 500)
  useEffect(() => {
    const cellIds = cell.map(({ value }) => value);

    const payload = {
      warehouseId: warehouse?.value,
      id: cellIds,
      isForJob: true,
      jobType: jobType,
      ...filter,
      fetchBasedOn: category == "skuWise" ? "SKU" : "Cell",
      pickUpAllowed: [
        "changeLocation",
        // "inventoryAudit",
        // "inHouseQualityCheck",
        // "inHouseQualityCheck"
      ].includes(jobType) || "all"
    };
    if(category != "skuWise"){
      payload["floorId"]=floor?.value
    }
   
    if (open) {
      setLoader(true);
      dispatch(getGridBlockData(payload, () => {
        setLoader(false);
      }));
    }
  }, [cell, warehouse, open, floor, dispatch, filter?.page,filter?.limit,filter?.entityType,filter?.gateInTime,debouncedEntityId]);

  useEffect(() => {
    if (gridBlockData) {
      setGridData(gridBlockData);
    }
  }, [gridBlockData]);

  const handleCheckChange = useCallback(
    (e, item) => {
      item["selectedQTY"] = item.availableQty || 0;
      item["count"] = item.availableQty || 0;
      if (e.target.checked) {
        setSelectedItems((prevItems) => [item, ...prevItems]);
      } else {
        setSelectedItems((prevItems) => {
          return prevItems.filter((prevItem) => prevItem._id !== item._id);
        });
      }
    },
    [setSelectedItems]
  );

  const handleCheckAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedItems(gridData?.map(dl=>({...dl,selectedQTY:dl.availableQty || 0,count:dl.availableQty || 0})));
      } else {
        setSelectedItems([]);
      }
    },
    [setSelectedItems, gridData]
  );

  const headers = useMemo(() => {
    return [
      <Checkbox
        size="small"
        onChange={handleCheckAll}
        checked={
          selectedItems.length !== 0 && gridData.length === selectedItems.length
        }
      />,
      "Entity ID",
      "Entity Name",
      "Entity Type",
      "Available QTY",
      "Location",
      "Gate-InTime",
    ];
  }, [selectedItems, handleCheckAll]);

  const handleClearFilter = (type) => {
    if (type === "all") {
      const { gateInTime, entityID, entityType, ...rest } = filter;
      setFilter({ ...rest, page: 1, limit: 25 });
    } else if (type === "gateInTime") {
      if (filter?.gateInTime) {
        const { gateInTime, ...rest } = filter;
        setFilter({ ...rest, page: 1, limit: 25 });
      }
    } else if (type === "entityId") {
      if (filter?.entityID) {
        const { entityID, ...rest } = filter;
        setFilter({ ...rest, page: 1, limit: 25 });
      }
    }
  };

  const rowData = gridData?.map((item, i) => {
    const rows = [];
    let entityId, entityType, entityName;
    if (item?.itemId) {
      entityId = item?.itemId;
      entityType = "Item";
      entityName = item?.SKU_Number;
    } else if (item?.packageId) {
      entityId = item?.packageId;
      entityType = "Package";
      entityName = item?.packageName;
    } else if (item?.shipmentId) {
      entityId = item?.shipmentId;
      entityType = "Shipment";
      entityName = "";
    }

    const getLocation = () => {
      let location;
      if (item?.cellName) {
        location = `${item.cellName} ${
          item?.rackName ? `- ${item.rackName}` : ""
        }`;
      }
      return location;
    };

    rows.push(
      <Checkbox
        size="small"
        checked={
          selectedItems.length &&
          !!selectedItems?.find(
            (selectedItem) => selectedItem._id === item?._id
          )
        }
        onChange={(e) => handleCheckChange(e, item)}
      />
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {entityId}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>{entityName}</Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {entityType}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {item.availableQty}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
        {getLocation()}
      </Typography>
    );
    rows.push(
      <Typography sx={{ ...textClasses.normalText }}>
         {moment(item.gateInTime).format("DD MMM YYYY hh:mm A")}
      </Typography>
    );

    return rows;
  });

  const handleSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onClose, onSubmit]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedItems([]);
  }, [onClose, setSelectedItems]);

  const handleFilterChange = useCallback(
    (e, filterType) => {
      if (filterType === "entityId") {
        if (e.target.value) {
          setFilter((prev) => ({
            ...prev,
            entityID: e.target.value,
            limit: 25,
            page: 1,
          }));
        } else {
          const { entityID, ...rest } = filter;
          setFilter(rest);
        }
      } else if (filterType === "entityType") {
        if (e) {
          setFilter((prev) => ({
            ...prev,
            entityType: e.value,
            limit: 25,
            page: 1,
          }));
        } else {
          const { entityType, ...rest } = filter;
          setFilter(rest);
        }
      } else if (filterType === "gateInTime") {
        if (e) {
          setFilter((prev) => ({ ...prev, gateInTime: e, limit: 25, page: 1 }));
        } else {
          const { gateInTime, ...rest } = filter;
          setFilter(rest);
        }
      }
    },
    [filter]
  );

  const viewClearButton = useCallback(() => {
    if (filter?.entityID || filter?.gateInTime || filter?.entityType) {
      return true;
    }
    return false;
  }, [filter]);

  const renderTableFooter = () => {
    let S = [],
      P = [],
      I = [];

    for (let ele of selectedItems) {
      if (ele?.shipmentId && !S.includes(ele.shipmentId)) {
        S.push(ele.shipmentId);
      }
      if (ele?.packageId && !P.includes(ele.packageId)) {
        P.push(ele.packageId);
      }
      if (ele?.itemId && !I.includes(ele.itemId)) {
        I.push(ele.itemId);
      }
    }

    if (S.length == 0 && P.length == 0 && I.length == 0) return;
    return (
      <Grid container sx={{ flexDirection: "row", mt: 2 }}>
        <Typography sx={{ fontWeight: "800", fontSize: ".9rem", mr: 4 }}>
          Selected QTY :
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Shipments(s) :{S.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Unique Package(s) : {P.length}
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            color: theme.themeOrange,
            fontSize: ".9rem",
            mr: 4,
          }}
        >
          Item(s) : {I.length}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        PaperProps={{ sx: { width: "100%" } }}
        maxWidth={"lg"}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ display: "flex", justifyContent: "space-between", marginRight: "1.5rem", alignItems: "center" }}>
          <Typography sx={{ ...textClasses.cardTitle }}>
            Select Inventories From List
          </Typography>
          <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, ml: 2 }}>
            Available QTY :
            Unique Shipment(s) - {avaialableInventoryData?.uniqueShipment}, Unique Package(s) - {avaialableInventoryData?.uniquePackage}, Unique Item(s) - {avaialableInventoryData?.uniqueItem},
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2}>
              <Typography>Selected Location:</Typography>
            </Grid>
            <Grid item xs={10}>
              <GenericCollapsableItemList
                items={cell}
                limit={3}
                itemSpacing={{ sm: 3, xs: 4 }}
                handleRemoveItem={handleRemoveSelectedObject}
                getContent={(itemList, i) => {
                  let itemObj = itemList[i];
                  return itemObj?.label;
                }}
                sx={{ mb: 3 }}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <OutlinedInput
                  value={filter?.entityID ?? ""}
                  onChange={(e) => handleFilterChange(e, "entityId")}
                  placeholder="Search by Entity Id"
                  sx={{
                    ...inputClasses.filterField,
                    "& input::placeholder": { fontSize: "14px" },
                    "& .MuiSelect-iconOutlined": { display: "" },
                  }}
                  endAdornment={
                    !filter.entityID ? (
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ) : (
                      <IconButton
                        sx={{
                          visibility:
                            filter.entityID != "" ? "visible" : "hidden",
                          fontSize: "14px",
                        }}
                        onClick={() => handleClearFilter("entityId")}
                      >
                        <Clear fontSize="inherit" />
                      </IconButton>
                    )
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Autocomplete
                value={
                  entityTypeOptions.find(
                    (type) => type.value === filter.entityType
                  ) ?? ""
                }
                onChange={(e, option) => {
                  handleFilterChange(option, "entityType");
                }}
                size="small"
                fullWidth
                options={entityTypeOptions}
                sx={{
                  ...inputClasses.filterField,
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                    color: "#455A64",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#455A64", ...textClasses.normalText }}
                    {...params}
                    label="Entity Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <DatePicker
                disableFuture
                defaultValue={null}
                onChange={(d) => handleFilterChange(d, "gateInTime")}
                label="Gate-In Date"
                sx={{ ...inputClasses.filterField }}
                slotProps={{
                  textField: { size: "small" },
                  field: {
                    clearable: true,
                    onClear: () => handleClearFilter("gateInTime"),
                  },
                }}
                format="DD/MM/YYYY"
              />
            </Grid>
            {viewClearButton() && (
              <Grid container item sx={4} sm={2} alignItems={"center"}>
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "none",
                    marginTop: "6px",
                  }}
                  onClick={() => handleClearFilter("all")}
                >
                  Clear Filter(s)
                </Typography>
              </Grid>
            )}
          </Grid>
          {
            loader
              ?
              <Grid container justifyContent={"center"} alignItems="center">
                <GenericLoader data-testid="genericloader" />
              </Grid>
              :
              <>
                <Grid xs={12} mt={2}>
                  <GenericTable
                    header={headers}
                    rows={rowData}
                    pageCount={gridDataCount}
                    pageNumber={filter.page - 1}
                    handleChangePage={(event, pagevalue) => {
                      setFilter((f) => ({
                        ...f,
                        page: pagevalue + 1,
                      }));
                    }}
                    handleChangeRowsPerPage={(event) => {
                      setFilter((f) => ({
                        ...f,
                        page: 1,
                        limit: +event.target.value,
                      }));
                    }}
                    rowsPerPage={filter.limit}
                  />
                </Grid>
                {selectedItems?.length ? renderTableFooter(selectedItems) : ""}
              </>
          }
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "flex-start", pl: 2, pb: 2 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  size: "small",
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "130px",
                }}
                onClick={handleClose}
                disabled={loader}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  minHeight: "40px",
                  backgroundColor: theme.themeOrange,
                  minWidth: "150px",
                  ml: 2,
                }}
                onClick={handleSubmit}
                disabled={selectedItems.length === 0 || loader}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectInventories;
