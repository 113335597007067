import { FETCH_EXTERNAL_FORM_CONFIG,FETCH_E_SALES_ORDER_PROCESSING,FETCH_E_SALES_STATUS_COUNT,FETCH_E_SALES_DETAIL } from "../types";

const INIT_STATE ={
    getExternalFormConfig :[],
    allESalesOrder: [],
    totalRows:0,
    ESalesDetail:[],
    ESalesDetailItemsCount:0

}

export default (state=INIT_STATE, action) =>{
    switch(action.type) {
        case FETCH_EXTERNAL_FORM_CONFIG :return {...state, getExternalFormConfig: action.payload}
        case FETCH_E_SALES_ORDER_PROCESSING :return {...state, allESalesOrder: action.payload.rows, totalRows: action.payload.count }
        case FETCH_E_SALES_STATUS_COUNT :return {...state, statusCount: action.payload}
        case FETCH_E_SALES_DETAIL: return {...state, ESalesDetail: action.payload.data, ESalesDetailItemsCount: action.payload.count}

        default: return state
    }
}