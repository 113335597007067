import {
    CHECK_DISTINCT_MASTER_EXIST,
    GET_MAPPED_MASTER_IDS,
    FLUSH_MAPPED_IDS,
    GET_AREA_MAPPING_DATA,
  } from "../types";

const intialState = {
    mappedArea:{},
    mappedAnalytics:[],
    distinctMasters: [],
    mappedMasterIds: [],
    areaMappingDetail: [],
    mappedAreaForView: [],
};


const areaMappingReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_MAPPED_AREA":
            return { ...state, mappedArea:payload }
        case "GET_MAPPED_AREA_FOR_VIEW" : 
        return {...state, mappedAreaForView: payload}
        case GET_AREA_MAPPING_DATA:
            return { ...state, areaMappingDetail: payload }
        
        case "GET_MAPPED_ANALYTICS":
            return { ...state, mappedAnalytics:payload }
        case FLUSH_MAPPED_IDS: 
        return {
            ...state, mappedMasterIds: []
        }
        case CHECK_DISTINCT_MASTER_EXIST : 
        return { ...state, distinctMasters: payload}
        case GET_MAPPED_MASTER_IDS : 
        return { ...state, mappedMasterIds: payload}
        default:
            return state;
    }
}
export default areaMappingReducer;
