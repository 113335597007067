export const inputClasses = {
  textField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: "1px solid #D9D9D9",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
  },
  shadowField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: "#D9D9D9",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
  },
  themeBoundary: {
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiFormLabel-root": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
  },
  filterField: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: "1px solid #D9D9D9",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    color: "#455A64",
    "& input::placeholder": {
      fontSize: "14px",
    },
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F77200",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#F77200",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#F77200",
      borderWidth: 1,
    },
    "&:focused > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#F77200",
      borderWidth: 1,
    },
  },
};

export const cardClasses = {
  basic: {
    borderRadius: "15px",
    border: "1px solid #0000002B",
    boxShadow: "0px 4px 4px 0px #00000040",
  },
};

export const textClasses = {
  t12n: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t13n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t14n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: 'Nunito'
  },
  normalText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
  },
  boldText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#455A64",
  },
  t15nBold:{
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "24px",
    fontFamily:'Nunito',
  },
  t16n: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    fontFamily: 'Nunito',
  },
  t20n: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  cardTitle: {
    fontSize: "24px",
    lineHeight: "33px",
    fontFamily: 'Nunito',
    fontWeight: "600",
  },
  pageTitle: {
    fontSize: "32px",
    lineHeight: "44px",
    fontWeight: "600",
    color: "#FF7200",
  },
};

export const baseButton = {
  border: "1px solid #D9D9D9",
  borderRadius: "12px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  minHeight: "48px",
  textTransform: "none",
};
  
export const buttonClasses = {
  small: {
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  normal: {
    border: "1px solid #D9D9D9",
    borderRadius: "12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    minHeight: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  medium: {
    boxShadow: "0px 4px 4px 0px #00000040",
    fontWeight: "600",
    fontSize: "14px",
    textTransform: "none",
    color: "#746a63",
    border: "1px solid #D9D9D9",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    lineHeight: "19px",
    minHeight: "37px",
    "&:hover": {
      border: "1px solid #FF7200",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  orange: {
    ...baseButton,
    backgroundColor: "#FF7200",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
    "&:disabled": {
      border: 0,
    }
  },
  orange: {
    ...baseButton,
    backgroundColor: "#FF7200",
    color: "white",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
    },
    "&:disabled": {
      border: 0,
    }
  },
  smallOrange: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "27px",
    maxHeight: "24px",
    maxWidth: "24px",
    textTransform: "none",
    backgroundColor: "#FF7200",
    color: "white",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
    "&:disabled": {
      border: 0,
    }
  },
  outlined: {
    border: "1px solid #FF7200",
    color: "#FF7200",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    textTransform: "none",
    minHeight: "40px",
    "&:hover": {
      border: "1px solid #FF7200",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&:disabled": {
      border: 0,
    }
  },
  lynkitOrangeEmpty: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#FF7200",
    border: `1px solid #FF7200`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF7200",
      border: `1px solid #FF7200`,
      color: "#fff",
    },
    "&:disabled": {
      backgroundColor: "grey",
      // border: 0,
    }
  },
  lynkitOrangeFill: {
    backgroundColor: "#FF7200",
    borderRadius: "8px",
    border: `1px solid #FF7200`,
    // border: `1px solid #fff`,
    textTransform: "none",
    color: "#fff",
    // "&:hover": {
    //   backgroundColor: "#FF7200",
    //   border: `1px solid #FF7200`,
    //   // color: "#FF7200",
    // },
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid #FF7200`,
      color: "#FF7200",
    },
    "&:disabled": {
      backgroundColor: "grey",
      // border: 0,
    }
  },
  lynkitBlackEmpty: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#000",
    border: `1px solid #000`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000",
      border: `1px solid #000`,
      color: "#fff",
    },
    "&:disabled": {
      backgroundColor: "grey",
      // border: 0,
    }
  },
  lynkitBlackFill: {
    backgroundColor: "#000",
    borderRadius: "8px",
    color: "#ffffff",
    border: `1px solid #000`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid #000`,
      color: "#000",
    },
    "&:disabled": {
      backgroundColor: "grey",
      // border: 0,
    }
  },
  viewFile: {
    ...textClasses.t14n,
    textDecoration: "underline",
    color: "#FF7200",
  },
};

export default {
  themeOrange: "#FF7200",
  themeLightOrange: "#f3d7c0",
  themeOrangeShadow: "#c95c03",
  themeBlack: "#000000",
  themeGray: "#D9D9D9",
  themeGreen: "#3E621E",
  themeRed: "#FF0101",
  themeError: "#d32f2f",
  themeWhite: "white",
  themeYellow: "#FFFF00",
  themeText: "#3b3434de",
};
