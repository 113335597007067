import React, {useState, useEffect, useCallback} from 'react'
import { 
    FormControl, Grid, OutlinedInput, Typography, Checkbox, ButtonGroup, Button, Card, CardHeader,
     CardContent, IconButton, Menu, MenuItem, Autocomplete, TextField, InputAdornment,Dialog, DialogContent
     } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../../../../static/styles/theme'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import GenericTable from '../../../../../Components/Generic/GenericTable'
import { Add, ArrowDownward, Clear, Close, KeyboardArrowDown, Remove, RestaurantMenuRounded, Search, SellOutlined } from '@mui/icons-material'
import { getCamelCaseText, showToast,dateTimeFormater } from '../../../../../../services/functions'
import FileModal from '../../../../EntityMaster/FileModal'
import { fetchFile, getAsnDetails,getReceivingConfig } from '../../../../../../redux/actions'
import { useDebounce } from '../../../../../../utils/hooks'
import { selectStylesOverride } from '../../../../../../utils/util'
import GenericLoader from '../../../../../Components/Generic/GenericLoader'
import AsnPackageTableDialog from './AsnPackageTableDialog'
import AsnPackageItemTableSection from './AsnPackageItemTableSection';


export default function AsnSection({
    template, asn, asnTemplateHeader, spiType, objects, setObjects,inventorySettings, receivingMethod,
    receivingTypeOptions=[],selectedType,setSelectedType,openAsnPackageTable,setOpenAsnPackageTable,
    receivingConfig={}
}) {
    

    const dispatch = useDispatch();
    const [selectedAsnItems, setSelectedAsnItems] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [showItemRow, setShowItemRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [loader, setLoader] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const asnDetailItemsCount = useSelector(state => state.asnEntry.asnDetailItemsCount);

   

    const [selectionConfig, setSelectionConfig] = useState({
        "pkg" : {
            "mutliple" : false,
           
        },
        "item" : {
            "mutliple" : false
        },
        "receivingTypeVisibility":false
    })

    const multiSelectFlag = (() => {
        if(spiType == "shipmentPackageOnly") return selectionConfig?.pkg?.mutliple;
        else if(spiType == "shipmentItemOnly" || spiType == "SPI") return selectionConfig?.item?.mutliple;
        return false
    })()

    const [filters, setFilters] = useState({
        entityId: "",
        status: null,
        entityType: null,
        orderedAt: null,
    })
    const debouncedEntityId = useDebounce(filters.entityId, 500);


    const resetFilters = (key) => {
        if(!key || key == ""){
            setFilters({
                entityID:"",
                status: null,
                entityType: null,
                orderedAt: null,
            })
        }
        else{
            let old = {...filters}
            old[key] = "";
            setFilters({
                ...old,
            })
        }
        setIsDirty(true);
        setPage(0)
        setLimit(10);
        
    }

    const handleFilterChange = (e, key) => {
        let oldFilters = {...filters};
        if(key == "entityId"){
            oldFilters[key] = e.target.value;
        }
        else{
            oldFilters[key] = e
        }
        setFilters(oldFilters);
        setIsDirty(true);
        setPage(0);
        setLimit(10);

    }

    const handleRowsPerChange = (e) => {
        setIsDirty(true);
        setLimit(e.target.value);
        setPage(0);
    }
    
    const fetchASNItem = () => {
        setLoader(true);
        let payload = {
            ASN_ID: asn.ASN_Id,
            page: page + 1,
            limit: limit,
            filterTable: true
        }

        if (filters.entityId) {
            payload["entity_ID"] = filters.entityId
        }
        if (filters.status) {
            payload["status"] = filters.status.value
        }
        if (filters.orderedAt) {
            payload["submissionDate"] = filters.orderedAt
        }
        payload["projection"] = {
            "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "product_ID": 0, "ASN_ItemId": 0,
            "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
        }
        payload["shipmentProjection"] = {
            "closed_by": 0, "isCloseforceFully": 0, "received_by": 0, "template_id": 0, "purchaseOrderID": 0, "product_ID": 0, "ASN_ItemId": 0,
            "base": 0, "created_by": 0, "savingMethod": 0, "itemMasterId": 0, "__v": 0
        }
        dispatch(getAsnDetails(payload, (data) => {
            setLoader(false)
        }))
    }


    const isFilterApplied = (() => {
        if(filters.entityId != "" || filters.status != null || filters.orderedAt != null){
            return true;
        }
        return false;
    })();

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    const handleCheckAll = (e) => {
        let check=e?.target?.checked || false
        if (check) {
            let tempSelected=[]
            for(let it of (asn?.itemDetail || [])){
                if(asn?.status=="received") continue
                let itemAdded = getSelectedItemCount(it?.formData?.product_ID);
                let count=it.totalQuantity - it.receivedQuantity - it.rejectedQuantity - itemAdded
                if(count>0){
                    let newI = {
                        ...it, 
                        count:count,
                        maxCount:count,
                        product_ID:it?.formData?.product_ID ,
                        UOM:it?.formData?.UOM ,
                    }
                    tempSelected.push(newI)
                }

            }
            setSelectedAsnItems([...tempSelected])
        }
        else {
            setSelectedAsnItems([]);
        }
    }

    const handleItemCountChange = (e, id, limit) => {
        let oldEntries = [...selectedAsnItems];
        let index = oldEntries.map(o => o._id).indexOf(id);
        ////("E Value", e);
        if(e == -1){
            if(selectedType?.value !== "exceedQuantity"){
                if(oldEntries[index].count >= 2){
                    oldEntries[index].count = oldEntries[index].count - 1
                }
            }
        }
        else if(e == 1){
            if(selectedType?.value === "lessQuantity" || selectedType?.value === "availableQuantity"){
                if(oldEntries[index].count < limit){
                    oldEntries[index].count = oldEntries[index].count + 1
                }
            }else if(selectedType?.value === "exceedQuantity"){
                oldEntries[index].count = oldEntries[index].count + 1
            } 
        }
        else{
            // if(Number(e.target.value) != 0){
                
            // }
            if(selectedType?.value === "availableQuantity" || selectedType?.value === "lessQuantity"){
                    oldEntries[index].count = Number(e.target.value) >= limit ? limit : Number(e.target.value)  
            }else{
                oldEntries[index].count = Number(e?.target.value)
            } 
        }
        
        setSelectedAsnItems(oldEntries);

    }

    const getSelectedItemCount = (sku_number) => {
        if (spiType == "SPI") {
            let ob = objects.map(o => {
                let items = [...o.itemDetail];
                let itemCount = items.filter(it => it.SKU_Number == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
                return itemCount;
            })
            return ob.reduce((a, b) => a+b, 0);
        }
        else if(spiType == "shipmentPackageOnly"){
            let packageCount = objects.filter(it => it.packageName == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
            return packageCount;
        }
        else if(spiType == "shipmentItemOnly"){
            let itemCount = objects.filter(it => it.SKU_Number == sku_number).map(it => it.count).reduce((a, b) => a+b, 0);
            return itemCount;
        }
        return 0;
    }

    const isItemNotDisable = (item) => {
        if(item.status == "received") return false;
        const addedInObjects = getSelectedItemCount(item.formData.product_ID);
        if(addedInObjects >= item.totalQuantity) return false;
        return true;
    }

    const isAllItemAdded = (() => {
        let rows = asn?.itemDetail?.map((item, i) => {
            const quantity = item.totalQuantity - Number(item?.receivedQuantity || 0)- Number(item?.rejectedQuantity|| 0)+Number(item?.exceedQuantity || 0);
            const addedInObjects = getSelectedItemCount(item?.formData?.product_ID);  
            const remaining = quantity - addedInObjects;
            // console.log("Sum",remaining,addedInObjects);
            return remaining
        })
        let sum = rows.reduce((a, b) => a + b, 0);
        // console.log("Sum", sum,rows);
        return sum <= 0;
    })();

    const handleChangeReceivingType = useCallback((option, id, limit)=>{
        setSelectedType(option)
        if(option?.value !== "exceedQuantity"){
            let oldEntries = [...selectedAsnItems];
            let index = oldEntries.map(o => o._id).indexOf(id);
            if(oldEntries[index].count > limit){
                oldEntries[index].count = limit
            }
        }
    },[setSelectedType, selectedAsnItems])
      
    let remainingItemForSelection=0

    const asnItemRows = (asn?.itemDetail || []).map((item, i) => {
        const quantity = item.totalQuantity - Number(item?.receivedQuantity || 0)- Number(item?.rejectedQuantity|| 0)+Number(item?.exceedQuantity || 0);
        const addedInObjects = getSelectedItemCount(item?.formData?.product_ID);  
        let selectedIndex = selectedAsnItems.findIndex(dl=>dl._id==item._id);
        let selectedObj = selectedIndex >= 0 ? selectedAsnItems[selectedIndex] : null;
        const remaining = quantity - addedInObjects;

        if(remaining>0){
            remainingItemForSelection+=1
        }

        const disabledType = objects?.length && objects.reduce((acc, selectedItem)=>{
            let id;
            if(spiType === "SPI"){
                 const selected = selectedItem?.itemDetail?.filter((selectedItem)=>{
                return selectedItem?.ASN_ComponentId === item?._id
            })
            if(selected.length){
                selected.map((item)=> acc.push(item?.ASN_ComponentId))
            }
            }else{
                // console.log(selectedItem?.ASN_ComponentId, "selectedItem?.ASN_ComponentId", item?._id, selectedItem?.ASN_ComponentId ===  item?._id)
                id = selectedItem?.ASN_ComponentId ===  item?._id ? item?._id : undefined
                acc.push(id)
            }
            return acc
        },[])

        const isDisableReceivingType = disabledType?.length ? disabledType?.filter((item)=> item !==undefined) : []
        
        let row = [];
        row.push(
            (item.status != "received" && remaining > 0) &&
            <Checkbox
                size="small"
                checked={selectedIndex >= 0}
                disabled={
                    (() => {
                        if(multiSelectFlag) return false;
                        return !(selectedAsnItems.length >= 1 && selectedAsnItems[0]._id == item._id || selectedAsnItems.length < 1)
                    })()
                }
                // disabled={!(selectedAsnItems.length == 1 && selectedAsnItems[0]._id == item._id || selectedAsnItems.length < 1)}
                onChange={(e) => handleCheckChange(e, item)}
            />
        )
        row.push(<Typography sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={() => setShowItemRow(item)}>{item.formData.product_ID}</Typography>)
        row.push("SKU")
        row.push(
            <Typography sx={{...textClasses.normalText, color: theme.themeOrange}}>
                {
                    `${item.totalQuantity} ${item.formData.UOM}`
                }
            </Typography>
        )
        row.push(
            <Typography sx={{...textClasses.normalText, color: theme.themeOrange}}>
                {
                    `${quantity>0?quantity:0 } ${item?.formData?.UOM || ""}`
                }
            </Typography>
        )
        row.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                {`${selectedObj?.count > 0 ? remaining - Number(selectedObj?.count || 0) : remaining} ${item?.formData?.UOM || ""}`}
            </Typography>
                //    selectedType?.value !== "exceedQuantity" 
                //    ?`${selectedIndex >= 0 && (remaining - selectedObj.count) > 0 ? '-' : ''}${remaining - (selectedIndex >= 0 ? selectedObj.count : 0)} ${item.formData.UOM}`
                //    :  `${selectedIndex >= 0 && (remaining - selectedObj.count) < 0 ? '+' : ''}${ ((selectedIndex >= 0 && selectedObj.count > 0 )  ? (selectedObj.count - remaining) : 0)} ${item.formData.UOM}`
        )
        if (selectionConfig?.receivingTypeVisibility?.visible) {
            row.push(
                <Autocomplete
                size="small"
                defaultValue={receivingTypeOptions[0]}
                disableClearable
                disabled={selectedAsnItems.filter(s => s._id == item._id).length == 0 || isDisableReceivingType?.includes(item?._id) }
                options={receivingTypeOptions}
                onChange={(e, option) => {
                    handleChangeReceivingType(option, item._id, remaining)
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: "12px",
                      ...selectStylesOverride,
                    }}
                    {...params}
                    label={
                      <Typography sx={{ fontSize: "14px" }}>
                        Type
                      </Typography>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                    //   color: option.isCreateOption ? "#ff7200" : "#727376",
                      fontSize: "14px",
                    }}
                  >
                    {option.label}
                  </li>
                )}
              />
            )
        }
        row.push(
            item.status != "received" 
            ?
            <ButtonGroup size='small' sx={{minWidth: "24px", ".MuiButtonGroup-grouped": {minWidth: "25px"}}}>
                <Button key="one" size='small' sx={{...buttonClasses.smallOrange, backgroundColor: (selectedIndex < 0 || selectedType === null)  ? "#D9D9D9" : theme.themeOrange, fontSize: "12px"}} disabled={selectedIndex < 0 || selectedType === null} onClick={() => handleItemCountChange(-1, item._id, remaining)}>
                    <Remove fontSize='inherit'/>
                </Button>
                <input
                    type="number"
                    style={{border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center"}}
                    value={selectedObj && selectedObj.count || null}
                    onChange={(e) => handleItemCountChange(e, item._id, remaining)}
                    disabled={selectedAsnItems.filter(s => s._id == item._id).length == 0 || selectedType === null}
                    onWheel = {(event) => event.target.blur()}
                    onKeyDown={(e) => {
                        if(['e', 'E', '+', '-'].includes(e.key)){
                            e.preventDefault();
                        }
                        else if(e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)){
                            e.preventDefault();
                        }
                    }}
                />
                <Button key="three" size='small' sx={{...buttonClasses.smallOrange, backgroundColor: (selectedIndex < 0 || selectedType === null) ? "#D9D9D9" : theme.themeOrange, fontSize: "12px"}} disabled={selectedIndex < 0 || selectedType === null} onClick={() => handleItemCountChange(1, item._id, remaining)}>
                    <Add fontSize='inherit'/>
                </Button>
            </ButtonGroup>
            :
            "NA"
        )
        const statusColor = {
            partialreceived: "#3E621E",
            received: "#3E621E",
            rejected: "#E9001A",
            drafted: "#FF0101",
            incoming: "#FF7200",
            default: "#000"
        }
        let statusText;
        if(item.status == "incoming"){
            statusText = "Incoming"
        }
        else if(item.status == "rejected"){
            statusText = `Rejected (${item.rejectedQuantity})`
        }
        else if(item.status == "partialReceived"){
            statusText = `Partial Received (${item.receivedQuantity})`
        }
        else if(item.status == "received"){
            statusText = `Received (${item.receivedQuantity})`
        }
        row.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[item.status ? item.status.toLowerCase() : "default"] }}>
                {statusText}
            </Typography>
        );
        row.push(dateTimeFormater(item.createdAt,"DD MMM YYYY hh:mm A"))
        row.push(<Typography sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown/></Typography>)
        return row;
    })

    let asnItemHeader = asn ? [
        (multiSelectFlag && !isAllItemAdded && remainingItemForSelection>0)
            ? <Checkbox
                data-testid="checkbox"
                checked={asn.itemDetail.length != 0 && selectedAsnItems?.length>0 && selectedAsnItems.length == remainingItemForSelection}
                onChange={handleCheckAll} size='small'
            /> : null,
        "Entity ID", "Entity Type", "Order QTY", "Available QTY", "Remaining", "Receiving QTY", "Status", "Ordered At", "Action"] : []
    if (asnItemHeader?.length > 0 && selectionConfig?.receivingTypeVisibility?.visible) {
        asnItemHeader.splice(asnItemHeader.length-4, 0,"Receiving Type")
    }

    const handleAddSelection = (obj) => {
        setSelectedAsnItems([...selectedAsnItems, {
            ...obj,
            maxCount:obj?.count || 0,
            product_ID:obj?.formData?.product_ID,
            UOM:obj?.formData?.UOM,
        }]);
    }

    const handleRemoveSelection = (id) => {
        let oldEntries = [...selectedAsnItems];
        const i = oldEntries.map(o => o._id).indexOf(id);
        if (i >= 0) {
            oldEntries.splice(i, 1);
            setSelectedAsnItems(oldEntries);
            if(oldEntries.length == 0){
                handleCloseAsnPackageTable()
            }
        }

    }

    const handleCheckChange = (event, row) => {
        
        if (event.target.checked) {
            const itemAdded = getSelectedItemCount(row.formData.product_ID);
            handleAddSelection({...row, count: row.totalQuantity - row.receivedQuantity - row.rejectedQuantity - itemAdded});
        }
        else {
            handleRemoveSelection(row._id);
        }
    }    
    
    const handleOpenAsnPackageTable = (type) => {
        const isApproved = handleReceivingQuantity()
        if (isApproved &&  selectedAsnItems?.at(0)) {
            let isZeroCountExist=selectedAsnItems.findIndex(dl=>!dl.count || dl?.count<=0)
            if(isZeroCountExist>=0){
                showToast(`Selected Quantity cannot be less than 1 for ${selectedAsnItems[isZeroCountExist]?.product_ID}`, true)
                return;
            }
            setOpenAsnPackageTable(type)
        }
    }

    const handleCloseAsnPackageTable = () => {
        setOpenAsnPackageTable("")
    }

    const handleReceivingQuantity = useCallback(()=>{
        let id;
        if(selectedType?.value === "exceedQuantity"){
             id = selectedAsnItems.find((item)=> item.count <= item.totalQuantity)
            if(id){
                showToast(`Selected Quantity cannot be less than ${id?.totalQuantity} for ${id?.product_ID}`, true)
            }
           
        }else if(selectedType?.value === "lessQuantity"){
             id = selectedAsnItems.find((item)=> item.count >= item.totalQuantity)
            if(id){
              
                showToast(`Selected Quantity cannot be greater than or equal to available quantity for selected Receiving Type for ${id?.product_ID}`, true)
            }
        }else if(selectedType?.value === "availableQuantity"){
            id = selectedAsnItems.find((item)=> item.count > item.totalQuantity)
            if(id){
              
                showToast(`Selected Quantity cannot be greater than available quantity for selected Receiving Type for ${id?.product_ID}`, true)
            }
        }
        if(id){
            return false
        }
        return true
    },[selectedAsnItems, selectedType])


    const handleSubmitPackages = (packagesToAdd,cb) => {
        // console.log("Adding packages", packagesToAdd);
        setObjects([...objects, ...packagesToAdd])
        setSelectedAsnItems([]);
        cb();
    }

    useEffect(() => {
        fetchASNItem()
    }, [page, limit, filters.orderedAt,filters.status])
    
    useEffect(() => {
        if(debouncedEntityId){
            fetchASNItem()
        }
    }, [debouncedEntityId])
    useEffect(() => {
        // dispatch(getReceivingConfig({configType:"asnReceiving"},(data)=>{
        //     setSelectionConfig(data || {})
        // }))
        setSelectionConfig(Object.keys(receivingConfig)?.length>0 ?receivingConfig : {})
        setSelectedAsnItems([]);
    }, [])

    return (
        <Grid container sx={{p: 2}}>
            <Grid container>
                <Typography sx={{...textClasses.boldText, textDecoration: "underline"}}>Select Row(s) & Add Receiving Quantity To Receive The Shipment As Package, Pallet Or Loose</Typography>
            </Grid>
            <Grid container spacing={1} sx={{mt: 1}}>
                <Grid item sx={4} sm={2}>
                    <FormControl variant='outlined' size='small' fullWidth>
                        <OutlinedInput
                            
                            value={filters.entityId}
                            onChange={(e) => handleFilterChange(e, "entityId")}
                            placeholder='Search by Entity Id'
                            sx={{ ...inputClasses.filterField, "& input::placeholder": { fontSize: "14px" } ,"& .MuiSelect-iconOutlined": {display: filters.entityId != "" ? 'none': ''}}}
                            endAdornment={
                                filters.entityId == ""
                                ?
                                    <InputAdornment position='end'><Search fontSize='small' /></InputAdornment>
                                :
                                    <IconButton sx={{visibility: filters.entityId != "" ? "visible": "hidden", fontSize: "14px"}} onClick={() => resetFilters("entityId")}><Clear fontSize='inherit'/></IconButton>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4} sm={2}>
                    <Autocomplete
                        data-testid="status"
                        value={filters.status}
                        onChange={(e, option) => handleFilterChange(option, "status")}
                        size='small'
                        fullWidth
                        options={[{label: "Received", value: "received"}, {label: "Incoming", value: "incoming"}]}
                        sx={{...inputClasses.filterField, ".MuiInputBase-input": {
                            fontSize: "15px",
                            color: "#455A64"
                        }}}
                        renderInput={(params) => <TextField sx={{color: "#455A64", ...textClasses.normalText}} {...params} label="Status" />}
                    />
                </Grid>
                
                <Grid item sx={4} sm={2}>
                    <DatePicker
                        data-testid="receivedAt"
                        value={filters.orderedAt}
                        onChange={(d) => handleFilterChange(d, "orderedAt")}
                        label="Ordered At"
                        sx={{...inputClasses.filterField}}
                        slotProps={{textField: {size: "small"}}}
                    />
                </Grid>
                {
                    isFilterApplied && 
                    <Grid container item sx={4} sm={2} alignItems={"center"}>
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}} onClick={() => resetFilters()}>
                            Clear Filter(s)
                        </Typography>
                    </Grid>
                }
                
            </Grid>
            {["package","pallet","bothPackageItem","bothPalletItem"].includes(openAsnPackageTable) && <AsnPackageTableDialog
                open={openAsnPackageTable}
                onClose={handleCloseAsnPackageTable}
                template={template}
                items={selectedAsnItems.filter(it => isItemNotDisable(it))}
                handleSubmitPackages={handleSubmitPackages}
                receivingType={selectedType}
                idSettings={inventorySettings ? inventorySettings : null}
                multiSKUSelected={selectedAsnItems.filter(it => isItemNotDisable(it))?.length>1}
                spiType={spiType}
                openType="add"
                receivingMethod={receivingMethod}
                />}
            {["uniqueItem", "bundleItem"].includes(openAsnPackageTable) &&
                <Dialog
                open={openAsnPackageTable!=""}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"xl"}
                >
                    <DialogContent>
                        <AsnPackageItemTableSection
                            open={openAsnPackageTable}
                            selectedAsnItems={selectedAsnItems.filter(it => isItemNotDisable(it))}
                            onClose={handleCloseAsnPackageTable}
                            template={template}
                            receivingType={selectedType} 
                            idSettings={inventorySettings ? inventorySettings : null}
                            totalItemCount={selectedAsnItems.filter(it => isItemNotDisable(it))?.reduce((a, b) =>Number(a ||  0) + Number(b.count || 0), 0) || 0} 
                            openType="add"
                            itemDetail={selectedAsnItems.filter(it => isItemNotDisable(it))}
                            saveItemHandler={(newItem=[])=>{
                                handleSubmitPackages(newItem, () => {
                                    handleCloseAsnPackageTable()
                                })
                            }}
                            multiSKUSelected={selectedAsnItems.filter(it => isItemNotDisable(it))?.length>1}
                        />
                    </DialogContent>
                </Dialog>
            }
            {
                    selectedAsnItems.length > 0 &&
                    <Grid container sx={{ mt: 2 , textTransform:"none"}}>
                        {
                            ["pallets", "packages"].indexOf(receivingMethod?.value) >= 0 &&
                            <>
                                {
                                    <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                        onClick={() => handleOpenAsnPackageTable(receivingMethod?.value?.split("s")?.at(0))}>Create {getCamelCaseText(receivingMethod.value)}</Button>
                                }
                            </>
                        }
                        {
                            receivingMethod.value == "bothPackageItem" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("package")}>Create Package</Button>
                            </>
                        }
                        {
                            receivingMethod.value == "bothPalletItem" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("package")}>Create Pallet</Button>
                            </>
                        }
                        {
                            receivingMethod.value == "individualItems" &&
                            <>
                                <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("bundleItem")}>Create Items</Button>
                                {selectedAsnItems.length==1 && <Button size='small' variant="outlined" sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                    onClick={() => handleOpenAsnPackageTable("uniqueItem")}>Create Unique Items</Button>}
                            </>
                        }
                    </Grid>
                }
            {
                loader
                ?
                    <Grid container justifyContent={"center"} sx={{m: 2}}>
                        <GenericLoader />
                    </Grid>
                :
                <Grid container sx={{mt: 2}}>
                    <Grid item xs={showItemRow ? 8 : 12}>
                        <GenericTable
                            header={asnItemHeader}
                            rows={asnItemRows}
                            totalRows={asnDetailItemsCount}
                            page={page}
                            limit={limit}
                            setPage={(p) => {setIsDirty(true); setPage(p)}}
                            setLimit={(l) => {setIsDirty(true); setLimit(l)}}
                            handleRowsPerChange={handleRowsPerChange}
                        />
                    </Grid>
                    {
                        showItemRow &&
                        <Grid item xs={4} sx={{p: 1, pt: 0}}>
                            <DetailCard
                                data={showItemRow}
                                templateHeader={asnTemplateHeader}
                                setShowItemRow={setShowItemRow}
                            />
                        </Grid>
                    }
                </Grid>
            }
            {
                actionIndex != null &&
                <Menu
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                >
                    <MenuItem sx={{...textClasses.normalText}}
                        onClick={() => {setShowItemRow(asn?.itemDetail[actionIndex]); handleCloseAction();}}
                    >
                        View Details
                    </MenuItem>

                </Menu>
                }
                
        </Grid>
    )
}


const DetailCard = ({data, templateHeader, setShowItemRow }) => {
    ////("ASN data", data);
    const dispatch = useDispatch();
    
    const DetailRow = ({title, children}) => {
        return (
            <Grid container sx={{mt: 1}}>
                <Grid item sm={4} xs={4} sx={{wordWrap: "break-word", wordBreak:"break-all"}}>
                    <Typography sx={{...textClasses.t12n, color: "#827878"}}>{title}: </Typography>
                </Grid>
                <Grid container item sm={8} xs={8} direction="row" alignItems={"center"} sx={{wordWrap: "break-word", wordBreak:"break-all"}}>
                    {children}
                </Grid>
            </Grid>
        )
    }
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
  
    const displayFile = (fileUrl, fileKey) => {
      const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
      const isValidImageUrl = imageUrlRegex.test(fileKey);
      if (isValidImageUrl) {
        setOpenModal(true);
        setDisplayUrl(fileUrl);
      } else {
        window.open(fileUrl, "_blank");
      }
    };
  
    const handleFile = (fileKey) => {
      setOpenModal(false);
      setDisplayUrl('');
      dispatch(fetchFile({ fileKey }, displayFile));
    };

    return (
        <>
            {
                data && 
            
                <Card sx={{...cardClasses.basic, borderRadius: "8px", border: `1px solid ${theme.themeOrange}`, position: "relative", height: "400px"}}>
                    <CardHeader
                        sx={{height: "46px", backgroundColor: theme.themeOrange}}
                        title={
                            <Typography sx={{...textClasses.boldText, color: "white", fontSize: "16px"}}>
                                <SellOutlined fontSize='inherit' sx={{mr: 1}}/>
                                SKU ID: {data?.formData?.product_ID}
                            </Typography>
                        }
                        action={
                            <IconButton size='small' sx={{p: 0, mb: 1}} onClick={() => setShowItemRow(null)}>
                                <Close sx={{color: "white"}}/>
                            </IconButton>
                        }
                    />
                    <CardContent sx={{minHeight: "400px", maxHeight: "400px", overflowY: "scroll"}}>
                        <Grid container>
                            <Grid container item sm={6} xs={6} alignItems="center">
                                <Typography sx={{...textClasses.t12n, color: "#827878"}}>Ordered Qty</Typography>
                                <Button disabled size='small' sx={{ml: 2, borderRadius: "12px", border: `1px solid ${theme.themeOrange}`, backgroundColor: theme.themeLightOrange, color: `${theme.themeOrange} !important`, textTransform: "none", ...textClasses.t12n, minWidth: "70px"}}>{data.totalQuantity} pcs</Button>
                            </Grid>
                            <Grid container item sm={6} xs={6} alignItems="center">
                                <Typography sx={{...textClasses.t12n, color: "#827878"}}>Received Qty</Typography>
                                <Button disabled size='small' sx={{ml: 2, borderRadius: "12px", border: `1px solid ${theme.themeOrange}`, backgroundColor: theme.themeLightOrange, color: `${theme.themeOrange} !important`, textTransform: "none", ...textClasses.t12n, minWidth: "70px"}}>{data.receivedQuantity} pcs</Button>
                            </Grid>
                        </Grid>
                        
                        {
                            templateHeader.itemHeader && templateHeader.itemHeader.map((head, i) => {
                                return (
                                    <DetailRow title={head.label}>
                                        {/* <Typography sx={{...textClasses.t12n}}>{ data.formData[head.name] }</Typography> */}
                                        <Typography sx={{...textClasses.normalText, ml: 1}}>
                                                {data.formData[head.name] ?
                                                    head.type === "file" ? (
                                                        <div onClick={() => handleFile(data.formData[head.name])}>
                                                          <span
                                                            style={{
                                                              ...buttonClasses.viewFile,
                                                              cursor:"pointer"
                                                            }}
                                                          >
                                                            View File
                                                          </span>
                                                        </div>
                                                      ) : 
                                                    head.type === "date"
                                                        ? dateTimeFormater(data.formData[head.name], head.format || "DD MMM YYYY")
                                                        : data.formData[head.name] : "-"}
                                            </Typography>
                                            <FileModal
                                              openModal={openModal}
                                              displayUrl={displayUrl}
                                              setOpenModal={setOpenModal}
                                              />                  
                                    </DetailRow> 
                                )
                            })
                        }
                        {
                            data.itemMasterDetail && templateHeader && templateHeader.itemMaster && templateHeader.itemMaster.map((head, i) => {
                                return (
                                    <DetailRow title={head.label}>
                                        <Typography sx={{...textClasses.t12n}}>{ data.itemMasterDetail[head.name] }</Typography>
                                    </DetailRow>
                                )
                            })
                        }
                        <DetailRow title="ASN Submission Date">
                            <Typography sx={{...textClasses.t12n}}>{ dateTimeFormater(data.createdAt,"DD MMM YYYY hh:mm A")}</Typography>
                        </DetailRow>
                        <DetailRow title="ASN Received At">
                            <Typography sx={{...textClasses.t12n}}>{dateTimeFormater(data.receivedDate,"DD MMM YYYY hh:mm A")}</Typography>
                        </DetailRow>
                    </CardContent>
                    
                </Card>
               
            }
        </>
    )
}