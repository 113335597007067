import { Grid, Typography, TextField,Divider, Checkbox, FormControl,Autocomplete, Box, Button } from '@mui/material'
import React, { useState, useEffect} from 'react'
import NavigationTwoToneIcon from '@mui/icons-material/NavigationTwoTone';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import theme, { buttonClasses } from '../../../static/styles/theme'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDispatch} from "react-redux";
import { getRouteData } from '../../../redux/actions';
import { showToast } from "../../../services/functions";



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const rows = 10;
const columns = 12
// var input = [
//     [
//         0,
//         2
//     ],
//     [
//         1,
//         2
//     ],
//     [
//         2,
//         2
//     ],
//     [
//         3,
//         2
//     ],
//     [
//         3,
//         3
//     ],
//     [
//         3,
//         4
//     ],
//     [
//         4,
//         4
//     ],
//     [
//         5,
//         4
//     ],
//     [
//         6,
//         4
//     ],
//     [
//         6,
//         3
//     ],
//     [
//         7,
//         3
//     ],
//     [
//         7,
//         4
//     ],
//     [
//         7,
//         5
//     ],
//     [
//         7,
//         6
//     ],

//     [
//         7,
//         7
//     ],
//     [
//         7,
//         8
//     ],
//     [
//         7,
//         9
//     ],
//     [
//         6,
//         9
//     ],
//     [
//         5,
//         9
//     ],
//     [
//         4,
//         9
//     ],
//     [
//         3,
//         9
//     ],
//     [
//         2,
//         9
//     ],
//     [
//         2,
//         10
//     ],
//     [
//         2,
//         11
//     ],
//     [
//         3,
//         11
//     ],
//     [
//         4,
//         11
//     ],
//     [
//         5,
//         11
//     ],
//     [
//         6,
//         11
//     ]
// ]


var top100Films = [];

var rr = [];
export default function Navigation() {
    // const [state, dispatch] = useReducer(reducer, initialState)
    const [condition, setCondition] = useState(true);
    const [tempArr, setTempArr] = useState([])
    const [selectedCells, setSelectedCells] = useState([])
    const [sourceCell, setSourceCell] = useState([])
    const [obstacleCells, setObstacleCells] = useState([])
    const [destinationCell, setDestinationCell] = useState([])
    const [inputs, setInputs] = useState({ typeDropdown: "block", unitDropdown: "feet" });
    const dispatch = useDispatch();
    const [input, setInput] = useState([ ])

    useEffect(() => {
        makeGrid();
    }, [])

    const makeGrid = () => 
    {
        let newArry = []

        if (rows && columns) 
        {
            for (let i = 0; i < rows; i++) 
            {
                for (let j = 0; j < columns; j++) 
                {
                    newArry.push(
                    {
                        "cellId": new Date().getTime() + "" + (i * rows) + "" + j + "",
                        "name": "",
                        "type": "NA",
                        "isRackExist": false,
                        "rowIndex": i,
                        "colIndex": j,
                        "disable": false,
                        "status": "available",
                        "fontColor": "",
                        "bgColor": "",
                    })
                }
            }
        }

        setTempArr(newArry)

        top100Films = newArry
    }

    const updateGrid = (cellId, x, y) => {
        rr.push([x, y])
        let cellSelect = []
        let gridBlock = tempArr.find(el => el.cellId === cellId)

        if (gridBlock) {

            let valid = true
            if (gridBlock.isSelected) {

                valid = true
                let tempIndex = selectedCells.findIndex(el => el.cellId == cellId)
                cellSelect.splice(tempIndex, 1)
                setSelectedCells(cellSelect)
            }
            else {
                // valid = checkSelectionValidity(gridBlock)
                if (valid) {

                    cellSelect.push(gridBlock)
                    setSelectedCells(cellSelect)


                }
            }
            if (valid) {
                gridBlock.isSelected = gridBlock.isSelected ? false : true
                setTempArr([...tempArr])
            }
            // else{
            // }
        }

        ////(rr);

        // let x = tempArr.filter(el => el.isSelected == true);

        // ////(x.map(it=> [it.rowIndex,it.colIndex]));
    }

    function getBlockByIndex(rowIndex, colIndex) {
        return (tempArr.find(el => el.rowIndex == rowIndex && el.colIndex == colIndex))
    }

    const onSourceHandler = (e, o) =>  
    {
        if(o)
        {
            setSourceCell([o.rowIndex,o.colIndex])
        }
        else
        {
            setSourceCell([])
        }
    }
    
    const onObstacleHandler = (e, o) =>  
    {
        if(o)
        {
            var s = o.map(i=> [i.rowIndex,i.colIndex]);
            setObstacleCells(s)
        }
        else
        {
            setObstacleCells([])
        }
    }

    const onDestinationHandler = (e, o) =>  
    {
        if(o)
        {
            setDestinationCell([o.rowIndex,o.colIndex])
        }
        else
        {
            setDestinationCell([]);
        }
    }

    const getAllRoute = () =>{
        // ////(sourceCell, obstacleCells, destinationCell)
        clearRoute();
        
        let obj = 
        {
            "start": sourceCell,
            "end": destinationCell,
            "obstacles": obstacleCells,
            "row": rows,
            "column": columns,
            "API_Path":"getRoute"
        }

        dispatch(getRouteData(obj,({success,message,data})=>{
			showToast(message, !success)
            ////("data",{success,message,data});
			if(success){
                setInput(data);
                setRoute(tempArr,data);
			}
            else{
                setInput([]);
            }
		}));

        // axios.post(`http://3.110.141.249:3200/getRoute`, obj).then((routeData) => 
        // {
        //     if (routeData.data.success) 
        //     {

        //       setInput(routeData.data.data);

        //       setRoute(tempArr);

        //     }
        //     else
        //     {
        //         // dispatch({ type: FETCH_ERROR, payload: routeData.message });
        //     }
        // }).
        // catch( (error) =>
        // {
        //     // dispatch({ type: FETCH_ERROR, payload: error.message });
        //     ////("Error****:", error.message);
        // });
    }

    function setRoute(arr,input_data) {
        input_data.map((parsed, j) => 
        {
            arr.map((raw, i) => 
            {
                if (parsed[0] == raw.rowIndex && parsed[1] == raw.colIndex) 
                {
                    raw.isSelected = true;
                    if (j == 0) 
                    {
                        raw.startPoint = true;
                    }
                    if (j == input_data.length - 1) 
                    {
                        raw.endPoint = true;
                    }
                }
                else{
                //      raw.isSelected = false;
                //      raw.startPoint = false;
                //      raw.endPoint = false;
                }
            })
        })

        ////("arr",{arr});
        setTempArr([...arr])
    }

    function clearRoute()
    {
        var arr = [...tempArr];

        arr.map((item) => 
        {
            item.isSelected = false;    
            item.startPoint = false;    
            item.endPoint = false;    
        })
        ////("clearRoute",{arr});
        setTempArr([...arr])
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={3}>
                <Grid item xs={12} sx={{ p: 1, }}>
                    <Typography sx={{ color: theme.themeOrange }}>
                        Navigation
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} margin={2}>
                    <FormControl size="small" fullWidth >
                        <Autocomplete
                            id="checkboxes-tags-demo"
                            limitTags={2}
                            options={top100Films}
                            disableCloseOnSelect
                            onChange={onSourceHandler}
                            getOptionLabel={(option) => option.rowIndex + "," +
                                option.colIndex}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.rowIndex},{option.colIndex}
                                </li>
                            )}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Source" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} margin={2}>
                    <FormControl size="small" fullWidth >
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            limitTags={2}
                            options={top100Films}
                            disableCloseOnSelect
                            onChange={onObstacleHandler}
                            getOptionLabel={(option) => option.rowIndex + "," +
                                option.colIndex}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.rowIndex},{option.colIndex}
                                </li>
                            )}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Obstacles" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} margin={2}>
                    <FormControl size="small" fullWidth >
                        <Autocomplete
                            id="checkboxes-tags-demo"
                            limitTags={2}
                            options={top100Films}
                            disableCloseOnSelect
                            onChange={onDestinationHandler}
                            getOptionLabel={(option) => option.rowIndex + "," +
                                option.colIndex}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.rowIndex},{option.colIndex}
                                </li>
                            )}
                            // style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Destination" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} margin={2}>
                    <Button
                        disabled={(sourceCell.length == 0 || destinationCell.length  == 0) ? true: false}
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={() => getAllRoute()}
                        sx={{ ...buttonClasses.lynkitOrangeFill, fontSize: ".7rem" }}>
                            Find route
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={9}>
                <div className="simpletable">
                    <table>
                        <tbody>
                        {
                            Array.from({ length: rows }, (x, i) => {
                                return <tr key={i}>
                                {
                                    Array.from({ length: columns }, (y, j) => {
                                        let gridBlock = getBlockByIndex(i, j)
                                        return <td key={j}
                                            onClick={() => updateGrid(gridBlock.cellId, i, j)}
                                            style={{
                                                background: getBackground(),
                                                textAlign: "center",
                                                color: gridBlock?.isSelected ? "" : gridBlock?.fontColor
                                            }}
                                        >
                                            {getIcon()}
                                        </td>

                                        function getBackground() {
                                            if (gridBlock?.startPoint) {
                                                return "green"
                                            }
                                            else if (gridBlock?.endPoint) {
                                                return "red"
                                            }
                                            else if (gridBlock?.isSelected) {
                                                return "#ff7200"
                                            }

                                            return "";
                                        }

                                        function getIcon() {
                                            if (gridBlock?.startPoint) {
                                                return <>
                                                    <AssistantPhotoTwoToneIcon className='text-white' />
                                                </>
                                            }
                                            else if (gridBlock?.endPoint) {
                                                return <>
                                                    <AssistantPhotoTwoToneIcon className='text-white' /> </>
                                            }
                                            else if (gridBlock?.isSelected) {
                                                var inputElem = input.findIndex((item) => item[0] == i && item[1] == j);

                                                var currentElem = input[inputElem];
                                                var nextElem = input[inputElem + 1];

                                                var obj = {};

                                                if (currentElem && nextElem) {
                                                    if (currentElem[0] == nextElem[0]) //same row
                                                    {
                                                        if (currentElem[1] > nextElem[1]) // left arrow
                                                        {
                                                            obj = { transform: "rotate(-90deg)" }
                                                        }
                                                        else // right arrow
                                                        {
                                                            obj = { transform: "rotate(90deg)" }
                                                        }
                                                    }
                                                    else if (currentElem[0] > nextElem[0]) // same column
                                                    {
                                                        if (currentElem[1] > nextElem[1]) {
                                                            obj = { transform: "rotate(180deg)" }
                                                        }
                                                        if (currentElem[1] < nextElem[1]) {
                                                            obj = { transform: "rotate(-180deg)" }
                                                        }
                                                        else {
                                                            obj = { transform: "rotate(0deg)" }
                                                        }
                                                    }
                                                    else if (currentElem[0] < nextElem[0]) // forwards arrow
                                                    {
                                                        if (currentElem[1] > nextElem[1]) {
                                                            obj = { transform: "rotate(-180deg)" }
                                                        }
                                                        if (currentElem[1] < nextElem[1]) {
                                                            obj = { transform: "rotate(180deg)" }
                                                        }
                                                        else {
                                                            obj = { transform: "rotate(180deg)" }
                                                        }
                                                    }
                                                }

                                                return <>
                                                    <NavigationTwoToneIcon className='text-white' sx={obj} />
                                                    </>
                                            }

                                            return <>
                                                {i +"," + j}
                                            </>;
                                        }
                                    })
                                }
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </Grid>
        </Grid>

    )
}
function showCanvas(condition, setCondition) {
    if (condition) {
        setCondition(false);
        let width = window.innerWidth;
        let height = window.innerHeight;
        var scene = new THREE.Scene();
        scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);
        scene.fog = new THREE.Fog(0xffffff, 1000, 4000);

        var camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 5;
        scene.add(camera);

        var renderer = new THREE.WebGLRenderer();
        renderer.setSize(width, height);
        document.body.appendChild(renderer.domElement);

        scene.add(new THREE.AmbientLight(0x666666));


        var cameraControls = new OrbitControls(camera, renderer.domElement);
        // cameraControls.target.set( 0, 50, 0 );
        // cameraControls.update();
        function animate() {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        }
        animate();
    }

    else {
        ////("😢😢😢😢😢😢😢😢");
    }
}

