import { Button, Grid, MenuItem, Paper, Select, Typography, Box, Tabs, Tab, FormControl, OutlinedInput, InputAdornment, Card, CardHeader, IconButton, CardContent, Divider, TablePagination, Modal, TextField, Menu, Tooltip } from '@mui/material'
import { Close, ControlPoint, KeyboardArrowDown, Label, QrCodeScanner, QuestionMark, Search, Sell, SellOutlined } from '@mui/icons-material'
import theme, { buttonClasses, cardClasses, inputClasses, textClasses } from '../../../static/styles/theme'


import moment from 'moment'
import e from 'cors'

export const getSpentTime = (then, now) => {
    if(then != null) {
        then = moment(then);
        now = now ? moment(now) : moment();
        
        let dayDiff = now.diff(then, "days");
        let hourDiff = now.diff(then, "hours");
        let minDiff = now.diff(then, "minutes");
        let timeSpent;

        if(dayDiff < 1){
            if(hourDiff < 1){
                timeSpent = `${minDiff} minutes`
            }
            else{
                timeSpent = `${hourDiff} hours`
            }
        }
        else{
            timeSpent = `${dayDiff} days`
        }
        return timeSpent
    }
    else{
        return "-"
    }
}

// Get Table header for all different type of inventories
// invType = "Shipment", "Package", "Item"
// modal = this flag hides few columns from table if opened in Dialog Box
export const getHeader = (invType, modal=false) => {
    let baseLabels;
    let baseIds;
    if (modal) {
        baseLabels = [ "Gated In","Gated Out","In Stock","Current Status", "Gate In", "Gate Out","Total Time Spent", "Data Entry Time" ]
        baseIds = ["gateInCount","gateOutCount", "inStockCount","status", "gateInTime", "gateOutTime","timeSpent", "entryTime"]
    }
    else{
        baseLabels = ["Gated In","Gated Out","In Stock","Current Status", "Gate In", "Gate Out","Total Time Spent", "Data Entry Time", "Generate Label"]
        baseIds = ["gateInCount","gateOutCount", "inStockCount","status", "gateInTime", "gateOutTime","timeSpent", "entryTime", "generateLabel"]
        if(invType == "Shipment"){
            baseLabels.splice(0, 3)
            baseIds.splice(0, 3)
        }
    }
    // let baseLabels = [ "Current Status", "Gate In", "Gate Out", "Total Time Spent", "Data Entry Time", "Generate Label", "Map RFID", "Action"]
    // let baseIds = ["status", "gateInTime", "gateOutTime", "timeSpent", "entryTime", "generateLabel", "mapRFID", "action"]
    let additionalLabels;
    let additionalIds;
    if (invType == "Shipment") {
        additionalLabels = ["Shipment ID",  "Mapped Area", "Package Count", "Item Count"]
        additionalIds = ["shipmentId", "mappingArea", "totalPackage", "totalItem"]
        
    }
    else if(invType == "Package") {
        additionalLabels = ["Package ID", "Package Name",  "Mapped Area","Total Package Count"]
        additionalIds = ["packageId", "packageName", "mappingArea","count"]
        if(!modal){
            additionalLabels.push("Item Count")
            additionalIds.push("count")
        }
    }
    else{
        additionalLabels = ["Item ID", "Item Name",  "Location","Total Item Count"]
        additionalIds = ["itemId", "SKU_Number", "mappingArea","count"]
    }
    baseLabels = [...additionalLabels, ...baseLabels]
    baseIds = [...additionalIds, ...baseIds]
    return {baseLabels, baseIds}
}

// Backup Function
export const getTableCell = (data, type, id, detailCard=false, handleSelectedRow, handleOpenDetailModal, handleOpenLabelModal, handleOpenRfidModal, actionMenu, handleOpenAction, handleCloseAction, functions) => {
    // if(["shipmentId", "packageId", "itemId"].indexOf(id) >= 0){
    //     if(detailCard){
    //         return data[id];
    //     }
    //     return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data[id]}</Typography>
    // }
    if(id == "srNo"){
        return <Typography sx={{...textClasses.normalText}}>{data}</Typography>
    }
    else if(id == "shipmentId"){
        if(detailCard){
            return data.customShipmentId ? data.customShipmentId : data[id];
        }
        return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data.customShipmentId ? data.customShipmentId : data[id]}</Typography>
    }
    else if(id == "packageId"){
        if(detailCard){
            return data.customPkgId ? data.customPkgId : data[id];
        }
        return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data.customPkgId ? data.customPkgId : data[id]}</Typography>
    }
    else if(id == "itemId"){
        if(detailCard){
            return data.customItemId ? data.customItemId : data[id];
        }
        return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data.customItemId ? data.customItemId : data[id]}</Typography>
    }
    else if(id == "packageCount" || id == "totalPackage"){
        id = "totalPackage"
        if(!data.isPackageExist){
            return "-";
        }
        if(detailCard){
            return data[id] ? data[id] : "-"
        }
        // let num = data.status == "pending" ? "0" : data.balancePackage;
        // let num  = data.balancePackage && data.balancePackage.physical // 7 oct 2023
        let num = data.gateInCount
        num = num == null ? "0" : num
        let den = data.totalPackage;
        // let num = 
        // {data[id] ? `${data[id]} / ${data[id]}` : "-"}
        return (
                // <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
                //     onClick={() => functions.handleModalOpen("listing", {
                //         modalType: "packageDetail",
                //         title: "Packages (s) Details", 
                //         type: type,
                //         data: data
                //     })}
                // >
                //     {data[id] ? `${num} / ${den}` : "-"}
                // </Typography>
                <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
                    onClick={() => handleOpenDetailModal({modalType: "packageDetail", title: "Packages (s) Details", type: type, data: data})}
                >
                    {data[id] ? `${num} / ${den}` : "-"}
                </Typography>
            )
    }
    else if (type == "Item" && id=="count"){
        if(detailCard){
            return data[id] ? data[id] : "-"
        }
        return <Typography sx={{...textClasses.normalText}}>{data[id] ? data[id] : "-"}</Typography>

    }
   
    else if(id == "itemCount" || id == "count" || id == "totalItem") {
        if(id == "itemCount"){
            id = "totalItem"
        }
        if(type == "Package" && !data.isItemExist){
            return data.count
        }
        if(!data.isItemExist){
            return "-";
        }
        if(detailCard){
            return data[id] ? data[id] : "-"
        }
        // let num = data.status == "pending" ? "0" : data.balanceItem;
        // let num = data.balanceItem && data.balanceItem.physical 7oct 2023
        let num = data.gateInCount
        num = num == null ? "0" : num
        let den = (type == "Package") ? data.count : data.totalItem;
        den = den == null ? "0" : den;
        ////(num,"nummmmm",den)
        
        return (
            // <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
            //     onClick={() => functions.handleModalOpen("listing", {
            //         modalType: "itemDetail",
            //         title: "Items (s) Details",
            //         type: type,
            //         data: data
            //     })}
            // >
            //     {data[id] ? `${num} / ${den}` : "-"}
            // </Typography>
            <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
            onClick={() => handleOpenDetailModal({
                modalType: "itemDetail",
                title: "Items (s) Details",
                type: type,
                data: data
            })}
        >
            {data[id] ? `${num} / ${den}` : "-"}
        </Typography>
        )
    }
    else if(id == "packageName"){
        // //console.log("data",data,id);
        if(detailCard){
            return data[id] ? data[id] : "-"
        }
        return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data.customPkgId ? data.customPkgId : data[id]}</Typography>
    }
    else if(id == "SKU_Number"){
        if(detailCard){
            return data[id] ? data[id] : "-"
        }
        return <Typography display={"block"} sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data.customItemId ? data.customItemId : data[id]}</Typography>
    }
    else if(["gateInTime", "gateOutTime"].indexOf(id) >= 0){
        if(detailCard){
            return data[id] ? moment(data[id]).format("DD MMM YYYY hh:mm A") : "-"
        }
        return <Typography sx={{...textClasses.normalText}}>{data[id] ? moment(data[id]).format("DD MMM YYYY hh:mm A") : "-"}</Typography>
    }
    else if(id == "dataEntryTime" || id == "entryTime"){
        id = "entryTime"
        if(detailCard){
            return type == "Shipment" ? moment(data[id]).format("DD MMM YYYY hh:mm A") : moment(data.createdAt).format("DD MMM YYYY hh:mm A")
        }
        return <Typography sx={{...textClasses.normalText}}>{type == "Shipment" ? moment(data[id]).format("DD MMM YYYY hh:mm A") : moment(data.createdAt).format("DD MMM YYYY hh:mm A")}</Typography>
    }
    else if(id == "mappingArea"){
        let convertedMappingArea;
        if(detailCard && data?.balanceCount?.physical>0 && data?.location?.length>0){
            convertedMappingArea=[]
            let arrTemp=[]
            data?.location?.map(dl=>{
                let isExist=arrTemp.findIndex(el=>{
                    let condition=el.cellId==dl.cellId
                    if(dl.rackId){
                        condition=condition && el.rackId==dl.rackId
                    }
                    return condition
                })
                if(isExist<0){
                    arrTemp.push(dl) 
                }
                else{
                    arrTemp.splice(isExist,1,{
                        ...arrTemp[isExist],
                        count:Number(dl.count || 0)+Number(arrTemp[isExist]["count"] || 0)
                    })  
                }
            })

            arrTemp.map(dl=>{
                let str_temp=dl.cellName || ""
                if(dl.rackName) str_temp=str_temp+"-"+dl.rackName+`(${dl.count})`
                else{
                    str_temp=str_temp+`(${dl.count})`
                }
                if(str_temp){
                    convertedMappingArea.push(str_temp)
                }
            })

        }
        else{
            if(type == "Item"){
                convertedMappingArea = data.mappingArea == "" ? "PUT IT ANYWHERE" : data.mappingArea
            }
            else{
                ////(data.mappingArea);
                convertedMappingArea = (data.mappingArea && Array.isArray(data.mappingArea) && data.mappingArea.length > 0) ? data.mappingArea.map(ma => ma == "" ? "PUT IT ANYWHERE" : ma) : ["PUT IT ANYWHERE"]
            }
        }
        
        if(detailCard){
            if(!Array.isArray(convertedMappingArea)) return convertedMappingArea
            else return convertedMappingArea.join(", ")
        }
        return (
            <Tooltip title={convertedMappingArea.toString()}>
                <Typography sx={{...textClasses.normalText}}>
                    <ControlPoint fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
                    { convertedMappingArea.toString().length > 10 ? convertedMappingArea.toString().substring(0, 10) + "..." : convertedMappingArea.toString()}
                </Typography>
            </Tooltip>
            
        )
        
    }
    else if(id =="status"){
        let statusMapping = {
            'partialGateOut': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Partially Gated-Out"
            },
            'gateOut': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Gated-Out"
            },
            'gateIn': {
                color: "#3E621E",
                background: "#D7ECC4",
                label: "Gated-In"
            },
            'pending': {
                color: "#FF7200",
                background: "#ff720026",
                label: "Pending"
            },
            'underApproval': {
                color: "#FF7200",
                background: "#ff720026",
                label: "Under-Approval",
                padding:5
            },

            'partialGateIn': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Partially Gated-In"
            },
            'rejected': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "Rejected"
            },
            'underDekitting': {
                color: "#FF7200",
                background: "#ff720026",
                label: "Under-Dekitting"
            },
            'dekitted': {
                color: "#E9001A",
                background: "#E9001A26",
                label: "De-Kitted"
            }

        }
        //console.log("data.status",);
        if (detailCard) {
            return statusMapping[data.status]
        }
        else {
            return <Typography sx={{ ...textClasses.normalText, color: statusMapping[data.status]?.color }}>{statusMapping[data.status]?.label}</Typography>
        }
    }
    else if(id == "totalTimeSpend" || id == "timeSpent"){
        
        let timeSpent = getSpentTime(data.gateInTime, data.gateOutTime)
        if(detailCard){
            return timeSpent
        }
        return <Typography sx={{...textClasses.normalText}}>{timeSpent}</Typography>
    }
    else if(id == "generateLabel") {
        return (
            <Typography sx={{...textClasses.normalText, cursor: "pointer"}}
                onClick={() => handleOpenLabelModal({
                    shipmentId: data.shipmentId,
                    templateId: type == "Shipment" ? data.template_id : data.dataEntryTemplate_Id 
                })}
                // onClick={() => functions.handleModalOpen("label", {
                //     shipmentId: data.shipmentId,
                //     templateId: type == "Shipment" ? data.template_id : data.dataEntryTemplate_Id 
                // })}
            >
                <QrCodeScanner fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
                Label
            </Typography>
        )            
    }
    else if (id== "inStockCount" || id == "inStock"){
        if(type=="Package"||type=="Item"){
            return <Typography sx={{...textClasses.normalText}}>{data.balanceCount?data.balanceCount.physical:"-"}</Typography>

        }
        else{
            return <Typography sx={{...textClasses.normalText}}>{data.totalCount?data.totalCount:"-"}</Typography>

        }
    }
    else if(id=="gateInCount"){
        
        return <Typography sx={{...textClasses.normalText}}>{data.gateInCount?data.gateInCount:"-"}</Typography>
    }
    else if (id=="gateOutCount"){
        let gateCount = data.gateInCount && data.balanceCount && data.gateInCount-data.balanceCount.physical
        return <Typography sx={{...textClasses.normalText}}>{gateCount?gateCount:"-"}</Typography>
    }
    // else if (id=="gateOutCount"){
    //     return <Typography sx={{...textClasses.normalText}}>{data.gateOutCount?data.gateOutCount:'-'}</Typography>
    // }
    else if(id == "mapRFID") {
        if(data.status != "pending") {
            return <Typography sx={{...textClasses.normalText, cursor: "pointer"}}>-</Typography>
        }
        let content;
        if(type == "Shipment"){
            content = data.epc && data.epc.length == data.totalItem ? "De-Allocate" : "Allocate";
        }
        else if(type == "Package"){
            content = data.epc && data.epc.length == data.count ? "De-Allocate" : "Allocate";
        }
        else{
            content = data.epc && data.epc.length > 0 ? "De-Allocate" : "Allocate";
        }
        
        
        let color = content == "Allocate" ? "#3E621E" : "#E9001A"
        return (
            <Typography display={"block"} sx={{...textClasses.normalText, cursor: "pointer", color: color}}
                onClick={() => handleOpenRfidModal({
                    shipmentId: data.shipmentId
                })}
            >
                <Sell fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
                {content}
            </Typography>
        )
    }
    else if( id == "action") {
        return (
            <>  
                <Typography id="" 
                    aria-controls={actionMenu !=null ? 'action-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={actionMenu !=null ? 'true' : undefined}   
                    sx={{...textClasses.normalText, color: theme.themeOrange}} onClick={handleOpenAction}>
                    Action
                    <KeyboardArrowDown fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
                </Typography>
                {/* <Menu
                    id="action-menu"
                    anchorEl={actionMenu}
                    open={actionMenu != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'action-button',
                    }}
                >
                    <MenuItem onClick={handleCloseAction}>Profile</MenuItem>
                    <MenuItem onClick={handleCloseAction}>My account</MenuItem>
                    <MenuItem onClick={handleCloseAction}>Logout</MenuItem>
                </Menu> */}
            </>
            
        )
    }
}

// This function returns the value in JSX format or String depending on detailCard flag

// export const getTableCell = (data, type, id, detailCard=false, handleSelectedRow, actionMenu, handleOpenAction, handleCloseAction, functions) => {
//     if(["shipmentId", "packageId", "itemId"].indexOf(id) >= 0){
//         if(detailCard){
//             return data[id];
//         }
//         return <Typography sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}} onClick={()=> handleSelectedRow(data)}>{data[id]}</Typography>
//     }
//     else if(id == "totalPackage"){
//         if(detailCard){
//             return data[id] ? data[id] : "-"
//         }
//         let num = data.status == "pending" ? "0" : data.balancePackage;
//         num = num == null ? "0" : num
//         let den = data.totalPackage;
//         // {data[id] ? `${data[id]} / ${data[id]}` : "-"}
//         return (
//                 <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
//                     onClick={() => functions.handleModalOpen("listing", {
//                         modalType: "packageDetail",
//                         title: "", 
//                         type: type,
//                         data: data
//                     })}
//                 >
//                     {data[id] ? `${num} / ${den}` : "-"}
//                 </Typography>
//                 // <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
//                 //     onClick={() => handleOpenDetailModal({modalType: "packageDetail", title: "Packages (s) Details", type: type, data: data})}
//                 // >
//                 //     {data[id] ? `${num} / ${den}` : "-"}
//                 // </Typography>
//             )
//     }
//     else if(id == "totalItem" || id == "count") {
//         if(detailCard){
//             return data[id] ? data[id] : "-"
//         }
//         let num = data.status == "pending" ? "0" : data.balanceItem;
//         num = num == null ? "0" : num
//         let den = type == "Package" ? data.count : data.totalItem;
//         den = den == null ? "0" : den;
//         return (
//             <Typography sx={{...textClasses.normalText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer"}}
//                 onClick={() => functions.handleModalOpen("listing", {
//                     modalType: "itemDetail",
//                     title: "Items (s) Details",
//                     type: type,
//                     data: data
//                 })}
//             >
//                 {data[id] ? `${num} / ${den}` : "-"}
//             </Typography>
//         )
//     }
//     else if(["packageName", "SKU_Number"].indexOf(id) >= 0){
//         if(detailCard){
//             return data[id] ? data[id] : "-"
//         }
//         return <Typography sx={{...textClasses.normalText}}>{data[id] ? data[id] : "-"}</Typography>
//     }
//     else if(["gateInTime", "gateOutTime"].indexOf(id) >= 0){
//         if(detailCard){
//             return data[id] ? moment(data[id]).format("DD-MM-YYYY HH:MM") : "-"
//         }
//         return <Typography sx={{...textClasses.normalText}}>{data[id] ? moment(data[id]).format("DD-MM-YYYY HH:MM") : "-"}</Typography>
//     }
//     else if(id == "entryTime"){
//         if(detailCard){
//             return type == "Shipment" ? moment(data[id]).format("DD-MM-YYYY HH:MM") : moment(data.createdAt).format("DD-MM-YYYY HH:MM")
//         }
//         return <Typography sx={{...textClasses.normalText}}>{type == "Shipment" ? moment(data[id]).format("DD-MM-YYYY HH:MM") : moment(data.createdAt).format("DD-MM-YYYY HH:MM")}</Typography>
//     }
//     else if(id == "mappingArea"){
//         if(detailCard){
//             return data.mappingArea
//         }
//         return (
//             <Typography sx={{...textClasses.normalText}}>
//                 <ControlPoint fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
//                 {/* {invType =="Item" ? data.mappingArea : data.mappingArea.join(", ")} */}
//                 { data.mappingArea.toString().length > 10 ? data.mappingArea.toString().substring(0, 10) + "..." : data.mappingArea.toString()}
//             </Typography>
//         )
        
//     }
//     else if(id =="status"){
//         let statusMapping = {
//             'partialGateOut': {
//                 color: "#E9001A",
//                 label: "Partially Gated-Out"
//             },
//             'gateOut': {
//                 color: "#E9001A",
//                 label: "Gated-Out"
//             },
//             'gateIn': {
//                 color: "#3E621E",
//                 label: "Gated-In"
//             },
//             'pending': {
//                 color: "#FF7200",
//                 label: "Pending"
//             }
//         }
//         if(detailCard){
//             return statusMapping[data.status]
//         }
//         else{
//             return <Typography sx={{...textClasses.normalText, color: statusMapping[data.status].color}}>{statusMapping[data.status].label}</Typography>
//         }
        
//     }
//     else if(id == "timeSpent"){
//         let timeSpent = getSpentTime(data.gateInTime, data.gateOutTime)
//         if(detailCard){
//             return timeSpent
//         }
//         return <Typography sx={{...textClasses.normalText}}>{timeSpent}</Typography>
//     }
//     else if(id == "generateLabel") {
//         return (
//             <Typography sx={{...textClasses.normalText, cursor: "pointer"}}
//                 // onClick={() => handleOpenLabelModal({
//                 //     shipmentId: data.shipmentId,
//                 //     templateId: type == "Shipment" ? data.template_id : data.dataEntryTemplate_Id 
//                 // })}
//                 onClick={() => functions.handleModalOpen("label", {
//                     shipmentId: data.shipmentId,
//                     templateId: type == "Shipment" ? data.template_id : data.dataEntryTemplate_Id 
//                 })}
//             >
//                 <QrCodeScanner fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
//                 Label
//             </Typography>
//         )            
//     }
//     else if(id == "mapRFID") {
//         return (
//             <Typography sx={{...textClasses.normalText, cursor: "pointer"}}
//                 // onClick={() => handleOpenRfidModal({
//                 //     shipmentId: data.shipmentId
//                 // })}
//                 onClick={() => functions.handleModalOpen("rfid", {
//                     shipmentId: data.shipmentId
//                 })}
//             >
//                 <Sell fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
//                 Allocated
//             </Typography>
//         )
//     }
//     else if( id == "action") {
//         return (
//             <>  
//                 <Typography id="" 
//                     aria-controls={actionMenu !=null ? 'action-menu' : undefined}
//                     aria-haspopup="true"
//                     aria-expanded={actionMenu !=null ? 'true' : undefined}   
//                     sx={{...textClasses.normalText, color: theme.themeOrange}} onClick={handleOpenAction}>
//                     Action
//                     <KeyboardArrowDown fontSize='inherit' sx={{color: theme.themeOrange, mr: 1}}/>
//                 </Typography>
//                 {/* <Menu
//                     id="action-menu"
//                     anchorEl={actionMenu}
//                     open={actionMenu != null}
//                     onClose={handleCloseAction}
//                     MenuListProps={{
//                         'aria-labelledby': 'action-button',
//                     }}
//                 >
//                     <MenuItem onClick={handleCloseAction}>Profile</MenuItem>
//                     <MenuItem onClick={handleCloseAction}>My account</MenuItem>
//                     <MenuItem onClick={handleCloseAction}>Logout</MenuItem>
//                 </Menu> */}
//             </>
            
//         )
//     }
// }