import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Grid,Box,InputLabel,Select,MenuItem,FormControl,Button,Typography,Autocomplete,TextField,OutlinedInput,InputAdornment,Menu,
  Card,CardContent,CardActions,CardMedia,Tooltip,IconButton,Radio,RadioGroup,FormControlLabel,
} from "@mui/material";
import "./Style/GridView.css";
import GridViewLayout from "./Components/GridViewLayout";
import WarehouseModel from "./Components/WarehouseModel";
import EmptyPage from "../../Modules/EntityMaster/EmptyPage";
import {
  getCompany,
  getGridDetail,
  addRack,
  viewFilledLocationByShipment,
  generateQrCode,
  getCellStorageCapacity,
  generateGridExcel,
  getCellCapacityDetail,
  getUtilizationAnalytics
} from "../../../redux/actions";
import { getMappedArea } from "../../../redux/actions/AreaMapping";
import { showToast, textCapitalize } from "../../../services/functions";
import { ToastContainer, toast } from "react-toastify";
import {
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import theme from "../../../static/styles/theme";
import { selectStylesOverride } from "../../../utils/util";
import DownloadIcon from "../../../static/images/Icon/Download-Icon.png";
import RedirectIcon from "../../../static/images/Icon/Redirect_icon.png";
import {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import GenericTable from "../CustomTable/GenericTable";
import { useDebounce } from "../../../utils/hooks";
import { push } from "react-router-redux";
import GenericLoader from "../Generic/GenericLoader";
import AlertDialog from "../DialogBox/dialogbox";
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import history from "../../../services/history";
import AccessbilityAndUtilization from "./Components/AccessbilityAndUtilization";


function GridView() {
  const dispatch = useDispatch();
  const { gridCellDetails, FilledLocationByShipment } = useSelector(
    (state) => state.warehouseData
  );
  const {
    warehouseDetail,
    floorDetail,
    generatedQr = false,
  } = useSelector((state) => state.superadmin);

  const { userPermissions = {} } = useSelector((state) => state.userPermission);
  const { mappedArea } = useSelector((state) => state.areaMappingData);

  const [warehouseInfo, setWarehouseInfo] = useState({});
  const [warehouseData, setWarehouseData] = useState([]);
  const [cellInfo, setCellInfo] = useState({});



  const [floorData, setFloorData] = useState([]);
  const [floorInfo, setFloorInfo] = useState({});
  const [condition, setCondition] = useState(true);

  const [allFilledLocation, setAllFilledLocation] = useState({});
  const [areaName, setAreaName] = useState("");
  const [userType, setUserType] = useState("");
  const [withWFFetch, setWithWFFetch] = useState({
    warehouse: true,
    floor: true,
  });

  const [floorLoader, setFloorLoader] = useState(true);
  const [qrLoader, setQrLoader] = useState(false);
  const [visibleCard, setVisibleCard] = useState("");
  const [showRfidTags, setShowRfidTags] = useState(false)
  const [analyticsBlockDetail, setAnalyticsBlockDetail] = useState([]);
  const [utilizationTypeValue, setUtilizationTypeValue] = useState("countPercentageFilled");
  const handleUtilizationValueChange = (event) => {
    setUtilizationTypeValue(event.target.value);
  };
 

  const colorHanlder = (cell_Data,analyticsRackDetail) => {
    if (cell_Data) {
      if (areaName && cell_Data.productType != areaName) {
        return "grey";
      }
      if (cell_Data.status == "full") {
        return "red";
      } else if (cell_Data.status == "partial") {
        let utilizationInfo = analyticsRackDetail?.find(dl => dl?.cellId === cell_Data?.cellId) || {};
        let percentage = 0;
        if (utilizationInfo[utilizationTypeValue] > 0) {
          percentage = (utilizationInfo[utilizationTypeValue] || 0)
        }
        percentage = Number(percentage || 0)
        if (percentage >= 100) {
          return "red";
        }
        return "yellow";
      } else if (cell_Data.status == "available") {
        return "green";
      } else {
        return "green";
      }
    } else {
      return "green";
    }
  };


  const showWarehouseModal = async (cellId = "") => {
    let data = gridCellDetails.find((ele) => ele.cellId == cellId);
    setVisibleCard("warehouse");
    setCellInfo(data);
  };

  const selectWarehouseHandler = (warehouse = {}) => {
    if (warehouse) {
      dispatch(
        getCompany({
          warehouseId: warehouse?.warehouseId || "",
          type: "floor",
          status: "active",
        })
      );
      setWarehouseInfo({
        ...warehouse,
        label: warehouse.name || "",
        value: warehouse.name || "",
      });
    } else {
      setWarehouseInfo({});
    }

    setFloorData([]);
    setFloorInfo({});
    setAllFilledLocation([]);
    setVisibleCard("")
  };

  const selectFloorHandler = (floor = {}) => {
    if (floor) {
      setFloorLoader(true);
      dispatch(
        getGridDetail(
          {
            floorId: floor?.floorId || "",
            type: "block",
            fetch_for: "gridview",
          },
          () => {
            setFloorLoader(false);
          }
        )
      );
      dispatch(
        viewFilledLocationByShipment({
          type: "block",
          floorId: floor?.floorId || "",
        })
      );
      dispatch(
        getMappedArea({ floorId: floor?.floorId || "", allAreaNameOnly: true })
      );
      setFloorInfo({
        ...floor,
        label: floor.name || "",
        value: floor.name || "",
      });
      dispatch(
        getUtilizationAnalytics({
          floorId: floor?.floorId || "", type: "block"
        }, (data) => {
          setAnalyticsBlockDetail(data.data)
        }))
    }
    else {
      setFloorInfo({});
    }
    setVisibleCard("")
    setAllFilledLocation([]);
    setWithWFFetch({ ...withWFFetch, graph: true });
  };
  const getDownloadExcel = () => {
    setShowRfidTags(true)
  }


  const handleAddRack = (data) => {
    if (data) {
      let obj = {
        ...data,
        warehouseId: warehouseInfo?.warehouseId || "",
        floorId: floorInfo?.floorId || "",
      };
      dispatch(
        addRack(obj, ({ success, message }) => {
          showToast(message, !success);
          if (success) {
            setVisibleCard("");
            setCellInfo({});
            setFloorLoader(true);

            dispatch(
              getGridDetail(
                {
                  floorId: floorInfo?.floorId || "",
                  type: "block",
                  fetch_for: "gridview",
                },
                () => {
                  setFloorLoader(false);
                }
              )
            );
            dispatch(
              viewFilledLocationByShipment({
                type: "block",
                floorId: floorInfo?.floorId || "",
              })
            );
          }
        })
      );
    }
  };
  useEffect(() => {
    if (floorInfo?.floorId && FilledLocationByShipment) {
      setAllFilledLocation(FilledLocationByShipment);
    }
  }, [FilledLocationByShipment]);


  useEffect(() => {
    let userData = localStorage.getItem("user");
    // //('data-userdata', userData)
    if (userData && userData.userType) {
      setUserType(userData.userType);
    }
    if (condition && (!warehouseData || warehouseData.length == 0)) {
      dispatch(
        getCompany({
          companyName: userData.company,
          type: "warehouse",
          status: "active",
        })
      );
      setCondition(false);
    } 
    else {
      if (warehouseDetail?.data?.length > 0) {
        setWarehouseData(warehouseDetail.data);
        if (withWFFetch && withWFFetch.warehouse) {
          if (!warehouseInfo?.warehouseId) {
            selectWarehouseHandler({
              ...warehouseDetail.data[0],
              warehouseId: warehouseDetail.data[0]?._id || "",
            });
          }
          let tempFetch = { ...withWFFetch, warehouse: false };
          setWithWFFetch(tempFetch);
        }
      } 
      else {
        setWarehouseData([]);
      }
      let tempFloor=(floorDetail || [])?.filter(dl=>dl.warehouseId==warehouseInfo?.warehouseId) || []
      if (warehouseInfo?.warehouseId && tempFloor?.length > 0) {
        setFloorData(tempFloor);
        if (withWFFetch && withWFFetch.floor) {
          if (!floorInfo?.floorId) {
            selectFloorHandler({
              ...tempFloor?.at(0),
              floorId: tempFloor?.at(0)?._id || "",
            });
          }
          let tempFetch = { ...withWFFetch, floor: false };
          setWithWFFetch(tempFetch);
        }
      } 
      else {
        setFloorData([]);
      }
    }
  }, [warehouseDetail, floorDetail]);

  const getQrCode = ({ warehouseId = "", _id = "", base = "" }) => {
    setQrLoader("qr");
    dispatch(
      generateQrCode(
        { warehouseId, floorId: _id + "", base },
        ({ success, message, awsUrl }) => {
          showToast(message, !success);
          setQrLoader(false);
          if (success && awsUrl) {
            window.open(awsUrl, "_blank");
          }
        }
      )
    );
  };

  return (
    <>
      <Grid
        container
        className="GridView_container"
        data-testid="GridView-dashboard"
      >
        <Grid container margin="normal">
          <Grid item xs={2}>
            <h2 className="fs-24 mt-1 font-Nunito fw-600">Grid View</h2>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={2} justifyContent={"end"}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Select Warehouse
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    options={
                      warehouseData.map((item) => ({
                        label: item.name,
                        name: item.name,
                        value: item.name,
                        warehouseId: item?._id,
                      })) || []
                    }
                    onChange={(e, option) => selectWarehouseHandler(option)}
                    value={warehouseInfo?.label || ""}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        value={option?.label || ""}
                        key={option?.label || ""}
                        {...props}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.label || ""}
                        </Typography>
                      </MenuItem>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Select Floor
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    options={
                      floorData.map((item) => ({
                        ...item,
                        label: item.name,
                        value: item.name,
                        floorId: item?._id,
                      })) || []
                    }
                    onChange={(e, option) => selectFloorHandler(option)}
                    value={floorInfo?.label || ""}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        value={option?.label || ""}
                        key={option?.label || ""}
                        {...props}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.label || ""}
                        </Typography>
                      </MenuItem>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride }}
                >
                  <Autocomplete
                    size="small"
                    labelId="jobName-label"
                    sx={{ height: "1.1rem" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12px" }}>
                            Area Type
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          fontSize: "12px",
                          "& input::placeholder": {
                            fontSize: "9px",
                          },
                        }}
                      />
                    )}
                    options={
                      mappedArea?.data?.filter((dl) => !dl.isDefault) || []
                    }
                    onChange={(e, option) => setAreaName(option?.value || "")}
                    value={{
                      label:
                        (areaName &&
                          mappedArea?.data.find((dl) => dl.value == areaName)
                            ?.label) ||
                        "",
                      value: areaName || "",
                    }}
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        value={option?.value || ""}
                        key={option?.value || ""}
                        {...props}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.label || ""}
                        </Typography>
                      </MenuItem>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          margin="normal"
          container
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            sx={{
              borderRadius: "10px",
              border: "1px solid #D9D9D9",
              width: visibleCard ? "69%" : "100%",
            }}
            item
          // xs={visibleCard ? 8 : 12}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item xs={3} display={"flex"} justifyContent={"start"}>
                <Box className="GV-title-container">
                  <h2 className="orange-color font-Nunito fs-18 fw-600">
                    {warehouseInfo?.name || ""}
                    {floorInfo?.name ? ` - ${floorInfo?.name}` : ""}{" "}
                  </h2>
                </Box>
              </Grid>
              <Grid
                item
                xs={9}
                padding={0}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Box className="GV-label-container">
                  <Box className="GV-container">
                    <Box className="GV-green-box"></Box>&nbsp;&nbsp;
                    <span className="fs-12 GV-center">
                      Fully Available (
                      {allFilledLocation.available
                        ? allFilledLocation.available
                        : "0"}
                      )
                    </span>
                  </Box>
                  <Box className="GV-container">
                    <Box className="GV-yellow-box"></Box>&nbsp;&nbsp;
                    <span className="fs-12 GV-center">
                      Partially Filled (
                      {allFilledLocation.partial
                        ? allFilledLocation.partial
                        : "0"}
                      )
                    </span>
                  </Box>
                  <Box className="GV-container">
                    <Box className="GV-red-box"></Box>&nbsp;&nbsp;
                    <span className="fs-12 GV-center">
                      Fully Filled (
                      {allFilledLocation.complete
                        ? allFilledLocation.complete
                        : "0"}
                      )
                    </span>
                  </Box>
                  <Box className="GV-container">
                    <Box className="GV-white-box"></Box>&nbsp;&nbsp;
                    <span className="fs-12 GV-center">
                      Disabled (
                      {allFilledLocation.disable
                        ? allFilledLocation.disable
                        : "0"}
                      )
                    </span>
                  </Box>

                  {((userType && userType.userType == "superadmin") ||
                    userPermissions?.permissions?.find(
                      (dl) => dl.permissionId == "generateGridQR"
                    )) && (
                      <Box className="GV-container">
                        <Button
                          sx={{ color: "#F77200" }}
                          disabled={
                            (qrLoader && qrLoader == "qr") ||
                            !floorInfo ||
                            !floorInfo.row ||
                            !floorInfo.isGridExist
                          }
                          onClick={() => getQrCode(floorInfo)}
                        >
                          Download QR
                        </Button>
                      </Box>
                    )}
                  {((userType && userType.userType == "superadmin") ||
                    userPermissions?.permissions?.find(
                      (dl) => dl.permissionId == "generateGridExcel"
                    )) && (
                      <Box className="GV-container">
                        <Button
                          sx={{ color: "#F77200" }}
                          disabled={
                            (qrLoader && qrLoader == "excel") ||
                            !floorInfo ||
                            !floorInfo.row ||
                            !floorInfo.isGridExist
                          }
                          onClick={() => getDownloadExcel(floorInfo)}
                        >
                          Download Excel
                        </Button>
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>

            {floorInfo?.isCapacityDefined == true && <Grid container spacing={2} >
              <Grid item md={4} sm={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center"}}>
                <Typography variant="body2" marginLeft="20px" fontWeight="700">Utilization Percentage Type (Filled)</Typography>
              </Grid>

              <Grid item sm={6} md={8} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>

                <FormControl>
                  {/* <FormLabel id="demo-controlled-radio-buttons-group">Utilization Type*</FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={utilizationTypeValue}
                    onChange={handleUtilizationValueChange}
                  >
                    <FormControlLabel sx={{
                      color: "#000",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                      },
                    }} value="countPercentageFilled" control={<Radio size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: "#FF7200",
                        },
                      }} />} label="QTY" />
                    <FormControlLabel sx={{
                      color: "#000",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                      },
                    }}
                      value="weightPercentageFilled" control={<Radio size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "#FF7200",
                          },
                        }} />} label="Weight" />
                    <FormControlLabel sx={{
                      color: "#000",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "12px",
                      },
                    }} value="volumePercentageFilled" control={<Radio size="small"
                      sx={{
                        "&.Mui-checked": {
                          color: "#FF7200",
                        },
                      }} />} label="Dimensions" />
                  </RadioGroup>
                </FormControl>

              </Grid>
            </Grid>}

            {floorLoader ? (
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{ p: 10 }}
              >
                <Grid item>
                  <GenericLoader />
                </Grid>
              </Grid>
            ) : (
              <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
                {floorInfo &&
                  Object.keys(floorInfo).length > 0 &&
                  floorInfo.row &&
                  floorInfo.col ? (
                  <GridViewLayout
                    areaName={areaName}
                    floorInfo={floorInfo}
                    gridCellDetails={gridCellDetails}
                    showWarehouseModal={(cellID) => showWarehouseModal(cellID)}
                    showRackModal={(cellID) =>
                      // showRackModal(cellID)
                      showWarehouseModal(cellID)
                    }
                    setVisibleCard={setVisibleCard}
                    analyticsBlockDetail={analyticsBlockDetail}
                    utilizationTypeValue={utilizationTypeValue}
                  />
                ) : (
                  <EmptyPage />
                  // <div>
                  //   <p>
                  //     No Grid View To Show - Select Warehouse And Floor First
                  //   </p>
                  // </div>
                )}
              </Box>
            )}
          </Grid>

          <ToastContainer
            position="bottom-center"
            autoClose={false}
            closeOnClick={false}
          />
          <Grid
            sx={{
              borderRadius: "10px",
              border: "1px solid #D9D9D9",
              width: visibleCard ? "30%" : "0%",
            }}
          >

            {floorInfo?.floorId && visibleCard == "warehouse" ? (
              <WarehouseModel
                show={visibleCard}
                onClose={() => setVisibleCard("")}
                colorHanlder={colorHanlder}
                cellInfo={cellInfo}
                handleAddRack={(data) => handleAddRack(data)}
                areaName={areaName}
                floorInfo={floorInfo}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {floorInfo?.floorId && (userPermissions?.permissions || []).some((dl) => [
        "addAreaUtilization", "viewAreaUtilization", "editAreaUtilization",
        "updateGridAccessbilityPreferences", "defineGridAccessibility", "getGridAccessbilityList",
      ].includes(dl?.permissionId))
        &&
        <Grid sx={{
          borderRadius: "10px",
          border: "1px solid #D9D9D9"
        }}>
        <AccessbilityAndUtilization
          warehouseInfo={warehouseInfo}
          floorInfo={floorInfo}
        />
        </Grid>
      }
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
      {showRfidTags && floorInfo?.floorId &&
        <RfidTags
          showRfidTags={showRfidTags} setShowRfidTags={setShowRfidTags}
          floorInfo={floorInfo} setQrLoader={setQrLoader} qrLoader={qrLoader}
        />}
    </>
  );
}

export default GridView;



const RfidTags = (props) => {
  const dispatch = useDispatch();
  const { showRfidTags = false, setShowRfidTags, floorInfo, setQrLoader, qrLoader } = props
  const handleDownloadExcel = () => {
    setQrLoader(true);
    const payload = {
      floorId: floorInfo?._id || "",
    };
    dispatch(
      generateGridExcel(payload, ({ success = "", message, awsUrl }) => {
        showToast(message, !success);
        setQrLoader(false);
        if (success && awsUrl) {
          window.open(awsUrl, "_blank");
        }
      })
    );
  }
  const handleGoToRFID = () => {
    history.push("/dashboard/RFID")
  }

  const getBody = () => {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Card sx={{ maxWidth: 300, marginRight: "10px", height: "100%" }}>
            <CardMedia
              sx={{ margin: "10px", height: "140px", objectFit: "contain" }}
              component="img"
              alt=""
              image={DownloadIcon}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                1. Download Sample
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ maxHeight: "100px" }}
              >
                As per the selected warehouse and floor location data will be
                downloaded in excel sample with addition tag related column(s).
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                <Button
                  size="small"
                  spacing={1}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    ...textClasses.t12n,
                    padding: "10px 10px",
                  }}
                  onClick={handleDownloadExcel}
                  disabled={qrLoader}
                >
                  Sample Download
                </Button>
              </Box>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 300, marginRight: "10px", height: "100%" }}>
            <CardMedia
              sx={{ margin: "10px", height: "140px", objectFit: "contain" }}
              component="img"
              alt=""
              image={RedirectIcon}
            />
            <CardContent sx={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6" component="div">
                2. Redirect to Manage Tag
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ maxHeight: "100px" }}
              >
                Once you are ready with your final excel as per the downloaded
                sample. Go to “Manage Tag” page
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                <Button
                  size="small"
                  spacing={1}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    ...textClasses.t12n,
                    padding: "10px 10px",
                  }}
                  onClick={handleGoToRFID}
                >
                  Go To Manage Tag
                </Button>
              </Box>
            </CardActions>
          </Card>

          <Card sx={{ maxWidth: 300, marginRight: "10px", height: "100%" }}>
            <CardMedia
              sx={{ margin: "10px", height: "140px", objectFit: "contain" }}
              component="img"
              alt=""
              image={RedirectIcon}
            />
            <CardContent sx={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6" component="div">
                3. Click On Bulk Upload
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ maxHeight: "100px" }}
              >
                At Manage Tag Page click on “Bulk Upload” button to upload the
                final mapped excel file.
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                <Button
                  size="small"
                  spacing={1}
                  sx={{
                    ...buttonClasses.lynkitBlackFill,
                    ...textClasses.t12n,
                    padding: "10px 10px",
                  }}
                  onClick={handleGoToRFID}
                >
                  Go To Manage Tag
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Box>
      </>
    );
  };
  return (
    <>
      <AlertDialog
        viewDialog={showRfidTags}
        sx={{ borderRadius: "50px" }}
        width="70%"
        title={
          <Typography
            sx={{ fontWeight: "1000", fontSize: "24px" }}
          >
            <span>Map Your Locations With RFID Tags</span>
          </Typography>
        }
        // subTitle={`You want to update User Role.`}
        body={
          getBody()
        }
        actionsCSS={{ display: "flex", justifyContent: 'flex-end', mr: 3 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => setShowRfidTags(false)}
          sx={{
            ...buttonClasses.lynkitOrangeEmpty,
            m: 1,
            fontSize: ".7rem",
            minWidth: "100px",
            display: 'flex !important',
            justifyContent: 'start important'
          }}
        >
          Cancel
        </Button>
      </AlertDialog>

    </>
  );
};


