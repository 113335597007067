import { Button, Checkbox, Grid, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getTableHeader, updateTableHeader } from '../../../redux/actions';
import { showToast } from '../../../services/functions';
import { buttonClasses, textClasses } from '../../../static/styles/theme'

export default function GenericCustomiseHeader({tableName, tableType}) {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [customiseHeader, setCustomiseHeader] = useState([]);

    const open = Boolean(anchorEl);

    const tableHeader = useSelector(state => state.customHeader[tableName]);


    
    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        if(tableHeader.validHeader){
            setCustomiseHeader([...tableHeader.validHeader.map((h) => h._id)])
        }
    }

    const handleCheck = (e, id) => {
        let old = [...customiseHeader];
        let index = old.indexOf(id)
        if ( index >= 0) {
            old.splice(index, 1)
        }
        else{
            old.push(id)
        }
        setCustomiseHeader(old);
    }

    const handleCheckAll = () => {
        let mandatory = tableHeader.allHeader.filter(h => h.isRequired);
        if(tableHeader.allHeader && tableHeader.allHeader.length > 0 && customiseHeader.length < tableHeader.allHeader.length){
            setCustomiseHeader([...tableHeader.allHeader.map(h => h._id)])
        }
        else{
            setCustomiseHeader([...mandatory.map(h => h._id)])
        }
    }

    const handleSaveHeader = () => {
        if (customiseHeader.length > 0) {
            
            dispatch(
                updateTableHeader({
                    tableType: tableType,
                    validHeaders: customiseHeader
                }, (res) => {
                    if (res) {
                        if(res.success) {
                            dispatch(
                                getTableHeader(tableName, {tableType: tableType})
                            )
                            setAnchorEl(null);
                        }
                        showToast(res.message, !res.success)
                    }
                })
            )
        }
        else{
            showToast("Table headers cannot be blank", true);
        }
    }

    useEffect(() => {
        dispatch(
            getTableHeader(tableName, {tableType: tableType})
        )
    }, [tableType])


    return (
        (
            <>
                <Button variant='outlined' size='small' sx={{...buttonClasses.lynkitOrangeEmpty}} onClick={handleOpen}>
                    Customise Table
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    sx={{maxHeight: "300px"}}
                >
                    <MenuItem >
                        <Grid container justifyContent={"center"}>
                            <Button variant='outlined' size='small' sx={{...buttonClasses.lynkitBlackEmpty, textAlign: "center", minWidth: "150px"}} onClick={handleSaveHeader}>Update Header</Button>
                        </Grid>
                    </MenuItem>
                    {
                        tableHeader && tableHeader.allHeader &&
                        <MenuItem sx={{...textClasses.normalText}}
                            onClick={handleCheckAll}
                        >
                            <Checkbox size='small' checked={customiseHeader.length == tableHeader.allHeader.length} onClick={handleCheckAll}/>
                            Select All
                        </MenuItem>
                    }
                    {
                        tableHeader && tableHeader.allHeader && tableHeader.allHeader.map((h, i) => {

                            return (
                                <MenuItem sx={{...textClasses.normalText}}
                                    key={i}
                                    onClick={(e) => handleCheck(e, h._id)}
                                    disabled={h.isRequired}
                                >
                                    <Checkbox 
                                        size='small'
                                        value={h._id} 
                                        checked={customiseHeader.includes(h._id)}
                                        onClick={(e) => handleCheck(e, h._id)}
                                        disabled={h.isRequired}
                                    />
                                    {h.fieldName}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </>
        )
    )
}
