import React, {useState, useEffect} from 'react'
import { Grid, Typography, Button, Dialog, DialogContent, IconButton } from '@mui/material'
import theme, { inputClasses, buttonClasses, textClasses, cardClasses } from '../../../../../static/styles/theme'
import { Cancel } from '@mui/icons-material';
import { getCamelCaseText } from '../../../../../services/functions';

const PackageDialog = ({open, onClose, items, setSelectedAsnItems, handleRemoveSelection, DynamicForm, setValue, handleSubmit, handlePackageAddAndOther, normalInward, receivingType}) => {
    //("Items in selected", items);
    const [showAll, setShowAll] = useState(false);
    const size = items.length;

    const getOtherDetail=(item)=>{
        let other={
            mappingArea:"PUT IT ANY WHERE",
            masterType: item.itemMasterDetail != null
        }
        
        if(item.itemMasterDetail != null && item?.itemMasterMappingArea?.length){
            other["restrictedMappingArea"]=item?.itemMasterMappingArea || []
            other["mappingArea"]=item?.itemMasterMappingArea?.at(0) || ""
        }
        return other
    }

    useEffect(() => {
        setValue("count", items.map(i => i.count).reduce((a, b) => a + b, 0))
        
    }, [open])

    return (
        <Dialog
            open={open != ""}
            onClose={onClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"xl"}
        >
            <DialogContent>
                <Typography sx={{...textClasses.cardTitle}}>{getCamelCaseText(open)} Details</Typography>
                <Grid sx={{p: 2, border: "1px solid #D9D9D9", borderRadius: "8px", mt: 2}}>
                    {
                        !normalInward
                        ?
                            <Typography sx={{...textClasses.boldText}}>Selected Items in {getCamelCaseText(open)} ({`${items.map(i => i.count).reduce((a, b) => a + b, 0)} ${items.length > 0 && items[0].formData && items[0].formData.UOM}`})</Typography>
                        : 
                            <Typography sx={{...textClasses.boldText}}>Selected Items in {getCamelCaseText(open)} ({`${items.map(i => i.count).reduce((a, b) => a + b, 0)}`})</Typography>
                    }
                    
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                        {
                            items.slice(0, showAll ? size : 2).map((st, i) => {
                                return (
                                    <Grid item sm={2} xs={3} key={i}>
                                        <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                            <Grid item sm={10} xs={8}>
                                                {
                                                    !normalInward 
                                                    ?
                                                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                        {(st.formData && st.formData.product_ID && st.formData.product_ID.length > 15) ? st.formData.product_ID.substring(0, 15) + "..." : st.formData.product_ID}
                                                        {st.formData.UOM && `(${st.count} ${st.formData.UOM})`}
                                                    </Typography>
                                                    :
                                                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                        {(st.SKU_Number.length > 15) ? st.SKU_Number.substring(0, 15) + "..." : st.SKU_Number}
                                                        {st.UOM && `(${st.count} ${st.UOM})`}
                                                    </Typography>
                                                  

                                                }
                                                {/* <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                    {(st.formData && st.formData.product_ID && st.formData.product_ID.length > 15) ? st.formData.product_ID.substring(0, 15) + "..." : st.formData.product_ID}
                                                    {st.formData.UOM && `(${st.count} ${st.formData.UOM})`}
                                                </Typography> */}

                                            </Grid>
                                            {
                                                handleRemoveSelection &&
                                                <Grid item sm={2} xs={4}>
                                                    <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st._id)}>
                                                        <Cancel sx={{ color: theme.themeOrange }} />
                                                    </IconButton>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        {
                            size > 2 &&
                            <>
                                <Grid container item sm={2} xs={3} justifyContent="center" alignItems={"center"}>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                        {
                                            showAll
                                                ?
                                                <>Collapse</>
                                                :
                                                <>View All ({size})</>
                                        }
                                    </Typography>
                                </Grid>

                            </>
                        }
                    </Grid>
                </Grid>
                <Grid sx={{p: 2, mt: 2, border: "1px solid #D9D9D9", borderRadius: "8px"}}>
                    { DynamicForm }    
                </Grid>
                <Grid container item direction={"row"} sm={12} xs={12} sx={{mt: 2}}>
                    <Button variant='contained' size='small' sx={{...buttonClasses.lynkitBlackFill, minHeight: "40px",  minWidth: "150px"}} onClick={onClose}> 
                        Cancel
                    </Button>
                    {/* <Button variant='outlined' size='small' sx={{...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px", ml: 2}}>
                        Edit Item Details
                    </Button> */}
                    {
                        !normalInward
                        ?
                    
                            <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}}
                                onClick={
                                    handleSubmit(
                                        (data) => handlePackageAddAndOther({
                                            ...data,
                                            defaultItems: items.map((it, i) => ({
                                                ASN_ComponentId: it._id,
                                                count: it.count,
                                                SKU_Number: it.formData.product_ID,
                                                SKU_Name: it.itemMasterDetail ? it.itemMasterDetail.SKU_Detail : "",
                                                UOM: it.formData ? it.formData.UOM : "",
                                                USN_Value: it.count > 1 ? "Not Applicable": "",
                                                receivingType: receivingType?.value,
                                                ...(getOtherDetail(it))    
                                            }))}, () => {
                                            onClose();
                                            setSelectedAsnItems([])
                                        })
                                    )
                                }
                            >
                                Submit {getCamelCaseText(open)}
                            </Button>
                        :
                            <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}}
                                onClick={
                                    handleSubmit(
                                        (data) => handlePackageAddAndOther({
                                            ...data,
                                            defaultItems: items
                                        }, () => {
                                            onClose();
                                            // setSelectedAsnItems([]);
                                        })
                                    )
                                }
                            >
                                Submit {getCamelCaseText(open)}
                            </Button>       
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default PackageDialog;