import React, { useEffect, useState } from "react"
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Box, Typography, Button,  } from "@mui/material";
import GenericTable from "../../Components/CustomTable/GenericTable";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";

const ViewAttachedTagsModal = ({ viewModal, setViewModal, sharedObj }) => {
    const tableData = sharedObj?.attachedTags
    const [pagination, setPagination] = useState({ page: 0, limit: 10 })
    const tableHeaders = ["S.No", "Display Name", "TID", "EPC"]
    const tableRows = tableData?.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit)?.map((row, index) => {
        let item = [];
        item.push(
            <Typography sx={{ ...textClasses.normalText }}>
                {(index + 1) + (pagination?.page * pagination?.limit)}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                {row?.displayName}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.tag_tid}
            </Typography>
        )
        item.push(
            <Typography sx={{ ...textClasses.normalText, }}>
                {row?.tag_epc}
            </Typography>
        )
        return item;
    })

    return (
        <>

            <AlertDialog
                viewDialog={viewModal === "viewAttachedTags"}
                handleClose={() => { setViewModal("") }}
                // viewSearchInput={true}
                showDivider={true}
                width="70%"
                // searchTitle="Sales Order ID:"
                title="Attached Tags Detail"
                actionsCSS={{ justifyContent: 'flex-start' }}
                body={
                    <>

                        <Box sx={{ width: "inherit" }}>
                            <GenericTable
                                data-testid="attached-tags-table"
                                header={tableHeaders}
                                rows={tableRows}
                                pageCount={tableData?.length || 0}
                                pageNumber={pagination.page}
                                handleChangePage={(event, pagevalue) => {
                                    setPagination({ ...pagination, page: pagevalue })
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                                }}
                                rowsPerPage={pagination.limit}
                            />
                        </Box>

                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal("") }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ViewAttachedTagsModal;