import React,{useState,useCallback,useEffect} from "react";
import { 
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
  Autocomplete
} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
import AlertDialog from "../../Components/DialogBox/dialogbox";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import CustomChip from "../../Components/CustomChip";
import { selectStylesOverride } from "../../../utils/util";
import { showToast ,getMonthlyMaxDays,getTimeFormISODate} from "../../../services/functions";
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import GenericLoader from "../../Components/Generic/GenericLoader";
import AddCircleIcon from '@mui/icons-material/AddCircle';

let initial_state = {
  "frequencyOption": "",
  "scheduleTime": "", "scheduleDay": "",// "dateRange": []
}

let allFrequancy = [
  { "label":"Once","value": "once", "isScheduleTimeRequired": true, "isScheduleDayRequired": false },
  { "label":"Daily","value": "daily", "isScheduleTimeRequired": false, "isScheduleDayRequired": false },
  {
    "label":"Weekly","value": "weekly", "scheduleDay": [
      "sunday", "monday", "tuesday", "wednesday",
      "thursday", "friday", "saturday"
    ], "isScheduleTimeRequired": false, "isScheduleDayRequired": true
  },
  {
    "label":"Monthly","value": "monthly", "scheduleDay":Array.from({length:getMonthlyMaxDays("current")}, (_,x) => x + 1), "isScheduleTimeRequired": false, "isScheduleDayRequired": true
  },
  {
    "label":"Custom","value": "custom", "scheduleDay": [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
      12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
      23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
      34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
      45
    ], "isScheduleTimeRequired": false, "isScheduleDayRequired": true
  },
];

const EmailReportModal = (props) => {
 
  let {
    open = false, handleClose = () => console.log("function not passed"), handleScheduleReportEmail = () => console.log("function not passed"),
    visibleEmail=[],setVisibleEmail= () => console.log("function not passed")
  } = props

  let [addEmail,setAddEmail]=useState("")
  let [state,setState]=useState(initial_state)
  let [loader,setLoader]=useState(false)

  const handleDateChange = useCallback((date, type,state) => {
    let tem_data = [], dateRange = [...state?.scheduleTime || []];
    // console.log("date",new Date(date))
    if (!date.isValid()) {
      showToast(`Please select a valid date`, true);
    }
    if (type === "time") {
      tem_data = [date];
    }
    else if (type === "from") {
      if (dateRange?.length === 2) {
        if (date?.isAfter(dateRange[1])) {
          showToast("Start Date must be smaller than End Date", true);
          tem_data = [undefined, dateRange[1] || undefined];
        }
        else {
          tem_data = [date, dateRange[1] || undefined];
        }
      } 
      else {
        tem_data = [date, dateRange[1] || undefined];
      }
    }
    else if (type === "to") {
      let tempEndDate = date
      tempEndDate = dayjs(tempEndDate).hour(23).minute(59).second(59).millisecond(999)
      if (dateRange?.length) {
        if (date?.isBefore(dateRange[0])) {
          showToast("End Date must be Greater than Start Date", true);
          tem_data = [dateRange[0] || undefined, undefined];
        } 
        else {
          tem_data = [dateRange[0] || undefined, tempEndDate];
        }
      } 
      else {
        tem_data = [undefined, tempEndDate];
      }
    }
    setState({ ...state, scheduleTime:tem_data });
  },[state.scheduleTime]);

  let checkDisable=()=>{
    // console.log("state",state)
    if(!state || !state.frequencyOption || !state.frequencyOption.value) return true

    if(!visibleEmail || visibleEmail?.length==0) return true

    if(state.frequencyOption.isScheduleTimeRequired  && !state.scheduleTime &&(!state.scheduleTime?.at(0))) return true

    if(state.frequencyOption.isScheduleDayRequired && !state.scheduleDay) return true
      
    return false
  }

  const handleEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(addEmail)) {
      setVisibleEmail([addEmail,...visibleEmail]);
      setAddEmail("");
    } else {
      showToast("Please enter valid email", true);
      return;
    }
  };

  const secheduleEmailHandler = () => {
    let isDisable=checkDisable()
    if(isDisable){
      showToast("Please select frequency, schedule(greater than current time) and email!",true)
      return;
    }
    
    if(state?.frequencyOption?.value =="once" && moment(new Date(state?.scheduleTime?.at(0))).isBefore(new Date())){
      showToast("Please select schedule time greater than current timestamp!",true)
      return;
    }

    let data = {
      "frequency": state?.frequencyOption?.value || "",
      "scheduleTime": state?.scheduleTime?.at(0) || "",
      "scheduleDay": state?.scheduleDay || ""
    }

    if(state?.frequencyOption?.value !="once"){
      data["scheduleTime"]=getTimeFormISODate(state?.scheduleTime?.at(0))
    }

    setLoader(true)
    handleScheduleReportEmail("scheduleEmail",data,()=>{
      setLoader(false)
    })

  }

  useEffect(() => {
    if (open) {
      let now = new Date()
      setState({
        ...initial_state,
        frequencyOption: allFrequancy[0],
        scheduleTime: [dayjs(new Date(now).setMinutes(now.getMinutes() + 5)),null],
      })
    }
  }, [open])
  return (
    <>
      <AlertDialog
        viewDialog={open}
        sx={{ borderRadius: "50px" }}
        title="Schedule Reports On Your Email(s)"
        body={
          <>
            {loader ? 
            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid> :
              <Box>

                {/* schedule frequancy and days input extion start */}
                <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "1rem" }}>
                  <Autocomplete
                    size="small"
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Typography variant="body2">
                            Select Frequancy *
                          </Typography>
                        }
                        sx={{
                          ...selectStylesOverride,
                          color: theme.themeOrange,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box data-testid="MenuOption" component="li" {...props}>
                        {option?.label}
                      </Box>
                    )}
                    sx={{
                      width: 250,
                      mr: 1,
                      "& legend span": {
                        color: theme.themeOrange
                      },
                    }}
                    options={allFrequancy || []}
                    onChange={(event, Option) => {
                      setState({ ...initial_state, frequencyOption: Option,scheduleTime: [dayjs(new Date().setMinutes(new Date().getMinutes() + 5)),null] })
                    }}
                    value={state?.frequencyOption?.label || ""}
                  />

                  {state?.frequencyOption?.value && state?.frequencyOption?.isScheduleDayRequired &&
                    <Autocomplete
                      size="small"
                      required
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <Typography variant="body2">
                              Select Days *
                            </Typography>
                          }
                          sx={{
                            ...selectStylesOverride,
                            color: theme.themeOrange,
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box data-testid="MenuOption" component="li"  {...props}>
                          {/* sx={{textTransform:"capitalize"}} */}
                          {option?.label}
                        </Box>
                      )}
                      sx={{
                        width: 250,
                        mr: 1,
                        "& legend span": {
                          color: theme.themeOrange
                        },
                      }}
                      options={state?.frequencyOption?.scheduleDay?.map((dl) => ({ label: dl, value: dl }))}
                      onChange={(event, option) => {
                        setState({ ...state, scheduleDay: option?.value || "" })
                      }}
                      value={(state?.scheduleDay || "")}
                    />}

                  <TimePicker
                    label="Select Schedule Time"
                    slotProps={{ textField: { size: "small" } }}
                    sx={{ ...selectStylesOverride, width: 250, color: theme.themeOrange }}
                    value={state?.scheduleTime?.at(0) || null}
                    disablePast={state?.frequencyOption?.value === "once"}
                    onChange={(d) => handleDateChange(d, "time", state)}
                    required
                  />

                </Box>

                {/* schedule frequancy and days input extion end */}

                {/* email input section start */}
                <Grid>
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      textAlign: "start",
                      margin: "25px 0 0 0 ",
                    }}
                  >
                    Enter Email(s) to get report at scheduled time periond
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <TextField
                      sx={{
                        ...selectStylesOverride,
                        ...textClasses.t12n,
                        width: "60%",
                      }}
                      size="small"
                      label="Enter email(s)"
                      variant="outlined"
                      onChange={(e) => {
                        setAddEmail(e.target.value);
                      }}
                      value={addEmail}
                    />

                    <Button
                      autoFocus
                      variant="contained"
                      spacing={1}
                      // disabled={!addEmail}
                      onClick={() => handleEmail()}
                      sx={{
                        ...buttonClasses.lynkitBlackFill,
                        // position:"relative",
                        // left:"-10px"
                        margin: "-1px 0px 0px -15px",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0"
                      }}
                    >
                   <AddCircleIcon sx={{mr:1}}/>
                      Add
                    </Button>
                  </Box>
                </Grid>

                {/* email input section end */}

                {/* email visible section start */}
                {visibleEmail?.length > 0 ?
                  <Grid container xs={12} sx={{ mt: 1 }} spacing={1}>
                    <CustomChip
                      data={visibleEmail || []}
                      makeDisabled={false}
                      handleDelete={(eleIndex, elem) => setVisibleEmail(visibleEmail.filter((dl, index) => index !== eleIndex))}
                      readLabelKey={"$self"}
                    />
                  </Grid>
                  : ""}
                {/* email visible section end */}
              </Box>}
          </>


        }
      >

        <Button
          variant="contained"
          onClick={() => handleClose()}
          sx={{ ...buttonClasses.lynkitBlackFill, m: 1, fontSize: ".7rem", minWidth: "130px" }}
        >Cancel</Button>

        <Button
          disabled={loader || checkDisable()}
          autoFocus
          variant="outlined"
          sx={{
            ...buttonClasses.lynkitOrangeFill, m: 1, fontSize: ".7rem", border: 'none', minWidth: "130px",
            backgroundColor:
            (loader || checkDisable()) ? "grey" : "#FF7200",
            border:
            (loader || checkDisable())
                ? "none"
                : "1px solid #FF7200",
          }}
          onClick={() => secheduleEmailHandler()}
        >
          Schedule Email(s)
        </Button>
      </AlertDialog>
    </>
  );
};

export default EmailReportModal;
