import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, FormControl, OutlinedInput, Card } from "@mui/material"
import SelectImage from "../../../static/images/salesOrder/Enter OTP-pana.svg"
import theme, {
    textClasses,
    cardClasses,
    inputClasses,
    buttonClasses,
} from "../../../static/styles/theme";
import { selectStylesOverride } from "../../../utils/util";
import { getPassLinkValidationToken } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../services/functions";
import history from "../../../services/history";
 

const PasscodeValidation = () => {
    const dispatch = useDispatch();
    //   const valid =useSelector((state)=>state?.SalesProcess?.passLinkValidationToken)
    //   console.log("token generated",valid)
    const [passLink, setPassLink] = useState({
        passcode: '', linkURL: window.location.href
    });
    console.log("link",passLink)

    const handleSubmit = () => {
        let payload = {
            passcode: passLink?.passcode || "",
            link: passLink?.linkURL || "",
            accessFor: "salesOrder"
        }
        dispatch(
            getPassLinkValidationToken(payload, ((data) => {
                showToast(data?.message, !data.success)
                if (data?.success) {
                    localStorage.setItem("passToken", JSON.stringify(data?.data));
                    history.push(`/saveEFormDetail?passcode=${passLink?.passcode}`)
                }
            }))
        )
    }

    const handleClear = () => {
     setPassLink({...passLink,passcode: ''})
    }
    // useEffect(()=>{


    //     return(()=>{
    //         dispatch({ type: FETCH_PASSLINK_VALIDATION_TOKEN, payload:  ""})
    //     })
    // })
    useEffect(()=>{
        localStorage.removeItem("passToken");
    },[])
    return (<Grid container justifyContent="center" alignItems="center" height="100vh" backgroundColor= "#F77200" >
        <Card item sx={{ p: 3, ...cardClasses.basic, minWidth: "64vw", minHeight: "20vh" }} className="mui-custom-card">
            {/* <Grid container item width="70vw" height="82vh" padding="2px" border={`1px solid ${theme.themeGray}`} boxShadow="0px 4px 4px 0px #00000040" > */}
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1, }}>
                <Typography sx={{ ...textClasses.cardTitle }}>
                    Enter Passcode
                </Typography>
            </Grid>
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <img src={SelectImage} width="450" height="300" />
            </Grid>
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ ...textClasses.t16n, textAlign: "center", mt: 1, fontWeight: '600' }}>
                    Please enter passcode shared along with this link
                    to fill the sales order form.             </Typography>
            </Grid>
            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <FormControl sx={{ ...selectStylesOverride, }}>

                    <OutlinedInput

                        value={passLink?.passcode}
                        onChange={(e) => setPassLink({ ...passLink, passcode: e.target.value })}
                        // placeholder='Create Passcode* (min 6 digit integer)'
                        // label="Password asd"
                        sx={{
                            ...inputClasses.textField,
                            ...selectStylesOverride,
                            minWidth: "308px",
                            height: "38px",
                            m: 0,
                            ml: 2,
                            '&: hover': {
                                borderColor: 'red'
                            },

                            // "& input::placeholder": { fontSize: "14px" } ,
                            // "& .MuiSelect-iconOutlined": {display: filters.entityId != "" ? 'none': ''}
                        }}

                    />
                </FormControl>

            </Grid>

            <Grid item sm={5} sx={{ display: "flex", justifyContent: 'space-around', mt: 2, mb: 2 }}>
                <>
                    <Button
                        variant="outlined"
                        size="medium"
                        data-testid="clear-passcode-btn"
                        sx={{
                            ...buttonClasses.lynkitBlackEmpty,
                            width: 150,

                        }}
                        onClick={handleClear}

                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        data-testid="submit-passcode-btn"
                        sx={{
                            ...buttonClasses.lynkitOrangeFill,
                            width: 150,
                            ml: 2

                        }}
                        onClick={handleSubmit}

                    >
                        Submit
                    </Button>
                </>
            </Grid>
            {/* </Grid> */}
        </Card>
    </Grid>)
}

export default PasscodeValidation;