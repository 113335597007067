import React, { useEffect } from 'react';
import { Box } from '@mui/material';


const Integration = ({myVariable,baseURL}) => {
    useEffect(() => {
        window.addEventListener("message", receivedMessage, false);
        function receivedMessage(evt) {
            try {
                let data = JSON.parse(evt.data);
                if (evt.origin === baseURL) {
                    // console.log("data key",data,data?.perkey,data?.perkey === 'sendphrase')
                    if (data?.perkey == 'sendphrase') {
                        // console.log("source");
                        evt.source.postMessage({ phrase: localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):"" }, evt.origin);
                    }
                    // console.log("evt.data",evt.data);
                    if (evt.data.frameHeight) {
                        const iframe = document.getElementById('frame');
                        iframe.style.height = evt.data.frameHeight + 'px';
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        return () => {
            window.removeEventListener("message", receivedMessage, false);
        };
    }, []);

    return (
        <Box sx={{
            overflow: "hidden"
        }}>
            <Box sx={{
               overflow: "hidden"
            }}>
                <iframe id="frame" src={myVariable} style={{
                    border: "none",
                    // height: "800px",
                    height: "80vh",
                    width: "100%",
                }}
                ></iframe>
            </Box>
        </Box>
    );
};

export default Integration;
