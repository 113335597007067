import { INIT_CANVAS, ADD_SECTION, REMOVE_SECTION, ADD_FIELD, REMOVE_FIELD, EDIT_FIELD, SET_EDIT_FIELD, REMOVE_EDIT_FIELD, SELECT_TEMPLATE, REPLACE_EDIT_CANVAS, SELECT_SUB_TEMPLATE, FETCH_SUPPLIER_MASTER_TEMPLATES, FETCH_CUSTOMER_MASTER_TEMPLATES, FETCH_ITEM_MASTER_TEMPLATES, SELECT_SUPPLIER_MASTER, SELECT_CUSTOMER_MASTER, SELECT_ITEM_MASTER, IS_ADDRESS_BOOK_CONNECTED, SET_ADDRESS_BOOK_FIELD } from "../types";
import config from "../../services/config";

export const cleanCanvas = (uomList=[]) => {
    const canvas = {
        attributes: {
            status: true,
            mandatory: [
                {
                    handle: true,
                    type: "input",
                    label: "Shipment Id",
                    _id: "customShipment",
                    required: true,
                    placeholder: "Enter your shipment id",
                    description: "Enter your custom shipment id",
                    subtype: "text",
                    // regex: idSettings.idPrefix.numberSeries.status ? numRegex : alphaRegex,
                    regex: "",
                    templateType: "inwardDataEntry"
                },
                {
                    "handle": true,
                    "_id": "packageQuantity",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Quantity",
                    "description": "Enter your number",
                    "placeholder": "Enter your number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter numeric value only",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "generatePackageCount": true,
                    "delete": false,
                    "value": '',
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                // {type: "input", label: "Product Name", _id: "productName", templateType: "inwardDataEntry", "placeholder": "Enter your product name"},
                {
                    "handle": true,
                    "_id": "dispatchOrderNo",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Dispatch Order No",
                    "description": "Enter your number",
                    "placeholder": "Enter your number",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "generatePackageCount": true,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "outwardDataEntry"
                },
                
                {
                    "handle": true,
                    "_id": "SKU_ID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "SKU ID",
                    "description": "Enter your SKU ID",
                    "placeholder": "Enter your SKU ID",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "itemMaster"
                },
                
                {
                    "handle": true,
                    "_id": "SKU_Detail",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "SKU Detail",
                    "description": "Enter SKU Detail",
                    "placeholder": "Enter SKU Detail",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "itemMaster"
                },
                {
                    "_id": "UOM",
                    "type": "select",
                    "label": "Unit Of Measurement (UOM)",
                    "description": "unit of measurements",
                    "placeholder": "select Unit",
                    // "values": uomList.map(u => ({label: u.unitName, value: u.unitValue})),
                    "required": true,
                    dynamic: true,
                    auth: true,
                    keyForValue: "unitValue",
                    keyForLabel: "unitName",
                    apiDomain: config.baseURL || "/v1/",
                    apiEndPoint: "base/getUOMList",
                    forType: "all",
                    apiMethod: "post",
                    name: "UOM List",
                    apiDataReturnKey: "data",
                    apiParameters: "data",
                    fetched: false,
                    templateType: "itemMaster"
                },
                {
                    "handle": true,
                    "_id": "stockTransferOrder",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Stock Transfer Order",
                    "description": "Stock Transfer Order",
                    "placeholder": "Stock Transfer Order",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "stockTransfer"
                },
                {
                    "handle": true,
                    "_id": "reason",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Reason",
                    "description": "Reason",
                    "placeholder": "Reason",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "stockTransfer"
                },
                {
                    "handle": true,
                    "_id": "shipmentID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Shipment ID",
                    "description": "Shipment ID",
                    "placeholder": "Shipment ID",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    "templateType": "qualityCheck"
                },
                {
                    "handle": true,
                    "_id": "invoiceID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Invoice ID",
                    "description": "Invoice ID",
                    "placeholder": "Invoice ID",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    "templateType": "qualityCheck"
                },
                {
                    "handle": true,
                    "_id": "checkList",
                    "type": "radio",
                    "icon": "simple-icon-note",
                    "label": "Type your Question",
                    "description": "Type your Question",
                    "placeholder": "Type your Question",
                    "className": "form-control",
                    "elementType":"Selection Type",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "values": [
                        {
                            "label": "Yes",
                            "value": "yes"
                        },
                        {
                            "label": "No",
                            "value": "no"
                        }
                    ],
                    "hideDataForInvoice": false,
                    "templateType": "qualityCheck"
                },
                {
                    "handle": true,
                    "_id": "referenceID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Reference ID",
                    "description": "Reference ID",
                    "placeholder": "Reference ID",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "inventoryAdjustment"
                },
                {
                    "handle": true,
                    "_id": "quantity",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Quantity",
                    "description": "Quantity",
                    "placeholder": "Quantity",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "inventoryAdjustment"
                },
                {
                    "handle": true,
                    "_id": "SKU",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "SKU",
                    "description": "SKU",
                    "placeholder": "SKU",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "inventoryAdjustment"
                },
                {
                    "handle": true,
                    "_id": "reason",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Reason",
                    "description": "Reason",
                    "placeholder": "Reason",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "inventoryAdjustment"
                },
                {
                    "handle": true,
                    "_id": "purchaseOrderID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Purchase Order ID",
                    "description": "Purchase Order ID",
                    "placeholder": "Purchase Order ID",
                    "className": "form-control",
                    "subtype": "text",
                    "forType": "table",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "ASNTemplate"
                },
                {
                    "handle": true,
                    "_id": "quantity",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Order Quantity",
                    "description": "Order Quantity",
                    "placeholder": "Order Quantity",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter numeric value only",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "ASNTemplate"
                }
            ],
            other: []
        },
        packageTemplate: {
            status: true,
            mandatory: [
                {
                    handle: true,
                    type: "input",
                    label: "Package Id",
                    _id: "customPkg",
                    required: true,
                    placeholder: "Enter your package id",
                    description: "Enter your custom package id",
                    subtype: "text",
                    // regex: idSettings.idPrefix.numberSeries.status ? numRegex : alphaRegex,
                    regex: "",
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "packageName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Package Name",
                    "description": "Enter Package name",
                    "placeholder": "Package name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "hideLabel": false,
                    "multiple": false,
                    "required": true,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "packageDescription",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Package Description",
                    "description": "Enter Package Description",
                    "placeholder": "Package description",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "hideLabel": false,
                    "multiple": false,
                    "required": false,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                // {type: "input", label: "Package Name", _id: "packageName"},
                // {type: "input", label: "Package Description", _id: "packageDescription", required: false},
                {
                    "handle": true,
                    "_id": "count",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Count",
                    "description": "Enter count",
                    "placeholder": "Enter your count placeholder",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter numeric value only",
                    "min": "1",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "UOM",
                    "type": "select",
                    "label": "Unit Of Measurement (UOM)",
                    "description": "unit of measurements",
                    "placeholder": "select Unit",
                    // "values": uomList.map(u => ({label: u.unitName, value: u.unitValue})),
                    "required": true,
                    dynamic: true,
                    auth: true,
                    keyForValue: "unitValue",
                    keyForLabel: "unitName",
                    apiDomain: config.baseURL || "/v1/",
                    apiEndPoint: "base/getUOMList",
                    forType: "all",
                    apiMethod: "post",
                    name: "UOM List",
                    apiDataReturnKey: "data",
                    apiParameters: "data",
                    fetched: false,
                    templateType: "inwardDataEntry"
                },
                // {type: "input", label: "Count", _id: "count", regex: "^[0-9]*$"}
            ],
            other: []
        },
        itemTemplate: {
            status: true,
            mandatory: [
                {
                    handle: true,
                    type: "input",
                    label: "Item Id",
                    _id: "customItem",
                    required: true,
                    placeholder: "Enter your item id",
                    description: "Enter your custom item id",
                    subtype: "text",
                    // regex: idSettings.idPrefix.numberSeries.status ? numRegex : alphaRegex,
                    regex: "",
                    templateType: "inwardDataEntry"
                },
                // {type: "input", label: "SKU Number", _id: "SKU_Number"},
                {
                    "_id": "SKU_Number",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "SKU Number",
                    "description": "Enter your SKU number",
                    "placeholder": "SKU Number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "isSKUNumber": true,
                    "hideLabel": false,
                    "multiple": false,
                    "required": true,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "SKU_Name",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "SKU Name",
                    "description": "Enter SKU name",
                    "placeholder": "SKU Name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "hideLabel": false,
                    "multiple": false,
                    "required": true,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                // {type: "input", label: "SKU Name", _id: "SKU_Name"},
                {
                    "_id": "USN_Value",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "USN",
                    "description": "Enter USN",
                    "placeholder": "USN",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "hideLabel": false,
                    "multiple": false,
                    "required": false,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                {
                    "handle": true,
                    "_id": "count",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Count",
                    "description": "Enter count",
                    "placeholder": "Enter your count placeholder",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter numeric value only",
                    "min": "1",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "hideDataForInvoice": false,
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "UOM",
                    "type": "select",
                    "label": "Unit Of Measurement (UOM)",
                    "description": "unit of measurements",
                    "placeholder": "select Unit",
                    // "values": uomList.map(u => ({label: u.unitName, value: u.unitValue})),
                    "required": true,
                    dynamic: true,
                    auth: true,
                    keyForValue: "unitValue",
                    keyForLabel: "unitName",
                    apiDomain: config.baseURL || "/v1/",
                    apiEndPoint: "base/getUOMList",
                    forType: "all",
                    apiMethod: "post",
                    name: "UOM List",
                    apiDataReturnKey: "data",
                    apiParameters: "data",
                    fetched: false,
                    templateType: "inwardDataEntry"
                },
                {
                    "_id": "product_ID",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Product ID/SKU",
                    "description": "Product ID/SKU",
                    "placeholder": "Product ID/SKU",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "handle": true,
                    "forType": "all",
                    "elementType": "Text and input",
                    "sortType": 1,
                    "value": "",
                    "delete": false,
                    "errorText": "",
                    "hideLabel": false,
                    "multiple": false,
                    "required": true,
                    "conditionalView": false,
                    "sourceElement": "",
                    "sourceValue": "",
                    "hideDataForInvoice": false,
                    templateType: "ASNTemplate"
                },
                {
                    "handle": true,
                    "_id": "quantity",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Quantity",
                    "description": "Quantity",
                    "placeholder": "Quantity",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter numeric value only",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": "",
                    "hideDataForInvoice": false,
                    templateType: "ASNTemplate"
                },
                {
                    "_id": "UOM",
                    "type": "select",
                    "label": "Unit Of Measurement (UOM)",
                    "description": "unit of measurements",
                    "placeholder": "select Unit",
                    // "values": uomList.map(u => ({label: u.unitName, value: u.unitValue})),
                    "required": true,
                    dynamic: true,
                    auth: true,
                    keyForValue: "unitValue",
                    keyForLabel: "unitName",
                    apiDomain: config.baseURL || "/v1/",
                    apiEndPoint: "base/getUOMList",
                    forType: "all",
                    apiMethod: "post",
                    name: "UOM List",
                    apiDataReturnKey: "data",
                    apiParameters: "data",
                    fetched: false,
                    templateType: "ASNTemplate"
                },
                // {type: "input", label: "USN", _id: "USN_Value"},
                // {type: "number", label: "Count", _id: "count"}
            ],
            other: []
        }
    }
    return canvas;
}

export const convertToCanvasObject = (categories) => {
    const canvas = {
        attributes: {
            status: true,
            mandatory: [],
            other: []
        },
        packageTemplate: {
            status: true,
            mandatory: [],
            other: []
        },
        itemTemplate: {
            status: true,
            mandatory: [],
            other: []
        }
    };
    for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        for (let j = 0; j < category.allTemplateTypes.length; j++) {
            const template = category.allTemplateTypes[j];
            canvas.attributes.mandatory = [
                ...canvas.attributes.mandatory,
                ...template.attributes.map(attr => {
                    const newAttr = {
                        ...attr,
                        templateType: template.templateTypekey
                    }
                    if(attr._id == "UOM"){
                        newAttr.apiMethod = "post"
                    }
                    return newAttr
                })
            ];
            if(template.packageAttributes){
                canvas.packageTemplate.mandatory = [
                    ...canvas.packageTemplate.mandatory,
                    ...template.packageAttributes.map(attr => {
                        const newAttr = {
                            ...attr,
                            templateType: template.templateTypekey
                        }
                        if(attr._id == "UOM"){
                            newAttr.apiMethod = "post"
                        }
                        return newAttr
                    })
                ];
            }
            if(template.itemAttributes){
                canvas.itemTemplate.mandatory = [
                    ...canvas.itemTemplate.mandatory,
                    ...template.itemAttributes.map(attr => {
                        const newAttr = {
                            ...attr,
                            templateType: template.templateTypekey
                        }
                        if(attr._id == "UOM"){
                            newAttr.apiMethod = "post"
                        }
                        return newAttr
                    })
                ];
            }
        }
        
    }
    //("Returning canvas", canvas);
    return canvas
}

const INIT_STATE = {
    components: convertToCanvasObject([]),
    editField: null,
    selectedTemplate: "-",
    selectedSubTemplate: "-",
    supplierMasterTemplates: [],
    customerMasterTemplates: [],
    itemMasterTemplates: [],
    selectedSupplierMaster: "-",
    selectedCustomerMaster: "-",
    selectedItemMaster: "-",

}

export default (state=INIT_STATE, action) => {
    let newComps;
    let sectionId = action.payload && action.payload.sectionId;

    switch(action.type) {

        case SELECT_TEMPLATE: return { ...state, selectedTemplate: action.payload.templateType, selectedSubTemplate: action.payload.subTemplateType, components: action.payload.canvas }

        case INIT_CANVAS: return { ...state, components: action.payload, selectedSupplierMaster: "-", selectedCustomerMaster: "-", selectedItemMaster: "-" }

        case REPLACE_EDIT_CANVAS: return { ...state, selectedTemplate: action.payload.templateType, selectedSubTemplate: action.payload.templateCategory, components: action.payload.newComponents }

        case ADD_SECTION:
            
            newComps = {...state.components};
            newComps[sectionId].status = true;
            return { ...state, components: newComps }
        
        case REMOVE_SECTION:
            newComps = {...state.components};
            newComps[sectionId].status = false;
            newComps[sectionId].other = []
            return { ...state, components: newComps }

        case ADD_FIELD:
            newComps = {...state.components};
            newComps[sectionId].other = [...newComps[sectionId].other, ...action?.payload?.fields]
            return { ...state, components: newComps }

        case REMOVE_FIELD:
            if(action?.payload?.type == "other"){
            newComps = {...state.components}
            let newArr = [...newComps[sectionId].other]
            newArr.splice(action.payload.index, 1);
            newComps[sectionId].other = newArr
            return { ...state, components: newComps}
            }
            else { 
                newComps = {...state.components}
                let newArr = [...newComps[sectionId].addressBook]
                newArr.splice(action.payload.index, 1);
                newComps[sectionId].addressBook = newArr
                return { ...state, components: newComps}
            }

        case SET_EDIT_FIELD: return {...state, editField: {...action.payload}}

        case REMOVE_EDIT_FIELD: return {...state, editField: null}

        case EDIT_FIELD:
            newComps = JSON.parse(JSON.stringify(state.components))
            newComps[sectionId][action.payload.type][action.payload.index] = action.payload.field
            return { ...state, components: newComps}

        case FETCH_SUPPLIER_MASTER_TEMPLATES: return {...state, supplierMasterTemplates: action.payload}
        case FETCH_CUSTOMER_MASTER_TEMPLATES: return {...state, customerMasterTemplates: action.payload}
        case FETCH_ITEM_MASTER_TEMPLATES: return {...state, itemMasterTemplates: action.payload} 
        case SELECT_ITEM_MASTER: return {...state, selectedItemMaster: action.payload}
        case SET_ADDRESS_BOOK_FIELD:
            newComps = {...state.components};
            newComps[sectionId].addressBook = action?.payload?.fields || [];
            return { ...state, components: newComps }
        default: return state
            

    }
}