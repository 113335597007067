import React, { useState } from "react";
import { textClasses } from "../../../../../static/styles/theme";
import { Autocomplete, Grid, Button, Typography,Breadcrumbs,Link } from "@mui/material";
import SidebarCard from "./SidebarCard";
import AutomationTriggers from "./AutomationTriggers";
import { AUTOMATION_TRIGGER, JOB_CREATION_STEP } from "../constant";
import AutomationHeader from "./AutomationHeader";
import JobCreationStep from "./JobCreationStep";
const CreateRuleAutomation = () => {
  const [automationHeader, setAutomationHeader] = useState(
    "Automation Rule Name"
  );
  const [warehouseFilter, setWarehouseFilter] = useState(null);
  const [step, setStep] = useState(AUTOMATION_TRIGGER);
  const [selectJobType, setSelectJobType] = useState(null);
  const [creationStep, setCreationStep] = useState("");
  const [editHeader, setEditHeader] = useState(false);
  const [jobTypefilter, setJobTypefilter] = useState(null);

  const editHandler = (e) => {
    setAutomationHeader(e.target.value);
  };

  const isAutomationTrigger = step === AUTOMATION_TRIGGER;
  const isJobCreationStep = step === JOB_CREATION_STEP;
  return (
    <>
      <Grid
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Grid item xs={2}>
          <Typography
            variant="h6"
            sx={{ ...textClasses.cardTitle, color: "#000000" }}
          >
            Automation Rules
          </Typography>
          <Breadcrumbs
            sx={{
              ...textClasses.t12n,
            }}
            aria-label="breadcrumb"
          >
            <Link
              sx={{
                color: "#FF7200",
                textTransform: "none",
              }}
              underline="hover"
              color="inherit"
              href=""
            >
              Setting
            </Link>
            <Link
              sx={{
                color: "#FF7200",
                textTransform: "none",
              }}
              underline="hover"
              color="inherit"
              href="/dashboard/setting/automation-rules"
            >
              AutomationRules
            </Link>
            <Typography
              sx={{
                textTransform: "none",
                ...textClasses.t12n,
              }}
              color="text.primary"
            >
              CreateRule
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Grid
        sx={{
          flexWrap: "nowrap",
          marginTop: "20px",
        }}
        container
        data-testid="create-automation"
      >
        <SidebarCard
          setStep={setStep}
          isJobCreationStep={isJobCreationStep}
          selectJobType={selectJobType}
          setCreationStep={setCreationStep}
          creationStep={creationStep}
          jobTypefilter={jobTypefilter}
        />
        <Grid sx={{ width: "77%", marginLeft: "20px" }}>
          <AutomationHeader
            setWarehouseFilter={setWarehouseFilter}
            warehouseFilter={warehouseFilter}
            editHandler={editHandler}
            editHeader={editHeader}
            setEditHeader={setEditHeader}
            automationHeader={automationHeader}
          />
          {isAutomationTrigger && (
            <AutomationTriggers
              setCreationStep={setCreationStep}
              setStep={setStep}
            />
          )}
          {isJobCreationStep && (
            <JobCreationStep
              selectJobType={selectJobType}
              setSelectJobType={setSelectJobType}
              setCreationStep={setCreationStep}
              creationStep={creationStep}
              jobTypefilter={jobTypefilter}
              setJobTypefilter={setJobTypefilter}
              resetStates={()=>{
                setSelectJobType(null)
                setCreationStep("")
                setJobTypefilter(null)
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default CreateRuleAutomation;
