import { Cancel } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import theme, { textClasses } from '../../../static/styles/theme';

export default function GenericCollapsableItemList({items, limit, getContent, handleRemoveItem, itemSpacing, ...other}) {
    const [show, setShow] = useState(false);
    const size = items.length;

    return (
        <Grid container spacing={2} {...other}>
            {
                items.slice(0, show ? size : limit).map((st, i) => {
                    return (
                        <Grid item sm={itemSpacing.sm} xs={itemSpacing.xs} key={i}>
                            <Grid container direction={"row"}  sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px"}}>
                                <Grid container item sm={10} xs={8} alignItems={"center"} sx={{height: "30px" }}>
                                    <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start", ml: 1 }}>
                                        {getContent(items, i)}
                                    </Typography>
                                </Grid>
                                {
                                    handleRemoveItem &&
                                    <Grid item sm={2} xs={4} justifyContent="right" alignItems={"center"} sx={{height: "30px" }}>
                                        <IconButton sx={{ alignContent: "end" ,height: "30px" }} onClick={() => handleRemoveItem(i, st)}>
                                            <Cancel sx={{ color: theme.themeOrange }} />
                                        </IconButton>
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    )
                })
            }
            {
                size > 3 &&
                <>
                    <Grid container item sm={itemSpacing.sm} xs={itemSpacing.xs} justifyContent="center" alignItems={"center"}>
                        <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShow(!show)}>
                            {
                                show
                                    ?
                                    <>Collapse</>
                                    :
                                    <>View All ({size})</>
                            }
                        </Typography>
                    </Grid>

                </>
            }
        </Grid>
    )
}
