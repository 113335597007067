import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BarcodeImage from "../../../static/images/data_entry/barcode.svg"

import {
    Grid, Typography, Select, MenuItem, Box,
    Card, CardHeader, CardContent, CardActions, CardMedia,
    Avatar, Divider, Paper, Tabs, Tab, Breadcrumbs, FormControl, FormControlLabel, Checkbox, Radio, Button
} from '@mui/material'

import theme, { buttonClasses, cardClasses, textClasses } from "../../../static/styles/theme"
import { changeInventorySettings, fetchInventorySettings } from '../../../redux/actions';
import { getCamelCaseText } from '../../../services/functions';
import { parseCamelCase } from '../../../utils/util';
import GenericLoader from '../../Components/Generic/GenericLoader';

const Section = ({section, settings, setSettings}) => {
    // let idPrefixKeys = ["alphaNumber", "numberSeries"];
    
    // if(section == "Package"){
    //     idPrefixKeys = ["linkedWithShipment"].concat(idPrefixKeys);
    // }
    // if(section == "Item"){
    //     idPrefixKeys = ["linkedWithShipment", "linkedWithPkg"].concat(idPrefixKeys);
    // }

    // let inputTypeKeys = ["auto", "manual"];

    let idPrefixKeys = settings && settings.idPrefix ? Object.keys(settings.idPrefix) : [];
    let inputTypeKeys = settings && settings.inputType ? Object.keys(settings.inputType) : [];

    const handleSettingsChange = (category, e) => {
        ////("Event ", e.target.value);
        let oldSettings = {...settings};
        if(category == "prefix"){
            idPrefixKeys.filter(i => i != e.target.value).forEach(id => {
                oldSettings.idPrefix[id].status = false
            });
            oldSettings.idPrefix[e.target.value].status = !oldSettings.idPrefix[e.target.value].status;
            let inputTypeOption=oldSettings.idPrefix[e.target.value]?.inputType || "all"
            if(inputTypeOption && inputTypeOption!="all"){
                inputTypeKeys.filter(i => i != inputTypeOption).forEach(id => {
                    oldSettings.inputType[id].status = false
                    oldSettings.inputType[id].hide = true
                });
                oldSettings.inputType[inputTypeOption].status=true
            }
            else{
                inputTypeKeys.forEach(id => {
                    oldSettings.inputType[id].hide = false
                });
            }
        }
        else{
            inputTypeKeys.filter(i => i != e.target.value).forEach(id => {
                oldSettings.inputType[id].status = false
            });
            oldSettings.inputType[e.target.value].status = !oldSettings.inputType[e.target.value].status;
        }
        setSettings(oldSettings);
    }

    return (
        <Paper sx={{...cardClasses.basic, border: `1px solid ${theme.themeOrange}`, borderRadius: "8px", p: 1, pb: 2, mt: 1, mb: 2}}>
            <FormControl sx={{ml: 1}}>
                <FormControlLabel
                    control={
                        <Checkbox size='large' sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} />
                    }
                    label={
                        <Typography sx={{...textClasses.boldText, fontSize: "20px"}}>
                            {section} Label*
                        </Typography>
                    }
                />
            </FormControl>
            <Grid container sx={{pl: 3, pr: 2}}>
                <Grid container item sm={2} xs={3} alignItems="center">
                    <Typography sx={{...textClasses.boldText, fontSize: "18px"}}>ID Prefix:</Typography>
                </Grid>
                <Grid container item sm={10} xs={9}>
                    {
                        idPrefixKeys.map((id, i) => {
                           
                            return (
                                <Grid container item sm={6} xs={6} alignItems="center" justifyContent={"left"} key={i}>
                                    <FormControl sx={{ml: 1}}>
                                        <FormControlLabel
                                            value={id}
                                            onChange={(e) => handleSettingsChange("prefix", e)}
                                            control={
                                                <Radio size='small' checked={settings.idPrefix[id].status} sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} />
                                            }
                                            label={
                                                <Typography sx={{...textClasses.normalText, fontSize: "18px"}}>
                                                    {settings.idPrefix[id].label}
                                                </Typography>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            )
                        
                        })
                    }
                    {/* {
                        idPrefixKeys.map((id, i) => {
                            return (
                                <Grid container item sm={6} xs={6} alignItems="center" justifyContent={"left"} key={i}>
                                    <FormControl sx={{ml: 1}}>
                                        <FormControlLabel
                                            value={id}
                                            onChange={(e) => handleSettingsChange("prefix", e)}
                                            control={
                                                <Radio size='small' checked={settings[id]} sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} />
                                            }
                                            label={
                                                <Typography sx={{...textClasses.normalText, fontSize: "18px"}}>
                                                    {parseCamelCase(id)}
                                                </Typography>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            )
                        })
                    } */}
                </Grid>
            </Grid>
            <Grid container sx={{pl: 3, pr: 2}}>
                <Grid container item sm={2} xs={3} alignItems="center">
                    <Typography sx={{...textClasses.boldText, fontSize: "18px"}}>Input Type:</Typography>
                </Grid>
                <Grid container item sm={10} xs={9}>
                    {
                        inputTypeKeys.map((id, i) => {
                            if(!(settings.inputType[id]?.hide || false)){
                            return (
                                <Grid container item sm={6} xs={6} alignItems="center" justifyContent={"left"} key={i}>
                                    <FormControl sx={{ml: 1}}>
                                        <FormControlLabel
                                            value={id}
                                            onChange={(e) => handleSettingsChange("type", e)}
                                            control={
                                                <Radio size='small' checked={settings.inputType[id].status} sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} />
                                            }
                                            label={
                                                <Typography sx={{...textClasses.normalText, fontSize: "18px"}}>
                                                    {settings.inputType[id].label}
                                                </Typography>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            )
                            }
                        })
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default function IdentificationConfig() {

    const dispatch = useDispatch();
    const inventorySettings = useSelector(state => state.inventory.inventorySettings);

    // const [shipmentSettings, setShipmentSettings] = useState({
    //     numberSeries: false, alphaNumber: false, auto: false, manual: false,
    //     "idPrefix": {
    //         "alphaNumber": {
    //             "label": "Alpha Number",
    //             "status": false
    //         },
    //         "numberSeries": {
    //             "label": "Number Series",
    //             "status": false
    //         }
    //     },
    //     "inputType": {
    //         "auto": {
    //             "label": "Auto",
    //             "status": false
    //         },
    //         "manual": {
    //             "label": "Manual",
    //             "status": false
    //         }
    //     }
    // })
    // const [packageSettings, setPackageSettings] = useState({
    //     linkedWithShipment: false, numberSeries: false, alphaNumber: false, auto: false, manual: false,
    //     "idPrefix": {
    //         "linkedWithShipment": {
    //             "label": "Linked with Shipment",
    //             "status": false
    //         },
    //         "alphaNumber": {
    //             "label": "Alpha Number",
    //             "status": false
    //         },
    //         "numberSeries": {
    //             "label": "Number Series",
    //             "status": false
    //         }
    //     },
    //     "inputType": {
    //         "auto": {
    //             "label": "Auto",
    //             "status": false
    //         },
    //         "manual": {
    //             "label": "Manual",
    //             "status": false
    //         }
    //     }
    // })
    // const [itemSettings, setItemSettings] = useState({
    //     linkedWithShipment: false, linkedWithPkg: false, numberSeries: false, alphaNumber: false, auto: false, manual: false,
    //     "idPrefix": {
    //         "linkedWithShipment": {
    //             "label": "Linked with Shipment",
    //             "status": false
    //         },
    //         "linkedWithPkg": {
    //             "label": "Linked with Package",
    //             "status": false
    //         },
    //         "alphaNumber": {
    //             "label": "Alpha Number",
    //             "status": false
    //         },
    //         "numberSeries": {
    //             "label": "Number Series",
    //             "status": false
    //         }
    //     },
    //     "inputType": {
    //         "auto": {
    //             "label": "Auto",
    //             "status": false
    //         },
    //         "manual": {
    //             "label": "Manual",
    //             "status": false
    //         }
    //     }
    // })

    const [shipmentSettings, setShipmentSettings] = useState(null)
    const [packageSettings, setPackageSettings] = useState(null)
    const [itemSettings, setItemSettings] = useState(null)

    const [loader, setLoader] = useState(false);
//    //(inventorySettings)
    const isChanged = (() => {
        if(inventorySettings){
            let initialSetting = {
                shipment: inventorySettings.shipment,
                pkg: inventorySettings.pkg,
                item: inventorySettings.item
            }
            let currentSetting = {
                shipment: shipmentSettings,
                pkg: packageSettings,
                item: itemSettings
            }
            return _.isEqual(initialSetting, currentSetting);
        }
        return false;
        
    })();

    const expandKeys = (data) => {
        let expanded = {}
        let outerKeys = Object.keys(data);

        for (let i = 0; i < outerKeys.length; i++) {
            const section = outerKeys[i];
            let innerKeys = Object.keys(data[section]);
            for (let j = 0; j < innerKeys.length; j++) {
                const element = innerKeys[j];
                // ////("ELEMETN", element);
                expanded[element] = data[section][element].status
            }
            
        }
        return expanded

    }

    const handleChange = (type) => {
        // const payload = {
        //     shipment: expandKeys(shipmentSettings),
        //     pkg: expandKeys(packageSettings),
        //     item: expandKeys(itemSettings)
        // }
        const payload = {
            shipment: shipmentSettings,
            pkg: packageSettings,
            item: itemSettings
        }
        ////(payload);
        setLoader(true);
        dispatch(
            changeInventorySettings(type, payload,() => {
                setLoader(false);
            })
        )
    }

    useEffect(() => {
        setLoader(true);
        dispatch(
            fetchInventorySettings({}, (res) => {
                setLoader(false);
                if(res){
                    setShipmentSettings(res.shipment);
                    setPackageSettings(res.pkg);
                    setItemSettings(res.item);
                }
                
            })
        )
    }, [])

    

    return (
        <>
            <Typography sx={{...textClasses.cardTitle}}>Inventory Identification Configuration</Typography>
            <Typography sx={{...textClasses.normalText}}>Define how you want to identify your inventories inside the warehouse.</Typography>
            <Grid container spacing={2} sx={{mt: 1}} direction="row" alignItems={"stretch"}>
                <Grid item sm={8} xs={10}>
                    <Card sx={{borderRadius: "8px"}}>
                        <CardHeader
                            sx={
                                {
                                    // pt: 1,
                                    // pl: 2,
                                    // pb: 1,
                                    p: 3,
                                    borderRadius: "8px 8px 0px 0px",
                                    backgroundColor: theme.themeOrange,
                                    color: "white",
                                    boxShadow: "0px 2px 6px gray"
                                }
                            }

                            title={
                                <Typography sx={{...textClasses.cardTitle, color: "white"}}>Labelling System</Typography>
                            }
                        />
                        <CardContent>
                            {
                                !loader
                                ?
                                    <>
                                        {
                                            shipmentSettings &&
                                            <Section section={"Shipment"} settings={shipmentSettings}  setSettings={setShipmentSettings}/>
                                        }
                                        {
                                            packageSettings &&
                                            <Section section={"Package"} settings={packageSettings}  setSettings={setPackageSettings}/>
                                        }
                                        {
                                            itemSettings &&
                                            <Section section={"Item"} settings={itemSettings} setSettings={setItemSettings}/>
                                        }
                                        {
                                            inventorySettings
                                            ?
                                                <Button variant="outlined" sx={{...buttonClasses.lynkitBlackFill, backgroundColor: "black", fontSize: "18px", minWidth: "150px", mt: 1}} onClick={() => handleChange("update")} >Update</Button>
                                            :
                                                <Button variant="outlined" sx={{...buttonClasses.lynkitBlackFill, backgroundColor: "black", fontSize: "18px", minWidth: "150px", mt: 1}} onClick={() => handleChange("add")} >Add</Button>
                                        }
                                    </>
                                :
                                    <Grid container justifyContent={"center"} alignItems="center" sx={{mt: 2, mb: 2}}>
                                        <GenericLoader />
                                    </Grid>
                            }
                            
                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={4} xs={2}>
                    <Paper sx={{...cardClasses.basic, border: `1px solid ${theme.themeOrange}`, borderRadius: "8px", backgroundColor: theme.themeLightOrange, p: 2, pb: 8}}>
                        <Grid container  sx={{mb: 18}} >
                            <Grid container item justifyContent={"center"} alignItems="center">
                                <img src={BarcodeImage} height="250" style={{marginTop: "50px"}} />
                                <Typography sx={{fontSize: "30px", fontWeight: "700", lineHeight: "41px", color: theme.themeOrange, textAlign: "center", mt: 5}}>
                                    Need of labelling in warehouse management
                                </Typography>
                                <Typography sx={{fontSize: "16px", fontWeight: "600", lineHeight: "22px", color: theme.themeOrange, textAlign: "center", mt: 4}}>
                                    To identifying items within the storage facility with reference to the areas assigned for stacking it. Use Lynkgrid WMS powered digital solutions requires in the form of barcode labels and RFID tags
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
