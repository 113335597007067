import React, { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Typography, Divider, TextField, Button, IconButton, Modal, Card, Select, FormControlLabel, Checkbox, ButtonGroup, CircularProgress, Tooltip, ImageList, ImageListItem, Dialog, DialogContent, Box, FormControl } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses  } from '../../../../../static/styles/theme'

export default function GenericCard({asn,spiType, sectionKey, pIndex, packageObj, handleOpenModal,  handleDeleteObject, handleOpenEditModal, dimensions={}, mandatoryFieldsId}) {
    //("MANDO", mandatoryFieldsId);
    
    return (
        <Paper sx={{...cardClasses.basic, borderRadius: "8px", backgroundColor: theme.themeLightOrange, p: 2}}>
            
            {
                sectionKey == "package"
                ?
                    <>
                        {
                            mandatoryFieldsId.indexOf("packageName") >= 0 &&
                        
                            <Grid container alignItems={"center"}>
                                <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Package Name: </Typography>
                                <Tooltip title={packageObj.packageName}>
                                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 1}}>
                                        {packageObj.packageName && packageObj.packageName.length > 14 ? packageObj.packageName.substring(0, 14) + "..." : packageObj.packageName}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        }
                    </>
                :
                    <>
                        {
                            mandatoryFieldsId.indexOf("SKU_Name") >= 0 &&
                        
                            <Grid container alignItems={"center"}>
                                <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Item Number: </Typography>
                                <Tooltip title={packageObj.SKU_Name}>
                                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 1}}>
                                        {packageObj.SKU_Name && packageObj.SKU_Name.length > 14 ? packageObj.SKU_Name.substring(0, 14) + "..." : packageObj.SKU_Number}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        }
                        {
                            mandatoryFieldsId.indexOf("USN_Value") >= 0 &&
                            <Grid container alignItems={"center"}>
                                <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Item USN: </Typography>
                                <Tooltip title={packageObj.USN_Value}>
                                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 1}}>
                                        {packageObj.USN_Value && packageObj.USN_Value.length > 14 ? packageObj.USN_Value.substring(0, 14) + "..." : packageObj.USN_Value}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        }
                        
                    </>
            }
            
            {
                spiType == "SPI" && 
                <Grid container alignItems={"center"}>
                    <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Item Count: </Typography>
                    {
                        packageObj.itemDetail && packageObj.itemDetail.length > 0 
                        ?
                            <Typography sx={{...textClasses.boldText, color: theme.themeBlack, ml: 1, textDecoration: "underline", cursor: "pointer"}} onClick={() => handleOpenModal(pIndex)}>View Items ({packageObj.itemDetail.map((i) => Number(i.count)).reduce((a, b) => a + b, 0)})</Typography>
                        :
                            <Typography sx={{...textClasses.boldText, color: theme.themeBlack, ml: 1, textDecoration: "underline", cursor: "pointer"}} onClick={() => handleOpenModal(pIndex)}>Add Item Details</Typography>
                    }
                    
                </Grid>
                
            }
            {
                asn && (spiType == "shipmentPackageOnly" || spiType=="shipmentItemOnly") && 
                <Grid container alignItems={"center"}>
                    <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Count: </Typography>
                    <Typography sx={{...textClasses.boldText, color: theme.themeBlack, ml: 1}} >({packageObj?.count || 0})</Typography>
                </Grid>
                
            }
            <Grid container sx={{mt: 1}}>
                <Button variant='contained' size='small' sx={{...buttonClasses.small, color: "white", backgroundColor: theme.themeOrange}} onClick={() => handleOpenEditModal(pIndex)}>Edit</Button>
                <Button variant='contained' size='small' sx={{...buttonClasses.small, color: "white", backgroundColor: "black", ml: 1}} onClick={() => handleDeleteObject(pIndex)}>Delete</Button>
            </Grid>

        </Paper>
    )
}