import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_ITEM_MASTER,
  FETCH_ITEMS_DETAIL,
  GET_MASTER_COUNT
} from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getItemMaster = (bodydata, apiKeyword, callBackFunction) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_START });
    await axios.post(`/inventory/${apiKeyword}`, bodydata).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: "GET_ITEM_MASTER", payload: { rows: data.data, count: data.count } });

      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: "GET_ITEM_MASTER", payload: {} });

      }
      if (callBackFunction) {
        callBackFunction({ success: data.success, message: data.message, count: data.count })
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      //("Error****:", error.message);
      if (callBackFunction) {
        callBackFunction({ message: error.message })
      }
    });
  }
};
export const getMasterTemplateWiseCount = (bodydata, apiKeyword, callBackFunction) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_START });
    await axios.post(`/inventory/${apiKeyword}`, bodydata).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: GET_MASTER_COUNT, payload: data });

      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: GET_MASTER_COUNT, payload: {}});

      }
      if (callBackFunction) {
        callBackFunction({ success: data.success, message: data.message || ""})
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (callBackFunction) {
        callBackFunction({ success: false, message: error.message })
      }
      //("Error****:", error.message);
      if (callBackFunction) {
        callBackFunction({ message: error.message })
      }
    });
  }
};

export const fetchFile = (payload , displayFile)=>{
  return(dispatch)=>{
    dispatch({type:FETCH_START});
    axios.post(`/inventory/getS3FileForItem`,payload).then(({data})=>{
        displayFile(data.fileUrl, payload.fileKey)
    })
  }
}

export const saveItem = (payload, apiKeyword, callback) => {
  return (dispatch) => {
    // //('apiKeyword', apiKeyword);
    dispatch({ type: FETCH_START });
    axios.post(`/inventory/${apiKeyword}`, payload).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
       
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });

      }

      if (callback) {
        callback({ success: data.success || false, message: data.message })
      }
      
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (callback) {
        callback({ success: false, message: error.message })
      }
      //("Error****:", error.message);
    });
  }
};

export const editItem = (payload, apiKeyword, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(`/inventory/${apiKeyword}`, payload).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
       
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        
      }

      if (callback) {
        callback({ success: data.success || false, message: data.message })
      }
      
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (callback) {
        callback({ success: false, message: error.message })
      }
      //("Error****:", error.message);
    });
  }
};

export const deleteItem = (bodydata, apiKey, callBackFunction) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_START });
    await axios.post(`/inventory/${apiKey}`, bodydata).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (callBackFunction) {
        callBackFunction({ success: data.success, message: data.message })
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      //("Error****:", error.message);
      if (callBackFunction) {
        callBackFunction(null)
      }
    });
  }
};

export const getItemMasterDetail = (payload, apiKeyword, callBackFunction) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post(`/inventory/${apiKeyword}`, {
      ...payload
    }).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: FETCH_ITEMS_DETAIL, payload: { rows: data.data } })
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: FETCH_ITEMS_DETAIL, payload: null })
      }
      if (callBackFunction) {
        callBackFunction({ success: data.success || false, message: data.message })
      }
      
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (callBackFunction) {
        callBackFunction({ success: false, message: error.message })
      }
      //("Error****:", error.message);
    });
  }
};

export const uploadItem = (bodydata,apiKeyword, callBackFunction) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_START });
    await axios.post(`/inventory/${apiKeyword}`, bodydata
    ).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (callBackFunction) {
        callBackFunction({ success: data.success, message: data.message })
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      //("Error****:", error.message);
      if (callBackFunction) {
        callBackFunction({ message: error.message })
      }
    });
  }
};
