import {
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START, FETCH_DYNAMIC_PAGE_CONFIG
} from "../types"
import axios from "../../services/Api";

export const getDynamicPageConfigDetails = (storedInPage, payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('base/getPageConfig', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_DYNAMIC_PAGE_CONFIG, payload: {storedInPage: storedInPage, data: data.data}})
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_DYNAMIC_PAGE_CONFIG, payload: {storedInPage: storedInPage, data: {} }})
            }
            
            
            if (cb) {
                cb(data?.data || {})
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: FETCH_DYNAMIC_PAGE_CONFIG, payload: {storedInPage: storedInPage, data: {} }})
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};
