import React from 'react'
import { Typography, Grid } from '@mui/material'
import theme, { textClasses } from "../../../static/styles/theme"

import GenericComponentsCollapse from '../../Components/Generic/GenericComponentsCollapse'

export default function PreviousUsedComponents({usedComponents}) {
    ////(" Used Components --- ");
    ////(usedComponents);
    return (
        <Grid container sx={{zIndex: 100, mr: 2, overflowY: "scroll", height:'75vh'}}>
            <Grid container item sm={12}>
                <Typography variant='h6' sx={{color: theme.themeOrange}}>Previous Used Component</Typography>
                <Typography sx={{...textClasses.normalText, fontSize: "12px", color: "#455A64"}}>
                    All form field which are already used in any template. Just drag a field and drop into your new template, no need to defined field header, placeholder and input validations again and again 
                </Typography>
                {
                    usedComponents.map((section, i) => {
                        return (
                            <GenericComponentsCollapse
                                index={i+1}
                                title={section._id ? section._id : "Most used components"}
                                components={section.elements}
                                componentType={"previous"}
                            />
                        )
                    })
                }
                {/* <GenericComponentsCollapse
                    index={1}
                    title={"Text and Input fields"}
                    components={textComponents}
                    componentType={"previous"}
                />
                <GenericComponentsCollapse
                    index={2}
                    title={"Frequently used components"}
                    components={mostUsedComps}
                    type="list"
                    componentType={"previous"}
                /> */}
            </Grid>
            
        </Grid>
    )
}

const textComponents = [
    {
        "_id": "646c72eafbd920897364c094",
        "base": "Coding Ninjas",
        "elementId": "SKU_Number",
        "conditionalView": false,
        "createdAt": "2023-05-23T08:01:46.653Z",
        "delete": false,
        "description": "Enter your SKU number",
        "elementType": "Text and input",
        "errorText": "",
        "forType": "all",
        "frequency": 1,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "icon": "simple-icon-note",
        "label": "SKU Number",
        "multiple": false,
        "placeholder": "SKU Number",
        "regex": "",
        "required": true,
        "sortType": 1,
        "sourceElement": "",
        "sourceValue": "",
        "subtype": "text",
        "type": "input",
        "updatedAt": "2023-05-23T08:01:46.653Z"
    },
    {
        "_id": "646c72eafbd920897364c095",
        "base": "Coding Ninjas",
        "elementId": "detail_Key",
        "conditionalView": false,
        "createdAt": "2023-05-23T08:01:46.653Z",
        "delete": false,
        "description": "Enter your input",
        "elementType": "Text and input",
        "errorText": "",
        "forType": "all",
        "frequency": 1,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "icon": "simple-icon-note",
        "label": "Detail",
        "multiple": false,
        "placeholder": "Package Detail",
        "regex": "",
        "required": true,
        "sortType": 1,
        "sourceElement": "",
        "sourceValue": "",
        "subtype": "text",
        "type": "input",
        "updatedAt": "2023-05-23T08:01:46.653Z"
    },
    // {
    //     "_id": "62beeacf20037f12772790bb",
    //     "type": "select",
    //     "icon": "simple-icon-menu",
    //     "label": "Dropdown",
    //     "description": "Select",
    //     "placeholder": "Select",
    //     "className": "form-control select2-single",
    //     "values": [
    //         {
    //             "label": "Option 1",
    //             "value": "option 1"
    //         },
    //         {
    //             "label": "Option 2",
    //             "value": "option 2"
    //         },
    //         {
    //             "label": "Option 3",
    //             "value": "option 3"
    //         }
    //     ],
    //     "subtype": "single",
    //     "forType": "all",
    //     "elementType": "Selection Type",
    //     "sortType": 2
    // },
]

const mostUsedComps = [
    {
        "_id": "6466132d7ee7a36dbfdb17f1",
        "base": "Coding Ninjas",
        "elementId": "packageQuantity",
        "createdAt": "2023-05-18T11:59:41.468Z",
        "delete": false,
        "description": "Enter your number",
        "errorText": "Enter numeric value only",
        "forType": "table",
        "frequency": 10,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel":false,
        "icon": "simple-icon-note",
        "label": "Quantity",
        "max": "",
        "min": "",
        "multiple": false,
        "placeholder": "Enter your number",
        "regex": "^[0-9]*$",
        "required": true,
        "subtype": "text",
        "type": "number",
        "updatedAt": "2023-05-23T08:22:17.539Z"
    },
    {
        "_id": "6466132d7ee7a36dbfdb17f2",
        "base": "Coding Ninjas",
        "elementId": "productName",
        "createdAt": "2023-05-18T11:59:41.468Z",
        "delete": false,
        "description": "Enter product name",
        "forType": "table",
        "frequency": 10,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "icon": "simple-icon-note",
        "label": "Product Name",
        "multiple": false,
        "placeholder": "Enter product name",
        "required": false,
        "subtype": "text",
        "type": "input",
        "updatedAt": "2023-05-23T08:22:17.539Z"
    },
    {
        "_id": "6466142f7ee7a36dbfdb2826",
        "base": "Coding Ninjas",
        "elementId": "searchTags",
        "createdAt": "2023-05-18T12:03:59.838Z",
        "delete": false,
        "description": "Enter search tags",
        "forType": "table",
        "frequency": 10,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "icon": "simple-icon-note",
        "label": "Search Tags",
        "multiple": false,
        "placeholder": "Enter search tags",
        "required": false,
        "subtype": "text",
        "type": "searchTags",
        "updatedAt": "2023-05-23T08:22:17.539Z"
    },
    {
        "_id": "646c7386fbd920897364c14e",
        "base": "Coding Ninjas",
        "elementId": "dispatchOrderNo",
        "createdAt": "2023-05-23T08:04:22.612Z",
        "delete": false,
        "description": "Enter dispatch order no",
        "forType": "table",
        "frequency": 1,
        "handle": true,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "icon": "simple-icon-note",
        "label": "SO Number",
        "multiple": false,
        "placeholder": "Enter dispatch order no",
        "required": true,
        "staticLabel": true,
        "subtype": "text",
        "type": "input",
        "updatedAt": "2023-05-23T08:04:22.612Z"
    }
]
