import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { textClasses } from "../../../../../static/styles/theme";
import playTicon from "../../../../../static/images/Icon/createAutomation/playTicon.svg";
import Line61 from "../../../../../static/images/Icon/createAutomation/Line61.svg";
import Line65 from "../../../../../static/images/Icon/createAutomation/Line65.svg";
import Line64 from "../../../../../static/images/Icon/createAutomation/Line64.svg";
import offline_bolt from "../../../../../static/images/Icon/createAutomation/offline_bolt.svg";
import ifelseicon from "../../../../../static/images/Icon/createAutomation/ifelseicon.svg";
import arrow_and_edge from "../../../../../static/images/Icon/createAutomation/arrow_and_edge.svg";
import shopingcart from "../../../../../static/images/Icon/createAutomation/shopingCart.svg";
import { ADD_COMPONENT, AUTOMATION_TRIGGER } from "../constant";

const SidebarCard = ({
  setStep,
  isJobCreationStep,
  selectJobType,
  setCreationStep,
  creationStep,
  jobTypefilter,
}) => {
  return (
    <Grid
      sx={{
        minHeight: "725px",
        width: "252px",
        borderRadius: "8px",
        border: "1px solid #FF7200",
        backgroundColor: "#FFE3CC",
        padding: "26px",
      }}
    >
      <Grid
        sx={{ cursor: "pointer" }}
        container
        spacing={0}
        onClick={() => {
          setStep(AUTOMATION_TRIGGER);
        }}
      >
        <img src={playTicon} />
        <Typography
          sx={{
            ...textClasses.boldText,
            paddingLeft: "10px",
            color: "#FF7200",
          }}
        >
          When : Create Trigger
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        <Box>
          <img src={Line61} />
        </Box>
        <Box>
          <Typography
            sx={{
              ...textClasses.t12n,
              paddingLeft: "15px",
              color: "#FF7200",
            }}
          >
            select a process step or scheduled task{" "}
          </Typography>
        </Box>
      </Box>
      {isJobCreationStep && creationStep && creationStep !== ADD_COMPONENT && (
        <Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box>
              <img src={ifelseicon} />
            </Box>
            <Typography
              sx={{
                padding: "0 0 0 10px",
                ...textClasses.t14n,
                color: "#FF7200",
              }}
              onClick={() => {}}
            >
              IF, ELSE : Add Condition Options{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            <img src={Line65} />
            <Box
              sx={{
                position: "absolute",
                top: "26px",
                left: "22px",
              }}
            >
              <img src={arrow_and_edge} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "27px",
                left: "50px",
                ...textClasses.t14n,
                color: "#FF7200",
                fontFamily: "Nunito",
              }}
            >
              {" "}
              <Typography>If Block</Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "70px",
                left: "22px",
              }}
            >
              {" "}
              <img src={Line64} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "100px",
                left: "32px",
              }}
            >
              <img src={offline_bolt} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "100px",
                left: "60px",
              }}
            >
              <Typography
                sx={{
                  ...textClasses.boldText,
                  color: "#FF7200",
                  fontFamily: "Nunito",
                }}
              >
                Then : Add An Action
              </Typography>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  color: "#FF7200",
                  fontFamily: "Nunito",
                }}
              >
                select a process step to perform
              </Typography>
            </Box>

              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: "190px",
                    left: "22px",
                  }}
                >
                  <img src={arrow_and_edge} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "195px",
                    left: "50px",
                  }}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: "#FF7200",
                      fontFamily: "Nunito",
                    }}
                  >
                   {selectJobType? 'Else Block' :'Add Else Block'}
                  </Typography>
                </Box>
              </>

            {selectJobType && (
              <>
                <Box
                  sx={{
                    position: "absolute",
                    top: "240px",
                    left: "22px",
                  }}
                >
                  {" "}
                  <img src={Line64} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "260px",
                    left: "32px",
                  }}
                >
                  <img src={offline_bolt} />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "263px",
                    left: "60px",
                  }}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: "#FF7200",
                      fontFamily: "Nunito",
                    }}
                  >
                    Then : Add An Action
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      color: "#FF7200",
                      fontFamily: "Nunito",
                    }}
                  >
                    select a process step to perform
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
      <Grid
        container
        spacing={0}
        onClick={() => {
          if (jobTypefilter) {
            setCreationStep(ADD_COMPONENT);
          }
        }}
        sx={{
          marginTop: "7px",
          cursor: "pointer",
        }}
      >
        <img src={shopingcart} />
        <Typography
          sx={{
            ...textClasses.t14n,
            fontFamily: "Nunito",
            color: "#FF7200",
          }}
        >
          Add a component{" "}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SidebarCard;
