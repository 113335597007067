import React, { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Typography, Divider, TextField, Button, IconButton, Modal, Card, Select, FormControlLabel, Checkbox, ButtonGroup, CircularProgress, Tooltip, ImageList, ImageListItem, Dialog, DialogContent, Box, FormControl } from '@mui/material'
import theme, { textClasses, cardClasses, inputClasses, buttonClasses  } from '../../../../../static/styles/theme'
import Table from './Table';


export default function ViewExcelDialog({showFileData, fileData, handleClose}) {
    const header = (fileData != null && fileData.length > 0) ? ["Sno"].concat(Object.keys(fileData[0])) : [];
    const rows = (() => {
        if(fileData){
            return fileData.map((row, i) => {
                let r = [];
                r.push(i+1);
                for (let j = 0; j < header.length; j++) {
                    if(["Sno", "__rowNum__"].indexOf(header[j]) < 0){
                        const e = row[header[j]];
                        r.push(e);
                    }
                    // //("Looking header " + header[j]);
                    
                }
                return r;
            })
        }
        return [];
    })();
    
    return (
        <Dialog
            open={showFileData}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{sx: {width: "100%"}}}
            maxWidth="md"
        >
            <DialogContent dividers>
                <Typography sx={{...textClasses.cardTitle, mb: 1}}>Uploaded excel file data</Typography>
                <Table 
                    header={header}
                    rows={rows}
                />
                <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "100px", mt:2}} onClick={handleClose}>
                    Close
                </Button>
            </DialogContent>
        </Dialog>
    )
}
