import React, { useEffect, useState } from "react"
import AlertDialog from "../../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../../static/styles/theme";
import SelectImage from "../../../../static/images/automationRules/Done-pana.svg"

const SubmitAutomationRuleView = ({ viewModal, setViewModal, }) => {

    return (
        <>
            <AlertDialog
                viewDialog={viewModal.type === "submitAutomationRule"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                viewSearchInput={true}
                showDivider={true}
                width="60%"
                // searchTitle="Sales Order ID:"
                // title="Sales Order Associated Trip(s) Detail"
                body={
                    <>
                       
                         <Grid container direction={"column"} justifyContent="center" alignItems="center" sx={{ p: 4 }}>
                         <img src={SelectImage} width="280px" />
                         <Typography sx={{ ...textClasses.t16n, textAlign: "center", mt: 1, fontWeight: '600' }}>
                           Congratulations, Your automation rule job completed.
                         </Typography>
 
                     </Grid>
                        
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default SubmitAutomationRuleView;