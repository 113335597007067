export const component = (areaType = "", floorId = "",fetch_for="areautilization",filter_key="isCapacityNotDefined") => {
  const component = {
    "_id": "cellId",
    "type": "select",
    "disabled": false,
    "label": `Select ${areaType}`,
    "placeholder": `Select ${areaType}`,
    "description": "Select ${areaType}",
    "required": true,
    "dynamic": true,
    "auth": true,
    "apiDomain": "/v1/",
    "apiEndPoint": "grid/getGridDetail",
    "apiMethod": "post",
    "fetched": false,
    "name": "Storage List",
    "apiDataReturnKey": "data",
    "dontFetch": true,
    "apiParameters": [
      {
        "label": "fetch_for",
        "value": fetch_for
      },
      {
        "label": "floorId",
        "value": floorId
      },
      {
        "label": "type",
        "value": areaType
      },
      {
        "label": filter_key,
        "value": true
      },
      {
        "label": "cellName",
        "value": "$self"
      }
    ],
    "keyForLabel": "name",
    "keyForValue": "name",
    "suffix": "",
    "extraValue": ["cellId", "parentId"],
  }
  return component
}





export const units = [
  {
    value: "meter",
    label: "meter",
  },
  {
    value: "feet",
    label: "feet",
  },
  {
    value: "centimeter",
    label: "centimeter",
  },
  {
    value: "inch",
    label: "inch",
  },
];

export const radioBtns = [
  {
    value: "block",
    label: "Block",
  },
  {
    value: "rack",
    label: "Rack",
  },
  {
    value: "shelves",
    label: "Shelves",
  },
  // {
  //   value: "Bin",
  //   label: "Bin",
  // },
];

export  const wieghtKg = [
  { label: "100Kg" },
  { label: "200Kg" },
  { label: "300Kg" },
  { label: "400Kg" },
  { label: "500Kg" },
  { label: "600Kg" },
  { label: "700Kg" },
];