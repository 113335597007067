import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    FETCH_TEMPLATE,
    FETCH_TEMPLATES,
    FETCH_STD_COMPS,
    FETCH_PRV_COMPS,
    SAVE_TEMPLATE,
    FETCH_TEMPLATES_ERR,
    FETCH_USERS,
    FETCH_STATUS_COUNT,
    FETCH_REGEX_LIST,
    FETCH_ALL_TEMPLATE_TYPES,

  } from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getTemplateTypes = (cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateTypes', {
            fetchSingle: false
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: data.data})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: []})
                
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getTemplate = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateDetail', payload).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_TEMPLATE, payload: data.data})
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_TEMPLATE, payload: {}})
        }

        if(cb){
            cb(data?.data || {})
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};

export const getConnectedTemplate = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateDetail', payload).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            // dispatch({ type: FETCH_TEMPLATE, payload: data.data})
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            // dispatch({ type: FETCH_TEMPLATE, payload: {}})
        }

        if(cb){
            cb(data?.data || {})
        }
       
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};
  
export const getAllTemplates = (body, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateList',body).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_TEMPLATES, payload: {row: data.data, count: data.count}})
        } 
        else {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({type: FETCH_TEMPLATES_ERR, payload: data.message});
            dispatch({ type: FETCH_TEMPLATES, payload: {row: [], count: 0}})
        }

        if(cb){
            cb(data?.data || [], data?.count || 0);
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const getStandardComonents = ({ type }) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getStandardComponentList', {
            type: type,
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_STD_COMPS, payload: data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getPreviousComonents = ({ type }) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getUsedComponentList', {
            type: type,
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_PRV_COMPS, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const saveTemplate = (body, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/saveTemplateDetailV2', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback(data) 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template created successfully", false)
            dispatch(getAllTemplates())
            cb(data.data);
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};

export const editTemplate = (body, callback) =>
{
    return (dispatch) =>
    {
        dispatch({ type: FETCH_START });
        axios.post('dynamicForm/editTemplateDetails', body).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast("Template edited successfully", false)
                // dispatch(getAllTemplates())
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                // showToast(data.message, true);
            }
            if (callback) {
                callback({
                    success: data.success || false,
                    message: data.message || (data.success && "Template Edit successfully" || "Some error occure, please try after some time")
                })
            }
        }).catch(function (error) {
            // showToast(error.message, true);
            if (callback) {
                callback({ success: false, message: error.message })
            }
            dispatch({ type: FETCH_ERROR, payload: error.message });
            //("Error****:", error.message);
        });
    }
};

// export const getTemplate = ({templateId}) =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             //("Error****:", error.message);
//         });
//     }
// };

// export const getTemplateTypes = () =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             //("Error****:", error.message);
//         });
//     }
// };


export const getStatusCount = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateStatusCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getRegexList = (payload={}) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getRegexList',payload
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_REGEX_LIST, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const changeTemplateStatus = (body, cb) => {
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/changeTemplateStatus', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template status changed successfully", false)
            // dispatch(getAllTemplates())
            if(cb){
                cb();
            }
            
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};


export const removeTemplates = (body, cb) => {
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/deleteTemplate', {
            ...body
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            showToast(data.message, true);
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            showToast("Template deleted successfully", false)
            // dispatch(getAllTemplates())
            if(cb){
                cb();
            }
            cb();
            
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast(error.message, true);
            //("Error****:", error.message);
        });
    }
};