import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.3s ease-out',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
    overflow : 'auto',
    border : "none",
    minWidth:200 ,
    maxWidth:670,
    border:'2px solid #fff'
};

export default function UserModel(props) {

    const [open, setOpen] = useState(false);
    const handleClose = (e) => {
        setOpen(!open);
        props.onClose(false);
    };
    useEffect(() => {
        setOpen(props.show);

    }, [props.show]);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid container sx={style} >
                <Grid item sm={12}>
                    <Box>
                        {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between'}}>
                            <span className='fs-24 font-Nunito fw-600'> {props.Title} </span>
                            <span className='curser' onClick={() => handleClose()}><CloseIcon /></span>
                        </Typography> */}
                    </Box>
                    <Box sx={{maxWidth: "inherit", maxHeight: "70vh"}}>
                        {props.children}
                    </Box>
                </Grid>

            </Grid>


        </Modal>
    )
}
