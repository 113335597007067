import * as React from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import theme, {
    textClasses,
    buttonClasses,
    cardClasses,
  } from "../../static/styles/theme";

function PageNotFound() {

    const history = useHistory();

    return (
        <Box sx={{ width: "100vw", height: "85vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Container sx={{ textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="subtitle1">
                    The page you're looking for doesn't exist.
                </Typography>
                <Box mt={4}>
                    <Button variant="contained"
                        sx={{
                            ...buttonClasses.lynkitOrangeFill
                        }}
                        onClick={() => {
                            history.push("/")
                        }}
                    >
                        Go to Home
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}

export default PageNotFound;
