import { FETCH_STOCK_DATA, FETCH_STOCK_RETURN_LIST } from "../types"

const INIT_STATE = {
    returnList: [],
    totalReturns: 0,
    allStock: [],
    totalRows: 0,
    
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_STOCK_DATA: return {...state, allStock: action.payload.data, totalRows: action.payload.count}
        case FETCH_STOCK_RETURN_LIST: return {...state, returnList: action.payload.data, totalReturns: action.payload.count}
        default: return state
    }
}
  