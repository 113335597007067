import React, { useState } from "react";
import { textClasses, inputClasses } from "../../../../../static/styles/theme";
import plusCreateIcon from "../../../../../static/images/Icon/createAutomation/plusCreateIcon.svg";
import { Autocomplete, Grid, TextField, Typography, Button } from "@mui/material";
import { ADD_COMPONENT, ADD_CONDITIONS, jobTypes } from "../constant";
import AddComponent from "./AddComponent";
import AddConditions from "./AddConditions";
import { buttonClasses } from "../../../../../static/styles/theme";
import PublishIcon from '@mui/icons-material/Publish';
import SubmitAutomationRuleView from "../SubmitAutomationRuleView";
const JobCreationStep = ({
  selectJobType,
  setSelectJobType,
  setCreationStep,
  creationStep,
  jobTypefilter,
  setJobTypefilter,
  resetStates
}) => {
  const [viewModal,setViewModal] = useState({type:'',action:''})
  const isAddComponent = creationStep === ADD_COMPONENT;
  const isAddConditions = creationStep === ADD_CONDITIONS;

  return (
    <>
      <Grid
        sx={{
          minHeight: "725px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          border: "1px solid #E0E0E0",
          backgroundColor: "white",
          boxShadow: "0px 3px 5px #D9D9D9",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "20px",
            paddingLeft: "40px",
          }}
        >
          <img src={plusCreateIcon} />
          <Typography
            sx={{
              paddingLeft: "10px",
              ...textClasses.t12n,
              color: "#000000",
            }}
          >
            When A New Job Created{" "}
          </Typography>
        </Grid>
        <Typography
          sx={{
            ...textClasses.t12n,
            color: "#827878",
            paddingLeft: "50px",
            marginTop: "5px",
          }}
        >
          Rule is run when a new Job or Task created by users or automatically
          created{" "}
        </Typography>
        <Grid
          container
          item
          justifyContent="flex-start"
          sx={{
            marginTop: "10px",
            marginLeft: "40px",
          }}
        >
          <Autocomplete
            value={jobTypefilter}
            onChange={(e, option) => {
              setJobTypefilter(option);
              if (option) {
                setCreationStep(ADD_COMPONENT);
              } else {
                setCreationStep("");
              }
            }}
            getOptionLabel={(option) => option.name}
            size="small"
            options={jobTypes}
            sx={{
              ...inputClasses.filterField,
              minWidth: "380px",
              background: "white",
              ".MuiInputBase-input": {
                fontSize: "15px",
                color: "yellow",
                "& label.Mui-focused": {
                  color: "#455A64",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{ color: "yellow", ...textClasses.normalText }}
                {...params}
                label="Select a Job Type "
              />
            )}
          />
        </Grid>
        {isAddComponent && <AddComponent setCreationStep={setCreationStep} />}
        {isAddConditions && (
          <AddConditions
            selectJobType={selectJobType}
            setSelectJobType={setSelectJobType}
          />
        )}
             <Button
								variant="contained"
								size="small"
								data-testid="create-job-Button"
								startIcon={<PublishIcon />}
                disabled={!selectJobType}
								sx={{
									...buttonClasses.lynkitOrangeFill,
                  mt:2,ml:5
								}}
								onClick={() => {
									setViewModal({...viewModal,type:'submitAutomationRule'})
                  if(resetStates){
                    resetStates();
                  }
								}}
							>
								Submit
							</Button>
      </Grid>
      {viewModal.type=="submitAutomationRule" &&
      <SubmitAutomationRuleView 
      viewModal={viewModal}
      setViewModal={setViewModal}
      />}
    </>
  );
};

export default JobCreationStep;
