import React,{useEffect} from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Landing from '../components/App';
import INVDetail from '../views/Landing/INVDetail';
import Dashboard from '../components/Dashboard';
import { useDispatch, useSelector } from 'react-redux'
import { getUserPermissions } from '../redux/actions';
import ExternalForm from '../views/Landing/ExternalForm/index.js';
import PageNotFound from '../views/Landing/pageNotFound.js';
import  PasscodeValidation  from '../views/Landing/passcodeValidation/index.js';
import ExternalFormCompletion from '../views/Landing/ExternalForm/ExternalFormCompletion.js';
export default function Routes() {
  const dispatch = useDispatch();
  // const name = useSelector(state => state.auth.name)
  const {sidebarList=[]} = useSelector((state) => state.userPermission);
  
  const getRirectUrl=()=>{
    let url="/"
    let perm=sidebarList?.length && sidebarList[0] || false
    if(perm?.url){
      if(perm?.subItems?.length){
          url=perm.subItems[0].url || "/"
      }
      else{
        url=perm.url
      }
    }
    return url
  }

  useEffect(() => {
    // //("window.location.pathname",window.location.pathname);
    // if(window.location.pathname=="/getInvDetail"){
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("shipmentID")
    //   localStorage.removeItem('user')
    // }
    if(!["/getInvDetail","/saveEFormDetail","/passcode/:id"].includes(window?.location?.pathname) && localStorage.getItem('token')){
      dispatch(getUserPermissions())
    }
  }, [])
  return (
    <Switch>
      <Route path="/passcode/thankyou" component={ExternalFormCompletion} redirectTo="/" exact/>
      <Route path="/getInvDetail" component={INVDetail} redirectTo="/"/>
      <Route path="/saveEFormDetail" component={ExternalForm} redirectTo="/"/>
      <Route path="/passcode/:id" component={PasscodeValidation} redirectTo="/"/>
      <Route path="/" exact component={Landing} redirectTo={getRirectUrl()}/>
      {/* <Route path="/error" component={Dashboard} redirectTo={getRirectUrl()} isPrivate/> */}
      <Route path="/dashboard" component={Dashboard} redirectTo={getRirectUrl()} isPrivate/>
      <Route component={PageNotFound} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      <Route component={Landing} isPrivate={false}/>
    </Switch>
  );
}