import React from "react";
import { textClasses } from "../../../../../static/styles/theme";
import CreateAutomationCard from "./CreateAutomationCard";
import { Grid, Typography } from "@mui/material";
import blackshopingcart from "../../../../../static/images/Icon/createAutomation/blackshopingcarticon.svg";
import ificon from "../../../../../static/images/Icon/createAutomation/ificon.png";
import blackicon from "../../../../../static/images/Icon/createAutomation/blackicon.svg";
import { ADD_CONDITIONS } from "../constant";

const AddComponent = ({ setCreationStep }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          marginLeft: "28px",
          marginTop: "25px",
        }}
      >
        <Grid item xs={0}>
          {" "}
          <img src={blackshopingcart} />
        </Grid>
        <Grid item xs={0}>
          {" "}
          <Typography
            sx={{
              ...textClasses.boldText,
              color: "#000000",
              paddingLeft: "15px",
            }}
          >
            Add Component
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{
          ...textClasses.t12n,
          color: "#827878",
          marginLeft: "65px",
          marginRight: "10px",
        }}
      >
        All rules will conclude with an action. You can narrow the scope of the
        preceding component with a condition. You can also create a seperate
        segment in your rule with a branch.
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          marginLeft: "56px",
          marginTop: "20px",
          gap: "20px",
          width: "auto",
        }}
      >
        <CreateAutomationCard
          text="IF, ELSE : Add Condition Options  "
          subText="Set up your rule to run one path out of multiple, depending on which condition met."
          icon={ificon}
          handleJobCreate={() => {
            setCreationStep(ADD_CONDITIONS);
          }}
        />
        <CreateAutomationCard
          text="THEN: Add an action"
          subText="Actions will execute when your rule runs successfully."
          icon={blackicon}
        />
      </Grid>
    </>
  );
};
export default AddComponent;
