import React, { useEffect, useState } from "react";
import StaticFormRender from "../../../../Components/DynamicFormRender/staticRendererForm";
import { useForm } from 'react-hook-form';
import {
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Button,
    TextField,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Box,
    Checkbox,
    Autocomplete,

} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../../static/styles/theme";
import { Link, useLocation } from 'react-router-dom';
import { showToast, getCamelCaseText } from "../../../../../services/functions";
import { getSuperAdminUsersDetails, getSuperAdminCompanyData, getTemplate, editUsers, addSuperAdminUsers, getAllUserRoles, getAllSuperAdminPermissions } from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../../services/history";
import { selectStylesOverride } from "../../../../../utils/util";
import GenericLoader from "../../../../Components/Generic/GenericLoader";
import GenericToggle from "../../../../Components/GenericToggle/index"
import { capitalizeSentence } from "../../../../../services/functions";

const AddAdmin = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editableUserId = queryParams.get("id")
    const dispatch = useDispatch();
    const [loader, setLoader] = useState("")
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
    const [dataReceieved, isDataReceived] = useState(false)
    const [templateDetail, setTemplateDetail] = useState([])
    const [roleName, setRoleName] = useState('')
    const [addRoleData, setAddRoleData] = useState({
        roleName: '',
        permissions: []
    })
    const [isFeildTouched, setIsFeildTouched] = useState(false)
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState({})
    const [selectedWarehouseDetail, setSelectedWarehouseDetail] = useState("")
    const [selectedFloorDetail, setSelectedFloorDetail] = useState("")
    const [selectedUserRole, setSelectedUserRole] = useState("")
    const [companiesList, setCompaniesList] = useState([])
    const [warehouseFloorList, setWarehouseFloorList] = useState([])
    const [isToggleEnable, setIsEnable] = useState(false);
    const [allCheck, setAllCheck] = useState(false)
    const { AllUserRoleData=[] } = useSelector((state) => state.UserRoles);
    const AllPermissions  = useSelector((state) => state.superadmin.superadminUsersPermissions || []);
    let isEditable = false;
    isEditable = editableUserId ? true : false;
    const textCapitalize = (d) => {

        let dataArr = d.split(/(?=[A-Z])/)
        let label = ""
        for (let data of dataArr) {
            data = data.slice(0, 1).toUpperCase() + data.slice(1).toLowerCase()
            if (label) {
                label = label + " " + data
            }
            else {
                label = data
            }
        }
        return label


    }
    const allChecked = (e) => {
        let temp = { ...addRoleData };

        if (e.target.checked === true) {
            temp.permissions.length = 0
            for (let i = 0; i < AllPermissions.length; i++) {
                let keyArr = AllPermissions[i]
                for (let j = 0; j < keyArr.assignedPermissions.length; j++) {
                    let keyName = keyArr.assignedPermissions[j].permissionId
                    temp.permissions.push(keyName)
                    setAddRoleData(temp)
                    setAllCheck(true)
                }
            }
        }
        else {
            temp.permissions.length = 0
            setAddRoleData(temp)
            setAllCheck(false)
        }
    }
    const categoryWiseCheck = (e, item) => {
        let temp = { ...addRoleData };
        if (e.target.checked === true) {
            for (let j = 0; j < item.assignedPermissions.length; j++) {
                let keyName = item.assignedPermissions[j].permissionId
                if (!temp.permissions.includes(keyName)) {
                    temp.permissions.push(keyName)
                    setAddRoleData(temp)
                }
                else { }
            }
        }
        else {
            for (let k = 0; k < item.assignedPermissions.length; k++) {
                temp.permissions.splice(temp.permissions.indexOf(item.assignedPermissions[k].permissionId), 1);
                setAddRoleData(temp)
            }
        }
        setIsFeildTouched(true)
    }

    const checkboxChange = (data) => {

        let temp = { ...addRoleData }
        let res = []
        if (!temp.permissions.includes(data.permissionId)) {
            temp.permissions.push(data.permissionId)
            setAddRoleData(temp)
        }
        else {
            temp.permissions.splice(temp.permissions.indexOf(data.permissionId), 1);
            setAddRoleData(temp)
        }

        for (let i = 0; i < AllPermissions.length; i++) {
            let keyArr = AllPermissions[i]
            for (let j = 0; j < keyArr.assignedPermissions.length; j++) {
                let keyName = keyArr.assignedPermissions[j].permissionId
                res.push(keyName)
            }

        }
        if (res.length === temp.permissions.length) {
            setAllCheck(true)
        }
        else {
            setAllCheck(false)
        }

        setIsFeildTouched(true)
    }
    // const disableSubmitButton = () => {
    //     if (!roleName) return true
    //     if (!addRoleData || !addRoleData.permissions || addRoleData.permissions.length == 0) return true
    //     if (!isFeildTouched) return true
    //     return false
    // }
    const handleSubmitData = (payloadData) => {
        let payload = {
            ...payloadData,
            userType: "admin",
            companyId: selectedCompanyDetail?._id,
            warehouseId: selectedWarehouseDetail,
            floorId: selectedFloorDetail,
            templateId: templateDetail?._id,
            roleId: selectedUserRole,
            customRoleName: roleName || "",
            permissions: addRoleData?.permissions || []
        }
        isDataReceived(true)
        if (isEditable) {
            dispatch(editUsers({ ...payloadData, _id: editableUserId,userRole:selectedUserRole, warehouseId: selectedWarehouseDetail,
                floorId: selectedFloorDetail }, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/users")
                }
            }))
        }
        else {
            dispatch(addSuperAdminUsers(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/users")
                }
            }))
        }
    }
    const handleClearAll = (attributes = [], data = {}) => {
        let resetD = {};
        for (let ele of attributes) {
            if (data[ele?._id]) {
                resetD[ele?._id] = data[ele?._id]
            }
            else {
                resetD[ele?._id] = ele?.type == "checkbox" ? [] : ""
            }
        }
        reset({
            ...resetD
        });
    }

    const handleToggle = (event) => {
        setIsEnable(event.target.checked);
    };
    useEffect(() => {
        setLoader("getDynamicTemplate")
        dispatch(getTemplate({ type: "user" }, (data) => {
           
            let tempData = data
            setLoader("")
            if (isEditable) {
                tempData.attributes=tempData?.attributes?.filter(dl=>dl?._id != "password")
                dispatch(getSuperAdminUsersDetails({ _id: editableUserId }, (detailData) => {
                    handleClearAll(data?.attributes || [], detailData?.data)
                    setSelectedWarehouseDetail(detailData?.data?.warehouseId)
                    setSelectedFloorDetail(detailData?.data?.floorId)
                    setSelectedUserRole(detailData?.data?.roleId)
                    setSelectedCompanyDetail({_id:detailData?.data?.companyId,companyName:detailData?.data?.company})
                }))
            }
            setTemplateDetail(tempData)
        }))
        if (!isEditable) {
            dispatch(getSuperAdminCompanyData({ projections: { _id: 1, companyName: 1, type: 1 }, type: 'company' }, (data) => {
                setCompaniesList(data)
            }))
        }

    }, [])

    useEffect(() => {
        if(selectedCompanyDetail?.companyName){
            if (selectedCompanyDetail != {}) {
                dispatch(getSuperAdminCompanyData({ projections: { _id: 1, name: 1, type: 1,warehouseId: 1 }, type: ['warehouse','floor'], companyName: selectedCompanyDetail?.companyName }, (data) => {
                    setWarehouseFloorList(data)
                }))
            }
            if (isToggleEnable) {
                dispatch(getAllSuperAdminPermissions())
            } else {
                dispatch(getAllUserRoles({companyId:selectedCompanyDetail?._id || ""}),(data)=>{
                })
           
            }
        }
    }, [selectedCompanyDetail, selectedWarehouseDetail, isToggleEnable])
    return (<>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', }}>
            <Grid item sm={3}>
                <Typography sx={{ ...textClasses.cardTitle }}>Manage Admin</Typography>

                <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-admin">
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/users">
                            Manage Admin
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                        {isEditable ? "Edit Admin" : "Create New Admin"}
                    </Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item sm={3}>
                <Autocomplete
                    data-testid="select-Company-dropdown"
                    value={selectedCompanyDetail || {}}
                    onChange={(e, option) => {
                        setSelectedCompanyDetail(option)
                        setSelectedWarehouseDetail("")
                        setSelectedFloorDetail("")
                    }}
                    getOptionLabel={(option) => getCamelCaseText(option?.companyName)}
                    size="small"
                    options={companiesList?.data || []}
                    sx={{
                        ...inputClasses.textField,
                        ...selectStylesOverride,
                        minWidth: "250px",
                        m: 0,

                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ color: "#455A64", ...textClasses.normalText }}
                            {...params}
                            label="Select Company*"
                        />
                    )}
                    disabled={isEditable}
                />
            </Grid>
            <Grid item sm={3}>
                <Autocomplete
                    data-testid="select-Warehouse-dropdown"
                    value={{name:warehouseFloorList?.data?.find(dl=>dl?._id==selectedWarehouseDetail)?.name || "",_id:selectedWarehouseDetail}}
                    onChange={(e, option) => {
                        setSelectedWarehouseDetail(option?._id)
                        setSelectedFloorDetail("")
                    }}
                    getOptionLabel={(option) => getCamelCaseText(option?.name)}
                    size="small"
                    options={warehouseFloorList?.data?.filter(dl=>dl?.type=="warehouse") || []}
                    sx={{
                        ...inputClasses.textField,
                        ...selectStylesOverride,
                        minWidth: "250px",
                        m: 0,
                        ml: 2,
                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ color: "#455A64", ...textClasses.normalText }}
                            {...params}
                            label="Select Warehouse*"
                        />
                    )}
                    disabled={!selectedCompanyDetail?._id}
                />
            </Grid>
            <Grid item sm={3}>
                <Autocomplete
                    data-testid="select-Floor-dropdown"
                    value={{name:warehouseFloorList?.data?.find(dl=>dl?._id==selectedFloorDetail)?.name || "",_id:selectedFloorDetail}}
                    onChange={(e, option) => {
                        setSelectedFloorDetail(option?._id)
                    }}
                    getOptionLabel={(option) =>option?.name}
                    size="small"
                    options={warehouseFloorList?.data?.filter(dl=>dl?.type=="floor" && dl?.warehouseId == selectedWarehouseDetail) || []}
                    sx={{
                        ...inputClasses.textField,
                        ...selectStylesOverride,
                        minWidth: "250px",
                        m: 0,
                        ml: 2,
                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ color: "#455A64", ...textClasses.normalText }}
                            {...params}
                            label="Select Floor*"
                        />
                    )}
                    disabled={!selectedCompanyDetail?._id } //|| !selectedWarehouseDetail
                />
            </Grid>
        </Grid>
        {loader == "getDynamicTemplate" ?
            <Grid
                data-testid="loader"
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                sx={{ p: 10 }}
            >
                <Grid item>
                    <GenericLoader />
                </Grid>
            </Grid> :
            <Grid container sx={{ overflowY: 'auto', border: '1px solid #E8E8E8', overflowX: 'hidden', padding: '10px', paddingTop: '20px', paddingBottom: '20px', mt: 3, mb: 3 }}>
                <StaticFormRender
                    formHooks={{ register, control, errors, handleSubmit, reset, setValue, watch }}
                    tempAttr={templateDetail?.attributes || []}
                    disabled={isEditable ? ["email"] : []}
                />
            </Grid>
        }
        {!isEditable && selectedCompanyDetail &&
            <Grid container item sm={3} sx={{ mb: 2 }} data-testid="roles-permissions-toggle">

            <GenericToggle
                isEnable={isToggleEnable}
                enabledName="'Permissions'"
                disbledName="'Roles'"
                name="toggle"
                handleToggle={handleToggle}
                width={110}
            />
        </Grid>}
        {isToggleEnable ? (
            <Grid container>
                <Grid item sm={4}>
                    <Box marginTop={1} >
                        <Box margin={1}>
                            <FormControl variant='outlined' size='small' fullWidth
                            //error
                            >
                                <OutlinedInput
                                    data-testid='dropdown-user-role'
                                    value={roleName}
                                    onChange={(e) => {
                                        setRoleName(e.target.value)
                                        setIsFeildTouched(true)
                                    }}
                                    // disabled={action === "view" ? true : false}
                                    // error={errorMessage.name ? true : false}
                                    required
                                    type='text'
                                    sx={{
                                        ...inputClasses.filterField,
                                        "&:focus": {
                                            borderColor: theme.themeOrange,
                                        },
                                        "&:before": {
                                            borderColor: theme.themeOrange,
                                        },
                                        "&:after": {
                                            borderColor: theme.themeOrange,
                                        },
                                        "& input::placeholder": { fontSize: "14px" },
                                    }}
                                    placeholder='Enter Role Name *'
                                />
                            </FormControl>
                        </Box>

                    </Box>
                </Grid>
                <Grid item sm={12} paddingRight={2} sx={{ height: "auto", overflow: "auto" }}>
                    <Box marginTop={1}>
                        {AllPermissions.length ? (
                            <Box margin={1}>
                                <Box>
                                    <Box sx={{ display: 'block' ,mr:2}}>
                                        <Checkbox
                                            data-testid='selectall'
                                            onChange={(e) => {
                                                allChecked(e);
                                                setIsFeildTouched(true);
                                            }}
                                            checked={allCheck === true ? true : false}
                                            size='small'
                                            sx={{ marginBottom: '4px' }}
                                        />
                                        <span style={{ color: '#000', fontWeight: '900' }}>Select All</span>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            ""
                        )}
                        {loader ? (
                            <Grid
                                data-testid='loader'
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems='center'
                                xs={12}
                                sx={{ p: 15 }}
                            >
                                <Grid item>
                                    <GenericLoader />
                                </Grid>
                            </Grid>
                        ) : (
                            AllPermissions.length ? (
                                AllPermissions.map((item, index) => (
                                    <Box key={index} sx={{ marginTop: '-3px', width: '95%', margin: '5px' }}>
                                        <Box sx={{ width: '100%', color: '#000', fontWeight: 500, margin: '1px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                                <Checkbox
                                                    data-testid='categoryCheckbox'
                                                    onChange={(e) => categoryWiseCheck(e, item, index)}
                                                    checked={item.assignedPermissions.every(data => addRoleData.permissions.includes(data.permissionId))}
                                                    size='small'
                                                    sx={{ marginBottom: '4px' }}
                                                />
                                                <span style={{ fontSize: '16px', color: '#000', textDecoration: 'underline', textUnderlineOffset: '3px', marginLeft: '8px',fontWeight:400 }}>
                                                    {textCapitalize(item && item.categoryKey ? item.categoryKey : '-')}
                                                </span>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                                {item.assignedPermissions.map((data, ind) => (
                                                    <Grid item sm={3} key={ind} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Checkbox
                                                            data-testid='subcategory'
                                                            onChange={() => checkboxChange(data)}
                                                            checked={addRoleData.permissions.includes(data.permissionId) ? true : false}
                                                            size='small'
                                                        />
                                                        <span data-testid='checkHead' style={{ fontSize: '14px'}}>
                                                            {data && data.permissionName ? data.permissionName : '-'}
                                                        </span>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                ''
                            )
                        )}
                    </Box>
                </Grid>


            </Grid>
        ) : (
            <Grid container item sm={3.9} sx={{ mb: 9 }}>
                <FormControl variant='outlined' size='small' fullWidth >
                    <Autocomplete
                        size='small'
                        data-testid="userRole"
                        options={AllUserRoleData && AllUserRoleData?.map(dl => ({ label: capitalizeSentence(dl.roleName), value: dl._id }))}
                        sx={{ height: "1.1rem", }}
                        onChange={(event, option) => {
                            setSelectedUserRole(option?.value || "")
                        }}

                        renderInput={(params) => (
                            <TextField
                                sx={{

                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                }}
                                {...params}

                                label={<Typography sx={{ fontSize: "12px" }}>User Role</Typography>}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                    {option?.label || ""}
                                </Typography>
                            </MenuItem>
                        )}
                        value={{ value: selectedUserRole, label: capitalizeSentence(AllUserRoleData?.find(dl => dl._id == selectedUserRole)?.roleName || "") }}
                        disabled={!selectedCompanyDetail?._id}
                    />
                </FormControl>
            </Grid>
        )}
        <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}  >
            <Button
                variant='outlined'
                size='small'
                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                onClick={() => {
                    handleClearAll(templateDetail?.attributes, isEditable ? { "email": getValues("email") } : {})
                    if (isEditable) {
                        setSelectedWarehouseDetail("")
                        setSelectedFloorDetail("")
                        setSelectedUserRole("")
                    } else {
                        setSelectedWarehouseDetail("")
                        setSelectedFloorDetail("")
                        setSelectedUserRole("")
                        setSelectedCompanyDetail({})
                    }}}
            >
                Clear All
            </Button>
            <Button
                variant='contained'
                size='small'
                disabled={dataReceieved}
                // disabled={dataReceieved || !selectedCompanyDetail || !selectedWarehouseDetail || !selectedFloorDetail || !selectedUserRole || !roleName }
                sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                onClick={handleSubmit(handleSubmitData, (errors) => {
                    let errorField = Object.keys(errors)[0];
                    showToast(`${errors[errorField].message}`, true);
                })}
            >
                {isEditable ? "Update" : "Submit"}
            </Button>
        </Grid>
    </>)
}

export default AddAdmin;