import {
    FETCH_ADJUSTMENT_REASONS, CREATE_ADJUSTMENT_REASON, FETCH_ALL_INVENTOR_ADJUSTMENTS, CREATE_INVENTORY_ADJUSTMENT,
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START, FETCH_ADJUSTMENTS_DETAILS, FETCH_ADJUSTMENT_DETAILS,
    FETCH_ADJUSTMENT_STATUS_COUNT
} from "../types";

import axios from "../../services/Api";
import { showToast } from "../../services/functions";


export const getAllInventoryAdjustment = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventoryAdjustment/getAllInventoryAdjustments', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_INVENTOR_ADJUSTMENTS, payload: {rows: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_INVENTOR_ADJUSTMENTS, payload: {rows: [], count: 0}})
            }
            if(cb){
                cb(data || {});
            }
            
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb({});
            }
        });
    }
};

export const getAllAdjustmentReasons = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventoryAdjustment/getAllAdjustmentReasons', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ADJUSTMENT_REASONS, payload: []})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ADJUSTMENT_REASONS, payload: data.data })
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const createInventoryAdjustment = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventoryAdjustment/createInventoryAdjustment', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                showToast(data.message, false);
                if(cb){
                    cb(data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            showToast("Something went wrong", true)
            if(cb){
                cb();
            }
        });
    }
};

export const getAdjustmentStatusCount = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventoryAdjustment/getAllInventoryAdjustmentsCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback();
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_ADJUSTMENT_STATUS_COUNT, payload: null})
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_ADJUSTMENT_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};
export const changeInventoryAdjustmentStatus = (payload,callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventoryAdjustment/changeInventoryAdjustmentStatus', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            if (callback) callback(data)
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (callback) callback({success:false,message: error.message})
        });
    }
};

