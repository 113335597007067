import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import Button from '@mui/material/Button';
import blankpage from "../../../../static/images/areamapping/blankpage.svg";
import AddIcon from '@mui/icons-material/Add';

function BlankScreen() {
    return (
        <>
            <Box sx={{ flexGrow: 1, padding: "2rem" }}>
                <Grid container spacing={2} margin="normal">
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box>
                                <img alt='blankpage' src={blankpage} />
                            </Box> 
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box>
                                <h1 className='font-Nunito fs-24 fw-600'>You Haven’t Define Your Area Utilization Matrix !  </h1>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ lineHeight: '0.5',  textAlign: 'center' }}>
                                <p className='font-Nunito fs-14 fw-600'>Define your warehouse area utilization matrix as per the requirement. Once you have</p>
                                <p className='font-Nunito fs-14 fw-600'>define your area, system will automatically suggest location for every shipment.</p>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                className='addRack-btn font-Nunito'
                                sx={{ borderRadius: "8px", bgcolor: 'warning.main' }}
                                variant="contained"
                            // onClick={handleSubmit(handleLogin)}
                            >
                               <AddIcon sx={{marginBottom: "2px"}} /> Map Area</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default BlankScreen;
