
import React from "react";
import {  Button, ButtonGroup } from "@mui/material";
import theme, { buttonClasses } from "../../../static/styles/theme";
import { Add, Remove } from "@mui/icons-material";

const GenericSingleRangeComponent = ({startRange,endRange,getOnChange,endRangeInfinity=false}) =>{
    const handleCountChange = (type, e) => {
        if (type) {
            if (type === "decrement") {
                if (startRange > 0) {
                    getOnChange(Number(startRange || 0) - 1);
                }
            } else if (type === "increment") {
                if(endRangeInfinity==true){
                    getOnChange(Number(startRange || 0) + 1);
                }else{
                    if (startRange < endRange) {
                        getOnChange(Number(startRange || 0) + 1);
                    }
                }
            } else if (type === "input") {
                let value = parseInt(e.target.value, 10) || "";
                if (!isNaN(value) && value >= 0) {
                    if (endRangeInfinity) {
                        getOnChange(value);
                      } else {
                        value = Math.min(endRange, value);
                        getOnChange(value);
                      }
                }
            }
        }
    };
    return(
        <>
        <ButtonGroup size='small' sx={{ minWidth: "24px", ".MuiButtonGroup-grouped": { minWidth: "25px" } }}>
        <Button key="one" size='small' sx={{
            ...buttonClasses.smallOrange, fontSize: "12px",
            backgroundColor: startRange <= 0 ? 'grey' : theme.themeOrange
        }}
            onClick={() => handleCountChange("decrement")} disabled={startRange <= 0}
        >
            <Remove fontSize='inherit' sx={{ color: 'white' }} />
        </Button>

        <input
            type="number"
            style={{ border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center" }}
            value={startRange>0?startRange:""}
            onChange={(e) => handleCountChange("input", e)}
            onWheel={(event) => event.target.blur()}

            onKeyDown={(e) => {
                if (['e', 'E', '+', '-'].includes(e.key)) {
                    e.preventDefault();
                }
                else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                    e.preventDefault();
                }
            }}
        />
        <Button key="three" size='small' sx={{
            ...buttonClasses.smallOrange, fontSize: "12px",
            backgroundColor: startRange >= endRange ? 'grey' : theme.themeOrange
        }}
            onClick={() => handleCountChange("increment")} disabled={endRangeInfinity==false?startRange >= endRange:false}
        >
            <Add fontSize='inherit' sx={{ color: 'white' }} />
        </Button>
    </ButtonGroup>
        </>
    )
}

export default GenericSingleRangeComponent;

