import axios from "../../services/Api";
import {
	FETCH_ALL_JOBS,GET_USERS_TO_ASSIGN_JOB,
	FETCH_ERROR,
	FETCH_CELL_RACK_LOCATION,
	FETCH_JOB_DETAIL,
	FETCH_START,
	FETCH_SUCCESS,
	FETCH_WAREHOUSE_FLOORS,
	FETCH_TEMPLATE_HEADERS,
	FETCH_JOB_TYPES,
	FETCH_JOB_TYPES_COUNT,
	FETCH_SHIPMENTS,
	FETCH_JOB_STATUS_COUNT,
	FETCH_ALL_JOB_ACTION,
	SHOW_MESSAGE,
	FETCH_JOB_LOCATION_DETAIL,
	FETCH_JOB_PENDENCY_DETAIL,
	FETCH_JOB_PROCESS_CONFIG,
	GENERATE_DISPATCH_ORDER_NUMBER,
	GENERATE_JOB_DETAIL,
	FETCH_JOB_CREATION,
	FETCH_WORK_ORDER_LIST_CONFIG,
	FETCH_JOB_SHIPMENT_DETAIL
} from "../types";
import { showToast } from "../../services/functions";

//Job Type Listing and creation
export const getAllJobActions =(body, callBackFunction) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post("jobTypeAction/getAllJobActions", body)
		.then(({ data: { success, data, message } }) => {
			if (success) {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({type: FETCH_ALL_JOB_ACTION,payload: {data}})
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({type: FETCH_ALL_JOB_ACTION,payload:{data:[]} });
			}
			if(callBackFunction){
				callBackFunction({ success, data, message });
			}
		})
		.catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			// //("Error:", error.message);
		});
		
	};
};
export const getAllJobTypes = (body = {},callBackFunction) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("jobType/getJobTypesList", body)
			.then(({ data: { success, count, data, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_TYPES,payload: { data, count }});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message,});
					dispatch({type: FETCH_JOB_TYPES,payload: { }});
				}
				if(callBackFunction)callBackFunction({ success, count, data, message })
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				if(callBackFunction)callBackFunction({ success:false, count:[], data:[], message:error.message })
				//("Error****:", error.message);
			});
	};
};
export const getJobTypeStatusCount = (body = {},callBackFunction) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("jobType/getJobTypeStatusCount", body)
			.then(({ data: { success, data, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_TYPES_COUNT,payload: { data,success,message }});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message,});
					dispatch({type: FETCH_JOB_TYPES_COUNT,payload: { }});
				}
				callBackFunction({ success, data, message })
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};

export const addJobTypes = (body = {},callBackFunction) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("jobType/addJobType", body)
			.then(({ data: { success, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: SHOW_MESSAGE,payload: message});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
				}
				callBackFunction({ success, message })
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const updateJobTypes = (body = {},callBackFunction) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("jobType/updateJobType", body)
			.then(({ data: { success, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: SHOW_MESSAGE,payload: message});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
				}
				callBackFunction({ success, message })
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const deleteJobTypes = (body = {},callBackFunction) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("jobType/deleteJobType", body)
			.then(({ data: { success, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: SHOW_MESSAGE,payload: message});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
				}
				callBackFunction({ success, message })
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};



//Job Listing and creation
export const getAllJobs =(body, callBackFunction) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post("job/getJobList", body)
		.then(({ data: { success, count=0, data, message } }) => {
			if (success) {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({type: FETCH_ALL_JOBS,payload: { data, count }})
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({type: FETCH_ALL_JOBS,payload: { data:[], count:0 }})
			}

			if(callBackFunction)callBackFunction({ success, count, data, message });
		})
		.catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			// //("Error:", error.message);
		});
		
	};
};

export const getJobsStatus = (body = { type: "wms" }) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("job/getJobStatusCount", body)
			.then(({ data: { success, count, data, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_STATUS_COUNT,payload: { data, count }});
				}
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: FETCH_JOB_STATUS_COUNT,payload: { }});
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};

// export const getJobDetail = (body) => {
// 	return (dispatch) => {
// 		dispatch({ type: FETCH_START });
// 		axios
// 			.post("job/getJobDetail", body)
// 			.then(({ data: { success, count, data, message } }) => {
// 				if (success) {
// 					callback();
// 				} else {
// 					dispatch({
// 						type: FETCH_ERROR,
// 						payload: message,
// 					});
// 				}

// 				function callback() {
// 					dispatch({ type: FETCH_SUCCESS });
// 					dispatch({
// 						type: FETCH_JOB_DETAIL,
// 						payload: { data },
// 					});

// 					dispatch(
// 						getGridDetails({
// 							commonCellId: parseToCellIdsRackIds(data),
// 							type: ["block", "rack"],
// 						})
// 					);
// 				}
// 			})
// 			.catch(function (error) {
// 				dispatch({ type: FETCH_ERROR, payload: error.message });
// 				//("Error****:", error.message);
// 			});
// 	};
// };

export const getJobDetailV2 = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("job/getJobDetailV2", {...body,call_from:"web"})
			.then(({ data: { success, count, data, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_DETAIL,payload: { data,count }});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: FETCH_JOB_DETAIL,payload: { data:[],count:0 }});
				}
				
				if(callBack){
					callBack({success:success || false,message})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message})
				}
				dispatch({type: FETCH_JOB_DETAIL,payload: { data:[],count:0 }});
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const getJobLocationDetail = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("job/getJobLocationDetail", {...body,call_from:"web"})
			.then(({ data: { success, count=0, data=[], message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_LOCATION_DETAIL,payload: { data,count }});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: FETCH_JOB_LOCATION_DETAIL,payload: { data,count}});
				}

				if(callBack){
					callBack({success:success || false,message,data,count})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message,data:[],count:0})
				}
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};

export const generateJobPickList = (obj,callback) => {
	return async dispatch => {
	  dispatch({ type: FETCH_START });
	  await axios
		.post("/report/generatePickList", obj)
		.then(({ data }) => {
		  if (data.success) {
			dispatch({ type: FETCH_SUCCESS, payload: data.success });
			dispatch({ type: GENERATE_JOB_DETAIL, payload: data.downloadURL})
		  } 
		  else {
			dispatch({ type: FETCH_ERROR, payload: data.message });
			dispatch({ type: GENERATE_JOB_DETAIL, payload: "" });
		  }
		  if(callback){
			callback({success:data.success,message:data.message,data:data.data})
		  }
		})
		.catch(function (error) {
		  dispatch({ type: FETCH_ERROR, payload: error.message });
		  if(callback){
			callback({success:false,message:error.message,data:""})
		  }
		  ////("Error****:", error.message);
		});
	};
};

export const getJobPendencyDetail = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("job/getPendencyDetail", body)
			.then(({ data: { success, count=0, data=[], message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_PENDENCY_DETAIL,payload: { data,count }});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: FETCH_JOB_PENDENCY_DETAIL,payload: { data,count}});
				}

				if(callBack){
					callBack({success:success || false,message,data,count})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message,data:[],count:0})
				}
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const getJobProcessConfig = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("base/getJobProcessConfig", body)
			.then(({ data: { success, data={}, message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: FETCH_JOB_PROCESS_CONFIG,payload:data});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: FETCH_JOB_PROCESS_CONFIG,payload:data});
				}

				if(callBack){
					callBack({success:success || false,message,data})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message,data:{}})
				}
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const generateDispatchOrderNo = (body,callBack) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("base/generateDispatchOrderNo", body)
			.then(({ data: { success, data="", message } }) => {
				if (success) {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({type: GENERATE_DISPATCH_ORDER_NUMBER,payload:data});
				} 
				else {
					dispatch({type: FETCH_ERROR,payload: message});
					dispatch({type: GENERATE_DISPATCH_ORDER_NUMBER,payload:data});
				}

				if(callBack){
					callBack({success:success || false,message,data})
				}
			})
			.catch(function (error) {
				if(callBack){
					callBack({success:false,message:error.message,data:""})
				}
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};

export const getUsersToAssignJob = (obj,callback) => {
	return async dispatch => {
	  dispatch({ type: FETCH_START });
	  await axios
		.post("/job/getUsersToAssignJob", obj)
		.then(({ data }) => {
		  if (data.success) {
			dispatch({ type: FETCH_SUCCESS, payload: data.success });
			dispatch({ type: GET_USERS_TO_ASSIGN_JOB, payload: data?.data || []})
		  } 
		  else {
			dispatch({ type: FETCH_ERROR, payload: data.message });
			dispatch({ type: GET_USERS_TO_ASSIGN_JOB, payload: [] });
		  }
		  if(callback){
			callback(data)
		  }
		})
		.catch(function (error) {
		  dispatch({ type: FETCH_ERROR, payload: error.message });
		  if(callback){
			callback({success:false,message:error.message,data:[]})
		  }
		});
	};
};

export const assignJobToUser = (obj,callback) => {
	return async dispatch => {
	  dispatch({ type: FETCH_START });
	  await axios
		.post("/job/assignJobToUser", obj)
		.then(({ data }) => {
		  if (data.success) {
			dispatch({ type: FETCH_SUCCESS, payload: data.success });
		  } 
		  else {
			dispatch({ type: FETCH_ERROR, payload: data.message });
		  }
		  if(callback){
			callback({success:data.success,message:data.message})
		  }
		})
		.catch(function (error) {
		  dispatch({ type: FETCH_ERROR, payload: error.message });
		  if(callback){
			callback({success:false,message:error.message,})
		  }
		});
	};
};
export const approveJobCompletion = (obj,callback) => {
	return async dispatch => {
	  dispatch({ type: FETCH_START });
	  await axios
		.post("/job/approveJobCompletion", obj)
		.then(({ data }) => {
		  if (data.success) {
			dispatch({ type: FETCH_SUCCESS, payload: data.success });
		  } 
		  else {
			dispatch({ type: FETCH_ERROR, payload: data.message });
		  }
		  if(callback){
			callback({success:data.success,message:data.message})
		  }
		})
		.catch(function (error) {
		  dispatch({ type: FETCH_ERROR, payload: error.message });
		  if(callback){
			callback({success:false,message:error.message,})
		  }
		});
	};
};

export const getAllWarehouseAndFloors = (body) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("company/getCompany", body)
			.then(({ data: { success, count, data, message } }) => {
				if (success) {
					callback();
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: message,
					});
				}

				function callback() {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({
						type: FETCH_WAREHOUSE_FLOORS,
						payload: { data: parseIdToName(data) },
					});
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};
export const getTemplateHeaders = (body) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("inventory/getTemplateHeader", body)
			.then(({ data: { success, count, templateHeader, message } }) => {
				if (success) {
					callback();
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: message,
					});
				}

				function callback() {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({
						type: FETCH_TEMPLATE_HEADERS,
						payload: { data: templateHeader },
					});
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};



export const getShipments = (body = {}) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START });
		axios
			.post("inventory/getInventoryData", body)
			.then(({ data: { success, count, data, message } }) => {
				if (success) {
					callback();
				} else {
					dispatch({
						type: FETCH_ERROR,
						payload: message,
						payload: { data:[], count:0 }
					});
				}

				function callback() {
					dispatch({ type: FETCH_SUCCESS });
					dispatch({
						type: FETCH_SHIPMENTS,
						payload: { data, count },
					});
				}
			})
			.catch(function (error) {
				dispatch({ type: FETCH_ERROR, payload: error.message });
				//("Error****:", error.message);
			});
	};
};

export const createJob = (body,callBackFunction) => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.post("job/createWarehouseJob", body)
		.then(({ data: { success, message } }) => {
			if (success) {
				dispatch({ type: FETCH_SUCCESS });
			} 
			else {
				dispatch({type: FETCH_ERROR,payload: message,});
			}

			if(callBackFunction){
				callBackFunction({success,message}) 
			}
		})
		.catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			//("Error****:", error.message);
			
			if(callBackFunction){
				callBackFunction({success:false,message: error.message}) 
			}
		});
	};
};
export const getInvForJobCreation = (payload,callBack) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/job/getInvForJobCreation', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_JOB_CREATION, payload: data.data})
             
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_JOB_CREATION, payload: []})
            }
            if(callBack){
                callBack(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_JOB_CREATION, payload: []})
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(callBack){
                callBack({success:false,message:error.message})
            }
            //("Error****:", error.message);
        });
    }
};

function parseToCellIdsRackIds(data) {
	// //({ parse: data });
	const result = data?.location?.reduce((acc, curr) => {
		if (curr.cellId) acc.push(curr.cellId);
		if (curr.rackId) acc.push(curr.rackId);
		return acc;
	}, []);

	// //({result})
	return result;
}

function parseIdToName(data) {
	// //({ parse: data });
	const result = data?.reduce((acc, curr) => {
		acc[curr._id] = curr.name;
		return acc;
	}, {});

	// //({result})
	return result;
}
function parseCellIdToName(data) {
	// //({ parse: data });
	const result = data?.reduce((acc, curr) => {
		acc[curr.cellId] = curr.name;
		return acc;
	}, {});

	// //({result})
	return result;
}

export const getWorkOrderListConfig = (payload,callBack) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/report/getWorkerOrderConfig', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WORK_ORDER_LIST_CONFIG, payload: data.data})
             
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_WORK_ORDER_LIST_CONFIG, payload: []})
            }
            if(callBack){
                callBack(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_WORK_ORDER_LIST_CONFIG, payload: []})
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(callBack){
                callBack({success:false,message:error.message})
            }
            //("Error****:", error.message);
        });
    }
};

export const endJobFunction = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('job/endJobs', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
			if(cb){
				cb(data)
			}
    
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb({success:false,message:error.message})
            }
        });
    }
};

export const getJobShipmentDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('job/getJobShipmentDetail', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success){
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_JOB_SHIPMENT_DETAIL, payload: {data: data?.data || {}, count: data?.count}})
            } 
            else{
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_JOB_SHIPMENT_DETAIL, payload: {data: {}, count: 0}})
            }

            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(cb){
                cb({success:false, message: error.message})
            }
        });
    }
};