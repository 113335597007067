import React from 'react';
import {lynkitIOURL} from '../../../../services/config';
import Integration from '../../../Components/Integration';
import { Grid } from '@mui/material';

const Trip = () => {
    const todayDate = new Date().getTime();
    const myVariable = `${lynkitIOURL}/login?action=/trip&auth=WMS&timestamp=${todayDate}`;
    return (
        <Grid data-testid="tripHome">
            <Integration
                myVariable={myVariable}
                baseURL={lynkitIOURL}
            />
        </Grid>
    );
};

export default Trip;
