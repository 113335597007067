import { Breadcrumbs, FormControlLabel, FormControl, Button, FormLabel, Grid, Menu, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getAllInventory, getAllWarehouses, getItemDetail, getPackageDetail, getShipmentDetail } from '../../../../redux/actions'
import { createInventoryAdjustment, getAllAdjustmentReasons, getAllInventoryAdjustment } from '../../../../redux/actions/InventoryAdjustment'
import { showToast } from '../../../../services/functions'
import history from '../../../../services/history'
import theme, { buttonClasses, cardClasses, textClasses } from '../../../../static/styles/theme'
import { CustomControlledInput } from '../../../Components/Utils'
import GenericLoader from '../../../Components/Generic/GenericLoader'

// IN85095

export default function EditInventoryAdjustment() {

    const { refNo } = useParams();
    
    const dispatch = useDispatch();
    const { register, control, handleSubmit, watch, setValue, formState: {errors}} = useForm();
    const { register: register2, control: control2, handleSubmit: handleSubmit2, watch: watch2, setValue: setValue2, formState: {errors: errors2}} = useForm();
    
    const [remark, setRemark] = useState("");
    const [loader, setLoader] = useState(false);

    const categoryKey = watch("categoryKey");
    const adjustmentCategory = watch("categoryName");

    // //("Category Key", categoryKey);
    // //("Adjustment category", adjustmentCategory);
    
    // const [refNo, setRefNo] = useState("");
    const [adjustmentReason, setAdjustmentReason] = useState("-");
    const [adjustmentType, setAdjustmentType] = useState("quantity");
    const [tableData, setTableData] = useState([]);
    // const [adjustmentCategory, setAdjustmentCategory] = useState("shipment");

    const allAdjustments = useSelector(state => state.inventoryAdjustment.allAdjustments);
    const [adjustment, setAdjustment] = useState(null);
    // //("Adjustment", adjustment);
    const allWarehouses = useSelector(state => state.inward.allWarehouses);
    const allReasons = useSelector(state => state.inventoryAdjustment.adjustmentReasons)
    const inventories = useSelector(state => state.inventory.allInventories);
    const shipmentData = useSelector(state => state.inventory.shipmentDetail);
    const packageData = useSelector(state => state.inventory.packageDetail);
    const itemData = useSelector(state => state.inventory.itemDetail);
    // //(shipmentData);
    // //(packageData);
    // //(itemData);

    const addCount = tableData.filter(r => r.actionType == "Add").map((i) => Number(i.quantity)).reduce((a, b) => a+b , 0)
    const subCount = tableData.filter(r => r.actionType == "Subtract").map((i) => Number(i.quantity)).reduce((a, b) => a+b , 0)

    const fields = {
        warehouseId: {
            "_id": "warehouseId",
            "type": "select",
            "label": "Select Warehouse",
            "description": "Select",
            "required": true,
            "placeholder": "Select warehouse",
            "values": allWarehouses.map((w, i) => ({label: w.name, value: w._id})),
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        adjustmentType: {
            "_id": "adjustmentType",
            "type": "radio",
            "label": "Adjustment Type",
            "description": "Select",
            "required": true,
            "placeholder": "Select type",
            "values": [
                {
                    "label": "Quantity",
                    "value": "quantity"
                },
            ],
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        referenceNumber: {
            "_id": "referenceNumber",
            "type": "input",
            "label": "Reference Number",
            "description": "rno",
            "required": true,
            "placeholder": "Enter reference number",
        },
        adjustmentReason: {
            "_id": "reasonKey",
            "type": "select",
            "label": "Adjustment Reason",
            "description": "Select",
            "required": true,
            "placeholder": "Select reason",
            "values": allReasons.map((r, i) => ({label: r.adjustment_reason, value: r.key})),
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        categoryName: {
            "_id": "categoryName",
            "type": "radio",
            "label": "Adjustment category",
            "description": "",
            "required": true,
            "placeholder": "",
            "values": [
                // {
                //     "label": "Individual Item(s) or SKU",
                //     "value": "sku"
                // },
                {
                    "label": "Shipments",
                    "value": "shipment"
                },
                {
                    "label": "Packages",
                    "value": "package"
                },
                // {
                //     "label": "Items",
                //     "value": "item"
                // },
            ]
        },
        categoryKey: {
            "_id": "categoryKey",
            "type": "select",
            "label": `${adjustmentCategory} Number`,
            "description": "Select",
            "required": true,
            "placeholder": `Select ${adjustmentCategory} number`,
            "values": inventories.map((inv, i) => ({label: inv[`${adjustmentCategory}Id`], value: inv[`${adjustmentCategory}Id`]})),
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        quantity: {
            "_id": "quantity",
            "type": "number",
            "label": "Quantity",
            "description": "rno",
            "required": true,
            "placeholder": "To be adjusted quantity",
        },
        actionType: {
            "_id": "actionType",
            "type": "radio",
            "label": "Action Type",
            "description": "",
            "required": true,
            "placeholder": "",
            "values": [
                {
                    "label": "Add QTY",
                    "value": "Add"
                },
                {
                    "label": "Subtract QTY",
                    "value": "Subtract"
                }
            ]
        },
        remark: {
            "_id": "remark",
            "type": "textarea",
            "label": "Remark(s)",
            "description": "rno",
            "required": false,
            "placeholder": "Enter remark(s)",
        },

    }

    
    const handleAddRow = (data) => {
        let row = {
            categoryName: data.categoryName,
            categoryKey: data.categoryKey,
            quantity: Number(data.quantity),
            actionType: data.actionType    
        }
        setTableData([...tableData, row])
    }


    const handleDraft = (data) => {
        handleCreate({...data, status: "DRAFTED"})
    }

    const handlePublish = (data) => {
        handleCreate({...data, status: "ADJUSTED"})
    }
    const handleCreate = (data) => {
        if(tableData.length > 0){
            let payload = {
                "referenceNumber": data.referenceNumber,
                "warehouseId": data.warehouseId,
                "reasonKey": data.reasonKey, 
                "adjustmentCategory": tableData,
                "status": data.status,
                "remark": data.remark
            }
            dispatch(
                createInventoryAdjustment(payload, (res) => {
                    if(res){
                        history.push("/dashboard/inventory/adjustments");
                    }
                })
            )
        }   
        else{
            showToast("Please add adjustments.", true)
        }
    }

    useEffect(() => {
        setLoader(true);
        setAdjustment(null);
        if(refNo){
            dispatch(
                getAllInventoryAdjustment({refNo: refNo}, (res) => {
                    setLoader(false);
                    // //("OUTPUT", res);
                    if(res && res.length > 0){
                        setAdjustment(res[0]);
                    }
                })
            )
        }
        
    }, [])

    useEffect(() => {
        if(adjustment){
            setValue2("referenceNumber", adjustment.referenceNumber);
            setValue2("warehouseId", adjustment.warehouseId);
            setValue2("adjustmentType", adjustment.adjustmentType);
            setValue2("reasonKey", adjustment.reasonKey);
            let row = {
                categoryName: adjustment.categoryName,
                categoryKey: adjustment.categoryKey,
                quantity: Number(adjustment.quantity),
                actionType: adjustment.actionType    
            }
            setTableData([row])
        }

    }, [adjustment])

    useEffect(() => {
        dispatch(getAllWarehouses({type: "warehouse"}));
        dispatch(getAllAdjustmentReasons({}));
    }, [])

    useEffect(() => {
        if(adjustmentCategory && adjustmentCategory != ""){
            let payload = {
                type: "wms",
                dataFetchType: adjustmentCategory+"Wise"
            }
            dispatch(
                getAllInventory(payload, () => {
    
                })
            )
        }
    }, [adjustmentCategory])

    useEffect(() => {
        // //("Category changed");
        if(categoryKey && categoryKey != "-" && categoryKey != ""){
            if(adjustmentCategory == "shipment"){
                dispatch(
                    getShipmentDetail({shipmentId: categoryKey})
                )
            }
            if(adjustmentCategory == "package"){
                // //("dispatching package details");
                let shipmentId = inventories.filter(i => i.packageId == categoryKey)[0].shipmentId;

                dispatch(
                    getPackageDetail({shipmentId: shipmentId, packageId: categoryKey})
                )
            }
            if(adjustmentCategory == "item"){
                let shipmentId = inventories.filter(i => i.itemId == categoryKey)[0].shipmentId;
                dispatch(
                    getItemDetail({shipmentId: shipmentId, itemId: categoryKey})
                )
            }
        }
    }, [adjustmentCategory, categoryKey])

    return (
        <>
            <Typography sx={{...textClasses.cardTitle}}>Inventory Adjustment</Typography>
            <Breadcrumbs sx={{mb: 1}}>
                <Typography sx={{...textClasses.normalText, fontSize: "12px", color: theme.themeOrange}}>
                    <Link style={{color: theme.themeOrange, textDecoration: "none"}} to="/dashboard/templates">
                        Inventory Adjustments
                    </Link>
                </Typography>
                
                <Typography sx={{...textClasses.normalText, fontSize: "12px"}}>Add New Adjustment</Typography>
            </Breadcrumbs>

            <Grid container>
                {
                    loader
                    ?
                        <>
                            <Paper elevation={1} sx={{...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%"}}>
                                <Grid container justifyContent={"center"}>
                                    <GenericLoader />
                                </Grid>
                            </Paper>
                        </>
                    :
                
                    <Paper elevation={1} sx={{...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%"}}>
                        <Grid container>
                            <Grid container item sm={6} xs={6}>
                                <Typography sx={{...textClasses.cardTitle}}>Add New Adjustment</Typography>
                            </Grid>
                            <Grid container item sm={3} xs={6} justifyContent="end">
                                
                            </Grid>
                            <Grid container item sm={3} xs={6} justifyContent="end">
                                <CustomControlledInput action={"use"} register={register2} component={fields.warehouseId} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                        </Grid>

                        
                        <Grid container sx={{mt: 1}}>
                            <Grid container item sm={2} xs={3} alignItems="center">
                                <Typography sx={{...textClasses.boldText}}>Adjustment Type:</Typography>
                            </Grid>
                            <Grid container item sm={10} xs={9}>
                                <Grid container item sm={6} xs={6} alignItems="center" justifyContent={"left"}>
                                    <CustomControlledInput action={"use"} register={register2} component={fields.adjustmentType} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container sx={{mt: 1}}>
                            <Grid container item sm={2} xs={3} alignItems="center">
                                <Typography sx={{...textClasses.boldText}}>Reference Number:</Typography>
                            </Grid>
                            <Grid container item sm={3} xs={9}>
                                <CustomControlledInput action={"use"} register={register2} component={fields.referenceNumber} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{mt: 2}}>
                            <Grid container item sm={2} xs={3} alignItems="center">
                                <Typography sx={{...textClasses.boldText}}>Adjustment Reason:</Typography>
                            </Grid>
                            <Grid container item sm={3} xs={9}>
                                <CustomControlledInput action={"use"} register={register2} component={fields.adjustmentReason} control={control2} errors={errors2} autoFillOnFocus={() => {}}/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{mt: 2}}>
                            <Grid container item xs={3} sm={2} alignItems="center">
                                <Typography sx={{...textClasses.boldText}}>Adjustment Category:</Typography>
                            </Grid>
                            <Grid container item xs={6} sm={9} alignItems="center">
                                <CustomControlledInput action={"use"} register={register} component={fields.categoryName} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{mt: 2}}>
                            <Grid container item xs={3} sm={2} alignItems="center">
                            </Grid>
                            <Grid container item xs={6} sm={10} alignItems="center">
                                <Grid container sm={4} xs={6} sx={{pr: 1, height: "100%"}}>
                                    <CustomControlledInput action={"use"} register={register} component={fields.categoryKey} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                                    <Typography sx={{fontSize: "11px"}}>*add multiple SKUs one-by-one, the table below showing list of SKUs to be adjusted.</Typography>
                                </Grid>
                                <Grid container sm={4} xs={6} sx={{pl: 1, height: "100%"}}>
                                    <CustomControlledInput action={"use"} register={register} component={fields.quantity} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                                    {
                                        adjustmentCategory && categoryKey != "-" && categoryKey != "" &&
                                        <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 2}}>
                                            Available QTY: 
                                            {
                                                adjustmentCategory == "shipment" && shipmentData != null
                                                ?
                                                Number(shipmentData.balanceCount.physical)
                                                :
                                                ""
                                            }
                                            {
                                                adjustmentCategory == "package" && packageData.length > 0
                                                ?
                                                Number(packageData[0].balanceCount.physical)
                                                :
                                                ""
                                            }
                                            {
                                                adjustmentCategory == "item" && itemData.length > 0
                                                ?
                                                Number(itemData[0].balanceCount.physical)
                                                : 
                                                ""
                                            }
                                        </Typography>
                                    }
                                    
                                </Grid>
                                <Grid container sm={4} xs={6} sx={{pl: 2, height: "100%"}} alignItems="center" direction={"column"}>
                                    <CustomControlledInput action={"use"} register={register} component={fields.actionType} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button variant='outlined' size='small' sx={{...buttonClasses.lynkitOrangeFill}} onClick={handleSubmit(handleAddRow)}>Add row</Button>
                        <Paper elevation={1}>
                            <table style={{width: "100%", marginTop: "15px"}}>
                                <thead style={{backgroundColor: theme.themeGray}}>
                                    <tr className='roundedRow'>
                                        
                                        {
                                            ["Adjustment Category", "QTY", "Action Type", "Action"].map((head, i) => {
                                                return (
                                                    <td style={{padding: "13px 10px 13px 10px", ...textClasses.boldText, textAlign: "center"}}>{head}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.map((row, i) => {
                                            return (
                                                <tr
                                                    key={i}
                                                    style={{borderBottom: "1px solid #0000002B"}}   
                                                >
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center", textTransform:"capitalize"}}>
                                                        {row.categoryName}
                                                    </td>
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center", textTransform:"capitalize"}}>
                                                        {row.quantity}
                                                    </td>
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center", textTransform:"capitalize"}}>
                                                        {row.actionType}
                                                    </td>
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center", textTransform:"capitalize"}}>
                                                        <Typography sx={{...textClasses.normalText, color: theme.themeOrange}}>Edit/Delete</Typography>
                                                    </td>    
                                                
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                    
                                </tbody>
                            </table>
                        </Paper>
                        <Grid container direction={"row"} sx={{mt: 2}}>
                            <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Total To Be Adjusted QTY: </Typography>
                            <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 3}}>
                                Add: {addCount}
                            </Typography>
                            <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 3}}>Subtract: {subCount}</Typography>
                        </Grid>
                        <Typography sx={{...textClasses.boldText, mt: 2}}>Remarks</Typography>
                        <Grid container sx={{mt: 1}}>
                            <Grid item sm={6} xs={12}>
                                <CustomControlledInput action={"use"} register={register2} component={fields.remark} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                                {/*  <TextField variant='outlined' size='small' fullWidth multiline rows={4} value={remark} onChange={(e) => setRemark(e.target.value)}/> */}
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"} sx={{mt: 2}}>
                            <Button variant="outlined" sx={{...buttonClasses.lynkitBlackFill}}>Cancel</Button>
                            <Button variant="outlined" sx={{...buttonClasses.lynkitOrangeFill, ml: 2}} onClick={handleSubmit2(handleDraft)}>Save as Draft</Button>
                            <Button variant="outlined" sx={{...buttonClasses.lynkitOrangeEmpty, ml: 2}}>Submit & Create A New Job</Button>
                            <Button variant="outlined" sx={{...buttonClasses.lynkitOrangeFill, ml: 2}} onClick={handleSubmit2(handlePublish)}>Submit</Button>
                        </Grid>
                    </Paper>
                }
            </Grid>

        </>
    )
}
