import {
  INIT_URL, DUMMY_FETCH_TEMPLATE_DETAIL, DUMMY_FETCH_TEMPLATE_LIST, DUMMY_FETCH_INVENTORY_LIST,
  DUMMY_FETCH_INVENTORY_DETAIL, DUMMY_FETCH_SHIP_DETAIL
} from "../types";

const INIT_STATE = {
  allTemplates: [],
  totalRows:0,
  templateDetail:null,
  allInv:[],
  invDetail:null,
  shipDetail:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    
    case DUMMY_FETCH_TEMPLATE_LIST: {
      return {...state, allTemplates: action.payload.row, totalRows: action.payload.count};
    }
    case DUMMY_FETCH_TEMPLATE_DETAIL: {
        return {...state, templateDetail: action.payload};
      }
     
      case DUMMY_FETCH_INVENTORY_LIST: {
        return {...state, allInv: action.payload.row, totalRows: action.payload.count};
      }
      case DUMMY_FETCH_INVENTORY_DETAIL: {
          return {...state, invDetail: action.payload};
        }

      case DUMMY_FETCH_SHIP_DETAIL: {
          return {...state, shipDetail: action.payload};
        }
           

   

    default:
      return state;
  }
}