import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    FETCH_USER_PRODUCTIVITY,
    FETCH_USERS_TYPE_WISE_COUNT
  } from "../types";
import axios from "../../services/Api";

export const getAllUsers = (bodydata, callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/users/getUsers', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            if(bodydata.withoutFilterStockTransfer){
              dispatch({ type: "GET_ALL_USERS_WITHOUT_FILTER", payload: data });  
            }
            else{
              dispatch({ type: "GET_ALL_USERS_DATA", payload: data });
            }
            
            // dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
            
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            if(bodydata.withoutFilterStockTransfer){
              dispatch({ type: "GET_ALL_USERS_WITHOUT_FILTER", payload: {data: []} });  
            }
            else{
              dispatch({ type: "GET_ALL_USERS_DATA", payload: {data: []} });
            }
            
            
          }
          if(callBackFunction){
            callBackFunction(data)
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message,success:false })
          }
        });
      }
};


export const addUsers = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/users/createUser', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            // dispatch({ type: "GET_ALL_USERS_DATA", payload: {} });
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};

export const editUsers = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/users/editUser', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message}); 
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};

export const deleteUsers = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/users/deleteUser', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
        });
      }
};

export const blockUsers = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/users/changeStatusUser', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
        });
      }
};

export const getActiveBlockCounts = (bodydata,callBack) =>
{
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        await axios.post('/users/getUserCountByStatus', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({ type: "GET_COUNTS", payload: data });           
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_COUNTS", payload: {} });
            
          }
          if(callBack){
            callBack(data)
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          if(callBack){
            callBack({success:false,message:error.message})
          }
          //("Error****:", error.message);
        });
      }
};
export const getUserProductivity = (bodydata, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios.post('/users/getUserProductivity', bodydata
    ).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        dispatch({ type: FETCH_USER_PRODUCTIVITY, payload: data?.data });
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: FETCH_USER_PRODUCTIVITY, payload: {} });
      }
      if (cb) {
        cb({ success: data.success, message: data.message, data: data?.data || {} })
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_USER_PRODUCTIVITY, payload: {} });
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (cb) {
        cb({ success: false, message: error.message })
      }
      //("Error****:", error.message);
    });
  }
};

export const getUsersTypeWiseCount = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/users/getUserTypesCounts', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_USERS_TYPE_WISE_COUNT, payload: data.data })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_USERS_TYPE_WISE_COUNT, payload: null })
          }


          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};
