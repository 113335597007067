import React from 'react'
import { Grid, Typography, Button } from '@mui/material'
import BarcodeImage from "../../../../../static/images/data_entry/barcode.svg"
import theme, { textClasses,buttonClasses  } from '../../../../../static/styles/theme'
import history from '../../../../../services/history'
import { useState } from 'react'
import GenerateLabel from "../../../Inventory/Label"
import { useSelector } from 'react-redux'

export default function ShipmentAdded({spiType, templateId,shipmentId, packageCount, itemCount, handleAddMore}) {
    const handleGateIn = () => {
        history.push("/dashboard/inventory/all")
    }
    
    const [view,setView] = useState(false)
    const { userPermissions } = useSelector((state) => state.userPermission);
    let permissions = userPermissions || {};

    const successMessage = (() => {
        let message = "";
        if(spiType){
            if(spiType == "shipmentOnly"){
                return message
            }
            else if(spiType == "shipmentPackageOnly"){
                message = message.concat(`with ${packageCount} packages`)
            }
            else if(spiType == "SPI"){
                message = message.concat(`with ${packageCount} packages and ${itemCount} items`)
            }
            else if(spiType == "shipmentItemOnly"){
                message = message.concat(`with ${itemCount} items`)
            }
        }
        return message;
    })();

    return (
        <Grid container direction="column" justifyContent={"center"} alignItems="center">
            <img src={BarcodeImage} height="250"/>
            <Typography sx={{...textClasses.boldText, fontSize: "20px"}}>New Shipment Added To Your Inventory</Typography>
            <Typography sx={{...textClasses.boldText, fontSize: "12px", textAlign: "center", mt: 1, mb: 2}}>
                <span style={{color: theme.themeOrange}}>Shipment ID {shipmentId}</span> {successMessage} has been added with Gate In Pending status. create and assign Gate-In Job to your internal workers. 
            </Typography>
            <Grid container item spacing={5}>
            {permissions && (permissions.permissions || []).some(item => item.permissionId === "generateInventoryLabel") ?
                <>
                   {templateId && shipmentId && <Grid container item sm={4} xs={4} justifyContent="center" alignItems="center">
                        <Button variant='contained' fullWidth size='small' sx={{...buttonClasses.small, fontSize: "14px", minHeight: "40px", backgroundColor: theme.themeOrange}} onClick={()=>setView(true)}>
                            Generate Label
                        </Button>
                    </Grid>}
                </>
         
                               
                : ''}
               
                <Grid container item sm={4} xs={4} justifyContent="center" alignItems="center">
                {permissions && (permissions.permissions || []).some(item => item.permissionId === "viewInventory") ?
                    <Button variant='outlined' fullWidth size='small' sx={{...buttonClasses.outlined, color: theme.themeOrange}} onClick={handleGateIn}>
                        View Inventory
                    </Button>
         
                               
                    : ''}

                    
                </Grid>
                <Grid container item sm={4} xs={4} justifyContent="center" alignItems="center">
                {permissions && (permissions.permissions || []).some(item => item.permissionId === "addNewInventory") ?
                     <Button variant='contained' fullWidth size='small' sx={{...buttonClasses.small, fontSize: "14px", minHeight: "40px", backgroundColor: theme.themeOrange}} onClick={handleAddMore}>
                        Add More shipment
                    </Button>
         
                               
                    : ''}
                   
                </Grid>
                
            </Grid>

            { view && <GenerateLabel
                data={{templateId,shipmentId}}
                showModal={view}
                handleCloseModal={()=>setView(false)}
            />}
        </Grid>
    )
}
