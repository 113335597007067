import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import theme, {
    textClasses,
    buttonClasses,
} from "../../../static/styles/theme";
import {
    getInputFieldForTableRow,
} from "../../Components/Utils";
import GenericLoader from "../../Components/Generic/GenericLoader";
import { handleDynamicChange,calculateTarget } from "../../../services/formFunction";
import {
    getShipmentConnectedMasterDetail,
} from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { Add, Delete, Edit, Remove, Search } from "@mui/icons-material";
import DetailDialog from "./DetailDialogBox";

let initialSKUDropdownItems = [
    { label: "SKU ID", checked: true, name: "formData.SKU_ID", disabled: true },
    { label: "SKU Name", checked: true, name: "formData.SKU_Detail", disabled: true },
    { label: "Current Stock", checked: false, name: "currentStock" },
    { label: "Total In (Till Date)", checked: false, name: "totalInStock" },
    { label: "Total Out (Till Date)", checked: false, name: "totalOutStock" },

];

const OrderSection = ({
    template,
    errors,
    dimensions,
    items,
    setItems,
    totalQuantity,
    countTillNow,
    remainingItems,
    validationCallback,
    informationShownForSKU
}) => {
    const dispatch = useDispatch();
    const { reset } = useForm();
    const masterType = (() => {
        if (template) {
            if (template.connectedMaster && template.connectedMaster.itemMaster) {
                return "itemMaster";
            }
        }
        return null;
    })();

    const [dialogData, setDialogData] = useState({});
    // const [errors, setErrors] = useState({})

    const allUom = useSelector((state) => state.uom.allUom);
    //
    const allFields =
        template?.otherTemplate && template?.otherTemplate?.length
            ? template?.otherTemplate?.at(0)?.attributes
            : [];
    // let uomIndex = allFields.map(f => f._id).indexOf("UOM");
    // if(uomIndex >= 0){
    //     allFields[uomIndex].values = allUom.map(u => ({
    //         label: u.unitName,
    //         value: u.unitValue
    //     }))
    // }
    // else{
    //     let field = {
    //         "_id": "UOM",
    //         "type": "select",
    //         "label": "Unit Of Measurement (UOM)",
    //         "description": "unit of measurements",
    //         "placeholder": "select Unit",
    //         "values": allUom.map(u => ({
    //             label: u.unitName,
    //             value: u.unitValue
    //         })),
    //         "required": true,
    //         "forType": "all",
    //         "elementType": "Selection Type",
    //         "sortType": 2,
    //     }
    //     allFields.push(field);
    // }
    const mandatoryFieldsId = useSelector(
        (state) => state.canvas.components.itemTemplate.mandatory
    )
        .filter((f) => f.templateType == template.templateType)
        .map((f) => f._id);
    const mandatoryFields = allFields.filter(
        (f) => mandatoryFieldsId.indexOf(f._id) >= 0
    );
    const otherFields = allFields.filter(
        (f) => mandatoryFieldsId.indexOf(f._id) < 0
    );
    // //("Other Fields", otherFields);
    const autoFillOnFocus = (index) => {
        if (dimensions) {
            let oldItems = [...items];
            for (let i = 0; i < otherFields.length; i++) {
                let compId = otherFields[i]._id;
                if (
                    ["weight", "length", "breadth", "height"].indexOf(compId) >= 0 &&
                    dimensions[compId]
                ) {
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setItems(oldItems);
        }
    };

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = (error) => {
                        reject(error);
                    };
                }, 400);
            });
        } else {
        }
    };

    const handleGenericChange = async (event, i, fieldId) => {
        const targetField = allFields.find((f) => f._id == fieldId);
        let oldItems = [...items];
        let oldObject = { ...oldItems[i] };
        if (targetField.type == "file") {
            const file = await convertDocsBase64(event.target.files[0]);
            oldObject[fieldId] = file;
        } else if (targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if (event.checked) {
                oldValues.push(event.value);
            } else {
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1);
            }
            oldObject[fieldId] = [...oldValues];
        } else {
            oldObject[fieldId] = event.target.value;
        }

        if (["select"].includes(targetField.type) && targetField.setTargetValue) {
            let temp = targetField.targetParameters;
            let tempObj={"formData.UOM":1}
            for (let ele of informationShownForSKU) {
                if(["SKU_ID","SKU_Detail"]?.includes(ele)){
                    tempObj[`formData.${ele}`]=1
                }else{
                tempObj[ele]=1
                }
            }
            temp.push({label:'projections',value:tempObj})
            oldObject["itemDetail"] = null
            if (event.target.value) {
            changeLoader(i, true);
            await handleDynamicChange(
                oldObject,
                { ...targetField, passToken: JSON.parse(localStorage.getItem("passToken")) },
                { value: event.target.value || "" },
                "itemDetail"
            );
            if (!oldObject["itemDetail"] || Object.keys(oldObject["itemDetail"]).length == 0) {
                oldObject["itemDetail"] = null
              }
            oldObject["loader"] = true;
            oldObject["tableHeader"] = initialSKUDropdownItems;
            oldObject["loader"] = false;
            }
        else {
            calculateTarget(oldObject, targetField, {})
          }
        }
        oldItems[i] = { ...oldObject };
        setItems([...oldItems]);
    };

    const getTableHeader = (headerType) => {
        let base = ["Quantity", "Item Details"];
        if (masterType == "itemMaster") {
            base.push("Detail");
        }
        let otherHeader = allFields
            .filter((f) => ["product_ID", "quantity"].indexOf(f._id) < 0)
            .map((of) => {
                let label = of.label;
                if (
                    headerType == "excel" &&
                    ["select", "radio", "checkbox"].indexOf(of.type) >= 0
                ) {
                    label = `${label} (${of.values.map((o) => o.value).join(",")})`;
                }
                return label;
            });
        return [...base, ...otherHeader];
    };

    const getItemRow = (i) => {
        let item = items[i];

        let base = [
            <ButtonGroup
                size="small"
                sx={{
                    minWidth: "24px",
                    ".MuiButtonGroup-grouped": { minWidth: "25px" },
                }}
            >
                <Button
                    key="one"
                    size="small"
                    sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
                    onClick={() => handleCountChange(-1, i)}
                >
                    <Remove fontSize="inherit" />
                </Button>
                <input
                    type="number"
                    style={{
                        border: "1px solid #D9D9D9",
                        maxWidth: "60px",
                        fontSize: "12px",
                        textAlign: "center",
                    }}
                    value={item.quantity || null}
                    onChange={(e) => handleCountChange(e, i)}
                    onWheel={(event) => event.target.blur()}
                    onKeyDown={(e) => {
                        if (["e", "E", "+", "-"].includes(e.key)) {
                            e.preventDefault();
                        } else if (
                            e.key == "0" &&
                            (e.target.value == "" || Number(e.target.value) == 0)
                        ) {
                            e.preventDefault();
                        }
                    }}
                />
                <Button
                    key="three"
                    size="small"
                    sx={{ ...buttonClasses.smallOrange, fontSize: "12px" }}
                    onClick={() => handleCountChange(1, i)}
                >
                    <Add fontSize="inherit" />
                </Button>
            </ButtonGroup>,
            getInputFieldForTableRow(
                { ...(allFields.find((f) => f._id == "product_ID") || {}), passToken: JSON.parse(localStorage.getItem("passToken")) },
                items,
                i,
                handleGenericChange,
                autoFillOnFocus,
                errors,
                false,
                null,
                item.itemDetail != null
            ),
        ];
        if (masterType == "itemMaster") {
            base.push(
                item.loader ? (
                    <GenericLoader />
                ) : (
                    <Typography
                        sx={{
                            ...textClasses.boldText,
                            color: theme.themeOrange,
                            cursor: "pointer",
                        }}
                        onClick={() => handleOpenDetail(i)}
                    >
                        {item.itemDetail != null ? "View Details" : "No data yet"}
                    </Typography>
                )
            );
        }
        const others = allFields
            .filter((f) => ["product_ID", "quantity"].indexOf(f._id) < 0)
            .map((of) => {
                let comp = { ...of };
                // debugger;
                if (
                    comp._id == "UOM" &&
                    // masterType != null &&
                    items[i][comp._id] != null &&
                    items[i][comp._id] != ""
                ) {
                    comp.disabled = true;
                } else {
                    comp.disabled = false;
                }
                return getInputFieldForTableRow(
                    { ...(comp || {}), passToken: JSON.parse(localStorage.getItem("passToken")) },
                    items,
                    i,
                    handleGenericChange,
                    autoFillOnFocus,
                    errors,
                    false
                );
            });

        return [...base, ...others];
    };

    const header = getTableHeader();
    const rows = items?.map((item, i) => {
        return getItemRow(i);
    });

    // const layout = getFieldsLayoutForEntry(otherFields, register, control, autoFillOnFocus, errors);

    const handleAddRow = () => {
        // let row = {
        //     itemId: "",
        //     itemDetail: null,
        //     quantity: 1,
        //     loader: false
        // }
        // setItems([...items, row])

        let fieldsData = {};
        let fields = allFields.filter((f) => f._id != "quantity");
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]?.type == "checkbox") {
                fieldsData[fields[i]._id] = [];
            } else if (fields[i]?.type == "file") {
                fieldsData[fields[i]._id] = null;
            } else {
                fieldsData[fields[i]._id] = "";
            }
        }
        let newRow = {
            quantity: remainingItems,
            itemDetail: null,
            loader: false,
            ...fieldsData,
        };
        setItems([...items, newRow]);
        reset();
    };

    const handleRemoveRow = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1);
        setItems(oldItems);
    };

    const handleEditRow = (i) => {
        let oldItems = [...items];
        oldItems[i] = {
            ...oldItems[i],
            itemDetail: null,
        };
        setItems(oldItems);
    };

    const handleCountChange = (direction, i) => {
        let oldItems = [...items];
        if (direction)
            if (direction == -1 && Number(oldItems[i].quantity) > 1) {
                oldItems[i].quantity = Number(oldItems[i].quantity) - 1;
                setItems(oldItems);
            } else if (direction == -1 && oldItems[i].quantity == 1) {
                handleRemoveRow(i);
            } else if (direction == 1) {
                if (remainingItems > 0) {
                    oldItems[i].quantity = Number(oldItems[i].quantity) + 1;
                    setItems(oldItems);
                }
            } else {
                let maxCap =
                    totalQuantity - (countTillNow - Number(oldItems[i].quantity));

                oldItems[i].quantity =
                    Number(direction.target.value) > maxCap
                        ? maxCap
                        : Number(direction.target.value);
                setItems(oldItems);
            }
    };

    const changeLoader = (i, status) => {
        let oldItems = [...items];
        oldItems[i] = {
            ...oldItems[i],
            loader: status,
        };
        setItems(oldItems);
    };

    const handleSearchButton = (i) => {
        let id = items[i].product_ID;
        let payload = {
            template_id: masterType ? template.connectedMaster.itemMaster : "",
        };
        if (masterType) {
            payload["SKU_ID"] = id;
        }

        changeLoader(i, true);
        dispatch(
            getShipmentConnectedMasterDetail(masterType, payload, (data) => {
                if (data) {
                    let oldItems = [...items];
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: data,
                        loader: false,
                        UOM: data.formData.UOM,
                    };
                    setItems(oldItems);
                } else {
                    let oldItems = [...items];
                    oldItems[i] = {
                        ...oldItems[i],
                        itemDetail: null,
                        loader: false,
                    };
                    setItems(oldItems);
                }
            })
        );
    };

    const handleOpenDetail = (i) => {
        setDialogData(items[i] || {});
    };

    const handleCloseDetail = () => {
        setDialogData({});
    };

    useEffect(() => {
        // dispatch(
        //     getAllUom({})
        // )
    }, []);

    return (
        <Grid
            container
            sx={{ mt: 2, width: "95%", marginLeft: "auto", marginRight: "auto" }}
        >
            <Grid item sm={12} xs={10}>
                <Card sx={{ borderRadius: "8px" }}>
                    <CardHeader
                        sx={{
                            p: 2,
                            borderRadius: "8px 8px 0px 0px",
                            backgroundColor: theme.themeGray,
                            color: "black",
                            boxShadow: "0px 1px 10px gray",
                        }}
                        title={
                            <Typography sx={{ ...textClasses.normalText }}>
                                3. Order Details
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Grid container>
                            <Table2
                                header={header}
                                rows={rows}
                                remainingItems={remainingItems}
                                handleAddRow={handleAddRow}
                                handleRemoveRow={handleRemoveRow}
                                handleEditRow={handleEditRow}
                            />
                        </Grid>
                        {dialogData && <DetailDialog rowDetail={dialogData} onClose={handleCloseDetail} titleKey="formData.SKU_ID"/>}
                        <Typography
                            sx={{
                                ...textClasses.boldText,
                                color: theme.themeOrange,
                                mt: 2,
                                ml: 2,
                            }}
                        >
                            Total Order Quantity: {countTillNow} pcs
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};


const Table2 = ({
    header,
    rows,
    remainingItems,
    handleAddRow,
    handleRemoveRow,
    handleEditRow,
}) => {
    return (
        <table
            style={{
                // display: "block",
                // maxWidth: "-moz-fit-content",
                // maxWidth: "fit-content",
                // margin: "0 auto",
                // overflowX: "auto",
                // whiteSpace: "nowrap",
                // borderRadius: "8px"
                width: "100%",
                textAlign: "center",
            }}
        >
            <thead style={{ backgroundColor: theme.themeGray }}>
                <tr
                    style={{
                        ...textClasses.boldText,
                        backgroundColor: theme.themeOrange,
                        color: "white",
                        padding: "10px",
                    }}
                >
                    {header.slice(0, 1).map((head, i) => {
                        return (
                            <td
                                style={{
                                    border: "1px solid white",
                                    padding: "10px",
                                    width: "150px",
                                }}
                                key={i}
                            >
                                {head}
                            </td>
                        );
                    })}
                    {header.slice(1, header.length).map((head, i) => {
                        return (
                            <td style={{ border: "1px solid white" }} key={i}>
                                {head}
                            </td>
                        );
                    })}

                    <td style={{ border: "1px solid white" }}>Action</td>
                </tr>
            </thead>
            <tbody>
                {rows?.length > 0 && (
                    <>
                        {rows.map((row, i) => (
                            <tr key={i} style={{ borderBottom: "1px solid #0000002B" }}>
                                {row.map((col, j) => {
                                    return (
                                        // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                        <td
                                            style={{ padding: "10px", border: "1px solid #D9D9D9" }}
                                        >
                                            {col}
                                        </td>
                                    );
                                })}
                                <td
                                    style={{
                                        padding: "10px",
                                        border: "1px solid #D9D9D9",
                                        width: "120px",
                                    }}
                                >
                                    <Grid container justifyContent={"center"} alignItems="center">
                                        <Button
                                            variant="outlined"
                                            className="RF_outline-btn"
                                            sx={{
                                                borderRadius: "8px",
                                                p: 0,
                                                height: "40px",
                                                minWidth: "40px",
                                                border: "1px solid #827878",
                                            }}
                                            onClick={() => handleEditRow(i)}
                                        >
                                            <Edit sx={{ color: "#827878" }} />
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className="RF_outline-btn"
                                            sx={{
                                                borderRadius: "8px",
                                                ml: 1,
                                                p: 0,
                                                height: "40px",
                                                minWidth: "40px",
                                                border: "1px solid #827878",
                                            }}
                                            onClick={() => handleRemoveRow(i)}
                                        >
                                            <Delete sx={{ color: "#827878" }} />
                                        </Button>
                                    </Grid>
                                </td>
                            </tr>
                        ))}
                    </>
                )}
                {
                    <tr>
                        <td
                            style={{
                                padding: "10px",
                                border: "1px solid #D9D9D9",
                                textAlign: "left",
                            }}
                        >
                            <Typography
                                sx={{
                                    ...textClasses.boldText,
                                    color: remainingItems > 0 ? theme.themeOrange : "#D7D7D7",
                                    textDecoration: "underline",
                                    cursor: remainingItems > 0 ? "pointer" : "edit",
                                }}
                                onClick={() => (remainingItems > 0 ? handleAddRow() : () => { })}
                            >
                                <Add fontSize="inherit" />
                                Add More Items
                            </Typography>
                        </td>
                        {header.map((_, i) => {
                            return (
                                <td
                                    style={{ padding: "10px", border: "1px solid #D9D9D9" }}
                                    key={i}
                                ></td>
                            );
                        })}
                    </tr>
                }
            </tbody>
        </table>
    );
};


export default OrderSection;