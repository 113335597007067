import React, { useEffect, useState } from "react"
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { Clear, Search, } from "@mui/icons-material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import GenericTable from "../../Components/CustomTable/GenericTable";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import { useDispatch } from "react-redux";
import { dateTimeFormater } from "../../../services/functions";
import { getAssociatedJobDetails } from "../../../redux/actions";
import { useDebounce } from "../../../utils/hooks";

const ViewAssociatedJob = ({ viewModal, setViewModal, AllUserData }) => {
    const dispatch = useDispatch();
    const [salesInputId, setSalesInputId] = useState(viewModal.action)
    const [loader, setLoader] = useState(false);
    const [detail, setDetail] = useState({ data: [], count: 0 })
    const [filter, setFilter] = useState({ page: 0, limit: 10 })
    const getAssociatedTableData = (pageNo, pageLimit) => {
        setLoader(true)
        dispatch(getAssociatedJobDetails({ id: salesInputId, forKey: "salesOrder", page: pageNo, limit: pageLimit }, (data) => {
            setLoader(false)
            setDetail({ ...detail, data: data?.data, count: data?.count })
        }))
    }
    const salesInputDebounceFilter = useDebounce(salesInputId, 500)
    const handleInputChange = (e) => {
        setSalesInputId(e.target.value)
        setFilter({ ...filter, page: 0 })
    }
    useEffect(() => {
        getAssociatedTableData(filter.page + 1, filter.limit)
    }, [filter.page, filter.limit, salesInputDebounceFilter])
    const renderTableCells = (columnData) => {
        if (Array.isArray(columnData)) {
            return (
                <Typography sx={{ ...textClasses.normalText }}>
                    {Array.isArray(columnData) ? columnData.join(", ") : columnData}
                </Typography>
            )
        } else {
            return (
                <Typography sx={{ ...textClasses.normalText }}>
                    {columnData}
                </Typography>
            );
        }
    };


    const associatedJobTableHeaders = ["Job ID", , "Job Name", "Current Status", "Assigned To", "Created At", "Completed At"]
    const associatedJobTableRows = detail?.data?.map((row, i) => {
        let item = [];
        let user = AllUserData?.data?.find(dl => dl?._id == row?.assignedTo?._id)
        let assignedTo = [];
        if (user?.name) {
            assignedTo.push(user.name)
        }
        if (user?.designation) {
            assignedTo.push(user.designation)
        }
        const statusColor = {
            ongoing: "#FF7200",
            pending: "#FCCB3E",
            failed: "#E9001A",
            completed: "green",
        };
        item.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
                {row?.groupBy}
            </Typography>
        )
        item.push(renderTableCells(row?.jobName))
        item.push(
            <Typography
                variant="caption"
                gutterBottom
                sx={{
                    pr: 1, pl: 1,
                    color: statusColor[row?.status],
                    fontWeight: "500",
                    border: `1px solid ${statusColor[row?.status]
                        }`,
                    borderRadius: "12px",
                    fontWeight: "500",
                }}
            >
                {row?.status.charAt(0).toUpperCase() + row?.status.slice(1)}
            </Typography>)
        item.push(
            <Typography
                sx={{
                    ...textClasses.normalText,
                    textAlign: "center",
                    textTransform: "none",
                }}
            >
                {assignedTo.join(", ")}
            </Typography>
        )
        item.push(dateTimeFormater(row?.createdAt))
        item.push(dateTimeFormater(row?.jobEndDate))
        return item;
    })
    return (
        <>

            <AlertDialog
                viewDialog={viewModal.type === "viewJobDetail"}
                handleClose={() => setViewModal({ ...viewModal, type: '' })}
                viewSearchInput={true}
                showDivider={true}
                width="70%"
                // searchTitle="Sales Order ID:"
                title="Sales Order Associated Job(s) Detail"
                searchInputDisplay={
                    <FormControl variant="outlined" size="small" fullWidth sx={{ width: 190, }}>
                        {/* <Typography sx={{ display: 'inline-block', marginRight: 1, ...textClasses.t15nBold }} >
                  Sales Order ID
                </Typography> */}
                        <OutlinedInput
                            data-testid="search-input"
                            fullWidth
                            value={salesInputId}
                            onChange={handleInputChange}
                            sx={{
                                ...inputClasses.filterField,
                                "&:focus": {
                                    borderColor: theme.themeOrange,
                                },
                                "&:before": {
                                    borderColor: theme.themeOrange,
                                },
                                "&:after": {
                                    borderColor: theme.themeOrange,
                                },
                                "& input::placeholder": { fontSize: "14px" },
                            }}
                            placeholder="Search By Order ID"
                            endAdornment={
                                salesInputId == ""
                                    ? (
                                        <InputAdornment position="end">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    ) : (
                                        <IconButton
                                            sx={{
                                                visibility: salesInputId != "" ? "visible" : "hidden",
                                                fontSize: "14px",
                                            }}
                                            onClick={() => {
                                                setSalesInputId("")
                                            }}
                                        >
                                            <Clear fontSize="inherit" />
                                        </IconButton>
                                    )
                            }
                        />
                    </FormControl>
                }
                // bodyCSS={{height:'500px'}}
                body={
                    <>
                        {loader ?
                            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                <Grid item><GenericLoader /></Grid>
                            </Grid> :
                            <Box sx={{ width: "inherit" }}>
                                <GenericTable
                                    data-testid="associatedJob-table"
                                    header={associatedJobTableHeaders}
                                    rows={associatedJobTableRows}
                                    pageCount={detail?.count}
                                    pageNumber={filter.page}
                                    handleChangePage={(event, pagevalue) => {
                                        setFilter({ ...filter, page: pagevalue })
                                    }}
                                    handleChangeRowsPerPage={(event) => {
                                        setFilter({ ...filter, limit: +event.target.value, page: 0 })
                                    }}
                                    rowsPerPage={filter.limit}
                                />
                            </Box>
                        }
                    </>
                }

            >
                <Button
                    variant="outlined"
                    data-testid="cancel-btn"
                    onClick={() => { setViewModal({ ...viewModal, type: '' }) }}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty, m: 1, fontSize: ".7rem", minWidth: "130px" }}
                >Cancel
                </Button>
            </AlertDialog>
        </>
    )
}
export default ViewAssociatedJob;