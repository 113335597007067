import React, { useEffect, useState } from "react";
import {
    Grid,
    Button,
    TextField,
    Typography,
    Breadcrumbs,
    Autocomplete,
} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../../static/styles/theme";
import { Link,useLocation } from 'react-router-dom';
import { showToast, validateFieldsMultiple, getCamelCaseText } from "../../../../../services/functions";
import { addWarehouse, getSuperAdminCompanyData, getTemplate,getCompanyDetails ,updateCompany} from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../../services/history";
import MultipleFormSave from "../../MultipleFormSave";
import { selectStylesOverride } from "../../../../../utils/util";
import GenericLoader from "../../../../Components/Generic/GenericLoader";

const AddWarehouse = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editableWarehouseId = queryParams.get("id")
    const dispatch = useDispatch();
    const [loader,setLoader] = useState("")
    const [items, setItems] = useState([{}]);
    const [errors, setErrors] = useState([])
    const [dataReceieved, isDataReceived] = useState(false)
    const [templateDetail, setTemplateDetail] = useState([])
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState({})
    const [companiesList, setCompaniesList] = useState([])
    let isEditable = editableWarehouseId ?true:false;
    const handleSubmitData = () => {
        const tempError = validateFieldsMultiple(templateDetail?.attributes, items)
        setErrors(tempError);
        if (tempError?.length > 0) {
            let errorDetail = Object.values(tempError[0] || {})?.at(0) || {}
            showToast(errorDetail.message, true);
            return;
        }
        isDataReceived(true)
        let payload = {
            companyId: selectedCompanyDetail?._id,
            warehouseData: items
        };
        if(isEditable){
            dispatch(updateCompany({ updatedData: items[0], type: "warehouse", componentId: editableWarehouseId }, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/warehouseFloor")
                }
            }))
        }else{
            dispatch(addWarehouse(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/warehouseFloor")
                }
            }))

        }
    }

    const handleClearAll = (attributes = [], data = {}) => {
        let resetD = items?.map(dl => {
            let tempObj = {}
            for (let ele of attributes) {
                if(data[ele?._id]){
                    tempObj[ele?._id] = data[ele?._id]
                }
                else{
                    tempObj[ele?._id] = ele?.type == "checkbox" ? [] : ""
                }
            }
            return tempObj;
        })
        setItems(resetD)
    }
  
    useEffect(() => {
        setLoader("getDynamicTemplate")
        dispatch(getTemplate({ type: "warehouse" }, (data) => {
            setTemplateDetail(data)
            setLoader("")
            if (isEditable) {
                dispatch(getCompanyDetails({ _id: editableWarehouseId }, (detailData) => {
                    handleClearAll(data?.attributes || [], detailData?.data?.formData)
                    setSelectedCompanyDetail({_id:detailData?.data?.companyId,companyName:detailData?.data?.companyName})
                    setCompaniesList([{_id:detailData?.data?.companyId,companyName:detailData?.data?.companyName}])
                }))
            }
        }))
        if(!isEditable){
            dispatch(getSuperAdminCompanyData({ projections: { _id: 1, companyName: 1, type: 1 }, type: 'company' }, (data) => {
                setCompaniesList(data)
            }))
        }
    }, [])

    return (<>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item sm={6}>
                <Typography sx={{ ...textClasses.cardTitle }}>Manage Warehouse</Typography>
                <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-warehouse">
                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                        <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/warehouseFloor">
                            Manage Warehouse
                        </Link>
                    </Typography>

                    <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>{isEditable ? "Edit Warehouse" : "Create New Warehouse"}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item sm={3}>
                <Autocomplete
                    data-testid="select-Company-dropdown"
                    value={selectedCompanyDetail}
                    onChange={(e, option) => {
                        setSelectedCompanyDetail(option)
                    }}
                    getOptionLabel={(option) => getCamelCaseText(option?.companyName)}
                    size="small"
                    options={companiesList?.data || {}}
                    sx={{
                        ...inputClasses.textField,
                        ...selectStylesOverride,
                        minWidth: "280px",
                        m: 0,
                        ml: 2,
                        ".MuiInputBase-input": {
                            fontSize: "14px",
                            color: "#455A64",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            sx={{ color: "#455A64", ...textClasses.normalText }}
                            {...params}
                            label="Select Company*"
                        />
                    )}
                    disabled={isEditable}
                />
            </Grid>
        </Grid>
       { loader == "getDynamicTemplate" ?
          <Grid
            data-testid="loader"
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid> :
        <MultipleFormSave
            formAttributes={templateDetail?.attributes}
            type={"warehouse"}
            items={items}
            setItems={setItems}
            errors={errors}
            isEditable={isEditable}
            />
       }
        <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}  >
            <Button
                variant='outlined'
                size='small'
                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                onClick={() => handleClearAll(templateDetail?.attributes || [], {})}
            >
                Clear All
            </Button>
            <Button
                variant='contained'
                size='small'
                disabled={dataReceieved}
                sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                onClick={handleSubmitData}
            >
               {isEditable ? "Update" : "Submit"}
            </Button>
        </Grid>
    </>)
}

export default AddWarehouse;