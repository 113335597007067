import React from "react";
import ReactDOM from "react-dom";
import App from "./components";
import "./static/css/global.css";
import "./static/css/table.css";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



// import { Provider } from 'react-redux';
// import createStore from './state/store';

// const mode = localStorage.getItem('theme-mode') || 'light';
// const store = createStore({ theme: { mode } });

ReactDOM.render(
	<React.StrictMode>
		<App />
		<ToastContainer
			position="bottom-center"
			autoClose={false}
			closeOnClick={false}
		/>
	</React.StrictMode>,
	document.getElementById("root")
);
// ReactDOM.render(
//   <Provider store={store}>
//       <App />
//   </Provider>,
//   document.getElementById('root'),
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
