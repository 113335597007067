import axios from "../../services/Api";
import { showToast } from "../../services/functions";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  CHECK_DISTINCT_MASTER_EXIST,
  GET_MAPPED_MASTER_IDS,
  FLUSH_MAPPED_IDS,
  GET_AREA_MAPPING_DATA,
} from "../types";


export const getMappedArea = (obj,callBackFunc, details) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_START });

    await axios.post('/areaMapping/getAreaMapping', obj
    ).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        if(details){
          dispatch({type: "GET_MAPPED_AREA_FOR_VIEW", payload: data})
        }else{
          dispatch({ type: "GET_MAPPED_AREA", payload: data });
        }
        // dispatch({type: SHOW_MESSAGE, payload: data.message});
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: "GET_MAPPED_AREA", payload: {} });
      }
      if(callBackFunc){
        callBackFunc({data:data?.data || []})
      }
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      //("Error****:", error.message);
      if(callBackFunc){
        callBackFunc({data:[]})
      }
    });
  }
};

export const addMappedArea = (obj,callBack) => {
  
  return async (dispatch) => {
   
    dispatch({type: FETCH_START});
    await axios.post('/areaMapping/addAreaMapping', obj
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS,payload: data.success});
        dispatch({type: SHOW_MESSAGE, payload: data.message});
      } 
      else {
        dispatch({type: FETCH_ERROR, payload: data.message});
      }
      if(callBack){
        callBack({success:data.success,message:data.message})
      }
    }).catch(function (error) {
      callBack({success:false,message:error.message})
      dispatch({type: FETCH_ERROR, payload: error.message});
      //("Error****:", error.message);
    });
  }
};

export const checkDistinctMasterExist = (obj,callBack) => {
  
  return async (dispatch) => {
   
    dispatch({type: FETCH_START});
    await axios.post('/inventory/checkDistinctMasterExist', obj
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS,payload: data.success});
        dispatch({ type: "CHECK_DISTINCT_MASTER_EXIST", payload: data.data });
      } 
      else {
        dispatch({type: FETCH_ERROR, payload: data.message});
        dispatch({ type: "CHECK_DISTINCT_MASTER_EXIST", payload: {} });
      }
      if(callBack){
        callBack({success:data.success,message:data.message,data:data.data || {}})
      }
    }).catch(function (error) {
      callBack({success:false,message:error.message})
      dispatch({type: FETCH_ERROR, payload: error.message});
      //("Error****:", error.message);
    });
  }
};

export const getMappedMasterId = (obj,callBack) => {
  
  return async (dispatch) => {
   
    dispatch({type: FETCH_START});
    await axios.post('/areaMapping/getMappedMasterId', obj
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS,payload: data.success});
        dispatch({ type: "GET_MAPPED_MASTER_IDS", payload: data.data });
      } 
      else {
        dispatch({type: FETCH_ERROR, payload: data.message});
        dispatch({ type: "GET_MAPPED_MASTER_IDS", payload: [] });
      }
      if(callBack){
        callBack({success:data.success,message:data.message,data:data.data || []})
      }
    }).catch(function (error) {
      callBack({success:false,message:error.message})
      dispatch({type: FETCH_ERROR, payload: error.message});
      //("Error****:", error.message);
    });
  }
};

export const getAreaMappingDetail = (obj,callBack) => {
 
  return async (dispatch) => {
   
    dispatch({type: FETCH_START});
    await axios.post('/areaMapping/getAreaMappingDetail', obj
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS,payload: data.success});
        dispatch({ type: GET_AREA_MAPPING_DATA, payload: data.data });
      } 
      else{
        dispatch({type: FETCH_SUCCESS,payload: false});
        dispatch({ type: GET_AREA_MAPPING_DATA, payload: [] });
      }
      if(callBack){
        callBack({success:data.success,message:data.message,data:data?.data || []})
      }
    }).catch((error)=> {
      if(callBack){
        callBack({success:false,message:error.message})
      }
      dispatch({type: FETCH_ERROR, payload: error.message});
      //("Error****:", error.message);
    });
  }
};

export const UpdateBulkUploadData = (payload, callback) =>{
  return (dispatch) =>
  {
      dispatch({type: FETCH_START});
      axios.post('/areaMapping/updateMasterId', payload).then(({data}) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS,message:data.message });
          } 
          else {
              dispatch({type: FETCH_ERROR, payload: data.message});
          }

          if(callback){
            callback({success:data.success  || false,message:data.message,invalidIds:data?.invalidIds || []});
          }        
      }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          // console.log("Error****:", error.message);
          if(callback){
            callback({success:false,message:error.message,invalidIds:[]})
          }
      });
  }
};

export const flushMappedIds = () => {
  return (dispatch) => {
    dispatch({ type: FLUSH_MAPPED_IDS, payload: "" });
  };
};