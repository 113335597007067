import { INIT_CANVAS, ADD_SECTION, REMOVE_SECTION, ADD_FIELD, REMOVE_FIELD, EDIT_FIELD, SET_EDIT_FIELD, REMOVE_EDIT_FIELD, SELECT_TEMPLATE, REPLACE_EDIT_CANVAS, SELECT_SUB_TEMPLATE, FETCH_SUPPLIER_MASTER_TEMPLATES, FETCH_CUSTOMER_MASTER_TEMPLATES, FETCH_ITEM_MASTER_TEMPLATES, FETCH_SUCCESS, FETCH_ERROR, FETCH_START, SELECT_SUPPLIER_MASTER, SELECT_CUSTOMER_MASTER, SELECT_ITEM_MASTER, IS_ADDRESS_BOOK_CONNECTED, SET_ADDRESS_BOOK_FIELD } from "../types";
import axios from "../../services/Api";

export const selectTemplate = (templateType, subTemplateType, canvas) => {
    return {
        type: SELECT_TEMPLATE,
        payload: { templateType, subTemplateType, canvas }
    }
    // return (dispatch) =>
    // {
    //     dispatch({type: FETCH_START});
    //     axios.post('base/getUOMList', {}
    //     ).then(({data}) => {
    //         if (data.success) {
    //             dispatch({ type: FETCH_SUCCESS });
    //             dispatch({ type: SELECT_TEMPLATE, payload: { templateType, subTemplateType, uomList: data.data }})
    //         } 
    //         else {
    //             dispatch({type: FETCH_ERROR, payload: data.message});
    //             dispatch({ type: SELECT_TEMPLATE, payload: { templateType, subTemplateType, uomList: [] }})
    //         }
    //     }).catch(function (error) {
    //         dispatch({type: FETCH_ERROR, payload: error.message});
    //         //("Error****:", error.message);
    //     });
    // }
}

export const selectSubTemplate = (subTemplateType) => {
    return {
        type: SELECT_SUB_TEMPLATE,
        payload: subTemplateType
    }
}

export const initCanvas = (canvas) => {
    return {
        type: INIT_CANVAS,
        payload: canvas
    }
}

// export const initCanvas = () =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('base/getUOMList', {}
//         ).then(({data}) => {
//             if (data.success) {
//                 dispatch({ type: FETCH_SUCCESS });
//                 dispatch({ type: INIT_CANVAS, payload: data.data})
//             } 
//             else {
//                 dispatch({type: FETCH_ERROR, payload: data.message});
//                 dispatch({ type: INIT_CANVAS, payload: []})
//             }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             //("Error****:", error.message);
//         });
//     }
// };


export const replaceEditCanvas = (templateType, templateCategory, newComponents) => {
    return {
        type: REPLACE_EDIT_CANVAS,
        payload: { templateType, templateCategory, newComponents }
    }
}


export const addSection = (sectionId) => {
    return {
        type: ADD_SECTION,
        payload: { sectionId }
    }
}

export const removeSection = (sectionId) => {
    return {
        type: REMOVE_SECTION,
        payload: { sectionId }
    }
}

export const addField = (sectionId, fields) => {
    return {
        type: ADD_FIELD,
        payload: { sectionId, fields }
    }
}

export const removeField = (sectionId, index, type) => {
    return {
        type: REMOVE_FIELD,
        payload: { sectionId, index, type }
    }
}

export const setEditField = (sectionId, type, index) => {
    return {
        type: SET_EDIT_FIELD,
        payload: { sectionId, type, index }
    }
}

export const setAddressBookField = (sectionId, fields) =>{
    return{
        type: SET_ADDRESS_BOOK_FIELD,
        payload: {sectionId, fields}
    }
}


export const removeEditField = () => {
    return {
        type: REMOVE_EDIT_FIELD
    }
}

export const saveField = (sectionId, type, index, field) => {
    return {
        type: EDIT_FIELD,
        payload: { sectionId, type, index, field }
    }
}

export const selectMasterTemplate = (templateType, templateId) => {
    const actionType = {
        "itemMaster": SELECT_ITEM_MASTER,
    }
    return {
        type: actionType[templateType],
        payload: templateId
    }
}

export const getEntityMasterTemplates = (body, cb) =>
{
    const actionType = {
        "itemMaster": FETCH_ITEM_MASTER_TEMPLATES,
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('dynamicForm/getTemplateList',body).then(({data}) => {
        if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: actionType[body.templateType], payload: data.data})
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: actionType[body.templateType], payload: []})
        }

        if(cb){
            cb(data?.data || []);
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};