import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
  } from "../types";
import axios from "../../services/Api";

export const getUserPermissions = (bodydata, callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/role/getUserPermission', bodydata
        ).then(({data}) => {
          if (data.success) 
           {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            // console.log("GET_USER_PERMISSIONS",data)
            data.sidebarList = [
              ...data.sidebarList,
            //   {
            //     "title" : "Sales Order ",
            //     "icon" : "AddShoppingCartOutlinedIcon",
            //     "activeIcon" : "AddShoppingCartOutlinedIcon",
            //     "fontSize" : "small",
            //     "url" : "/dashboard/salesOrder",
            //     // "permissionId" : [
            //     //     "downloadExcelReport",
            //     //     "viewReport"
            //     // ],
            //     "subItems" : [
            
            //     ]
            // }
            ]

            dispatch({ type: "GET_USER_PERMISSIONS", payload: data });
            // dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
            
          }
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_USER_PERMISSIONS", payload: {} });
            
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message,data:data })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};
////here