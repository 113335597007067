import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
  } from "../types";
import axios from "../../services/Api";

export const getAllUserRoles = (bodydata, callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/role/getRoles', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({ type: "GET_ALL_USERS_ROLE", payload: data.data ||[] });
            // dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
            
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_ALL_USERS_ROLE", payload: [] });
            
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};

export const addUserRoles = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        await axios.post('/role/addRole', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_ALL_USERS_DATA", payload: {} });
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};

export const editUserRoles = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/role/editRole', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          }   
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
          if(callBackFunction){
            callBackFunction({message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};


export const createSuperAdminRole = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        await axios.post('role/createRoleSuperAdmin', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_ALL_USERS_DATA", payload: {} });
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};


export const editSuperAdminRole = (bodydata,callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/role/updateRoleSuperAdmin', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
          }   
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
          }
          if(callBackFunction){
            callBackFunction({message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};