import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	FormControl,Grid,InputAdornment,OutlinedInput,
	Button,MenuItem,InputLabel,Typography,Tab,
    Tabs,Box,Checkbox,Breadcrumbs,
    TextField,Autocomplete,Tooltip,Select
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import colors, { buttonClasses, textClasses } from '../../../../static/styles/theme';
import { selectStylesOverride } from "../../../../utils/util";
import { Link, useHistory } from "react-router-dom";
import JobTypeModel from "./CRUDModel";
import {
    Add,
	Search,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    WorkOutlineOutlined as WorkOutlineOutlinedIcon
} from "@mui/icons-material";
import moment from "moment";

import {
	getAllJobTypes,getAllUsers,getAllJobActions,addJobTypes, updateJobTypes,deleteJobTypes,
    getJobTypeStatusCount
} from "../../../../redux/actions";
import theme from "../../../../static/styles/theme";
import JobTypeTable from "./JobTypeTable";
// import { parseCamelCase } from "../../../../utils/util";
import { useDebounce } from "../../../../utils/hooks";
import { showToast } from "../../../../services/functions";
import GenericTable from "../../../Components/CustomTable/GenericTable"
import { parseCamelCase, } from "../../../../utils/util";
import { dateTimeFormater,capitalizeSentence,getCamelCaseText, getShortText } from "../../../../services/functions";
import GenericLoader from "../../../Components/Generic/GenericLoader";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
const statusColor = {
	ongoing: "#FF7200",
	pending: "#FCCB3E",
	failed: "#E9001A",
	completed: "green",
};



const initFilters = {
    initail:true,
	page: 1,
	limit: 10,
	jobName: "",
	jobTypeId: "",
	actionType: "",
	createdBy: "",
	createdOn: null
};

let initialJob_state={"name": "","actionTypes": [], "disabled": true,"categoryKey":""}

let initialButton_state={"action":"","activeRow":"","selectedRow":[],"deleteData":[]}

export default function JobTypeModule() {
	const dispatch = useDispatch();
	const history = useHistory();
	const {job_types,allJobActions,job_types_count={}} = useSelector((state) => state.job);
    const {AllUserData} = useSelector((state) => state.users);
    const { userPermissions,sidebarList=[] } = useSelector((state) => state.userPermission);

	const [tabIndex, setTabIndex] = useState(0);
    const [buttonActionOn, setButtonActionOn] = useState(initialButton_state);
	const [condition, setCondition] = useState(true);
	const [loader, setLoader] = useState(true);
	const [jobTypes, setJobTypes] = useState({});
	const [filter, setFilter] = useState({...initFilters});
    const [initialState, setInitialState] = useState(initialJob_state);
    // const [anchorEl, setAnchorEl] =useState(null);
    // let open = Boolean(anchorEl);


	const debouncedJobName = useDebounce(filter.jobName, 500);

	const tableHeader = ()=>{
        let head=[
            < Typography sx={{ ...textClasses.normalText,  color: theme.themeOrange }}>
                <Checkbox
                    data-testid="checkbox"
                    checked={buttonActionOn?.selectedRow?.length==0
                        ?false
                        :jobTypes?.data.filter(dl=>buttonActionOn.selectedRow.includes(dl._id)).length==jobTypes?.data.length || false
                    }
                    onChange={(event) => controlRowCheckAction(event.target.checked,"all")}
                    size='small' />
            </Typography >,
            < Typography sx={{ ...textClasses.normalText,
            margin:"10px"
            }}>
                S.No
            </Typography >,
            "Job Type",
            "Action Types",
            "Created By",
            "Created On",
            "Action",
        ]
        if (!jobTypes.data || jobTypes.data.length == 0 ||
            (!userPermissions?.permissions?.find(dl => dl.permissionId == "deleteJobType") && 
                !userPermissions?.permissions?.find(dl => dl.permissionId == "editJobType"))) {
            head.shift()
            head.pop()
        }

        return head
    };

    const renderActionTypes=(data=[])=>{
        let arrType=[]
        for(let ele of data){
            if(typeof ele=="string"){
                arrType.push(ele) 
                continue
            }
            if(ele.actionName){
                arrType.push(ele.actionName)  
            }
        }
        return arrType
    }

	
    const reducer = (state, action) => {
        switch (action.type) {
            case 'set_initial_state': {
                // ////("action.valueObj",action.valueObj);
                let data = {
                    "name": "",
                    "actionTypes": [],
                    "disabled": true,
                    "categoryKey":""
                }
                if (action.valueObj) {
                    let {name,actionTypes=[],_id,categoryKey=""}=action.valueObj
                    data = {disabled:true,name:name || "",actionTypes,categoryKey}
                    if(_id) data["jobTypeId"]=_id
                }
                return data;
            }
            case 'change_action': {
                // ////("action",action);
                return {
                    ...state,
                    "actionTypes":action.value,
                    "categoryKey":action?.value?.length>0 && state.categoryKey || "",
                    "disabled": false
                };
            }
            case 'changed_name': {
                // ////("action",action);
                return {
                    ...state,
                    "name": action.value?.trimStart() || "",
                    "disabled": false
                };
            }
            case 'select_by_category': {
                // ////("action",action);
                
                let {actionTypes=[]}=state,{categoryKey="",checked=false}=action

                let select_action=actionTypes.filter(el=>!el.categoryKey || el.categoryKey!=categoryKey)
                // ////("select_action",select_action);
                if(checked){
                    let category_action=allJobActions.find(dl=>dl?.categoryKey && dl.categoryKey==categoryKey)
                    // ////("category_action",category_action);
                    if(category_action && category_action?.actions?.length>0){
                        select_action.push(...category_action.actions)
                    }
                }
                return {
                    ...state,
                    "actionTypes":select_action,
                    "disabled": false,
                    "categoryKey":categoryKey
                };
            }
            default:
                return state;
        }
    }
    const actionTypeOptions= [
		// { actionName: "---Select---", value: "" },
		...allJobActions.reduce((prev,_) => {
            if(_?.actions?.length){
                let temp=[...prev]
                temp.push(..._.actions)
                return temp
            }
            return prev
		},[]),
	];
    const userOptions= [
		// { label: "---Select---", value: "" },
		...(AllUserData?.data || []).map((_) => {
			return {
				label: capitalizeSentence(_.name || ""),
				value: _._id,
				userType: _.userType,
			};
		}),
	];

    const controlTableAction=(type="Add",viaActionButton=false,index)=>{
        let tempInitialState={...initialJob_state},temp_btnState={...buttonActionOn,action:type,activeRow:index}
        // ////("index,",{index});
        if(["Edit","Delete"].includes(type)){
           if(type=="Edit"){
                let temp_detail={}
                if(viaActionButton){
                        temp_detail= jobTypes?.data && jobTypes.data[temp_btnState.activeRow] || {}
                }
                else{
                    temp_detail= jobTypes?.data && jobTypes.data.find(dl=>dl._id==temp_btnState?.selectedRow[0]) || {}
                }
                tempInitialState["name"]=temp_detail?.name ||""
                tempInitialState["actionTypes"]=temp_detail?.actionTypes || []
                tempInitialState["_id"]=temp_detail?._id || ""
                temp_btnState["deleteData"]=[]
           }
           else{
            //delete 
                if(viaActionButton){
                    temp_btnState["deleteData"]=jobTypes?.data && [jobTypes.data[temp_btnState.activeRow]] || []
                }
                else{
                    temp_btnState["deleteData"]=jobTypes?.data && jobTypes.data.filter(dl=>(temp_btnState?.selectedRow || []).includes(dl._id || ""))
                }

                tempInitialState["name"]=""
                tempInitialState["actionTypes"]=[]
           }

            // temp_btnState["activeRow"]=""
            // setAnchorEl(null)
            // open = false
        }

        // ////("controlTableAction>>>>",{type,tempInitialState,initialButton_state});
        setInitialState(tempInitialState)
        setButtonActionOn(temp_btnState)
    }

    const controlRowCheckAction=(checked=false,jobTypeId)=>{
        let temp_select={...buttonActionOn,"action":"","activeRow":"","deleteData":[]},index=-1

        if(jobTypeId!="all"){
            if(jobTypeId){
                checkRowFunction(temp_select,jobTypeId,checked)
            }
        }
        else{
            for(let ele of jobTypes?.data){
                if(ele?._id){
                    checkRowFunction(temp_select,ele?._id,checked)
                }
            }
        }
        // ////("temp_select>>",temp_select);
        setButtonActionOn(temp_select)
    }

    const checkRowFunction=(temp_select,jobTypeId,checked)=>{
        let index=-1
        index=temp_select?.selectedRow?.indexOf(jobTypeId)
        if(checked){
            if(index<=-1){
                temp_select["selectedRow"]=[...(temp_select.selectedRow || []),jobTypeId]
                
            }
        }
        else{
            if(index>-1) temp_select?.selectedRow?.splice(index,1) 
        }
    }

    // const onMenuOpenHandler=(event,row)=>{
    //     setAnchorEl(event.currentTarget)
    //     setButtonActionOn({...buttonActionOn,"activeRow":row})
    //     setInitialState(initialJob_state)
    // }
    // const onMenuCloseHandler=()=>{
    //     setAnchorEl(null)
    //     setButtonActionOn({...buttonActionOn,"activeRow":""})
    //     setInitialState(initialJob_state)
    // }

    const get_Job_Type=(filer_d,fetchCount=false)=>{
        setLoader(true)
        dispatch(getAllJobTypes(filer_d,()=>{
            setLoader(false)
            if(fetchCount) dispatch(getJobTypeStatusCount({}))
            if(filer_d.makeInitial){
                setFilter({...filer_d,initail:false})
            }
        }));
    }

    const submitHandler=({result,clickType="submit",open_type="Add"})=>{
        // ////("submit>>>",{result,clickType,open_type});
       
        if(open_type=="Add"){
            dispatch(addJobTypes(result,({success,message})=>{
                updateState(success,message,clickType)
            }))
        }
        else if(open_type=="Edit"){
            //edit
            dispatch(updateJobTypes({...result},({success,message})=>{
                updateState(success,message,clickType)
            }))
        }
        else{
            //delete
            dispatch(deleteJobTypes({...result},({success,message})=>{
                updateState(success,message,clickType)
            }))
        }

    }

    const updateState=(success_o,message_o,clickType)=>{
        showToast(message_o, !success_o)
        if (success_o) {
            setFilter(initFilters)
            if (clickType == "submit") {
                setButtonActionOn(initialButton_state)
            }
            setInitialState(initialJob_state)
            get_Job_Type(initFilters,true)
            // setAnchorEl(null)
            // open = false
        }
    }

    const tableRows =jobTypes?.data && jobTypes.data.map((type, index) => {
		let row = [];
		// ////("type",type);
        let user=userOptions.find(dl=>type.added_by._id && dl.value==type.added_by._id)
        if(userPermissions?.permissions?.find(dl => dl.permissionId == "deleteJobType") || 
        userPermissions?.permissions?.find(dl => dl.permissionId == "editJobType")){
            row.push(
                < Typography sx={{ ...textClasses.normalText,  color: theme.themeOrange }}>
                   {/* {(filter.limit * (filter.page-1)) + (index+1)} */}
                    <Checkbox
                        checked={type?._id && buttonActionOn?.selectedRow?.includes(type._id) || false}
                        onChange={(event) =>controlRowCheckAction(event.target.checked,type?._id || "")}
                        size='small' />
                </Typography >,
            )
        }
		row.push(
                <Typography
                variant="caption"
                display="block"
                gutterBottom
                
            >
                {((filter.page-1)*(filter.limit))+index+1}
            </Typography>,
            capitalizeSentence(type?.name|| "-"),
            <Tooltip title={renderActionTypes(type?.actionTypes || []).join(", ")} placement="top">
            <Typography sx={{ ...textClasses.t13n}}>
                {
                    
                    getShortText(renderActionTypes(type?.actionTypes || []).join(", "), 38)
                }
            </Typography>
        </Tooltip>,
			type?.added_by?._id
            ?capitalizeSentence(user?.label|| "-") +", "+capitalizeSentence(user?.userType|| "-")
            :"-",
			type.createdAt &&  dateTimeFormater(type.createdAt) || "-",
		);
        if(userPermissions?.permissions?.find(dl => dl.permissionId == "deleteJobType") || 
        userPermissions?.permissions?.find(dl => dl.permissionId == "editJobType")){
            row.push(
                < Typography key={index} data-testid="dropdown" sx={{ ...textClasses.normalText,  }} >
                                <div>
                                    <Select value='actions' className='customSelectt' sx={{ fontSize: '13px ! important' }} size='small' 
                                    disabled={buttonActionOn?.selectedRow?.length >1 ? true : false}
                                    // onClick={(event) => onMenuOpenHandler(event,index)}
                                    >
                                        <MenuItem value='actions' disabled sx={{ color: "#455A64", ...textClasses.normalText, display: 'none' }}  >Action</MenuItem>
                                        <MenuItem data-testid='delete' onClick={() =>  controlTableAction("Edit",true,index)} sx={{ color: "#455A64", ...textClasses.normalText }}>Edit </MenuItem>
                                        <MenuItem data-testid='edit' onClick={() => controlTableAction("Delete",true,index)} sx={{ color: "#455A64", ...textClasses.normalText }}>Delete </MenuItem>
                                    </Select>
                                </div>
                            </Typography >
                // < Typography sx={{ ...textClasses.normalText,  }} key={type?._id || index}>
                //     <div>
                //         <Button
                //             id="basic-button"
                //             aria-controls={open ? 'basic-menu' : undefined}
                //             aria-haspopup="true"
                //             aria-expanded={open ? 'true' : undefined}
                //             disabled={buttonActionOn?.selectedRow?.length>1}
                //             onClick={(event) => onMenuOpenHandler(event,index)}
                //             endIcon={open? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                //             sx={{ fontSize: "14px", color: "orange" }}
                //         >
                //             Action
                //         </Button>
                //     </div>
                // </Typography >
            );
        }
		return row;
	}) || [];


    const viewClearButton=()=>{
		for(let key in filter){
			if(["jobName","jobTypeId","createdOn","actionType","createdBy"].includes(key) && filter[key]){
				return true
			}
		}
		return false
	}

    useEffect(() => {
        if(!filter.initail){
            get_Job_Type(filter)
        }
    }, [filter.page, filter.limit, filter.actionType,filter.createdBy,filter.jobTypeId,filter.createdOn,]);

	useEffect(() => {
        if(!filter.initail){
            get_Job_Type(filter)
        }	
    }, [debouncedJobName]);


	useEffect(() => {
		if(condition){
            get_Job_Type(initFilters,true)
            dispatch(getAllJobActions({}));
            dispatch(getAllUsers({"isAdminIncluded":true}));
        }
        else{
           setJobTypes(job_types)
        }

        if(condition){
            setCondition(false)
        }
	}, [job_types]);


    return (
        <>
            <Grid container data-testid="component-jobtype">
                <Grid item sm={6} container direction={"column"} justifyContent={"start"} alignItems="start">
                    <Typography sx={{ ...textClasses.cardTitle }} data-testid="header-jobtype">
                        Manage Warehouse Jobs
                    </Typography>
                    <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-jobtype">
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                fontSize: "12px",
                                color: theme.themeOrange,
                            }}
                        >
                            <Link
                                style={{ color: theme.themeOrange,textDecoration:"none" }}
                                to="/dashboard/jobs"
                            >
                                Manage Warehouse Jobs
                            </Link>
                        </Typography>

                        <Typography
                            sx={{ ...textClasses.normalText, fontSize: "12px" }}
                        >
                            View Job Type (s)
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid
                    container
                    item
                    sm={6}
                    justifyContent="end"
                    alignItems="start"
                    gap={1}
                >
                   {userPermissions?.permissions?.find(dl=>dl.permissionId=="viewAllJobs") && <Button
                        variant="contained"
                        size="small"
                        data-testid="viewJobButton-jobtype"
                        startIcon={<WorkOutlineOutlinedIcon />}
                        sx={buttonClasses.lynkitOrangeEmpty}
                        onClick={() => {
                            history.push("/dashboard/jobs")
                        }}
                    >
                        View Job (s)
                    </Button>}
                    {userPermissions?.permissions?.find(dl=>dl.permissionId=="addJobType") &&<Button
                        variant="contained"
                        size='small'
                        data-testid="addButton-jobtype"
                        startIcon={<Add />}
                        sx={{
                            mr: 1,
                            ...buttonClasses.lynkitOrangeFill,
                            paddingTop: "5.5px",
                            paddingBottom: "5.5px",
                        }}
                        onClick={() => controlTableAction("Add")}
                    >
                        <Typography variant='caption'>Create Job Type</Typography>
                    </Button>}
                </Grid>
            </Grid>
            <Box
                sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}
            >
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    style={{ height: "20px" }}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: theme.themeOrange,
                        },
                        "& .MuiTab-root.Mui-selected": {
                            color: theme.themeOrange,
                        },
                    }}
                >
                    <Tab
                        data-testid="tabs-jobtype"
                        label={`All Job Type (${job_types_count?.data?.total || 0})`}
                        {...a11yProps(0)}
                        sx={{ ...textClasses.normalText, textTransform: "none" }}
                    />
                </Tabs>
            </Box>
            {job_types_count?.data?.total ?
                <Grid container spacing={2}>
                    <Grid item sx={{ flexBasis: "15%" }} data-testid="jobName-jobtype">
                        <FormControl variant="outlined" size="small" sx={{ ...selectStylesOverride }}>
                            <OutlinedInput
                                onChange={(e) =>
                                    setFilter((f) => ({
                                        ...f,
                                        jobName: e.target.value,
                                        initail: false,
                                        page: 1
                                    }))
                                }
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                value={filter.jobName || ""}
                                placeholder="Search by Job Type"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Search fontSize="small" />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item sx={{ flexBasis: "15%" }} data-testid="actionType-jobtype">
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                        >
                            <Autocomplete
                                size='small'
                                options={actionTypeOptions}
                                sx={{ height: "1.1rem", }}
                                getOptionLabel={(option) => option.actionName}
                                onChange={(event, option) => {
                                    setFilter((f) => ({
                                        ...f,
                                        actionType: option?.actionId || "",
                                        initail: false,
                                        page: 1
                                    }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            fontSize: "12px",
                                            ...selectStylesOverride,
                                        }}
                                        {...params}

                                        label={<Typography sx={{ fontSize: "12px" }}>Action Type</Typography>}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <MenuItem value={option?.actionId || ""} key={option?.actionId || ""}  {...props}>
                                        <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            {option?.actionName || ""}
                                        </Typography>
                                    </MenuItem>
                                )
                                }
                                value={{ actionName: filter.actionType && actionTypeOptions.find(dl => dl.actionId == filter.actionType)?.actionName || "", actionId: filter.actionType || "" }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexBasis: "15%" }} data-testid="addedBy-jobtype">
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                        >
                            <Autocomplete
                                size='small'
                                options={userOptions}
                                sx={{ height: "1.1rem", }}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, option) => {
                                    setFilter((f) => ({
                                        ...f,
                                        createdBy: option?.value || "",
                                        initail: false,
                                        page: 1
                                    }))
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{
                                            fontSize: "12px",
                                            ...selectStylesOverride,
                                        }}
                                        {...params}
                                        variant="outlined"
                                        label={<Typography sx={{ fontSize: "12px" }}>Created By</Typography>}
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <MenuItem value={option?.value || ""} key={option?.value || ""}  {...props}>
                                        <Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                            {option?.label || ""}
                                        </Typography>
                                    </MenuItem>
                                )}
                                value={{ label: userOptions.find(dl => dl.value == filter.createdBy)?.label || "", value: filter.createdBy || "" }}
                            />
                        </FormControl>

                    </Grid>

                    <Grid item sx={{ flexBasis: "15%" }} data-testid="addedOn-jobtype">
                        <FormControl variant="outlined" size="small" sx={{ ...selectStylesOverride }}>
                            <DatePicker
                                label="Created On"
                                name="createdOn"
                                // className='customDatePicker'
                                value={filter.createdOn}
                                onChange={(newValue) => {
                                    setFilter((f) => ({
                                        ...f,
                                        createdOn: newValue,
                                        initail: false,
                                    }));
                                }}
                                sx={{
                                    ...selectStylesOverride,
                                    ".MuiSvgIcon-root": {
                                        fontSize: "1.1rem !important"
                                    },
                                    ".MuiFormLabel-root": {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                    },
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                format="DD/MM/YYYY"
                                variant="inline"
                                inputVariant="outlined"
                                slotProps={{ textField: { size: "small" } }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ flexBasis: "15%" }}>
                        {viewClearButton() &&
                            <Button type="button" data-testid="clearfilter-jobtype">
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange, textDecoration: "underline", cursor: "pointer",
                                        textTransform: "none"
                                    }}
                                    onClick={() => {
                                        setFilter({ ...initFilters, initail: false, makeInitial: true });
                                        setTabIndex(0);
                                        // setFilter({...initFilters,jobStatus:filter.jobStatus});
                                        // setTabIndex(
                                        // 	!jobStatus
                                        // 		? 0
                                        // 		: jobStatus == "pending"
                                        // 			? 1
                                        // 			: jobStatus == "ongoing"
                                        // 				? 2
                                        // 				: 3
                                        // );
                                    }}
                                >
                                    Clear Filter(s)
                                </Typography>
                            </Button>
                        }
                        {/* 
                    <Button
                        sx={{
                            color: theme.themeOrange,
                            textDecoration: "underline",
                        }}
                        onClick={() => {
                            setFilter({ ...initFilters, initail: false, makeInitial: true });
                            setTabIndex(0);
                        }}
                    >
                        Clear Filters
                    </Button> */}
                    </Grid>
                </Grid>
                : ""}

            <Grid container>
                {loader
                    ? <Grid container  data-testid="loader" direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                        <Grid item><GenericLoader /></Grid>
                    </Grid>
                    : <Grid
                        item
                        xs={12}
                        sx={{mt:buttonActionOn?.selectedRow?.length ? 0 : 1}}
                    >
                        {buttonActionOn?.selectedRow?.length ? <Box>
                            <Button
                                variant="contained"
                                className='UM_uploadfile-btn'
                                disabled={(buttonActionOn?.selectedRow?.length ==0 || buttonActionOn?.selectedRow?.length > 1) ? true : false}
                                onClick={() =>controlTableAction("Edit",false)}
                                sx={{ fontSize: "12px", borderRadius: "8px", margin: "10px 10px 10px 0", color: "black", border: "1px solid black" }}
                            > Edit Job Type</Button>

                            <Button
                                variant="contained"
                                className='UM_delete-btn'
                                onClick={() =>controlTableAction("Delete",false)}
                                sx={{ fontSize: "12px", borderRadius: "8px", margin: "10px", color: "black", border: "1px solid black" }}
                            > Delete Job Type (s)</Button>
                            <Button
                                onClick={() => {
                                    setButtonActionOn(initialButton_state);
                                    setTabIndex(0);
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...textClasses.boldText,
                                        color: theme.themeOrange, textDecoration: "underline", cursor: "pointer",
                                        textTransform: "none"
                                    }}

                                >
                                    Clear Selection(s)
                                </Typography>
                            </Button>

                        </Box> : ""}
                        <Box sx={{width:"100%"}}>
                            <GenericTable
                                data-testid="jobTypeTable"
                                header={tableHeader()}
                                rows={tableRows}
                                pageCount={jobTypes?.count || 0}
                                pageNumber={filter.page - 1}
                                handleChangePage={(event, pagevalue) => {
                                    setFilter((f) => ({
                                        ...f,
                                        page: pagevalue + 1,
                                        initail: false,
                                    }));
                                }}
                                handleChangeRowsPerPage={(event) => {
                                    setFilter((f) => ({
                                        ...f,
                                        limit: +event.target.value,
                                        initail: false,
                                        page: 1
                                    }));
                                }}
                                rowsPerPage={filter.limit}
                                // onMenuCloseHandler={onMenuCloseHandler}
                                // controlTableAction={controlTableAction}
                                // anchorEl={anchorEl}
                                // open={open}
                            />
                        </Box>
                    </Grid>
                }

            </Grid>

            {buttonActionOn?.action && ["Add","Delete","Edit"].includes(buttonActionOn.action) && 
            <JobTypeModel
                open={buttonActionOn.action}
                hide={() => {
                    setButtonActionOn({...buttonActionOn,"action":"","activeRow":""})
                }}
                buttonActionOn={buttonActionOn}
                allJobActions={allJobActions}
                initialState={initialState}
                reducer={reducer}
                submitHandler={submitHandler}
            />}
        </>

    );
}



function capitalize(str) {
	if (!str) return;
	// ////({ str });
	return str[0].toUpperCase().concat(str.slice(1));
}
