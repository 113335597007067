import { Dialog, DialogContent, Typography, Grid, Button } from '@mui/material'
import React from 'react'
import history from '../../../services/history'
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme'
import documents_amico from '../../../static/images/templates/Documents-amico.svg';

export default function SuccessDialog({ open, data, handleSucccessModel }) {
    //console.log("Succ data", data);
    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid container direction="column" justifyContent={"center"} alignItems="center">
                    <img src={documents_amico} height="250" />
                    <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>New Template Published Successfully</Typography>
                    <Typography sx={{ ...textClasses.boldText, fontSize: "12px", textAlign: "center", mt: 1, mb: 2 }}>
                        <span style={{ color: theme.themeOrange }}>{data && data.name} </span>
                        created successfully. Create more templates to streamline your warehouse operations.
                    </Typography>
                    <Grid container item spacing={5}>
                        <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                            <Button variant='outlined' fullWidth size='small' sx={{ ...buttonClasses.outlined, color: theme.themeOrange }} onClick={() => history.push("/dashboard/templates")}>
                                View Templates
                            </Button>
                        </Grid>
                        <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                            <Button variant='contained' fullWidth size='small'
                                sx={{ ...buttonClasses.small, fontSize: "14px", minHeight: "40px", backgroundColor: theme.themeOrange }}
                                onClick={() => {
                                    handleSucccessModel(false)
                                    history.push("/dashboard/templates/create")
                                }
                                }
                            >
                                Create New Template
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}
