import {
    FETCH_USER_PRODUCTIVITY,
    FETCH_USERS_TYPE_WISE_COUNT
  } from "../types";

const intialState = {
    AllUserData: {},
    AllUserWithoutFilter: {},
    userJobProductivity: {},
    counts :{},
    userTypeWiseCount: null
};


const ManageTeamReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_ALL_USERS_DATA":
            return { ...state, AllUserData: payload }
        case "GET_ALL_USERS_WITHOUT_FILTER": 
            return { ...state, AllUserWithoutFilter: payload}
        case "GET_COUNTS":
            return { ...state, counts: payload }
        case FETCH_USER_PRODUCTIVITY:
            return { ...state, userJobProductivity: payload }
        case FETCH_USERS_TYPE_WISE_COUNT:
            return { ...state, userTypeWiseCount: payload }
        default:
            return state;
    }
}
export default ManageTeamReducer;