import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { buttonClasses } from "../../../../static/styles/theme";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Button from "@mui/material/Button";
import theme, { textClasses} from "../../../../static/styles/theme";
import { Edit, Upload } from "@mui/icons-material";
import BulkUploadMapping from "./BulkUploadMapping";
import SampleXlsx from "../../../../static/file/bulkMap.xlsx";
import { checkDistinctMasterExist, flushMappedIds, getAreaMappingDetail } from "../../../../redux/actions";
import ViewAreaMapping from "./ViewAreaMapping";
import { useCallback } from "react";
import GenericTable from "../../CustomTable/GenericTable";
import GenericLoader from "../../Generic/GenericLoader";
import AllocateModel from "./AllocateModel";
import {dataModifer} from "./utils";



function MapTable(props) {
  const dispatch = useDispatch();
  const {
    tableData,
    pageCount,
    pageNumber,
    rowsPerPage,
    handleRefreshTable,
    tableLoader,
    setTableLoader
  } = props;

  const distinctMasters = useSelector(
    (state) => state.areaMappingData.distinctMasters
  );
  const [selectedRack, setSelectedRack] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [viewMappedArea, setViewMappedArea] = useState(false);
  const [categoryClicked, setCategoryClicked] = useState();
  const [showAreaMapping, setShowAreaMapping] = useState(false);
  const [racksData, setRacksData] = useState()

  const { userPermissions } = useSelector((state) => state.userPermission);
  let permissions = userPermissions || {};



  const handleEdit = useCallback((data) => {
    const warehouseDetails = { warehouseId: data._id.warehouseId, floorId: data._id.floorId }
    const payload = {
      floorId: data._id.floorId,
      mappingArea: [data._id.subProductType],
    }
    setTableLoader("areaDetail")
    dispatch(getAreaMappingDetail(payload, ({ success, message, data }) => {
      setTableLoader("")
      const racksData = dataModifer(warehouseDetails, data)
      setRacksData(racksData)
      setShowAreaMapping(true)
    }))
  }, [dispatch])

  const closeAllocateModel = ()=>{
    setShowAreaMapping(false)
  }

  useEffect(() => {
    dispatch(
      checkDistinctMasterExist({ master_Type: ["item-master"] }, () => {})
    );
  }, [dispatch]);

  const handleFlushMappedIds = useCallback(()=>{
    dispatch(flushMappedIds())
    setViewMappedArea(false)
  },[dispatch, setViewMappedArea])

  const headers = ["Area Name","Area Type", "Mapped Area", "Category & Details", "Upload Data", "Actions"]

  const rows =tableData?.length && tableData?.map((item, i) => {
      let row = [];

      const renderCellForConnectedItem = ()=>{

        const connectedItems = item?.connectedItem?.map((item)=> {
            return item
        }).join(", ")

        const moreItems = item?.connectedItemCount > 3 && <Typography sx={{
            ...textClasses.t13n,
            textAlign: "center",
            color: theme.themeOrange,
            cursor: "pointer",
            textDecoration: "underline",
            alignSelf: "center",
          }}
          data-testid="clickableItemMasterCount"
          onClick={() =>{
            setViewMappedArea(true);
            setSelectedRack(item);
            setCategoryClicked("itemMaster")

          }}>+{Number(item?.connectedItemCount || 0)- Number(item?.connectedItem?.length || 0)}</Typography>;

        return (item?.connectedItemCount > 0 && (item?.connectedItemCount > 3
          ? (<Typography sx={{ display: "flex" }}>ITEM MASTER -({connectedItems}) ({moreItems}) </Typography>)
          : (<Typography sx={{ display: "flex" }}>ITEM MASTER - ({connectedItems})</Typography>))
        )
      }

      const renderCellForConnectedCustomer = ()=>{

        const connectedCustomers = item?.connectedCustomer?.map((item)=> {
            return item
        }).join(", ")

        const moreItems = item?.connectedCustomerCount > 3 && <Typography sx={{
            ...textClasses.t13n,
            textAlign: "center",
            color: theme.themeOrange,
            cursor: "pointer",
            textDecoration: "underline",
            alignSelf: "center",
          }}
          onClick={() =>{
            setViewMappedArea(true);
            setSelectedRack(item);
            setCategoryClicked("customerMaster")
          }}>+{item?.connectedCustomerCount} </Typography>;

        return (item?.connectedCustomerCount > 0   && (item?.connectedCustomerCount > 3 ? (<Typography  sx={{ display: "flex"}} >CUSTOMER MASTER - ({connectedCustomers} {moreItems}) </Typography>) : (<Typography  sx={{ display: "flex"}}>CUSTOMER MASTER - ({connectedCustomers})</Typography>) ))
      }

      row.push(
        <Typography
          sx={{ ...textClasses.normalText, color: theme.themeOrange }}
        >
          {(item.cell && item.cell[0]?.subProductType) ?? "-"}
        </Typography>
      );
      row.push(
        <Typography
          sx={{ ...textClasses.normalText }}
        >
         {item?.areaType?.join(", ") ?? "-"}
        </Typography>
      );
     let cell=item?.cell?.map((item) =>item.name) || []
      row.push(
        
        <Typography
          sx={{ ...textClasses.normalText }}
        >
          {item.cell?.map((item) => item?.locations 
          ).join(", ") ?? "-"}
        </Typography>
      );
      row.push(
        <Typography
          sx={{ ...textClasses.normalText, display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
         {item?.connectedItemCount > 0 || item?.connectedCustomerCount > 0 ? 
         (<Typography sx={{display: "flex"}}>
            <Typography sx={{mr: "8px"}}>{renderCellForConnectedItem()}</Typography>
            <Typography>{renderCellForConnectedCustomer()}</Typography>
         </Typography>)
          : "Other" }
        </Typography>
      );
      row.push(
        <Typography
          sx={{ ...textClasses.normalText, color: theme.themeOrange }}
        >
          <Button
                        data-testid="uploadButton"
                        variant="contained"
                        size="small"
                        sx={{
                          ...buttonClasses.lynkitBlackFill,
                          minHeight: "24px",
                          minWidth: "80px",
                          color: "white",
                        }}
                        startIcon={<Upload />}
                        onClick={() => {
                          setUploadStatus(true);
                          setSelectedRack(item);
                        }}
                      >
                        Upload File
                      </Button>
        </Typography>,
      );
      row.push( <Typography sx={{display: "flex"}}>
      <IconButton
     aria-label="edit"
     onClick={()=>handleEdit(item)}
   >
   
    {permissions &&
              (permissions.permissions || []).find(
                (item) => item.permissionId === "editAreaMapping"
              ) ? (
                < Edit sx={{ color: "black" }} />
              ) : (
                "-"
              )}
    
   </IconButton>
   </Typography>)
      return row;
    });

  return (
    <>
      {tableLoader=="tableData" ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
        <Grid item><GenericLoader /></Grid>
      </Grid>
        :
        <GenericTable
          header={headers}
          rows={rows}
          pageCount={pageCount}
          pageNumber={pageNumber}
          handleChangePage={(event, pagevalue) => {
            props.handleChangePage(event, pagevalue)
          }}
          handleChangeRowsPerPage={(event) => {
            props.handleChangeRowsPerPage(event)
          }}
          rowsPerPage={rowsPerPage}
        />
      }
      {uploadStatus && (
        <BulkUploadMapping
          open={uploadStatus}
          selectedData={selectedRack}
          hide={() => setUploadStatus(false)}
          sampleFile={SampleXlsx}
          distinctMasters={distinctMasters}
          handleRefreshTable={handleRefreshTable}
        />
      )}
      {viewMappedArea && <ViewAreaMapping
        open={viewMappedArea}
        hide={() => {
          handleFlushMappedIds()
        }}
        selectedData={selectedRack}
        distinctMasters={distinctMasters}
        categoryClicked={categoryClicked}
      />}
      {showAreaMapping && <AllocateModel show={showAreaMapping}
        onSuccess={handleRefreshTable}
        isEdit selectedRackData={racksData}
        onClose={() => closeAllocateModel()}
        tableLoader={tableLoader}
        setTableLoader={setTableLoader}
      />}
    </>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default MapTable;
