import axios from "../../services/Api";
import { showToast } from "../../services/functions";

import { FETCH_ERROR, FETCH_START, FETCH_STOCK_DATA, FETCH_STOCK_RETURN_LIST, FETCH_SUCCESS } from "../types";

export const getStockReturnList = (payload, cb) => {
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('manageReturn/getReturnStockList', payload).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_STOCK_RETURN_LIST, payload: {data: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_STOCK_RETURN_LIST, payload: {data: [], count: 0}})
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_STOCK_RETURN_LIST, payload: {data: [], count: 0}})
        });
    }
};

export const getStockPackageItemData = (payload, cb) =>
{
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('manageReturn/getStockPackageItemData', payload).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_STOCK_DATA, payload: {data: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_STOCK_DATA, payload: {data: [], count: 0}})
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_STOCK_DATA, payload: {data: [], count: 0}})
        });
    }
};

export const createStockReturn = (payload, cb) =>
{
    return (dispatch) => {
        dispatch({type: FETCH_START});
        axios.post('manageReturn/saveStockReturn', payload).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_STOCK_DATA, payload: {data: [], count: 0}})
        });
    }
};