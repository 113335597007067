import axios from "../../services/Api";
import { showToast } from "../../services/functions";
import { FETCH_ERROR, FETCH_SUCCESS,FETCH_START, FETCH_EXTERNAL_FORM_CONFIG ,FETCH_E_SALES_ORDER_PROCESSING,FETCH_E_SALES_STATUS_COUNT, FETCH_E_SALES_DETAIL} from "../types";

export const getESOPFormTemplateDetails = (payload,callback) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/getExternalFormConfigs', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success){
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: FETCH_EXTERNAL_FORM_CONFIG, payload: data?.data})
        } 
        else {
            dispatch({type: FETCH_ERROR, payload: data?.message});
            dispatch({ type: FETCH_EXTERNAL_FORM_CONFIG, payload: {}})
        }
        if(callback){
            callback(data ||  {})
        }

        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_EXTERNAL_FORM_CONFIG, payload: ""})
            showToast(error.message, true);
            if(callback){
                callback({success:false,message:error.message})
            }
            //("Error****:", error.message);
        });
    }
};


export const saveESOPFormTemplateDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/submitResponse', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast(data.message, false);
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};

export const getAllESalesOrder = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/responseForm/getESOResponseList', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_E_SALES_ORDER_PROCESSING, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_E_SALES_ORDER_PROCESSING, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data.data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //('here')
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const approveRejectESales = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/updateESOStatus', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast(data.message, false);
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};

export const getESalesStatusCount = (payload) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/getESOStatusWiseCount', {
            ...payload
        }
        ).then(({data}) => {
        if (data.success) 
        {
            callback(data);
        } 
        else 
        {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: FETCH_E_SALES_STATUS_COUNT, payload: null})
        }

        function callback() 
        {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type:FETCH_E_SALES_STATUS_COUNT, payload: data.data})
        }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};


export const getESalesDetails = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('responseForm/getESORespsonseDetail', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_E_SALES_DETAIL, payload: {data: null, count: 0}})
                // showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_E_SALES_DETAIL, payload: {data: data.data, count: data.count}})
                if(cb){
                    cb(data.data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast("Something went wrong", true);
            if(cb){
                cb()
            }
        });
    }
};
