import axios from "../../services/Api";
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    GET_NAVIGATION_ROUTE,
    GET_WAREHOUSE_CAPACITY,
    GET_CELL_CAPACITY,
    GET_CELL_STORAGE_CAPACITY,
    GET_CELL_CAPACITy,
    GET_CELL_CAPACITY_DETAIL,
    GET_UTILIZATION_ANALYTICS_BLOCK,
    GET_UTILIZATION_ANALYTICS_RACK,
    GET_CELL_ACCESSBILITY_DETAIL,
    GET_CELL_ACCESSBILITY_LIST
} from "../types";



export const getGridDetail = (obj, cb) => {
    return async (dispatch) => {

        dispatch({ type: FETCH_START });
        await axios.post('/grid/getGridDetail', obj
        ).then(({ data,success,count,message}) => {

            if (data.success) {
                dispatch({ type: FETCH_SUCCESS, payload: data.success });
                // dispatch({type: SHOW_MESSAGE, payload: data.message});  
                if (obj.type == "block") {
                    dispatch({ type: "GET_GRID_CELL_DETAIL", payload: data.data });
                }
                else if (obj.type == "rack") {
                    dispatch({ type: "GET_GRID_RACK_DETAIL", payload: data.data });
                }
                else {
                    dispatch({ type: "GET_GRID_ALL_DETAIL", payload: data.data });
                }
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                if (obj.type == "block") {
                    dispatch({ type: "GET_GRID_CELL_DETAIL", payload: [] });
                }
                else if (obj.type == "rack") {
                    dispatch({ type: "GET_GRID_RACK_DETAIL", payload: [] });
                }
                else {
                    dispatch({ type: "GET_GRID_ALL_DETAIL", payload: [] });
                }
                
            }
            if(cb){
                cb({success:data.success || false,message:data.message , data:data?.data || []});
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            ////("Error****:", error.message);
            if(cb){
                cb({success:false,message:error.message});
            }
        });
    }
}

export const getGridDimensions = (obj) => {
    return async (dispatch) => {

        dispatch({ type: FETCH_START });
        await axios.post('/grid/getGridDimensions', obj
        ).then(({ data }) => {

            if (data.success) {
                dispatch({ type: FETCH_SUCCESS, payload: data.success });
                // dispatch({type: SHOW_MESSAGE, payload: data.message});  
                if (obj.type == "block") {
                    dispatch({ type: "GET_GRID_CELL_DIMENTION", payload: data.data });
                }
                else if (obj.type == "rack") {
                    dispatch({ type: "GET_GRID_RACK_DIMENTION", payload: data.data });
                }
                else {
                    dispatch({ type: "GET_GRID_ALL_DIMENTION", payload: data.data });
                }

            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                if (obj.type == "block") {
                    dispatch({ type: "GET_GRID_CELL_DIMENTION", payload: [] });
                }
                else if (obj.type == "rack") {
                    dispatch({ type: "GET_GRID_RACK_DIMENTION", payload: [] });
                }
                else {
                    dispatch({ type: "GET_GRID_ALL_DIMENTION", payload: [] });
                }

            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            ////("Error****:", error.message);
        });
    }
}

export const addRack = (obj,callBack) => {
    return async (dispatch) => {

        dispatch({ type: FETCH_START });
        await axios.post('/grid/addRack', obj
        ).then(({ data }) => {

            if (data.success) {
                dispatch({ type: FETCH_SUCCESS, payload: data.success });
                dispatch({ type: SHOW_MESSAGE, payload: data.message });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });

            }

            if(callBack){
                callBack(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            callBack({success:false,message:error.message})
            ////("Error****:", error.message);
        });
    }
}

export const viewFilledLocationByShipment = obj => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/grid/viewFilledLocations", obj)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    // dispatch({ type: SHOW_MESSAGE, payload: data.message });
                    dispatch({ type: "VIEW_FILLED_LOCATION_BY_SHIPMENT", payload: data });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                ////("Error****:", error.message);
            });
    };
};

export const getGridBlockData = (obj,callBack) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/inventory/gridBlockData", obj)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: "GET_GRID_BLOCK_DATA_BY_LOCATION", payload: data });
                } else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    let item = { ...data, data: [], count: 0 }
                    dispatch({ type: "GET_GRID_BLOCK_DATA_BY_LOCATION", payload: item });
                }
                if(callBack){
                    callBack({ success :data.success || false, data :data?.data || [], count :data?.count || 0, stockDetail:data?.stockDetail || {} })
                }
            })
            .catch(function (error) {
                if(callBack){
                    callBack({ success :false, data : [], count : 0 })
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                ////("Error****:", error.message);
            });
    };
};
export const getRouteData =( obj,callBack) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/navigation/getRouteData", obj)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_NAVIGATION_ROUTE, payload: data });
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_NAVIGATION_ROUTE, payload: {...data,data: []} });
                }
                if(callBack){
                    callBack({...data,success:data.success || false,message:data.message || 'No data found'})
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                ////("Error****:", error.message);
            });
    };
};

export const saveWarehouseCapacity =(body,callback) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/areaUtilization/saveWarehouseCapacity",body)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_WAREHOUSE_CAPACITY, payload: data });
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    // dispatch({ type: GET_NAVIGATION_ROUTE, payload: {...data,data: []} });
                }

                if(callback){
                    callback(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(callback){
                    callback({success:false,message:error.message})
                }
                ////("Error****:", error.message);
            });
    };
};

export const saveStorageCellCapacity =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/areaUtilization/saveStorageCellCapacity",body)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_CELL_CAPACITy, payload: data });
                    if(cb){
                        cb({success:true,message:data.message}) 
                    }
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    if(cb){
                        cb({success:false,message:data.message})
                    }
                    // dispatch({ type: GET_NAVIGATION_ROUTE, payload: {...data,data: []} });
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
                ////("Error****:", error.message);
            });
    };
};


export const getCellStorageCapacity =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/areaUtilization/getCellStorageCapacity",body)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_CELL_STORAGE_CAPACITY, payload: data });
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_CELL_STORAGE_CAPACITY, payload: {} });
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
                ////("Error****:", error.message);
            });
    };
};

export const getCellCapacityDetail =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/areaUtilization/getCellCapacityDetail",body)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_CELL_CAPACITY_DETAIL, payload: data });
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_CELL_CAPACITY_DETAIL, payload: {} });
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
                ////("Error****:", error.message);
            });
    };
};

export const getUtilizationAnalytics =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/areaUtilization/getUtilizationAnalytics",body)
            .then(({ data }) => {
                // console.log("inside  daa",data)
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    if(body?.type=="block"){
                    dispatch({ type: GET_UTILIZATION_ANALYTICS_BLOCK, payload: data });
                    }else{
                    dispatch({ type: GET_UTILIZATION_ANALYTICS_RACK, payload: data });}

                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    if(body?.type=="rack"){
                    dispatch({ type: GET_UTILIZATION_ANALYTICS_BLOCK, payload: {}});}
                    else{
                    dispatch({ type: GET_UTILIZATION_ANALYTICS_RACK, payload: {}});}
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
                ////("Error****:", error.message);
            });
    };
};


/****
 * Grid accessbility API start
 */

export const defineGridAccessibility =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/gridAccessbility/defineGridAccessibility",body)
            .then(({ data }) => {
                // console.log("inside  daa",data)
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });

                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
            });
    };
};
export const getGridAccessbilityList =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/gridAccessbility/getGridAccessbilityList",body)
            .then(({ data }) => {
                // console.log("inside  daa",data)
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_CELL_ACCESSBILITY_LIST, payload: data })

                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_CELL_ACCESSBILITY_LIST, payload: {}});
                    
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                dispatch({ type: GET_CELL_ACCESSBILITY_LIST, payload: {}});
                if(cb){
                    cb({success:false,message:error.message})
                }
            });
    };
};
export const getGridAccessbilityDetail =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/gridAccessbility/getGridAccessbilityDetail",body)
            .then(({ data }) => {
                // console.log("inside  daa",data)
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                    dispatch({ type: GET_CELL_ACCESSBILITY_DETAIL, payload: data })

                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });
                    dispatch({ type: GET_CELL_ACCESSBILITY_DETAIL, payload: {}});
                    
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                dispatch({ type: GET_CELL_ACCESSBILITY_DETAIL, payload: {}});
                if(cb){
                    cb({success:false,message:error.message})
                }
            });
    };
};
export const updateGridAccessbilityPreferences =(body , cb) => {
    return async dispatch => {
        dispatch({ type: FETCH_START });
        await axios
            .post("/gridAccessbility/updateGridAccessbilityPreferences",body)
            .then(({ data }) => {
                if (data.success) {
                    dispatch({ type: FETCH_SUCCESS, payload: data.success });
                }
                else {
                    dispatch({ type: FETCH_ERROR, payload: data.message });                    
                }
                if(cb){
                    cb(data)
                }
            })
            .catch(function (error) {
                dispatch({ type: FETCH_ERROR, payload: error.message });
                if(cb){
                    cb({success:false,message:error.message})
                }
            });
    };
};
