import React from "react";
import { textClasses, inputClasses } from "../../../../../static/styles/theme";
import {
  Autocomplete,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import ificon from "../../../../../static/images/Icon/createAutomation/ificon.png";
import ifblockicon from "../../../../../static/images/Icon/createAutomation/ifblockicon.svg";
import { useEffect, useState } from "react";
import {
  conditionsList,
  fieldValueData,
  jobTypes,
  taskFieldList,
} from "../constant";
import CreateAutomationCard from "./CreateAutomationCard";
import blackicon from "../../../../../static/images/Icon/createAutomation/blackicon.svg";
import plusCreateIcon from "../../../../../static/images/Icon/createAutomation/plusCreateIcon.svg";
import sendnotifactionicon from "../../../../../static/images/Icon/createAutomation/Sendnotificationicon.svg";

const AddConditions = ({
  selectJobType,
  setSelectJobType
}) => {
  const [textfields, setTextfields] = useState(null);
  const [condition, setCondition] = useState(null);
  const [fieldValueCondition, setFieldValueCondition] = useState(null);
  const [displayAddAnAction, setDisplayAddAnAction] = useState(false);
  const [displayCreateJob, setDisplayCreateJob] = useState(false);

  const handleConditionChange = (option, setValue) => {
    setValue(option);
    if(option === null) {
      setDisplayAddAnAction(false);
      setDisplayCreateJob(false);
    }
  }

  return (
    <>
      <>
        <Grid
          container
          spacing={0}
          sx={{
            marginLeft: "30px",
            marginTop: "25px",
          }}
        >
          <Grid item xs={0}>
            {" "}
            <img src={ificon} />
          </Grid>
          <Grid item xs={0}>
            {" "}
            <Typography
              sx={{
                ...textClasses.boldText,
                color: "#000000",
                paddingLeft: "15px",
              }}
            >
              IF, ELSE : Add Condition Options
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{
            ...textClasses.t12n,
            color: "#827878",
            marginLeft: "65px",
          }}
        >
          Set up your rule to run one path out of multiple, depending on which
          condition met.
        </Typography>
        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #E0E0E0",
            margin: "10px 10px 0 55px",
            padding: " 10px 0px 15px 0px",
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              padding: "10px 0 0 15px",
            }}
          >
            <img src={ifblockicon} />
            <Typography
            data-testid="If-block"
              sx={{
                ...textClasses.boldText,
                color: "#000000",
                marginLeft: "8px",
              }}
            >
              If Block
            </Typography>
          </Grid>
          <Typography
            sx={{
              ...textClasses.t12n,
              marginLeft: "40px",
            }}
          >
            The if block executes the actions within that block when the all
            specified conditions matches.
          </Typography>
          <Grid
            container
            spacing={0}
            sx={{
              padding: "0px 0px 20px 25px",
            }}
          >
            <Autocomplete
              value={textfields}
              onChange={(e, option) => {
                handleConditionChange(option, setTextfields)
              }}
              getOptionLabel={(option) => option.name}
              size="small"
              options={taskFieldList}
              sx={{
                ...inputClasses.filterField,
                width: "277px",
                margin: "10px 0 0 10px",
                background: "white",
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "yellow", ...textClasses.normalText }}
                  {...params}
                  label="Select Task Field *"
                />
              )}
            />
            <Autocomplete
              value={condition}
              onChange={(e, option) => {
                handleConditionChange(option, setCondition)
              }}
              getOptionLabel={(option) => option.name}
              size="small"
              options={conditionsList}
              sx={{
                ...inputClasses.filterField,
                width: "277px",
                margin: "10px 0 0 10px",
                background: "white",
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "yellow", ...textClasses.normalText }}
                  {...params}
                  label="Condition  *"
                />
              )}
            />
            <Autocomplete
              value={fieldValueCondition}
              onChange={(e, option) => {
                handleConditionChange(option, setFieldValueCondition)
              }}
              getOptionLabel={(option) => option.name}
              size="small"
              options={fieldValueData}
              sx={{
                ...inputClasses.filterField,
                width: "277px",
                margin: "10px 0 0 10px",
                background: "white",
              }}
              renderInput={(params) => (
                <TextField
                  sx={{ color: "yellow", ...textClasses.normalText }}
                  {...params}
                  label="Select Field Value  *"
                />
              )}
            />
          </Grid>
          <Box
            sx={{
              margin: "0px 0 10px 35px",
            }}
          >
            {!displayAddAnAction &&
              textfields &&
              condition &&
              fieldValueCondition &&
               (
                <CreateAutomationCard
                  text="THEN: Add an action"
                  subText="Actions will execute when your rule runs successfully."
                  icon={blackicon}
                  width="281px"
                  handleJobCreate={() => {
                    setDisplayAddAnAction(true);
                  }}
                />
              )}
          </Box>

          {displayAddAnAction && !displayCreateJob && (
            <Box
              sx={{
                borderRadius: "8px",
                margin: "10px 10px 0 35px",
              }}
            >
              <Grid
                container
                spacing={0}
                sx={{
                  margin: "18px 0 0 10px",
                }}
              >
                <img src={blackicon} />
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  THEN: Add an action
                </Typography>
              </Grid>

              <Typography
                sx={{
                  ...textClasses.t12n,
                  margin: "0 0 0 38px",
                }}
              >
                Actions will execute when your condition met successfully.
              </Typography>

              <Grid
                container
                spacing={0}
                sx={{
                  margin: "18px 0 10px 30px",
                  gap: "20px",
                }}
              >
                <CreateAutomationCard
                  text="Create A New Job "
                  subText="Create a new Job if above mentioned condition met. "
                  icon={plusCreateIcon}
                  width="281px"
                  testId="create-job"
                  handleJobCreate={() => {
                    setDisplayCreateJob(true);
                  }}
                />
                <CreateAutomationCard
                  text="Send notification and emails to users "
                  subText="send email to if above mentioned condition met. "
                  width="281px"
                  icon={sendnotifactionicon}
                />
              </Grid>
            </Box>
          )}
          {displayCreateJob && (
            <>
              {" "}
              <Grid
                container
                spacing={0}
                sx={{
                  margin: "10px 0 0 10px",
                }}
              >
                <img src={plusCreateIcon} />
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  Create A New Job{" "}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  margin: "0 0 0 38px",
                }}
              >
                Create a new Job if above mentioned condition met.{" "}
              </Typography>
              <Box
                sx={{
                  marginBottom: "10px",
                }}
              >
                <Autocomplete
                  value={selectJobType}
                  onChange={(e, option) => {
                    setSelectJobType(option);
                  }}
                  getOptionLabel={(option) => option.name}
                  size="small"
                  options={jobTypes}
                  sx={{
                    ...inputClasses.filterField,
                    width: "380px",
                    margin: "10px 0 0 35px",
                    background: "white",
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ color: "yellow", ...textClasses.normalText }}
                      {...params}
                      label="Select Job Type *"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Box>
      </>
    </>
  );
};
export default AddConditions;
