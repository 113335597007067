import { FETCH_KIT,FETCH_KIT_STATUS_COUNT,FETCH_KIT_DETAIL } from "../types"
const INIT_STATE={
    allKit:[],
    totalRows:0,
    statusCount: null,
    kitDetail: null,
    kitDetailItemCount: 0,

}
export default (state=INIT_STATE, action) => {
    switch(action.type){
        case FETCH_KIT: return {...state,allKit: action.payload.rows,totalRows : action?.payload?.count || 0}
        case FETCH_KIT_STATUS_COUNT: return {...state, statusCount: action.payload}
        case FETCH_KIT_DETAIL: return {...state, kitDetail: action?.payload?.data, kitDetailItemCount: action?.payload?.count || 0}

        default: return state
    }
}