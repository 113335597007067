import React from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    Typography,
    StepContent,
    stepConnectorClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check, ReportProblemOutlined as ErrorIcon,RemoveRedEye as View} from '@mui/icons-material';
import theme from "../../../static/styles/theme";
import { useHistory,} from "react-router-dom";

const CustomStepConnector = styled(StepConnector)(({ theme,customTheme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: customTheme.themeOrange, // Orange for active steps
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: customTheme.themeOrange, // Orange for active steps
            // borderColor: customTheme.themeGreen, // Orange for completed steps
        },
    },
    // Ensure that inactive steps do not override the above styles
    [`&.${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.divider, // Default color for non-active/non-completed lines
    },
}));

const CustomStepIcon = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: ownerState.active ? '#FF7200' : '#ccc', // orange for active, grey for pending/ongoing
    width: '24px',
    height: '24px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    ...(ownerState.active && {
        backgroundColor: theme.themeOrange, // themeLightOrange for completed
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.themeGreen, // themeOrange for completed
    }),
    ...(ownerState.error && {
        backgroundColor: theme.themeError, // red for error
    }),
}));

// const steps = [
//   {
//     heading: 'Stock Allocation',
//     subHeading: 'Allocated by Himani at 14-03-2024 07:46 PM',
//     nextSubHeading: 'PM',
//     description: '1 pcs allocated',
//     link: '',
//     isStepFailed: false,
//   },
// ];

export default function CustomizedSteppers({
    orientation = 'vertical',
    expanded = true,
    activeStep = 1,
    steps = []
}) {

    let history=useHistory()

    return (
        <Stepper
            orientation={orientation}
            activeStep={activeStep}
            connector={<CustomStepConnector customTheme={theme} />}
        >
            {steps.map((step, index) => (
                <Step key={index} expanded={expanded}>
                    <StepLabel
                        error={step?.isStepFailed == true || false}
                        optional={
                            <Typography
                                variant="caption"
                                {...(step.isStepFailed ? { color: 'error' } : {})}
                            >
                                {step.subHeading}
                            </Typography>
                        }
                        StepIconComponent={({ active, completed }) => {
                            if (step.isStepFailed) {
                                return <ErrorIcon color="error" />;
                            }
                            return <CustomStepIcon ownerState={{ active, completed, error: step.isStepFailed == true }} theme={theme}>
                                {completed ? <Check /> : index + 1}
                            </CustomStepIcon>
                        }
                        }
                    >
                        <Typography color={step.isStepFailed ? 'error' : 'inherit'} sx={{display:"flex",justifyContent:"space-between"}}>
                            <span>
                                {step.heading}
                            </span>
                            <span>
                                {step.link ?
                                    <View onClick={() => history.push(step.link)} sx={{cursor:"pointer"}}/>
                                    : ""
                                }
                            </span>
                        </Typography>
                        {step.nextSubHeading && <Typography
                            variant="caption"
                            display="block"
                            color={step.isStepFailed ? 'error' : 'textSecondary'}
                        >
                            {step.nextSubHeading}
                        </Typography>}
                    </StepLabel>
                    <StepContent>
                        <Typography sx={{ color: theme.themeOrange }}>{step.description}</Typography>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}
