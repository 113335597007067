import React from "react";
import { Button, Grid, Typography } from "@mui/material";

import EmptyImage from "../../../static/images/templates/emptyTable.svg";
import { PlusOneOutlined } from "@mui/icons-material";
import "../../../static/css/dashboard2.css";

export default function EmptyPage({description}) {
  
	return (
		<>
			<Grid container>
				<Grid item sm={3}></Grid>
				<Grid
					container
					item
					sm={6}
					justifyContent={"center"}
					alignItems="center"
					sx={{ pt: 5, display:'flex', flexDirection:'column' }}
				>
					<img src={EmptyImage} width="39%" />
					<Typography sx={{ mt: 3, fontSize:'15px ! important', fontWeight:'800', color:'#727376 ! important' }} variant="h6">
						No Data Found!
					</Typography>
					{description && <Typography sx={{ mt: 3, fontSize:'15px ! important', fontWeight:'800', color:'#727376 ! important' }} variant="h6">
						{description}
					</Typography>}

					{/* <Typography sx={{ mt: 1 }} variant="caption">
						You haven’t created any job and assigned the same to
						your workers, let’s start with shipments gate-in
						gate-out operations job creation and allocation to the
						internal workers. Learn more.
					</Typography> */}
					{/* <Button
						variant="contained"
						startIcon={<PlusOneOutlined />}
						className="mui-orange-btn"
						sx={{ mt: 2 }}
					>
						Clear Filter
					</Button> */}
				</Grid>
			</Grid>
		</>
	);
}
