const intialState = {
    userPermissions: {},
    configDetail: {},
    sidebarList:[]
};


const userPermissionReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_USER_PERMISSIONS":
            return { ...state, userPermissions: payload?.data || {},sidebarList:payload?.sidebarList || [],configDetail:payload?.configDetail || {} };
        default:
            return state;
    }
}
export default userPermissionReducer;