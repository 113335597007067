
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,GET_ALL_ANALYTICS, TRACK_USER, GET_ZONE_USER, GET_CHART_DATA, GET_DASHBOARD_DATA, GET_WAREHOUSE_OPRATION, GET_WAREHOUSE_INVENTORY, GET_REPORT, GET_HEADER, GET_ITEM_VISE_REPORT
  } from "../types";
const intialState = {
    mappedArea:{},
    mappedAnalytics:[],
    allChartdata:{},
    wareHousedata:{},
    WarehouseInventory:{}

};


const mainDashboardReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case GET_ALL_ANALYTICS:
            return { ...state, allAnalytics:payload }
            case GET_ZONE_USER:
                ////(payload.data,'reducer')
                return { ...state, allZoneUser: payload.data }
            case TRACK_USER:
                ////(payload,'reducer')
                return { ...state,trackdata:payload}

            case GET_DASHBOARD_DATA:
                return{...state, allChartdata: payload}  
                
            case GET_WAREHOUSE_OPRATION:
                return{...state, wareHousedata:payload}  
                
           case GET_WAREHOUSE_INVENTORY:
            return{...state,WarehouseInventorydata:payload}  
           case GET_REPORT:
            return{...state,DownloadReportData:payload}   
            case GET_HEADER:
            return{...state,HeadersData:payload}
            case GET_ITEM_VISE_REPORT:
                return{...state,ItemViseReport:payload}

        default:
            return state;
    }
}
export default mainDashboardReducer;