import React from 'react';
import {lynkID} from '../../../services/config';
import Integration from '../../Components/Integration';


// const myVariable = `http://localhost:4406/dashboard`;

const RFIDTag = () => {
    const todayDate = new Date().getTime();
    const myVariable = `${lynkID}/login?action=/tags&auth=WMS&timestamp=${todayDate}`;

    return (
        <>
        <Integration
            myVariable={myVariable}
            baseURL={lynkID}
        />
       </>
    );
};

export default RFIDTag;


