import {
     CLEAR_CONNECTED_MASTER_DETAIL, FETCH_ORDER_CONNECTED_MASTER_DETAIL, FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL,
    FETCH_ADVANCE_NOTICE_SHIPMENT, FETCH_ASN_STATUS_COUNT, FETCH_ASN_DETAIL, FETCH_SHIPMENT_TEMPLATE_HEADER, 
    FETCH_ORDER_CONNECTED_MASTER_ENTRIES, FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES,GENERATE_PO_NUMBER
} from "../types"

const INIT_STATE = {
    shipmentConnectedMasterData: null,
    orderConnectedMasterData: null,
    allASN: [],
    totalRows:0,
    statusCount: null,
    asnDetail: null,
    asnDetailItemsCount: 0,
    shipmentTemplateHeader: [],
    shipmentMasterEntries: [],
    itemMasterEntries: [],
    PO_Number:""
}

export default (state=INIT_STATE, action) => {
    switch(action.type){
        case FETCH_SHIPMENT_CONNECTED_MASTER_DETAIL: return {...state, shipmentConnectedMasterData: action.payload}
        case FETCH_ORDER_CONNECTED_MASTER_DETAIL: return {...state, orderConnectedMasterData: action.payload}
        case FETCH_SHIPMENT_CONNECTED_MASTER_ENTRIES: return {...state, shipmentMasterEntries: action.payload}
        case FETCH_ORDER_CONNECTED_MASTER_ENTRIES: return {...state, itemMasterEntries: action.payload}
        case CLEAR_CONNECTED_MASTER_DETAIL: return {...state, shipmentConnectedMasterData: null, orderConnectedMasterData: null}
        case FETCH_ADVANCE_NOTICE_SHIPMENT: return {...state, allASN: action.payload.rows, totalRows: action.payload.count}
        case FETCH_ASN_STATUS_COUNT: return {...state, statusCount: action.payload}
        case GENERATE_PO_NUMBER: return {...state, PO_Number: action.payload}
        case FETCH_ASN_DETAIL: return {...state, asnDetail: action.payload.data, asnDetailItemsCount: action.payload.count}
        case FETCH_SHIPMENT_TEMPLATE_HEADER: return {...state, shipmentTemplateHeader: action.payload}
        default: return state
    }
}