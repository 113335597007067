export const templateTypes = [
    {
        name: "Inward Data Entry",
        value: "inwardDataEntry",
        description: "Includes set of information, used to describe a gate-in of particular shipment, package or items."
    },
    {
        name: "Outward Data Entry",
        value: "outwardDataEntry",
        description: "Includes set of information, used to describe dispatch process."
    },
    {
        name: "Entity Master",
        value: "entityMaster",
        description: "An Entity Master is a collection of records about inventories/ items , customers(buyers), transporters,partners and sellers etc",
        subtypes: [
            {
                name: "Item Master",
                value: "itemMaster",
                description: "Includes set of information, used to describe your master inventories (SKUs, Master Box etc.) This helps users on data entry automation."
            },

        ]
    },
    {
        name:"In House Movement",
        value:"inHouseMovement", // key confirmation
        description:"Includes set of information, used to describe all internal movements like Stock Transfer,Quality check etc of an item , and/ or package(s)",
        subtypes: [
            {
                name: "Stock Transfer",
                value: "stockTransfer",
                description: "Includes set of information, to whom or where to transfer the Stock, Stock QTY to be transferred. You can also transfer stock without creating a template."
            },
            {
                name: "Quality Checks",
                value: "qualityCheck",
                description: "Includes Check list of quality control metrics, users want to capture at the time of Receiving, Picking & Packaging"
            },
            {
                name: "Inventory Adjustment",
                value: "inventoryAdjustment",
                description: "Inventory Adjustments can be used to adjust inventory in the store up or down, or mark items available or unavailable, for any reason"
            }
        ]

    },
    {
        name: "Other",
        value: "freeTemplate",
        description: "Create your own dynamic template (s) and link these with your defined workflow. you can access the same while performing a warehouse operation"
    }
]

export const statusTypes = [
    "published",
    "disabled",
    "drafted"
]

export const currentStatus = [
    "Allocated",
    "Not Allocated"
]

export const getTemplateTypeName = (templateType) => {
    switch(templateType){
        case "salesOrderTemplate" : return "Sales Order";
        case "kittingTemplate" : return "Kit Details";
        case "inwardDataEntry": return "Inward Data Entry";
        case "outwardDataEntry": return "Outward Data Entry";
        case "itemMaster": return "Entity Master - Item Master";
        case "stockTransfer": return "In house movement - Stock Transfer";
        case "qualityCheck": return "In house movement - Quality Check";
        case "inventoryAdjustment": return "In house movement - Inventory Adjustment";
        // case "inHouseMovementTemplate": return "In House Movement" ; // key confirm
        case "ASNTemplate": return "ASN"
        case "freeTemplate": return "Other"
        default: return "Other"
    }
}

export const getSectionHelperText = (templateType) => {
    switch(templateType){
        case "componentDetail" : return "* add all data fields you want to capture for component."
        case "kittingTemplate" : return "* add all data fields you want to capture for kit."
        case "shipmentTemplate": return "* add all data fields you want to capture for an upcoming shipment."
        case "packageTemplate": return "* add all data fields you want to capture for any individual package or box in a shipment."
        case "itemTemplate": return "* add all data fields you want to capture for any individual item or product."
        case "outwardDataEntry": return "* add all data fields you want to capture to perform outward operation(s).";
        case "itemMaster": return "* add all data fields you are capturing for your master inventory.";
        case "stockTransfer": return "* add all data fields you want to capture while transferring stock to a location other warehouse or an user.";
        case "qualityCheck": return "* create a checklist to capture quality control data points.";
        case "inventoryAdjustment": return "* provide some helper description.";
        case "freeTemplate": return "Other"
        default: return "Other"
    }
}
export const userDetailsVisibilityFields = [
    {label: "Name", name:'name', type:'input',formatWord:"toCapitalize"},
    {label: "Email", name:'email', type:'input',},
    {label: "Phone Number", name:'mobileNumber', type:'input',},
    {label: "Company Name", name:'companyName', type:'input',formatWord:"toCapitalize"},

]