import React, { useState} from 'react';
import {  useDispatch } from 'react-redux';
import {  Button, Typography, IconButton, Dialog, DialogContent, Grid } from '@mui/material'
import { showToast } from '../../../../services/functions';
import { blockUsers, deleteUsers, } from '../../../../redux/actions/ManageTeam';
import theme, { buttonClasses, textClasses } from '../../../../static/styles/theme'
import { Cancel } from '@mui/icons-material';

function EditBlockModel(props) {
    const dispatch = useDispatch();
    const { open, hide ,action, selectedRows=[],setSelectedRows, setEditBlock} = props
    const [callStatusChange, setCallStatusChange] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const size =  selectedRows.length;

    const handleDelete = (index) => {
        let bodyData = {
            userId : selectedRows.length == 1 ? selectedRows[0].id : selectedRows.map(st => st.id)
        }
        setCallStatusChange(true)
        dispatch(deleteUsers(bodyData, ({success,message}) => {
            showToast(message,!success)
            setCallStatusChange(false)
            if(success){
                props.callFetchUsers(true)
                hide("success")
            }
        }))
    }


    const  handleBlockUser = () => {
        let bodyData = {
            "userId": selectedRows?.map(st => st.id),
            "approved": false
        }
        setCallStatusChange(true)    

        dispatch(blockUsers(bodyData,({success,message}) => {
            showToast(message,!success)
            setCallStatusChange(false)
            if(success){
                props.callFetchUsers(true)
                hide("success")
            }
        }))
        
    }

    const handleUnBlockUser = () => {
        let bodyData = {
            "userId": selectedRows?.map(st => st.id),
            "approved": true,
            
        }
        setCallStatusChange(true)
        dispatch(blockUsers(bodyData, ({success,message}) => {
            showToast(message,!success)
            setCallStatusChange(false)
            if(success){
                props.callFetchUsers(true)
                hide("success")
            }
        }))

    }

    
    const handleCloseConfirmModal = () => {
        setEditBlock(false)
    }

    const handleRemoveSelection = (templateId) => {
        let oldTemplates = [...selectedRows];
        const i = oldTemplates.map(o => o.id).indexOf(templateId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedRows(oldTemplates);
        }
        if(oldTemplates.length === 0){
            setSelectedRows([])
            setEditBlock(false)
        }
    }
   

    return (
        <>
            <Dialog
                open={action != null && selectedRows.length !== 0}
                onClose={handleCloseConfirmModal}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"sm"}
            >
                <DialogContent>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>{action} the selected Team(s)</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                        {
                            selectedRows.slice(0, showAll ? size : 5).map((st, i) => {
                                return (
                                    <Grid item sm={4} xs={6} key={i}>
                                        <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                            <Grid item sm={10} xs={8}>
                                                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                    {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                                </Typography>

                                            </Grid>
                                            {
                                                handleRemoveSelection &&
                                                <Grid item sm={2} xs={4}>
                                                    <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st.id)}>
                                                        <Cancel sx={{ color: theme.themeOrange }} />
                                                    </IconButton>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        {
                            size > 5 &&
                            <>
                                <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                        {
                                            showAll
                                                ?
                                                <>Collapse</>
                                                :
                                                <>View All ({size})</>
                                        }
                                    </Typography>
                                </Grid>

                            </>
                        }
                    </Grid>
                    <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={handleCloseConfirmModal} >
                            No, Cancel
                        </Button>


                        {(action === "Activate") &&   <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                            disabled={selectedRows.length <= 0}
                            onClick={handleUnBlockUser}
                        >
                            Activate
                        </Button>
                        }
                        {(action === "Block") &&  <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                            disabled={selectedRows.length <= 0}
                            onClick={handleBlockUser}
                        >
                            Block
                        </Button>
                        }

                        {(action === "Delete") && 
                        <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                            disabled={selectedRows.length <= 0}
                            onClick={handleDelete}
                        >
                            Yes, {action}
                        </Button>
                        }
























                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EditBlockModel;
