import React from "react";
import Box from "@mui/material/Box";
import { textClasses } from "../../../../../static/styles/theme";
import playIcon from "../../../../../static/images/Icon/createAutomation/play_icon.svg";
import plusCreateIcon from "../../../../../static/images/Icon/createAutomation/plusCreateIcon.svg";
import okMenuCreate from "../../../../../static/images/Icon/createAutomation/okMenuCreate.svg";
import notAcceptedjob from "../../../../../static/images/Icon/createAutomation/notAcceptedjob.svg";
import persondisabled from "../../../../../static/images/Icon/createAutomation/persondisabled.svg";
import { Grid, Typography } from "@mui/material";
import CreateAutomationCard from "./CreateAutomationCard";
import { JOB_CREATION_STEP } from "../constant";

const AutomationTriggers = ({ setStep, setCreationStep }) => {
  return (
    <Grid
      sx={{
        minHeight: "725px",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        border: "1px solid #E0E0E0",
        backgroundColor: "white",
        boxShadow: "0px 3px 5px #D9D9D9",
      }}
    >
      <Box
        sx={{
          paddingLeft: "20px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={1}>
          <img src={playIcon} />
          <Typography
            sx={{
              fontFamily: "Nunito",
              ...textClasses.boldText,
              marginLeft: "10px",
              color: "#000000",
            }}
          >
            Select A Trigger
          </Typography>
        </Grid>
        <Typography
          sx={{
            fontFamily: "Nunito",
            ...textClasses.t12n,
            color: "#827878",
            marginLeft: "20px",
            letterSpacing: "0em",
          }}
        >
          Triggers start the execution of a rule. Triggers can listen for events
          or be scheduled to run.
        </Typography>
      </Box>
      <Box
        sx={{
          paddingLeft: "40px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            ...textClasses.boldText,
            color: "#000000",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          A. Job Automation Triggers{" "}
        </Typography>
        <Grid
          container
          spacing={0}
          sx={{
            gap: "20px",
          }}
        >
          <CreateAutomationCard
            testId="jobsCard"
            width='287px'
            text="When a Job is created"
            subText="Rule is run when a new Job or Task created by users or automatically created"
            icon={plusCreateIcon}
            handleJobCreate={() => {
                setStep(JOB_CREATION_STEP)
                setCreationStep('')
            }}
          />
          <CreateAutomationCard
            width='287px'
            text="When A New Job Completed"
            subText="Rule is run when a new Job marked as completed by users "
            icon={okMenuCreate}
          />
          <CreateAutomationCard
            width='287px'
            text="When A Job Not Accepted "
            subText="Rule is run when a new Job not accepted by users"
            icon={notAcceptedjob}
          />
          <CreateAutomationCard
            width='287px'
            text="When A  Job Rejected"
            subText="Rule is run when a new Job or Task rejected by users."
            icon={persondisabled}
          />
        </Grid>
        <Typography
          sx={{
            marginTop: "23px",
            marginBottom: "23px",
            ...textClasses.boldText,
            color: "#000000",
          }}
        >
          B. Data Entry Automation Triggers
        </Typography>
        <Grid
          container
          spacing={0}
          sx={{
            gap: "20px",
          }}
        >
          <CreateAutomationCard
            width='287px'
            text="When Inward Data Entry Submitted "
            subText="Rule is run when a new Job or Task rejected by users."
            icon={persondisabled}
          />
          <CreateAutomationCard
            width='287px'
            text="When Outward Data Entry Submitted "
            subText="Rule is run when a new Job or Task rejected by users."
            icon={persondisabled}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default AutomationTriggers;
