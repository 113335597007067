import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {  Button, Typography,Box } from '@mui/material'
import CustomModel from '../../Components/CustomModels/CustomModel1';

import { buttonClasses } from '../../../static/styles/theme';

function DeleteWorkflow(props) {
    const dispatch = useDispatch();
    const { open, hide, action ,item } = props
    const [workflowNamw, setWorkflowName] = useState('')
    useEffect(()=>{
        ////('item',item);
    },[item])
   
    return (
        <div data-testid='deleteModal' className='ppmodal' style={{maxWidth:'20%'}}>
            <CustomModel
                show={open}
                onClose={() => hide()}               
            >
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant='h6' sx={{ paddingLeft: "0px", fontSize: "20px", fontWeight: "600" }}>
                            Are You Sure ?
                        </Typography>
                        <Typography variant='h6' sx={{ paddingLeft: "0px", fontSize: "20px", fontWeight: "600" }}>
                            {action} {item.operationName} Workfow ?
                        </Typography>
                    </Box>
                    <Box padding={3} sx={{ display: "flex", justifyContent: "center", flexWrap:'wrap' }}>
                       
                       

                    </Box>
                    <Box mt={2} sx={{ display: "flex" }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => hide()}
                            sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 2, fontSize: ".7rem" }}

                        >Cancel</Button>
                        {(action === "Delete") && <Button
                            fullWidth
                            variant="outlined"
                            sx={{ ...buttonClasses.lynkitOrangeFill, ml: 2, fontSize: ".7rem" ,border:'none'}}
                            // onClick={() => handleDeleteUser()}
                        > Delete</Button>}
                    </Box>
                </Box>

            </CustomModel>
        </div>
    )
}

export default DeleteWorkflow;
