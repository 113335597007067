import React, { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
} from "@mui/material";
import "../Style/GridView.css";
import BinModel from "./BinModel";



let blockHeight=30
let blockWidth=45

function GridViewLayout(props) {
  const dispatch = useDispatch();
  const { viewAllocation, areaName = "", setVisibleCard ,analyticsBlockDetail,utilizationTypeValue,floorInfo} = props;
  const [selectedRack, setSelectedRack] = useState([]);
  const [areaArray, setAreaArray] = useState([]);
  const [showBinModel, setShowBinModel] = useState(false);
  const [selectedCell, setSelectedCell] = useState({});

  const colorHanlder = (cell_Data) => {
    if (cell_Data) {
      if (areaName && !cell_Data.subProductType.includes(areaName)) {
        return "grey";
      }
      if (cell_Data.status == "full") {
        return "red";
      } else if (cell_Data.status == "partial") {
        let utilizationInfo = analyticsBlockDetail?.find(dl => dl?.cellId === cell_Data?.cellId) || {};
        let percentage = 0;
        if (utilizationInfo[utilizationTypeValue] > 0) {
          percentage = (utilizationInfo[utilizationTypeValue] || 0)
        }
        percentage = Number(percentage || 0)
        if (percentage >= 100) {
          return "red";
        }
        return "yellow";
      } else if (cell_Data.status == "available") {
        return "green";
      } else {
        return "green";
      }
    } else {
      return "green";
    }
  };

  const colorAreaMappingHanlder = useCallback((cell_Data) => {
    if (cell_Data) {
      if (cell_Data.disable) {
        return "white";
      } else if (
        cell_Data.subProductType &&
        cell_Data.subProductType.length > 0 && 
        !selectedRack.find((dl) => dl.cellId == cell_Data.cellId)
      ) {
        return "yellow";
      } else {
        if (
          cell_Data.cellId &&
          selectedRack.find((dl) => dl.cellId == cell_Data.cellId)
        ) {
          return "grey";
        } else {
          return "green";
        }
      }
    } else {
      return "green";
    }
  }, [selectedRack])

  const getBlockByIndex = (rowIndex, colIndex) => {
    if (props.gridCellDetails && props.gridCellDetails.length) {
      const result = props.gridCellDetails.find(
        (el) => el.rowIndex == rowIndex && el.colIndex == colIndex
      );
      // //('data1', result)
      return props.gridCellDetails.find(
        (el) => el.rowIndex == rowIndex && el.colIndex == colIndex
      );
    }
  };

  const getStyleForBlock = (i, j, data) => {
    let tempObj = { color: data.fontColor ? data.fontColor : "#000" };
    if (i === props.floorInfo.row) {
      tempObj["borderBottomWidth"] = "0px";
    }
    if (j === props.floorInfo.col) {
      tempObj["borderRightWidth"] = "0px";
    } else {
      tempObj["cursor"] = "pointer";
    }
    tempObj["backgroundColor"] = "#FFF";
    tempObj["padding"] = "10px 1px";
    let allType=["space"]
    if(viewAllocation){
      allType.push("stagingArea")
    }
    if (!allType.includes(data.type)) {
      if (!props.area || props.area.length == 0) {
        tempObj["backgroundColor"] = viewAllocation
          ? colorAreaMappingHanlder(data)
          : colorHanlder(data);
        tempObj["cursor"] = viewAllocation ? "pointer" : 
          (!data.isRackExist &&
            data.status == "available" &&
            data?.subProductType?.length > 0 &&
            "not-allowed") ||
          "pointer";
      } else {
        let bgColor = "grey";
        if (
          props.area &&
          props.area.length &&
          props.area.includes(data.cellId)
        ) {
          bgColor = colorHanlder(data);
        }
        tempObj["backgroundColor"] = bgColor;
      }
    }
    // //("data.bgColor====", data.bgColor ? data.bgColor : "white" )
    // let colorCode = hexToRgba(data.bgColor ? data.bgColor : "#FFFFFF", 0.8)
    if (allType.includes(data.type)) {
      tempObj["backgroundColor"] = data.bgColor ? data.bgColor : "white";
      tempObj["cursor"] = "not-allowed";
      tempObj["border"] = "none";
      // tempObj['border'] = (data.bgColor === "white") ? "none" : `1px solid ${data.bgColor}`;
    }

    tempObj["height"] = blockHeight+"px";
    tempObj["width"] = blockWidth+"px";
    if(data?.mergeColumn && Number(data?.mergeColumn) > 0){
      tempObj["width"] =(blockWidth*Number(data?.mergeColumn))+"px";
    }
    if(data?.mergeRows && Number(data?.mergeRows) > 0){
      tempObj["height"] =(blockHeight*Number(data?.mergeRows))+"px";
    }
    return tempObj;
  };

  const onhandleRack = (gridBlock) => {
    setSelectedCell(gridBlock);
    if (gridBlock.disable || gridBlock.type == "space") {
      if (setVisibleCard) setVisibleCard("");
      return;
    }
     else if (
      !gridBlock.isRackExist &&
      gridBlock.status == "available" &&
      gridBlock?.subProductType?.length > 0
    ) {
      if (setVisibleCard) setVisibleCard("");
      return;
    }

    if (gridBlock.isRackExist) {
      props.showWarehouseModal(gridBlock.cellId);
    } else {
      if (gridBlock.type=="stagingArea" || gridBlock.status == "full" || gridBlock.status == "partial") {
        setShowBinModel(true);
      } 
      else {
        props.showWarehouseModal(gridBlock.cellId);
      }
    }
  };

  const onhandleViewAllocation = (gridBlock) => {
    let tempArray = [...selectedRack]; // Clone to avoid direct mutation
    let areaArr = [...areaArray]; // Clone to avoid direct mutation
    let blockObj = { [gridBlock.cellId]: gridBlock.col * gridBlock.row };
  
    let obj = {
      cellId: gridBlock.cellId,
      renderId: Date.now(),
      selectType: gridBlock.isRackExist ? "rack" : "block",
      data: [{
        materialType: "",
        rackId: [],
      }],
      actionType: gridBlock.disable || gridBlock.subProductType.length > 0 ? "update" : "addNew",
      name: gridBlock.name,
      isRackExist: gridBlock.isRackExist,
      oldMaterialType: gridBlock?.subProductType ?? [],
    };
  
    const existingIndex = tempArray.findIndex(item => item.cellId === gridBlock.cellId);
  
    if (existingIndex === -1) {
      tempArray.push(obj);
      areaArr.push(blockObj);
    } else {
      tempArray.splice(existingIndex, 1);
      const areaIndex = areaArr.findIndex(item => item.cellId === gridBlock.cellId);
      if (areaIndex !== -1) {
        areaArr.splice(areaIndex, 1);
      }
    }
  
    setSelectedRack(tempArray);
    setAreaArray(areaArr);
  
    const finalObj = {
      type: "wms",
      areaTypeData: tempArray,
      floorId: gridBlock.floorId,
      warehouseId: gridBlock.warehouseId,
      blockarea: areaArr,
    };
    props.addSelectedRack(finalObj);
  };
  const calculateCellUtilization = (cellId) => {
    let utilizationInfo = analyticsBlockDetail?.find(dl => dl?.cellId === cellId);
    if (!utilizationInfo) {
      return ""
    }
    let percentage = 0
    if (utilizationInfo[utilizationTypeValue] > 0) {
      percentage = (utilizationInfo[utilizationTypeValue] || 0)
    }
    percentage = (percentage || 0)+""
    if(percentage.split(".").length>1 && percentage.split(".")[1]==0){
      percentage=percentage.split(".")[0]
    }
    return <Typography>
      {percentage}% 
    </Typography>
  }

  useEffect(() => {
    setSelectedRack(props.selectedRackArr);
  }, [props.selectedRackArr]);

  return (
    <>
      <Box data-testid="gridView">
        <div
          className="gx-flex-column  gx-mt-3"
          sx={{
            overflowX: "scroll",
            minWidth: "100%",
          }}
        >
          <div
            className={`grid-section gx-flex-row gx-justify-content gx-align-items-start`}
            style={{ justifyContent: "space-evenly" }}
          >
            <table className="gridTable" data-testid="gridTable">
              {Array.from(
                { length: props.floorInfo.row ? props.floorInfo.row : 0 },
                (_, rowIndex) => {
                  return (
                    <tr className="gridRow" key={"blr-" + rowIndex}>
                      {Array.from(
                        {
                          length: props.floorInfo.col ? props.floorInfo.col : 0,
                        },
                        (_, colIndex) => {
                          let gridBlock = getBlockByIndex(rowIndex, colIndex);
                          if (gridBlock && !gridBlock.disable) {
                            return (
                              <td
                                className={`gridItem ${
                                  gridBlock.type == "space" ? "space_area" : ""
                                } `}
                                key={"blc-" + colIndex}
                                onClick={() => {
                                  if(setVisibleCard)setVisibleCard("")
                                  if (["block", "stagingArea"].includes(gridBlock?.type)) {
                                    if (!viewAllocation) {
                                      onhandleRack(gridBlock)
                                    }
                                    else if (viewAllocation && gridBlock.type == "block") {
                                      onhandleViewAllocation(gridBlock)
                                    }
                                  }
                                }
                                }
                                rowSpan={
                                  gridBlock.mergeRows
                                    ? Number(gridBlock.mergeRows)
                                    : ""
                                }
                                colSpan={
                                  gridBlock.mergeColumn
                                    ? Number(gridBlock.mergeColumn)
                                    : ""
                                }
                                style={getStyleForBlock(
                                  rowIndex,
                                  colIndex,
                                  gridBlock
                                )}
                              >
                                {gridBlock.name || ""}
                                {gridBlock?.type=="block" && floorInfo?.isCapacityDefined == true && utilizationTypeValue
                                  ? calculateCellUtilization( gridBlock.cellId)
                                  : ""
                                }
                                {/* {gridBlock.type == 'space' ? "" : (gridBlock.name || "")} */}
                              </td>
                            );
                          } else {
                            return "";
                          }
                        }
                      )}
                    </tr>
                  );
                }
              )}
            </table>
          </div>
        </div>
        {showBinModel && (
          <BinModel
            showBinModel={showBinModel}
            setShowBinModel={setShowBinModel}
            selectedCell={selectedCell}
          />
        )}
      </Box>
    </>
  );
}

export default GridViewLayout;
