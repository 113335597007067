import { dateTimeFormater,deepCopy_Object } from "../../../services/functions"


export const StockTranferState={
    transferFromWarehouse:{}, transferToCategory : {},
    //for warehouse
    transferToWarehouse : {}, transferToFloor : {},
    //for Intenal User
    transferToInternalUser : {},
    //for externaluser
    name : "",email : "",mobile : "",address : ""
}

export const templateState={
    visible:false,
    templateOption:{},
    templateType:"",
    title:""
}
export const initialCountFor_SPI = {
    countFor_PKG_Item: {},
    countFor_Shipment_Item: {},
    countFor_Shipment_PKG: {}
}

export const ShipmentFilter = [
    {
        label: "First In First Out",
        value: "FIFO",
    },
    {
        label: "Last In First Out",
        value: "LIFO",
    },
    {
        label: "Date",
        value: "date",
    },
    {
        label: "Date Range",
        value: "dateRange",
    },
    {
        label: "First Expiry First Out",
        value: "FEFO",
    },
]


export const getLabelKeyForJobConfig = (jobProcessConfig, get_for = "shipment", info = "label", data = {}, skip = [],log=false) => {

   
    if (get_for == "item") {
        if (info == "key") return renderOption(data, jobProcessConfig?.itemVisibility?.itemVisibilityKey || [], skip,log)
        // if(info=="key") return jobProcessConfig?.itemVisibility?.itemVisibilityKey || "itemId"
        else return jobProcessConfig?.itemVisibility?.itemVisibilityLabel || "Item ID"
    }
    else if (get_for == "package") {
        if (info == "key") return renderOption(data, jobProcessConfig?.packageVisibility?.packageVisibilityKey || [], skip,log)
        // if(info=="key") return jobProcessConfig?.packageVisibility?.packageVisibilityKey || "packageId"
        else return jobProcessConfig?.packageVisibility?.packageVisibilityLabel || "PackageID"
    }
    else {
        if (info == "key") return renderOption(data, jobProcessConfig?.shipmentVisibility?.shipmentVisibilityKey || [], skip,log)
        // if(info=="key") return jobProcessConfig?.shipmentVisibility?.shipmentVisibilityKey || "shipmentId"
        else return jobProcessConfig?.shipmentVisibility?.shipmentVisibilityLabel || "Shipment ID"
    }
}

const renderOption=(item,renderOptions,skip=[],log=false)=>{
  
    let str=''
    if(renderOptions && renderOptions.length){
        for(let d of renderOptions){
            // if(log)console.log(skip,"skip",d)
            if(skip?.length>0 && skip.includes(d.key)){
                continue;
            }
            let value=getPathValue(item,d)
            if(value){
                if(d.formatDate){
                    value=dateTimeFormater(value,d.format)
                }
                let tempstr=""
                if(d.label){
                    if(d.label.includes("$")){
                        let str1=d.label.slice(1)
                        d.label=item[str1]
                        if(!d.label){
                            for(let key of d.keys){
                                let newkey=key.split(".")[0]
                                if(item[newkey]){
                                    d.label=newkey
                                    break;
                                }
                            }
                        }
                    }
                    tempstr+=d.label+" : "
                }
                tempstr+=value
                if(d.prefix) tempstr=d.prefix+tempstr
                if(d.postfix) tempstr+=d.postfix
                if(d.uppercase){
                    tempstr=tempstr.toUpperCase()
                }
                str+=tempstr
            }
        }
    }

    function getPathValue(data,dd){
        let value=data;
        if(dd.multiple){
            let val_exists=false
            for(let c of dd.keys){
                let val=getPathValueInternal(c,value)
                if(val){
                    val_exists=true
                    value=val
                    break;
                }
            }
            if(!val_exists){
                value=null
            }
        }
        else{
            value=getPathValueInternal(dd.key,value)
        }
        return value || null

        function getPathValueInternal(path,value){
            let path_array=path?path.split("."):[]
            // if(log)console.log(skip,"skip",path)
            for(let d of path_array){
                
                if(d.includes("$")){
                    let tmpstr=d.slice(1)
                    d=item[tmpstr]
                }
                if(value[d]){
                    value=value[d]
                }
                else{
                    value=null
                    break;
                }
            }
            return value
        }
    }
    return str || 'NA'
  }
 
  export const reasonComponent = (type)=>{
    return {
        "_id": "selectReason",
        "type": "select",
        "label": "Select Reason",
        "description": "Select Reason",
        "placeholder": "Select Reason",
        "elementType": "Selection Type",
        "value": "",
        "dynamic": true,
        "auth": true,
        "keyForValue": "reason",
        "keyForLabel": "reason",
        "extraValue":["_id"],
        "apiDomain": "",
        "baseURL":"",
        "apiEndPoint": "inventoryAdjustment/getAllAdjustmentReasons",
        "apiMethod": "post",
        "name": "Reason List",
        "apiDataReturnKey": "data",
        "apiParameters": [
            {
                "label": "type",
                "value": type
            },
            {
                "label": "page",
                "value": 1
            },
            {
                "label": "limit",
                "value": 15
            },
            {
                "label": "reason",
                "value": "onSearch"
            }
        ],
        "setTargetValue": false,
      }
  }

export const inventoryTypes = [
    { "label": "Shipment ID", "value": "shipmentId"},
    { "label": "Package ID", "value": "packageId" },
    { "label": "Item ID", "value": "itemId" },
]

export const inventoryComponent = (jobComponentId, invType, filterID) => {
    const inventoryComponent = {


        "_id": "selectInventory",
        "type": "select",
        "label": "Select Inventory",
        "description": "Select Inventory",
        "placeholder": "Select Inventory",
        "elementType": "Selection Type",
        "value": "",
        "dynamic": true,
        "auth": true,
        "keyForValue": "inventoryID",
        "keyForLabel": "inventoryID",
        "extraValue": ["inventoryID","count"],
        "apiDomain": "",
        "baseURL": "",
        "apiEndPoint": "job/getJobInvForSelection",
        "apiMethod": "post",
        "name": "Inventory List",
        "apiDataReturnKey": "data",
        dontFetch:true,
        "apiParameters": [
            {
                "label": "jobComponentId",
                "value": jobComponentId
            },
            {
                "label": "filterID",
                "value": filterID
            },
            {
                "label": "page",
                "value": 1
            },
            {
                "label": "limit",
                "value": 15
            },
            {
                "label": "searchValue",
                "value": "onSearch"
            },
            {
                "label": "inventoryType",
                "value": invType
            }
        ],
        "setTargetValue": false,
    }
    return inventoryComponent;
}

  export const locationComponent =(jobComponentId="",invetoryType="",inventoryId="")=> {
    const locationComponent ={
    "_id": "selectLocation",
    "type": "select",
    "label": "Select Location",
    "description": "Select Location",
    "placeholder": "Select Location",
    "elementType": "Selection Type",
    "value": "",
    "dynamic": true,
    "auth": true,
    "keyForValue": "name",
    "keyForLabel": "name",
    "extraValue":["cellId","rackId","name"],
    "apiDomain": "",
    "baseURL":"",
    "apiEndPoint": "job/getAssociatedJobLocations",
    "apiMethod": "post",
    "name": "Location List",
    "apiDataReturnKey": "data",
    dontFetch:true,
    "apiParameters": [
        {
            "label": "jobComponentId",
            "value": jobComponentId
        },
        {
            "label": "inventoryType",
            "value": invetoryType
        },
        {
            "label": "inventoryId",
            "value": inventoryId
        },
        {
            "label": "page",
            "value": 1
        },
        {
            "label": "limit",
            "value": 25
        },
        {
            "label": "cellName",
            "value": "onSearch"
        }
    ],
    "setTargetValue": false,

}
return locationComponent;
  }

export const checkboxChangeSameForALL = (index, addedItems) => {
    let addedItemsCopy = deepCopy_Object(addedItems);
    let ratioObj = addedItems[index]?.cellDetail?.reduce((acc, currVal) => {
        let tempObj = acc;
        tempObj[currVal?.rackId ? currVal?.rackId : currVal?.cellId] = (currVal?.count / addedItems[index]?.selectedQTY) * 100;
        return tempObj;
    }, {});

    const newItems = [...addedItems];
    let filteredItems = newItems.filter((_, ind) => ind > index);
    for (let item of filteredItems) {
        item.cellDetail = [];
        let isIncluded = addedItemsCopy.some(copyItem =>
            (copyItem?.rackId && copyItem?.rackId === item?.rackId) ||
            (copyItem?.cellId && copyItem?.cellId === item?.cellId)
        );

        if (isIncluded) {
            if (addedItems[index]?.selectedQTY == item?.selectedQTY) {
                item.cellDetail = addedItemsCopy[index]?.cellDetail || [];
            } else {
                let totalConsumedCount = 0;
                let convertedCellDetail = deepCopy_Object([...addedItemsCopy[index]?.cellDetail])?.map(dl => {
                    let boxValue = (item?.selectedQTY * ratioObj[dl?.rackId ? dl?.rackId : dl?.cellId]) / 100;
                    if ((boxValue + "").includes(".")) {
                        boxValue = Number((boxValue + "")?.split(".")?.at(0) || 0);
                    }
                    dl.count = boxValue;
                    totalConsumedCount += boxValue;
                    return dl;
                });
                item.cellDetail = convertedCellDetail?.filter(dl => dl?.count > 0);
                if (item?.cellDetail?.length > 0 && totalConsumedCount < item?.selectedQTY) {
                    item.cellDetail.at(0).count = item?.cellDetail?.at(0)?.count + item?.selectedQTY - totalConsumedCount;
                } else if (item?.cellDetail?.length == 0) {
                    item.cellDetail = [{ ...addedItemsCopy[index]?.cellDetail?.at(0), count: item?.selectedQTY }];
                }
            }
        }
    }

    return [...addedItems.filter((_, ind) => ind <= index), ...filteredItems];
};