import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect } from "react";
import { textClasses, buttonClasses } from "../../../../static/styles/theme";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import TemplatesTable from "../../Templates/TemplateTable";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import theme from "../../../../static/styles/theme";
import { Menu, MenuItem } from "@mui/material";
import { automationHeader, automationTableDataInward,automationTableDataOutward,automationTableDataInventory } from "./constant";
import GenericToggle from "../../../Components/GenericToggle" ;

const AutomationRule = () => {
  const history = useHistory();
  const [tableData,setTableData]=useState(automationTableDataInward || []);
  const [tabIndex, setTabIndex] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  useEffect(() => {
    ////("status filter changed", statusFilter);
  }, [statusFilter]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (e, value) => {
    switch (value) {
      case 0:
        setStatusFilter({ label: "Inward-Operation-Rules", value: "inward" });
        setTableData(automationTableDataInward)
        break;
      case 1:
        setStatusFilter({ label: "Outward-Operation-Rules", value: "outward" });
        setTableData(automationTableDataOutward)
        break;
      case 2:
        setStatusFilter({
          label: "Inventory-Movement-Rules",
          value: "inventoryMovement",
        });
        setTableData(automationTableDataInventory)
        break;
    }
    setPage(0);
    setTabIndex(value);
  };

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActionIndex(null);
  };
  const automationRow =
  tableData.length > 0
      ? tableData.map((row, index) => {
          const item = [];
          item.push(
            <Typography
              sx={{
                color: " #FF7200",
                fontFamily: "Nunito",
                ...textClasses.normalText,
              }}
            >
              {row.Name}
            </Typography>
          );
          item.push(
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
            >
              {row.Details}
            </Typography>
          );
          item.push(
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
            >
              {row.WorkFlow}
            </Typography>
          );
          item.push(
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
            >
              {row.CreatedBy}
            </Typography>
          );
          item.push(
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
            >
              {row.UpdatedAt}
            </Typography>
          );
          item.push(<GenericToggle isEnable={true}/>);
          // item.push(
          //   <Typography
          //     data-testid="dropdown"
          //     sx={{
          //       ...textClasses.t13n,
          //       color: theme.themeOrange,
          //       cursor: "pointer",
          //     }}
          //     onClick={(e) => handleOpenAction(e, index)}
          //   >
          //     Action <KeyboardArrowDown />
          //   </Typography>
          // );
          return item;
        })
      : "";

  return (
    <>
      <Grid container data-testid="automation-rules">
        <Grid
          sx={{
            fontSize: "12px",
            fontWeight: "400",
          }}
          item
          sm={4}
        >
          <Typography
            variant="h6"
            sx={{ ...textClasses.cardTitle, fontFamily: "Nunito" }}
          >
            Automation Rules
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              sx={{
                color: "#FF7200",
                ...textClasses.t12n,
              }}
              underline="hover"
              color="inherit"
              href=""
            >
              Setting
            </Link>
            <Typography
              sx={{
                ...textClasses.normalText,
              }}
              color="text.primary"
            >
              AutomationRule
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container item sm={8} justifyContent="end" alignItems="end">
          <Button
            variant="contained"
            size="small"
            data-testid="create-rule"
            startIcon={<Add />}
            sx={{
              ...buttonClasses.lynkitOrangeFill,
            }}
            onClick={() => {
              history.push(
                "/dashboard/setting/automation-rules/CreateRuleAutomation"
              );
            }}
          >
            Create Rule
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: "0" }}>
        <Tabs
          onChange={handleTabChange}
          value={tabIndex}
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
            "& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
          }}
        >
          <Tab
            data-testid="Inward Operation Rules"
            sx={{
              ...textClasses.normalText,
              textTransform: "none",
            }}
            {...a11yProps(0)}
            label="Inward Operation Rules"
          />
          <Tab
            data-testid="Outward Operation Rules"
            sx={{
              ...textClasses.normalText,
              textTransform: "none",
            }}
            {...a11yProps(1)}
            label="Outward Operation Rules"
          />
          <Tab
            data-testid="Inventory Movement Rules"
            sx={{
              ...textClasses.normalText,
              textTransform: "none",
            }}
            {...a11yProps(2)}
            label="Inventory Movement Rules"
          />
        </Tabs>
      </Box>
      <Typography
        sx={{
          ...textClasses.t13n,
          margin: "10px 0 10px 0",
          fontFamily: "Nunito",
          fontStyle: "italic",
        }}
      >
        Focus on what’s important. Let automation do the rest.
      </Typography>
      <TemplatesTable
        header={automationHeader}
        rows={automationRow}
        totalRows={automationRow.length}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          <MenuItem key={1} onClick={handleClose}>
            Edit
          </MenuItem>
          <MenuItem key={2} onClick={handleClose}>
            View
          </MenuItem>
          <MenuItem key={4} onClick={handleClose}>
            Delete
          </MenuItem>
        </Menu>
      )}
    </>
  );
};
export default AutomationRule;
