import React, { useEffect, useState, useRef } from 'react'
import { Grid, Paper, Typography, Button, TextField, IconButton, ButtonGroup, Select, MenuItem, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, Box, InputAdornment, FormLabel, Switch, Card, CardHeader, CardContent, Checkbox } from '@mui/material';
import { Visibility, Delete, Download, Upload, Add, Remove, AddOutlined, FileUpload, Search, Sell, Close } from '@mui/icons-material';
import theme, { textClasses, buttonClasses, inputClasses } from '../../../../../static/styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFile, getItemMasterDetailForInward } from '../../../../../redux/actions';
import {  getInputFieldForTableRow } from '../../../../Components/Utils';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style'
// import * as xlsx from 'xlsx';
import { read, utils } from 'xlsx';
import moment from 'moment';
import { showToast } from '../../../../../services/functions';
import FileModal from '../../../EntityMaster/FileModal';
import { deepCopy_Object,validateFieldsMultiple } from '../../../../../services/functions';
import { handleDynamicChange,calculateTarget } from '../../../../../services/formFunction';
import { getItemPackageComponent } from './constant';


const ViewFileModal = ({showFileData, fileData, handleClose}) => {
    //// //("FILE DATA");
    //// //(fileData);
    const header = (fileData != null && fileData.length > 0) ? ["Sno"].concat(Object.keys(fileData[0])) : [];
    const rows = (() => {
        if(fileData){
            return fileData.map((row, i) => {
                let r = [];
                r.push(i+1);
                for (let j = 0; j < header.length; j++) {
                    if(["Sno", "__rowNum__"].indexOf(header[j]) < 0){
                        const e = row[header[j]];
                        r.push(e);
                    }
                    // //// //("Looking header " + header[j]);
                    
                }
                return r;
            })
        }
        return [];
    })();
    //// //("HEADER");
    //// //(header);

    //// //("ROWS");
    //// //(rows);
    return (
        <Dialog
            open={showFileData}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{sx: {width: "100%"}}}
            maxWidth="md"
        >
            <DialogContent dividers>
                <Typography sx={{...textClasses.cardTitle, mb: 1}}>Uploaded excel file data</Typography>
                <ExcelTable 
                    header={header}
                    rows={rows}
                />
                <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "100px", mt: 2}} onClick={handleClose}>
                    Close
                </Button>
            </DialogContent>
        </Dialog>
    )
}

const ExcelTable = ({header, rows}) => {
    return (
        <table
            style={{
                display: "block",
                maxWidth: "-moz-fit-content",
                maxWidth: "fit-content",
                margin: "0 auto",
                overflowX: "auto",
                whiteSpace: "nowrap"
            }}
        >
            <thead style={{backgroundColor: theme.themeGray}}>
                <tr className='roundedRow'>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}} key={i}>
                                    {
                                        head.includes("(") ? head.split("(")[0] : head
                                    }
                                </td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "200px", ...textClasses.boldText, textAlign: "center"}} key={i}>
                                    {
                                        head.includes("(") ? head.split("(")[0] : head
                                    }
                                </td>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{borderBottom: "1px solid #0000002B"}}   
                                >
                                    {
                                        row.map((col, j) => {
                                            return (
                                                // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                                    {col}
                                                </td>    
                                            )
                                        })
                                    }
                                
                                </tr>
                            ))
                        }  
                         
                    </>    
                }
            </tbody>
        </table>
    )
}


export default function ItemSection({template, pIndex, packageObj, packages, addItems, handleCloseModal, setIsItemClicked, idSettings, asn}) {

    const dispatch = useDispatch();
    const objectTemplateDetails = template?.otherTemplate?.filter(ot => ot.templateType == `itemDetailTemplate`);

    const mappingLocations = useSelector(state => state.inward.allMapping);
    const allUom = useSelector(state => state.uom.allUom);
    const allFields = objectTemplateDetails?.length > 0 ? (objectTemplateDetails[0]?.attributes  || []): []; 

    const sectionIndex = 1;
    const name = "Item Details"
    
    const [fileLoading, setFileLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef();
    const [showFileData, setShowFileData] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [shellState, setShellState] = useState(false);
    const [isManual, setIsManual] = useState(true);
    const [apiDataIndex, setApiDataIndex] = useState(null);
    const [completeFields, setCompleteFields] = useState([]);

    const [items, setItems] = useState(packageObj?.itemDetail || []);
    const [errors, setErrors] = useState({})
    const countTillNow = items.map((i) => Number(i.count)).reduce((a, b) => a+b , 0)

    const dimensions = useSelector(state => state.inventory.parcelgetDimensions);


    let generateFormFields=(fieldAll=[],UOMList=[])=>{
        let tempFeilds=deepCopy_Object(fieldAll)
        let mappingAreaField = {
            type: "select",
            label: "Mapping Area",
            required: true,
            _id: "mappingArea",
            values: mappingLocations || [],
            defaultValue: "PUT IT ANY WHERE"
        }    
    
        let uomIndex = tempFeilds.map(f => f._id).indexOf("UOM");
        if(uomIndex >= 0){
            tempFeilds[uomIndex].values = UOMList.map(u => ({
                label: u.unitName,
                value: u.unitValue
            }));
        }
        else{
            let field = {
                "_id": "UOM",
                "type": "select",
                "label": "Unit Of Measurement (UOM)",
                "description": "unit of measurements",
                "placeholder": "select Unit",
                "values": UOMList.map(u => ({
                    label: u.unitName,
                    value: u.unitValue
                })),
                "required": true,
                "forType": "all",
                "elementType": "Selection Type",
                "sortType": 2,
            }
            tempFeilds.push(field);
        }
    

        if(idSettings && idSettings.inputType.manual.status){
            // If true
            let numRegex = "^[0-9]*$"
            let alphaRegex = "^[a-zA-Z0-9]+$"
            let fieldI = tempFeilds.map(f => f._id).indexOf("customItem")
            
            
            if(fieldI >= 0){
                // Find customId field and update regex
                tempFeilds[fieldI] = {
                    ...tempFeilds[fieldI],
                    regex: idSettings.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
            }
            else{
                // If doesnt exist in template . Add Custom Shipment Field
                let field = {
                    type: "input",
                    label: "Custom Item Id",
                    _id: "customItem",
                    required: true,
                    placeholder: "Enter your item id",
                    regex: idSettings.idPrefix.numberSeries.status ? numRegex : alphaRegex
                }
                
                tempFeilds = [field].concat(tempFeilds);
            }
    
        }
        else{
            // remove from list
            let fieldI = tempFeilds.map(f => f._id).indexOf("customItem");
            if(fieldI >= 0){
                tempFeilds.splice(fieldI, 1);
            }
        }
        
        tempFeilds = tempFeilds.concat([mappingAreaField])
       return tempFeilds
    }
   

    const convertDocsBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const handleFileChange = async (e) => {
        //// //(e.target.files[0]);
        // const file = await convertDocsBase64(e.target.files[0])
        setSelectedFile(e.target.files[0]);
    }

    const getTableHeader = (headerType) => {
        let base = ["Count"]
        let otherHeader = completeFields.filter(f => f._id != "count").map((of) => {
            let label = of.label;
            if(headerType == "excel" && ["select", "radio", "checkbox"].indexOf(of.type) >= 0 && of.values != null && Array.isArray(of.values)){
                label = `${label} (${of.values.map(o => o.value).join(",")})`
            }
            return label
        })
        return [...base, ...otherHeader];
    }

    const getLabelToIdMapping = () => {
        let mapping = {
            "Count": "count"
        }

        for (let index = 0; index < completeFields.length; index++) {
            if(completeFields[index]._id != "count"){
                let label = completeFields[index].label;
                if(["select", "radio", "checkbox"].indexOf(completeFields[index].type) >= 0 && completeFields[index].values != null && Array.isArray(completeFields[index].values)){
                    label = `${label} (${completeFields[index].values.map(o => o.value).join(",")})`
                }
                mapping[label] = completeFields[index]._id;
            }
        }
        return mapping;
    }

    const handleShowFileModal = () => {
        setShowFileData(true);
    }

    const handleCoseFileModal = () => {
        setShowFileData(false);
    }

    const handleCloseDetailDialog = () => {
        setApiDataIndex(null);
    }

    const handleDeleteFile = () => {
        setShellState(Math.random().toString(36));
        setSelectedFile(null);
        setFileData([]);
        setShowFileData(false);
    }

    const matchFileHeader = (fileHeader, uploadedHeader) => {
        const diff = uploadedHeader.filter( h => !fileHeader.includes(h));
        if(diff.length > 0){
            return false;
        }
        return true;
    }

    const getAndValidateField = (mappedField, raw) => {
        //// //("Found Excel Field", mappedField);
        //// //("With Excel Data", raw);
        if(mappedField._id == "UOM"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "date"){
            let newFormat = (raw && raw != "") ? moment(raw).format("YYYY-MM-DD") : null
            if(!mappedField.required){
                return [newFormat, true, ""]
            }
            if(mappedField.required && newFormat){
                return [newFormat, true, ""]
            }
            
            else{
                return [newFormat, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(["input", "number", "textarea"].indexOf(mappedField.type) >= 0){
            if(!mappedField.required){
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else if(mappedField.required && raw && raw != ""){
                if(mappedField.regex && !(new RegExp(mappedField.regex)).test(raw)){
                    return [raw, false, `Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            } 
        }
        else if(mappedField.type == "file"){
            if(!mappedField.required){
                return [raw, true, ""]
            }
            if(mappedField.required && raw && raw != ""){
                return [raw, true, ""]
            }
            else{
                return [raw, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "checkbox"){
            let options = raw.split(",").map(o => o.trim());
            let invalidOptions = options.filter(o => (o != "" && mappedField.values.map( m => m.value).indexOf(o) < 0));
            if(invalidOptions.length > 0){
                return [options, false, `File Error: Invalid ${mappedField.label} values passed`]
            }
            if(!mappedField.required){
                return [options, true, ""]
            }
            else if(mappedField.required && options.length > 0){
                return [options, true, ""]
            }
            else{
                return [options, false, `File Error: ${mappedField.label} is required`]
            }
        }
        else if(mappedField.type == "radio" || mappedField.type == "select"){
            let pos = mappedField.values.map(m => m.value).indexOf(raw);
            if(mappedField.required){
                if(raw == "" && pos < 0){
                    return [raw, false, `File Error: ${mappedField.label} is required`]
                }
                else if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
            else{
                if(raw != "" && pos < 0){
                    return [raw, false, `File Error: Invalid value for ${mappedField.label}`]
                }
                return [raw, true, ""]
            }
        }
    }

    const handleUploadFile = (upload_type) => {
        //// //("Selected File", selectedFile);
        const file = selectedFile;
        const reader =  new FileReader();
        setFileLoading(true);

        reader.onerror = (e) => {
            handleDeleteFile();
            setFileLoading(false);
            showToast("File Error: Error reading file. Please select again", true);
        }

        reader.onabort = () => {
            //// //("aborted");   
        }

        reader.onload = (e) => {
            const wb = read(e.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
                const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {raw: false});
                if (rows.length == 0) {
                    showToast("Cannot upload blank file", true)
                    setFileLoading(false);
                    reader.abort();
                    return;
                }
                const labelToIdMap = getLabelToIdMapping();
                const tHeader = getTableHeader("excel");

                let items = []

                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    if(!matchFileHeader(tHeader, Object.keys(row))){
                        showToast("File header doesnt match. Please re-upload with correct header", true)
                        setFileLoading(false);
                        reader.abort();
                        return;
                    }
                    else{
                        let obj = {}
                        for (let j = 0; j < tHeader.length; j++) {
                            let mappedField = completeFields.filter((of) => of._id == labelToIdMap[tHeader[j]])
                            if(mappedField.length > 0){
                                mappedField = mappedField[0];
                                const [cleanValue, status, errorMessage] = getAndValidateField(mappedField, rows[i][tHeader[j]]);
                                if(status){
                                    obj[labelToIdMap[tHeader[j]]] = cleanValue
                                }
                                else{
                                    showToast(errorMessage, true);
                                    setFileLoading(false);
                                    reader.abort();
                                    return;
                                }
                            }
                            else{
                                obj[labelToIdMap[tHeader[j]]] = rows[i][tHeader[j]]
                            }
                        }
                        // if(!obj.count){
                        //     obj.count = 1
                        // }
                        if(obj.count > 1){
                            obj.USN_Value = "Not Applicable"
                        }
                        items.push(obj)
                    }
                    
                }
                // setFileData(rows); 
                if(upload_type == "view"){
                    setFileData(rows)
                    setShowFileData(true);
                }
                else{
                    let newItemsCount = items.map((i) => Number(i.count)).reduce((a, b) => a + b, 0);
                    if(newItemsCount + countTillNow > packageObj.count){
                        showToast(`File Error: Total items cannot exceed ${packageObj.count}`, true)
                    }
                    else{
                        addItems(pIndex, items);
                        handleCloseModal();
                    }
                }
                
            }
            setFileLoading(false);
        }
        if(file){
            reader.readAsArrayBuffer(file);
        }
        else{
            showToast("File Error: Something wrong with file")
        }
    }
    
    const handleGenericChange = async (event, i, fieldId) => {
        const targetField = completeFields.find(f => f._id == fieldId) || {};
        let oldItems = [...items];
        let oldObject = {...oldItems[i]};
        if(targetField.type == "file"){
            const file = await convertDocsBase64(event.target.files[0])
            oldObject[fieldId] = file;
        }
        else if(targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if(event.checked){
                oldValues.push(event.value);
            }
            else{
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }
        else{
            oldObject[fieldId] = event.target.value;
        }

        if (targetField.type && ["select"].includes(targetField.type) && targetField.setTargetValue) {
            if (event.target.value) {
                await handleDynamicChange(oldObject, targetField, {value:event.target.value}, "itemDetail",["tableHeader"])
            }
            else {
                calculateTarget(oldObject, targetField, {})
                oldObject.disabled=[]
                oldObject.itemDetail=null
                oldObject.tableHeader=[]
            }
            oldObject.fetchedFromItemMaster = false

            if(oldObject?.itemDetail?.formData && Object.keys(oldObject?.itemDetail?.formData).length > 0){
                oldObject.fetchedFromItemMaster=true
            }
            if (oldObject?.itemDetail?.mappingArea?.length) {
                oldObject["restrictedMappingArea"] = oldObject?.itemDetail?.mappingArea || []
                oldObject["mappingArea"] = oldObject?.itemDetail?.mappingArea?.at(0) || ""
            }
        }

        oldItems[i] = {...oldObject};
        setItems([...oldItems]);
    }

    const autoFillOnFocus = (index) => {
        if(dimensions){
            let oldItems = [...items];
            for (let i = 0; i < completeFields.length; i++) {
                let compId = completeFields[i]._id
                if(["weight", "length", "breadth", "height"].indexOf(compId) >= 0 && dimensions[compId]){
                    oldItems[index][compId] = dimensions[compId];
                }
            }
            setItems(oldItems)
        }
    }

    const changeFieldDisableStatus = (fieldId, status) => {
        let fieldIndex = completeFields.map(f => f._id).indexOf(fieldId);
        if(fieldIndex >= 0){
            completeFields[fieldIndex].disable = status;
        }
    }

    const handleAdornmentSearchButton = (i) => {
        // let payload = {
        //     templateWise: false,
        //     fetch_for: "item",
        //     SKU_ID: items[i].SKU_Number
        // }
        let payload = {
            base_ID: items[i].SKU_Number,
            master_Type: "item-master",
            fetch_for: "item",
        }
        // setScanLoader(true);
        dispatch(getItemMasterDetailForInward(payload, (data) => {
            if(data && data.success){
                // setItemMasterDetail(data);
                let newItems = [...items];
                newItems[i].apiData = data
                newItems[i].SKU_Name = data.data.formData.SKU_Detail
                newItems[i].UOM = data.data.formData.UOM
                newItems[i].fetchedFromItemMaster = true
                if(data?.data?.mappingArea?.length){
                    newItems[i]["restrictedMappingArea"]=data?.data?.mappingArea || []
                    newItems[i]["mappingArea"]=data?.data?.mappingArea?.at(0) || ""
                }
                setItems(newItems);

            }
            else{
                showToast(data ? data.message: "Something went wrong", true)
                // setItemMasterDetail(null);
            }
        }))
        
    }

    const handleSaveForNext = (i, e) => {
        if(!items[i].mappingArea){
            showToast("Please select mapping area", true)
        }
        else if(items[i].mappingArea && e.target.checked){
            let oldItems = [...items]
            let object = {...oldItems[i]};
            let newItems = oldItems.map((oi, j) => {
                let ni = {...oi};
                if(j > i){
                    ni.mappingArea = object.mappingArea;
                }
                return ni;
            })
            setItems([...newItems]);
        }
    }

    const getItemRow = (i) => {
        let item = items[i];
        const adornmentForScan = (
            <InputAdornment position='end'>
                <IconButton
                    onClick={() => handleAdornmentSearchButton(i)}
                    disabled={item.SKU_Number.length < 2}
                >
                    <Search/>
                </IconButton>
            </InputAdornment>
        )
        let base = [
            (
                <ButtonGroup size='small' sx={{minWidth: "24px", ".MuiButtonGroup-grouped": {minWidth: "25px"}}}>
                    <Button key="one" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} onClick={() => handleItemCount(i, -1)}>
                        <Remove fontSize='inherit'/>
                    </Button>
                    {/* <Button key="two" disabled size='small' sx={{...buttonClasses.smallOrange, border: "1px solid #FF2700", backgroundColor: "white", fontSize: "12px"}}>
                        {items[i].count}
                    </Button> */}
                    <input
                        type="number"
                        style={{border: "1px solid #D9D9D9", maxWidth: "60px", fontSize: "12px", textAlign: "center"}}
                        value={items[i].count || null}
                        onChange={(e) => handleItemCount(i, e)}
                        onWheel={(event) => event.target.blur()}
                        
                        onKeyDown={(e) => {
                            if(['e', 'E', '+', '-'].includes(e.key)){
                                e.preventDefault();
                            }
                            else if(e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)){
                                e.preventDefault();
                            }
                        }}
                    />
                    <Button key="three" size='small' sx={{...buttonClasses.smallOrange, fontSize: "12px"}} onClick={() => handleItemCount(i, 1)}>
                        <Add fontSize='inherit'/>
                    </Button>
                </ButtonGroup>
            )
        ]

        const others = completeFields.filter( f => f._id != "count").map((of) => {
            let component = {...of};
            if(of._id == "USN_Value"){
                component.disabled = items[i].count > 1
            }
            else if(items[i]?.itemDetail && ["SKU_Number", "SKU_Name","UOM"].indexOf(component._id) >= 0 && items[i][component._id] != null && items[i][component._id] != ""){
                component.disabled = true;
            }
            else if(asn && ["SKU_Number", "UOM"].indexOf(of._id) >= 0 && items[i][of._id] != null && items[i][of._id] != ""){
                component.disabled = true;
            }
            else if(asn && ["SKU_Name"].indexOf(of._id) >= 0 && items[i].masterType &&  items[i][of._id] != null && items[i][of._id] != ""){
                component.disabled = true;
            }
            else if(item?.disabled?.includes(of._id)){
                component.disabled = true;
            }
            let isVisible=true
            if(of._id=="mappingArea"){
                if(((asn  && items[i]?.masterType) || items[i]?.fetchedFromItemMaster) && items[i]?.restrictedMappingArea?.length>0){
                    let restrict=items[i]?.restrictedMappingArea?.map(dl=>({label:dl,value:dl}))
                    component.values=[...restrict]
                    component.label=component.label + " (Restricted)"
                    isVisible=false
                }
            }

            return (
                <> 
                    {
                        getInputFieldForTableRow(component, items,  i, handleGenericChange, autoFillOnFocus, errors, false, null)
                        // getInputFieldForTableRow(component, items,  i, handleGenericChange, autoFillOnFocus, errors, false, !asn && !isManual && of._id == "SKU_Number" ? adornmentForScan : null)
                    }
                    {
                        isVisible && of._id == "mappingArea" &&
                        <FormControlLabel control={<Checkbox onClick={(e) => handleSaveForNext(i, e)} size='small' />} label={<Typography sx={{fontSize: "12px"}}>Same for next</Typography>}/>
                    }
                    {
                        !asn && !isManual && of._id == "SKU_Number" && 
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange, cursor: "pointer"}} onClick={() => setApiDataIndex(i)}>{items[i]?.itemDetail ? "View Detail" : "No Data yet"}</Typography>
                    }
                </>
            )
        })
        
        return [...base, ...others]
        
    }

    const rows = items.map((item, i) => {
        return getItemRow(i);
    })

    const addItemRow = () => {
        let fieldsData = {}
        let fields = completeFields.filter(f => f._id != "count")
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type == "checkbox"){
                fieldsData[fields[i]._id] = []    
            }
            else if (fields[i].type == "file"){
                fieldsData[fields[i]._id] = null;
            }
            else{
                fieldsData[fields[i]._id] = ""
            }
            
        }
        let newRow = {
            count: remainingItems,
            ...fieldsData,
            mappingArea: "PUT IT ANY WHERE",
        }
        if(asn){
            newRow = {
                ...items[items.length - 1],
                count: remainingItems
            }
        }
        setItems([...items, newRow]);
    }

    
    
    const downloadSample = async () => {
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml;charset=UTF-8";
        const fileExt = ".xlsx";

        const excelData = {};
        const theaders = getTableHeader("excel");
        for (let i = 0; i < theaders.length; i++) {
            excelData[theaders[i]] = "Enter Value"
        }
        
        const ws = XLSX.utils.json_to_sheet([excelData]);
        const wb = { Sheets: {data: ws}, SheetNames: ['data']};
        const buffer = XLSX.write(wb, {bookType: 'xlsx', type: "array"});
        const data = new Blob([buffer], {type: fileType});
        FileSaver.saveAs(data, "Item_Template_name" + fileExt);

    }

    const deleteItemRow = (index) => {
        let oldItems = [...items];
        oldItems.splice(index, 1);
        setItems(oldItems);
    }

    const handleDeleteItem = (i) => {
        let oldItems = [...items];
        oldItems.splice(i, 1);
        setItems(oldItems);
    }

    const handleItemCount = (i, direction) => {
        let oldItems = [...items];
        if(direction == 1){
            if(countTillNow < Number(packageObj.count)){
                oldItems[i].count = Number(oldItems[i].count) + 1
                if(oldItems[i].count > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                
                setItems(oldItems);
            }
            
        }
        else if(direction == -1 ){
            if(Number(oldItems[i].count) > 1){
                oldItems[i].count = Number(oldItems[i].count) - 1
                if(Number(oldItems[i].count) > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                else if(Number(oldItems[i].count) == 1){
                    oldItems[i].USN_Value = ""
                }
                setItems(oldItems);
            }
            else if(Number(oldItems[i].count) == 1){
                oldItems.splice(i, 1);
                if(oldItems[i] && Number(oldItems[i].count) > 1){
                    oldItems[i].USN_Value = "Not Applicable"
                }
                setItems(oldItems)
            }
            
        }
        else{
            
            let maxCap = Number(packageObj.count) - (countTillNow - Number(oldItems[i].count));

            oldItems[i].count = Number(direction.target.value) > maxCap ? maxCap : Number(direction.target.value)
            if(Number(oldItems[i].count) > 1){
                oldItems[i].USN_Value = "Not Applicable"
            }
            else if(Number(oldItems[i].count) <= 1){
                oldItems[i].USN_Value = ""
            }
            setItems(oldItems);
        }
        
    }

    const handleUSNChange = (e, i) => {
        let oldItems = [...items];
        oldItems[i].USN_Value = e.target.value;
        setItems(oldItems);
    }

    const handleLocationChange = (event, i) => {
        //// //("event " + event.target.value + " from index " + i);
        let oldItems = [...items];
        oldItems[i].mappingArea = event.target.value;
        setItems(oldItems);
    }

    const validateFields = () => {
        
        return validateFieldsMultiple(completeFields,items)
        
        let newErrors = [];
        
        for (let j = 0; j < items.length; j++) {
            const row = items[j];
            let rowErrors = {};
            const keys = Object.keys(row);
            for (let i = 0; i < keys.length; i++) {
                const fieldId = keys[i];
                const element = row[fieldId];
                const targetField = completeFields.filter(f => f._id == fieldId)[0];
                if(targetField){
                    if(targetField.required && (!element || element == "")){
                        // //// //(`${targetField.label} failing required test with value ${element}`);

                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `${targetField.label} is required`
                        }
                    }
                    else if(targetField.pattern && !(new RegExp(targetField.pattern)).test(element)){
                        // //// //(`${targetField.label} failing pattern test ${targetField.pattern} with value ${element}`);
                        rowErrors[fieldId] = {
                            name: targetField.label,
                            message: `Invalid value for ${targetField.label}`
                        }
                    }
                }
            }
            newErrors.push(rowErrors)
        }
        
        return newErrors;
    }

    const handleScanChange=(manual=false)=>{
        let checked = !manual
        let tempAll=deepCopy_Object(completeFields)
        let componentId="SKU_Number"
        let compIndex = tempAll?.findIndex(dl => dl._id == componentId)
        // console.log(compIndex,"compIndex",tempAll)
        if(componentId &&  compIndex>=0){
            if (checked) {
                let temComp = getItemPackageComponent(tempAll,tempAll[compIndex])
                // console.log("temComp",temComp)
                tempAll.splice(compIndex, 1, temComp)
            }
            else{
                let initialCom=allFields.find(dl=> dl._id == componentId) || {}
                tempAll.splice(compIndex, 1, {...initialCom})
            }
            // console.log("tempAll",tempAll)
            tempAll=tempAll.map(dl=>({...dl,disabled:false}))
            setCompleteFields([...tempAll])
        }
    }

    const handleItemAdd = () => {
        let zeroCount = items.filter(it => !it.count );
        let newErrors = validateFields();
        if(zeroCount.length > 0){
            showToast("Item count cannot be 0", true)
        }
        else if(newErrors.filter(row => Object.keys(row).length != 0).length != 0){
            showToast("Form Error: Please check for errors", true);
        } else if(countTillNow != packageObj.count){
            showToast(`Form Error: Total items should be equal to ${packageObj.count}, currently = ${countTillNow}`, true)
        }
        else{
            
            addItems(pIndex, items);
            handleCloseModal();
        }
        setErrors(newErrors);
    }

    // const header = ["Count", "Item Name", "USN", "Allocate Location", "Item Code", "Item Category"]
    const header = getTableHeader()
    

    useEffect(() => {
        // console.log("llllll")
        // dispatch(
        //     getFloorMapping({})
        // )
        // dispatch(
        //     getAllUom({})
        // )
        // setIsItemClicked(false);
    }, [])

    useEffect(() => {
        //// //("Packages Changed");
        //// //(packages);
    }, [packages])

    useEffect(() => {
        handleScanChange(isManual)
    }, [isManual])

    useEffect(() => {
        let out=generateFormFields(allFields,allUom)
        setCompleteFields(out)
    }, [template,allUom])

    const remainingItems = packageObj.count - countTillNow;

    return (
        <Paper key={sectionIndex} elevation={0}>
            <Grid container>
                <Grid container item sm={6} xs={6} direction="row">
                    <Typography sx={{...textClasses.cardTitle}}>{packageObj.packageName} - {name}</Typography>
                    {
                        !asn && 
                        <FormControlLabel
                            sx={{ml: 2}}
                            control={
                                <>
                                    <FormLabel sx={{ color: theme.themeOrange, ...textClasses.t12n }}>Manual</FormLabel>
                                    <Switch
                                        size='small'
                                        color="warning"
                                        checked={!isManual}
                                        onChange={(event) => setIsManual(!event.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <FormLabel sx={{ color: theme.themeOrange, ...textClasses.t12n }}>Scan</FormLabel>
                                </>
                            }
                        />
                    }
                </Grid>
                <Grid container direction={"row"} item sm={6} xs={6} justifyContent='end' alignItems="end">
                    <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>
                        Total - {packageObj.count}
                    </Typography>
                    <Typography sx={{...textClasses.boldText, color: theme.themeOrange, ml: 1}}>
                        Remaining - {packageObj.count - countTillNow}
                    </Typography>
                </Grid>
            </Grid>
            <ViewFileModal
                showFileData={showFileData}
                fileData={fileData}
                handleClose={handleCoseFileModal}
                header={getTableHeader()}
            />
            {
                !asn && isManual &&
                <Grid container sx={{mt: 1, p: 1, border: "1px solid #D9D9D9"}}>
                    <Grid item sm={4}>
                        
                    </Grid>
                    <Grid container item sm={8} justifyContent="end" alignItems="end">
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content" }}>
                                <a target="_blank" rel="noopener noreferrer" download>
                                    <Button
                                        variant="contained"
                                        spacing={1}
                                        className='RF_sampledownload-btn'
                                        sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px", backgroundColor: "black" }}
                                        onClick={downloadSample}
                                    >
                                        <Download /> Sample Download
                                    </Button>
                                </a>
                                <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px" }}>
                                    {/* <TextField size='small' type="file" sx={{ml: 1, mr: 1}} id="upload" className="RF_fileupload-input" onChange={handleFileChange} ref={fileInputRef} key={shellState}/> */}
                                    <input
                                        type="file"
                                        name="upload"
                                        id="upload"
                                        className='RF_fileupload-input'
                                        onChange={handleFileChange} ref={fileInputRef} key={shellState}
                                    />
                                </FormControl>
                                <Button
                                    variant="outlined"
                                    spacing={1}
                                    className='RF_outline-btn'
                                    sx={{ marginRight: "5px", borderRadius: "8px" }}
                                    disabled={selectedFile == null} onClick={handleDeleteFile}
                                    >
                                    <Delete />
                                </Button>
                                <Button
                                    variant="outlined"
                                    spacing={1}
                                    className='RF_outline-btn'
                                    color="primary"
                                    sx={{ marginRight: "5px", borderRadius: "8px" }}
                                    onClick={() => handleUploadFile("view")}
                                    // disabled={!fileData || fileData.length  == 0} onClick={handleShowFileModal}
                                >
                                    <Visibility />
                                </Button>
                                {
                                    fileLoading 
                                    ?
                                        <>
                                            <CircularProgress size="2rem" sx={{color: theme.themeOrange}}/>
                                            <Typography sx={{...textClasses.t12n, ml: 1}}>Please wait...</Typography>
                                        </>
                                    :
                                        <Button
                                            variant="contained"
                                            spacing={1}
                                            className={selectedFile == null ? "" : 'RF_uploadfile-btn'}
                                            sx={{ height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px" }}
                                            disabled={selectedFile == null} onClick={() => handleUploadFile("upload")}
                                        >
                                            <FileUpload /> Upload File
                                        </Button>
                                }
                                
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            }
             <Grid container sx={{ width: "fit-to-content", margin: "0 auto", overflow: "auto", mt: 2, maxHeight: "50vh" }}>
                <Table header={header} rows={rows} addItemRow={addItemRow} countTillNow={countTillNow} packageObj={packageObj} handleDeleteItem={handleDeleteItem} asn={asn} remainingItems={remainingItems}/>
               { apiDataIndex>=0 && items[apiDataIndex] && items[apiDataIndex].itemDetail && Object.keys(items[apiDataIndex].itemDetail).length>0 && 
               <DetailDialog
                    data={apiDataIndex != null ? items[apiDataIndex] || {} : null}
                    onClose={handleCloseDetailDialog}
                />}
            </Grid>
            <Grid container direction={"row"} sm={12} xs={12} sx={{mt: 2}}>
                <Button variant='outlined' size='small' sx={{...buttonClasses.outlined, color: theme.themeOrange, minWidth: "150px"}} onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant='contained' size='small' sx={{...buttonClasses.small, minHeight: "40px", backgroundColor: theme.themeOrange, minWidth: "150px", ml: 2}} onClick={handleItemAdd}>
                    Submit
                </Button>
            </Grid>
        </Paper>
    )
}

const Table = ({header, rows, addItemRow, countTillNow, packageObj, handleDeleteItem, asn}) => {

    return (
        <table
            style={{
                width:"inherit",
                whiteSpace: "nowrap",
                borderRadius: "8px",
                border: `1px solid ${theme.themeGray}`
            }}
        >
            <thead style={{backgroundColor: theme.themeGray}}>
                <tr className='roundedRow'>

                    {
                        header.slice(0, 1).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                            )
                        })
                    }
                    {
                        header.slice(1, header.length).map((head, i) => {
                            return (
                                <td style={{padding: "13px 10px 13px 10px", minWidth: "200px", ...textClasses.boldText, textAlign: "center"}} key={i}>{head}</td>
                            )
                        })
                    }
                    <td style={{padding: "13px 10px 13px 10px", minWidth: "100px", ...textClasses.boldText, textAlign: "center"}}>Action</td>
                </tr>
            </thead>
            <tbody>
                {
                    rows.length > 0
                    &&
                    <>
                        {
                            rows.map((row, i) => (
                                <tr
                                    key={i}
                                    style={{borderBottom: "1px solid #0000002B"}}   
                                >
                                    {
                                        row.map((col, j) => {
                                            return (
                                                // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText}}>
                                                    {col}
                                                </td>    
                                            )
                                        })
                                    }
                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center"}}>
                                        <IconButton size='small' sx={{backgroundColor: "red", color: "white"}} onClick={() => handleDeleteItem(i)}>
                                            <Remove fontSize='inherit'/>
                                        </IconButton>
                                    </td>
                                
                                </tr>
                            ))
                        }  
                         
                    </>    
                }
                {
                    // !asn &&
                    <tr
                    style={{borderBottom: "1px solid #0000002B"}}   
                    >
                        {
                            <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, "textAlign": "center"}}>
                                <IconButton sx={{backgroundColor: theme.themeOrange, color: "white"}} size="small" onClick={()=> addItemRow()} disabled={countTillNow >= packageObj.count}>
                                    <AddOutlined/>
                                </IconButton>
                            </td>
                        }
                    
                    </tr>  
                }
            </tbody>
        </table>
    )
}

const DetailDialog = ({data, onClose}) => {
    // console.log("Data in Detail", data);

    let header=data?.tableHeader || []
    let detail=data?.itemDetail?.formData||  {}

    const dispatch = useDispatch();

    const DetailRow = ({title, children}) => {
        return (
            <Grid container sx={{mt: 1}}>
                <Grid item sm={4} xs={4}>
                    <Typography sx={{...textClasses.t12n, color: "#827878"}}>{title}</Typography>
                </Grid>
                <Grid container item sm={8} xs={8} direction="row" alignItems={"center"}>
                    {children}
                </Grid>
            </Grid>
        )
    }
    const [displayUrl, setDisplayUrl] = useState("");
    const [openModal, setOpenModal] = React.useState(false);
  
    const displayFile = (fileUrl, fileKey) => {
      const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
      const isValidImageUrl = imageUrlRegex.test(fileKey);
      if (isValidImageUrl) {
        setOpenModal(true);
        setDisplayUrl(fileUrl);
      } else {
        window.open(fileUrl, "_blank");
      }
    };
  
    const handleFile = (fileKey) => {
      setOpenModal(false);
      setDisplayUrl('');
      dispatch(fetchFile({ fileKey }, displayFile));
    };

    return (
        <Dialog
            open={data != null}
            onClose={onClose}
            scroll="paper"
            PaperProps={{sx: {width: "100%", borderRadius: "8px"}}}
            maxWidth="sm"
            
        >  
            <DialogContent sx={{p: 0}}>
                {
                    data != null &&
                    <Card sx={{m: 0, border: `1px solid ${theme.themeOrange}`, borderRadius: "8px"}}>
                        <CardHeader
                            title={<Typography sx={{fontSize: "16px", color: "white", lineHeight: "22px"}}>
                                <Sell fontSize='inherit' sx={{mr: 1}}/>
                                SKU ID: {detail?.SKU_ID}
                            </Typography>}
                            sx={{backgroundColor: theme.themeOrange}}
                            action={<IconButton size='small' onClick={onClose}><Close fontSize='inherit' sx={{color: "white"}}/></IconButton>}
                        />
                        <CardContent>
                            {/* {console.log("header",header)} */}
                            {
                                 header?.length>0 && header?.map((h, i) => {
                                    return (
                                        <DetailRow title={h.label} key={i}> 
                                            <Typography sx={{...textClasses.normalText, ml: 1}}>
                                                {detail[h.name] ?
                                                    h.type === "file" ? (
                                                        <div onClick={() => handleFile(detail[h.name])}>
                                                          <span
                                                            style={{
                                                              ...buttonClasses.viewFile,
                                                              cursor:"pointer"
                                                            }}
                                                          >
                                                            View File
                                                          </span>
                                                        </div>
                                                      ) : detail[h.name] : "-"}
                                            </Typography>
                                        </DetailRow>
                                    )
                                })
                            }
                        </CardContent>
                    </Card>
                }
            </DialogContent>
            <FileModal   
                openModal={openModal}
                displayUrl={displayUrl}
                setOpenModal={setOpenModal}/>

        </Dialog>
    )
}


export {DetailDialog}
// {
//     "template_id": "64e7943e04576fa01c0c8feb",
//     "shipmentData": {
//         "packageQuantity": "2"
//     },
//     "otherData": [
//         {
//             "packageName": "Pants",
//             "packageDescription": "pants hi pants",
//             "count": "3",
//             "c9ce573f-8a30-421b-8a0a-a889199225db": "2",
//             "634af495-8042-4f34-801b-ee293af56ed9": [
//                 "option 2",
//                 "option 1"
//             ],
//             "62beeacf20037f12772790be": "option 3",
//             "itemDetail": [
//                 {
//                     "count": 3,
//                     "SKU_Name": "Blackberry shirts",
//                     "USN_Value": "",
//                     "mappingArea": "3",
//                     "84f450d4-4555-43cc-ac9f-e2ec9e98b8a5": "Black",
//                     "SKU_Number": "21das"
//                 }
//             ]
//         },
//         {
//             "packageName": "Shirts",
//             "packageDescription": "shirts hi shirts",
//             "count": "2",
//             "c9ce573f-8a30-421b-8a0a-a889199225db": "4",
//             "634af495-8042-4f34-801b-ee293af56ed9": [
//                 "option 3"
//             ],
//             "62beeacf20037f12772790be": "option 1",
//             "itemDetail": [
//                 {
//                     "count": "2",
//                     "SKU_Name": "Blackberry shirts",
//                     "USN_Value": "",
//                     "mappingArea": "3",
//                     "84f450d4-4555-43cc-ac9f-e2ec9e98b8a5": "Black",
//                     "SKU_Number": "sadasd"
//                 }
//             ]
//         }
//     ]
// }