import React, { useEffect, useState,useCallback } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    Modal,
    OutlinedInput,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    FormGroup,
    Tooltip,
    IconButton
} from "@mui/material";
import theme, {
    textClasses,
    buttonClasses,
} from "../../../../../static/styles/theme";
import { grey, orange } from "@mui/material/colors";
import { GridCloseIcon, GridSearchIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
    defineGridAccessibility
} from "../../../../../redux/actions";

import { selectStylesOverride } from "../../../../../utils/util";
import { showToast,deepCopy_Object } from "../../../../../services/functions";
import { component, units } from "../utils";
import TypeAndSearch from "../../../TypeAndSearch";
import CreateIcon from "@mui/icons-material/Create";
import { AutoSizer } from "react-virtualized";
import { FixedSizeGrid } from "react-window";
import { Cancel } from "@mui/icons-material";


const style = {
    marginTop: "55px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width: '900px',
    height: "91vh",
    maxHeight: "91vh",
    overflow: "auto"
};

const radioBtns = [
    {
        value: "block",
        label: "Block",
    },
    {
        value: "shelves",
        label: "Shelves",
    },
];
const radioBtnsYesNo = [
    {
        value: "yes",
        label: "Yes",
    },
    {
        value: "no",
        label: "No",
    },
];
const radioBtnsAllow = [
    {
        value: "allowed",
        label: "Allowed",
    },
    {
        value: "notAllowed",
        label: "Not Allowed",
    },
];

const initial_state={
    "areaType": "block",
    "cellData": [],
    "markAllDamaged": false,
    "accessbilityPreferences": {
        "pickUp": { isDisabled: false, isActive: true },
        "drop": { isDisabled: false, isActive: true },
        "temperatureSensors": { isDisabled: true, isActive: true }
    }
}
const AreaAccessbility = (props) => {
    let {
        showModel,
        setShowModel,
        floorId,
        floorInfo,
        fetchAreautilization,
    } = props

    const dispatch = useDispatch();
    const [state, setState] = useState(deepCopy_Object(initial_state));
    const [loader, setLoader] = useState(false);


    const handleRadio = (e) => {
        setState({ ...state, cellData: [], areaType: e.target.value })
    };

    const numberOfRows = Math.ceil(
        ((state?.cellData || [])?.length || 0) / 4
    );
    const cellRenderer = ({ columnIndex, rowIndex, style }) => {
        const index = rowIndex * 4 + columnIndex; // Assuming 3 columns
        const itemdata = (state?.cellData || [])?.map(
            (dl) => dl.value
        );
        const item = itemdata[index];
        return (
            <div style={{ ...style, padding: '1px', }}>
                {item && (
                    <div style={{ margin: '6px' }}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"}
                            sx={{
                                backgroundColor: theme.themeLightOrange,
                                border: `1px solid ${theme.themeOrange}`,
                                borderRadius: "12px",
                                pl: 1,
                                pr: 1,
                                minHeight: "32px",
                                ml: 2,
                                mr: 2
                            }}
                        >
                            <Grid item sm={10} xs={8}>
                                {item && item.length > 12 ? (
                                    <Tooltip arrow placement="top" title={item}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: theme.themeOrange,
                                                textAlign: "start",
                                            }}
                                        >
                                            {item && item.length > 20
                                                ? item.substring(0, 20) + "..."
                                                : item}
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography
                                        sx={{
                                            ...textClasses.normalText,
                                            color: theme.themeOrange,
                                            textAlign: "start",
                                        }}
                                    >
                                        {item}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "end" }}>
                                <IconButton
                                    sx={{ alignContent: "right", padding: '1px' }}
                                    onClick={() => {
                                        const filteredData = (state?.cellData || []).filter((_, i) => i !== index);
                                        setState({ ...state, cellData: filteredData });
                                    }}
                                >
                                    <Cancel sx={{ color: theme.themeOrange }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        );
    };

    const handleSubmitAccessbility=()=>{
        
        if(!enableSubmit()) return
        
        let accessbilityPreferences={}

        if(state?.accessbilityPreferences?.pickUp?.isActive){
            accessbilityPreferences["pickUp"]={"isDisabled": state?.accessbilityPreferences?.pickUp?.isDisabled}
        }
        if(state?.accessbilityPreferences?.drop?.isActive){
            accessbilityPreferences["drop"]={"isDisabled": state?.accessbilityPreferences?.drop?.isDisabled}
        }
        if(state?.accessbilityPreferences?.temperatureSensors?.isActive){
            accessbilityPreferences["temperatureSensors"]={"isDisabled": state?.accessbilityPreferences?.temperatureSensors?.isDisabled}
        }

        let payload={
            ...state,
            "floorId": floorInfo?.floorId,
            "accessbilityPreferences":accessbilityPreferences,
            "cellData":state?.cellData?.map(dl=>dl.cellId),
        }
        // console.log(payload)
        setLoader(true)
        dispatch(defineGridAccessibility(payload,({success,message})=>{
            setLoader(false)
            showToast(message,!success)
            if(success){
                setShowModel("")
                fetchAreautilization()
            }
        }))
    }
    const enableSubmit=()=>{
        if(!state?.areaType){
            showToast("Area type id required!",true)
            return false
        }
        if(!state?.cellData || state.cellData.length==0){
            showToast("Cell data required!",true)
            return false
        }

        if(!Object.values(state?.accessbilityPreferences || [])?.some(dl=>dl.isActive)){
            showToast("Minimum one preference type need to be selected!",true)
            return false
        }
       return true
    }

    return (
        <>
            {["addAccessbility"].includes(showModel) ? (
                <Drawer
                    anchor={"right"}
                    open={showModel}
                >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography
                                data-testid="heading"
                                sx={{
                                    ...textClasses.cardTitle,
                                    fontWeight: "700",
                                    margin: "-15px 0 10px 0",
                                }}
                            >
                                Configure Area Accessbility
                            </Typography>
                            <GridCloseIcon
                                sx={{
                                    marginLeft: "32%",
                                    cursor: "pointer",
                                }}
                              onClick={()=>setShowModel()}
                            />
                        </Box>
                        <Box
                            data-testid="accessbility"
                            sx={{
                                width: "100%",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                                marginLeft: "auto",
                                marginRight: "auto",
                                borderRadius: "8px",
                            }}
                        >
                            <Box
                                sx={{
                                    pt: "8px",
                                }}
                            >

                            </Box>
                            <Box
                                sx={{
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                                    borderRadius: "8px",
                                    m: "8px",
                                    p: "8px",
                                    marginTop: "20px",
                                }}
                            >
                                <Grid
                                    container
                                    xs={12}
                                    spacing={2}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <Grid item xs={2}>
                                        <Typography
                                            data-testid="areaType"
                                            sx={{
                                                ...textClasses.boldText,
                                                color: "#000000",
                                                // ml:2
                                            }}
                                        >
                                            Area Type* :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            value={state?.areaType || ''}
                                            name="row-radio-buttons-group"
                                            sx={{ flexWrap: "nowrap" }}
                                        >
                                            {radioBtns.map((item) => (
                                                <FormControlLabel
                                                    disabled={!!(state?.cellData || []).length}
                                                    data-testid={`${item.value}`}
                                                    onChange={handleRadio}
                                                    value={item.value}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: grey[800],
                                                                "&.Mui-checked": {
                                                                    color: orange[800],
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </Grid>
                                    {state?.areaType === "block" ? (
                                        <Grid item xs={4}>
                                            <TypeAndSearch
                                                component={component("block", floorId, "areaAccessbility", "isAccessbilityNotDefined")}
                                                value={(state?.cellData || [])}
                                                setEmptyOnChange={false}
                                                handleChange={(e, option) => {
                                                    if (option && !(state?.cellData || []).find((item) => item.value === option.value)) {
                                                        setState({ ...state, cellData: [option, ...(state?.cellData || [])] });
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid container item xs={12} md={6} spacing={2}>
                                            <Grid item xs={6}>
                                                <TypeAndSearch
                                                    fullWidth
                                                    component={component("rack", floorId, "areaAccessbility", "isAccessbilityNotDefined")}
                                                    value={""}
                                                    setEmptyOnChange={false}
                                                    handleChange={(e, option) => {
                                                        if (option && !(state?.cellData || []).find((item) => item.value === option.value)) {
                                                            setState({ ...state, cellData: [option, ...(state?.cellData || [])] });
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TypeAndSearch
                                                    fullWidth
                                                    component={component("shelves", floorId, "areaAccessbility", "isAccessbilityNotDefined")}
                                                    value={(state?.cellData || [])}
                                                    setEmptyOnChange={false}
                                                    handleChange={(e, option) => {
                                                        const fetchBlock = option?.value.split("_")


                                                        const existingBlock = fetchBlock && fetchBlock[0] && (state?.cellData || []).find((option) => option.value === fetchBlock[0])
                                                        if (existingBlock) {
                                                            showToast("This entire block is Already selected", true)
                                                            return
                                                        }
                                                        else if (option && !(state?.cellData || []).find((item) => item.value === option.value)) {
                                                            setState({ ...state, cellData: [option, ...(state?.cellData || [])] });
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                {(state?.cellData || [])?.length === 0 ? null :
                                    <Box
                                        sx={{
                                            border: "2px solid #FF7200",
                                            borderRadius: "8px",
                                            padding: "0px 20px 10px 0",
                                            marginBottom: "20px",
                                            width: '100%',
                                            height: '210px'
                                        }}
                                    >
                                        <AutoSizer>
                                            {({ width, height }) => {
                                                return (
                                                    <FixedSizeGrid
                                                        columnCount={4} // Number of columns
                                                        columnWidth={190} // Width of each column
                                                        height={numberOfRows < 3 ? numberOfRows * 50 : 210} // Total height of the grid
                                                        rowCount={numberOfRows}
                                                        rowHeight={50} // Height of each row
                                                        width={width} // Total width of the grid
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            width: '792px',
                                                            position: 'absolute',
                                                            left: '8px'

                                                        }}
                                                    >
                                                        {cellRenderer}
                                                    </FixedSizeGrid>
                                                );
                                            }}
                                        </AutoSizer>
                                    </Box>
                                }
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: "1px solid #FF7200",
                                    borderRadius: "5px",
                                    bgcolor: "#ff720014",
                                    color: "#FF7200",
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    m: "8px",
                                    p: "8px",
                                }}
                            >

                                <Typography
                                    data-testid="damagedLocation"
                                >
                                    Mark all the selected location(s) are damaged ?
                                </Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    value={state?.markAllDamaged == true ? "yes" : 'no'}
                                    name="row-radio-buttons-group"
                                    sx={{ flexWrap: "nowrap" }}
                                >
                                    {radioBtnsYesNo.map((item) => (
                                        <FormControlLabel
                                            data-testid={`${item.value}`}
                                            onChange={(e) => { setState({ ...state, markAllDamaged: e?.target?.value == "yes" }) }}
                                            value={item.value}
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: grey[800],
                                                        "&.Mui-checked": {
                                                            color: orange[800],
                                                        },
                                                    }}
                                                />
                                            }
                                            label={item.label}
                                        />
                                    ))}
                                </RadioGroup>

                            </Box>

                            <Box
                                sx={{
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                                    borderRadius: "8px",
                                    m: "8px",
                                    p: "8px",
                                }}
                            >
                                <Grid container xs={12}>
                                    <Grid item xs={4}>
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                            }}
                                        >
                                            Operation Type Preferences:
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={8} sx={{ display: "flex", flexDirection: "column" }}>
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <Box>
                                                <FormControlLabel
                                                    label="Pick Up"
                                                    control={<Checkbox
                                                        checked={state?.accessbilityPreferences?.pickUp?.isActive}
                                                        onChange={(e) => {
                                                            let tempState = { ...state }
                                                            tempState["accessbilityPreferences"]["pickUp"]["isActive"] = e?.target?.checked
                                                            setState(tempState)
                                                        }
                                                        }
                                                    />}
                                                />
                                            </Box>
                                            <Box>
                                                <RadioGroup
                                                    row
                                                    value={
                                                        state?.accessbilityPreferences?.pickUp?.isDisabled == true ? "notAllowed" : "allowed"
                                                    }
                                                    onChange={(e) => {
                                                        let tempState = { ...state }
                                                        tempState["accessbilityPreferences"]["pickUp"]["isDisabled"] = e?.target?.value == "allowed" ? false : true
                                                        setState(tempState)
                                                    }}
                                                    disabled={!state.accessbilityPreferences || !state.accessbilityPreferences["pickUp"] || !state.accessbilityPreferences["pickUp"]["isActive"]}
                                                >
                                                    {radioBtnsAllow.map((item) => (
                                                        <FormControlLabel
                                                            data-testid={`${item.value}`}
                                                            value={item.value}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        color: grey[800],
                                                                        "&.Mui-checked": {
                                                                            color: orange[800],
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <Box>
                                                <FormControlLabel
                                                    label="Drop"
                                                    control={
                                                        <Checkbox
                                                            checked={state?.accessbilityPreferences?.drop?.isActive}
                                                            onChange={(e) => {
                                                                let tempState = { ...state }
                                                                tempState["accessbilityPreferences"]["drop"]["isActive"] = e?.target?.checked
                                                                setState(tempState)
                                                            }
                                                            }
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <RadioGroup
                                                    row
                                                    disabled={!state.accessbilityPreferences || !state.accessbilityPreferences["drop"] || !state.accessbilityPreferences["drop"]["isActive"]}
                                                    value={
                                                        state?.accessbilityPreferences?.drop?.isDisabled == true ? "notAllowed" : "allowed"
                                                    }
                                                    onChange={(e) => {
                                                        let tempState = { ...state }
                                                        tempState["accessbilityPreferences"]["drop"]["isDisabled"] = e?.target?.value == "allowed" ? false : true
                                                        setState(tempState)
                                                    }}
                                                >
                                                    {radioBtnsAllow.map((item) => (
                                                        <FormControlLabel
                                                            data-testid={`${item.value}`}
                                                            value={item.value}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        color: grey[800],
                                                                        "&.Mui-checked": {
                                                                            color: orange[800],
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                                            <Box>
                                                <FormControlLabel
                                                    label="Tempareture Sensors"
                                                    control={
                                                        <Checkbox
                                                            checked={state?.accessbilityPreferences?.temperatureSensors?.isActive}
                                                            onChange={(e) => {
                                                                let tempState = { ...state }
                                                                tempState["accessbilityPreferences"]["temperatureSensors"]["isActive"] = e?.target?.checked
                                                                setState(tempState)
                                                            }
                                                            }
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <RadioGroup
                                                    row
                                                    disabled={!state.accessbilityPreferences || !state.accessbilityPreferences["temperatureSensors"] || !state.accessbilityPreferences["temperatureSensors"]["isActive"]}
                                                    value={
                                                        state?.accessbilityPreferences?.temperatureSensors?.isDisabled == true ? "no" : "yes"
                                                    }
                                                    onChange={(e) => {
                                                        let tempState = { ...state }
                                                        tempState["accessbilityPreferences"]["temperatureSensors"]["isDisabled"] = e?.target?.value == "yes" ? false : true
                                                        setState(tempState)
                                                    }}
                                                >
                                                    {radioBtnsYesNo.map((item) => (
                                                        <FormControlLabel
                                                            data-testid={`${item.value}`}
                                                            value={item.value}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        color: grey[800],
                                                                        "&.Mui-checked": {
                                                                            color: orange[800],
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Button
                                variant="contained"
                                spacing={1}
                                disabled={loader}
                                onClick={()=>setState(deepCopy_Object(initial_state))}
                                sx={{
                                    ...buttonClasses.lynkitBlackFill,
                                    marginRight: "4px",
                                    margin: "20px 0 12px 16px",
                                    padding: "5px 35px",
                                }}
                            >
                                Clear All
                            </Button>
                            <Button
                                sx={{
                                    ...buttonClasses.lynkitOrangeEmpty,
                                    marginRight: "4px",
                                    margin: "20px 0 12px 16px",
                                    padding: "5px 35px",

                                }}
                                disabled={loader}
                                // disabled={!enableSubmit() || loader}
                                onClick={handleSubmitAccessbility}
                                variant="outlined"
                            >
                                {showModel === "updateCapacity" ? "Update" : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Drawer>
            ) : (
                ""
            )}
        </>
    );
};
export default AreaAccessbility;
