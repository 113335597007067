import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { showToast } from "../../../services/functions";
import ClearIcon from "@mui/icons-material/Clear";
import {
  dashBoardData,
  warehouseOperations,
  WarehouseInventory,
} from "../../../redux/actions/MainDashboard";
import { ReactComponent as OperationIcon } from "../../../static/images/Icon/OperationIcon.svg";
import { ReactComponent as WarehouseIcon } from "../../../static/images/Icon/WarehouseIcon.svg";
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import { selectStylesOverride } from "../../../utils/util";
import "react-datepicker/dist/react-datepicker.css";
import TypeAndSearch from "../../Components/TypeAndSearch";
import {getAllJobs} from "../../../redux/actions/Job";
import { red, blue, green, grey,yellow } from "@mui/material/colors";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import $, { data } from "jquery";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import {
  Button,
  Divider,
} from "@mui/material";
import theme, {
  buttonClasses,
  inputClasses,
  textClasses,
} from "../../../static/styles/theme";
import {
  getAllUsers,
  getCompany,
} from "../../../redux/actions";
import MultiSelectAll from "./MultiSelect";
import { makeStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import GenericLoader from "../../Components/Generic/GenericLoader";
import dayjs from "dayjs";
import GenericTable from "../../Components/CustomTable/GenericTable";
import { useDebounce } from "../../../utils/hooks";
import { component } from "./utils";
import GenericDashboardCard from "../../Components/GenericDashboardCard";

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles({
  datePickerWrapper: {
    "& .react-datepicker-wrapper": {
      width: "100%",
      paddingTop: "10px",
      "& .react-datepicker__close-icon::after": {
        backgroundColor: "#FF7200",
        right: "30px",
        position: "absolute",
        top: "9px",
      },
      "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
        right: "0px",
        "& path": {
          fill: "grey !important",
        },
      },
    },
  },
  datePickerContainer: {
    border: "1px solid #adb5bd",
    minHeight: "35px",
    minWidth: "100%",
    padding: "0 0 0 10px !important",
    ...inputClasses.filterField,
    boxShadow: "none",
    "&::placeholder": {
      color: "#455A64",
      fontWeight: "300",
    },
    "&:hover": {
      border: "1px solid #FF7200",
    },
  },
});

export default function MainDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { AllUserData } = useSelector((state) => state.users);
  const { allAnalytics } = useSelector((state) => state.mainDashboard);
  const { allChartdata } = useSelector((state) => state.mainDashboard);
  const { wareHousedata } = useSelector((state) => state.mainDashboard);
  const { userPermissions } = useSelector((state) => state.userPermission);

  const { WarehouseInventorydata } = useSelector(
    (state) => state.mainDashboard
  );
  const warehouseDetail = useSelector(
    (state) => state.superadmin.warehouseDetail
  );
  const jobs = useSelector((state) => state.job.jobs.data);
  const jobcount = useSelector((state) => state.job.jobs.totalCount);

  let getToDate = () => {
    let current1 = dayjs().hour(0).minute(0).second(0).millisecond(0);
    let fromDate = current1.subtract(7, "day");
    let toDate = current1.hour(23).minute(59).second(59).millisecond(999);

    let date = [fromDate, toDate];
    return date;
  };
  const [dateRange, setDateRange] = useState(getToDate);
  const [condition, setCondition] = useState(true);
  const [analyticsLoader, setAnalyticsLoader] = useState(true);
  const [barChartLoader, setBarChartLoader] = useState(true);
  const [lineChartLoader, setLineChartLoader] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [nameFilter, setNameFilter] = useState("");
  const [mappingAreaFilter, setMappingAreaFilter] = useState("")
  const [skuFilter, setSKUFilter] = useState("");
  const [isInitial, setInitial] = useState(true);
  
  var chart;

  const getUserData = (userId) => {
    const user = AllUserData?.data?.find((user) => user._id === userId);
    if (user) {
      return user?.name + "," + user?.designation;
    } else {
      return "-";
    }
    // user?user?.name + "," + user?.designation:"-"
  };
  const handleJobs = () => {
    history.push("/dashboard/jobs");
  };
  const statusColor = {
    ongoing: "#FF7200",
    pending: "#FCCB3E",
    failed: "#E9001A",
    completed: "green",
  };

  useEffect(() => {
    if (condition) {
      dispatch(
        getAllJobs({ page: 1, limit: 10 }, () => {
          dispatch(getAllUsers({ isAdminIncluded: true }));
          setAnalyticsLoader(false);
        })
      );
    }
    const payload = { type: "warehouse", status: "active" };
    dispatch(getCompany(payload));
    if (condition) {
      setCondition(false);
    }
  }, []);

  useEffect(() => {
    barChart();
    instanceChart();
    instanceChart2();
    reportXChart();
  }, [wareHousedata]);

  useEffect(() => {
    lineChart();
  }, [WarehouseInventorydata]);

  const getTextBoxInputValue = useCallback((input) =>
    input.map((itm) => itm._id)
  );

  const [currentSelection, setCurrentSelection] = useState(
    getTextBoxInputValue([])
  );

  const handleSelectionChange = (result) => {
    const valueToSave = result.map((itm) => itm._id);
    setCurrentSelection(valueToSave);
    fetch_data(valueToSave, dateRange);
  };

  const handleDateChange = useCallback(
    (date, type) => {
      let tem_data = [];
      if (type === "from") {
        if (!date) {
          tem_data = [null, dateRange[1] || null];
        } else if (date?.isAfter(dateRange[1])) {
          showToast("Start Date must be smaller than End Date", true);
          tem_data = [null, dateRange[1] || null];
        } else {
          tem_data = [date, dateRange[1] || null];
        }
      } else {
        //if (type === "to")
        if (!date) {
          tem_data = [dateRange[0] || null, null];
        } else if (date?.isBefore(dateRange[0])) {
          showToast("End Date must be Greater than Start Date", true);
          tem_data = [dateRange[0] || null, null];
        } else {
          let tempEndDate = date;
          tempEndDate = dayjs(tempEndDate)
            .hour(23)
            .minute(59)
            .second(59)
            .millisecond(999);
          tem_data = [dateRange[0] || null, tempEndDate];
        }
      }
      setDateRange(tem_data);
      fetch_data(currentSelection, tem_data);
    },
    [dateRange]
  );

  let fetch_data = (warehouse = "", date_range) => {
    let payload = { wareHouse: warehouse };

    if (date_range[0] && date_range[1]) {
      payload.dateFrom = date_range[0];
      payload.dateTo = date_range[1];
    }

    dispatch(warehouseOperations(payload));
    dispatch(dashBoardData(payload));
    dispatch(WarehouseInventory(payload));
    setBarChartLoader(false);
    setLineChartLoader(false);
    setInitial(false)
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getAllJobs({ page: newPage + 1, limit: rowsPerPage }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    dispatch(getAllJobs({ page: 1, limit: +event.target.value }));
  };

  const tableHeader = [
    "Job Name",
    "Status",
    "Warehouse",
    "Created On",
    "Assigned To",
  ];

  const tableRowData = jobs?.map((job, index) => {
    const item = [];
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {capitalize(
          job.commonDetails?.jobName == "Send To User"
            ? "Gate Out"
            : job.commonDetails?.jobName || "-"
        )}
      </Typography>
    );
    item.push(
      <Typography
        variant="caption"
        gutterBottom
        sx={{
          pr: 1,
          pl: 1,
          color: statusColor[job.commonDetails?.status],
          fontWeight: "500",
          border: `1px solid ${statusColor[job.commonDetails?.status]}`,
          borderRadius: "12px",
          fontWeight: "500",
        }}
      >
        {capitalize(job.commonDetails?.status || "-")}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
      {
        job.commonDetails?.shipmentDetail
        .map(info=>info?.warehouseName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(", ")
      }
        {/* {job.commonDetails?.shipmentDetail.map((item) => (
          <div>{item?.warehouseName}</div>
        ))} */}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {job.commonDetails?.jobAllocationDate == "Send To User"
          ? "Gate Out"
          : moment(job.commonDetails?.jobAllocationDate).format(
              "DD MMM YYYY hh:mm A"
            ) || "-"}
      </Typography>
    );
    item.push(
      <Typography
        sx={{
          ...textClasses.normalText,
        }}
      >
        {getUserData(job?.commonDetails?.assignedTo?._id)}
      </Typography>
    );
    return item;
  });

  const nameDebounceFilter = useDebounce(nameFilter, 500)
  const areaMappingDebounceFilter = useDebounce(mappingAreaFilter, 500)
  const skuDebounceFilter = useDebounce(skuFilter, 500)

  useEffect(()=>{
    if(!isInitial){
      var payload = { wareHouse: currentSelection, jobTypeId: nameFilter };
      // var payload = { wareHouse: currentSelection, jobName: nameFilter };
      if (dateRange[0] && dateRange[1]) {
        payload.dateFrom = dateRange[0];
        payload.dateTo = dateRange[1];
      }

      dispatch(warehouseOperations(payload));

    }
     
  },[nameDebounceFilter])

  useEffect(()=>{
   
    if(!isInitial){
      var payload = { wareHouse: currentSelection, mappingArea: mappingAreaFilter, SKU_ID: skuFilter };
      
      if (dateRange[0] && dateRange[1]) {
        payload.dateFrom = dateRange[0];
        payload.dateTo = dateRange[1];
      }
      dispatch(WarehouseInventory(payload));
    }
  },[ areaMappingDebounceFilter, skuDebounceFilter])

  const handleNameChange = (e,option) => {
    // console.log("option",option)
    setNameFilter(option?._id || "");
    // setNameFilter(e.target.value);
  };

  const handleMappingAreaChange = (e,option)=>{
    setMappingAreaFilter(option?.value || "")
    // setMappingAreaFilter(e.target.value)
  }

  const handleSKUChange = (e,option)=>{
    setSKUFilter(option?.value || "")
    // setSKUFilter(e.target.value)
  }

  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            ...textClasses.cardTitle,
            color: "#000000",
            fontWeight: "700",
          }}
        >
          Overview
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid
            item
            xs={10}
            sm={6}
            md={3}
            sx={{
              margin: "-10px 0 0 0 ",
            }}
          >
            <MultiSelectAll
              sx={{ maxheight: "700px" }}
              items={warehouseDetail?.data || []}
              selectAllLabel="Select All"
              value={currentSelection}
              onChange={handleSelectionChange}
              label="Select Warehouse"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              margin: "-8px 0 0 0 ",
            }}
            className={classes.datePickerWrapper}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid item xs={12}
                md={6} sx={{ position: "relative" }}>
                <DateTimePicker
                  sx={{
                    marginTop: "10px",
                    ...selectStylesOverride,
                  }}
                  label="From Date"
                  value={dateRange[0] || null}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(d) => handleDateChange(d, "from")}
                />
                {dateRange[0] && (
                  <ClearIcon
                    sx={{
                      color: "grey",
                      position: "absolute",
                      top: 16,
                      right: 28,
                    }}
                    onClick={() => handleDateChange("", "from")}
                  />
                )}
              </Grid>
              <Grid item xs={12}
                md={6} sx={{ position: "relative" }}>
                <DateTimePicker
                  sx={{
                    marginTop: "10px",
                    ...selectStylesOverride,
                    marginLeft: "15px",
                  }}
                  label="To Date"
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(d) => handleDateChange(d, "to")}
                  value={dateRange[1] || null}
                />
                {dateRange[1] && (
                  <ClearIcon
                    sx={{
                      color: "grey",
                      position: "absolute",
                      top: 16,
                      right: 28,
                    }}
                    onClick={() => handleDateChange("", "to")}
                  />
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          margin: "0 0 15px 0",
        }}
      />
      <Grid container spacing={3}>
        <GenericDashboardCard
          title="My Warehouse"
          bgColor={green[900]}
          icon={<WarehouseIcon />}
          route="/dashboard/area/mapping"
          data={[
            { label: "All Active", value: allChartdata.data?.myWarehouse?.activeWareHouse || 0 },
            { label: "All Blocked", value: allChartdata.data?.myWarehouse?.InActiveWareHouse || 0 }
          ]}
        />
        <GenericDashboardCard
          title="Operations"
          bgColor={blue[900]}
          icon={<OperationIcon />}
          route="/dashboard/jobs"
          data={[
            { label: "Total Inward", value: allChartdata.data?.operationsCount?.putAwayCompletedJob || 0 },
            { label: "Total Outward", value: allChartdata.data?.operationsCount?.dispatchCompletedJob || 0 }
          ]}
        />
        <GenericDashboardCard
          title="Inventory Count"
          bgColor={red[900]}
          icon={<Inventory2OutlinedIcon />}
          route="/dashboard/inventory/all"
          data={[
            { label: "In Stock Units", value: allChartdata.data?.inventory?.inStock || 0 },
            { label: "To Be Received", value: allChartdata.data?.inventory?.toBeReceived || 0 },
            { label: "Gate In Count", value: allChartdata.data?.inventory?.gateInCount || 0 },
            { label: "Gate Out Count", value: allChartdata.data?.inventory?.gateOutCount || 0 }
          ]}
        />
        <GenericDashboardCard
          title="Inventory Weight"
          bgColor={yellow[900]}
          icon={<MonitorWeightIcon />}
          route="/dashboard/inventory/all"
          data={[
            { label: "In Stock Weight", value: allChartdata.data?.weight?.inStockWeight || 0 },
            { label: "To Be Received Weight", value: allChartdata.data?.weight?.toBeReceivedWeight || 0 },
            { label: "Gate Out Weight", value: allChartdata.data?.weight?.gateOutWeight || 0 },
            { label: "Gate In Weight", value: allChartdata.data?.weight?.gateInWeight || 0 },
          ]}
        />
        {barChartLoader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} className="cp">
            <Paper elevation={3}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid container xs={12}>
                    <Grid item xs={4}>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#000000",
                          padding: "16px 0 16px 20px",
                          minWidth: "33%",
                        }}
                      >
                        Daily Warehouse Operations Counts
                      </Typography>

                    </Grid>
                    <Grid item xs={2} sx={{ mt: 1 }}>
                      <TypeAndSearch
                        data-testid="jobNameFilter"
                        component={component({ type: "jobName" })}
                        handleChange={(e, option) => {
                          handleNameChange(e, option);
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      // sm={10}
                      justifyContent="end"
                      alignItems="end"
                      gap={1}
                      sx={{
                        marginBottom: "20px",
                      }}
                    >
                      <Item
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#E39323",
                          margin: "0 6px 0  0",
                        }}
                      ></Item>
                      <Typography
                        variant="contained"
                        sx={{ ...textClasses.t14n, color: "#000000" }}
                      >
                        Created Job
                      </Typography>
                      <Item
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#3E621E",
                          margin: "0 6px 0  0",
                        }}
                      ></Item>
                      <Typography
                        variant="contained"
                        sx={{ ...textClasses.t14n, color: "#000000" }}
                      >
                        Completed Job
                      </Typography>
                    </Grid>

                  </Grid>
                </Box>
                <Divider />
                <div style={{ width: "100%", height: "507px" }} id="bar"></div>
              </Item>
            </Paper>
          </Grid>
        )}
        {lineChartLoader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} className="cp">
            <Paper elevation={3}>
              <Item>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={5}>
                      <Box>
                        <Typography
                          sx={{
                            ...textClasses.t20n,
                            fontWeight: "700",
                            textAlign: "start",
                            color: "#000000",
                            padding: "16px 0 16px 20px",
                            minWidth: "35%",
                          }}
                        >
                          Warehouse Inventory
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <TypeAndSearch
                        data-testid="mappingFilter"
                        component={component({ type: "mappingArea" })}
                        handleChange={(e, option) => {
                          handleMappingAreaChange(e, option);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TypeAndSearch
                        data-testid="skuFilter"
                        component={component({ type: "SKU" })}
                        handleChange={(e, option) => {
                          handleSKUChange(e, option);
                        }}
                      />
                    </Grid>

                  </Grid>
                  <Grid
                    container
                    item
                    sm={9.8}
                    justifyContent="end"
                    alignItems="end"
                    gap={1}
                    sx={{
                      marginBottom: "16px",
                    }}
                  >
                    <Item
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#E9001A",
                        margin: "0 6px 0  0",
                      }}
                    ></Item>
                    <Typography
                      variant="contained"
                      sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                      Total Inward
                    </Typography>
                    <Item
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#3E621E",
                        margin: "0 6px 0  0",
                      }}
                    ></Item>
                    <Typography
                      variant="contained"
                      sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                      In Stock
                    </Typography>
                    <Item
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#E39323",
                        margin: "0 6px 0  0",
                      }}
                    ></Item>
                    <Typography
                      variant="contained"
                      sx={{ ...textClasses.t14n, color: "#000000" }}
                    >
                      Total Outward
                    </Typography>
                  </Grid>
                </Box>
                <Divider />
                <div style={{ width: "100%", height: "507px" }} id="line"></div>
              </Item>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} md={12} className="cp">
          {analyticsLoader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    marginTop: "20px",
                    border: "1px solid #D9D9D9",
                    padding: "2px 15px 15px 15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "4px",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.cardTitle,
                          textAlign: "start",
                          color: "#000000",
                          padding: "10px 0px 0px 20px",
                        }}
                      >
                        Job Status
                      </Typography>
                    </Box>

                    <Box>
                      <Button
                        data-testid="viewAllBtn"
                        variant="contained"
                        size="small"
                        sx={{
                          ...buttonClasses.lynkitOrangeFill,
                          textTransform: "none",
                          marginTop: "10px",
                        }}
                        onClick={handleJobs}
                      >
                        View All
                      </Button>
                    </Box>
                  </Box>
                  <Divider />

                  <Paper
                    elevation={0}
                    sx={{
                      paddingTop: "8px",
                    }}
                  >
                    <GenericTable
                      header={tableHeader}
                      rows={tableRowData}
                    // pageCount={jobcount}
                    // pageNumber={page}
                    // handleChangePage={handleChangePage}
                    // handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // rowsPerPage={rowsPerPage}
                    />
                  </Paper>
                </Box>
              </Grid>
              {/* <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper>
                     ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper>
                    ddd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper>
                      dd
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper>
                     ddd
                    </Paper>
                  </Grid>
                </Grid> */}
            </>
          )}
        </Grid>
      </Grid>

      {userPermissions?.permissions?.find(
        (dl) => dl.permissionId == "staticChart"
      ) ? (
        <>
          {lineChartLoader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10, mt: 2 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="cp" sx={{ mt: 2 }}>
              <Paper elevation={3}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#000000",
                          padding: "8px 0 10px 20px",
                        }}
                      >
                        Area Mapping & Utilization
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{ width: "50%", height: "507px" }}
                      id="chartdiv"
                    ></div>
                    <div
                      style={{ width: "50%", height: "507px" }}
                      id="chartdiv2"
                    ></div>
                  </Box>
                </Item>
              </Paper>
            </Grid>
          )}
          {lineChartLoader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 10, mt: 2 }}
            >
              <Grid item>
                <GenericLoader />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} className="cp" sx={{ mt: 2 }}>
              <Paper elevation={3}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          ...textClasses.t20n,
                          fontWeight: "700",
                          textAlign: "start",
                          color: "#000000",
                          padding: "8px 0 10px 20px",
                        }}
                      >
                        Inventory Stock History
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{ width: "100%", height: "507px" }}
                      id="chartdiv3"
                    ></div>
                  </Box>
                </Item>
              </Paper>
            </Grid>
          )}
        </>
      ) : (
        ""
      )}
    </Grid>
  );

  function barChart() {
    chart = am4core.create("bar", am4charts.XYChart);

    chart.colors.step = 0;

    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.legend.paddingBottom = 20;
    // chart.legend.labels.template.maxWidth = 95;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.tooltip.disabled = true;
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.extraMax = 0.05;
    yAxis.renderer.grid.template.disabled = false;
    xAxis.renderer.grid.template.disabled = true;
    yAxis.title.text = "Jobs count";
    yAxis.title.fontWeight = "bold";
    // yAxis.title.fontSize = "20px";

    function createSeries(value, name, color) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;
      series.columns.template.fill = am4core.color(color);
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);
      series.columns.template.tooltipText = "{valueY}";
      // series.tooltip.getFillFromObject = false;
      // series.tooltip.label.fill = "black";
      // series.tooltip.background.fill = "white";
      var bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.bottomAxesContainer;
      return series;
    }

    chart.data = wareHousedata?.data?.map((item) => {
      return {
        category: item.date,
        first: item. createdTaskCount,
        third: item.completedTaskCount,
      };
    });

    createSeries("first", "", "#E39323");
    // createSeries('second', 'Pending Jobs', '#727377');
    createSeries("third", "", "#228b22");

    function arrangeColumns() {
      var series = chart.series.getIndex(0);

      var w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;

          var newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;

            var dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }

    setTimeout(() => {
      $('g:has(> g[stroke="#3cabff"])').hide();
    }, 100);
  }

  function lineChart() {
    var chart = am4core.create("line", am4charts.XYChart);

    chart.plotContainer.stroke = am4core.color("#aaa");
    chart.plotContainer.strokeOpacity = 1;
    chart.paddingRight = 40;

    // Add data
    chart.data = WarehouseInventorydata?.data?.map((item) => ({
      date: item.date,
      instock: item.instock,
      inward: item.totalInward,
      outward: item.totalOutward,
    }));

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    // Create inward axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.05;
    valueAxis.title.text = "Inventory Count";
    valueAxis.title.fontWeight = "bold";

    var lineSeries2 = chart.series.push(new am4charts.LineSeries());
    lineSeries2.dataFields.valueY = "inward";
    lineSeries2.dataFields.dateX = "date";
    // lineSeries2.name = "inward";
    lineSeries2.strokeWidth = 3;
    lineSeries2.fill = am4core.color("#fff");
    lineSeries2.stroke = am4core.color("red");
    lineSeries2.smoothing = "monotoneX";

    // Create series
    var lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = "instock";
    lineSeries.dataFields.dateX = "date";
    // lineSeries.name = "instock";
    lineSeries.strokeWidth = 3;
    lineSeries.fill = am4core.color("#fff");
    lineSeries.stroke = am4core.color("#3E621E");
    lineSeries.smoothing = "monotoneX";

    var lineSeries1 = chart.series.push(new am4charts.LineSeries());
    lineSeries1.dataFields.valueY = "outward";
    lineSeries1.dataFields.dateX = "date";
    // lineSeries1.name = "Outward";
    lineSeries1.strokeWidth = 3;
    lineSeries1.fill = am4core.color("#fff");
    lineSeries1.stroke = am4core.color("#FF7200");
    lineSeries1.smoothing = "monotoneX";

    // Add simple bullet
    var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    var bullet = lineSeries1.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    var bullet = lineSeries2.bullets.push(new am4charts.CircleBullet());
    bullet.circle.stroke = am4core.color("#7AEDE3");
    bullet.circle.strokeWidth = 1;
    bullet.circle.propertyFields.fill = "color";

    chart.cursor = new am4charts.XYCursor();
    // chart.legend = new am4charts.Legend();

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    setTimeout(() => {
      $('g:has(> g[stroke="#3cabff"])').hide();
    }, 100);
  }

  function instanceChart() {
    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        country: "HEX FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#3E621E"),
      },
      {
        country: "ALLEN FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#FF7722"),
      },
      {
        country: "EXPORT MISC FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#000000"),
      },
      {
        country: "PALLET FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#89570E"),
      },
      {
        country: "NUT FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#5962B5"),
      },
      {
        country: "WASHER FAMILY",
        litres: getRandomInt(120),
        color: am4core.color("#4F1825"),
      },
      {
        country: "Free Space",
        litres: getRandomInt(120),
        color: am4core.color("#FF0848"),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend();
  }
  function instanceChart2() {
    // Create chart instance
    var chart = am4core.create("chartdiv2", am4charts.PieChart);

    // Add data
    chart.data = [
      {
        country: "Filled",
        litres: getRandomInt(120),
        color: am4core.color("#FF0"),
      },
      {
        country: "Available",
        litres: getRandomInt(120),
        color: am4core.color("#008000"),
      },
      {
        country: "Disable",
        litres: getRandomInt(120),
        color: am4core.color("#FF7722"),
      },
    ];

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.propertyFields.fill = "color";

    chart.legend = new am4charts.Legend();
  }

  function reportXChart() {
    let x = am4core.create("chartdiv3", am4charts.XYChart);
    x.paddingRight = 20;
    let data = [];
    let visits = 1000; // starting value for visits
    let today = new Date(); // current date
    for (let i = 6; i >= 0; i--) {
      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      let date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - i
      );
      data.push({ date: date, name: "name" + i, value: visits });
    }
    //console.log("data", data);
    x.data = data;
    let dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 40; // Set the minimum grid distance to ensure last 7 days are shown
    dateAxis.dateFormats.setKey("day", "MMM dd"); // Format the date labels
    dateAxis.periodChangeDateFormats.setKey("day", "MMM dd"); // Format the date axis tooltip
    dateAxis.baseInterval = { count: 1, timeUnit: "day" }; // Set the base interval to 1 day
    dateAxis.start = today.getTime() - 6 * 24 * 60 * 60 * 1000; // Set the start date to 7 days ago
    dateAxis.end = today.getTime(); // Set the end date to the current date
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    let series = x.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.tooltipText = "{valueY.value}";
    x.cursor = new am4charts.XYCursor();
    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    x.scrollbarX = scrollbarX;
  }

  // function reportXChart(){
  //   let x = am4core.create("chartdiv3", am4charts.XYChart);

  //   x.paddingRight = 20;

  //   let data = [];
  //   let visits = 1000; // starting value for visits
  //   let today = new Date(); // current date
  //   for (let i = 6; i >= 0; i--) {
  //     visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
  //     let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
  //     data.push({ date: date, name: "name" + i, value: visits });
  //   }

  //   //console.log("data",data)
  //   x.data = data;

  //   let dateAxis = x.xAxes.push(new am4charts.DateAxis());
  //   dateAxis.renderer.grid.template.location = 0;
  //   dateAxis.min = 0;

  //   let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
  //   valueAxis.tooltip.disabled = true;
  //   valueAxis.renderer.minWidth = 35;

  //   let series = x.series.push(new am4charts.LineSeries());
  //   series.dataFields.dateX = "date";
  //   series.dataFields.valueY = "value";
  //   series.tooltipText = "{valueY.value}";
  //   x.cursor = new am4charts.XYCursor();

  //   let scrollbarX = new am4charts.XYChartScrollbar();
  //   scrollbarX.series.push(series);
  //   x.scrollbarX = scrollbarX;

  // };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function capitalize(str) {
  if (!str) return;
  // ////({ str });
  return str[0].toUpperCase().concat(str.slice(1));
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
