import { customAlphabet } from 'nanoid';
const alphabet =
"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Excluding hyphen (-)
const nanoid = customAlphabet(alphabet, 21);

export const dataModifer= (warehouseDetails, racksData)=>{
    const modifiedData = racksData.reduce((acc, mapping)=>{  
        const obj ={
          actionType : "update",
          cellId: mapping.cellDetail.cellId,
          data: mapping.mappingAreaData?.map((data)=>{

            // console.log("data",data);
              return {
                  oldMaterialType: data?.mappingArea || "",
                  materialType: data?.mappingArea || "",
                  entityMasterId: data.connectedItem,
                  rackId: data?.rack?.map(({ cellId }) => cellId),
                  category: data?.connectedItem?.length > 0 ? "itemMaster" : "other"
              }
          }),
          isRackExist: mapping.cellDetail.isRackExist,
          name: mapping.cellDetail.name,
          selectType: mapping.cellDetail.selectType,
          renderId: nanoid(),
        };
        acc.push(obj)
        return acc
      },[])
// console.log("modifiedData",modifiedData);
      const selectedR= {
        floorId: warehouseDetails.floorId,
        warehouseId: warehouseDetails.warehouseId,
        areaTypeData: modifiedData,
        type: "wms",
      }

      return selectedR
}
