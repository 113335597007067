import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useRef } from 'react'

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import TextIcon from "../../../static/images/components/text.svg"
import DateIcon from "../../../static/images/components/date.svg"
import ParagraphIcon from "../../../static/images/components/paragraph.svg"

import DropdownIcons from "../../../static/images/components/dropdown.svg"
import CheckboxIcon from "../../../static/images/components/checkbox.svg"
import RadioIcon from "../../../static/images/components/radio.svg"

import ProductNameIcon from "../../../static/images/components/product_name.svg"
import SearchTagIcon from "../../../static/images/components/search_tags.svg"
import ExpiryDateIcon from "../../../static/images/components/expiry_date.svg"
import ActualWeightIcon from "../../../static/images/components/actual_weight.svg"
import StandardWeightIcon from "../../../static/images/components/standard_weight.svg"
import PriceIcon from "../../../static/images/components/price.svg"
import LengthIcon from "../../../static/images/components/length.svg"
import WidthIcon from "../../../static/images/components/width.svg"
import HeightIcon from "../../../static/images/components/height.svg"
import VehicleNumberIcon from "../../../static/images/components/vehicle_number.svg"
import QuantityIcon from "../../../static/images/components/quantity.svg"
import SONumberIcon from "../../../static/images/components/so_number.svg"
import InvoiceIcon from "../../../static/images/components/invoice_number.svg"
import ETAIcon from "../../../static/images/components/eta.svg"
import AssigneeIcon from "../../../static/images/components/assignee.svg"




import AttachmentIcon from "../../../static/images/components/attachment.svg"
import HeadingIcon from "../../../static/images/components/heading.svg"
import { Info, InfoOutlined } from '@mui/icons-material';
import {styled} from "@mui/material/styles";
import { textClasses } from '../../../static/styles/theme';

const OrangeTooltip = styled(({ className, ...props}) => (
    <Tooltip {...props} componentsProps={{tooltip: {className: className}}}/>
))(`
    color: #FFFFF;
    background-color: #FF7200;
`)

const iconMapping = {
    input: TextIcon,
    number: TextIcon,
    date: DateIcon,
    textarea: ParagraphIcon,

    select: DropdownIcons,
    checkbox: CheckboxIcon,
    radio: RadioIcon,

    file: AttachmentIcon,
    heading: HeadingIcon,
    paragraph: ParagraphIcon,
    searchTags: SearchTagIcon,

    product_name: ProductNameIcon, 
    search_tags: SearchTagIcon,
    expiry_date: ExpiryDateIcon,
    actual_weight: ActualWeightIcon,
    standard_weight: StandardWeightIcon,
    price: PriceIcon,
    length: LengthIcon,
    width: WidthIcon,
    height: HeightIcon,
    vehicle_number: VehicleNumberIcon, 
    quantity: QuantityIcon,
    so_number: SONumberIcon,
    invoice_number: InvoiceIcon,
    eta: ETAIcon,
    assignee: AssigneeIcon,
    
}


export default function GenericDraggableComponent({componentType, component, index, moveComponent, orientation}) {

    const ref = useRef(null);
    const [{isDragging}, drag] = useDrag(()=> ({
        type: "component",
        item: {...component, componentType: componentType},
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging
            }
        }
    }))

    drag(ref);

    const getDraggableCard = () => {
        if(component.templateType && component.templateType == "freeTemplate"){
            return (
                <Paper ref={ref} sx={{p: 1, width: "100%", alignItems: "left", justifyContent: "center", textAlign: "left", "&:hover": {boxShadow: "0px 2px 6px #444444"}}}>
                    <Grid container>
                        <Grid container item sm={1} xs={2} alignItems="center">
                            <img src={iconMapping["textarea"]} height="24" width="24" />
                        </Grid>
                        <Grid container item sm={10} xs={8} alignItems="center">
                            <Typography sx={{...textClasses.normalText, ml: 1}}>{component.name}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )
        }
        else{
            if(orientation && orientation == "list"){
                return (
                    <Paper ref={ref} sx={{p: "10px", width: "100%", alignItems: "left", justifyContent: "center", textAlign: "left", "&:hover": {boxShadow: "0px 2px 6px #444444"}}}>
                        <Grid container>
                            <Grid container item sm={1} xs={2} alignItems="center">
                                <img src={iconMapping[component.type]} height="24" width="24" />
                            </Grid>
                            <Grid container item sm={10} xs={8} alignItems="center">
                                <Typography sx={{...textClasses.normalText, ml: 1}}>{component.label}</Typography>
                            </Grid>
                            {
                                componentType == "previous" &&
                                <Grid container item sm={1} xs={2} alignItems="center">
                                    <OrangeTooltip title={`used in ${component.frequency} templates`} sx={{float: "right"}} arrow placement="top">
                                        <IconButton size='small' sx={{fontSize: '14px'}}>
                                            <InfoOutlined />
                                        </IconButton>
                                    </OrangeTooltip>
                                </Grid>
                            }   
                        </Grid>
                        
                    </Paper>
                )
            }
            else{
                return (
                    <Paper ref={ref} sx={{p: "10px", width: "100%", height: "100%", alignItems: "center", justifyContent: "center", textAlign: "center", "&:hover": {boxShadow: "0px 2px 6px #444444"}}}>
                        <img src={iconMapping[component.type]} height="45" width="45" />
                        <Grid container justifyContent={"center"} alignItems={"center"} sx={{mt: 1}}>
                            <Grid container item sm={componentType == "previous" ? 10: 12} justifyContent={componentType == "previous" ? "left": "center"} alignItems={"center"}>
                                <Typography sx={{...textClasses.normalText}}>{component.label}</Typography>
                            </Grid>
                            {
                                componentType == "previous" && 
                                <Grid container item sm={2}  alignItems={"center"}>
                                    <OrangeTooltip title={`used in ${component.frequency} templates`} arrow placement="top">
                                        <IconButton size='small'>
                                            <InfoOutlined fontSize='4px'/>
                                        </IconButton>
                                    </OrangeTooltip>
                                </Grid>
                            }
                            
                        </Grid>
                        
                    </Paper>
                )
            }
        }
    }

    return (
        <>
            {
                  getDraggableCard()   
            }
        </>
        
    )
}
