import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import theme from "../../../static/styles/theme";
import { ExpandMore } from "@mui/icons-material";
import {
  Add,
  ExpandMore as ExpandMoreIcon,
  Search,
  BusinessCenter,
  Download,
  Close,
  ArrowForwardIos,
  ArrowBackIos,
  ArrowBackIosNew,
} from "@mui/icons-material";
import {
  capitalizeSentence,
  dateTimeFormater,
  timeConversionFunction,
} from "../../../services/functions";
import { jobTypes } from "../Settings/AutomationRules/constant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getAllJobActions,
  getAllJobTypes,
  getAllUsers,
  getAllWarehouseAndFloors,
  getJobProcessConfig,
  getJobsStatus,
} from "../../../redux/actions";

const JobView = () => {
  const dispatch = useDispatch();
  let { groupBy } = useParams();
  const { jobDetail, allJobActions = [] } = useSelector((state) => state.job);
  const {
    jobDetail: { data = [], count = 0 },
  } = useSelector((state) => state.job);
  const jobTypes = useSelector((state) => state?.job?.job_types?.data || []);
  const allJobTypeName = jobTypes.map((jt) => ({
    label: capitalizeSentence(jt.name),
    value: jt._id,
    _id: jt._id,
  }));
  const [isExpanded, setIsExpanded] = useState({
    jobDetail: true,
    dispatchDetail: false,
    locationDetail: false,
    warehouseDetail: false,
    salesOrderDetail: false,
    remarks: false,
    userDetail: false,
  });
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({});
  const [condition, setCondition] = useState(true);
  const {
    _id,
    jobName,
    status,
    jobType,
    assignedTo,
    assignedBy,
    completedBy,
    result,
    remark,
    jobAllocationDate,
    shipmentDetail,
    salesOrderDetail,
    jobTemplateDetail = {},
    jobTypeId,
    jobEndDate,
    totalCount = 0,
    scannedCount = 0,
    jobAcceptedTime = "",
    isOpen = false,
    jobExtraDetail,
  } = (data?.length && data[0]) || {};
  const assigned_to = assignedTo?._id
    ? capitalizeSentence(user[assignedTo._id]?.name || "") +
      ", " +
      capitalizeSentence(user[assignedTo._id]?.designation || "")
    : "-";
  const assigned_by = assignedBy?._id
    ? capitalizeSentence(user[assignedBy._id]?.name || "") +
      ", " +
      capitalizeSentence(user[assignedBy._id]?.designation || "")
    : "-";
  const completed_by = completedBy?._id
    ? capitalizeSentence(user[completedBy._id]?.name || "") +
      ", " +
      capitalizeSentence(user[completedBy._id]?.designation || "")
    : "-";

//   useEffect(() => {
//     if (condition) {
//       setLoader(true);
//       get_Job_All(initFilters, true);
//       dispatch(
//         getAllWarehouseAndFloors({
//           companyName: JSON.parse(localStorage.getItem("user"))?.company || "",
//           type: ["warehouse", "floor"],
//         })
//       );
//       dispatch(getAllJobActions({ fetchSingle: true }));
//       dispatch(getJobsStatus());
//       dispatch(getAllJobTypes());
//       dispatch(getAllUsers({ approved: "all", isAdminIncluded: true }));
//       dispatch(getJobProcessConfig());
//       setCondition(false);
//     } else {
//       let temp = {};
//       if (AllUserData?.data?.length) {
//         for (let ele of AllUserData?.data) {
//           temp[ele._id] = {
//             name: ele.name,
//             designation: ele.designation,
//             email: ele.email,
//           };
//         }
//       }
//       setUser(temp);
//     }
//   }, [AllUserData]);
  return (
    <>
      {/* Job Detail */}
      <Card data-testid="job-detail-card" sx={{ width: "45%" }}>
        <CardHeader
          title={
            <Typography
              variant="span"
              sx={{
                color: theme.themeOrange,
              }}
            >
              Job Details
            </Typography>
          }
          action={
            <ExpandMore
              expand={isExpanded.jobDetail}
              onClick={() => {
                setIsExpanded((o) => ({
                  ...o,
                  jobDetail: !o.jobDetail,
                }));
              }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={isExpanded.jobDetail} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5em",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Job Name :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {capitalizeSentence(
                  allJobTypeName.find((dl) => dl._id == jobTypeId)?.label || "-"
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Job Type :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {allJobActions.find((dl) => dl.actionId == jobType)
                  ?.actionName || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Total Count :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {totalCount || "0"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Scanned Count :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {scannedCount || "0"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Result :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {result || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                {" "}
                Assigned To :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {assigned_to}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                {" "}
                Job Allocate Date :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "60%" }}>
                {(jobAllocationDate && dateTimeFormater(jobAllocationDate)) ||
                  "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                {" "}
                Job Accepted Date :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "60%" }}>
                {(jobAcceptedTime && dateTimeFormater(jobAcceptedTime)) || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Assigned By :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {assigned_by}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Completed By :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {completed_by}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Job End Date :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "70%" }}>
                {(jobEndDate && dateTimeFormater(jobEndDate)) || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.25em",
                fontSize: "13px",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="span" sx={{ color: "gray" }}>
                Total Time Spent :
              </Typography>
              <Typography variant="span" sx={{ flexBasis: "60%" }}>
                {status != "pending"
                  ? timeConversionFunction(
                      `${moment(isOpen ? new Date() : jobEndDate).diff(
                        moment(jobAcceptedTime || jobAllocationDate),
                        "seconds"
                      )}`
                    )
                  : ""}
              </Typography>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};
export default JobView;
