import React, { useEffect, useState } from "react";
import AlertDialog from "../../Components/DialogBox/dialogbox";
import { Grid, Box, Typography, Button, FormControl, OutlinedInput, IconButton, InputAdornment, TextField, Link, FormControlLabel, Checkbox, Autocomplete, ButtonGroup } from "@mui/material";
import theme, { textClasses, buttonClasses, inputClasses } from "../../../static/styles/theme";
import { Clear, Search, Delete } from "@mui/icons-material";
import GenericLoader from "../../Components/Generic/GenericLoader";
import { inventoryTypes, inventoryComponent, locationComponent } from "./Constant";
import TypeAndSearch from "../../Components/TypeAndSearch";
import { selectStylesOverride } from "../../../utils/util";
import GenericTable from "../../Components/CustomTable/GenericTable";
import GenericSingleRangeComponent from "../../Components/Generic/GenericSingleRangeComponent";
import { useDispatch, useSelector } from "react-redux";
import { endJobFunction } from "../../../redux/actions";
import { showToast } from "../../../services/functions";
const initialData = {
  inventoryType: {},
  inventoryId: '',
  cellId: '',
  rackId: '',
  locationName: '',
  count: 0,
  maxCount: 0
}

const StartJobInward = ({ checkedRow, setCheckedRow, initFilters, get_Job_All }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [jobInputId, setJobInputId] = useState(checkedRow?.selectedRow[0]?.groupBy)
  const [tableData, setTableData] = useState([])
  const [selectedData, setSelectedData] = useState(initialData)
  const [dataReceieved, isDataReceived] = useState(false)
  const [pagination, setPagination] = useState({ page: 0, limit: 100 })
  const jobComponentId = checkedRow?.selectedRow?.at(0)?.jobComponentId
  const handleInputChange = (e) => {
    setJobInputId(e.target.value)
    // setFilter({ ...filter, page: 0 })
  }
  const handleAddTableData = () => {
    setTableData(prevTableData => [selectedData, ...prevTableData]);
    setSelectedData({...initialData,inventoryType:selectedData?.inventoryType || {}});
  }
  const countChangeHandler = (count) => {
    setSelectedData({ ...selectedData, count: count > 0 ? count : "" })
  }
  const countTableChangeHandler = (count, inventoryId) => {
    const updatedTableData = [...tableData];
    const index = updatedTableData.findIndex(
      (dl) => dl.inventoryId === inventoryId
    );
    if (index !== -1) {
      updatedTableData.splice(index, 1, { ...updatedTableData[index], count: count > 0 ? count : "" });
      setTableData(updatedTableData);
    }
  }
  const createPayload = () => {
    const filledInfo = tableData.map(row => (
      {
        cellId: row.cellId,
        rackId: row.rackId,
        full: false   //row.count >= row.maxCount
      }));

    const location = tableData.map(row => ({
      cellId: row.cellId,
      rackId: row.rackId,
      count: row.count,
      shipmentId: row.inventoryId
    }));

    return {
      filledInfo,
      jobComponentId: jobComponentId,
      location
    };
  };
  const handleSubmitClick = () => {
    isDataReceived(true);
    let payload = createPayload()
    dispatch(
      endJobFunction(payload, (data={}) => {
        showToast(data.message, !data.success);
        isDataReceived(false);
        if (data.success) {
          setTableData([])
          get_Job_All({ ...initFilters, makeInitial: true }, true)
          if (data?.allScanned == true) {
            setCheckedRow({ visible: false, selectedRow: [], type: "" })
          }
          else {
            setPagination({ page: 0, limit: 100 })
            setSelectedData(initialData)
          }
        }
      })
    )

  }

  const tableHeaders = ["Inventory ID", "Inventory Type", "Total QTY", "Location", "Enter QTY", "Action"]
  const handleRowDelete = (index) => {
    setTableData(prevTableData => prevTableData.filter((_, i) => i !== index));
    // setSelectedData(initialData)
  }
  const tableRows = tableData?.slice(pagination.page * pagination.limit, pagination.page * pagination.limit + pagination.limit)?.map((row, index) => {
    let item = [];
    item.push(
      <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange }}>
        {row?.inventoryId}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.inventoryType["label"]}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.maxCount}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {row?.locationName}
      </Typography>
    )
    item.push(
      <Typography sx={{ ...textClasses.normalText, }}>
        {<GenericSingleRangeComponent
          data-testid="rangefilter"
          startRange={row?.count}
          endRange={row?.maxCount}
          getOnChange={(count) => countTableChangeHandler(count, row?.inventoryId)}
        />}
      </Typography>
    )
    item.push(
      <IconButton
        aria-label="delete"
        key={index}
        onClick={() => handleRowDelete(index)}
      >
        <Delete sx={{ color: "red" }} />
      </IconButton>,
    )

    return item;
  })
  const isAddButtonDisabled = () => {
    return !selectedData.inventoryType ||
      !selectedData.inventoryId ||
      !selectedData.cellId ||
      // !selectedData.rackId ||
      !selectedData.locationName ||
      selectedData.count === "" ||
      selectedData.maxCount === 0
  };

  return (
    <AlertDialog
      viewDialog={["gateIn"].includes(checkedRow?.type)}
      handleClose={() => setCheckedRow({ ...checkedRow, type: '' })}
      viewSearchInput={true}
      showDivider={true}
      width="80%"
      title="Select Inventory To Complete The Job"
      actionsCSS={{ justifyContent: 'flex-start' }}
      searchInputDisplay={
        <FormControl variant="outlined" size="small" fullWidth sx={{ width: 190, }}>
          {/* <Typography sx={{ display: 'inline-block', marginRight: 1, ...textClasses.t15nBold }} >
              Sales Order ID
            </Typography> */}
          <OutlinedInput
            data-testid="search-input"
            fullWidth
            value={jobInputId}
            onChange={handleInputChange}
            disabled={true}
            sx={{
              ...inputClasses.filterField,
              "&:focus": {
                borderColor: theme.themeOrange,
              },
              "&:before": {
                borderColor: theme.themeOrange,
              },
              "&:after": {
                borderColor: theme.themeOrange,
              },
              "& input::placeholder": { fontSize: "14px" },
            }}
            placeholder="Search By Job ID"
          // endAdornment={
          //     jobInputId == ""
          //         ? (
          //             <InputAdornment position="end">
          //                 <Search fontSize="small" />
          //             </InputAdornment>
          //         ) : (
          //             <IconButton
          //                 sx={{
          //                     visibility: jobInputId != "" ? "visible" : "hidden",
          //                     fontSize: "14px",
          //                 }}
          //                 onClick={() => {
          //                     setJobInputId("")
          //                 }}
          //             >
          //                 <Clear fontSize="inherit" />
          //             </IconButton>
          //         )
          // }
          />
        </FormControl>
      }
      body={
        <>
          {loader ?
            <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
              <Grid item><GenericLoader /></Grid>
            </Grid>
            :
            <Box sx={{ width: "inherit" }}>
              <Grid container sx={{ mb: 1 }}>
                <Grid item sm={2.5}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth

                    sx={{ ...selectStylesOverride }}
                  >
                    <Autocomplete
                      data-testid="inventoryTypeFilter"
                      value={selectedData?.inventoryType?.label || ""}
                      onChange={(event, option) => {
                        setSelectedData({ ...selectedData, inventoryType: option || {} });

                      }}
                      size="small"
                      fullWidth
                      options={inventoryTypes}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            ...selectStylesOverride,
                            fontSize: "12px",
                            "& input::placeholder": {
                              fontSize: "9px",
                            },
                          }}
                          {...params}
                          label="Select Inventoy Type"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={2.5} sx={{ ml: 1 }}>
                  <TypeAndSearch
                    data-testid="inventoryFilter"
                    disabled={!selectedData?.inventoryType["value"] }
                    fullWidth
                    component={inventoryComponent(jobComponentId, selectedData?.inventoryType["value"], tableData?.map((ele) => ele?.inventoryId))}
                    value={selectedData?.inventoryId}
                    set_Value={true}
                    setEmptyOnChange={false}
                    handleChange={(event, option) => {
                      if (event.target.value != "") {
                        setSelectedData({
                          ...selectedData, inventoryId: option?.inventoryID, count: option?.count,
                          maxCount: option?.count
                        });
                      }
                      else {
                        setSelectedData({
                          ...selectedData, inventoryId: "", count: 0,
                          maxCount: 0
                        })
                      }
                    }}
                  />
                </Grid>

                <Grid item sm={2.5} sx={{ ml: 1 }}>
                  <TypeAndSearch
                    data-testid="locationFilter"
                    disabled={!selectedData?.inventoryType["value"] || !selectedData?.inventoryId}
                    fullWidth
                    component={locationComponent(jobComponentId, selectedData?.inventoryType["value"], selectedData?.inventoryId)}
                    value={selectedData?.locationName}
                    set_Value={true}
                    setEmptyOnChange={false}
                    handleChange={(event, option) => {
                      if (event.target.value != "") {
                        setSelectedData({
                          ...selectedData, cellId: option?.cellId, locationName: option?.name, rackId: option?.rackId,
                        });
                      }
                      else {
                        setSelectedData({
                          ...selectedData, cellId: "", locationName: "", rackId: "",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item sm={1.5} sx={{ mt: 1, ml: 2 }}>
                  <GenericSingleRangeComponent
                    data-testid="rangeFilter"
                    startRange={selectedData?.count}
                    endRange={selectedData?.maxCount}
                    getOnChange={(count) => countChangeHandler(count)}
                  />
                </Grid>
                <Grid item sm={1} >
                  <Button
                    data-testid="addBtn"
                    variant="contained"
                    spacing={1}
                    disabled={isAddButtonDisabled()}
                    onClick={handleAddTableData}
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      // position:"relative",
                      // left:"-10px"
                      ml: 1,
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <GenericTable
                data-testid="startJob-table"
                header={tableHeaders}
                rows={tableRows || []}
                pageCount={tableData?.length}
                pageNumber={pagination.page}
                handleChangePage={(event, pagevalue) => {
                  setPagination({ ...pagination, page: pagevalue })
                }}
                handleChangeRowsPerPage={(event) => {
                  setPagination({ ...pagination, limit: +event.target.value, page: 0 })
                }}
                rowsPerPage={pagination.limit}
              />
            </Box>
          }
        </>
      }

    >

      <Button
        data-testid="cancelBtn"
        variant="outlined"
        size="small"
        onClick={() => setCheckedRow({ ...checkedRow, type: '' })}
        sx={{ ...buttonClasses.lynkitOrangeEmpty, width: '120px', mr: 1 }}
      >Cancel
      </Button>
      <Button
        data-testid="submitBtn"
        variant="contained"
        size="small"
        disabled={tableData?.length <= 0 || tableData?.some(dl => !dl?.count) || dataReceieved}
        sx={{ ...buttonClasses.lynkitOrangeFill, width: '180px' }}
        onClick={handleSubmitClick}
      >
        Submit To Complete Job
      </Button>
    </AlertDialog>
  )

}

export default StartJobInward;

