import {
    FETCH_ALL_INVENTORY,
    FETCH_INVENTORY_STATUS_COUNT,
    FETCH_INVENTORY_TYPE_COUNT,
    FETCH_ITEM_DETAIL,
    FETCH_PACKAGE_DETAIL,
    FETCH_PACKAGE_ITEM_DETAIL,
    FETCH_SHIPMENT_DETAIL, 
    FETCH_INVENTORY_FOR_MODAL,
    FETCH_TEMPLATE_HEADER,
    FETCH_INVENTORY_FOR_LABEL,
    FETCH_ALL_TAGS,
    FETCH_USED_TAGS,
    FETCH_PARCEL_DIEMENSION,
    FETCH_INVENTORY_SETTINGS,
    FETCH_INVENTORY_TABLE_HEADER,
    FETCH_VALIDATE_INVENTORY,
    FETCH_PARCEL_DIEMENSION_DATA
  
  } from "../types";
  
  const INIT_STATE = {
    tableHeader: {},
    allInventories: [],
    totalRows: 0,
    inventoryTypeCount: null,
    inventoryStatusCount: null,
    shipmentDetail: null,
    packageDetail: [],
    itemDetail: [],
    templateHeader: null,
    modalInventories: [],
    modalTotalRows: 0,
    labelInventories: [],
    allTags: [],
    usedTags: [],
    usedTagsRows: 0,
    modalTotalRows: [],
    labelInventories: [],
    parcelgetDimensions:{},
    inventorySettings: null,
    parcelgetDimensionsData: {}
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      // case CLEAR_ENTRY: return {...state, selectedTemplate: null, packages: []}
      case FETCH_INVENTORY_TABLE_HEADER: return {...state, tableHeader: action.payload}
      case FETCH_ALL_INVENTORY: return {...state, allInventories: action.payload.rows, totalRows: action.payload.count}
      case FETCH_INVENTORY_TYPE_COUNT: return {...state, inventoryTypeCount: action.payload}
      case FETCH_INVENTORY_STATUS_COUNT: return {...state, inventoryStatusCount: action.payload}
      case FETCH_SHIPMENT_DETAIL: return {...state, shipmentDetail: action.payload}
      case FETCH_PACKAGE_DETAIL: return {...state, packageDetail: action.payload}
      case FETCH_VALIDATE_INVENTORY : return {...state,validateInventory : action.payload}
      case FETCH_ITEM_DETAIL: return {...state, itemDetail: action.payload}
      case FETCH_PACKAGE_ITEM_DETAIL: return {...state, itemDetail: action.payload}
      case FETCH_INVENTORY_FOR_MODAL: return {...state, modalInventories: action.payload.rows, modalTotalRows: action.payload.count}
      case FETCH_TEMPLATE_HEADER: return {...state, templateHeader: action.payload}
      case FETCH_INVENTORY_FOR_LABEL: return {...state, labelInventories: action.payload.rows}
      case FETCH_ALL_TAGS: return {...state, allTags: action.payload}
      case FETCH_USED_TAGS: return {...state, usedTags: action.payload.rows, usedTagsRows: action.payload.count}
      case FETCH_PARCEL_DIEMENSION: return {...state, parcelgetDimensions: action.payload}
      case FETCH_INVENTORY_SETTINGS: return {...state, inventorySettings: action.payload}
      case FETCH_PARCEL_DIEMENSION_DATA: return {...state, parcelgetDimensionsData: action.payload}
      
      default:
        return state;
    }
  }
  //chnage