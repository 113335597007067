  import { FETCH_ALL_INVENTORY,FETCH_PACKAGE_ITEM_DETAIL, FETCH_ERROR, FETCH_INVENTORY_FOR_LABEL,
     FETCH_INVENTORY_FOR_MODAL, FETCH_INVENTORY_STATUS_COUNT, FETCH_INVENTORY_TYPE_COUNT,
      FETCH_ITEM_DETAIL, FETCH_PACKAGE_DETAIL, FETCH_SHIPMENT_DETAIL, FETCH_START, FETCH_SUCCESS,
       FETCH_TEMPLATE_HEADER,FETCH_PARCEL_DIEMENSION,ALLOCATE_TAG, ALLOCATE_TAGS,
       FETCH_ALL_TAGS,FETCH_USED_TAGS, FETCH_INVENTORY_SETTINGS, FETCH_INVENTORY_TABLE_HEADER, FETCH_VALIDATE_INVENTORY, FETCH_JOB_CREATION, FETCH_PARCEL_DIEMENSION_DATA } from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getInvTableHeader = (payload, cb) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/getTableHeaderConfig', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INVENTORY_TABLE_HEADER, payload: data.data})
                if(cb){
                    cb();
                }
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_TABLE_HEADER, payload: {}})
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const deleteInventoryData = (payload, cb) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/deleteInventoryData', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            showToast(data.message, !data.success)
            if(cb){
                cb(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
             showToast(error.message, true)
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const approveRejectPendingShipment = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/updateASNApprovalStatus', {
            ...payload
        }
        ).then(({data}) => {
            if (data.success) 
            {
                callback(data);
            } 
            else 
            {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true);
                if(cb){
                    cb()
                }
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                // showToast(data.message, false);
                if(cb){
                    cb(data)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            showToast(error.message, true);
            if(cb){
                cb()
            }
        });
    }
};


export const updateInvTableHeader = (payload, cb) =>{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/updateTableHeaderConfig', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS }); 
                if(cb){
                    cb(data);
                }
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                if(cb){
                    cb(data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};

export const getAllInventory = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getInventoryData', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_INVENTORY, payload: {rows: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_INVENTORY, payload: {rows: [], count: 0}})
            }
            if(cb){
                cb({success:data.success,data:data?.data || []});
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            if(cb){
                cb({success:false,data:[]});
            }
        });
    }
};

export const getInventoryForModal = (modalType, payload, cb) =>
{
    let url = '/inventory/getInventoryData';
    if(modalType == "locationDetail"){

    }
    else if(modalType == "packageDetail"){
        url = "/inventory/getPackageDetail"
    }
    else if(modalType == "itemDetail") {
        url = "/inventory/getItemDetail"
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post(url, {
               ...payload,
               header: true
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INVENTORY_FOR_MODAL, payload: {rows: data.data, count: data.count}})
              
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_FOR_MODAL, payload: {rows: [], count: 0}})
            }
            if(cb){
                cb(data.data)
            }
            
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            dispatch({ type: FETCH_INVENTORY_FOR_MODAL, payload: {rows: [], count: 0}})
            //("Error****:", error.message);
            if(cb){
                cb(null)
            }
        });
    }
};

export const getInventoryForLabel = (labelType, payload, callback) =>
{
    let url = '/inventory/getInventoryData',obj={header: true};

    if(labelType == "shipmentOnly"){
        url = '/inventory/getShipmentDetail';
    }
    else if(labelType == "packageOnly"){
        url = "/inventory/getPackageDetail"
    }
    else if(labelType == "packageAndEveryItem"){
        url = "/inventory/getShipmentDetail"
        obj={...obj,fetchPackage:true,fetchItem:true}
    }
    else{
        url = "/inventory/getItemDetail"
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post(url, {
               ...payload,
               ...obj
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS }); 
                dispatch({ type: FETCH_INVENTORY_FOR_LABEL, payload: {rows: data.data, count: data.count}})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_FOR_LABEL, payload: {rows: [], count: 0}})
            }
            if(callback){
                callback()
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            if(callback){
                callback(error.message)
            }
            //("Error****:", error.message);
        });
    }
};

export const getInventoryCount = () =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getInventoryTypeWiseCount', {
               type: "wms"
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_TYPE_COUNT, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INVENTORY_TYPE_COUNT, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getInventoryStatusCount = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getInventoryStatusWiseCount', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_STATUS_COUNT, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INVENTORY_STATUS_COUNT, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getShipmentDetail = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getShipmentDetail', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_SHIPMENT_DETAIL, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_SHIPMENT_DETAIL, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getPackageDetail = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getPackageDetail', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_PACKAGE_DETAIL, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PACKAGE_DETAIL, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getItemDetail = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getItemDetail', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ITEM_DETAIL, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ITEM_DETAIL, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getTemplateHeader = (payload,callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getTemplateHeader', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_TEMPLATE_HEADER, payload: data.templateHeader})
          
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_TEMPLATE_HEADER, payload: []})
            }
            if (callback) {
                callback(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
            callback({success:false,message:error.message,templateHeader:[],count:0,templateCode:""})

        });
    }
};

export const getTemplateHeaderShell = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getTemplateHeader', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                // dispatch({ type: FETCH_TEMPLATE_HEADER, payload: null})
                if(cb){
                    cb(null)
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                // dispatch({ type: FETCH_TEMPLATE_HEADER, payload: data.templateHeader})
                if(cb){
                    cb(data.templateHeader)
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);

        });
    }
};

export const getPackageItemDetail = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/getItemDetail', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ITEM_DETAIL, payload: null})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PACKAGE_ITEM_DETAIL, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getRfidTags = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/RFIDSeal/getSeal', {
               isUsed: "false",
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_TAGS, payload: []})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_TAGS, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getUsedRfidTags = () =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/RFIDSeal/getSeal', {
               isUsed: "true"
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_USED_TAGS, payload: {rows: [], count: 0}})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_USED_TAGS, payload: {rows: data.data, count: data.count}})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const allocateTag = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/RFIDSeal/mapSeal', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                showToast("Tag Allocated", false)
                cb();
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const deallocateTag = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/RFIDSeal/dellocateSeal', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_TAGS, payload: []})
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                // dispatch({ type: FETCH_ALL_TAGS, payload: data.data})
                showToast("Tag deallocated", false)
                cb();
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const getDimensions = (payload) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/parcel/getDimensions',payload).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_PARCEL_DIEMENSION, payload: null})
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PARCEL_DIEMENSION, payload: data.data})
                
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};

export const fetchInventorySettings = (payload, cb) => {
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/base/getSettings',{

        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_INVENTORY_SETTINGS, payload: null})
                if(cb){
                    cb(null);
                }
            }
            

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_INVENTORY_SETTINGS, payload: data.data})
                if(cb){
                    cb(data.data);
                }
                
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
}

export const changeInventorySettings = (type, payload, cb) => {
    let url;
    if(type == "add"){
        url = '/base/addSettings'
    }
    else{
        url = '/base/updateSettings'
    }
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post(url,{
            ...payload
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                if(cb){
                    cb(null);
                    showToast(data.message, true);
                }
            }
            

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                if(cb){
                    cb(data.data);
                    showToast(data.message, false);
                }
                
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
}


export const getvalidateGateInInventory = (payload,callBack) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/job/validateGateInInventory', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_VALIDATE_INVENTORY, payload: data.data})
             
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_VALIDATE_INVENTORY, payload: null})
            }
           if(callBack){
            callBack(data)
           }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            callBack({success:false,message:error.message})
            //("Error****:", error.message);
        });
    }
};
export const getParcelDimensionsData = (payload,callBack) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('parcel/getDimensions', {
               ...payload
        }).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PARCEL_DIEMENSION_DATA, payload: data.data})
             
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_PARCEL_DIEMENSION_DATA, payload: null})
            }
           if(callBack){
            callBack(data)
           }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            callBack({success:false,message:error.message})
            //("Error****:", error.message);
        });
    }
};


