import * as React from 'react';
import history from '../../services/history';
import { styled, useTheme, alpha } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid"
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {Download, ExitToAppOutlined, InfoOutlined, Person, PhoneOutlined, Upload } from '@mui/icons-material'
import KeyIcon from '@mui/icons-material/Key';
import { BrowserRouter as Router, Routes, Route, Switch, Redirect } from "react-router-dom";
import { Menu, MenuItem, Box, Button, ThemeProvider, createTheme, Avatar } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { useState } from 'react';
import ChangePasswordView from '../Landing/ChangePasswordView';
import "../../static/css/dashboard2.css"
import LynkitLogo from "../../static/images/login/logo-white.svg"
import colors, { buttonClasses, textClasses } from '../../static/styles/theme';
import { useDispatch } from 'react-redux';
import { FETCH_ASN_DETAIL } from '../../redux/types';

const drawerWidth = 200;

{/* <AppBar position="fixed" sx={{ width: `calc(100% - ${open ? drawerWidth : 45}px)`, ml: `${open ? drawerWidth : theme.spacing(7) * 7}px`, backgroundColor: "white", color: "black" }}> */}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
//   paddingLeft: "45px",
  transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
//     ...(open && {
//     paddingLeft: 0,
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
}));


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
  
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
}));


const theme = createTheme({
    
});



export default function MiniDrawer({open, handleDrawerOpen}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const [viewModal,setViewModal] = useState({type:''})
    const userData = JSON.parse(localStorage.getItem("user"))

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = (event) => {
        setAnchorEl(null);
    }

    const handleLogout = () => {
        localStorage.removeItem('token')
        dispatch({type:'GET_WAREHOUSE',payload:{}})
        history.push("/")
    }

    const handleChangePassword = () =>{
        setViewModal({...viewModal,type:"viewChangePassword"})
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem>
                <Grid container>
                    <Grid container item sm={3} justifyContent="center" alignItems="center">
                        <Avatar sx={{ width: 32, height: 32 }}>
                            {userData.name[0]}
                        </Avatar>
                    </Grid>
                    <Grid container item direction={"column"} sm={9} justifyContent="center" sx={{pl: 2}}>
                        <Typography sx={{...textClasses.boldText, color: colors.themeOrange}}>{userData.name}</Typography>
                        <Typography sx={{fontSize: "12px", lineHeight: "17px", fontWeight: "400"}}>{userData.mobile}</Typography>
                    </Grid>
                </Grid>
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem dense>
                <Person sx={{mr: 2}}/>
                <Typography variant='caption'>{userData.designation}</Typography>
            </MenuItem>
            <MenuItem dense onClick={handleChangePassword}>
                <KeyIcon sx={{mr: 2}}/>
                <Typography variant='caption'>Change Password</Typography>
            </MenuItem>
            <MenuItem onClick={()=> handleLogout()} dense>
                <ExitToAppOutlined sx={{mr: 2}}/>
                <Typography variant='caption'>Logout</Typography>
            </MenuItem>
            
        </Menu>
    )
    const handleInwardClick = ()=>{
        // //(window.location)
        if(window?.location?.pathname=="/dashboard/data/inward"){
            dispatch({type: FETCH_ASN_DETAIL, payload: {data: null, count: 0}})
            history.replace();
            window.location.reload()
        }
        else{
            history.push("/dashboard/data/inward")
        }
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <AppBar open={open} style={{backgroundColor: "white", color: "black"}}>
                    <Toolbar>
                        {/* {!open &&  */}
                        <img src={LynkitLogo} height="40" />
                        {/* } */}
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: {  md: 'flex' } }}>
                            <Button variant="contained" size='small' startIcon={<Download />} sx={{display: { xs: 'none', md: 'flex' },mr: 1, ...buttonClasses.small, backgroundColor: colors.themeOrange}} onClick={handleInwardClick}>
                                <Typography variant='caption'>Inward Data Entry</Typography>
                            </Button>
                            <IconButton
                                sx={{p: 0}}
                                size="small"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                                color="inherit"
                                >
                                <Avatar sx={{ width: 32, height: 32 }}>
                                    {userData.name[0]}
                                </Avatar>
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                {renderMenu}
            </ThemeProvider>
            {viewModal.type=="viewChangePassword" &&
            <ChangePasswordView 
            viewModal={viewModal}
            setViewModal={setViewModal}
            handleMenuClose={handleMenuClose}
            />
            }
        </>
    );
}
