import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography, Dialog, DialogContent, Grid, IconButton } from '@mui/material'
import { showToast } from '../../../services/functions';
import { deleteItem } from '../../../redux/actions';
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { Cancel } from '@mui/icons-material';
import { modelInfoSingle } from './Utils';

function DeleteItem(props) {
    const dispatch = useDispatch();
    const { hide, action, selectedTemplates, urlPath, setDeleteStatus, setSelectedTemplates, setCheckedArray,clearFilter } = props
    const [showAll, setShowAll] = useState(false);
    const size = selectedTemplates.length;

    const handleDeleteItem = () => {
        let apiKeyword = modelInfoSingle[urlPath]["deleteAPIPath"]
        let apiKey = modelInfoSingle[urlPath]["apiKey"]

    
        let bodyData = {
            [apiKey]: selectedTemplates.length == 1 ? selectedTemplates[0].id : selectedTemplates.map(st => st.id)
        }
        
        // ////("selectedTemplates",{selectedTemplates});
        dispatch(deleteItem(bodyData, apiKeyword, ({message,success=false}) => {
            showToast(message, !success)
            if (success) {
                setCheckedArray([])
                setSelectedTemplates([])
                hide()
                clearFilter(null,true,true)
            }
        }))
    }

    const handleRemoveSelection = (templateId) => {
        let oldTemplates = [...selectedTemplates];
        const i = oldTemplates.map(o => o.id).indexOf(templateId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedTemplates(oldTemplates);
            setCheckedArray(oldTemplates.map((item)=>{return(item.id)}))
        }
        if(oldTemplates.length === 0){
            setDeleteStatus(false)
            setCheckedArray([])
            setSelectedTemplates([])
        }
    }

    const handleCloseConfirmModal = () => {
        setDeleteStatus(false)
    }


    return (
        <>
            <Dialog
                open={action != null && selectedTemplates.length !== 0}
                onClose={handleCloseConfirmModal}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"sm"}
            >
                <DialogContent>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>{action} the selected Item(s)</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                        {
                            selectedTemplates.slice(0, showAll ? size : 5).map((st, i) => {
                                return (
                                    <Grid item sm={4} xs={6} key={i}>
                                        <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                            <Grid item sm={10} xs={8}>
                                                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                    {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                                </Typography>

                                            </Grid>
                                            {
                                                handleRemoveSelection &&
                                                <Grid item sm={2} xs={4}>
                                                    <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st.id)}>
                                                        <Cancel sx={{ color: theme.themeOrange }} />
                                                    </IconButton>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        {
                            size > 5 &&
                            <>
                                <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                        {
                                            showAll
                                                ?
                                                <>Collapse</>
                                                :
                                                <>View All ({size})</>
                                        }
                                    </Typography>
                                </Grid>

                            </>
                        }
                    </Grid>
                    <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                        <Button variant='contained' size='small' sx={{ ...buttonClasses.lynkitBlackFill, minWidth: "150px" }} onClick={handleCloseConfirmModal} >
                            No, Cancel
                        </Button>


                        <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "150px" }}
                            disabled={selectedTemplates.length <= 0}
                            onClick={handleDeleteItem}
                        >
                            Yes, {action}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeleteItem;
