import React, { useState } from "react";
import { Switch,Box } from "@mui/material";
import { makeStyles } from "@mui/styles";



export default function ToggleButton(props) {

  const { isEnable = false, enabledName = "'Enabled'", disbledName = "'Disabled'", name = "", handleToggle = () => { }, isActionEnabledSwitch = true, width = 85 } = props
  const baseWidth = 85
  const classes = (makeStyles({
    root: {
      width: width + "px",
      height: "20px",
      padding: "0px",
    },
    switchBase: {
      padding: "0px",
      top: "2px",
      left: "2px",
      "&$checked": {
        "& + $track": {
          backgroundColor: "#FF7200",
          "&:after": {
            content: enabledName ? enabledName : "'Enabled'",
          },
        },
      },
    },
    thumb: {
      color: "white",
      width: "16px",
      height: "16px",
    },
    track: {
      borderRadius: "24px",
      backgroundColor: "#818181",
      opacity: "1 !important",
      "&:after, &:before": {
        color: "white",
        fontSize: "12px",
        position: "absolute",
        top: "2px",
        left: "20px",
      },
      "&:after": {
        content: disbledName ? disbledName : "'Disabled'",
      },
    },
    checked: {
      color: "#FF7200",
      position: "absolute",
      top: "2px",
      transform: `translateX(${65 + (Number(width || 0) - Number(baseWidth || 0))}px) !important`,
    },
  }))();

  return (
    <Box onClick={() => {
      if (isActionEnabledSwitch) {
        handleToggle({ target: { name: name, checked: !isEnable }, })
      }
    }}
      sx={{ cursor: isActionEnabledSwitch ? "pointer" : "not-allowed" }}
    >
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={isEnable}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </Box>
  );
}
