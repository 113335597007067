const intialState = {
    AllUserRoleData: []
};


const UserRoleReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_ALL_USERS_ROLE":
            return { ...state, AllUserRoleData: payload || []}
        default:
            return state;
    }
}
export default UserRoleReducer;