import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Paper, Avatar, Typography, Card, CardHeader, CardContent } from '@mui/material';

const GenericDashboardCard = ({ title, bgColor, icon, route, data }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(route);
    };

    return (
        <Grid item xs={12} md={3} className="cp" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper elevation={3} sx={{ flexGrow: 1 }}>
                <Card sx={{ boxShadow: "none", p: 1, height: '100%', display: 'flex', flexDirection: 'column' }} onClick={handleClick}>
                    <CardHeader
                        className="pt-2 pb-2"
                        avatar={
                            <Avatar className="br-1" sx={{ bgcolor: bgColor }} variant="square">
                                {icon}
                            </Avatar>
                        }
                        title={
                            <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="h6" gutterBottom>
                                {title}
                            </Typography>
                        }
                    />
                    <CardContent className="pt-0 pb-2" sx={{ flexGrow: 1 }}>
                        {data.map((item, index) => (
                            <Typography key={index} sx={{ textAlign: "left" }} variant="body2">
                                {item.label}: {item.value}
                            </Typography>
                        ))}
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    );
};

export default GenericDashboardCard;
