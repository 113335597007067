import axios from "../../services/Api";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GENERATE_QR,
  GENERATE_EXCEL,
  FETCH_COMPANY_DETAIL,
  FETCH_COMPANY_STATUS_COUNT,
  FETCH_COMPANY_TYPE_WISE_COUNT,
  FETCH_SUPERADMIN_USERS_PERMISSIONS,
  FETCH_SUPERADMIN_USERS_DETAIL,
} from "../types";


export const getCompany = (obj,callback) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/getCompany", obj).then(({ data }) => { 
      // alert(obj.warehouseId)
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        if (obj.type && obj.type == "company") {
          dispatch({ type: "GET_COMPANY", payload: data });
        }
        else if (obj.type && obj.type == "floor") {
          dispatch({ type: "GET_FLOOR", payload: obj.includeCount==true ?data: data.data });
        }
        else if (obj.type && obj.type == "warehouse") {
          dispatch({ type: "GET_WAREHOUSE", payload: data });
        }
        else {
          dispatch({ type: "GET_ALL", payload: data.data || [] });
        }

      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (obj.type == "company") {
          dispatch({ type: "GET_COMPANY", payload: {} });
        }
        else if (obj.type == "floor") {
          dispatch({ type: "GET_FLOOR", payload:  obj.includeCount==true?{}:[] });
        }
        else if (obj.type == "warehouse") {
          dispatch({ type: "GET_WAREHOUSE", payload:{} });
        }
        else {
          dispatch({ type: "GET_ALL", payload: [] });
        }
      }
      if(callback){
        callback(data)
      }
    })
    .catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if(callback){
        callback({success:false,message:error.message})
      }
      ////("Error****:", error.message);
    })
  };
};

export const setFloorInStore = obj => {

  return dispatch => {
    dispatch({ type: "SET_FLOOR", payload: obj });
  }

}


export const getAllSuperAdminPermissions = (payload, cb) => {
  return async (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post("admin/getAllPermission", {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_SUPERADMIN_USERS_PERMISSIONS, payload: data?.data })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_SUPERADMIN_USERS_PERMISSIONS, payload: [] })
          }


          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};



export const generateQrCode = (obj,callback) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/generateQR", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: GENERATE_QR, payload: data.downloadURL})
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GENERATE_QR, payload: "" });
        }
        if(callback){
          callback({success:data.success,message:data.message,awsUrl:data.awsUrl})
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        if(callback){
          callback({success:false,message:error.message,awsUrl:""})
        }
        ////("Error****:", error.message);
      });
  };
};
export const generateGridExcel = (obj,callback) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/grid/generateGridExcel", obj)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: GENERATE_EXCEL, payload: data.downloadURL})
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: GENERATE_EXCEL, payload: "" });
        }
        if(callback){
          callback({success:data.success,message:data.message,awsUrl:data.awsUrl})
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        if(callback){
          callback({success:false,message:error.message,awsUrl:""})
        }
        ////("Error****:", error.message);
      });
  };
};

export const getSuperAdminCompanyData = (obj,callback) => {
  return async dispatch => {
    dispatch({ type: FETCH_START });
    await axios.post("/company/getCompanySuperAdmin", obj).then(({ data }) => { 
      // alert(obj.warehouseId)
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS, payload: data.success });
        // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        if (obj.type && obj.type == "company") {
          dispatch({ type: "GET_SUPERADMIN_COMPANY", payload: data });
        }
        else if (obj.type && obj.type == "floor") {
          dispatch({ type: "GET_SUPERADMIN_FLOOR", payload: data.data });
        }
        else if (obj.type && obj.type == "warehouse") {
          dispatch({ type: "GET_SUPERADMIN__WAREHOUSE", payload: data });
        }
        else {
          dispatch({ type: "GET_SUPERADMIN_ALL", payload: data.data || [] });
        }

      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (obj.type == "company") {
          dispatch({ type: "GET_SUPERADMIN_COMPANY", payload: {} });
        }
        else if (obj.type == "floor") {
          dispatch({ type: "GET_SUPERADMIN_FLOOR", payload: [] });
        }
        else if (obj.type == "warehouse") {
          dispatch({ type: "GET_SUPERADMIN__WAREHOUSE", payload:{} });
        }
        else {
          dispatch({ type: "GET_SUPERADMIN_ALL", payload: [] });
        }
      }
      if(callback){
        callback(data)
      }
    })
    .catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if(callback){
        callback({success:false,message:error.message})
      }
      ////("Error****:", error.message);
    })
  };
};


export const getCompanyDetails = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('company/getCompanyDetail', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_COMPANY_DETAIL, payload: { data: data?.data || {}, count: data?.count } })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_COMPANY_DETAIL, payload: { data: {}, count: 0 } })
          }

          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};


export const getCompanyStatusCount = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('company/getCompanyStatusWiseCount', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_COMPANY_STATUS_COUNT, payload: data.data })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_COMPANY_STATUS_COUNT, payload: null })
          }


          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const getCompanyTypeWiseCount = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('company/getCompanyTypeCounts', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_COMPANY_TYPE_WISE_COUNT, payload: data.data })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_COMPANY_TYPE_WISE_COUNT, payload: null })
          }


          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};


export const addCompany = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/users/addCompany', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const addWarehouse = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/company/addWarehouse', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const addFloor = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/company/addFloor', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};
export const updateCompany = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/company/updateCompanyData', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const enableDisableCompanyStatus = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/company/updateCompanyStatus', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};
export const addGridDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios.post('/company/addGridDetails', {
      ...payload
    }
    ).then(({ data }) => {
      if (data.success) {
        dispatch({ type: FETCH_SUCCESS });
      }
      else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (cb) {
        cb(data)
      }

    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      if (cb) {
        cb({ success: false, message: error.message })
      }
    });
  }
};


export const enableDisableUsersStatus = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/users/changeStatusUser', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const addSuperAdminUsers = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('users/createUserSuperAdmin', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
          }
          if (cb) {
              cb(data)
          }

      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

export const getSuperAdminUsersDetails = (payload, cb) => {
  return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/users/getUserDetail', {
          ...payload
      }
      ).then(({ data }) => {
          if (data.success) {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({ type: FETCH_SUPERADMIN_USERS_DETAIL, payload: { data: data?.data || {}, count: data?.count } })
          }
          else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: FETCH_SUPERADMIN_USERS_DETAIL, payload: { data: {}, count: 0 } })
          }

          if (cb) {
              cb(data)
          }
      }).catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          if (cb) {
              cb({ success: false, message: error.message })
          }
      });
  }
};

