import {
    Button, Box, FormControl
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useCallback, useRef } from 'react';
import { showToast} from '../../../services/functions';
import theme, { inputClasses, textClasses, buttonClasses } from '../../../static/styles/theme';

const GenericFileUpload = ({ onChange, onUpload, payload, previewAvailable, onDelete, handleDownload, isDisableUpload, viewExcel }) => {

    const fileRef = useRef()

    const handleDeleteFile = useCallback(() => {
        fileRef.current.value = "";
        onDelete()
    }, [onDelete])


    const handleUploadExcelFile = async (e) => {
        e.preventDefault();
        let files= e.target?.files || ""
        if(e.target?.files?.length){
            let fileObj =files[0];
            if (!fileObj || !fileObj.name) {
                showToast("File name is required in uploaded file!")
                return onChange({target:{files:""}});
            }
            let fileExtension = fileObj.name.split('.');
            if(!["xlsx","xls"].includes(fileExtension[fileExtension.length - 1])) {
                showToast("You can upload excel file only!!")
                return onChange({target:{files:""}});
            }
        }
        else{
            return onChange({target:{files:""}});
        }
        return onChange({target:{files:files}});
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box border={1} sx={{ padding: "5px", borderColor: 'grey.500', borderRadius: "4px", width: "fit-content" }}>
                <a target="_blank" rel="noopener noreferrer" onClick={handleDownload}>
                    <Button
                        variant="contained"
                        spacing={1}
                        className='RF_sampledownload-btn'
                        sx={{ ...buttonClasses.lynkitBlackFill, height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px", }}>
                        <DownloadIcon /> Sample Download
                    </Button>
                </a>
                <FormControl spacing={1} sx={{ marginRight: "5px", marginTop: "2px", cursor: "pointer" }}>
                    <input
                        type="file"
                        name="upload"
                        id="upload"
                        className='RF_fileupload-input'
                        ref={fileRef}
                        onChange={(e)=>handleUploadExcelFile(e)}
                    />
                </FormControl>
                <Button
                    variant="outlined"
                    spacing={1}
                    disabled={!payload?.file}
                    className='RF_outline-btn'
                    onClick={(e)=>handleDeleteFile(e)}
                    sx={{ ...buttonClasses.lynkitOrangeFill, marginRight: "5px", borderRadius: "8px" }}>
                    <DeleteIcon />
                </Button>
                {previewAvailable && viewExcel && <Button
                    variant="outlined"
                    spacing={1}
                    className='RF_outline-btn'
                    disabled={!payload?.file}
                    color="primary"
                    sx={{ ...buttonClasses.lynkitOrangeFill, marginRight: "5px", borderRadius: "8px" }}
                    onClick={(e)=>viewExcel(e)}
                >
                    <VisibilityIcon />
                </Button>}
                {onUpload && <Button
                    variant="contained"
                    spacing={1}
                    disabled={isDisableUpload}
                    sx={{
                        ...buttonClasses.lynkitOrangeEmpty,
                        height: "34px", fontSize: "12px", marginRight: "5px", borderRadius: "8px"
                    }}
                    onClick={(e)=>onUpload(e)}
                >
                    <FileUploadIcon /> Upload File
                </Button>}
            </Box>
        </Box>
    )
}

export default GenericFileUpload;