import {
    Breadcrumbs, Button, Grid, Paper, Typography, TablePagination
}
from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useForm} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { showToast } from '../../../../services/functions'
import history from '../../../../services/history'
import theme , { buttonClasses, cardClasses, textClasses }  from '../../../../static/styles/theme'
import { CustomControlledInput } from '../../../Components/Utils'
import { CheckBox } from '@mui/icons-material'
import { createStockReturn, getAllWarehouses} from '../../../../redux/actions'
import { getAllAdjustmentReasons } from '../../../../redux/actions/InventoryAdjustment'
import { GridDeleteIcon } from '@mui/x-data-grid'



export default function ReturnProcess(){

    const dispatch = useDispatch();
    const { register, control:control, handleSubmit, watch, reset, setValue, getValues, formState: {errors}} = useForm();
    const { register: register2, control: control2,getValues:getValues2, handleSubmit: handleSubmit2, watch: watch2, formState: {errors: errors2}} = useForm();
    const [tableData, setTableData] = useState([]);

    const [selectedData, setSelectedData] = useState({"count":0});

    const allWarehouses = useSelector(state => state.inward.allWarehouses);
    const allReasons = useSelector(state => state.inventoryAdjustment.adjustmentReasons);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const totalRows = 15;

    const disableFields = (fieldName) => {
        return watch(fieldName) ? false: true
    }

    const handleChangeEntity=(e,option)=>{
        //console.log("e,option",e,option)
        setSelectedData(option?.value && option || {})
    }

    const fields = {
        warehouseId: {
            "_id": "warehouseId",
            "type": "select",
            "label": "Select Warehouse",
            "description": "Select",
            "required": true,
            "placeholder": "Select warehouse",
            "values": allWarehouses.map((w, i) => ({label: w.name, value: w._id})),
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        returnType: {
            "_id": "returnType",
            "type": "radio",
            "label": "Return Category",
            "description": "Select",
            "required": true,
            "placeholder": "Select type",
            "values": [
                {
                    "label": "Stock Transferred To User",
                    "value": "stockTransfer"
                },
                // {
                //     "label":"Dispatched",
                //     "value":"dispatch"
                // }

            ],
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        referenceNumber: {
            "_id": "referenceNumber",
            "type": "input",
            "label": "Reference Number",
            "description": "rno",
            "required": true,
            "placeholder": "Enter reference number",
        },
        returnReason: {
            "_id": "reasonKey",
            "type": "select",
            "label": "Reason",
            "description": "Select",
            "required": true,
            "placeholder": "Select reason",
            "values": allReasons.map((r, i) => ({label: r.reason, value: r._id})),
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        categoryName: {
            "_id": "categoryName",
            "type": "radio",
            // "disabled":true,
            "label": "Adjustment category",
            "description": "",
            "required": true,
            "placeholder": "",
            "values": [
                {
                    "label": "Packages",
                    "value": "package"
                },
                {
                    "label": "Items",
                    "value": "item"
                },
            ]
        },
        categoryKey: {
            "_id": "categoryKey",
            "type": "select",
            "disabled":disableFields("warehouseId"),
            "label": `Select ${watch("returnType")}`,
            "placeholder": `Select ${watch("returnType")}`,
            "description": "Select",
            "required": true,
            "dynamic": true,
            "auth": true,
            "apiDomain": "/v1/",
            "apiEndPoint": "manageReturn/getStockPackageItemData",
            "apiMethod": "post",
            "fetched": false,
            "name": "Item Master List",
            "apiDataReturnKey": "data",
            "multipleAPICall": true,
            "onChange":handleChangeEntity,
            "apiParameters": [
                {
                    "label": "returnCategory",
                    "value": watch("returnType")
                },
                {
                    "label": "entityType",
                    "value": watch("categoryName")
                },
                {
                    "label": "warehouseId",
                    "value": watch("warehouseId")
                },
                {
                    "label": "entityName",
                    "value": "$self"
                }
            ],
            "keyForLabel": watch("categoryName") == "package" ? "packageName" : "SKU_Number",
            "keyForValue": watch("categoryName") == "package" ? "packageName" : "SKU_Number",
            "suffix": watch("categoryName") == "package" ? "packageId" : "itemId",
            "extraValue":["count","cellDetail","packageId","itemId"],
            "onChangeSet": {

            },
            "subtype": "single",
            "forType": "all",
            "elementType": "Selection Type",
            "sortType": 2
        },
        quantity: {
            "_id": "quantity",
            "type": "number",
            "disabled":disableFields("warehouseId"),
            "label": "To Be Returned Quantity",
            "description": "rno",
            "required": true,
            "placeholder": "To be return quantity",
        },
        actionType: {
            "_id": "actionType",
            "type": "radio",
            "label": "Action Type",
            "description": "",
            "required": true,
            "placeholder": "",
            "values": [
                {
                    "label": "Add QTY",
                    "value": "ADD"
                },
                {
                    "label": "Subtract QTY",
                    "value": "SUBTRACT"
                }
            ]
        },
        remark: {
            "_id": "remark",
            "type": "textarea",
            "label": "Remark(s)",
            "description": "rno",
            "required": false,
            "placeholder": "Enter remark(s)",
        },

    }

    const handleAddRow = (data) => {
        if(Number(data.quantity) == 0){
            showToast("Quantity cannot be 0", true)
        }
        else if(data.quantity <= selectedData.count){
            if (tableData.map(r => r.entityID).indexOf(data.categoryName == "package" ? selectedData.packageId : selectedData.itemId) < 0){
                setTableData([
                    {
                        entityID: data.categoryName == "package" ? selectedData.packageId : selectedData.itemId,
                        count: Number(data.quantity),
                        entityName: selectedData.label,
                        // entityName: data.categoryName == "package" ? selectedData.packageName : selectedData.SKU_Number,
                        entityType: data.categoryName,
                        oldLocation: selectedData?.cellDetail?.length > 0
                            ? selectedData.cellDetail?.map((item) => (
                                item.cellName + (item.rackName ? " - " + item.rackName : " ")
                            )) : "-"
                    }, ...tableData
                ])
                
                setValue("categoryKey", null);
                setValue("quantity", "")
                setSelectedData({})

            }
            else{
                showToast(`${selectedData.label} already added`, true)
            }
            
        }
        else{
            showToast("Quantity cannot be greater than available quantity", true)
        }
    }

    const handleDeleteRow = (i) => {
        let oldRows = [...tableData];
        oldRows.splice(i, 1);
        setTableData([...oldRows])
    }

    const handleSave = (data) => {
        if(getValues2('reasonKey') === ''){
            showToast("Reason not exist, Please provide a valid reason!", true)
        }
        let payload = {
            warehouseId: watch("warehouseId"),
            returnCategory: watch("returnType"),
            referenceNumber: data.referenceNumber,
            stockData: tableData,
            reasonID: data.reasonKey,
            remark: data.remark,
            isTriggerJobCreation: true
        }
        // //console.log("Payload", payload);
        dispatch(
            createStockReturn(payload, (data) => {
                showToast(data.message, !data.success)
                if(data.success){
                    history.push("/dashboard/inventory/returns");
                }
                
            })
        )
    }
    

    useEffect(() => {
        setValue("returnType","stockTransfer")
        setValue("categoryName","package")
        dispatch(getAllWarehouses({type: "warehouse"}));
        dispatch(getAllAdjustmentReasons({type: "returnToSeller"}));
    }, [])


    useEffect(() => {
        if(watch('warehouseId')) {
            setTableData([]);
            setValue("categoryKey", null);
            setValue("quantity", "")

        }
    },[watch('warehouseId')])


    return (
        <>
            <Typography sx={{...textClasses.cardTitle}}>Returns</Typography>
            <Breadcrumbs sx={{mb: 1}}>
                <Typography sx={{...textClasses.normalText, fontSize: "12px", color: theme.themeOrange}}>
                    <Link style={{color: theme.themeOrange, textDecoration: "none"}} to="/dashboard/inventory/returns">
                        Manage Returns
                    </Link>
                </Typography>
                
                <Typography sx={{...textClasses.normalText, fontSize: "12px"}}>Add New Retun</Typography>
            </Breadcrumbs>

            <Grid container>
                <Paper elevation={1} sx={{...cardClasses.basic, borderRadius: "8px", p: 2, width: "100%"}}>
                    <Grid container>
                        <Grid container item sm={6} xs={6}>
                            <Typography sx={{...textClasses.cardTitle}}>Add New Return</Typography>
                        </Grid>
                        <Grid container item sm={3} xs={6} justifyContent="end">
                            
                        </Grid>
                        <Grid container item sm={3} xs={6} justifyContent="end">
                            <CustomControlledInput action={"use"} register={register2} component={fields.warehouseId} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                        </Grid>
                    </Grid>

                    
                    <Grid container sx={{mt: 1}}>
                        <Grid container item sm={2} xs={3} alignItems="center">
                            <Typography sx={{...textClasses.boldText}}>Return Category:</Typography>
                        </Grid>
                        <Grid container item sm={10} xs={9}>
                            <Grid container item sm={6} xs={6} alignItems="center" justifyContent={"left"}>
                                <CustomControlledInput  component={fields.returnType} register={register} errors={errors} control={control} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt: 1}}>
                        <Grid container item sm={2} xs={3} alignItems="center">
                            <Typography sx={{...textClasses.boldText}}>Reference Number:</Typography>
                        </Grid>
                        <Grid container item sm={3} xs={9}>
                            <CustomControlledInput action={"use"} register={register2} component={fields.referenceNumber} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt: 2}}>
                        <Grid container item sm={2} xs={3} alignItems="center">
                            <Typography sx={{...textClasses.boldText}}>Reason:</Typography>
                        </Grid>
                        <Grid container item sm={3} xs={9}>
                            <CustomControlledInput action={"use"} register={register2} component={fields.returnReason} control={control2} errors={errors2} autoFillOnFocus={() => {}}/>
                        </Grid>
                    </Grid>
                    <Grid container sx={{mt: 2}}>
                        <Grid container item xs={3} sm={2} alignItems="center">
                            <Typography sx={{...textClasses.boldText}}>Return Type:</Typography>
                        </Grid>
                        <Grid container item xs={6} sm={9} alignItems="center">
                            <CustomControlledInput action={"use"} register={register} component={fields.categoryName} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                        </Grid>
                    </Grid>
                    {
                        watch('warehouseId')?'':<Typography sx={{
                             ...textClasses.t12n,
                                color:'red',margin:'0 0 -10px 18%'
                            }}>Select Warehouse</Typography>
                    }
                    <Grid container sx={{mt: 2}}>
                        <Grid container item xs={3} sm={2} alignItems="center">
                        </Grid>
                        <Grid container item xs={6} sm={10} alignItems="center">
                            <Grid container sm={3} xs={4} sx={{pr: 1, height: "100%"}} alignItems="center" justifyContent={"center"}>
                                <CustomControlledInput action={"use"} register={register} component={fields.categoryKey} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                            <Grid container sm={3} xs={4} sx={{pl: 1, height: "100%"}} alignItems="center" justifyContent={"center"}>
                                <CustomControlledInput action={"use"} register={register} component={fields.quantity} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid>
                            {/* <Grid container sm={4} xs={3} sx={{pl: 2, height: "100%"}} alignItems="center" justifyContent={"center"}>
                                <CustomControlledInput action={"use"} register={register} component={fields.actionType} control={control} errors={errors} autoFillOnFocus={() => {}} multiline={false}/>
                            </Grid> */}
                            {/* <Grid>
                                <CustomControlledInput action={"use"} register={register} component={fields.filedType} control={control} errors={errors} autoFillOnFocus={()=>{}} multiline={false}/>

                            </Grid> */}
                            <Grid container sm={2} xs={1} alignItems="center" justifyContent={"center"}>
                                <Button variant='outlined' size='small' sx={{...buttonClasses.lynkitOrangeFill}} disabled={!selectedData} onClick={handleSubmit(handleAddRow)}>Add row</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container item xs={3} sm={2} alignItems="center">
                        </Grid>
                        <Grid container item xs={6} sm={10} alignItems="center">
                            <Grid container sm={3} xs={4} sx={{pr: 1, height: "100%"}} alignItems="center" justifyContent={"center"}>
                            <Typography sx={{fontSize: "11px"}}>*add multiple SKUs one-by-one, the table below showing list of SKUs to be returned.</Typography>

                            </Grid>
                            <Grid container sm={3} xs={4} sx={{pl: 1, height: "100%"}} alignItems="center" justifyContent={"center"}>
                                {
                                    selectedData  &&
                                    <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 2}}>
                                        Available QTY: 
                                        {
                                           selectedData?.count &&  Number(selectedData?.count) || "N/A"
                                        }
                                        
                                    </Typography>
                                }
                                
                            </Grid>
                            <Grid container sm={4} xs={3} sx={{pl: 2, height: "100%"}} alignItems="center" justifyContent={"center"}>
                                
                            </Grid>
                            <Grid container sm={2} xs={1} alignItems="center" justifyContent={"center"}>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        tableData.length > 0 && 
                    
                        <Paper elevation={1}>
                            <table style={{width: "100%", marginTop: "15px"}}>
                                <thead style={{backgroundColor: theme.themeGray}}>
                                    <tr className='roundedRow'>
                                        
                                        {
                                            ["Entity Name", "Entity Type", "QTY", "Old Location", "Action"].map((head, i) => {
                                                return (
                                                    <td style={{padding: "13px 10px 13px 10px", ...textClasses.boldText, textAlign: "center"}}>{head}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.slice(page*limit, page*limit + limit).map((row, i) => {
                                            return(
                                                <tr
                                                    key={i}
                                                    style={{borderBottom:"1px"}}
                                                >
                                                    <td style={{padding:"10px 5px 10px  5px",...textClasses.normalText, textAlign:"center", textTransform:"capitalize"}}>
                                                        {row.entityName}
                                                    </td>
                                                    <td style={{padding:"10px 5px 10px  5px",...textClasses.normalText, textAlign:"center", textTransform:"capitalize"}}>
                                                        {row.entityType}

                                                    </td>
                                                    <td style={{padding:"10px 5px 10px  5px",...textClasses.normalText, textAlign:"center", textTransform:"capitalize"}}>
                                                        {row.count}
                                                    </td>
                                                    <td style={{padding:"10px 5px 10px  5px",...textClasses.normalText, textAlign:"center", textTransform:"capitalize"}}>
                                                        {row.oldLocation}
                                                    </td>
                                                    <td style={{padding: "10px 5px 10px 10px", ...textClasses.normalText, textAlign: "center", textTransform:"capitalize"}} onClick={() => handleDeleteRow(i)}>
                                                        <Typography sx={{...textClasses.normalText, color: theme.themeOrange, cursor: "pointer"}}><GridDeleteIcon/></Typography>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {
                                tableData.length > 0 &&
                                <Grid container justifyContent="center" justifyItems="center">
                                    <TablePagination
                                        component="div"
                                        count={tableData.length}
                                        page={page}
                                        onPageChange={(e, newPage) => setPage(newPage)}
                                        rowsPerPage={limit}
                                        onRowsPerPageChange={(e) => {setLimit(e.target.value); setPage(0)}}
                                        sx={{
                                            ".MuiTablePagination-displayedRows": {
                                                "margin-top": "1em",
                                                "margin-bottom": "1em"
                                            },
                                            ".MuiTablePagination-selectLabel": {
                                                "margin-top": "1em",
                                                "margin-bottom": "1em"
                                            }
                                        }}
                                    />
                                </Grid>
                            }
                        </Paper>
                    }
                    {tableData.length ? (<Grid container direction={"row"} sx={{mt: 2}}>
                        <Typography sx={{...textClasses.boldText, color: theme.themeOrange}}>Total QTY To Be Returned: </Typography>
                        <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 3}}>
                            Packages: {tableData.filter(td => td.entityType == "package").map(td => td.count).reduce((a, b) => a+b, 0)}
                        </Typography>
                        <Typography sx={{...textClasses.normalText, color: theme.themeOrange, ml: 3}}>
                            Items: {tableData.filter(td => td.entityType == "item").map(td => td.count).reduce((a, b) => a+b, 0)}
                        </Typography>
                    </Grid>):''}
                    <Typography sx={{...textClasses.boldText, mt: 2}}>Remarks</Typography>
                    <Grid container sx={{mt: 1}}>
                        <Grid item sm={6} xs={12}>
                            <CustomControlledInput action={"use"} register={register2} component={fields.remark} control={control2} errors={errors2} autoFillOnFocus={() => {}} multiline={false}/>
                        </Grid>
                    </Grid>
                    <Grid container direction={"row"} sx={{mt:2}} >
                        <CheckBox  size="small" sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}></CheckBox>
                        <Typography sx={{...textClasses.boldText, color: theme.themeBlack, ml: 3}}>Create a return put-away job with old locations</Typography>

                    </Grid>
                    <Grid container direction={"row"} sx={{mt: 2}}>
                        <Button variant="outlined" sx={{...buttonClasses.lynkitBlackFill}} onClick={()=>window.location.reload()}>Clear All</Button>
                        <Button disabled={disableFields("warehouseId") || !watch2("referenceNumber" || !watch2("reasonKey")) } variant="outlined" sx={{...buttonClasses.lynkitOrangeEmpty, ml: 2}} >Submit & Create New</Button>
                        <Button  disabled={disableFields("warehouseId") || !watch2("referenceNumber") || !watch2("reasonKey") } variant="outlined" sx={{...buttonClasses.lynkitOrangeFill, ml: 2}} onClick={handleSubmit2(handleSave)}>Submit</Button>
                    </Grid>
                </Paper>
            </Grid>

        </>
    )
}