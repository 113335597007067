import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import {
    FormControl, Chip, Button, TextField, Stack,IconButton,Collapse,
    MenuItem, Typography, Box, Checkbox, ListSubheader, ListItemText,Autocomplete
} from '@mui/material'
import { selectStylesOverride } from "../../../../utils/util";
import {ExpandLess,ExpandMore, Clear as ClearIcon,
} from '@mui/icons-material';

import theme from "../../../../static/styles/theme";
import CustomModel from '../../../Components/CustomModels/CustomModel1';
import { showToast } from '../../../../services/functions';
import { buttonClasses, textClasses } from '../../../../static/styles/theme';


const JobTypeModel = (props) => {
    const { open, hide, allJobActions, initialState, reducer,submitHandler,buttonActionOn} = props
    const [state, dispatch] = useReducer(reducer, initialState);
    const [deleteData, setDeleteData] = useState([]);
    const [expand, setExpand] = useState(false);

    
    useEffect(() => {
        if(["Edit","Add"].includes(open)){
            dispatch({ type: "set_initial_state", valueObj: initialState })
        }
        else{
            // ////("deleteData", {deleteData:buttonActionOn["deleteData"] })
            setDeleteData(buttonActionOn["deleteData"] || []) 
        }
    }, [initialState])

    const handleValidationSubmit = (clickType="submit") => {
        if(["Edit","Add"].includes(open)){
            let {name="",actionTypes=[],jobTypeId=""}=state, result={name,actionTypes}
            if (!name || !name.trim()) {
                showToast("Please provide Job Type Name!", true);
                return;
            }
            else{
                result["name"]=name.trim()
            }
    
            if (!actionTypes || actionTypes.length==0) {
                showToast("Please select minimum 1 action type!", true);
                return;
            }
            else{
                result["actionTypes"]=actionTypes.map(dl=>dl.actionId)
            }
            if(open=="Edit"){
                result["jobTypeId"]=jobTypeId
            }
            // ////("result11111111",{result,clickType,open_type:open})
            submitHandler({result,clickType,open_type:open})
            // addJobTypes
        }
        else{
            let jobTypeId=[]
            for(let ele of deleteData){
                if(ele._id){
                    jobTypeId.push(ele._id)
                }
            }
            submitHandler({
                result: {jobTypeId},
                clickType,
                open_type: open
            })
        }
    }

    const actionCovertor = () => {
        let arr = []
        // ////("allJobActions", allJobActions);
        for (let ele of allJobActions) {
            if (ele?.categoryKey && ele?.actions && ele?.actions?.length > 0) {
                arr.push({ isCategory: true, actionName: ele.categoryKey, categoryKey: ele.categoryKey })
                arr.push(...ele.actions)
            }
        }
        // ////("hhhh", arr);
        return arr
    }

    const handleDelete=(itemIndex)=>{

        let tempData=[...deleteData]
        tempData.splice(itemIndex,1)
        setDeleteData(tempData)

        ////("handleDelete",itemIndex);
    }

    let renderChip=(name,index=0)=>{
        return(
            <Chip
                label={<Typography sx={{ fontSize: ".8rem", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    {name || ""}
                </Typography>}
                onDelete={()=>handleDelete(index)}
                key={index}
                sx={{m:0.5,color: theme.themeOrange,backgroundColor:theme.themeLightOrange,border:"1px solid #FF7200"}}
                deleteIcon={
                    <IconButton
                        disabled={!deleteData || deleteData.length < 2}
                    >
                        <ClearIcon sx={{color: theme.themeOrange}}/>
                    </IconButton>
                }
            />
        ) 
    }

    return (
        <>
            <CustomModel
                autoClose={false}
                data-testid={open}
                show={open || false} onClose={() => hide()}
                minWidth={200} maxWidth={open == "Add" && 500 || 400}
                pt={1} pb={3} pl={3} pr={3}
                maxHeight={"90vh"} viewCloseIcon={["Edit","Add"].includes(open)}
                childrenMaxHeight={"inherit"}
                // childrenMaxHeight={"85vh"}
                Title={["Edit","Add"].includes(open) && `${open} Job Type`}
            >
                {["Edit","Add"].includes(open) ? 
                <Box>
                    <Box margin={2}>
                        <FormControl
                            size="small"
                            fullWidth
                            data-testid={open+"-jobName-jobtype"}
                        >
                            <TextField
                                label={<Typography sx={{ fontSize: "12px" }}>Job Name</Typography>}
                                labelId="job-name-label"
                                type='text'
                                size="small"
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                focusColor='red'
                                value={state?.name || ""}
                                onChange={(e) => dispatch({ type: "changed_name", value: e.target.value })}
                            />
                        </FormControl>
                    </Box>
                    <Box margin={2}>
                        <FormControl
                            size="small"
                            fullWidth
                            data-testid={open+"-actionRender-jobtype"}
                        >
                            <Autocomplete
                                multiple
                                labelId="action"
                                size='small'
                                sx={{
                                    fontSize: "12px",
                                    ...selectStylesOverride,
                                    "& input::placeholder": {
                                        fontSize: "12px",
                                    },
                                }}
                                limitTags={5}
                                options={actionCovertor()}
                                getOptionLabel={(option) => option.actionName}
                                onChange={(event, option) => {
                                    // ////("options>>>>", option);
                                    dispatch({ type: "change_action", value: option })
                                }}
                                disableCloseOnSelect
                                renderOption={(props, option, { selected }) => {
                                    const { isCategory = false, categoryKey, actionName, actionId } = option
                                    // ////("selected",selected,props,option)
                                    if (isCategory) {
                                        return (
                                            <ListSubheader key={categoryKey} sx={{ mt: 0, pt: 0 }}>
                                                {actionName}
                                                <Checkbox
                                                    onChange={(e) => {
                                                        // ////("checkbox",e.target.checked)
                                                        dispatch({ type: "select_by_category", categoryKey: categoryKey, checked: e.target.checked })
                                                    }}
                                                    checked={allJobActions.find(dl => dl?.categoryKey == categoryKey)?.actions?.length == state?.actionTypes.filter(el => el.categoryKey == categoryKey)?.length}
                                                />
                                            </ListSubheader>
                                        )
                                    }
                                    else {
                                        return (
                                            <MenuItem value={actionId} key={actionId} sx={{ ml: 2 }} {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                />
                                                <ListItemText primary={actionName} />
                                            </MenuItem>
                                        )
                                    }
                                }
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={<Typography sx={{ fontSize: "12px" }}>Action Type</Typography>}
                                    />
                                )}
                                value={state?.actionTypes || []}
                            />
                        </FormControl>
                    </Box>
                </Box>
                :
                    <Box>
                        <Typography
                            id="modal-modal-title"
                            variant="h7"
                            component="h2"
                            data-testid="deleteText-jobtype"
                            sx={{mt:2,mb:2, display: 'flex',alignItems:"center", justifyContent: 'center',flexDirection:"column", zIndex: 100 }}
                        >
                            <span className='fs-24 font-Nunito fw-1000'> Are You Sure ?</span>
                            <span className='fs-24 font-Nunito fw-1000'> Delete The Job Type (s)</span>
                            
                        </Typography>
                        <Stack 
                            direction="row" 
                            flexWrap="wrap"
                            justifyContent="start"
                            alignItems="start"
                            data-testid="deleteData-jobtype"
                        >
                            {
                                deleteData?.length && deleteData.slice(0,5).map((dl,index)=>renderChip(dl.name || "",index))
                            }

                            {
                                deleteData?.length>5 &&
                                <>
                                    {!expand &&
                                        <Typography
                                            sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange,mt:1 }}
                                        >
                                            View All <ExpandMore style={{ cursor: "pointer" }} onClick={() => setExpand(true)} />
                                        </Typography>
                                    }
                                    <Collapse in={expand} timeout="auto" unmountOnExit>
                                            {deleteData.slice(5).map((dl,index)=>renderChip(dl.name || "",5+index))}
                                    </Collapse>
                                    {expand &&
                                        <Typography
                                            sx={{ ...textClasses.normalText, textAlign: "center", color: theme.themeOrange ,mt:1}}
                                        >
                                            View Less <ExpandLess onClick={() => setExpand(false)} style={{ cursor: "pointer" }} />
                                        </Typography>
                                    }
                                </>
                            }
                        </Stack>
                    </Box>
                }
                <Box
                    sx={{
                        marginTop: "25px",
                        padding: "0px 10px 0px 10px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                    >
                    <Button
                        data-testid="cancelButton-jobtype"
                        fullWidth
                        variant="contained"
                        size="small"
                        onClick={() => hide()}
                        sx={{ ...buttonClasses.lynkitOrangeEmpty, fontSize: ".7rem" }}

                    >Cancel</Button>

                    {open === "Add" && <Button
                        fullWidth
                        size="small"
                        data-testid="addNewButton-jobtype"
                        variant="contained"
                        sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 2, fontSize: ".7rem" }}
                        onClick={() => handleValidationSubmit("submitAddNew")}
                    > Submit & Add New</Button>}

                    <Button
                        fullWidth
                        variant="contained"
                        data-testid="submit-jobtype"
                        size="small"
                        sx={{ ...buttonClasses.lynkitOrangeFill, ml: 2, fontSize: ".7rem" }}
                        onClick={() => handleValidationSubmit("submit")}
                    > {open=="Delete" && "Delete" || "Submit"}</Button>

                </Box>
            </CustomModel>
        </>
    )
}


export default JobTypeModel;
