import {INIT_URL, FETCH_TEMPLATES, FETCH_STD_COMPS, FETCH_PRV_COMPS, FETCH_TEMPLATE, FETCH_TEMPLATES_TYPE, FETCH_STATUS_TYPE, FETCH_TEMPLATES_ERR, FETCH_USERS, FETCH_STATUS_COUNT, FETCH_REGEX_LIST, FETCH_ALL_TEMPLATE_TYPES} from "../types";

const INIT_STATE = {
  templateTypes: [],
  allTemplates: [],
  totalRows: 0,
  usedComponents: [],
  standardComponents: [],
  viewTemplateObj: null,
  templateTypes: [],
  statusTypes: [],
  statusCount: null,
  predefinedElements: [],
  allRegex: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case FETCH_ALL_TEMPLATE_TYPES: return { ...state, templateTypes: action.payload}
    
    case FETCH_TEMPLATE: return { ...state, viewTemplateObj: action.payload}
    case FETCH_TEMPLATES: {
      return {...state, allTemplates: action.payload.row, totalRows: action.payload.count};
    }
    case FETCH_TEMPLATES_ERR: {
      return {...state, allTemplates: []};
    }

    case FETCH_PRV_COMPS: {
        return {...state, usedComponents: action.payload};
    }

    case FETCH_STD_COMPS: {
        return {...state, standardComponents: action.payload.data, predefinedElements: action.payload.predefinedElement};
    }

    case FETCH_TEMPLATES_TYPE: return { ...state, templateTypes: action.payload}
    case FETCH_STATUS_TYPE: return { ...state, statusTypes: action.payload}
    case FETCH_REGEX_LIST: return { ...state, allRegex: action.payload}
    
    case FETCH_STATUS_COUNT: return {...state, statusCount: action.payload}

    default:
      return state;
  }
}