import React, { useEffect, useRef, useState,useCallback } from 'react'
import {
   Typography, Button, Grid, FormControl, IconButton, FormControlLabel, FormLabel, RadioGroup, Radio, 
    Box, Dialog, DialogContent, Switch, Checkbox, 
    Autocomplete,TextField,FormGroup,DialogTitle,DialogActions,DialogContentText

} from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { selectStylesOverride } from '../../../utils/util';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import theme, { textClasses, buttonClasses } from '../../../static/styles/theme';
import { styled } from '@mui/material/styles';                 
import Barcode from 'react-barcode';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import QRCode from 'qrcode.react';
import { useDispatch } from 'react-redux';
import {
     getLabelConfigration, uploadLabelLogo, fetchFile
} from '../../../redux/actions';
import html2canvas from 'html2canvas';
import { Document, Page, Image, View} from '@react-pdf/renderer';
import { showToast,convertDocsBase64,isFileValid } from '../../../services/functions';
import ReactToPrint from 'react-to-print';
import SignatureCanvas from 'react-signature-canvas';
import GenericLoader from '../../Components/Generic/GenericLoader';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));


const ConfigFieldSection = ({ header,handleChange, configType}) => {
    return (
        <>
            {
                header.map((h, i) => {
                    return (
                        <Grid item sm={4} xs={4}>
                            {
                                configType == "label"
                                ?
                                    <FormControlLabel
                                        value={h.showInLabel ? h.showInLabel : false}
                                        control={
                                            <Checkbox
                                                checked={h.showInLabel ? h.showInLabel : false}
                                                onChange={() => handleChange(h.name)} />
                                        }
                                        label={h.label}
                                    />
                                    :
                                    <FormControlLabel
                                        value={h.encodeConfig ? h.encodeConfig : false}
                                        control={
                                            <Checkbox
                                                checked={h.encodeConfig ? h.encodeConfig : false}
                                                onChange={() => handleChange(h.name)}
                                            />
                                        }
                                        label={h.label}
                                    />
                            }
                            
                        </Grid>
                    )
                })
            }
        </>
    )
}

const ConfigureLabel = ({labelConfig=[], encodeConfig=[], showModal, handleCloseModal, saveConfig}) => {
    const [config, setConfig] = useState(labelConfig);
    const [encode, setEncode] = useState(encodeConfig);
    const [configType, setConfigType] = useState("label");

    useEffect(() => {
        setConfig(labelConfig);
        setEncode(encodeConfig);
    }, [showModal]);

    const configOptions = configType == "label" ? config : encode;

    const handleChange = (id) => {
        ////("Changing ID", id);
        if(configType == "label"){
            let oldConfig = [...config];
            const i = oldConfig.map(f => f.name).indexOf(id);
            oldConfig[i].showInLabel = oldConfig[i].showInLabel ? !oldConfig[i].showInLabel : true;
            setConfig(oldConfig)
        }
        else{
            let oldConfig = [...encode];
            const i = oldConfig.map(f => f.name).indexOf(id);
            oldConfig[i].encodeConfig = oldConfig[i].encodeConfig ? !oldConfig[i].encodeConfig : true;
            setEncode(oldConfig)
        }
    }

    const handleSave = () => {
        saveConfig(configType, config);
        handleCloseModal();
    }

    return (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            scroll={"paper"}
            // fullScreen={fullScreen}
            PaperProps={{ sx: { width: "60%" } }}
            maxWidth="50%"
        >
            <DialogTitle id="responsive-dialog-title">
                <Typography sx={{ ...textClasses.cardTitle }}>
                        Configure Label Details
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                    <Grid container sx={{ mt: 1, p: 1 }}>
                        {/* <Typography sx={{...textClasses.boldText, fontSize: "18px"}}>Details</Typography> */}
                        <Grid container>
                            <ConfigFieldSection
                                configType={configType}
                                header={configOptions || []}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
            </DialogContent>
            <DialogActions>
                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "120px" }} onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill,minWidth: "120px" }} onClick={() => handleSave()}>
                        Submit
                    </Button>
                    {/* <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, fontSize: "14px", backgroundColor: theme.themeOrange, minWidth: "120px" }} onClick={() => handleSave()}>
                        Save for future
                    </Button> */}
            </DialogActions>

        </Dialog>
    )
}

 
const ScannerCode = React.forwardRef(({ data, labelConfig,labelDetail={},setLabelDetail,ID_Key="SKU_ID",templateCode=""}, ref) => {

    const companyName = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))?.company || ""
 

    const getEncodingData = (qrType="qrCode",dynamicDetail=false,) => {
        
        let returnData="",isDynamic=false
        if(dynamicDetail && qrType!="barCode" &&  labelConfig){
            isDynamic=labelConfig.some(dl=>dl.isForBarcode==true) || false
        }
        if(isDynamic){
            returnData+=(labelConfig?.filter(dl=>dl.isForBarcode==true && Number(dl.orderInBarCode)>0).sort((a, b) => a.orderInBarCode - b.orderInBarCode).map(h => data[h.name]).reduce((a, b) => a + "#" + b, ""));
            if(templateCode){
                returnData=templateCode+returnData
            }
            else{
                returnData=returnData.substring(1)
                // returnData=returnData.slice(1)
            }
        }
        else{
            returnData= data[ID_Key] || {};
        }

        return returnData;
    }
    
    const renderElement = (element, index) => {
        switch (element.type) {
          case 'logo':
            return (
              <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                    const newElements = {...prevElements};
                    newElements["allFields"][index] = {
                      ...element,
                      x: x,
                      y: y,
                    };
                    return newElements;
                  })}
              >
                <Resizable
                  style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "",padding:element.padding || ""}}
                  size={{ width: element.width, height: element.height }}
                  onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                      const newElements = {...prevElements};
                      newElements["allFields"][index] = {
                        ...element,
                        width: element.width + d.width,
                        height: element.height + d.height,
                      };
                      return newElements;
                    });
                  }}
                >
                        <Box className="logo">
                            {element.url
                                ? <img
                                    src={element.url}
                                    width={element?.style?.width || "inherit"}
                                    height={element?.style?.height || "inherit"}
                                    alt="Logo" 
                                    // sx={element.style || {
                                    //     width: "inherit",
                                    //     height: "inherit"
                                    // }}
                                    />
                                : <Typography sx={element.style || {
                                    ...textClasses.boldText,
                                    fontSize: "18px",
                                    color: "#000000"
                                }}>+ Add Logo</Typography>
                            }
                        </Box>
                </Resizable>
              </Draggable>
            );
    
          case 'name':
            return (
              <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                    const newElements = {...prevElements};
                    newElements["allFields"][index] = {
                      ...element,
                      x: x,
                      y: y,
                    };
                    return newElements;
                  })}
              >
                <Resizable
                        style={{
                            border: labelDetail.showBorder && "1px solid #FF2700" || "",
                            margin: (labelDetail["allFields"].find(dl => dl.type == "logo") || {})?.disabled
                                ? "0 0 0 92px"
                                :"0",
                            padding:element.padding || ""
                        }}
                  size={{ width: element.width, height: element.height }}
                  onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                      const newElements = {...prevElements};
                      newElements["allFields"][index] = {
                        ...element,
                        width: element.width + d.width,
                        height: element.height + d.height,
                      };
                      return newElements;
                    });
                  }}
                >
                    <Box className="name">
                            <Typography sx={element.style || {
                                ...textClasses.boldText,
                                fontSize: "18px",
                                color: "#000000"
                            }}>{companyName}</Typography>
                    </Box>
                </Resizable>
              </Draggable>
            );
    
          case 'itemDetail':
            // if (element.multiline) {
            //   return (
            //     <Box style={element.multilineCSS}>
            //       {driveDetail().map((ele, eleIndex) => {
            //         return (
            //           <Draggable
            //             key={index + '/' + eleIndex}
            //             defaultPosition={{ x: ele.x, y: ele.y }}
            //             onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
            //                 const newElements = {...prevElements};
            //                 newElements["allFields"][index] = {
            //                   ...element,
            //                   x: x,
            //                   y: y,
            //                 };
            //                 return newElements;
            //               })}
            //           >
            //             <Resizable
            //               style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "", padding: ele.padding }}
            //               size={{
            //                 width: ele.width,
            //                 height: ele.height,
            //               }}
            //               onResizeStop={(e, direction, ref, d) => {
            //                 setLabelDetail((prevElements) => {
            //                   const newElements = {...prevElements};
            //                   if (
            //                     prevElements["allFields"][index].type == 'itemDetail' &&
            //                     prevElements["allFields"][index].subItems &&
            //                     prevElements["allFields"][index].subItems.length > 0
            //                   ) {
            //                     newElements["allFields"][index]['subItems'][eleIndex] = {
            //                       ...ele,
            //                       width: ele.width + d.width,
            //                       height: ele.height + d.height,
            //                     };
            //                   } else {
            //                     newElements["allFields"][index] = {
            //                       ...element,
            //                       width: element.width + d.width,
            //                       height: element.height + d.height,
            //                     };
            //                   }
            //                   return newElements;
            //                 });
            //               }}
            //             >
            //               <Box className="item-detail">
            //                     <Typography key={eleIndex}>
            //                         <span style={element.labelStyle}>{ele.label} : </span>
            //                         <span style={element.valueStyle}>{ele.value || "-"}</span>
            //                     </Typography>
            //               </Box>
            //             </Resizable>
            //           </Draggable>
            //         );
            //       })}
            //     </Box>
            //   );
            // }
            //  else {
            // }
            return (
              <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                  const newElements = {...prevElements};
                  newElements["allFields"][index] = {
                    ...element,
                    x: x,
                    y: y,
                  };
                  return newElements;
                })}
              >
                <Resizable
                 style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "",padding:element.padding || ""}}
                  size={{
                    width: element.width,
                    height: element.height,
                    // height: "auto",
                    // minHeight: element.height,
                  }}
                  onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                      const newElements ={...prevElements};
                      newElements["allFields"][index] = {
                        ...element,
                        width: element.width + d.width,
                        height: element.height + d.height,
                        // height: "auto",
                      };
                      return newElements;
                    });
                  }}
                >
                        <Box className="item-detail">
                            {
                                driveDetail().map((detailElement, detaiIndex) => {
                                    return (
                                        <Typography key={detaiIndex}>
                                            <span style={element.labelStyle}>{detailElement.label} : </span>
                                            <span style={element.valueStyle}>{detailElement.value || "-"}</span>
                                        </Typography>
                                    )
                              })
                            }
                        </Box>
                </Resizable>
              </Draggable>
            );
          case 'qr':
            return (
              <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                    const newElements = {...prevElements};
                    newElements["allFields"][index] = {
                      ...element,
                      x: x,
                      y: y,
                    };
                    return newElements;
                  })}
              >
                <Resizable
                    style={{ 
                            border: labelDetail.showBorder && "1px solid #FF2700" || "",
                            margin: (labelDetail["allFields"].find(dl => dl.type == "itemDetail") || {})?.disabled
                                ? "0 0 28px 28px"
                                : driveDetail().length < 3
                                    ? "0 0 28px 0"
                                    : "0",
                            padding:element.padding || ""
                    }}
                  size={{ width: element.width, height: element.height }}
                  onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                      const newElements = {...prevElements};
                      newElements["allFields"][index] = {
                        ...element,
                        width: element.width + d.width,
                        height: element.height + d.height,
                      };
                      return newElements;
                    });
                  }}
                >
                  <Box className="qr">
                            {
                                element?.qrType == "qrCode"
                                    ? <QRCode
                                        value={getEncodingData("qrCode",element.dynamicDetail || false)}
                                        style={{
                                            width: element.width,
                                            height: element.height,
                                        }}
                                    />
                                    :
                                    <Box 
                                    
                                    >
                                        <Barcode
                                           value={getEncodingData("barCode",element.dynamicDetail || false)}
                                           width={1} 
                                           height={element.height}
                                           displayValue={false}
                                       />
                                    </Box>
                            }
                   
                  </Box>
                </Resizable>
              </Draggable>
            );
    
          case 'signature':
            return (
              <Draggable
                key={index}
                defaultPosition={{ x: element.x, y: element.y }}
                onDrag={(e, { x, y })=>setLabelDetail((prevElements) => {
                    const newElements = {...prevElements};
                    newElements["allFields"][index] = {
                      ...element,
                      x: x,
                      y: y,
                    };
                    return newElements;
                  })}
              >
                <Resizable
                 style={{ border: labelDetail.showBorder && "1px solid #FF2700" || "",padding:element.padding || ""}}
                  size={{ 
                    width: element.width,
                    //  height: element.height,
                     minHeight: element.heightF
                     }}
                  onResizeStop={(e, direction, ref, d) => {
                    setLabelDetail((prevElements) => {
                      const newElements = {...prevElements};
                      newElements["allFields"][index] = {
                        ...element,
                        width: element.width + d.width,
                        height: element.height + d.height,
                      };
                      return newElements;
                    });
                  }}
                >
                        <Box className="signature">
                            {element.url
                                ? <img
                                    src={element.url}
                                    alt="Logo" sx={{
                                        width: "inherit",
                                        height: "inherit"
                                    }} />
                                : <Typography sx={element.style || {
                                    ...textClasses.boldText,
                                    fontSize: "18px",
                                    color: "#000000"
                                }}>+ Add Signature</Typography>
                            }
                        </Box>
                    </Resizable>
              </Draggable>
            );
    
          default:
            return "Invalid Type"
        }
      };
      const dispatch = useDispatch();
      const [displayUrl, setDisplayUrl] = useState("");
      const [openModal, setOpenModal] = React.useState(false);
    
      const displayFile = (fileUrl, fileKey) => {
        const imageUrlRegex = /\.(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const isValidImageUrl = imageUrlRegex.test(fileKey);
        if (isValidImageUrl) {
          setOpenModal(true);
          setDisplayUrl(fileUrl);
        } else {
          window.open(fileUrl, "_blank");
        }
      };
    
      const handleFile = (fileKey) => {
        //console.log('fileKey',fileKey)
        setOpenModal(false);
        setDisplayUrl('');
        dispatch(fetchFile({ fileKey }, displayFile));
      };
      const handleClick = () =>{
        //console.log('called')
      }


    let driveDetail = () => {
        return labelConfig?.filter(head => head.showInLabel == true && !["file", "paragraph", "heading"].includes(head?.type)).map((head, i) => {
            const value = data[head.name] || "-";
            return ({
                label: head.label,
                value: value
            });
        })
    }

    return (
            <Box className="single-check" ref={ref} >
                <Resizable
                    style={{border: labelDetail.showBorder && "1px solid #FF2700" || "",...(labelDetail.style || {})} }
                    size={{
                        width: labelDetail.width || 500,
                        height: labelDetail.height || 'auto',
                    }}
                    onResizeStop={(e, direction, ref, d) => {
                        setLabelDetail((prevElements) => {
                          let newElements = {...prevElements};
                          newElements= {
                            ...newElements,
                            width: newElements.width + d.width,
                            // height: newElements.height + d.height,
                          };
                          return newElements;
                        });
                    }}
                    
                >
                    <Box className="top" style={labelDetail?.fieldsConfig?.top?.style || {}}>
                        {labelDetail?.allFields?.map((element, index) => {
                            if (!element.disabled && (element.type === 'logo' || element.type === 'name')) {
                                return renderElement(element, index);
                            }
                        })}
                        <Box></Box>
                    </Box>
                    <Box className="content" style={labelDetail?.fieldsConfig?.content?.style || {}}>
                        {labelDetail?.allFields?.map((element, index) => {
                            if (!element.disabled && (element.type === 'qr' || element.type === 'itemDetail')) {
                                return renderElement(element, index);
                            }
                        })}
                    </Box>
                    <Box className="footer">
                        {labelDetail?.allFields?.map((element, index) => {
                            if (!element.disabled && element.type === 'signature') {
                                return renderElement(element, index);
                            }
                        })}
                    </Box>
                </Resizable>
            </Box>
    )
})


const Generate = ({ 
    templateHeader=[], dataList,labelDetail,setLabelDetail,count,setCount,step, setStep,setPdfContent,componentRef,
    scannerLoader,templateCode,
    }) => {
   
    let dispatch=useDispatch()
    const [retries, setRetries] = useState(0);
    const [itemIndex, setItemIndex] = useState(0);
    const [configureModal, setConfigureModal] = useState(false);
    const [labelConfig, setLabelConfig] = useState(templateHeader);
    const [encodeConfig, setEncodeConfig] = useState(templateHeader);
    const [loader,setLoader] = useState({logo:false,logoFile:{},disabled:true});
    const [indexJump, setIndexJump] = useState([null,null]);

    const handleChange1 = () => {
        let first=indexJump[0] || ""
        first=Math.min(first, count[2])
        let second=indexJump[1] || ""
        second=Math.min(second, count[2])

        setCount([Math.min(first, count[1]), Math.max(second, count[0]),count[2]]);
        setItemIndex(0);
    };
   
    const signatureRef = useRef();

    const handleClear = () => {
        signatureRef.current.clear();
    };

    const handleSave = () => {
        const signatureData = signatureRef.current.toDataURL();
        let tempLabelDetail={...labelDetail}
        // Use the signature data as needed (e.g., save it to a database, send it to a server, etc.)
        if(!tempLabelDetail || !tempLabelDetail.allFields){
            return ""
        }
        let barCodeElementIndex=tempLabelDetail?.allFields?.findIndex(dl=>dl.type=="signature")
        // //(barCodeElementIndex,"labelDetail?.allFields",labelDetail?.allFields);
        if(barCodeElementIndex<0){
            return "";
        }

        tempLabelDetail["allFields"][barCodeElementIndex]["url"]=signatureData
        tempLabelDetail={
            ...tempLabelDetail,
            showSignCanvas:false

        }
        // tempLabelDetail["showSignCanvas"]=false
        setLabelDetail(tempLabelDetail)
        // //(signatureData);
    };


    const saveConfig = (type, config) => {
        if(type == "label"){
            setLabelConfig([...config]);
        }
        else{
            setEncodeConfig([...config]);
        }
    }

    const handleCloseConfigureModal = () => {
        setConfigureModal(false);
    }

    const showConfigureModal = () => {
        setConfigureModal(true);
    }


    const handleIndexChange = (direction) => {
        if (direction < 0) {
            // //("fff");
            setItemIndex(itemIndex > 0 ? itemIndex - 1 : itemIndex);
        }
        else {
            setItemIndex(itemIndex < (count?.at(1) - count?.at(0)) ? itemIndex + 1 : itemIndex)
        }
    }

    const exportAsImage = async (element) => {
        const canvas = await html2canvas(element);
        const image = canvas.toDataURL("image/png", 1.0)
        return image;
    }

    const generateLabelPdf = async () => {
        try{

            ////(componentRef.current);
            const children = componentRef.current.children;
            let labelImages = [];
            let height = 0;
            for (let i = 0; i < children.length; i++) {
                let child = children[i]
                // child.style.display = "block";
                ////("Child height ", child.offsetHeight);
                if(child.offsetHeight > height){
                    height = child.offsetHeight;
                }
                
                labelImages.push(await exportAsImage(child));
                // child.style.display = "none";
            }
            // Compute Labels per page
            // let height = labelImages[0].height;
            // let height = ref.current.children[0].offsetHeight;
            let labelsPerPage = 840 / height;
            // ////(`Height ${height} and labels per page ${labelsPerPage}`);
            if(labelsPerPage < 1){
                showToast("Label is too big for A4 size sheet", true)
            }
            else{
                const chunks = [];
                const chunksToPrint = []
                for (let i = 0; i < labelImages.length; i += Number(labelsPerPage)) {
                    const chunk = labelImages.slice(i, i + Number(labelsPerPage));
                    const chunkToPrint = dataList.slice((count?.at(0)-1), (count?.at(1))).slice(i, i + Number(labelsPerPage));
                    chunks.push(chunk);
                    chunksToPrint.push(chunkToPrint);
                }
                const document = (
                    <Document>
                        {
                            chunks.map((chunk, i) => {
                                return (
                                    <Page>
                                        <View>
                                            {
                                                chunk.map((image, j) => {
                                                    return (
                                                        <Image src={image}/>
                                                    )
                                                })
                                            }
                                        </View>
                                    </Page>
                                )
                            })
                            
                        }
                    </Document>
                )
                setPdfContent(document);
            }
        }
        catch(e){
            ////(" I came in catch", e);
            setRetries(retries + 1);
        }
        
    }

    const renderBarCodeOptions=()=>{
        if(!labelDetail || !labelDetail.allFields){
            return ""
        }
        let barCodeElementIndex=labelDetail?.allFields?.findIndex(dl=>dl.type=="qr")
        // //(barCodeElementIndex,"labelDetail?.allFields",labelDetail?.allFields);
        if(barCodeElementIndex<0){
            return "";
        }
        let barCodeElement=labelDetail.allFields[barCodeElementIndex]
        if(!barCodeElement || !barCodeElement.options || barCodeElement.options.length==0){
            return "";
        }
        let allOption=barCodeElement.options
        return <Grid container>
            <Grid container item xs={6} sm={3} alignItems="center">
                <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>
                    {barCodeElement.fieldName || "Label Type"}
                </FormLabel>
            </Grid>
            <Grid container item xs={6} sm={6} alignItems="center">
                <RadioGroup
                    row
                    value={barCodeElement?.qrType}
                    onChange={(e) => 
                        setLabelDetail((prevElements) => {
                            const newElements ={...prevElements};
                            newElements["allFields"][barCodeElementIndex] = {
                              ...barCodeElement,
                              qrType: e.target.value
                            };
                            return newElements;
                          })
                        }
                >
                    {
                        allOption.map((ele, index) => (
                            <FormControlLabel
                                key={index} value={ele.value}
                                control={
                                    <Radio size="small"
                                        sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} />
                                } label={ele.label} />
                        ))
                    }
                </RadioGroup>
            </Grid>
        </Grid>
    }

    const handleFileChange = useCallback((event) => {
        if(event){
            let file=event.target.files[0]

            // //("file",file);
            if(!isFileValid(file,['jpg', 'jpeg', 'png',"svg"])){
                showToast("Invalid file format. Only JPG, JPEG, and PNG files are allowed.",true)
                return;
            }
            setLoader((prevLoader) => ({
              ...prevLoader,
              logoFile: file,
              disabled:false
            }));
        }
        else{
            setLoader((prevLoader) => ({
                ...prevLoader,
                logoFile: {},
                disabled:true
              })); 
        }
      }, []);


    const uploadLogoHandler=async ()=>{
        const base64 = await convertDocsBase64(loader.logoFile)
        if(!base64){
            showToast("No file",true)
            return;
        }
        // //("loader",loader);
        setLoader({...loader,logo:true})
        dispatch(uploadLabelLogo({label_ID:labelDetail._id,base64String:base64,update_for:"logo"},({data="",success,message})=>{
            showToast(message,!success)
            if(success){
                let tempLabelDetail={...labelDetail}
                let logoElementIndex=-1
                logoElementIndex=tempLabelDetail?.allFields?.findIndex(dl=>dl.type=="logo")
                if(logoElementIndex<0){
                    return "";
                }
                tempLabelDetail.allFields[logoElementIndex]["url"]=data
                tempLabelDetail["showLogoUpload"]=false
                setLabelDetail(tempLabelDetail)
                setLoader({logo:false,logoFile:{},disabled:false})
            }
            else{
                setLoader({...loader,disabled:false})
            }
        }))
    }


    useEffect(() => {
        if(step == 1){
            setTimeout(() => {
                if(retries < 3){
                    generateLabelPdf();
                }
                else{
                    // Go Back
                    setStep(0);
                }
            }, 3000)
        }
        else{
            setRetries(0);
            setPdfContent(null);
        }
    }, [step])

    useEffect(() => {
        setPdfContent(null);
    }, [ count, labelConfig, encodeConfig])


    useEffect(() => {
        setIndexJump([count[0] || null, count[1] || null])
    }, [count])

    return (
        <>

            <div style={{ padding: "20px" }}>
                {
                    step == 0 
                        ?
                        <>
                           { renderBarCodeOptions()}
                            {
                                labelDetail?.allFields?.filter(dl =>dl.type != "qr")?.length > 0
                                    ?
                                    <Grid container>
                                        <Grid container item xs={6} sm={3} alignItems="center">
                                            <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>Other</FormLabel>
                                        </Grid>
                                        <Grid container item xs={6} sm={24} alignItems="flex-start">
                                            <FormGroup sx={{ display: "flex", flexDirection: "row" }}  >
                                                {
                                                    labelDetail?.allFields?.map((element, index) => {
                                                        if (element.type != "qr") {
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    control={
                                                                        <Checkbox checked={!element.disabled}
                                                                            onChange={(event) => setLabelDetail((prevElements) => {
                                                                                const newElements = { ...prevElements };
                                                                                newElements["allFields"][index] = {
                                                                                    ...element,
                                                                                    disabled: !event.target.checked
                                                                                };
                                                                                return newElements;
                                                                            })
                                                                            }
                                                                        />
                                                                    }
                                                                    label={element.fieldName || ""}
                                                                />
                                                            )
                                                        }
                                                    })

                                                }
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    : ""
                            }
                            {
                               dataList?.length>1 &&

                                <Grid container sx={{ mt: 1 }}>
                                    <Grid container item xs={6} sm={3} alignItems="center">
                                        <FormLabel required sx={{ ...textClasses.boldText, color: theme.themeOrange }}>Label Count</FormLabel>
                                    </Grid>
                                    <Grid container item xs={6} sm={6} alignItems="center">
                                            
                                            <Box style={{
                                                display:"flex",
                                                color:"#FF7200"

                                            }}>
                                                <input
                                                    type="number"
                                                    max={count?.at(2) || 1}
                                                    style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "14px", marginLeft: "10px",marginRight: "10px" }}
                                                    value={indexJump?.at(0) || ""}
                                                    onChange={(e) => setIndexJump([e.target.value,(indexJump?.at(1) || null)])}
                                                    onWheel={(event) => event.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (['e', 'E', '+', '-'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                        else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                 to
                                                <input
                                                    type="number"
                                                    style={{ border: "1px solid #D9D9D9", maxWidth: "60px", textAlign: "center", fontSize: "14px",marginLeft: "10px",}}
                                                    max={count?.at(2) || 1}
                                                    value={indexJump?.at(1) || ""}
                                                    onChange={(e) => setIndexJump([(indexJump?.at(0) || null),e.target.value])}
                                                    onWheel={(event) => event.target.blur()}
                                                    onKeyDown={(e) => {
                                                        if (['e', 'E', '+', '-'].includes(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                        else if (e.key == '0' && (e.target.value == "" || Number(e.target.value) == 0)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <Button variant='outlined' size='small'
                                                    disabled={!indexJump?.at(0) || !indexJump?.at(1)}
                                                    sx={{ ...buttonClasses.lynkitOrangeFill, ml: 1 }}
                                                    onClick={handleChange1}
                                                >
                                                    Jump
                                                </Button>
                                            </Box>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container sx={{mt: 1}}>
                                <Grid container item xs={6} sm={3} alignItems="center">
                                    <Button size='small' sx={{...buttonClasses.small, backgroundColor: theme.themeBlack, color: "white"}} onClick={showConfigureModal}>
                                        Configure Label Details
                                    </Button>
                                    
                                </Grid>
                                <ConfigureLabel
                                    labelConfig={labelConfig}
                                    encodeConfig={encodeConfig} templateHeader={templateHeader}
                                    saveConfig={saveConfig} showModal={configureModal}
                                    handleCloseModal={handleCloseConfigureModal}
                                />
                                <Grid container item xs={6} sm={3} alignItems="center">
                                    <FormControlLabel
                                        control={<Android12Switch 
                                            disabled={labelDetail.showLogoUpload || labelDetail.showSignCanvas}
                                            checked={labelDetail.showBorder} 
                                            onChange={(event)=>setLabelDetail({...labelDetail,showBorder:event.target.checked})}
                                            />}
                                        label="Show Border"
                                    />
                                    
                                </Grid>
                               {labelDetail?.allFields?.find(dl=>dl.type=="logo") &&  <Grid container item xs={6} sm={3} alignItems="center">
                                    <FormControlLabel
                                        control={<Android12Switch 
                                            disabled={labelDetail.showSignCanvas}
                                            checked={labelDetail.showLogoUpload || false} 
                                            onChange={(event)=>setLabelDetail({...labelDetail,showLogoUpload:event.target.checked})}
                                            />}
                                        label="Upload Logo"
                                    />
                                    
                                </Grid>}
                                {labelDetail?.allFields?.find(dl=>dl.type=="signature") && <Grid container item xs={6} sm={3} alignItems="center">
                                    <FormControlLabel
                                        control={<Android12Switch 
                                            disabled={labelDetail.showLogoUpload}
                                            checked={labelDetail.showSignCanvas || false} 
                                            onChange={(event)=>setLabelDetail({...labelDetail,showSignCanvas:event.target.checked})}
                                            />}
                                        label="Create Signature"
                                    />
                                    
                                </Grid>}
                            </Grid>
                            <br />
                            <Grid container justifyContent={"center"} alignItems="center">
                                {
                                    labelDetail.showSignCanvas ?
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <Box sx={{ backgroundColor: "grey", mr: 1 }}>
                                                <SignatureCanvas
                                                    ref={signatureRef}
                                                    canvasProps={{
                                                        width: 400,
                                                        height: 150,
                                                        className: 'signature-canvas',
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                ml: 1
                                            }}>
                                                <Button variant='outlined' size='small'
                                                    sx={{ m: 1, ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                                    onClick={() => handleClear()}
                                                >
                                                    clear
                                                </Button>
                                                <Button variant='outlined' size='small'
                                                    sx={{ m: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                                    onClick={() => handleSave()}>
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                        :
                                        labelDetail.showLogoUpload
                                            ? <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center"
                                            }}>
                                                <Box sx={{ mr: 1 }}>
                                                    <FormControl >
                                                        <TextField
                                                            type='file'
                                                            sx={{
                                                                ...selectStylesOverride,
                                                            }}
                                                            onChange={handleFileChange}
                                                        />
                                                    </FormControl> 
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    ml: 1
                                                }}>
                                                    <Button variant='outlined' size='small'
                                                        disabled={loader.disabled}
                                                        sx={{ m: 1, ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                                                        onClick={() => uploadLogoHandler()}>
                                                        Upload
                                                    </Button>
                                                </Box>
                                            </Box>
                                            :  
                                             scannerLoader ? 
                                           <GenericLoader/>
                                             : 
                                            <ScannerCode
                                                templateHeader={templateHeader}
                                                data={(dataList.slice(count?.at(0)-1, count?.at(1)))?.at(itemIndex) || {}}
                                                labelConfig={labelConfig}
                                                encodeConfig={encodeConfig}
                                                templateCode={templateCode}
                                                labelDetail={labelDetail} setLabelDetail={setLabelDetail}
                                            />
                                }

                            </Grid>
                            {labelDetail.showSignCanvas || labelDetail.showLogoUpload || scannerLoader || dataList.length <= 1
                                ? ""
                                :
                                <Grid container justifyContent="center" alignItems="center" direction={"row"} sx={{ mt: 1 }}>
                                    <IconButton size='small'
                                        sx={{ backgroundColor: theme.themeOrange, "&:hover": { backgroundColor: theme.themeOrange } }}
                                        onClick={() => handleIndexChange(-1)}
                                    >
                                        <KeyboardArrowLeft sx={{ color: "white" }} />
                                    </IconButton>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textAlign: "center", ml: 2, mr: 2 }}>
                                        {`${itemIndex + 1} of ${count?.at(1) - count?.at(0) + 1}`}
                                    </Typography>
                                    <IconButton size='small'
                                        sx={{ backgroundColor: theme.themeOrange, "&:hover": { backgroundColor: theme.themeOrange } }}
                                        onClick={() => handleIndexChange(1)}
                                    >
                                        <KeyboardArrowRight sx={{ color: "white" }} />
                                    </IconButton>

                                </Grid>
                            }
                        </>
                        :
                        <>
                            <Grid containter>
                                <div  ref={componentRef} id="toPrint">
                                    {

                                        
                                        dataList.slice((count?.at(0)-1), (count?.at(1))).map((inv, i) => {
                                            return (
                                                <Grid container justifyContent={"center"} alignItems="center" id={"code"+i} sx={{mb: 2}}>
                                                    <ScannerCode  templateHeader={templateHeader} 
                                                        data={inv}  labelConfig={labelConfig}
                                                        encodeConfig={encodeConfig}
                                                        labelDetail={labelDetail} setLabelDetail={setLabelDetail}
                                                        templateCode={templateCode}
                                                      />
                                                </Grid>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </Grid>
                        </>
                }
            </div>

        </>
    )
}

function sortingData(data=[]){
    if(!data || data.length<1) return;
    data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
}

export default function Label({ labelData={}, showModal, handleCloseModal,labelType}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    let templateHeader=labelData?.templateHeader || []
    let templateCode=labelData?.templateCode || ""
   
    const componentRef = useRef();

    const [step, setStep] = useState(0);
    const [loader,setLoader] = useState(false);
    const [pdfContent, setPdfContent] = useState(null)
    const [count, setCount] = useState([1,1,1]);//first, 2nd and max
    const [labelList,setLabelList] = useState([]);
    const [labelDetail,setLabelDetail] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [scannerLoader, setScannerLoader] = useState(false)  
    

    const updateLabelHandler=()=>{
        setLoader(true)
        dispatch(uploadLabelLogo({label_ID:labelDetail._id,labelDetail,update_for:"detail"},({success,message})=>{
            showToast(message,!success)
            setLoader(false)
        }))
    }

    useEffect(() => {
        if (labelData?.data?.length>0) {
            sortingData(labelData?.data)
            setCount([1,labelData?.data.length,labelData?.data.length])
            setDataList(labelData?.data)
        }
        else{
            setCount([1,1,1])
            setDataList([])
        }
    }, [labelData?.data])

    useEffect(() => {
        setLoader(true)
        dispatch(getLabelConfigration({labelType:labelType},({data=[],success=false,message})=>{
            setLabelList(data || [])
            setLoader(false)
            if(data?.length){
                setLabelDetail({...data[0],label: data[0].labelName, value: data[0]._id})
            }
        }))
     }, [])

    return (
        <Dialog
            open={showModal}
            onClose={handleCloseModal}
            scroll={"paper"}
            fullScreen={fullScreen}
            PaperProps={{ sx: { width: "70%" } ,p:0}}
            maxWidth="50%"
            sx={{p:0}}
        >
            <DialogTitle id="responsive-dialog-title">
                {
                    step == 0
                        ?
                        <Grid container display={"flex"} justifyContent={"space-between"}>
                            <Grid item xs={12} sm={5}>
                                <Typography sx={{ ...textClasses.cardTitle }}>
                                    Generate Label(s):
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4} sx={{ ml: 2 }}>
                                <Autocomplete
                                    id="barcode-label"
                                    size="small"
                                    fullWidth
                                    disabled={labelList.length < 2}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Select Label Settings"
                                            sx={{
                                                ...selectStylesOverride,
                                            }}
                                        />
                                    )}
                                    options={labelList.map(dl => ({ ...dl, label: dl.labelName, value: dl._id }))}
                                    onChange={(e, option) => setLabelDetail(option || {})}
                                    value={labelDetail.label || ""}
                                />
                            </Grid>
                        </Grid>
                        :
                        <>
                            <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }} onClick={() => setStep(0)}>
                                Back
                            </Button>
                            <ReactToPrint
                                documentTitle="ShippingInvoice"
                                onAfterPrint={() => setStep(0)}
                                bodyClass="adjustContent"
                                removeAfterPrint
                                trigger={() => <Button variant='contained' size='small'
                                    sx={{ ml: 1, ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                                >
                                    Print ({count?.at(1) - count?.at(0) + 1})
                                </Button>}
                                content={() => componentRef.current}
                            />
                        </>
                }
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description" sx={{ pt: 0 }}>

                    {loader ?
                        <GenericLoader />
                        :
                        <Generate
                            labelDetail={labelDetail}
                            setLabelDetail={setLabelDetail}
                            templateHeader={templateHeader}
                            dataList={dataList}
                            setStep={setStep}
                            step={step}
                            count={count}
                            setCount={setCount}
                            pdfContent={pdfContent}
                            setPdfContent={setPdfContent}
                            handleCloseModal={handleCloseModal}
                            componentRef={componentRef}
                            scannerLoader={scannerLoader}
                            templateCode={templateCode}
                        />
                    }
                </DialogContentText>
            </DialogContent>
            {
                step ==0
                    ?
                    <DialogActions>
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeEmpty, minWidth: "100px" }}
                            onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                            onClick={() => updateLabelHandler()} disabled={loader}>
                            Save Label Config
                        </Button>
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "100px" }}
                            onClick={() => setStep(1)} disabled={loader}>
                            Generate ({count?.at(1) - count?.at(0) + 1})
                        </Button>
                    </DialogActions>
                    : ""
            }
        </Dialog>
    )
}
