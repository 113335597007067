import { FETCH_SALES_DETAIL, FETCH_SALES_ORDER_ITEM_LIST, FETCH_SALES_ORDER_PROCESSING, FETCH_SALES_STATUS_COUNT, GENERATE_SO_NUMBER ,FETCH_ESOPFORM_TABLE_DATA,FETCH_PASSLINK_VALIDATION_TOKEN, FETCH_ASSOCIATED_JOB, FETCH_ASSOCIATED_TRIP } from "../types"


const INIT_STATE ={
    allSalesOrder :[],
    totalRows:0,
    allSaleOrderItems: [],
    orderItemRows: 0,
    SO_Number: "",
    eSOPFormTableData:[],
    passLinkValidationToken:"",
    associatedJobData: [],
    associatedJobRows: 0,
    associatedTripData: [],
    associatedTripRows: 0
}

export default (state=INIT_STATE, action) =>{
    switch(action.type) {
        case FETCH_SALES_ORDER_PROCESSING :return {...state, allSalesOrder: action.payload.rows, totalRows: action.payload.count}
        case FETCH_SALES_DETAIL: return {...state, SalesDetail: action.payload.data, SalesDetailItemsCount: action.payload.count}
        case GENERATE_SO_NUMBER: return {...state, SO_Number: action.payload}
        case FETCH_SALES_STATUS_COUNT: return {...state, statusCount: action.payload}
        case FETCH_SALES_ORDER_ITEM_LIST :return {...state, allSaleOrderItems: action.payload.rows, orderItemRows: action.payload.count}
        case FETCH_ESOPFORM_TABLE_DATA : return {...state, eSOPFormTableData:action.payload}
        case FETCH_PASSLINK_VALIDATION_TOKEN :  return {...state, passLinkValidationToken:action.payload}
        case FETCH_ASSOCIATED_JOB: return {...state, associatedJobData: action.payload.data, associatedJobRows: action.payload.count}
        case FETCH_ASSOCIATED_TRIP: return {...state, associatedTripData: action.payload.data, associatedTripRows: action.payload.count}
        default: return state
    }
}