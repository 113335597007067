import React, { useState, useEffect } from 'react';
import { Box, Modal, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from "@mui/material";





export default function CustomModel(props) {

    const [open, setOpen] = useState(false);
    const handleClose = (e) => {
        setOpen(!open);
        props.onClose(false);
    };
    useEffect(() => {
        setOpen(props.show);

    }, [props.show]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transition: 'all 0.3s ease-out',
        minWidth: props?.minWidth || 400,
        maxWidth: props?.maxWidth || 800,
        // maxHeight: props?.maxHeight || '95vh',
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        pt: props?.pt || 3,
        pb: props?.pb || 3,
        pl: props?.pl || 3,
        pr: props?.pr || 3,
       
        //  border: "none",
        //  overflow:'auto'
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: { pointerEvents: "none" },
                onClick: (event) => props.autoClose ? "" : event.stopPropagation()
            }}

        >
            <Grid container sx={style} >
                <Grid item sm={12}>
                    <Box sx={{ position: "sticky", top: 0, zIndex: 100 }}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ display: 'flex', justifyContent: 'space-between', position: "sticky", top: 0, zIndex: 100 }}
                        >
                            <span className='fs-24 font-Nunito fw-1000'> {props.Title} </span>
                            <span >{props.TableLink}</span>
                            {props?.viewCloseIcon &&
                                <span className='curser' onClick={() => handleClose()}><CloseIcon /></span>
                            }
                        </Typography>
                    </Box>
                    <Box 
                     sx={{ maxWidth: "inherit", maxHeight: props.childrenMaxHeight || "70vh", overflowY: 'auto',minHeight:'30vh',overflowX:'hidden' }}
                    >
                        {props.children}
                    </Box>
                    <Box sx={{ display:"flex",justifyContent:"flex-start",gap:2,position:'sticky',zIndex:100,top:0,mt:1}}>
                        {props.actionButton}
                    </Box>
                </Grid>

            </Grid>


        </Modal>
    )
}
