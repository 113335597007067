import axios from "../../services/Api";
import { showToast } from "../../services/functions";
import { CHANGE_USN_MODAL_STATUS, FETCH_ALL_UOM, FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from "../types";

export const getAllUom = () =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('base/getUOMList', {}
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_UOM, payload: data.data})
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: FETCH_ALL_UOM, payload: []})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};


export const changeUomModalStatus = (status) => {
    return {
        type: CHANGE_USN_MODAL_STATUS,
        payload: status
    }
}

export const addUom = (uomList, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('base/addUOMList', {
            "UOM_Data": uomList
        }
        ).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            if(cb){
                cb(data)
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            //("Error****:", error.message);
        });
    }
};
