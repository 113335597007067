import React from 'react';
import {lynkitIOURL} from '../../../../services/config';
import Integration from '../../../Components/Integration';


const Addressbook = () => {
    const todayDate = new Date().getTime();
    const myVariable = `${lynkitIOURL}/login?action=/address-book&auth=WMS&timestamp=${todayDate}`;
    return (
       <>
        <Integration
            myVariable={myVariable}
            baseURL={lynkitIOURL}
        />
       </>
    );
};

export default Addressbook;
