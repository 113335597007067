export const valid_mobile = (mobile) => {
    var phoneno = /^[6-9][0-9]{9}$/;
    if(!mobile.match(phoneno)){
      return false;
    }
    else{
      return true;
    }
}
  
export const valid_email = (email) => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    {
      return true;
    }
    else{
      return false; 
    }        
}
  
export const valid_gst = (gst) => {
    var regexGST = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}Z[a-zA-Z\d]{1}?$/;
    return regexGST.test(gst);
}
export const valid_otp_six_digit = (otp) => {
  const otpRegex = /^\d{6}$/;
  return otpRegex.test(otp);
}

export const valid_pan = (pan) => {
    var regexPAN = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}?$/;
    if(!pan.match(regexPAN)|| pan.length!==10) 
    {
      return false;
    }
    else{
        return true;
    }
}

export const isEmptyObject = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const valid_password = (password) => {
  var regexPAN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%]).{8,}$/;
  if(!password.match(regexPAN)) 
  {
    return false;
  }
  else{
      return true;
  }
};