import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { textClasses } from '../../../../../static/styles/theme';

const CreateAutomationCard = ({
    text,
    subText,
    icon,
    width='311px',
    testId="",
    handleJobCreate = () => { }
}) => {
    return (
        <Card sx={{
            width: width,
            height: "78px",
            borderRadius: '8px',
            border: '1px solid #E0E0E0',
            cursor:'pointer'
        }} onClick={handleJobCreate}>
            <CardContent>
                <Grid container spacing={2}
                    sx={{
                        paddingTop: "5px",
                        paddingLeft: '8px'
                    }}
                    data-testid={testId}>
                    <img src={icon} />
                    <Typography sx={{
                        margin: '5px',
                        ...textClasses.t14n,
                        fontFamily: "Nunito",
                        fontWeight: '600',
                        color: '#000000'
                    }}>{text}</Typography>
                </Grid>
                <Typography sx={{
                    paddingLeft: '20px',
                    ...textClasses.t12n,
                    fontFamily: 'Nunito',
                    color: '#827878'
                }}>{subText}</Typography>
            </CardContent>
        </Card>
    )
}
export default CreateAutomationCard;