import React from "react";
import { Grid } from "@mui/material";
import loaderGif from "../../../static/images/drawer/loader.gif";

const GenericLoader = () =>{
    return (
        <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                    <Grid item><img src={loaderGif} alt='' /></Grid>
                </Grid>
    )

}

export default GenericLoader;