import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Typography, CardHeader, Breadcrumbs, Button , Autocomplete , TextField
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, Monitor } from "@mui/icons-material";

import theme, { textClasses } from "../../../static/styles/theme";
import { Link, useHistory } from "react-router-dom";
import colors, { buttonClasses } from '../../../static/styles/theme';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getAllJobActions } from "../../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenericLoader from "../../Components/Generic/GenericLoader";

export default function AddWorkflow() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [items, setItems] = useState([])
    const [loader, setLoader] =useState(false)
    const [warehouse, setWarehouse] = useState([
        {label :'Select Warehouse', value:'Select Warehouse'}
    ])
    const [collapse, setCollapse] = useState({
        collapse1: false,
        collapse2:false,
        collapse3:false
    })
    const [workflowState, setworkflowState] = useState([
        {
            type: 'Inward Operations',
            subText: '* add all your inward process steps in their completion order. you can create multiple workflows and update them anytime.',
            operations: [
                {
                    taskName: 'Put Away (Gate-In)',
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />,
                    type: 'Inward Operations',
                }
            ]
        },
        {
            type: 'In-house Operations',
            subText: '* define your in-house warehouse operations ',
            operations: [
                {
                    taskName:'',
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />,
                    type: 'In-house Operations',
                }
            ]
        },
        {
            type: 'Outward Operations',
            subText: '* add all your Outward process steps in their completion order.',
            operations: [
                {
                    taskName: 'Dispatch',
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />,
                    type: 'Outward Operations',
                }
            ]
        },

    ])
    const { allJobActions } = useSelector((state) => state.job);

    const Expand = (ind) => {
        var element = document.getElementById(`table-${ind}`);
        let temp = {...collapse}
        if (element.classList.length === 1) {
            element.classList.add("priyanshuScroll");
        }
        else {
            element.classList.remove("priyanshuScroll");
        }
        if(ind=== 0){
            if(collapse.collapse1 === true){
                temp.collapse1 = false
                setCollapse(temp)
            }
            else{
                temp.collapse1 = true
                setCollapse(temp)
            }
        }
        if( ind === 1){
            if(collapse.collapse2 === true){
                temp.collapse2 = false
                setCollapse(temp)
            }
            else{
                temp.collapse2 = true
                setCollapse(temp)
            }
        }
       if(ind === 2){
        if(collapse.collapse3 === true){
            temp.collapse3 = false
            setCollapse(temp)
        }
        else{
            temp.collapse3 = true
            setCollapse(temp)
        }
       }
       
    }

    const removeTask = (index, ind) => {
        let temp = [...workflowState]
        temp[index].operations.splice(ind, 1)
        temp[index].operations[temp[index].operations.length - 1].canBeDeleted = true
        setworkflowState(temp)
    }

    const ItemTypes = {
        ITEM: 'item',
    };

    const ReOrderTypes = {
        reItem: 'item',
    };

    const DragDropItem = ({ actionName, outerIndex, index, approved, actionID, categoryKey, order }) => {
        const ref = React.useRef(null);
        const [, drop] = useDrop({
            accept: ItemTypes.ITEM,
            // drop: (item) => moveItem(item.index, index),
        });

        const [{ isDragging }, drag] = useDrag({
            item: { actionName, outerIndex, index, approved, actionID, categoryKey, order },
            type: ItemTypes.ITEM,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0.4 : 1;

        drag(drop(ref));

        return (
            <div ref={ref} className="customOrangeBtn mgAll" style={{ opacity, paddingRight: '16px' }}>
                {actionName}
            </div>
        );
    };

    const DropTargets = () => {
        const [, drop] = useDrop({
            accept: ItemTypes.ITEM,
            drop: (item) => moveItem(item.outerIndex, item.index),
        });

        return (            
             <div ref={drop} className="dropZone" style={{cursor:'no-drop'}}> 
                <span>Drop Here</span>
            </div>
          
        
        );
    };

    const moveItem = (outerIndex, innerIndex) => {
        if(outerIndex !== undefined && innerIndex !== undefined){
            const draggedItem = items[outerIndex].actions[innerIndex];
            let temp = [...workflowState]

        if (temp[outerIndex].operations.length === 1) {
            temp[outerIndex].operations[0].canBeDeleted = false
            temp[outerIndex].operations.push(
                {
                    taskName: draggedItem.actionName,
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />
                }
            )
            setworkflowState(temp)
        }
        else {
            temp[outerIndex].operations[temp[outerIndex].operations.length - 1].canBeDeleted = false
            temp[outerIndex].operations.push(
                {
                    taskName: draggedItem.actionName,
                    canBeDeleted: true,
                    icon: <DoubleArrowIcon />
                }
            )
            setworkflowState(temp)
        }
        }
        else{
            
        }
        
    };

    const DragDropReorder = ({ items, index, ind }) => {

        const ref = React.useRef(null);
        const [, drop] = useDrop({
            accept: ReOrderTypes.reItem,
                drop: (item) => {
                    moveAndReorder(item.ind,ind,index)} 
                });

        const [{ isDragging }, drag] = useDrag({
            item: { index, ind },
            type: ReOrderTypes.reItem,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
        });

        const opacity = isDragging ? 0.4 : 1;
        drag(drop(ref));
        return (
            <div className="pBoxVertical" >
             {items.taskName !== ''   ?
                <div className="customOrangeBtn" ref={ref}  style={{ opacity ,marginBottom: '12px', position: 'relative', zIndex: '10' }}>
                {items.taskName}
                    {ind !== 0 ?
                        <span><CloseIcon className="closeIcon" onClick={() => removeTask(index, ind)} /></span>
                        : ''}
                </div>
                : ''}
                {items.taskName !== ''   ?
                <div className="doubleArr" >
                    {items.icon}
                </div>
                :''}
                {items.canBeDeleted === true ?
                    <DropTargets
                        items={items}
                        index={index}
                        ind={ind}
                    />
                    : ''}
            </div>
        );
    };

    const moveAndReorder = (dragIndex, hoverIndex,index) => {
        let temp = [...workflowState]
        const draggedItem = temp[index].operations[dragIndex];
        // temp[index].operations.splice(dragIndex, 1);
        // temp[index].operations.splice(hoverIndex, 0, draggedItem);

        
        [temp[index].operations[dragIndex], temp[index].operations[hoverIndex]] = [temp[index].operations[hoverIndex], temp[index].operations[dragIndex]];

        let len = temp[index].operations.length -1
        if(hoverIndex === len){
            temp[index].operations[dragIndex].canBeDeleted = false
            temp[index].operations[temp[index].operations.length -1].canBeDeleted = true
        }
        else{
            temp[index].operations[temp[index].operations.length -1].canBeDeleted = true
            temp[index].operations[hoverIndex].canBeDeleted = false
        }
        setworkflowState(temp)
    }

    useEffect(() => {
        setLoader(true)
        const bodyData = {}
        dispatch(getAllJobActions(bodyData, () => {
            setLoader(false)
        }))
    }, [])

    useEffect(() => {
        if (allJobActions) {
            const rearrangedArray = [...allJobActions].sort((a, b) => {
                const categoryOrder = {
                    "Inward Operations": 0,
                    "In-house Operations": 1,
                    "Outward Operations": 2,
                };

                return categoryOrder[a.categoryKey] - categoryOrder[b.categoryKey];
            });
            setItems(rearrangedArray)
        }
    }, [allJobActions])


    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Grid container sx={{ flexDirection: "column" }}>
                    <Grid item >
                        <Typography sx={{ fontSize: "24px", fontWeight: 600 }}>
                            Manage Workflow(s)
                        </Typography>
                        <Breadcrumbs sx={{ mb: 1 }}>
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    fontSize: "12px",
                                    color: theme.themeOrange,
                                }}
                            >
                                <Link
                                    style={{ color: theme.themeOrange }}
                                    to="/dashboard/setting/manage-workflows"
                                >
                                    Manage Workflow
                                </Link>
                            </Typography>

                            <Typography 
                            data-testid='create'
                                sx={{ ...textClasses.normalText, fontSize: "12px" }}
                            >
                                create workflow
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <div className="customCard">
                        <div className="customCardLeft" >
                            <div  className='clHeader' >
                            Create Workflow
                            <Autocomplete
                                    sx={{ height: "10px", width:'30%', padding: "0px", marginTop: "0px" }}
                                    // className='multi-select'
                                    // id="select-Rack-label"
                                    size="small"
                                    multiple
                                    disableCloseOnSelect
                                    limitTags={2}
                                    // defaultValue={[label:"warehouse", value:'warehouse']}
                                    options={warehouse}
                                    // getOptionLabel={(option) => option.name}
                                    // getOptionDisabled={(option) =>
                                    //     checkdisableValue(option.cellId)
                                    // }
                                    // onChange={(event, option) => {
                                    //     handleSelectRackChange(index, option)
                                    // }}
                                    // renderOption={(props, option, { selected }) => (
                                    //     <MenuItem
                                    //         key={option.cellId}
                                    //         value={option.cellId}
                                    //         sx={{ justifyContent: "space-between" }}
                                    //         {...props}
                                    //     >
                                    //         {option.name}
                                    //         {selected ? <CheckIcon color="info" /> : null}
                                    //     </MenuItem>
                                    // )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Warehouse"
                                            placeholder="Select Warehouse"
                                        />
                                    )}
                                />
                            </div>
                               
                            <div style={{ borderBottom: '1px solid #D9D9D9', width: '97%', height: 'auto', margin: 'auto', position: 'relative', top: '-13px' }}>
                                <span style={{ fontSize: '12px' }}>
                                    * You can’t remove the default process steps. You can only change the order of any step any time
                                </span>
                            </div>
                            {workflowState.map((item, Parentindex) => {
                                return (
                                    <div className="pcardContainer">
                                        <div className="pcardCTop">
                                            <span className="operationName">{item.type}</span>
                                            <span className="operationText">{item.subText}</span>
                                        </div>
                                        <div className="pcardCBody">
                                            {item.operations.map((data, ind) => {
                                                return (
                                                    <DragDropReorder
                                                        items={data}
                                                        index={Parentindex}
                                                        ind={ind}
                                                        isLastZone={item.operations[ind] }
                                                    />
                                                )
                                            })}
                                        </div>



                                        <div className="pcardCHeader">
                                            <Button
                                                variant="contained"
                                                className='UM_delete-btn'
                                                // onClick={() => {
                                                //     setActionStatus("Delete")
                                                //     setEditBlock(true)
                                                // }}
                                                sx={{ fontSize: "12px", borderRadius: "8px", marginRight: "11px", height: '33px', color: "black", border: "1px solid black" }}
                                            > Clear
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={buttonClasses.lynkitOrangeEmpty}
                                                // onClick={() => {
                                                //     history.push("/dashboard/job/viewJobTypes")
                                                // }}
                                            >
                                                Submit & Add New
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className='UM_uploadfile-btn'
                                                // onClick={() => {
                                                //     setActionStatus("Activate/Block")
                                                //     setEditBlock(true)
                                                // }}
                                                sx={{ fontSize: "12px", borderRadius: "8px", margin: "10px", height: '33px', color: "black", border: "1px solid black" }}
                                            > Submit
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="customCardRight">
                            <CardHeader
                                title={
                                    <Typography sx={{ fontSize: "18px", color: '#ff7200' }}>
                                        Standard Operations
                                    </Typography>
                                }
                            />
                            <div style={{ width: '92%', height: 'auto', margin: 'auto', position: 'relative', top: '-13px' }}>
                                <span style={{ fontSize: '12px' }}>
                                    Use standard operations to define how work progresses with in warehouse.
                                </span>
                            </div>
                            {loader
                                ? <Grid data-testid="loader" container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 15 }}>
                                    <Grid item><GenericLoader /></Grid>
                                </Grid>
                                :
                            items && items.map((item, index) => {
                                return (
                                    <div className="pcardContainer" style={{ marginTop: '-7px',marginBottom:'10px', boxShadow: 'none' }}>
                                        <div className="pcardCTop">
                                            <span className="operationName">{index + 1}. {item.categoryKey}</span>
                                            <span className="operationText">
                                                {item.categoryKey === "Inward Operations" ?
                                                    '( Gate In )'
                                                    : ''
                                                }
                                                {item.categoryKey === "In-house Operations" ?
                                                    ''
                                                    : ''
                                                }
                                                {item.categoryKey === "Outward Operations" ?
                                                    '( Gate Out )'
                                                    : ''
                                                }
                                            </span>
                                        </div>
                                        <div className="pcardO" id={`table-${index}`} >
                                            <div className="pcardBoyChange pcardO">
                                                {item.actions.map((data, ind) => {
                                                    return (
                                                        <DragDropItem
                                                            key={data.ind}
                                                            outerIndex={index}
                                                            index={ind}
                                                            actionName={data.actionName}
                                                            approved={data.approved}
                                                            actionID={data.actionId}
                                                            categoryKey={data.categoryKey}
                                                            order={data.order}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="pcardScroll"  id={`collapse-${index}`} onClick={() => Expand(index)}>
                                        {index === 0 ?
                                        collapse.collapse1 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>
                                        :''}
                                        {index === 1 ?
                                        collapse.collapse2 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>
                                        :''
                                        }
                                        {index === 2 ?
                                        collapse.collapse3 === false ?
                                        <>
                                            <span>View All</span>
                                             <span><ExpandMoreIcon/></span>
                                        </>
                                            :
                                            <>
                                            <span>Hide All</span>
                                            <span><ExpandLessIcon/></span>

                                            </>

                                           :'' 
                                        }
                                        </div>
                                    </div>

                                )
                            })}
                            

                        </div>

                    </div>

                </Grid>
            </DndProvider>
        </>
    );
}

// import React , {useState} from 'react';
// import { useDrag, useDrop, DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// const AddWorkflow = () => {
//     const [items, setItems] = useState([
//         { id: 'item1', text: 'Item 1' },
//         { id: 'item2', text: 'Item 2' },
//         { id: 'item3', text: 'Item 3' },
//         { id: 'item4', text: 'Item 4' },
//     ]);

//     const moveItem = (dragIndex, hoverIndex) => {
//         const draggedItem = items[dragIndex];
//         const updatedItems = [...items];
//         updatedItems.splice(dragIndex, 1);
//         updatedItems.splice(hoverIndex, 0, draggedItem);
//         ////('draggeeeeeeeed', dragIndex, hoverIndex);
//         setItems(updatedItems);
//     };

//     const ItemTypes = {
//         ITEM: 'item',
//     };

//     const DragDropItem = ({ id, index, text }) => {
//         const ref = React.useRef(null);
//         const [, drop] = useDrop({
//             accept: ItemTypes.ITEM,
//             drop: (item) =>////('i',item,index)
//         });
//         const [{ isDragging }, drag] = useDrag({
//             item: { id, index },
//             type: ItemTypes.ITEM,
//             collect: (monitor) => ({
//                 isDragging: monitor.isDragging(),
//             }),
//         });

//         const opacity = isDragging ? 0.4 : 1;

//         drag(drop(ref));

//         return (
//             <div ref={ref} className="draggable-item" style={{ opacity }}>
//                 {text}
//             </div>
//         );
//     };

    // const DropTarget = () => {
    //     const [, drop] = useDrop({
    //         accept: ItemTypes.ITEM,
    //         // drop: (item) => moveItem(item.index, items.length),
    //     });

    //     return (
    //         <div ref={drop} className="drop-target" style={{ width: '100%', height: '100px', border: '1px dashed silver' }}>
    //             Drop here
    //         </div>
    //     );
    // };
 
//     return (
//         <DndProvider backend={HTML5Backend}>
//             <div>
//                 <div>
//                     {items.map((item, index) => (
//                         <DragDropItem
//                             key={item.id}
//                             id={item.id}
//                             index={index}
//                             text={item.text}
//                         />
//                     ))}
//                 </div>
//                 {/* <DropTarget /> */}
//             </div>
//         </DndProvider>
//     );
// };

// export default AddWorkflow;