import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../services/history'

import {
    Grid, Typography, Select, MenuItem, Box,
    Card, CardHeader, CardContent, CardActions, CardMedia,
    Avatar, Divider, Paper, Tabs, Tab, Breadcrumbs
} from '@mui/material'

import theme, { textClasses } from "../../../static/styles/theme"

import SelectTemplate from './SelectTemplate';
import ComponentCanvas from './ComponentCanvas';
import StandardComponents from './StandardComponents';
import PreviousUsedComponents from './PreviousUsedComponents';

import { editTemplate, getPreviousComonents, getSelectedTemplate, getStandardComonents, initCanvas,
     replaceEditCanvas, saveTemplate, selectTemplate, getTemplateTypes, selectMasterTemplate ,getAllTemplates, setAddressBookField
    } from '../../../redux/actions';
import { templateTypes } from '../../../services/constants';
import { cleanCanvas, convertToCanvasObject } from '../../../redux/reducers/Canvas';
import { showToast } from '../../../services/functions';
import GenericLoader from '../../Components/Generic/GenericLoader';
import { component } from '../TMS/Addressbook/utils';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useSpiType = (template) => {
    const [spiType, setSpiType] = useState(null);

    useEffect(() => {
        if(template){
            let type;
            if(!template.otherTemplate || template.otherTemplate.length == 0){
                type = "shipmentOnly";
            }
            else if(template.otherTemplate.length == 2){
                type = "SPI";
            }
            else if(template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate"){
                type = "shipmentPackageOnly";
            }
            else{
                type = "shipmentItemOnly";
            }

            setSpiType(type);
            
        }
    }, [template])

    return spiType;
}

export default function EditTemplate() {

    const {templateId} = useParams();
    

    const dispatch = useDispatch();
    const template = useSelector(state => state.inward.selectedTemplate);
    ////("Template Id", template);
    const usedComponents = useSelector(state => state.templates.usedComponents)
    const stdComponents = useSelector(state => state.templates.standardComponents)
    const predefinedElements = useSelector(state=> state.templates.predefinedElements)
    
    const components = useSelector(state => state.canvas.components )
    const allTemplateTypes = useSelector(state => state.templates.templateTypes);

    const selectedSupplierMaster = useSelector(state => state.canvas.selectedSupplierMaster);
    const selectedCustomerMaster = useSelector(state => state.canvas.selectedCustomerMaster);
    const selectedItemMaster = useSelector(state => state.canvas.selectedItemMaster);
    const addressBookFields = component({type:"customer"})

    const initEditCanvas = (template) => {
        // let newComponents = JSON.parse(JSON.stringify(components));
        let newComponents = convertToCanvasObject(allTemplateTypes);
        if(template){
            // Get Shipment Components
            let newMandatory = [];
            let newOthers = [];
            // let mandatoryIds = [];
            // mandatoryIds = newComponents.attributes.mandatory.map(c => c._id);
            let mandatoryIds = newComponents.attributes.mandatory.filter(f => {
                if(f.templateType && f.templateType == template.templateType){
                    return true;
                }
                return false;
            }).map(c => c._id);
            for (let i = 0; i < template.attributes.length; i++) {
                const element = template.attributes[i];
                
                if(mandatoryIds.indexOf(element._id) >= 0){
                    newMandatory.push({...element});
                }
                else{
                    newOthers.push({...element, removable: !template.frequency || template.frequency == 0});
                }
            }
            newComponents.attributes.mandatory = newMandatory;
            newComponents.attributes.other = newOthers;

            // Get Package Components if any
            const pIndex = template.otherTemplate.map(t => t.templateType).indexOf("packageDetailTemplate");
            if( pIndex >= 0){
                newMandatory = [];
                newOthers = [];
                mandatoryIds = newComponents.packageTemplate.mandatory.map(c => c._id);
                
                for (let i = 0; i < template.otherTemplate[pIndex].attributes.length; i++) {
                    const element = template.otherTemplate[pIndex].attributes[i];
                    if(mandatoryIds.indexOf(element._id) >= 0){
                        newMandatory.push({...element});
                    }
                    else{
                        newOthers.push({...element, removable: !template.frequency || template.frequency == 0});
                    }
                    // if(newComponents.packageTemplate.mandatory.map(c => c._id).indexOf(element._id) < 0){
                    //     newOthers.push({...element, removable: !template.frequency || template.frequency == 0});
                    //     // newComponents.packageTemplate.other.push({...element, removable: false});
                    // }
                }
                newComponents.packageTemplate.mandatory = newMandatory;
                newComponents.packageTemplate.other = newOthers;
            }
            else{
                newComponents.packageTemplate.status = false;
            }

            // Get Item Components if any
            const iIndex = template.otherTemplate.map(t => t.templateType).indexOf("itemDetailTemplate");
            if( iIndex >= 0){
                newMandatory = [];
                newOthers = [];
                // mandatoryIds = newComponents.itemTemplate.mandatory.map(c => c._id);
                mandatoryIds = newComponents.itemTemplate.mandatory.filter(f => {
                    if(!f.templateType){
                        return true;
                    }
                    if(f.templateType && f.templateType == template.templateType){
                        return true;
                    }
                    return false;
                }).map(c => c._id);
                for (let i = 0; i < template.otherTemplate[iIndex].attributes.length; i++) {
                    const element = template.otherTemplate[iIndex].attributes[i];
                    if(mandatoryIds.indexOf(element._id) >= 0){
                        newMandatory.push({...element});
                    }
                    else{
                        newOthers.push({...element, removable: !template.frequency || template.frequency == 0});
                    }
                    // if(newComponents.itemTemplate.mandatory.map(c => c._id).indexOf(element._id) < 0){
                    //     newOthers.push({...element, removable: !template.frequency || template.frequency == 0});
                    //     // newComponents.itemTemplate.other.push({...element, removable: false});
                    // }
                }
                newComponents.itemTemplate.mandatory = newMandatory;
                newComponents.itemTemplate.other = newOthers;
            }

            else{
                newComponents.itemTemplate.status = false;
            }

            // Mark sections
            ////("New Components", newComponents);
        }
        return newComponents
    }
    
    const selectedTemplate = useSelector(state => state.canvas.selectedTemplate)
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate)
    // const selectedTemplate = template ? template.templateType : "";

    const spiType = useSpiType(template);
    const [tabIndex, setTabIndex] = useState(0);
    const [sectionStep, setSectionStep] = useState(2);
    const [selectModal, setSelectModal] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const handleOpenModal = () => setSelectModal(true);
    const handleCloseModal = () => setSelectModal(false);

    const handleTemplateSelect = (templateType, subTemplateType) => {
        dispatch(
            selectTemplate(templateType, subTemplateType, convertToCanvasObject(allTemplateTypes))
        )
        setSectionStep(sectionStep + 1);
    }

    const handleTabChange = (event, value) => {
        ////("Changings " + value);
        setTabIndex(value)
    }

    const handleTemplateChange = (event) => {
        // ////("Changing template to ", parent, event.target.value);
        // let tType;
        // let sTType;
        // for (let i = 0; i < allTemplateTypes.length; i++) {
        //     const t = allTemplateTypes[i];
        //     for (let j = 0; j < t.allTemplateTypes.length; j++) {
        //         const st = t.allTemplateTypes[j];
        //         if(st.templateTypekey == event.target.value){
        //             tType = t.templateCategorykey;
        //             sTType = st.templateTypekey;
        //             dispatch(
        //                 selectTemplate(tType, sTType)
        //             )
        //             return;
        //         }
        //     }
            
        // }
    }

    const getSectionStep = () => {
        if(sectionStep == 1) {
            return (
                <SelectTemplate showModal={selectModal} handleTemplateSelect={handleTemplateSelect} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal}/>
            )
        }
        else if(sectionStep == 2) {
            return <ComponentCanvas  components={components} setComponents={null} handleSaveTemplate={handleEditTemplate} template={template} edit={true}/>
        }
        return <div>Coming Soon..</div>
    }

    const getTabStyles = (tIndex) => {
        return {
            backgroundColor: tabIndex == tIndex ? theme.themeOrange: "white",
            color: tabIndex == tIndex ? "white" : theme.themeOrange,
            border: "1px solid",
            boxShadow: "0px 2px 6px gray",
            borderColor: theme.themeOrange,
            pt: 2,
            pb: 2,
            fontSize: "11px",
            lineHeight: "19px",
            fontWeight: "400",
            textTransform: "none",
        }
    }

    const handleEditTemplate = (data, callback) => {        
    
        const payload = {
            name: data.name,
            status: data.status,
            description: data.description,
            template_id: templateId,
            attributes: [...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.attributes.other.map(c => {
                let newC = {...c};
                delete newC["removable"]
                delete newC["templateType"]
                return newC
            })],
            // packageTemplate: [...components.packageTemplate.mandatory, ...components.packageTemplate.other],
            // itemTemplate: [...components.itemTemplate.mandatory, ...components.itemTemplate.other],
        }
        if(components.packageTemplate.status){
            payload['packageTemplate'] = [...components.packageTemplate.mandatory, ...components.packageTemplate.other.map(c => {
                let newC = {...c};
                delete newC["removable"]
                delete newC["templateType"]
                return newC
            })]
        }
        if(components.itemTemplate.status){
            payload['itemTemplate'] = [...components.itemTemplate.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.itemTemplate.other.map(c => {
                let newC = {...c};
                delete newC["removable"]
                delete newC["templateType"]
                return newC
            })]
        }
        // if(selectedSubTemplate == "itemMaster"){
        //     payload.dataEntryType = data.dataEntryType
        // }
        if(selectedSubTemplate == "itemMaster"){
            payload.dataEntryType = data.dataEntryType;
            payload.attributes = [
                ...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate).map((f, i) => {
                    let fcopy = {...f};
                    if(fcopy.isForBarcode){
                        fcopy.orderInBarCode = i
                    }
                    return fcopy
                })
            ]
            payload.attributes = payload.attributes.concat(
                ...components.attributes.other.map((f, i) => {
                    let fcopy = {...f};
                    if(fcopy.isForBarcode){
                        fcopy.orderInBarCode = payload.attributes.length + i
                    }
                    return fcopy
                })
            )
        }
        if(["ASNTemplate","salesOrderTemplate"].includes(selectedSubTemplate)){
            payload["connectedMaster"] = {
                "itemMaster": selectedItemMaster != "-" ? selectedItemMaster : "",
            }
            payload["connectedWithAddressbook"] = components?.attributes?.addressBook;
        }
        
        ////(payload);
        dispatch(editTemplate(payload, ({success,message})=>{
            // ////("result--->Edit",{success,message});
            showToast(message, !success);
            if(success){
                callback()
                dispatch(getAllTemplates({page:1,limit:10}))
                history.push("/dashboard/templates")
                // setTimeout(()=>{
                //     window.location.href="/dashboard/templates"
                // },1000)
            }
        }))
    }

    const resetConnectedMasters = () => {
    
        dispatch(
            selectMasterTemplate("itemMaster", "-")
        )
        dispatch(setAddressBookField("attributes",[])) 
    }

    useEffect(() => {
        
        dispatch(
            getStandardComonents({type: "wms"})
        )
        dispatch(
            getPreviousComonents({type: "wms"})
        )
        dispatch(
            getTemplateTypes((data) => {
                if(data && data.success){
                    dispatch(
                        initCanvas(convertToCanvasObject(data.data))
                    )

                    if(templateId && templateId != ""){
                        ////("Got Templates Id", templateId);
                        setLoading(true);
                        dispatch(
                            getSelectedTemplate({template_id: templateId}, (data) => {
                                ////("EDIT DATA", data);
                                if(data && Object.keys(data).length > 0){
                                    dispatch(
                                        replaceEditCanvas(data.templateCategory, data.templateType, initEditCanvas(data))
                                    )
                                    setLoading(false);
                                }
                                else{
                                    showToast("Something went wrong", true)
                                    history.push("/dashboard/templates")
                                }
                            })
                        )
                    }
                }
            })
        )
        
    }, [])

    // useEffect(() => {
        
        
    // }, [])

    useEffect(() => {
        if(template){
            if(template.connectedMaster && template.connectedMaster.itemMaster && template.connectedMaster.itemMaster != ""){
                dispatch(
                    selectMasterTemplate("itemMaster", template.connectedMaster.itemMaster)
                )
            }
         
            else{
                dispatch(
                    selectMasterTemplate("itemMaster", "-")
                )
            }
            if(template?.connectedWithAddressbook?.length > 0){
                dispatch(setAddressBookField("attributes",[addressBookFields])) 
            }
        }
        else{
            resetConnectedMasters();
        }
    }, [template])

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Typography sx={{...textClasses.cardTitle}}>Edit Template - {template && template.name}</Typography>
                <Breadcrumbs sx={{mb: 1}}>
                    <Typography sx={{...textClasses.normalText, fontSize: "12px", color: theme.themeOrange}}>
                        <Link style={{color: theme.themeOrange, textDecoration: "none"}} to="/dashboard/templates">
                            Manage Templates
                        </Link>
                    </Typography>
                    
                    <Typography sx={{...textClasses.normalText, fontSize: "12px"}} >Edit Template</Typography>
                </Breadcrumbs>
                <Grid container spacing={2}>
                    <Grid item sm={8} xs={10}>
                        <Card sx={{borderRadius: "12px"}}>
                            <CardHeader
                                sx={
                                    {
                                        pt: 1,
                                        pl: 2,
                                        pb: 1,
                                        borderRadius: "8px 8px 0px 0px",
                                        backgroundColor: theme.themeOrange,
                                        color: "white",
                                        boxShadow: "0px 2px 6px gray"
                                    }
                                }
                                action={
                                    sectionStep > 1 && 
                                    <Select
                                        disabled
                                        sx={{
                                            backgroundColor: "white",
                                            color: theme.themeOrange,
                                            minWidth: "200px",
                                        }}
                                        size='small'
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedSubTemplate}
                                        onChange={handleTemplateChange}
                                    >
                                        {
                                            allTemplateTypes.map((item, i) => {
                                                if(item.allTemplateTypes.length > 1){
                                                    let menus = item.allTemplateTypes.map((s, j) => {
                                                        return <MenuItem value={s.templateTypekey} key={`${i}-${j}`}>{item.templateCategoryLabel} - {s.templateTypeLabel}</MenuItem>
                                                    })
                                                    return menus;
                                                }
                                                else{
                                                    return <MenuItem value={item.allTemplateTypes[0].templateTypekey} key={i}>{item.allTemplateTypes[0].templateTypeLabel}</MenuItem>
                                                }
                                            })
                                        }
                                    </Select>
                                }
                                title={
                                    <Typography variant='h6'>Drop Section</Typography>
                                }
                            />
                            <CardContent sx={{pt: 2}}>
                                <Grid container sx={{mb: 1}}>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 1 ? theme.themeOrange : null}}>1</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>select template type</Typography>
                                    </Grid>
                                    <Grid item sm={3} justifyContent="center">
                                        <Divider sx={{mt: 2}}/>
                                    </Grid>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 2 ? theme.themeOrange : null}}>2</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>drag and drop comp</Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Divider sx={{mt: 2}}/>
                                    </Grid>
                                    <Grid container item sm={2} direction="column" alignItems="center" justifyContent={"center"}>
                                        <Avatar sx={{width: 32, height: 32, backgroundColor: sectionStep == 3 ? theme.themeOrange : null}}>3</Avatar>
                                        <Typography variant='caption' sx={{mt: 1, fontSize: "12px"}}>preview & submit form</Typography>
                                    </Grid>
                                </Grid>

                                {
                                    loading 
                                    ?
                                        <Grid container justifyContent={"center"} alignItems="center" sx={{mt: 3}}>
                                            <GenericLoader />
                                        </Grid>
                                    :
                                        getSectionStep()
                                }
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={4} xs={2}>
                        <Paper sx={{position: "fixed", zIndex: 100, mr: 2, overflowY: "scroll", maxHeight: "500px", borderRadius: "8px"}}>
                            <Tabs value={tabIndex} aria-label="disabled tabs example" variant='fullWidth' onChange={handleTabChange} sx={{'& .MuiTabs-indicator': { display: 'none' }, "& .MuiTab-root.Mui-selected": {color: '#FFFFFF'}}}>
                                <Tab label="Previously Used Components"  {...a11yProps(0)} sx={{...getTabStyles(0), borderTopLeftRadius: "8px"}} />
                                <Tab label="Standard Form Components" {...a11yProps(1)} sx={{...getTabStyles(1), borderTopRightRadius: "8px"}} />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0} style={{backgroundColor: "#EEEEEE"}} >
                                <PreviousUsedComponents usedComponents={usedComponents} />
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1} style={{backgroundColor: "#EEEEEE"}}>
                                <StandardComponents stdComponents={stdComponents} predefinedElements={predefinedElements}/>
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Grid>
            </DndProvider>
        </>
    )
}
