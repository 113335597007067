import React, { useEffect, useState } from 'react';
import { lynkitIOURL } from '../../../../services/config';
import { useLocation } from "react-router-dom";
import Integration from '../../../Components/Integration';


const TripDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const todayDate = new Date().getTime();
    const [myVariable, setMyVariable] = useState(`${lynkitIOURL}/login?action=/trip&auth=WMS&timestamp=${todayDate}`)
    // https://beta.lynkit.io/trip/trip-detail?id=TRP251&_id=66163e12e3066bdd0c0b3b6d&hideUpdate=false&hideDelete=false&trip_view=1
   
    //https://beta.lynkit.io/trip/trip-detail?id=TRP1050&_id=6619117d949643329ca77999&hideUpdate=false&hideDelete=false&trip_view=1
    //https://beta.lynkit.io/login?action=/trip/trip-detail?id=TRP65&_id=661a1905a8d0c490e56ef4c2&hideUpdate=false&hideDelete=false&trip_view=1&auth=WMS&timestamp=1713007546383
    useEffect(() => {
        const tripNumber = queryParams.get('trip-number');
        const tripId = queryParams.get('trip-id');
        if (tripNumber && tripId) {
            let tempPath = `trip/trip-detail?id=${tripNumber}&_id=${tripId}&hideUpdate=false&hideDelete=false&trip_view=1`
            let path=`${lynkitIOURL}/login?action=/${tempPath}&auth=WMS&timestamp=${todayDate}`
            console.log("trip_path",path)
            setMyVariable(path)
        }
    }, []);
    return (
        <>
            <Integration
                myVariable={myVariable}
                baseURL={lynkitIOURL}
            />
        </>
    );
};
export default TripDetail;
