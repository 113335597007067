import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
  } from "../types";
import axios from "../../services/Api";

export const getAllPermisssions = (bodydata, callBackFunction) =>
{
    return async (dispatch) => {
   
        dispatch({type: FETCH_START});
        await axios.post('/role/getPermissions', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({ type: "GET_ALL_PERMISSIONS", payload: data.data });
            // dispatch({type: SHOW_MESSAGE, payload: data.message});
            // //('manageteam0', data)
            
          } 
          else 
          {
            dispatch({type: FETCH_ERROR, payload: data.message});
            dispatch({ type: "GET_ALL_PERMISSIONS", payload: {} });
            
          }
          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          //("Error****:", error.message);
          if(callBackFunction){
            callBackFunction({message: error.message })
          }
        });
      }
};
