import React, { useEffect, useState,useRef} from 'react'
import {
    TextField, MenuItem,Autocomplete
} from '@mui/material'
import { inputClasses} from '../../../static/styles/theme'
import CheckIcon from "@mui/icons-material/Check";
import { customAPI_For_APIPara } from "../../../services/formFunction"
import { debounce } from '@mui/material/utils';
import { selectStylesOverride } from '../../../utils/util';
import { Add } from '@mui/icons-material';
import theme from '../../../static/styles/theme';
import AddReason from '../Reason/AddReason';

const TypeAndSearch = ({
    component,label, value,options=[], handleChange,setEmptyOnChange=false,resetField=false,
     disabled,set_Value=false,addNewOptions={},reasonType=""
    }) => {
        //  addNewOptions ={isEnabled:false,label:""}
    const [selectOptions, setSelectOptions] = useState(options);
    const [initial, setInitial] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [searching, setSearching] = useState("");
    const [openReasonModal,setOpenReasonModel]=useState(false);
    const [enableFetch, setEnableFetch] = useState(false);

    const usePrevious = (value="") => {
        const ref = useRef();

        useEffect(() => {
            ref.current = value;
        }, [value]);

        return ref.current;
    };

    const prevData = usePrevious(label || value);
    const prevInput = usePrevious(inputValue);

    const handleOpenModal = ()=>{
        setOpenReasonModel(true)
    }
    // const fetch = React.useMemo(() => 
    //     debounce(async (component, value, setOptions, setSearchingStatus,addNewOptions={}) => {
    //         setSearchingStatus("Searching...");
    //         try {
    //             if(value){
    //                 await customAPI_For_APIPara(component, value,(results=[]) => {
    //                     let temp_option=results
    //                     if(addNewOptions?.isEnabled== true){
    //                         temp_option.unshift({label:addNewOptions?.label,value:'addNew'})
    //                     }
    //                     setOptions(temp_option);
    //                     setSearchingStatus("");
    //                 });

    //             }
    //             else{
    //                 setOptions([]);
    //                 setSearchingStatus("");  
    //             }
    //         } catch (error) {
    //             console.error("Failed to fetch data:", error);
    //             setOptions([]);
    //             setSearchingStatus("Failed to fetch");
    //         }
    //     }, 500),
    //     [value],
    // );

    // useEffect(async() => {
    //     let active = true;     
    //     const fetchData = async () => {
    //         // console.log("initial1",{
    //         //     component_id:component._id,
    //         //     label:label ,
    //         //     value:value ,
    //         //     dynamic:component?.dynamic,
    //         //     dontFetch:component?.dontFetch,
    //         //     initial,prevData
    //         // });

    //         if (initial && component?.dynamic && !component.dontFetch) {
    //         //  console.log("initial2",initial);

    //             if ((label && value) || options?.length>0) {
    //                 let temp_option = []
    //                 temp_option = options || [{label,value}];
    //                 if(addNewOptions?.isEnabled== true){
    //                     temp_option.unshift({label:addNewOptions?.label,value:'addNew'})
    //                 }
    //                 setSearching("")
    //                 setSelectOptions(temp_option)
    //             }
    //             else {//if ((label || value)) //!=prevData
    //                 await fetch(component, (label || value), setSelectOptions, setSearching,addNewOptions);
    //             }
    //             // else{
    //             //     let temp_option = []
    //             //     if(addNewOptions?.isEnabled== true){
    //             //         temp_option.unshift({label:addNewOptions?.label,value:'addNew'})
    //             //     }
    //             //     setSearching("")
    //             //     setSelectOptions(temp_option)  
    //             // }
    //         }
    //         setInitial(false);
    //     };
    //     fetchData();     
    //     return () => {
    //         active = false;
    //     };
    // }, [value, label, component]);


    // useEffect(async () => {
    //     let active = true;
    //     if (!initial && component?.dynamic && enableFetch ) {
    //         console.log(component?._id, "initial34", component?.value || "N/A",enableFetch, "jjj", inputValue || "N/AA", "hhhhhhh", prevInput);
    //         if (!inputValue) {
    //             // if (component?.value) {
    //             //     await fetch(component, component?.value, setSelectOptions, setSearching,addNewOptions);
    //             // }
    //             // else{
    //             let temp_option = []
    //             if (addNewOptions?.isEnabled == true) {
    //                 temp_option.unshift({ label: addNewOptions?.label, value: 'addNew' })
    //             }
    //             setSelectOptions(temp_option)
    //             setSearching("")
    //             // }
    //             setEnableFetch(false);
    //             return undefined;
    //         }
    //         else if(prevInput!=inputValue){
    //             await fetch(component, inputValue, setSelectOptions, setSearching, addNewOptions);
    //         }
    //         setEnableFetch(false);
    //     }


    //     return () => {
    //         active = false;
    //     };
    // }, [inputValue,component, fetch]);


    const fetch = React.useMemo(
        () =>
            debounce((component, value, callback) => {
                setSearching("Searching...")
                customAPI_For_APIPara(component, value, callback)
            }, 400),
        [],
    );

    useEffect(async() => {
        // console.log(value,"value",component)
        let active = true;
        if (initial) {
            // console.log(component,"component")
            if (component?.dynamic) {
                let temp_option = []
                if ((label && value) || options?.length>0) {
                    // console.log(label,"temp_option1")
                    temp_option = options?.length>0 && options || [{ label, value }];
                    if (addNewOptions.isEnabled == true) {
                        temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                    }

                    setSearching("")
                    setSelectOptions(temp_option)
                }
                else if (!component.dontFetch) {
                    // console.log(label,"temp_option2")
                    fetch(component, label || value, (results) => {
                        // console.log(results,"results",active)
                        if (active && results) {
                            temp_option = [...results];
                        }
                        if (addNewOptions.isEnabled == true) {
                            temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                        }
                        setSearching("")
                        setSelectOptions(temp_option)
                    });
                }
                else {
                    // console.log(label,"temp_option2")
                    if (addNewOptions.isEnabled == true) {
                        temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                    }
                    setSearching("")
                    setSelectOptions(temp_option)
                }
                setInitial(false)
            }
            else{
                setInitial(false)
            }
        }        

        return () => {
            active = false;
        };
    }, [value, label, component]);


    useEffect(() => {
        let active = true;
        if (!initial && component?.dynamic && enableFetch) {
            if (!inputValue) {

                let temp_option = []
                if (addNewOptions.isEnabled == true) {
                    temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
                }
                if(!component.dontFetch){
                    fetch(component, "", (results) => {
                        setSearching("")
                        if (active) {
                            if (results) temp_option = [...temp_option, ...results];
                            setSelectOptions(temp_option); 
                        }
                        return undefined;
                    });
                }
                else{
                    setSelectOptions(temp_option)
                    setSearching("")
                    return undefined;
                }
            }
            else if(prevInput!=inputValue){
                fetch(component, inputValue, (results) => {
                    setSearching("")
                    if (active) {
                        let newOptions = [];
                        if (results) newOptions = [...newOptions, ...results];
                        if (addNewOptions.isEnabled == true) {
                            newOptions.unshift({ label: addNewOptions.label, value: 'addNew' })
                        }
                        setSelectOptions(newOptions); 
                    }
                });
            }
        }

        return () => {
            active = false;
        };
    }, [inputValue,enableFetch]);

    const fetchNewData = () => {
        let temp_option = []
        if (addNewOptions.isEnabled == true) {
            temp_option.unshift({ label: addNewOptions.label, value: 'addNew' })
        }
        fetch(component, "", (results) => {
            setSearching("")
            if (results) temp_option = [...temp_option, ...results];
            setSelectOptions(temp_option);
        });
    }

    
    return (
        <>
            <Autocomplete
                id={component?._id}
                disableClearable={!inputValue}
                sx={{ ...inputClasses.themeBoundary }}
                filterOptions={(x) => x}
                options={selectOptions}
                disabled={disabled}
                {...(set_Value ? {
                    value: value && selectOptions.find(dl => dl.value == value)?.label || ""
                } : {})}
                // autoComplete
                size="small"
                includeInputInList
                filterSelectedOptions
                noOptionsText={searching
                    ?searching
                    :inputValue && "No Data Found" || "Type Here..."
                }
                onChange={(event, newValue) => {
                    handleChange({ target: { value: newValue?.value || "" } },newValue)
                    if(setEmptyOnChange){
                        setInputValue("")
                        setSelectOptions(addNewOptions.isEnabled==true ? [{ label: addNewOptions.label, value: 'addNew' }]:[]);
                    }
                    else{
                        setSelectOptions(newValue? [newValue] : addNewOptions.isEnabled==true ? [{ label: addNewOptions.label, value: 'addNew' }]:[]);
                    }
                    // console.log(enableFetch,"initial1")
                    setEnableFetch(false)
                }}
                onInputChange={(event, newInputValue,reason) => {
                    setInputValue(newInputValue);
                    setSelectOptions(addNewOptions.isEnabled == true ? [{ label: addNewOptions.label, value: 'addNew' }] : [])
                    setSearching(newInputValue ? "Typing..." : "")
                    setEnableFetch(newInputValue != prevInput || false)
                    // console.log(enableFetch,"initial2",reason)
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        label={`${component?.placeholder || component.label}${component.required ? "*" : ""}`}
                        fullWidth
                        sx={{
                            ...selectStylesOverride,
                        }}
                    />
                )}
                renderOption={(props, option, { selected }) => {
                    return (
                        <>
                            {
                                option?.value == "addNew" ? <MenuItem
                                    sx={{ color: theme.themeOrange }}
                                    onClick={handleOpenModal}
                                >
                                    <Add fontSize='inherit' /> {option.label}
                                </MenuItem>
                                    :
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{ justifyContent: "space-between" }}
                                        {...props}
                                    >
                                        {option.label}
                                        {selected ? <CheckIcon color="info" /> : null}
                                    </MenuItem>}
                        </>
                    )
                }}
                // inputValue={inputValue}
            />
                {openReasonModal && <AddReason open={openReasonModal} setOpenReasonModel={setOpenReasonModel} type={reasonType || ""} fetchNewData={fetchNewData}/>}

        </>
    )
}

export default TypeAndSearch;
