import {
    GENERATE_EXCEL,
    GENERATE_QR,
    FETCH_COMPANY_DETAIL,
    FETCH_COMPANY_STATUS_COUNT ,
    FETCH_COMPANY_TYPE_WISE_COUNT,
    FETCH_SUPERADMIN_USERS_PERMISSIONS,
    FETCH_SUPERADMIN_USERS_DETAIL
  } from "../types";

const intialState = {
    superadmin: {},
    companies: {},
  
    //current
    allPermissions: [],
    warehouseDetail: {},
    floorDetail: [],
    allWFDetail: [],
    allAnalytics: {},

    //superadmin
    superadminCompanies:{},
    superadminWarehouseDetail:{},
    superadminFloorDetail:{},
    superadminAllWFDetail:{},
    superadminUsersPermissions:[],
    superadminUsersDetail: null,
    
    // warehouseAnalytics:{},
    // floorAnalytics:[],
    generatedQr: undefined,
    //incomming
    // warehouse:[],
    // floors:[],
    selectedFloor: undefined,
    statusCount: null,
    typeWiseCount: null,
    companyDetail: null,
    companyDetailItemCount: 0,
};

const SuperAdmin = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_COMPANY":
            return { ...state, companies: payload };
        case "GET_FLOOR":
            return { ...state, floorDetail: payload };
        case "GET_WAREHOUSE":
            return { ...state, warehouseDetail: payload };
        case "GET_ALL":
            return { ...state, allWFDetail: payload };
        case "GET_SUPERADMIN_COMPANY":
            return { ...state, superadminCompanies: payload };
        case "GET_SUPERADMIN_FLOOR":
            return { ...state, superadminFloorDetail: payload };
        case "GET_SUPERADMIN__WAREHOUSE":
            return { ...state, superadminWarehouseDetail: payload };
        case "GET_SUPERADMIN_ALL":
            return { ...state, superadminAllWFDetail: payload };
        case GENERATE_QR:
            return { ...state, generatedQr: payload };
        case GENERATE_EXCEL:
            return { ...state, generatedQr: payload };
        case FETCH_COMPANY_STATUS_COUNT:
            return { ...state, statusCount: payload }
        case FETCH_COMPANY_TYPE_WISE_COUNT:
            return { ...state, typeWiseCount: payload}
        case FETCH_COMPANY_DETAIL:
            return { ...state, companyDetail: payload?.data, companyDetailItemCount: payload?.count || 0 }
        case FETCH_SUPERADMIN_USERS_PERMISSIONS:
            return {...state, superadminUsersPermissions: payload || []}
        case FETCH_SUPERADMIN_USERS_DETAIL:
            return {...state, superadminUsersDetail: payload?.data || {}}
        default: 
        return state;
    }
}

export default SuperAdmin;