import { CHANGE_USN_MODAL_STATUS, FETCH_ALL_UOM } from "../types";

const INIT_STATE = {
    openModal: false,
    allUom: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_UOM: return {...state, allUom: action.payload}
      case CHANGE_USN_MODAL_STATUS: return {...state, openModal: action.payload}
      default:
        return state;
    }
  }


  