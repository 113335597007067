import {
    FETCH_ERROR, FETCH_SUCCESS, FETCH_START, FETCH_ALL_DEKIT, FETCH_DEKIT_DETAIL, FETCH_DEKIT_STATUS_COUNT
} from "../types"
import axios from "../../services/Api";

export const initiateDekitProcess = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/initiateDekit', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getAllDekits = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/getItemsUnderDekitting', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_DEKIT, payload: { rows: data?.data || {}, count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_ALL_DEKIT, payload: { rows: {}, count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};

export const getDeKitDetails = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/showComponentDetailsForDekitting', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_DEKIT_DETAIL, payload: { data: data?.data || {}, count: data?.count } })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_DEKIT_DETAIL, payload: { data: {}, count: 0 } })
            }

            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};


export const getDeKitStatusCount = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/getDekittingStatusWiseCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_DEKIT_STATUS_COUNT, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_DEKIT_STATUS_COUNT, payload: null })
            }


            if (cb) {
                cb(data)
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};



export const approveRejectDeKit = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios.post('inventory/approveRejectDekit', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }
            if (cb) {
                cb(data)
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            if (cb) {
                cb({ success: false, message: error.message })
            }
        });
    }
};
