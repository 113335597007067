import React, { useEffect, useState } from "react";
import StaticFormRender from "../../../Components/DynamicFormRender/staticRendererForm";
import { useForm } from 'react-hook-form';
import {
    Grid,
    Button,
    Typography,
    Breadcrumbs,
} from "@mui/material";
import theme, { textClasses, cardClasses, inputClasses, buttonClasses } from "../../../../static/styles/theme";
import { Link,useLocation } from 'react-router-dom';
import { showToast } from "../../../../services/functions";
import { addCompany, getTemplate,getCompanyDetails,updateCompany } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../services/history";
import GenericLoader from "../../../Components/Generic/GenericLoader";

const AddCompany = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editableCompanyId = queryParams.get("id")
    const dispatch = useDispatch();
    const [loader,setLoader] = useState("")
    const { register, handleSubmit, watch, getValues, setValue, control, reset, formState: { errors } } = useForm();
    const [dataReceieved, isDataReceived] = useState(false)
    const [templateDetail, setTemplateDetail] = useState([])
    let isEditable = false;
    isEditable = editableCompanyId ?true:false;

    const handleSubmitData = (payloadData) => {
        let payload = {
            companyData: payloadData,
            templateId: templateDetail?._id
        }
        isDataReceived(true)
       
        if (isEditable) {
            dispatch(updateCompany({ updatedData: payloadData, type: "company", componentId: editableCompanyId }, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/company")
                }
            }))
        }  
        else{
            dispatch(addCompany(payload, (data) => {
                showToast(data.message, !data.success);
                isDataReceived(false)
                if (data.success) {
                    history.push("/dashboard/company")
                }
            }))
        }
    }
    const handleClearAll = (attributes = [], data = {}) => {
        let resetD = {};
        for (let ele of attributes) {
            if (data[ele?._id]) {
                resetD[ele?._id] = data[ele?._id]
            }
            else {
                resetD[ele?._id] = ele?.type == "checkbox" ? [] : ""
            }
        }
        reset({
            ...resetD
        });
    }
  
    useEffect(() => {
        setLoader("getDynamicTemplate")
        dispatch(getTemplate({ type: "company" }, (data) => {
            setTemplateDetail(data)
            setLoader("")
            if (isEditable) {
                dispatch(getCompanyDetails({ _id: editableCompanyId }, (detailData) => {
                    handleClearAll(data?.attributes || [], detailData?.data?.formData)
                }))
            }
        }))

    }, [])
    return (<>
        <Typography sx={{ ...textClasses.cardTitle }}>Manage Company</Typography>

        <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumb-create-company">
            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/company">
                    Manage Company
                </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              {isEditable ?  "Edit Company":"Create New Company" }
                </Typography>
        </Breadcrumbs>
        { loader == "getDynamicTemplate" ?
          <Grid
            data-testid="loader"
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 10 }}
          >
            <Grid item>
              <GenericLoader />
            </Grid>
          </Grid> :
        <Grid container sx={{ overflowY: 'auto', border: '1px solid #E8E8E8', overflowX: 'hidden', padding: '10px', paddingTop: '20px', paddingBottom: '20px', mt: 3, mb: 3 }} data-testid="create-company-template">
            <StaticFormRender
                formHooks={{ register, control, errors, handleSubmit, reset, setValue, watch }}
                tempAttr={templateDetail?.attributes || []}
                disabled={isEditable? ["pan"]:[]}
            />
        </Grid>
}
        <Grid container item direction={"row"} sm={12} xs={12} sx={{ mt: 2 }}  >
            <Button
                data-testid="clear-all-btn"
                variant='outlined'
                size='small'
                sx={{ ...buttonClasses.lynkitOrangeEmpty, ml: 0, mr: 2, fontSize: ".7rem", height: '37px', minWidth: "150px" }}
                onClick={()=>handleClearAll(templateDetail?.attributes,{})}
            >
                Clear All
            </Button>
            <Button
                data-testid="submit-btn"
                variant='contained'
                size='small'
                disabled={dataReceieved}
                sx={{ ...buttonClasses.lynkitOrangeFill, ml: 0, fontSize: ".7rem", border: 'none', height: '37px', minWidth: "150px" }}
                onClick={handleSubmit(handleSubmitData, (errors) => {
                    let errorField = Object.keys(errors)[0];
                    showToast(`${errors[errorField].message}`, true);
                })}
            >
               {isEditable ? "Update" : "Submit"}
            </Button>
        </Grid>
    </>)
}

export default AddCompany;